<template>
  <PopupEditor :showEdit.sync="showEditComputed" :title="estructuraEditable.descripcion" :update="updateEstructura">
    <div class="p-3">
      <div class="d-flex flex-wrap w-100 gap-4">
        <div class="d-flex flex-column w-100">
          <label class="form-label">{{ $t("descripcion") }}*</label>
          <div class="form-data">
            <DxTextBox :value.sync="estructuraEditable.descripcion">
              <DxValidator>
                <DxRequiredRule :message="$t('errorDescripcionRequerida')" />
              </DxValidator>
            </DxTextBox>
          </div>
        </div>

        <div class="d-flex flex-column w-100">
          <label class="form-label">{{ $t("proceso") }}</label>
          <div class="form-data">
            <DxTextBox :value.sync="estructuraEditable.proceso"> </DxTextBox>
          </div>
        </div>

        <div class="d-flex flex-row w-100 gap-4">
          <div class="d-flex flex-column w-100">
            <label class="form-label">{{ $t("maquina") }}</label>
            <div class="form-data">
              <DxSelectBox
                :value.sync="estructuraEditable.id_maquinas"
                :search-enabled="true"
                search-mode="contains"
                :search-expr="['codigo', 'descripcion']"
                :display-expr="(item) => (item ? `${item.codigo} - ${item.descripcion}` : '')"
                value-expr="id"
                :data-source="maquinasDataSource"
                :show-clear-button="true"
              >
                <template #item="{ data }">
                  <div class="clientes-item">
                    <span v-if="data.descripcion">{{ data.descripcion }}</span>
                    <i v-else>{{ $t("sinNombre") }}</i>
                    <br />
                    <small>{{ data.codigo }}</small>
                  </div>
                </template>
              </DxSelectBox>
            </div>
          </div>
        </div>

        <div class="d-flex flex-column w-100">
          <label class="form-label">{{ $t("notas") }}</label>
          <div class="form-data">
            <HtmlEditor :value.sync="estructuraEditable.notas"> </HtmlEditor>
          </div>
        </div>
      </div>
    </div>
  </PopupEditor>
</template>

<script>
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

import PopupEditor from "@/components/core/PopupEditor.vue";
import HtmlEditor from "@/components/core/HtmlEditor.vue";
import DxTextBox from "devextreme-vue/text-box";
import DxSelectBox from "devextreme-vue/select-box";
import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";

export default {
  props: ["showEdit", "estructura", "update"],
  components: {
    DxTextBox,
    DxSelectBox,
    HtmlEditor,
    DxValidator,
    DxRequiredRule,
    PopupEditor,
  },
  data() {
    return {
      estructuraEditable: { ...this.estructura },

      maquinasDataSource: new DataSource({
        paginate: true,
        store: new CustomStore({
          key: "id",
          loadMode: "raw",
          load: () => this.$fetch.get(`${global.API_URL}/maquinas`),
          // byKey: (key) => this.$fetch.get(`${global.API_URL}/proveedores/${key}`),
          // insert: (values) => this.$fetch.post(`${global.API_URL}/proveedores`, values),
          // update: (key, values) => this.$fetch.put(`${global.API_URL}/proveedores/${key}`, values),
          // remove: (key) => this.$fetch.delete(`${global.API_URL}/proveedores/${key}`),
        }),
      }),
    };
  },
  computed: {
    showEditComputed: {
      get() {
        return this.showEdit;
      },
      set(value) {
        this.$emit("update:showEdit", value);
      },
    },
  },

  methods: {
    updateEstructura: function () {
      this.update(this.estructuraEditable);
    },
  },
};
</script>
