<template>
  <div id="root" :key="componentKey">
    <div :class="cssClasses">
      <router-view name="layout" :title="title" :is-x-small="screen.isXSmall" :is-large="screen.isLarge">
        <div class="content">
          <router-view name="content" />
        </div>

        <template #footer>
          <the-footer />
        </template>
      </router-view>
    </div>
  </div>
</template>

<script>
import TheFooter from "./components/the-footer";
import { sizes, subscribe, unsubscribe } from "./utils/media-query";
import { locale, loadMessages } from "devextreme/localization";

import esMessages from "devextreme/localization/messages/es.json";
import frMessages from "devextreme/localization/messages/fr.json";

function getScreenSizeInfo() {
  const screenSizes = sizes();

  return {
    isXSmall: screenSizes["screen-x-small"],
    isLarge: screenSizes["screen-large"],
    cssClasses: Object.keys(screenSizes).filter((cl) => screenSizes[cl]),
  };
}

export default {
  name: "app",
  data() {
    return {
      title: this.$appInfo.title,
      screen: getScreenSizeInfo(),
      componentKey: 0,
    };
  },
  computed: {
    cssClasses() {
      return ["app"].concat(this.screen.cssClasses);
    },
  },
  methods: {
    screenSizeChanged() {
      this.screen = getScreenSizeInfo();
    },
    initMessages() {
      loadMessages(esMessages);
      loadMessages(frMessages);
    },
  },

  mounted() {
    subscribe(this.screenSizeChanged);

    let idioma = "es";

    if (!this.$cookies.isKey("lang")) {
      const idiomas_soportados = ["en", "es", "fr", "cz", "ca"];
      idioma = idiomas_soportados.includes(navigator.language) ? navigator.language : "en";
      this.$cookies.set("lang", idioma, Infinity);
    } else {
      idioma = this.$cookies.get("lang", idioma);
    }

    this.$moment.locale(idioma);
    this.$i18n.locale = idioma;

    this.initMessages(); // inicializo los diccionarios de idiomas.

    locale(idioma);
  },

  beforeDestroy() {
    unsubscribe(this.screenSizeChanged);
  },

  components: {
    TheFooter,
  },
};
</script>

<style lang="scss">
html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.app {
  @import "./themes/generated/variables.base.scss";
  background-color: darken($base-bg, 5);
  display: flex;
  height: 100%;
  width: 100%;
}

.dx-swatch-additional .app {
  @import "./themes/generated/variables.additional.scss";
  background-color: darken($base-bg, 5);
}
</style>
