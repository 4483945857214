export const DEF = 1;
export const MDEF = 2;
export const XR = 3;
export const XBR = 4;
export const MAXMIN = 5;
export const OTHER = 6;

export const ATR = [DEF, MDEF];
export const VAR = [XR, XBR, MAXMIN];
// export const USR = ![...ATR, ...VAR];

export const isAtr = (type) => {
  return ATR.includes(type);
};

export const isVar = (type) => {
  return VAR.includes(type);
};

// condiciones variables
export const MAS_MENOS = 1; //±
export const ENTRE = 2; // ÷
export const MAS_MAS = 3; //++
export const MAS_MENOS_DIF = 4; //+-
export const MENOS_MENOS = 5; //--
export const MENOS_MAS = 6; // -+
export const MAX = 7; //Max
export const MIN = 8; //Min
export const PORCIENTO_MAX = 9; //%Max
export const PORCIENTO_SUM = 10; //%Sum

// condiciones atributos
export const CIENPORCIEN = 11;
export const NCA = 12;
