<template>
  <div>
    <code v-if="$store.state.debug"> isValid: {{ isValid }} </code>

    <div class="d-flex flex-column align-items-center pb-4" style="gap: 10px">
      <div class="d-flex flex-column pb-2 w-100 justify-content-center align-items-center">
        <DxValidationGroup ref="addValorValidationGroup">
          <DxNumberBox
            :label="$t('valores')"
            ref="nuevoValor"
            mode="text"
            v-model="nuevoValor"
            :showSpinButtons="true"
            :step="0.01"
            :min="0"
            @enter-key="nuevoValorOnEnterKey"
            @focus-in="nuevoValorOnFocusIn"
            width="250"
          >
            <DxNumberBoxButton :options="addButton" name="add" location="after" />

            <DxNumberBoxButton name="spins" />

            <DxValidator>
              <DxRequiredRule :message="$t('errorValorRequerido')" />
              <!-- <DxCustomRule
                    :validation-callback="validacionListaValores"
                    :message="$t('errorNoHayElementosEnLaLista')"
                  ></DxCustomRule> -->
            </DxValidator>
          </DxNumberBox>
        </DxValidationGroup>

        <div v-if="valores.length > 0" class="valores d-flex flex-row py-2 mt-2 gap-1 flex-wrap">
          <span
            v-for="(v, index) in valores"
            :key="index"
            @click="deleteUnValorOfValores(index)"
            class="badge fs-6"
            v-bind:class="{
              'text-bg-success': !fueraDeLimites(v),
              'text-bg-danger': fueraDeLimites(v),
            }"
          >
            {{ v }}
          </span>
        </div>
        <div class="p-3" :class="{}" :style="{ border: borderStyle }">
          <DxValidator :adapter="adapterConfig" ref="valueListValidator">
            <DxRequiredRule :reevaluate="true" :message="$t('errorNoHayElementosEnLaLista')"></DxRequiredRule>
          </DxValidator>
          <DxValidationSummary>
            <template #item="{ data }">
              <span class="badge text-bg-danger fs-6 border-0">
                {{ data.text }}
              </span>
            </template>
          </DxValidationSummary>
        </div>
      </div>

      <div class="d-flex flex-row align-items-end">
        <!-- <div class="d-flex flex-column analisis readonly limite-tolerancia-inferior">
          <label class="form-label" :title="$t('limiteToleranciaInferior')">{{ $t("lti") }}</label>
          <div class="form-data">
            {{ caracteristica.LTI && caracteristica.LTI.toFixed(3) }}
          </div>
        </div> -->

        <Display :value="caracteristica.LTI?.toFixed(3)" :label="$t('lti')" />

        <span class="le">&le;</span>

        <Display
          style="width: 90px"
          :value="analisis.minimo?.toFixed(3)"
          :label="$t('minimo')"
          :valueStyle="{
            'bg-danger-subtle border border-2 border-danger fw-bold text-danger-emphasis': fueraDeLimites(
              analisis.minimo
            ),
          }"
        />
        <!-- <div class="d-flex flex-column analisis readonly minimo">
          <label class="form-label">{{ $t("minimo") }}</label>
          <div class="form-data" :class="{ 'text-danger': fueraDeLimites(this.analisis.minimo) }">
            {{ analisis.minimo && analisis.minimo.toFixed(3) }}
          </div>
        </div> -->
        <span class="le">&le;</span>
        <Display
          style="width: 90px"
          :value="analisis.promedio?.toFixed(3)"
          :label="$t('promedio')"
          :valueStyle="{
            'bg-danger-subtle border border-2 border-danger fw-bold text-danger-emphasis': fueraDeLimites(
              analisis.valor
            ),
          }"
        />
        <!-- <div class="d-flex flex-column analisis readonly valor">
          <label class="form-label">{{ $t("promedio") }}</label>
          <div class="form-data" :class="{ 'text-danger': fueraDeLimites(this.analisis.valor) }">
            {{ analisis.valor && analisis.valor.toFixed(3) }}
          </div>
        </div> -->
        <span class="le">&le;</span>

        <Display
          style="width: 90px"
          :value="analisis.maximo?.toFixed(3)"
          :label="$t('maximo')"
          :valueStyle="{
            'bg-danger-subtle border border-2 border-danger fw-bold text-danger-emphasis': fueraDeLimites(
              analisis.maximo
            ),
          }"
        />

        <!-- <div class="d-flex flex-column analisis readonly maximo">
          <label class="form-label">{{ $t("maximo") }}</label>
          <div class="form-data" :class="{ 'text-danger': fueraDeLimites(this.analisis.maximo) }">
            {{ analisis.maximo && analisis.maximo.toFixed(3) }}
          </div>
        </div> -->
        <span class="le">&le;</span>

        <Display :value="caracteristica.LTS?.toFixed(3)" :label="$t('lts')" />

        <!-- <div class="d-flex flex-column analisis readonly limite-tolerancia-superior">
          <label class="form-label" :title="$t('limiteToleranciaSuperior')">{{ $t("lts") }}</label>
          <div class="form-data">
            {{ caracteristica.LTS && caracteristica.LTS.toFixed(3) }}
          </div>
        </div> -->
      </div>

      <!-- <div class="d-flex flex-column analisis readonly tamano-muestra">
        <label class="form-label">{{ $t("tamanoMuestra") }}</label>
        <div class="form-data">{{ analisis.tamano_muestra }} ({{ caracteristica.tamano_muestra }})</div>
      </div> -->

      <Display :value="analisis.tamano_muestra" :label="$t('tamanoMuestra')" />

      <!-- <div class="d-flex flex-column analisis readonly recorrido-movil">
        <label class="form-label">{{ $t("recorridoMovil") }}</label>
        <div class="form-data">
          {{ analisis.recorrido_movil && analisis.recorrido_movil.toFixed(3) }}
        </div>
      </div> -->

      <Display :value="analisis.recorrido_movil?.toFixed(3)" :label="$t('recorridoMovil')" />

      <span class="h5 d-flex flex-column analisis fuera-de-limites">
        <div v-if="fueraDeLimites(this.analisis.valor)" class="badge badge-danger">
          {{ $t("fueraLimitesTolerancia") }}
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import { DxNumberBox, DxButton as DxNumberBoxButton } from "devextreme-vue/number-box";
import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";
import DxValidationGroup from "devextreme-vue/validation-group";
import DxValidationSummary from "devextreme-vue/validation-summary";
import Display from "../../core/Display.vue";

export default {
  props: ["value", "caracteristica"],
  created() {
    this.$set(this.analisis, "_valores", this.analisis._valores || []);
  },
  data() {
    return {
      borderStyle: "none",
      isValid: false,
      nuevoValor: null,

      addButton: {
        icon: "add",
        type: "success",
        onClick: () => {
          let vg = this.$refs.addValorValidationGroup.instance;
          this.plusClick({ validationGroup: vg });
        },
      },

      adapterConfig: {
        getValue: () => {
          return this.analisis._valores.length > 0;
        },
        applyValidationResults: () => {
          // console.log("applyValidationResults", e);
          // this.borderStyle = e.isValid ? "none" : "2px solid var(--danger)";
        },
      },
    };
  },

  mounted() {
    // console.log("%cxbr.vue mounted!!!", "background-color: green");
  },
  updated() {
    // this.isValid = this.validate();
    // console.log("xbr.vue updated", this.isValid);

    this.analisis.maximo = this.valores.length ? Math.max(...this.valores) : null;
    this.analisis.minimo = this.valores.length ? Math.min(...this.valores) : null;
    this.analisis.tamano_muestra = this.valores.length;
    this.analisis.recorrido_movil = this.analisis.maximo - this.analisis.minimo;

    let sum = this.valores.reduce((previous, current) => (current += previous), 0);
    let avg = parseFloat((sum / this.valores.length).toFixed(10));

    this.analisis.promedio = avg ? avg : null;
    this.analisis.valor = avg ? avg : null;

    this.analisis.rechazo = this.fueraDeLimites(this.analisis.valor);
  },
  computed: {
    analisis: function () {
      return this.value;
    },
    valores: function () {
      return this.analisis._valores || [];
    },
  },
  components: {
    DxNumberBox,
    DxNumberBoxButton,
    DxValidator,
    DxValidationGroup,
    DxRequiredRule,
    DxValidationSummary,
    Display,
  },
  methods: {
    validate() {
      let valid = true;
      // No es valido si el valor no es un número
      if (typeof this.analisis.valor !== "number") valid = false;

      valid &= this.valores.length > 0;

      // @TODO elminar el validate y la emisión del evento valid...
      valid = true;
      this.$emit("valid", valid);
      return valid;
    },
    fueraDeLimites: function (valor) {
      if (typeof valor !== "number") return false;
      return valor < this.caracteristica.LTI || valor > this.caracteristica.LTS;
    },

    nuevoValorOnEnterKey() {
      //obtengo el validation group:
      let vg = this.$refs.addValorValidationGroup.instance;
      this.plusClick({ validationGroup: vg });
    },

    nuevoValorOnFocusIn(e) {
      let inputEl = e.element.querySelector(".dx-texteditor-input");
      inputEl.selectionStart = 0;
      inputEl.selectionEnd = inputEl.value.length;
    },

    plusClick(e) {
      if (e.validationGroup.validate().isValid) {
        this.valores.push(this.nuevoValor);
        const numberBoxNuevoValor = this.$refs.nuevoValor.instance;
        numberBoxNuevoValor.blur();
        numberBoxNuevoValor.focus();
      }

      // con esto consigo quitar el error (si estuviera) al hacer click en el boton de añadir
      this.$refs.valueListValidator.instance.validate();
    },

    // validacionListaValores() {
    //   return this.valores.length > 0;
    // },

    deleteUnValorOfValores(index) {
      this.valores.splice(index, 1);
    },
  },
};
</script>

<style scoped>
.valores .badge {
  cursor: pointer;
  user-select: none;
}

.valores .badge:hover::after {
  content: "  ❌";
}

/* .analisis {
  width: 150px;
} */

/* .valores {
  width: 275px;
} */

/*
.readonly .form-data {
  min-width: 75px;
  text-align: center;
  padding: 12px 5px;
  border: 1px solid #aaa;
  background-color: #ddd;
  border-radius: 2px;
  height: 40px;
}

.readonly .form-data.text-danger {
  text-align: left;
  padding: 11px 5px;
  font-weight: 700;
  border: 2px solid #dc3545;
  background-color: rgb(255, 242, 242);
}
*/

.fuera-de-limites {
  padding-top: 25px;
  width: auto;
}

.le {
  padding: 7px 4px;
  font-size: 2em;
}
</style>
