export default [
  {
    id: "home",
    text: "home",
    path: "/home",
    icon: "home",
  },
  {
    text: "planes",
    icon: "hierarchy",
    modulo: "PC",
    items: [
      {
        id: "/planes",
        text: "planes",
        path: "/planes",
        icon: "hierarchy",
        modulo: "PC",
      },

      {
        text: "catalogos",
        icon: "fa fa-database",
        modulo: "PC",
        items: [
          {
            text: "planDeControl",
            icon: "hierarchy",
            items: [
              {
                id: "/planes/plantas",
                text: "plantas",
                icon: "fa fa-industry",
                path: "/planes/plantas",
              },
              {
                id: "/planes/agrupaciones",
                text: "agrupaciones",
                icon: "fa fa-building",
                path: "/planes/agrupaciones",
              },
              {
                id: "/planes/productos",
                text: "productos",
                icon: "openbox",
                path: "/planes/productos",
              },
              {
                id: "/planes/maquinas",
                text: "maquinas",
                icon: "robot-industrial",
                path: "/planes/maquinas",
              },
            ],
          },
          {
            text: "caracteristicas",
            icon: "fa fa-car",
            items: [
              {
                id: "/planes/frecuencias",
                text: "frecuencias",
                icon: "ion ion-md-speedometer",
                path: "/planes/frecuencias",
              },
              {
                id: "/planes/caracteristicaTipos",
                text: "caracteristicasTipo",
                icon: "fa fa-building",
                path: "/planes/caracteristicaTipos",
              },
              {
                id: "/planes/metodosEval",
                text: "metodosEvaluacion",
                icon: "fa fa-building",
                path: "/planes/metodosEval",
              },
              {
                id: "/planes/unidades",
                text: "unidades",
                icon: "label",
                path: "/planes/unidades",
              },
              {
                id: "/caracteristicas/nca",
                text: "nca",
                icon: "fa fa-building",
                path: "/caracteristicas/nca",
              },
              {
                id: "/caracteristicas/metodoanalisis",
                text: "metodosAnalisis",
                icon: "fa fa-building",
                path: "/caracteristicas/metodoanalisis",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    text: "autocontrol",
    icon: "customchart",
    modulo: "AC",
    items: [
      {
        id: "/autocontrol",
        text: "autocontrol",
        icon: "customchart",
        path: "/autocontrol",
        modulo: "AC",
      },
      {
        id: "/analisis-dataraw",
        text: "analisis",
        icon: "fa fa-table",
        path: "/analisis-dataraw",
        modulo: "AC",
      },
    ],
  },

  {
    text: "instrumentos",
    icon: "caliper",
    modulo: "CA",
    items: [
      {
        id: "/instrumentos",
        text: "instrumentos",
        icon: "caliper",
        path: "/instrumentos",
        modulo: "CA",
      },
      {
        id: "/instrumentos/calibraciones",
        text: "calibraciones",
        icon: "fa fa-question",
        path: "/instrumentos/calibraciones",
        modulo: "CA",
      },
      {
        id: "/instrumentos/verificaciones",
        text: "verificaciones",
        icon: "fa fa-shield",
        path: "/instrumentos/verificaciones",
        modulo: "CA",
      },
      {
        text: "catalogos",
        icon: "fa fa-database",
        modulo: "CA",
        items: [
          {
            id: "/instrumentos/ubicaciones",
            text: "ubicaciones",
            icon: "fa fa-map-pin",
            path: "/instrumentos/ubicaciones",
            modulo: "CA",
          },
          {
            id: "/instrumentos/clasificacion",
            text: "clasificacion",
            icon: "hierarchy",
            path: "/instrumentos/clasificacion",
            modulo: "CA",
          },
          {
            id: "/instrumentos/categorias",
            text: "categorias",
            icon: "fa fa-th",
            path: "/instrumentos/categorias",
            modulo: "CA",
          },
        ],
      },
    ],
  },

  {
    text: "catalogos",
    icon: "fa fa-database",
    items: [
      {
        id: "/catalogos/departamentos",
        text: "departamentos",
        icon: "fa fa-building",
        path: "/catalogos/departamentos",
      },
      {
        id: "/catalogos/clientes",
        text: "clientes",
        icon: "fa fa-address-book",
        path: "/catalogos/clientes",
      },
      {
        id: "/catalogos/proveedores",
        text: "proveedores",
        icon: "fa fa-truck",
        path: "/catalogos/proveedores",
      },
      {
        id: "/catalogos/responsables",
        text: "responsables",
        icon: "fa fa-users",
        path: "/catalogos/responsables",
      },
    ],
  },

  {
    text: "administracion",
    icon: "fa fa-lock ",
    modulo: "AD",
    items: [
      {
        id: "/admin",
        text: "usuarios",
        icon: "users",
        path: "/admin",
      },
    ],
  },
];
