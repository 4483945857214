<template>
  <div :key="componentKey">
    <h2 class="content-block">
      <DxButton icon="arrowleft" type="back" styling-mode="text" @click="$router.go(-1)" />

      <router-link :to="{ name: 'instrumento' }" class="text-reset">
        <!-- {{ instrumento.nombre }} -->
        {{ instrumento.codigo }}
      </router-link>

      <i class="fs-6 text-light">
        <!-- {{ instrumento.codigo }} -->
        {{ instrumento.nombre }}
      </i>

      <small v-if="$store.state.debug" class="p-3"
        ><code>{{ id }}</code></small
      >
    </h2>

    <h3 class="content-block">{{ $t("nueva_verifiacion") }}</h3>

    <Debug>
      <pre>{{ instrumento }}</pre>
      <pre>{{ verificacion }}</pre>
    </Debug>

    <div class="content-block d-flex flex-wrap mt-4 gap-3 column-gap-5">
      <div class="d-flex flex-column">
        <label class="form-label">{{ $t("verificado_por") }}</label>
        <div class="form-data">
          <DxTextBox :value.sync="verificacion.verificado_por">
            <!-- <DxValidator ref="codigoValidator">
              <DxRequiredRule :message="$t('error_codigo_required')" />
            </DxValidator> -->
          </DxTextBox>
        </div>
      </div>
      <div class="d-flex flex-column">
        <label class="form-label">{{ $t("certificado_por") }}</label>
        <div class="form-data">
          <DxTextBox :value.sync="verificacion.certificado_por">
            <!-- <DxValidator ref="codigoValidator">
              <DxRequiredRule :message="$t('error_codigo_required')" />
            </DxValidator> -->
          </DxTextBox>
        </div>
      </div>

      <div class="d-flex flex-column">
        <label class="form-label">{{ $t("fecha") }}</label>
        <div class="form-data">
          <DxDateBox :value.sync="verificacion.fecha">
            <DxValidator ref="fechaValidator">
              <DxRequiredRule :message="$t('error_fecha_required')" />
            </DxValidator>
          </DxDateBox>
        </div>
      </div>
    </div>

    <div class="content-block">
      <h4>{{ $t("caracteristicas") }}</h4>

      <!-- <ul>
        <li v-for="linea in calibracion.lineas" :key="linea.id">
          <pre>{{ linea }}</pre>
          <div>
            <label>{{ $t("caracteristica") }}</label>
            <input v-model="linea.caracteristica" />
          </div>
        </li>
      </ul> -->

      <div
        class="caracteristica"
        v-for="linea in calibracion.lineas"
        :key="linea.id_instcaracteristicascalib"
        :class="{ success: isValid(linea) }"
      >
        <h3>{{ linea.caracteristica }}</h3>
        <!-- {{ linea.id_instcaracteristicascalib }} -->
        <!-- <div class="d-flex flex-column">
          <Display
            :label="$t('caracteristica')"
            :value="linea.caracteristica"
          />
        </div> -->

        <div class="d-flex flex-wrap mt-4 gap-3 column-gap-5">
          <Display class="flex-fill" :label="$t('metodo')" :value="linea.metodo" />
          <Display class="flex-fill" :label="$t('patron')" :value="linea.patron" />
          <Display class="flex-fill" :label="$t('procedimiento')" :value="linea.procedimiento" />
          <Display class="flex-fill" :label="$t('valor_pedido')" :value="linea.valor_pedido" />
          <Display class="flex-fill" :label="$t('valor_teorico')" :value="linea.valor_teorico" />
          <!-- <div class="d-flex flex-column">
            <label class="form-label">{{ $t("metodo") }}</label>
            <div class="form-data">
              <DxTextBox :value.sync="linea.metodo"> </DxTextBox>
            </div>
          </div>

          <div class="d-flex flex-column">
            <label class="form-label">{{ $t("patron") }}</label>
            <div class="form-data">
              <DxTextBox :value.sync="linea.patron"> </DxTextBox>
            </div>
          </div>
          <div class="d-flex flex-column">
            <label class="form-label">{{ $t("procedimiento") }}</label>
            <div class="form-data">
              <DxTextBox :value.sync="linea.procedimiento"> </DxTextBox>
            </div>
          </div>
          <div class="d-flex flex-column">
            <label class="form-label">{{ $t("valor_pedido") }}</label>
            <div class="form-data">
              <DxTextBox :value.sync="linea.valor_pedido"> </DxTextBox>
            </div>
          </div>
          <div class="d-flex flex-column">
            <label class="form-label">{{ $t("valor_teorico") }}</label>
            <div class="form-data">
              <DxTextBox :value.sync="linea.valor_teorico"> </DxTextBox>
            </div>
          </div> -->
        </div>

        <div class="d-flex flex-wrap mt-4 gap-3 column-gap-5">
          <div class="d-flex flex-fill flex-column">
            <label class="form-label">{{ $t("valor_obtenido") }}</label>
            <div class="form-data">
              <DxTextBox :value.sync="linea.valor_obtenido" width="100%">
                <DxValidator ref="valorObtenidoValidator">
                  <DxRequiredRule :message="$t('error_valor_obtenido_required')" />
                </DxValidator>
              </DxTextBox>
            </div>
          </div>
          <div class="d-flex flex-column">
            <label class="form-label">{{ $t("resultado") }}</label>
            <div class="form-data">
              <DxSelectBox
                width="200"
                :data-source="resultados_linea"
                :value.sync="linea.resultado"
                display-expr="text"
                value-expr="id"
                field-template="field"
                item-template="item"
              >
                <template #field="{ data }">
                  <!-- <Field :field-data="data"/> -->
                  <div class="d-flex">
                    <img v-if="data" :src="data.img" height="30" class="ps-2 py-1 pe-0" />
                    <DxTextBox :value="data?.text" :read-only="true" />
                  </div>
                </template>
                <template #item="{ data }">
                  <!-- <Item :item-data="data"/> -->
                  <div class="d-flex align-items-center">
                    <img :src="data.img" height="16" />
                    <span class="ps-2">{{ data?.text }}</span>
                  </div>
                </template>

                <DxValidator ref="resultadoValidador">
                  <DxRequiredRule :message="$t('error_resultado_required')" />
                </DxValidator>
              </DxSelectBox>
            </div>
          </div>
          <div class="d-flex flex-column">
            <label class="form-label">{{ $t("vp") }}</label>
            <div class="form-data">
              <DxTextBox :value.sync="linea.vp" width="75"> </DxTextBox>
            </div>
          </div>
          <div class="d-flex flex-column">
            <label class="form-label">{{ $t("condicion") }}</label>
            <div class="form-data">
              <DxTextBox :value.sync="linea.condicion" width="75"> </DxTextBox>
            </div>
          </div>
          <div class="d-flex flex-column">
            <label class="form-label">{{ $t("tolerancia") }}</label>
            <div class="form-data">
              <DxTextBox :value.sync="linea.tolerancia" width="75"> </DxTextBox>
            </div>
          </div>
          <div class="d-flex flex-column">
            <label class="form-label">{{ $t("fecha") }}</label>
            <div class="form-data">
              <DxDateBox :value.sync="linea.fecha" width="150">
                <!-- <DxValidator ref="codigoValidator">
              <DxRequiredRule :message="$t('error_codigo_required')" />
            </DxValidator> -->
              </DxDateBox>
            </div>
          </div>
        </div>

        <div class="d-flex flex-column">
          <label class="form-label">{{ $t("notas") }}</label>
          <div class="form-data">
            <DxTextArea :value.sync="linea.notas"> </DxTextArea>
          </div>
        </div>
      </div>
    </div>

    <div class="content-block">
      <div class="d-flex flex-column">
        <!-- resultadoDisabled: {{ resultadoDisabled }} -->
        <label class="form-label">{{ $t("resultado") }}</label>
        <div class="form-data">
          <!-- <DxTextBox :value.sync="calibracion.resultado">
            <DxValidator ref="codigoValidator">
              <DxRequiredRule :message="$t('error_codigo_required')" />
            </DxValidator>
          </DxTextBox> -->

          <DxSelectBox
            :data-source="resultados"
            :value.sync="calibracion.resultado"
            display-expr="text"
            value-expr="id"
            field-template="field"
            item-template="item"
            :disabled="resultadoDisabled"
          >
            <template #field="{ data }">
              <!-- <Field :field-data="data"/> -->
              <div class="d-flex">
                <img v-if="data" :src="data.img" class="py-2 ps-3 pe-0" />
                <DxTextBox :value="data?.text" :read-only="true" />
              </div>
            </template>
            <template #item="{ data }">
              <!-- <Item :item-data="data"/> -->
              <div class="d-flex align-items-center">
                <img :src="data.img" />
                <span class="ps-2">{{ data?.text }}</span>
              </div>
            </template>

            <DxValidator ref="resultadoValidador">
              <DxRequiredRule :message="$t('error_resultado_required')" />
            </DxValidator>
          </DxSelectBox>
        </div>
      </div>

      <div class="d-flex flex-column">
        <label class="form-label">{{ $t("notas") }}</label>
        <div class="form-data">
          <DxTextArea :value.sync="calibracion.notas"> </DxTextArea>
        </div>
      </div>
    </div>

    <div class="content-block d-flex justify-content-end">
      <DxButton type="default" @click="onClickSaveCalibracion" :text="$t('guardar_calibracion')" />
    </div>
  </div>
</template>

<script>
import { DxButton } from "devextreme-vue/button";
import { DxTextBox } from "devextreme-vue/text-box";
import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";
import DxTextArea from "devextreme-vue/text-area";

import Display from "@/components/core/Display.vue";

import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";

// import {
//   DxDataGrid,
//   DxColumn as DxColumnGrid,
// } from "devextreme-vue/data-grid";

import auth from "@/auth";
import DxDateBox from "devextreme-vue/date-box";
import DxSelectBox from "devextreme-vue/select-box";

export default {
  components: {
    DxButton,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxTextArea,
    Display,
    DxDateBox,
    DxSelectBox,
  },

  data() {
    return {
      componentKey: 0,
      id: Number(this.$route.params.id),

      instrumento: {},
      calibracion: {
        lineas: [],
      },

      resultados: [
        { id: 0, img: "/img/tipos/11.png", text: "nok" },
        { id: 1, img: "/img/tipos/12.png", text: "ok" },
        { id: 2, img: "/img/tipos/13.png", text: "en verificacion" },
      ],

      resultados_linea: [
        { id: 0, img: "/img/tipos/11.png", text: "nok" },
        { id: 1, img: "/img/tipos/12.png", text: "ok" },
        { id: 2, img: "/img/tipos/13.png", text: "excedido por arriba" },
        { id: 3, img: "/img/tipos/14.png", text: "excedido por abajo" },
        {
          id: 4,
          img: "/img/tipos/15.png",
          text: "excedido por arriba y abajo",
        },
      ],

      instrumentoCalibracionesDataSource: new DataSource({
        store: new CustomStore({
          key: "id",
          load: () => this.$fetch.get(`${global.API_URL}/instrumentos/${this.id}/calibraciones`),
          byKey: (key) => this.$fetch.get(`${global.API_URL}/instrumentos/${this.id}/calibraciones/${key}`),
          insert: (values) => this.$fetch.post(`${global.API_URL}/instrumentos/${this.id}/calibraciones`, values),
          update: (key, values) =>
            this.$fetch.put(`${global.API_URL}/instrumentos/${this.id}/calibraciones/${key}`, values),
          remove: (key) => this.$fetch.delete(`${global.API_URL}/instrumentos/${this.id}/calibraciones/${key}`),
        }),
      }),

      instrumentoCaracteristicasCalibracionStore: new CustomStore({
        key: "id",
        load: () => this.$fetch.get(`${global.API_URL}/instrumentos/${this.id}/caracteristicas/calibracion`),
        // byKey: (key) =>
        //   this.$fetch.get(
        //     `${global.API_URL}/instrumentos/${this.id}/caracteristicas/calibracion/${key}`
        //   ),
        insert: (values) =>
          this.$fetch.post(`${global.API_URL}/instrumentos/${this.id}/caracteristicas/calibracion`, values),
        // update: (key, values) =>
        //   this.$fetch.put(
        //     `${global.API_URL}/instrumentos/${this.id}/caracteristicas/calibracion/${key}`,
        //     values
        //   ),
        // remove: (key) =>
        //   this.$fetch.delete(
        //     `${global.API_URL}/instrumentos/${this.id}/caracteristicas/calibracion/${key}`
        //   ),
      }),
    };
  },

  methods: {
    async load() {
      console.log("load!!");
      const urls = [`${global.API_URL}/instrumentos/${this.id}`];
      //obtengo todos los fetch en paralelo
      const responses = await Promise.all(
        urls.map((u) =>
          fetch(u, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + auth.getToken(),
            },
          })
        )
      );
      const data = await Promise.all(responses.map((r) => r.json()));
      this.instrumento = data[0];

      const caracteristicasACalibrar = await this.instrumentoCaracteristicasCalibracionStore.load();

      console.log("caracteristicasACalibrar", caracteristicasACalibrar);

      this.calibracion = {
        id_instrumentos: this.id,
        resultado: null,
        fecha: Date.now(),
        verificado_por: this.$store.state.user.nombre,
        certificado_por: this.$store.state.user.nombre,
        notas: null,
        lineas: caracteristicasACalibrar
          .filter((c) => c.activa)
          .map((c) => {
            return {
              //id_calibraciones: null, // lo pondrá el servidor
              secuencia: c.secuencia,
              caracteristica: c.caracteristica,
              metodo: c.metodo,
              patron: c.patron,
              procedimiento: c.procedimiento,
              valor_pedido: c.valor_pedido,
              valor_teorico: c.valor_teorico,
              valor_obtenido: null,
              resultado: null,
              vp: null,
              condicion: null,
              tolerancia: null,
              fecha: new Date(), // lo pondrá el servidor
              notas: null,
              // dateCreated: null, // lo pondrá el servidor
              // dateModified: null, // lo pondrá el servidor
              // idModifier: null, // lo pondrá el servidor
              // nameModifier: null, // lo pondrá el servidor
              id_instcaracteristicascalib: c.id,
            };
          }),
      };
      console.log(this.calibracion);
    },

    isValid(linea) {
      return (
        linea.valor_obtenido !== null &&
        linea.valor_obtenido !== undefined &&
        linea.valor_obtenido !== "" &&
        linea.resultado !== null &&
        linea.resultado !== undefined &&
        linea.resultado !== "" &&
        linea.vp !== null &&
        linea.vp !== undefined &&
        linea.vp !== "" &&
        linea.condicion !== null &&
        linea.condicion !== undefined &&
        linea.condicion !== "" &&
        linea.tolerancia !== null &&
        linea.tolerancia !== undefined &&
        linea.tolerancia !== ""
      );
    },

    onEditorPreparing(e) {
      console.log("onEditorPreparing", e);
    },

    onInitNewRow(e) {
      console.log("onInitNewRow", e);
      // e.data.familia = false;
      // e.data.situacion = 0;
      // e.data.fecha_emision = new Date();
    },
    onEditingStart(e) {
      console.log("onEditingStart", e);
      // e.data.familia = false;
      // e.data.situacion = 0;
      // e.data.fecha_emision = new Date();
    },

    async onClickSaveCalibracion(e) {
      console.log("onClickSaveCalibracion", e);

      // @TODO Se debería arreglar la calibración para que sea un enpoint en el servidor

      if (!e.validationGroup.validate().isValid) return;

      // const cac = await this.instrumentoCaracteristicasCalibracionStore.load();

      // const result = await this.instrumentoCalibracionesDataSource
      //   .store()
      //   .insert({
      //     id_instrumentos: this.id,
      //     resultado: 1,
      //     fecha: Date.now(),
      //     verificado_por: this.$store.state.user.nombre,
      //     certificado_por: this.$store.state.user.nombre,
      //     lineas: [
      //       {
      //         id_calibraciones: 1,
      //         secuencia: 1,
      //         caracteristica: "caracteristica",
      //         metodo: "metodo",
      //         patron: "patron",
      //         procedimiento: "procedimiento",
      //         valor_pedido: 1,
      //         valor_teorico: 1,
      //         valor_obtenido: 1,
      //         resultado: 1,
      //         vp: 1,
      //         condicion: 1,
      //         tolerancia: 1,
      //         fecha: Date.now(),
      //         notas: "notas",
      //         id_instcaracteristicascalib: 1,
      //       },
      //     ],
      //   });

      //   for (const acac of cac) {
      //     console.log(acac);

      //     if (!acac.activa) continue;

      //     const cl = {
      //       id_calibraciones: result.id,
      //       secuencia: acac.secuencia,
      //       caracteristica: acac.caracteristica,
      //       metodo: acac.metodo,
      //       patron: acac.patron,
      //       procedimiento: acac.procedimiento,
      //       valor_pedido: acac.valor_pedido,
      //       valor_teorico: acac.valor_teorico,
      //       valor_obtenido: result.id,
      //       resultado: getDummyResult(),
      //       vp: result.id,
      //       condicion: result.id,
      //       tolerancia: result.id,
      //       fecha: new Date(),
      //       notas: result.id,
      //       id_instcaracteristicascalib: acac.id,
      //     };

      //     const lineaResult = await instrumentoCalibracionesLineasStore.insert(
      //       cl
      //     );
      //     console.log(lineaResult);
      //   }

      try {
        const result = await this.instrumentoCalibracionesDataSource.store().insert(this.calibracion);

        console.log("result", result);
      } catch (ex) {
        console.log("ex", ex);
        this.$notify(
          this.$t("se_ha_producido_un_error"),
          this.$t("ha_habido_un_error_al_guardar_una_calibración"),
          5000
        );
      }

      this.$router.go(-1);
    },

    onFocusedRowChanged(e) {
      this.focusedCalibracion = e.row.data;
      console.log("fetching this calibracion: ", this?.focusedCalibracion?.id);
      // this.instrumentoCalibracionesLineasDataSource.clearRawDataCache();
      this.instrumentoCalibracionesLineasDataSource.reload();
    },

    onRowDblClick(e) {
      // e.row.data;
      // console.log("fetching this calibracion: ", this?.focusedCalibracion?.id)
      // this.instrumentoCalibracionesLineasDataSource.clearRawDataCache();
      // this.instrumentoCalibracionesLineasDataSource.reload();

      this.$router.push({
        name: "lineas_calibracion",
        params: {
          id_instrumento: this.instrumento.id,
          id: e.key,
        },
      });
    },
  },

  async mounted() {
    // await this.responsablesDataSource.load();
    // await this.load();
    // console.log(this.responsablesDataSource.items());
  },

  async created() {
    // necesito llamar al load porque estoy llamando al endpoint de instrumentos/id a mano..
    // @TODO mirar de hacer un datasource o algo mejor...
    await this.load();
  },

  computed: {
    resultadoDisabled() {
      if (this.calibracion.lineas.length === 0) return true;
      this.calibracion.lineas.forEach((l) => {
        console.log(l.resultado);
      });
      return this.calibracion.lineas.some((l) => l.resultado === null);
    },
    idioma() {
      return this.$i18n.locale;
    },
  },
  watch: {
    idioma() {
      this.componentKey++;
    },
  },
};
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
}

a:hover {
  // border-bottom: 3px solid red;
  text-decoration: underline;
  text-decoration-color: var(--accent);
  text-decoration-thickness: 3px;
}

.caracteristica {
  margin: 1em 0;
  padding: 1em 1em;
  background-color: rgba(125, 125, 125, 0.1);
  border: 1px solid gray;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.caracteristica.success {
  position: relative;
  background-color: rgba(125, 255, 125, 0.05);
  border-color: var(--bs-green);
  border-width: 2px;
}

.caracteristica.success::before {
  content: "✔";
  position: absolute;
  top: 0;
  right: 0;

  font-size: 1em;
  padding: 3px;
}

.caracteristica:has(.dx-invalid) {
  background-color: rgba(255, 125, 125, 0.05);
  border-color: var(--bs-red);
  border-width: 2px;
}
</style>
