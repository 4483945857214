<template>
  <div :key="componentKey">
    <DxPopup :title="$t('Cuidado!')" :visible.sync="isPopupDeleteVisible" width="300px" height="auto">
      <DxScrollView height="300">
        ¿Estás seguro que quieres borrar los siguientes elementos?

        <ul class="list-to-delete">
          <li v-for="key in this.selectedCalibraciones" :key="key">{{ key }}</li>
        </ul>
      </DxScrollView>

      <DxToolbarItem :options="toolbarItemCancelarOptions" widget="dxButton" location="after" toolbar="bottom" />
      <DxToolbarItem :options="toolbarItemConfirmarOptions" widget="dxButton" location="after" toolbar="bottom" />
    </DxPopup>

    <DxPopup
      v-if="attachedID"
      :title="$t('ficherosAdjuntos')"
      :visible.sync="isPopupAttachedVisible"
      :fullScreen="false"
      :animation="{
        show: {
          type: 'pop',
        },
        hide: {
          type: 'pop',
          from: { scale: 1, opacity: 1 },
          to: { scale: 0, opacidy: 0 },
        },
      }"
      @hidden="refreshDataGrid"
    >
      <template>
        <div>
          <AttachedViewer
            ref="attachedViewerGeneral"
            v-if="attachedID && attachedResourceType === 'IMG'"
            :key="attachedID"
            :resourceURI="attachedResourceURI"
            :resourceType="attachedResourceType"
          ></AttachedViewer>

          <AttachedDocumentViewer
            ref="attachedDocumentViewerGeneral"
            v-if="attachedID && attachedResourceType === 'DOC'"
            :key="attachedID"
            :resourceURI="attachedResourceURI"
            :resourceType="attachedResourceType"
          ></AttachedDocumentViewer>
        </div>
      </template>
    </DxPopup>

    <h2 class="content-block">{{ $t("verificaciones") }}</h2>
    <div class="content-block">
      <div class="dx-card">
        <!-- <h1>Añadir toolbar: - imprimir plan (exportar grid) - duplicar plan</h1> -->
        <!-- <code class="d-block m-0 p-3" v-if="$store.state.debug">
          calibraciones seleccionadas: {{ selectedCalibraciones }}
        </code> -->

        <!-- :selection="{ mode: 'multiple' }" -->
        <DxDataGrid
          :ref="gridRefName"
          id="gridCalibraciones"
          class="dx-card wide-card"
          :data-source="dataSourceVerificaciones"
          :hover-state-enabled="true"
          :height="gridHeight"
          width="100%"
          :paging="{
            enabled: true,
          }"
          :allow-column-resizing="true"
          :allow-column-reordering="true"
          @selection-changed="onSelectionChanged"
          @rowDblClick="onRowDblClick"
          @exporting="onExporting"
          :filter-value="filterValue"
        >
          <DxEditingGrid :allow-deleting="$store.state.debug" />
          <DxStateStoring :enabled="true" type="localStorage" storage-key="grid-verificaciones" :savingTimeout="0">
          </DxStateStoring>
          <DxSearchPanel :visible="true" :width="240" />
          <DxFilterRow :visible="true"></DxFilterRow>
          <DxFilterPanel :visible="showFilterPanel"></DxFilterPanel>
          <DxGroupPanel :visible="true" />
          <DxGrouping :auto-expand-all="true" />

          <DxLoadPanel :enabled="true" />

          <!-- Para mostrar todos lo elementos: -->
          <!-- <DxScrolling mode="standard" /> -->
          <!-- <DxScrolling mode="virtual" /> -->
          <DxScrolling mode="standard" />

          <DxExport :enabled="true" :formats="['pdf']" />

          <DxToolbar>
            <DxItem location="before" name="groupPanel"></DxItem>
            <DxItem location="after" name="exportButton"></DxItem>
            <!-- <DxItem
                        location="after" name="delete" template="deleteSelectionTemplate">
                      </DxItem> -->
            <DxItem location="after" name="refresh" template="refreshTemplate"> </DxItem>
            <DxItem location="after" name="showFilterPanel" template="showFilterPanelTemplate"></DxItem>
            <DxItem location="after" name="searchPanel"></DxItem>
          </DxToolbar>

          <DxColumn data-type="number" data-field="id" :caption="$t('numero')" width="80" :allowEditing="false" />

          <!-- <DxColumn data-field="id_instrumentos" data-type="string" :caption="$t('codigo')">
            <DxLookup display-expr="codigo" value-expr="id" :data-source="customStoreInstrumentos"> </DxLookup>
          </DxColumn> -->

          <DxColumn
            data-field="id_instrumentos"
            data-type="string"
            :caption="$t('instrumento')"
            cell-template="instrumentoTemplate"
          >
            <DxLookup
              :allowClearing="true"
              :display-expr="(e) => `${e.codigo} (${e.nombre})`"
              value-expr="id"
              :data-source="customStoreInstrumentos"
              itemTemplate="itemTemplateInstrumentos"
            >
              <template #itemTemplateInstrumentos="{ data }"> aaaa {{ data }} </template>
            </DxLookup>
          </DxColumn>
          <template #instrumentoTemplate="{ data }">
            <div>
              <div>{{ data?.column?.lookup.items.find((i) => i.id === data.value)?.codigo }}</div>
              <!-- <div class="fst-italic fs-smaller text-light text-opacity-75"> -->
              <div class="fst-italic fs-smaller text-secondary">
                {{ data?.column?.lookup.items.find((i) => i.id === data.value)?.nombre }}
              </div>
            </div>
          </template>

          <DxColumn
            data-field="fecha"
            sort-order="desc"
            :caption="$t('fecha')"
            data-type="datetime"
            cell-template="fechaTemplate"
          ></DxColumn>

          <template #fechaTemplate="{ data }">
            <div>
              {{ getFecha(new Date(data.value)) }}
            </div>
          </template>

          <DxColumn
            data-field="resultado"
            :caption="$t('resultado')"
            cell-template="itemTemplateResultado"
            alignment="center"
          >
            <DxLookup
              :data-source="dataSourceResultados"
              display-expr="text"
              value-expr="id"
              :allow-clearing="true"
            ></DxLookup>
          </DxColumn>

          <template #itemTemplateResultado="{ data }">
            <div>
              <img
                :src="`${dataSourceResultados?.find((x) => x.id === data.value)?.img}`"
                :title="dataSourceResultados?.find((x) => x.id === data.value)?.value"
                height="24"
              />
            </div>
          </template>

          <DxColumn data-field="verificado_por" :caption="$t('verificado_por')" data-type="string"></DxColumn>
          <DxColumn data-field="certificado_por" :caption="$t('certificado_por')" data-type="string"></DxColumn>
          <DxColumn data-field="notas" :caption="$t('notas')" data-type="string"></DxColumn>

          <DxColumn
            :allow-editing="false"
            data-field="documentos.length"
            width="50"
            cell-template="documentos"
            header-cell-template="documentos-header"
          >
          </DxColumn>
          <template #documentos-header>
            <span class="pr-1">
              <i class="ion ion-md-attach"></i>
            </span>
          </template>
          <template #documentos="{ data }">
            <ButtonAttachedDocuments
              v-if="!data.row.isEditing"
              @click="
                onAttachedClick({
                  data: data.data,
                  resource: `instrumentos/verificaciones/${data.data.id}`,
                  type: 'DOC',
                })
              "
              :number="data?.data?.documentos?.length"
            />
          </template>

          <DxColumn
            :allow-editing="false"
            data-field="fotos.length"
            cell-template="fotos"
            width="50"
            header-cell-template="fotos-header"
          >
          </DxColumn>
          <template #fotos-header>
            <span class="pr-1">
              <i class="ion ion-md-camera"></i>
            </span>
          </template>
          <template #fotos="{ data }">
            <ButtonAttachedImages
              v-if="!data.row.isEditing"
              @click="
                onAttachedClick({
                  data: data.data,
                  resource: `instrumentos/verificaciones/${data.data.id}`,
                  type: 'IMG',
                })
              "
              :number="data?.data?.fotos?.length"
            />
          </template>

          <template #deleteSelectionTemplate>
            <DxButton
              icon="trash"
              stylingMode="text"
              :disabled="selectedCalibraciones.length === 0"
              @click="deleteSelectedCalibracionesClick"
            ></DxButton>
          </template>

          <template #showSelectionTemplate>
            <DxButton
              icon="fa fa-eye"
              stylingMode="text"
              :disabled="selectedCalibraciones.length === 0"
              @click="showSelectedCalibracionesClick"
            ></DxButton>
          </template>

          <template #hideSelectionTemplate>
            <DxButton
              icon="fa fa-eye-slash"
              stylingMode="text"
              :disabled="selectedCalibraciones.length === 0"
              @click="hideSelectedCalibracionesClick"
            ></DxButton>
          </template>

          <template #refreshTemplate>
            <DxButton icon="refresh" @click="refreshDataGrid"></DxButton>
          </template>

          <template #showFilterPanelTemplate>
            <!-- using type to show a kind of checked button... -->
            <DxButton
              icon="filter"
              :type="showFilterPanel ? 'success' : 'normal'"
              @click="showFilterPanel = !showFilterPanel"
            >
            </DxButton>
          </template>
        </DxDataGrid>
      </div>
    </div>
  </div>
</template>

<script>
// @TODO: Cambiar Calibraciones por Verificaciones

import {
  DxDataGrid,
  DxColumn,
  DxLookup,
  DxScrolling,
  DxLoadPanel,
  DxFilterRow,
  DxSearchPanel,
  DxEditing as DxEditingGrid,
  DxFilterPanel,
  DxGroupPanel,
  DxGrouping,
  DxExport,
  DxToolbar,
  DxItem,
  DxStateStoring,
} from "devextreme-vue/data-grid";

import { DxButton } from "devextreme-vue/button";

import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";

import { DxScrollView } from "devextreme-vue/scroll-view";

import { jsPDF } from "jspdf";
import { exportDataGrid } from "devextreme/pdf_exporter";

// import CustomStore from "devextreme/data/custom_store";

import { CustomStoreInstrumentos } from "../data/instrumentos/InstrumentoDataSource";
import { DataSourceVerificaciones } from "../data/instrumentos/VerificacionesDataSource";

import resultados from "@/data/instrumentos/Resultados.js";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxLookup,
    DxScrolling,
    DxLoadPanel,
    DxFilterRow,
    DxSearchPanel,
    DxEditingGrid,
    DxFilterPanel,
    DxGroupPanel,
    DxGrouping,
    // DxSwitch,
    DxExport,
    DxToolbar,
    DxItem,
    DxButton,
    //
    DxPopup,
    DxToolbarItem,
    DxScrollView,
    DxStateStoring,

    AttachedViewer: () => import("../components/attached-viewer.vue"),
    AttachedDocumentViewer: () => import("../components/attached-document-viewer.vue"),

    ButtonAttachedDocuments: () => import("../components/button-attached-documents.vue"),
    ButtonAttachedImages: () => import("../components/button-attached-images.vue"),
  },

  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },

      // dataSourceInstrumentos: InstrumentoDataSource,
      customStoreInstrumentos: new CustomStoreInstrumentos(),
      dataSourceVerificaciones: new DataSourceVerificaciones(),

      // customStoreInstrumentos: new CustomStore({
      //   key: "id",
      //   load: () => this.$fetch.get(`${global.API_URL}/instrumentos`),
      //   byKey: (key) => this.$fetch.get(`${global.API_URL}/instrumentos/${key}`),
      //   // insert: (values) => this.$fetch.post(`${global.API_URL}/instrumentos/calibraciones`, values),
      //   // update: (key, values) => this.$fetch.put(`${global.API_URL}/instrumentos/calibraciones/${key}`, values),
      //   // remove: (key) => this.$fetch.delete(`${global.API_URL}/instrumentos/calibraciones/${key}`),
      // }),

      // customStoreCalibraciones: new CustomStore({
      //   key: "id",
      //   load: () => this.$fetch.get(`${global.API_URL}/instrumentos/calibraciones`),
      //   byKey: (key) => this.$fetch.get(`${global.API_URL}/instrumentos/calibraciones/${key}`),
      //   // insert: (values) => this.$fetch.post(`${global.API_URL}/instrumentos/calibraciones`, values),
      //   // update: (key, values) => this.$fetch.put(`${global.API_URL}/instrumentos/calibraciones/${key}`, values),
      //   // remove: (key) => this.$fetch.delete(`${global.API_URL}/instrumentos/calibraciones/${key}`),
      // }),

      selectedCalibraciones: [],
      componentKey: 0,
      gridRefName: "gridCalibraciones",

      showFilterPanel: false,
      filterValue: undefined,

      isPopupDeleteVisible: false,

      isPopupAttachedVisible: false,
      attachedID: undefined,
      attachedResourceURI: undefined,
      attachedResourceType: undefined,
    };
  },

  computed: {
    gridHeight() {
      //return window.innerHeight - 300;
      return this.window.height - 300;
    },
    idioma() {
      return this.$i18n.locale;
    },

    dataSourceResultados() {
      return resultados.map((e) => ({
        ...e,
        value: this.$t(e.text),
      }));
    },

    //Necesito que esté en computed para que el idioma lo pueda modificar
    toolbarItemCancelarOptions: function () {
      return {
        text: this.$t("cancelar"),
        type: "normal",
        onClick: () => (this.isPopupDeleteVisible = false),
      };
    },

    //Necesito que esté en computed para que el idioma lo pueda modificar
    toolbarItemConfirmarOptions: function () {
      return {
        text: this.$t("eliminar"),
        stylingMode: "contained",
        type: "danger",
        // no puedo usar useSubmitBehavior porque estoy fuera del form...
        // useSubmitBehavior: true,
        onClick: this.confirmDeleteSelectedCalibracionesClick,
        validationGroup: this.resourceURI,
      };
    },
  },

  methods: {
    onAttachedClick({ data, resource, type }) {
      this.isPopupAttachedVisible = true;
      this.attachedID = data.id;
      this.attachedResourceURI = resource;
      this.attachedResourceType = type;
    },

    onSelectionChanged(e) {
      this.selectedCalibraciones = e.selectedRowKeys;
    },

    onRowDblClick(e) {
      if (e.isNewRow) return;
      if (e.isEditing) return;

      // named route with params to let the router build the url
      this.$router.push({
        name: "verificacion",
        params: {
          id_instrumento: e.data.id_instrumentos,
          id: e.key,
        },
      });
    },

    async load() {},

    getFecha(date) {
      return this.$moment(date).format("L");
    },

    handleResize() {
      // console.log(window.innerWidth, window.innerWidth);
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },

    displayExprCliente(item) {
      if (item.nombre && item.codigo) return `${item.codigo} - ${item.nombre}`;
      if (item.nombre) return item.nombre;
      if (item.codigo) return item.codigo;
    },
    displayExprAgrupacion(item) {
      if (item.nombre && item.codigo) return `${item.codigo} - ${item.nombre}`;
      if (item.nombre) return item.nombre;
      if (item.codigo) return item.codigo;
    },
    async onExporting(e) {
      const doc = new jsPDF();
      await exportDataGrid({
        jsPDFDocument: doc,
        component: e.component,
        indent: 5,
      });
      doc.save("Planes.pdf");
    },

    duplicateControlPlan() {
      this.isPopupDuplicateVisible = true;
    },

    refreshDataGrid() {
      this.$refs[this.gridRefName].instance.refresh();
    },

    async hideSelectedCalibracionesClick() {
      if (this.selectedCalibraciones === undefined) return console.log("Error, no hay calibraciones seleccionado");

      const values = this.selectedCalibraciones; // this.selectedCalibraciones.map(e => e.id);
      await this.$fetch.post(`${global.API_URL}/calibraciones/hide`, { ids: values });
      this.$refs[this.gridRefName].instance.refresh();
    },

    async showSelectedCalibracionesClick() {
      if (this.selectedCalibraciones === undefined) return console.log("Error, no hay calibraciones seleccionado");

      const values = this.selectedCalibraciones; // this.selectedCalibraciones.map(e => e.id);
      await this.$fetch.post(`${global.API_URL}/calibraciones/show`, { ids: values });
      this.$refs[this.gridRefName].instance.refresh();
    },

    deleteSelectedCalibracionesClick() {
      if (this.selectedCalibraciones === undefined) return console.log("Error, no hay calibraciones seleccionado");
      // preguntar si quiere borrar!
      this.isPopupDeleteVisible = true;
    },

    async confirmDeleteSelectedCalibracionesClick() {
      console.log("A borrar!");
      const values = this.selectedCalibraciones; // this.selectedCalibraciones.map(e => e.id);
      try {
        await this.$fetch.post(`${global.API_URL}/calibraciones/remove`, { ids: values });

        this.isPopupDeleteVisible = false;
        this.$refs[this.gridRefName].instance.refresh();
      } catch (ex) {
        console.log("ha ocurrido algo inesperado.", ex);
      }
    },
  },

  created() {
    console.log("created");
    this.load();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  beforeCreate() {},

  mounted() {
    // const now = new Date();
    // const dateWithMonthsSubstracted = new Date(now.setMonth(now.getMonth() - 1))
    // this.filterValue =
    //   [
    //     ["plancontrol_situacion", "=", 2],
    //     "and",
    //     ["calibraciones_fecha", ">", dateWithMonthsSubstracted],
    //     "and",
    //     ["calibraciones_ocultar", "=", false],
    //     "and",
    //     ["calibraciones_rechazo", "=", true],
    //   ];
  },

  watch: {
    idioma() {
      // this.componentKey++;
    },
  },
};
</script>

<style lang="scss">
.list-to-delete {
  padding-top: 3em;
  padding-bottom: 3em;
  // max-height: 300px;
  // overflow-x: auto;
}
</style>
