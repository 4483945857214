<template>
  <div class="content-block">
    <h2>{{ $t("caracteristicas") }}</h2>

    <dx-data-grid
      class="dx-card wide-card"
      :data-source="caracteristicasDataSource"
      :focused-row-index="0"
      :show-borders="false"
      :focused-row-enabled="true"
      :column-auto-width="true"
      :column-hiding-enabled="true"
    >
      <dx-paging :page-size="10" />
      <dx-pager :show-page-size-selector="true" :show-info="true" />
      <dx-filter-row :visible="true" />

      <dx-column data-field="id" caption="id" :width="190" :hiding-priority="8" />

      <dx-column data-field="id_pcestructura" caption="id_pcestructura" :hiding-priority="6" />

      <dx-column data-field="observaciones" :caption="$t('observaciones')" />

      <dx-column data-field="nombre" :caption="$t('nombre')" />

      <dx-column data-field="id_caracteristicastipo" :caption="$t('tipoCaracteristica')">
        <dx-lookup display-expr="codigo" value-expr="id" :data-source="tipoCaracteristicasDataSource" />
      </dx-column>

      <!-- 
      <dx-column data-field="valor_pedido" caption="Valor Pedido" />
      <dx-column data-field="id_condiciones" caption="Condicion" />

      <dx-column data-field="toleracia" caption="Tolerancia" />
      <dx-column data-field="toleracia1" caption="Tolerancia1" />
      <dx-column data-field="toleracia2" caption="Tolerancia2" />

      <dx-column data-field="id_unidades" caption="Unidades" />
      <dx-column data-field="tamano_muestra" caption="Tamaño de la Muestra" />
      <dx-column
        data-field="id_metodoevaluacion"
        caption="Metodo de Evaluacion"
      />
      <dx-column data-field="token_usuariosrol" caption="Roles" />
      <dx-column data-field="reaccion" caption="Reacción" />
      <dx-column data-field="lcsx" caption="LCSX" />
      <dx-column data-field="lcix" caption="LCIX" />
      <dx-column data-field="lcx" caption="LCX" />
      <dx-column data-field="lcr" caption="LCR" />
      <dx-column data-field="lcsr" caption="LCSR" />
      <dx-column data-field="lcir" caption="LCIR" />
      <dx-column data-field="cp" caption="CP" />
      <dx-column data-field="cpk" caption="CPK" />
      <dx-column data-field="id_frecuencias" caption="Frecuencia" /> -->

      <dx-column data-field="fecha_inicio" :caption="$t('fechaInicio')" data-type="date" :hiding-priority="3" />

      <dx-column data-field="fecha_fin" :caption="$t('fechaFin')" data-type="date" :hiding-priority="4" />

      <dx-column data-field="pp" caption="PP" />
      <dx-column data-field="ppk" caption="PPK" />
      <dx-column data-field="linea" :caption="$t('linea')" />
      <dx-column data-field="protocolo" :caption="$t('protocolo')" />
      <dx-column data-field="id_calibres" :caption="$t('calibre')" />
      <dx-column data-field="calibre" :caption="$t('calibre')" />
      <dx-column data-field="id_amfelin" :caption="$t('amfe')" />
      <dx-column data-field="operacion" :caption="$t('operacion')" />
      <dx-column data-field="analisis" :caption="$t('analisis')" />
      <dx-column data-field="id_nca" :caption="$t('nca')" />

      <dx-column data-field="dateCreated" caption="(internal) Fecha de creación" />
      <dx-column data-field="dateModified" caption="(internal) Fecha de modificación" />
      <dx-column data-field="idModifier" caption="(internal) id" />
      <dx-column data-field="nameModifier" caption="(internal) nombre" />

      <!-- <dx-column
        data-field="Task_Priority"
        caption="Priority"
        :hiding-priority="5"
      >
        <dx-lookup
          display-expr="name"
          value-expr="value"
          :data-source="priorities"
        />
      </dx-column> -->

      <!-- <dx-column
        data-field="ResponsibleEmployee.Employee_Full_Name"
        caption="Assigned To"
        :allow-sorting="false"
        :hiding-priority="7"
      /> -->
    </dx-data-grid>
  </div>
</template>

<script>
//@TODO: https://js.devexpress.com/Documentation/Guide/Data_Binding/Data_Source_Examples/#Custom_Sources/Connect_to_RESTful_Service
//@TODO: Consultar ¿OData? https://github.com/DevExpress-Examples/XPO_how-to-implement-odata4-service-with-xpo
//import "devextreme/data/odata/store";
// import CustomStore from "devextreme/data/custom_store";

import { DxDataGrid, DxColumn, DxFilterRow, DxLookup, DxPager, DxPaging } from "devextreme-vue/data-grid";
import { DataSourceCaracteristicas } from "../data/caracteristicas/CaracteristicasDataSource";
import { DataSourceCaracteristicasTipo } from "../data/caracteristicas/CaracteristicasTipoDataSource";

// import auth from "../auth";

// import Devexpress from "devextreme";

// function handleErrors(response) {
//   if (!response.ok) {
//     throw Error(response.statusText);
//   }
//   return response;
// }

// const caracteristicasDataSource = new CustomStore({
//   key: "id",
//   // loadMode: "raw", // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
//   load: () => {
//     return fetch(`${global.API_URL}/caracteristicas`, {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + auth.getToken(),
//       },
//     })
//       .then(handleErrors)
//       .then((response) => response.json())
//       .catch(() => {
//         throw "Network error";
//       });
//   },
// });

// const tipoCaracteristicasDataSource = new CustomStore({
//   key: "id",
//   // loadMode: "raw", // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
//   load: () => {
//     return fetch(`${global.API_URL}/caracteristicas/tipos`, {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + auth.getToken(),
//       },
//     })
//       .then(handleErrors)
//       .then((response) => response.json())
//       .catch(() => {
//         throw "Network error";
//       });
//   },
// });

export default {
  data() {
    return {
      caracteristicasDataSource: new DataSourceCaracteristicas(),
      tipoCaracteristicasDataSource: new DataSourceCaracteristicasTipo(),
    };
  },
  methods: {
    refresh() {
      // console.log("refresh!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
    },
  },
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxLookup,
    DxPager,
    DxPaging,
  },
};
</script>

<style scoped>
.dx-rtl .dx-datagrid-rowsview .dx-selection.dx-row > td:not(.dx-focused).dx-datagrid-group-space,
.dx-rtl .dx-datagrid-rowsview .dx-selection.dx-row:hover > td:not(.dx-focused).dx-datagrid-group-space {
  border-left-color: #bf4e6a;
}

.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-lines) > td,
.dx-datagrid-rowsview .dx-selection.dx-row:hover:not(.dx-row-lines) > td {
  border-bottom: 1px solid #bf4e6a;
  border-top: 1px solid #bf4e6a;
}

.dx-datagrid-rowsview .dx-selection.dx-row > td.dx-datagrid-group-space,
.dx-datagrid-rowsview .dx-selection.dx-row:hover > td.dx-datagrid-group-space {
  border-right-color: #bf4e6a;
}

.dx-datagrid-rowsview .dx-selection.dx-row > td,
.dx-datagrid-rowsview .dx-selection.dx-row:hover > td {
  background-color: #bf4e6a;
  color: #232323;
}

.dx-datagrid-table
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)
  > td:not(.dx-focused) {
  background-color: #bf4e6a;
  color: #333;
}
</style>
