import { get, post, put, remove } from "@/core/fetch";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

export class CustomStoreCalibracionesInstrumento extends CustomStore {
  constructor(id_instrumento) {
    const baseUrl = `${global.API_URL}/instrumentos/${id_instrumento}/calibraciones`;

    super({
      key: "id",
      loadMode: "raw",
      // cacheRawData: false,
      load: async () => {
        const result = await get(`${baseUrl}`);
        return desnullify(result);
      },
      // byKey: (key) => get(`${baseUrl}/${key}`),
      insert: (values) => post(`${baseUrl}`, values),
      update: (key, values) => put(`${baseUrl}/${key}`, values),
      remove: (key) => remove(`${baseUrl}/${key}`),
      onLoaded: desnullify,
    });
  }
}

/*
Hay un problema con el lookup...
Cuando es nulo el resultado no puedo filtrar por este,
ya que considera como filtro borrado y muestra todos los resultados.
Hacemos un postProceso de los datos y reemplazamos los nulos por -1000
así podemos pedir filtrar por un -1000 y mostrar los registros
con resultado "null"
*/
const desnullify = (data) => {
  return data.map((item) => ({
    ...item,
    resultado: item.resultado ?? -1000,
  }));
};

export class DataSourceCalibracionesInstrumento extends DataSource {
  constructor(id_instrumento) {
    const store = new CustomStoreCalibracionesInstrumento(id_instrumento);
    super({
      store,
    });
  }
}
