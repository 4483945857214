<template>
  <div>
    <div>
      <dx-data-grid
        ref="grid"
        id="gridCaracteristicas"
        class="dx-card wide-card"
        :data-source="caracteristicasDataSource"
        keyExpr="id"
        :hover-state-enabled="true"
        :focused-row-enabled="false"
        :paging="{
          enabled: false,
        }"
        :show-borders="false"
        @rowDblClick="
          (event) => {
            this.$emit('rowDblClick');
          }
        "
        :selection="{ mode: 'single' }"
        @selection-changed="onSelectionChanged"
        :noDataText="$t('noHayPautaControl')"
        :allow-column-resizing="true"
        column-resizing-mode="nextColumn"
        @exporting="onExporting"
      >
        <DxStateStoring :enabled="true" storage-key="grid-caracteristicas-autocontrol"> </DxStateStoring>
        <DxExport :enabled="true" :formats="['xlsx']" />
        <dx-filter-row :visible="true" />
        <dx-header-filter :visible="true" />

        <dx-column data-field="linea" :caption="$t('linea')" :width="60" :allowFiltering="false" sort-order="asc" />

        <dx-column data-field="id" :caption="$t('numero')" :width="60" :allowFiltering="false" />

        <!-- <dx-column
        data-field="id_pcestructura"
        caption="id_pcestructura"
        :hiding-priority="6"
      /> -->

        <dx-column data-field="nombre" :caption="$t('caracteristica')" :allowFiltering="false" />

        <dx-column data-field="id_metodoanalisis" :caption="$t('metodoAnalisis')">
          <dx-lookup display-expr="codigo" value-expr="id" :data-source="metodoAnalisisCustomStore"> </dx-lookup>
        </dx-column>

        <dx-column :encodeHtml="false" data-field="especificacion" :caption="$t('especificacionProducto')" />

        <dx-column data-field="id_metodoevaluacion" :caption="$t('metodoEvaluacion')">
          <dx-lookup display-expr="descripcion" value-expr="id" :data-source="metodoEvaluacionCustomStore"> </dx-lookup>
        </dx-column>

        <dx-column :width="70" data-field="tamano_muestra" :caption="$t('tamano') + '/' + $t('recorridoMovil')" />

        <dx-column data-field="id_frecuencias" :caption="$t('frecuencia')">
          <dx-lookup display-expr="descripcion" value-expr="id" :data-source="frecuenciaCustomStore"> </dx-lookup>
        </dx-column>

        <dx-column data-field="id_instrumentos" :caption="$t('calibre')">
          <dx-lookup display-expr="codigo" value-expr="id" :data-source="instrumentosCustomStore"> </dx-lookup>
        </dx-column>

        <dx-column data-field="token_usuariosrol" :caption="$t('rol')" cell-template="rolTemplate">
          <!-- <dx-lookup display-expr="codigo" value-expr="id" :data-source="roles">
        </dx-lookup> -->
          <DxLookup :dataSource="customStoreRoles" :display-expr="(a) => [a?.codigo, a?.descripcion]" value-expr="id" />
        </dx-column>

        <dx-column data-field="reaccion" :caption="$t('reaccion')" tooltip="lorem ipsum sit amet">
          <!-- <dx-lookup
          display-expr="texto"
          value-expr="id"
          :data-source="reaccionesDataSource"
        >
        </dx-lookup> -->
        </dx-column>

        <dx-column
          data-field="fechaUltimoAnalisis"
          :caption="$t('fechaUltimoAnalisis')"
          cell-template="fechaTemplate"
        />

        <template #rolTemplate="{ data: templateOptions }">
          <div class="tag-container text-wrap gap-1 d-flex flex-wrap">
            <div
              v-for="role in templateOptions.value"
              :key="role.id"
              class="badge text-bg-secondary"
              :title="templateOptions.column.lookup.calculateCellValue(role, true)[1]"
            >
              {{ templateOptions.column.lookup.calculateCellValue(role, true)[0] }}
            </div>
          </div>
        </template>

        <template #fechaTemplate="{ data }">
          <!-- <div>{{ moment(data).fromNow() }} <br /></div> -->
          <div
            v-if="data.value"
            :title="`${getFecha(new Date(data.value))}
${getHora(new Date(data.value))}
${getTime(new Date(data.value))}`"
          >
            {{ getFecha(new Date(data.value)) }} <br />
            {{ getHora(new Date(data.value)) }}
          </div>
        </template>
      </dx-data-grid>
    </div>
  </div>
</template>

<script>
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import {
  DxDataGrid,
  DxColumn,
  DxExport,
  DxFilterRow,
  DxHeaderFilter,
  DxLookup,
  DxStateStoring,
} from "devextreme-vue/data-grid";

import { RolesCustomStore } from "@/data/usuarios/RolesDataSource";
import { MetodosAnalisisCustomStore } from "../../data/caracteristicas/MetodosAnalisisDataSource";
import { MetodosEvaluacionCustomStore } from "../../data/caracteristicas/MetodosEvaluacionDataSource";
import { FrecuenciasCustomStore } from "../../data/caracteristicas/FrecuenciasDataSource";
import { CustomStoreInstrumentos } from "../../data/instrumentos/InstrumentoDataSource";
import { DataSourceCaracteristicas } from "../../data/caracteristicas/CaracteristicasDataSource";

const tipo = {};

export default {
  props: ["estructuraIds"],
  data() {
    return {
      tipo,
      metodoAnalisisCustomStore: new MetodosAnalisisCustomStore(),
      metodoEvaluacionCustomStore: new MetodosEvaluacionCustomStore(),
      frecuenciaCustomStore: new FrecuenciasCustomStore(),
      instrumentosCustomStore: new CustomStoreInstrumentos(),
      caracteristicasDataSource: {},

      popupShow: false,
      popupTarget: "",
      popupContent: "()",

      customStoreRoles: new RolesCustomStore(),
    };
  },

  components: {
    DxDataGrid,
    DxColumn,
    DxExport,
    DxFilterRow,
    DxHeaderFilter,
    DxLookup,
    DxStateStoring,
  },
  methods: {
    onSelectionChanged(e) {
      this.$emit("update:selectedRow", e.selectedRowsData[0]);
    },

    async refresh() {
      this.$refs.grid.instance.refresh();
    },

    getTime(date) {
      return this.$moment(date).fromNow();
    },

    getFecha(date) {
      return this.$moment(date).format("L");
    },

    getHora(date) {
      return this.$moment(date).format("LTS");
    },

    async onExporting(e) {
      e.cancel = true;

      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(this.$t("pautaControlRPT"));

      const roles = await this.customStoreRoles.load();

      await exportDataGridToExcel({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true,
        topLeftCell: { row: 2, column: 2 },
        customizeCell: async function (options) {
          const { excelCell, gridCell } = options;

          if (gridCell.rowType === "data" && gridCell.column.dataField === "fechaUltimoAnalisis") {
            excelCell.value = gridCell.value ? new Date(gridCell.value) : null;
            excelCell.numFmt = 'dd/mm/yyyy \n "\r\n" hh:mm:ss';

            excelCell.alignment = {
              horizontal: "right",
              vertical: "top",
              wrapText: true,
            };
          }

          if (gridCell.rowType === "data" && gridCell.column.dataField === "especificacion") {
            console.log("especificacion", gridCell);
            console.log("especificacion", excelCell);

            excelCell.value = excelCell.value
              .replace("<br>", "\n")
              .replace('<span class="tolerancia">', "")
              .replace("</span>", "");

            excelCell.alignment.wrapText = true;
            excelCell.alignment.horizontal = "right";
          }

          if (gridCell.rowType === "data" && gridCell.column.dataField === "token_usuariosrol") {
            excelCell.alignment.wrapText = true;
            excelCell.value = gridCell.value.map((a) => roles.find((w) => w.id == a)?.codigo).join("\n");
          }
        },
      });

      const buffer = await workbook.xlsx.writeBuffer();

      saveAs(new Blob([buffer], { type: "application/octet-stream" }), this.$t("pautaControlRPT") + ".xlsx");
    },
  },

  watch: {
    estructuraIds: {
      immediate: true,
      handler: async function (val) {
        if (val.length === 0) return (this.caracteristicasDataSource = {});

        this.caracteristicasDataSource = new DataSourceCaracteristicas({ idEstructuraList: val });
      },
    },
  },
};
</script>

<style scoped>
#gridCaracteristicas {
  max-height: 400px;
}
</style>
