<template>
  <div>
    <DxButton
      v-if="isButtonVisible"
      class="danger"
      icon="trash"
      :text="text"
      type="danger"
      styling-mode="contained"
      @click="onClickDangerButton"
    />

    <DxPopup
      :title="titulo"
      :visible.sync="isPopupVisible"
      width="80%"
      height="auto"
      @shown="onShown"
      :showTitle="true"
    >
      <template>
        <slot></slot>

        <h6 class="pl-1 pt-3 pb-2">
          {{ $t("escribe") }} “<span class="a-copiar">{{ textToConfirm?.toUpperCase() }}</span
          >” {{ $t("paraBorrarDefinitivamente") }}
        </h6>
        <DxValidationGroup ref="validationGroup" name="vprompt">
          <DxTextBox ref="respuesta" v-model="respuesta" :placeholder="pregunta" @key-up="onKeyUp">
            <DxValidator ref="respuestaValidator">
              <DxCompareRule
                :message="$t('errorValorIncorrecto')"
                :comparisonTarget="() => textToConfirm?.toUpperCase()"
              />
              <DxRequiredRule :message="$t('errorValorRequerido')" />
            </DxValidator>
          </DxTextBox>
        </DxValidationGroup>
      </template>

      <DxToolbarItem :options="toolbarItemCancelarOptions" widget="dxButton" location="after" toolbar="bottom" />

      <DxToolbarItem :options="toolbarItemGuardarOptions" widget="dxButton" location="after" toolbar="bottom" />

      <!-- <DxToolbarItem
        :options="{
          text: this.$t('cancelar'),
          type: 'normal',
          onClick: this.onClickCancelar,
        }"
        widget="dxButton"
        location="after"
        toolbar="bottom"
      /> -->

      <!-- <DxToolbarItem
        :options="{
          text: this.$t('guardar'),
          stylingMode: 'contained',
          // useSubmitBehavior: true,
          type: 'default',
          onClick: this.onClickAceptar,
        }"
        widget="dxButton"
        location="after"
        toolbar="bottom"
      /> -->
    </DxPopup>
  </div>
</template>

<script>
import DxTextBox from "devextreme-vue/text-box";
import { DxValidator, DxRequiredRule, DxCompareRule } from "devextreme-vue/validator";
import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";
import { DxValidationGroup } from "devextreme-vue/validation-group";

import DxButton from "devextreme-vue/button";

export default {
  props: {
    text: {
      type: String,
      default: "Eliminar",
    },
    textToConfirm: {
      type: String,
    },
    dataType: {
      type: String,
      default: "el registro",
    },
    isButtonVisible: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      componentKey: 0,
      isPopupVisible: false,
      titulo: "",
      pregunta: "",
      respuesta: "",
    };
  },

  components: {
    DxButton,
    DxPopup,
    DxToolbarItem,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxCompareRule,
    DxValidationGroup,
  },
  methods: {
    onClickDangerButton() {
      console.log("onClickDangerButton");
      this.isPopupVisible = true;
      this.titulo = `¿Estas seguro que quieres borrar ${this.textToConfirm}?`;
      // this.pregunta = `Escribe <code>${this.textToConfirm.toUpperCase()}</code> para borrar definitivamente el registro`;
      this.respuesta = "";
      // try {
      //   const resultado = await this.$refs.prompt.prompt(
      //     `¿Estas seguro que quieres borrar ${this.textToConfirm}?`,
      //     `Escribe ${this.textToConfirm.toUpperCase()} para borrar definitivamente el registro`,
      //     ""
      //   );

      //   if (resultado === this.textToConfirm.toUpperCase()) {
      //     this.$emit("confirmed");
      //   } else {
      //     this.$refs.prompt.showInputError();
      //   }
      // } catch (error) {
      //   // console.log("error");
      // }
    },

    onShown() {
      this.$refs.respuesta.$el.querySelector("input").select();
    },

    onKeyUp(e) {
      e.event.keyCode === 13 && this.onClickAceptar(e);
      e.event.keyCode === 27 && this.onClickCancelar(e);
    },

    onClickCancelar() {
      // this.reject();
      this.isPopupVisible = false;

      // this.$refs.respuestaValidator.instance.reset();
      // this.$refs.respuesta.instance.reset();
    },

    showInputError() {
      this.$refs.respuesta.instance.isValid = false;
    },

    onClickAceptar() {
      const validationGroup = this.$refs.validationGroup.instance;

      if (!validationGroup.validate().isValid) return;

      this.isPopupVisible = false;

      this.$refs.respuesta.instance.reset();
      this.$refs.respuestaValidator.instance.reset();

      this.$emit("confirmed");
    },
  },

  computed: {
    toolbarItemCancelarOptions: function () {
      return {
        text: this.$t("cancelar"),
        type: "normal",
        onClick: this.onClickCancelar,
      };
    },

    toolbarItemGuardarOptions: function () {
      return {
        text: this.$t("borrar"),
        stylingMode: "contained",
        type: "danger",
        onClick: this.onClickAceptar,
      };
    },
    idioma() {
      // return this.$store.state.idioma;
      return this.$i18n.locale;
    },
  },
};
</script>

<style>
.a-copiar {
  color: black;
  white-space: pre-wrap;
  font-weight: bold;
  user-select: auto;
}
.dx-swatch-additional .a-copiar {
  color: white;
}
</style>
