<template>
  <div id="reportVAR">
    <div class="reportContent">
      <h2>{{ $t("graficoPorAtributos") }}: {{ $t("multiplesDefectos") }}</h2>

      <div class="d-flex flex-row pt-2 pb-4" style="gap: 50px">
        <div class="d-flex flex-column plan-control">
          <label class="form-label fw-bold">{{ $t("planControl") }}</label>

          <label class="form-label"
            >{{ $t("referencia") }} / {{ $t("pieza") }} /
            {{ $t("fase") }}</label
          >
          <label>
            {{ info.plan.referencia }} / {{ info.plan.nombre }} /
            {{ info.estructura.descripcion }}
          </label>
        </div>

        <div class="d-flex flex-column caracteristica">
          <label class="form-label fw-bold">{{ $t("caracteristica") }}</label>

          <label class="form-label"
            >{{ $t("numero") }} / {{ $t("nombre") }} /
            {{ $t("especificacion") }}
          </label>

          <div class="form-data">
            {{ info.caracteristica.id }} / {{ info.caracteristica.nombre }} /
            <div
              style="display: inline-block"
              v-html="info.caracteristica.especificacion"
            ></div>
          </div>
        </div>
      </div>

      <br />
      <br />
      <img :src="dataImageX" width="100%" @load="ImageXLoad" />
      <br />
      <br />

      <table width="100%" border="1px solid black">
        <thead>
          <tr>
            <th>N</th>
            <th>{{ $t("fechaHora") }}</th>
            <th>{{ $t("lote") }}</th>
            <th>{{ $t("defectos") }}(c)</th>
            <th>{{ $t("tamanoMuestra") }}</th>
            <th>{{ $t("def") }}/{{ $t("tm") }}(u)</th>
            <th>{{ $t("defectos") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(punto, i) in info.analisis" :key="i">
            <td>{{ i + 1 }}</td>
            <td>
              {{ $moment(punto.fecha).format("L") }}
              {{ $moment(punto.fecha).format("LTS") }}
            </td>
            <td>{{ punto.lote }}</td>
            <td>{{ punto.total_defectuosas }}</td>
            <td>{{ punto.tamano_muestra }}</td>
            <td>
              {{ (punto.total_defectuosas / punto.tamano_muestra).toFixed(2) }}
            </td>
            <td>
              <table>
                <tbody>
                  <tr
                    v-for="(def, i) in info.valoresMdef.filter(
                      (x) => x.id_analisis === punto.id
                    )"
                    :key="i"
                  >
                    <td>{{ def.nombre_defecto }}</td>
                    <td>{{ def.cantidad }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import jspdf from "jspdf";
import html2canvas from "html2canvas";

export default {
  props: ["info", "metodoAnalisis"],

  data() {
    return {
      dataImageX: null,
    };
  },

  methods: {
    //   addFooters: function(doc) {
    //   const pageCount = doc.internal.getNumberOfPages();
    //   for (var i = 1; i <= pageCount; i++) {
    //     doc.setPage(i)
    //     doc.text('Page ' + String(i) + ' of ' + String(pageCount), doc.internal.pageSize.width / 2, 287, {
    //       align: 'center'
    //     })
    //   }
    //  },

    print: async function (el) {
      const canvas = await html2canvas(el);
      this.dataImageX = canvas.toDataURL();
    },

    ImageXLoad: function () {
      const doc = new jspdf({
        unit: "px",
        hotfixes: ["px_scaling"],
        format: "a4",
        pageWidth: "750",
      });

      doc.html(this.$el.innerHTML, {
        pagesplit: true,
        margin: [30, 10, 10, 30],
        heigth: 750,
        useFor: "page",
        callback: function (doc) {
          //doc.save();
          window.open(doc.output("bloburl"), "_blank");
        },
      });
    },
  },
};
</script>


<style>
.reportContent {
  width: 19cm;
  padding: 0.5cm;
  margin: 1cm;
  box-sizing: border-box;
}
</style>