<template>
  <header class="header-component">
    <dx-toolbar class="header-toolbar">
      <dx-item :visible="menuToggleEnabled" location="before" css-class="menu-button">
        <template #default>
          <dx-button icon="menu" styling-mode="text" @click="toggleMenuFunc" />
        </template>
      </dx-item>

      <dx-item v-if="title" location="before" css-class="header-title dx-toolbar-label">
        <template>
          <div>{{ title }}</div>
        </template>
      </dx-item>

      <dx-item location="after" locate-in-menu="auto">
        <template #default>
          <div>
            <dx-button
              class="moon-button"
              styling-mode="text"
              @click="toggleDark"
              :icon="isDark ? 'fa fa-sun-o' : 'fa fa-moon-o'"
            >
            </dx-button>
          </div>
        </template>
      </dx-item>

      <dx-item location="after" locate-in-menu="auto" menu-item-template="menuLanguageItem">
        <template #default>
          <div>
            <dx-button class="lang-button" styling-mode="text">
              <lang-panel menu-mode="context" />
            </dx-button>
          </div>
        </template>
      </dx-item>

      <dx-item location="after" locate-in-menu="auto" menu-item-template="menuUserItem">
        <template #default>
          <div>
            <dx-button class="user-button authorization" height="100%" styling-mode="text">
              <user-panel :user="user" :menu-items="userMenuItems" menu-mode="context" />
            </dx-button>
          </div>
        </template>
      </dx-item>

      <template #menuUserItem>
        <user-panel :user="user" :menu-items="userMenuItems" menu-mode="list" />
      </template>

      <template #menuLanguageItem>
        <lang-panel menu-mode="list" />
      </template>
    </dx-toolbar>
  </header>
</template>

<script>
import { useDark, useToggle } from "@vueuse/core";

import DxButton from "devextreme-vue/button";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import auth from "../auth";

import UserPanel from "./user-panel";
import LangPanel from "./lang-panel";

export default {
  setup() {
    const isDark = useDark({
      // selector: ["#root"],
      // attribute: "class",
      // valueDark: "dx-swatch-additional",
      // valueLight: "",
      onChanged(isDark) {
        // console.log("onChanged::isDark: ", isDark);
        document?.querySelector("html")?.classList.toggle("dx-swatch-additional", isDark);
        document?.querySelector("#root")?.classList.toggle("dx-swatch-additional", isDark);

        // bootstrap 5.3 needs bsTheme?!
        console.log("onChanged::isDark: ", isDark, document.querySelector("html").dataset["bsTheme"]);
        document.querySelector("html").dataset["bsTheme"] = isDark ? "dark" : "light";
      },
    });

    return { isDark, toggleDark: useToggle(isDark) };
  },

  props: {
    menuToggleEnabled: Boolean,
    title: String,
    toggleMenuFunc: Function,
    logOutFunc: Function,
  },
  created() {
    this.user = auth?.user;
  },
  data() {
    return {
      user: {},
    };
  },
  computed: {
    userMenuItems: function () {
      return [
        {
          text: this.$t("perfil"),
          icon: "user",
          onClick: this.onProfileClick,
        },
        {
          text: this.$t("logout"),
          icon: "runner",
          onClick: this.onLogoutClick,
        },
      ];
    },
  },
  methods: {
    onProfileClick() {
      console.log("click boom");
      this.$router.push({ name: "profile" });
    },
    onLogoutClick() {
      auth.logOut();
      this.$router.push({
        path: "/login-form",
        // query: { redirect: this.$route.path },
      });
    },
  },
  components: {
    DxButton,
    DxToolbar,
    DxItem,
    UserPanel,
    LangPanel,
  },
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";
@import "../dx-styles.scss";

:root {
  // --accent: #cddc39;
  // --accent: $base-accent;
  --accent: #ff5722;
}

.header-component {
  flex: 0 0 auto;
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
    // color: $base-accent;
    color: var(--accent);
  }
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 40px;

  .screen-x-small & {
    padding: 0 20px;
  }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: $side-panel-min-width;
  text-align: center;
  padding: 0;
}

.header-title .dx-item-content {
  padding: 0;
  margin: 0;
}

.dx-theme-generic {
  .dx-toolbar {
    padding: 10px 0;
  }

  .user-button > .dx-button-content {
    padding: 3px;
  }
}
</style>
