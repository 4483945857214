<template>
  <div class="w-100 d-flex flex-column gap-4 p-3 border rounded">
    <h3>{{ nombreMetodoAnalisis }}</h3>
    <div class="d-flex flex-column w-25">
      <DictionaryEditor
        :label="$t('valorPedido')"
        :title="$t('diccValores')"
        v-model="caracteristicaComputed.valor_pedido"
        value-expr="texto"
        recurso="caracteristicas/valores/diccionario"
      >
        <DxValidator>
          <DxRequiredRule :message="$t('errorValorPedidoRequerido')" />
        </DxValidator>
      </DictionaryEditor>
    </div>
  </div>
</template>

<script>
import DictionaryEditor from "@/components/core/DictionaryEditor.vue";
import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";

import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";

export default {
  components: {
    DictionaryEditor,
    DxValidator,
    DxRequiredRule,
  },
  props: ["caracteristica"],
  data() {
    return {
      metodoAnalisisDataSource: new DataSource({
        paginate: true,
        store: new CustomStore({
          key: "id",
          loadMode: "raw",
          load: () => this.$fetch.get(`${global.API_URL}/caracteristicas/metodoanalisis`),
          // byKey: (key) => this.$fetch.get(`${global.API_URL}/caracteristicas/metodoanalisis/${key}`),
          // insert: (values) => this.$fetch.post(`${global.API_URL}/caracteristicas/metodoanalisis`, values),
          // update: (key, values) => this.$fetch.put(`${global.API_URL}/caracteristicas/metodoanalisis/${key}`, values),
          // remove: (key) => this.$fetch.delete(`${global.API_URL}/caracteristicas/metodoanalisis/${key}`),
        }),
      }),
    };
  },

  mounted() {
    this.metodoAnalisisDataSource.load();
  },

  computed: {
    caracteristicaComputed: {
      get() {
        return this.caracteristica;
      },
      set(value) {
        console.log("caracteristicaComputed:set happened");
        this.$emit("update:caracteristica", value);
      },
    },

    nombreMetodoAnalisis: {
      get() {
        const nombre = this.metodoAnalisisDataSource
          .items()
          .find((item) => item.id === this.caracteristica.id_metodoanalisis)?.codigo;
        return nombre;
      },
    },
  },
};
</script>
