import * as crud from "../core/fetch";

export const createCalibracion = async (initValues) => {
  const url = `${global.API_URL}/instrumentos/${initValues.id_instrumentos}/calibraciones`;
  const response = await crud.post(url, initValues);
  return response;
};

export const copyCalibracion = async (initValues, fromInstrumento, toInstrumento) => {
  const url = `${global.API_URL}/instrumentos/${toInstrumento}/calibraciones`;
  const response = await crud.post(url, { data: initValues, fromInstrumento, toInstrumento });
  return response;
};
