<template>
  <div>
    <h3 class="d-flex justify-content-between">
      {{ $t("equipo") }}
      <DxButton
        icon="add"
        type="default"
        stylingMode="contained"
        :hint="$t('agregar_equipo')"
        @click="() => this.$refs.gridEquipo.instance.addRow()"
        class="mx-3"
      />
    </h3>

    <DxDataGrid
      ref="gridEquipo"
      id="gridEquipo"
      class="dx-card wide-card"
      :data-source="equiposDataSource"
      keyExpr="id"
      :hover-state-enabled="true"
      :noDataText="$t('noHayEquipo')"
      :repaintChangesOnly="true"
    >
      <DxEditingGrid :allow-updating="true" :allow-adding="false" :allow-deleting="true" mode="row" />

      <DxColumnGrid
        data-field="id_responsables"
        :caption="$t('responsable')"
        edit-cell-template="responsableEditor"
        :setCellValue="setCellValueResponsable"
        cell-template="responsable"
      >
        <DxRequiredRule />
        <DxLookup display-expr="nombre" value-expr="id" :data-source="responsablesStore"> </DxLookup>
      </DxColumnGrid>

      <template #responsable="{ data }">
        {{ nombre_responsable(data.data) }}
      </template>

      <template #responsableEditor="{ data: cellInfo }">
        <div>
          <SelectBoxCatalogEditor
            :title="$t('catalogoResponsables')"
            v-model="cellInfo.value"
            :search-expr="['nombre']"
            :display-expr="(item) => (item ? `${item.nombre}` : '')"
            :popupWidth="900"
            :columnas="{
              nombre: { caption: $t('nombre'), width: 200 },
              telefono: { caption: $t('telefono'), width: 170 },
              email: { caption: $t('eMail'), width: 170 },
              funcion: { caption: $t('funcion'), width: 200 },
            }"
            value-expr="id"
            recurso="responsables"
            @input="(e) => cellInfo.setValue(e)"
            @dataSourceUpdated="() => responsablesStore.load()"
          >
            <template #item="{ data }">
              <div class="responsable-item">
                <span v-if="data.nombre">{{ data.nombre }}</span>
                <i v-else>{{ $t("sinNombre") }}</i>
              </div>
            </template>
          </SelectBoxCatalogEditor>
        </div>
      </template>

      <DxColumnGrid data-field="funcion" :caption="$t('funcion')"></DxColumnGrid>
      <DxColumnGrid :calculate-cell-value="(rowData) => email_responsable(rowData)" :caption="$t('eMail')">
      </DxColumnGrid>
      <DxColumnGrid :calculate-cell-value="(rowData) => telefono_responsable(rowData)" :caption="$t('telefono')">
      </DxColumnGrid>

      <!-- <template #email_responsable="{ data }">
          {{ email_responsable(data) }}
        </template> -->
      <!-- <template #telefono_responsable="{ data }">
          {{ telefono_responsable(data) }}
        </template> -->
    </DxDataGrid>
  </div>
</template>

<script>
import { DxButton } from "devextreme-vue/button";
import { DxDataGrid } from "devextreme-vue/data-grid";
import { DxColumn as DxColumnGrid } from "devextreme-vue/data-grid";
import { DxEditing as DxEditingGrid } from "devextreme-vue/data-grid";
import { DxLookup } from "devextreme-vue/data-grid";
import { DxRequiredRule } from "devextreme-vue/validator";

import SelectBoxCatalogEditor from "@/components/core/SelectBoxCatalogEditor.vue";

import { EquiposDataSource } from "@/data/plancontrol/EquiposDataSource.js";
import { ResponsablesCustomStore } from "@/data/ResponsablesDataSource.js";

export default {
  components: {
    DxButton,
    DxDataGrid,
    DxColumnGrid,
    DxEditingGrid,
    DxLookup,
    SelectBoxCatalogEditor,
    DxRequiredRule,
  },
  props: {
    idPlan: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      isPopupAttachedVisible: false,
      attachedID: null,
      attachedResourceURI: null,
      attachedResourceType: null,

      equiposDataSource: new EquiposDataSource(this.idPlan),
      responsablesStore: new ResponsablesCustomStore(),
    };
  },

  methods: {
    nombre_responsable(data) {
      return this.responsablesStore.getResponsable(data.id_responsables)?.nombre;
    },
    email_responsable(data) {
      return this.responsablesStore.getResponsable(data.id_responsables)?.email;
    },
    telefono_responsable(data) {
      return this.responsablesStore.getResponsable(data.id_responsables)?.telefono;
    },

    async setCellValueResponsable(newData, value) {
      newData.id_responsables = value;
      newData.funcion = this.responsablesStore.getResponsable(value)?.funcion;
    },
  },
};
</script>
