import { get, put, remove } from "@/core/fetch";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

export class CustomStoreVerificaciones extends CustomStore {
  constructor() {
    const baseUrl = `${global.API_URL}/instrumentos/verificaciones`;

    super({
      key: "id",
      loadMode: "raw",
      // cacheRawData: false,
      load: () => get(`${baseUrl}`),
      // byKey: (key) => get(`${baseUrl}/${key}`),
      // insert: (values) => post(`${baseUrl}`, values),
      update: (key, values) => put(`${baseUrl}/${key}`, values),
      remove: (key) => remove(`${baseUrl}/${key}`),
    });
  }
}

/*
Hay un problema con el lookup...
Cuando es nulo el resultado no puedo filtrar por este,
ya que considera como filtro borrado y muestra todos los resultados.
Hacemos un postProceso de los datos y reemplazamos los nulos por -1000
así podemos pedir filtrar por un -1000 y mostrar los registros
con resultado "null"
*/
const desnullify = (data) => {
  console.log("postProcess", data);

  // data = data.map((item) => ({
  //   ...item,
  //   resultado: item.resultado ?? -1000,
  // }));

  //
  // El lookup no tiene el valor -1000 cuando se hace el map...
  // por lo que no se puede filtrar
  // no entiendo porqué el map no funciona, pero el for sí...
  //

  for (let i = 0; i < data.length; i++) {
    if (data[i].resultado === null) {
      data[i].resultado = -1000;
    }
  }

  return data;
};

export class DataSourceVerificaciones extends DataSource {
  constructor() {
    const store = new CustomStoreVerificaciones();
    super({
      store,
      postProcess: desnullify,
    });
  }
}

// export const store = new CustomStore({
//   key: "id",
//   loadMode: "raw",
//   load: () => get(`${global.API_URL}/instrumentos/verificaciones`),
//   byKey: (key) => get(`${global.API_URL}/instrumentos/verificaciones/${key}`),
//   // para insertar necesito acceder al enpoint instrumentos/:ID/verificaciones para insertar sobre el instrumento!
//   // insert: (values) =>
//   //   post(`${global.API_URL}/instrumentos/verificaciones`, values),
//   update: (key, values) =>
//     put(`${global.API_URL}/instrumentos/verificaciones/${key}`, values),
//   remove: (key) =>
//     remove(`${global.API_URL}/instrumentos/verificaciones/${key}`),
// });

// export const datasource = new DataSource({
//   store: store,
// });

// export default datasource;
