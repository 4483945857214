<template>
  <div>
    <h3 class="d-flex justify-content-between">
      {{ title }}
      <DxButton
        icon="add"
        type="default"
        stylingMode="contained"
        :hint="$t('agregar_imagen')"
        @click="() => this.$refs.attachedViewer.add()"
        class="mx-3"
      />
    </h3>
    <div class="dx-card responsive-paddings">
      <AttachedViewer
        @changed="onChanged"
        ref="attachedViewer"
        :resourceURI="resourceURI"
        :showAddButton="false"
      ></AttachedViewer>
    </div>
  </div>
</template>

<script>
import DxButton from "devextreme-vue/button";
import AttachedViewer from "@/components/attached-viewer.vue";

export default {
  props: ["title", "resourceURI"],
  components: {
    DxButton,
    AttachedViewer,
  },
  methods: {
    repaint() {
      this.$refs.attachedViewer.repaint();
    },
    onChanged(e) {
      this.$emit("changed", e);
    },
  },
};
</script>
