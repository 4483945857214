<template>
  <div :key="componentKey">
    <TitleInstrumentoWithBackButton :instrumento="instrumento" />

    <h3 class="content-block">{{ $t("verificaciones_realizadas") }}</h3>

    <div class="content-block">
      <div class="text-end mb-2">
        <DxButton
          icon="arrowright"
          type="default"
          rtlEnabled="true"
          styling-mode="contained"
          :text="$t('nueva_verificacion')"
        />
      </div>
      <DxDataGrid
        ref="gridVerificaciones"
        id="gridVerificaciones"
        class="dx-card wide-card"
        :data-source="dataSourceInstrumentoVerificaciones"
        keyExpr="id"
        :hover-state-enabled="true"
        :allow-column-resizing="true"
        :allow-column-reordering="true"
        :noDataText="$t('noTieneNingunaVerificacion')"
        :focused-row-enabled="true"
        @focused-row-changed="onFocusedRowChanged"
        @row-dbl-click="onRowDblClick"
      >
        <DxPaging :page-size="12" />
        <DxStateStoring :enabled="true" storage-key="grid-verificaciones-instrumento"> </DxStateStoring>
        <!-- <DxEditingGrid :allow-updating="true" :allow-adding="true" :allow-deleting="true" mode="row" /> -->
        <DxColumnGrid data-field="fecha" :caption="$t('fecha')" data-type="date" sort-order="desc"> </DxColumnGrid>

        <DxColumnGrid
          data-field="resultado"
          :caption="$t('resultado')"
          cell-template="itemTemplateResultado"
          alignment="center"
        >
          <!-- <DxLookup :data-source="dataSourceResultados" display-expr="text" value-expr="id"> </DxLookup> -->
        </DxColumnGrid>

        <template #itemTemplateResultado="{ data }">
          <div class="text-center">
            <img
              :src="`${dataSourceResultados?.find((x) => x.id === data.value)?.img}`"
              :title="dataSourceResultados?.find((x) => x.id === data.value)?.value"
              height="24"
            />
          </div>
        </template>

        <DxColumnGrid data-field="verificado_por" :caption="$t('verificado_por')"> </DxColumnGrid>
        <DxColumnGrid data-field="certificado_por" :caption="$t('certificado_por')"> </DxColumnGrid>
        <DxColumnGrid data-field="notas" :caption="$t('notas')"> </DxColumnGrid>
      </DxDataGrid>
    </div>

    <h3 class="content-block">{{ $t("verificacion_realizada") }}</h3>

    <div class="content-block">
      <DxDataGrid
        ref="gridCalibracionesLineas"
        id="gridCalibracionesLineas"
        class="dx-card wide-card"
        :data-source="dataSourceInstrumentoVerificacionesLineas"
        keyExpr="id"
        :hover-state-enabled="true"
        :noDataText="$t('noTieneNingunVerificacionLinea')"
      >
        <!-- <DxEditingGrid :allow-updating="true" :allow-adding="true" :allow-deleting="true" mode="row" /> -->

        <DxColumnGrid data-field="secuencia" :caption="$t('secuencia')"> </DxColumnGrid>
        <DxColumnGrid data-field="caracteristica" :caption="$t('caracteristica')"> </DxColumnGrid>

        <DxColumnGrid data-field="metodo" :caption="$t('metodo')"> </DxColumnGrid>

        <DxColumnGrid data-field="patron" :caption="$t('patron')"> </DxColumnGrid>
        <DxColumnGrid data-field="procedimiento" :caption="$t('procedimiento')"> </DxColumnGrid>
        <DxColumnGrid data-field="valor_pedido" :caption="$t('valor_pedido')"> </DxColumnGrid>
        <DxColumnGrid data-field="valor_teorico" :caption="$t('valor_teorico')"> </DxColumnGrid>
        <DxColumnGrid data-field="valor_obtenido" :caption="$t('valor_obtenido')"> </DxColumnGrid>

        <!-- <DxColumnGrid data-field="resultado" :caption="$t('resultado')"> </DxColumnGrid> -->

        <DxColumnGrid
          data-field="resultado"
          :caption="$t('resultado')"
          cell-template="itemTemplateResultado"
          alignment="center"
        >
        </DxColumnGrid>

        <template #itemTemplateResultado="{ data }">
          <div class="text-center">
            <img
              :src="`${dataSourceResultadosLineas?.find((x) => x.id === data.value)?.img}`"
              :title="dataSourceResultadosLineas?.find((x) => x.id === data.value)?.value"
              height="24"
            />
          </div>
        </template>

        <DxColumnGrid data-field="fecha" :caption="$t('fecha')"> </DxColumnGrid>
        <DxColumnGrid data-field="notas" :caption="$t('notas')"> </DxColumnGrid>
      </DxDataGrid>
    </div>

    <!-- <h3 class="content-block">{{ $t("fotos") }}</h3>

    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <AttachedViewer :resourceURI="`instrumentos/${this.id}`"></AttachedViewer>
      </div>
    </div> -->

    <!-- <DxPopup v-if="attachedID" :title="$t('ficherosAdjuntos')" :visible.sync="isPopupAttachedVisible"
      :fullScreen="false" @shown="onShownAttachedPopup" :animation="{
        show: {
          type: 'pop',
        },
        hide: {
          type: 'pop',
          from: { scale: 1, opacity: 1 },
          to: { scale: 0, opacidy: 0 },
        },
      }">
      <template>
        <div>
          <AttachedViewer ref="attachedViewerGeneral" v-if="attachedID" :key="attachedID"
            :resourceURI="attachedResourceURI" :resources.sync="attachedResource.fotos"></AttachedViewer>
        </div>
      </template>
    </DxPopup>

    <Prompt ref="prompt"></Prompt> -->
  </div>
</template>

<script>
import { DxButton } from "devextreme-vue/button";
// import DxPopup from "devextreme-vue/popup";
// import Prompt from "@/components/prompt";

// import AttachedViewer from "@/components/attached-viewer.vue";
// import DataSource from "devextreme/data/data_source";
// import CustomStore from "devextreme/data/custom_store";

import { DxDataGrid, DxColumn as DxColumnGrid, DxPaging, DxStateStoring } from "devextreme-vue/data-grid";

import auth from "../../auth";
import TitleInstrumentoWithBackButton from "../../components/core/TitleInstrumentoWithBackButton.vue";

import resultados from "@/data/instrumentos/Resultados.js";
import resultadosLineas from "@/data/instrumentos/ResultadosLineas.js";

import { DataSourceVerificacionesInstrumento } from "../../data/instrumentos/VerificacionesInstrumentoDataSource";
import { DataSourceLineasVerificacion } from "../../data/instrumentos/LineasVerificacionDataSource";

export default {
  components: {
    DxButton,
    DxDataGrid,
    DxColumnGrid,
    DxPaging,
    DxStateStoring,
    TitleInstrumentoWithBackButton,
  },

  data() {
    const id = this.$route.params.id;
    console.log("data ->", id);

    return {
      componentKey: 0,
      id,
      dataSourceInstrumentoVerificaciones: new DataSourceVerificacionesInstrumento(id),
      instrumento: {},
      focusedVerificacion: {},
    };
  },

  methods: {
    async load() {
      const urls = [`${global.API_URL}/instrumentos/${this.id}`];
      //obtengo todos los fetch en paralelo
      const responses = await Promise.all(
        urls.map((u) =>
          fetch(u, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + auth.getToken(),
            },
          })
        )
      );
      const data = await Promise.all(responses.map((r) => r.json()));
      this.instrumento = data[0];
    },

    onEditorPreparing(e) {
      console.log("onEditorPreparing", e);
    },

    onInitNewRow(e) {
      console.log("onInitNewRow", e);
      // e.data.familia = false;
      // e.data.situacion = 0;
      // e.data.fecha_emision = new Date();
    },
    onEditingStart(e) {
      console.log("onEditingStart", e);
      // e.data.familia = false;
      // e.data.situacion = 0;
      // e.data.fecha_emision = new Date();
    },

    async onClickVerificar() {},

    onFocusedRowChanged(e) {
      this.focusedVerificacion = e.row.data;
      console.log("fetching this Verificacion: ", this?.focusedVerificacion?.id);
      // this.instrumentoVerificacionesLineasDataSource.clearRawDataCache();
      // this.instrumentoVerificacionesLineasDataSource.reload();
    },

    onRowDblClick(e) {
      // e.row.data;
      // console.log("fetching this calibracion: ", this?.focusedCalibracion?.id)
      // this.instrumentoCalibracionesLineasDataSource.clearRawDataCache();
      // this.instrumentoCalibracionesLineasDataSource.reload();

      this.$router.push({
        name: "verificacion",
        params: {
          id_instrumento: this.instrumento.id,
          id: e.key,
        },
      });
    },
  },

  async mounted() {
    // await this.responsablesDataSource.load();
    // await this.load();
    // console.log(this.responsablesDataSource.items());
  },

  async created() {
    await this.load(); // VERIFICAR SI ESTO ES CORRECTO...
  },

  computed: {
    idioma() {
      return this.$i18n.locale;
    },
    dataSourceResultados() {
      return resultados.map((e) => ({
        ...e,
        value: this.$t(e.text),
      }));
    },
    dataSourceResultadosLineas() {
      return resultadosLineas.map((e) => ({
        ...e,
        value: this.$t(e.text),
      }));
    },

    // se tiene que renovar cada vez que cambio de calibracion
    dataSourceInstrumentoVerificacionesLineas() {
      // console.log("dataSourceInstrumentoVerificacionesLineas", this.focusedVerificacion?.id);
      return new DataSourceLineasVerificacion(this.id, this.focusedVerificacion?.id);
    },
  },
  watch: {
    idioma() {
      this.componentKey++;
    },
  },
};
</script>

<style scoped lang="scss"></style>
