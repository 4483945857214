<template>
  <div class="content-block">
    <footer class="footer">
      Copyright © 2011-{{ new Date().getFullYear() }} {{ this.$appInfo.title }} Triplei Informática S.L. <br />
      All trademarks or registered trademarks are property of their respective owners.

      <div v-if="false"><input id="debug" type="checkbox" v-model="debug" /> <label for="debug">debug</label></div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    debug: {
      get() {
        return this.$store.state.debug;
      },
      set(value) {
        this.$store.commit("setDebug", value);
      },
    },
  },
};
</script>

<style lang="scss">
.footer {
  @import "../themes/generated/variables.base.scss";
  display: flex;
  justify-content: space-between;

  color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 20px;
  padding-bottom: 24px;
}

.dx-swatch-additional .footer {
  @import "../themes/generated/variables.additional.scss";
  color: rgba($base-text-color, alpha($base-text-color) * 0.7);
}
</style>
