import * as crud from "../core/fetch";

export const duplicateInstrumento = async (instrumento, relatedTables) => {
  const values = {
    instrumento: instrumento,
    relatedTables: relatedTables,
    // relatedTables: [
    //   "pcconjuntosimplicados": this.duplicateConjuntos,
    //   "pcequipo": this.duplicateEquipos,
    //   "pcestructura": this.duplicateEstructura,
    //   "pcnotas": this.duplicateNotas,
    //   "pcplanos": this.duplicatePlanos,
    //   "pcplantasconsumidoras": this.duplicatePlantas,
    //   "pcrevisiones": this.duplicateRevisiones,
    //   "fotos": this.duplicateFotos,
    //   "documentos": this.duplicateDocumentos
    // ]
  };

  const url = `${global.API_URL}/instrumentos/${instrumento.id}`;
  const response = await crud.post(url, values);
  return response;
};
