import { get, post, put, remove } from "@/core/fetch";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

/**
 * Dependiendo de las opciones pasadas, se construye la URL para obtener las caracteristicas.
 * @param {*} options Si contiene sólo idPlan, se obtienen las caracteristicas del plan, si contiene idEstructura, se obtienen las caracteristicas de la estructura, si contiene idEstructuraList, se obtienen las caracteristicas de una lista de estructuras.
 * @returns
 */
const getUrlFrom = (options) => {
  const { idPlan, idEstructura, idEstructuraList } = options;

  if (idEstructura) {
    return `${global.API_URL}/planes/${idPlan}/estructura/${idEstructura}/caracteristicas`;
  }

  if (idPlan) {
    return `${global.API_URL}/planes/${idPlan}/caracteristicas`;
  }

  if (idEstructuraList) {
    const e = idEstructuraList.join(",");
    return `${global.API_URL}/caracteristicas/autocontrol?e=${e}`;
  }

  return `${global.API_URL}/caracteristicas`;
};

export class CustomStoreCaracteristicas extends CustomStore {
  constructor(options = {}) {
    const baseUrlCaracteristicasFrom = getUrlFrom(options);
    const baseUrlCaracteristica = `${global.API_URL}/caracteristicas/`;

    super({
      key: "id",
      loadMode: "raw",
      load: () => get(`${baseUrlCaracteristicasFrom}`),

      byKey: (key) => get(`${baseUrlCaracteristica}/${key}`),
      insert: (values) => post(`${baseUrlCaracteristica}`, values),
      update: (key, values) => put(`${baseUrlCaracteristica}/${key}`, values),
      remove: (key) => remove(`${baseUrlCaracteristica}/${key}`),
    });

    this.copy = async (ids, estructuraID) => {
      console.log("copying", ids, "to", estructuraID , "from", baseUrlCaracteristica);
      const url = `${baseUrlCaracteristica}`;
      const response = await post(url, { from: ids, to: estructuraID });
      
      return response;
    }

  }
}

export class DataSourceCaracteristicas extends DataSource {
  constructor(options = {}) {
    const store = new CustomStoreCaracteristicas(options);
    // @TODO: abrir la puerta a posibles configuraciones del datasource.
    options.store = store;
    super(options);
  }
}
