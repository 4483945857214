<template>
  <div>
    <h3 class="d-flex justify-content-between">
      {{ $t("revisiones") }}
      <DxButton
        icon="add"
        type="default"
        stylingMode="contained"
        :hint="$t('agregar_revision')"
        @click="() => this.$refs.grid.instance.addRow()"
        class="mx-3"
      />
    </h3>

    <DxDataGrid
      ref="grid"
      id="gridRevisiones"
      class="dx-card wide-card"
      :data-source="revisionesStore"
      keyExpr="id"
      :hover-state-enabled="true"
      :noDataText="$t('noHayRevisiones')"
      @row-updated="updated"
      @row-inserted="updated"
    >
      <DxEditingGrid :allow-updating="true" :allow-adding="false" :allow-deleting="true" mode="row">
        <!-- <DxTexts
                :confirmDeleteMessage="$t('confirmarBorrar')"
              /> -->
      </DxEditingGrid>

      <DxColumnGrid data-field="fecha" :caption="$t('fecha')" data-type="datetime" width="200">
        <DxRequiredRule />
      </DxColumnGrid>

      <DxColumnGrid data-field="revision" :caption="$t('revision')"></DxColumnGrid>

      <DxColumnGrid data-field="nivel" :caption="$t('nivel')"> </DxColumnGrid>
      <DxColumnGrid data-field="notas" :caption="$t('notas')"> </DxColumnGrid>
      <!-- <DxColumnGrid data-field="id_responsables" :caption="$t('responsable')" cell-template="responsable">
          <DxLookup display-expr="nombre" value-expr="id" :data-source="responsablesStore"> </DxLookup>
        </DxColumnGrid>

        <template #responsable="{ data }">
          {{ nombre_responsable(data) }}
        </template> -->

      <DxColumnGrid data-field="id_preparador" :caption="$t('realizadoPor')">
        <DxLookup display-expr="nombre" value-expr="id" :data-source="responsablesStore"> </DxLookup>
      </DxColumnGrid>

      <DxColumnGrid data-field="id_revisor" :caption="$t('revisadoPor')">
        <DxLookup display-expr="nombre" value-expr="id" :data-source="responsablesStore"> </DxLookup>
      </DxColumnGrid>

      <DxColumnGrid data-field="id_aprobador" :caption="$t('aprobadoPor')">
        <DxLookup display-expr="nombre" value-expr="id" :data-source="responsablesStore"> </DxLookup>
      </DxColumnGrid>

      <DxColumnGrid
        data-field="documentos.length"
        :allow-editing="false"
        width="50"
        cell-template="documentos"
        header-cell-template="documentos-header"
      >
      </DxColumnGrid>
      <template #documentos-header>
        <span class="pr-1">
          <i class="ion ion-md-attach"></i>
        </span>
      </template>

      <template #documentos="{ data }">
        <ButtonAttachedDocuments
          v-if="!data.row.isEditing"
          @click="
            onAttachedClick({
              data: data.data,
              resource: `planes/${idPlan}/revisiones/${data.data.id}`,
              type: 'DOC',
            })
          "
          :number="data?.data?.documentos?.length"
        />
      </template>

      <DxColumnGrid
        :allow-editing="false"
        data-field="fotos.length"
        cell-template="fotos"
        width="50"
        header-cell-template="fotos-header"
      >
      </DxColumnGrid>
      <template #fotos-header>
        <span class="pr-1">
          <i class="ion ion-md-camera"></i>
        </span>
      </template>

      <template #fotos="{ data }">
        <ButtonAttachedImages
          v-if="!data.row.isEditing"
          @click="
            onAttachedClick({
              data: data.data,
              resource: `planes/${idPlan}/revisiones/${data.data.id}`,
              type: 'IMG',
            })
          "
          :number="data?.data?.fotos?.length"
        />
      </template>
    </DxDataGrid>

    <DxPopup
      v-if="attachedID"
      :title="$t('ficherosAdjuntos')"
      :visible.sync="isPopupAttachedVisible"
      :fullScreen="false"
      :animation="{
        show: {
          type: 'pop',
        },
        hide: {
          type: 'pop',
          from: { scale: 1, opacity: 1 },
          to: { scale: 0, opacidy: 0 },
        },
      }"
      @hidden="refreshDataGrid"
    >
      <template>
        <div>
          <AttachedViewer
            ref="attachedViewerGeneral"
            v-if="attachedID && attachedResourceType === 'IMG'"
            :key="attachedID"
            :resourceURI="attachedResourceURI"
            :resourceType="attachedResourceType"
          ></AttachedViewer>

          <AttachedDocumentViewer
            ref="attachedDocumentViewerGeneral"
            v-if="attachedID && attachedResourceType === 'DOC'"
            :key="attachedID"
            :resourceURI="attachedResourceURI"
            :resourceType="attachedResourceType"
          ></AttachedDocumentViewer>
        </div>
      </template>
    </DxPopup>
  </div>
</template>

<script>
import { DxButton } from "devextreme-vue/button";
import { DxDataGrid } from "devextreme-vue/data-grid";
import { DxColumn as DxColumnGrid } from "devextreme-vue/data-grid";
import { DxEditing as DxEditingGrid } from "devextreme-vue/data-grid";
import { DxLookup } from "devextreme-vue/data-grid";
import { DxPopup } from "devextreme-vue/popup";
import { DxRequiredRule } from "devextreme-vue/validator";

import { RevisionesDataSource } from "@/data/plancontrol/RevisionesDataSource.js";
import { ResponsablesCustomStore } from "@/data/ResponsablesDataSource.js";

import ButtonAttachedImages from "@/components/button-attached-images.vue";
import ButtonAttachedDocuments from "@/components/button-attached-documents.vue";
import AttachedViewer from "@/components/attached-viewer.vue";
import AttachedDocumentViewer from "@/components/attached-document-viewer.vue";

export default {
  components: {
    DxButton,
    DxDataGrid,
    DxColumnGrid,
    DxEditingGrid,
    DxLookup,
    DxPopup,
    DxRequiredRule,
    ButtonAttachedDocuments,
    ButtonAttachedImages,
    AttachedViewer,
    AttachedDocumentViewer,
  },
  props: {
    idPlan: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      isPopupAttachedVisible: false,
      attachedID: null,
      attachedResourceURI: null,
      attachedResourceType: null,

      // revisiones: [],
      // revisionesStore: new CustomStore({
      //   key: "id",
      //   load: () => this.$fetch.get(`${global.API_URL}/planes/${this.idPlan}/revisiones`),
      //   insert: (values) => this.$fetch.post(`${global.API_URL}/planes/${this.idPlan}/revisiones`, values),
      //   update: (key, values) => this.$fetch.put(`${global.API_URL}/planes/${this.idPlan}/revisiones/${key}`, values),
      //   remove: (key) => this.$fetch.delete(`${global.API_URL}/planes/${this.idPlan}/revisiones/${key}`),
      //   onLoading: (test) => {
      //     console.log(test);
      //   },
      //   onLoaded: (result) => {
      //     // ordenamos revisiones:
      //     this.revisiones = result.sort((a, b) => new Date(b.fecha) - new Date(a.fecha));
      //   },
      // }),

      revisionesStore: new RevisionesDataSource(this.idPlan),
      responsablesStore: new ResponsablesCustomStore(),
    };
  },

  methods: {
    updated() {
      this.$emit("updated");
    },

    onDataErrorOcurred(e) {
      e.error.message = this.$t(e.error.message);
    },

    onAttachedClick({ data, resource, type }) {
      this.isPopupAttachedVisible = true;
      this.attachedID = data.id;
      this.attachedResourceURI = resource;
      this.attachedResourceType = type;
    },

    refreshDataGrid() {
      console.log("refreshDataGrid");
      this.$refs.grid.instance.refresh();
    },
  },
};
</script>
