var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-6"},[(true)?_c('DxChart',{ref:"graficoPareto",attrs:{"id":"graficoPareto","data-source":_vm.dataSourcePareto},on:{"point-click":_vm.onClickDefecto}},[_c('DxTitle',{attrs:{"text":_vm.$t('pareto'),"subtitle":{
            text: _vm.titulo,
            font: {
              size: '2em',
            },
          }}}),_c('DxCommonSeriesSettings',{attrs:{"argument-field":"defecto"}}),_c('DxCommonAxisSettings',[_c('DxGrid',{attrs:{"visible":false}})],1),_c('DxSeries',{attrs:{"name":_vm.$t('numeroDefectos'),"value-field":"cantidad","axis":"frequency","type":"bar","color":"#fac29a"}}),_c('DxSeries',{attrs:{"name":_vm.$t('acumulado'),"value-field":"acumulado","axis":"acumulado","type":"spline","color":"#6b71c3"}}),_c('DxArgumentAxis',[_c('DxLabel',{attrs:{"overlapping-behavior":"stagger"}})],1),_c('DxValueAxis',{attrs:{"name":"frequency","position":"left"}}),_c('DxValueAxis',{attrs:{"show-zero":true,"value-margins-enabled":false,"name":"acumulado","position":"right"}}),_c('DxTooltip',{attrs:{"enabled":true,"shared":true,"customize-tooltip":_vm.customizeTooltip}}),_c('DxLegend',{attrs:{"visible":false}})],1):_vm._e()],1),_c('div',{staticClass:"col-xl-6"},[(true)?_c('DxPieChart',{ref:"graficoPie",attrs:{"id":"graficoPie","data-source":_vm.dataSourcePareto},on:{"point-click":_vm.onClickDefecto}},[_c('DxTitle',{attrs:{"text":_vm.$t('defectos%'),"subtitle":{
            text: _vm.titulo,
            font: {
              size: '2em',
            },
          }}}),_c('DxSeries',{attrs:{"argument-field":"defecto","value-field":"cantidad"}},[_c('DxLabel',{attrs:{"visible":true,"customize-text":_vm.formatLabel,"position":"columns"}},[_c('DxConnector',{attrs:{"visible":true,"width":0.5}}),_c('DxFont',{attrs:{"size":16}})],1)],1),_c('DxLegend',{attrs:{"column-count":4,"orientation":"horizontal","item-text-position":"right","horizontal-alignment":"center","vertical-alignment":"bottom"}})],1):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }