<template>
  <div>
    <h3 class="d-flex justify-content-between">
      {{ $t("notasAlControlador") }}

      <DxButton
        icon="add"
        type="default"
        stylingMode="contained"
        :hint="$t('agregar_nota_al_controlador')"
        @click="() => $refs.grid.instance.addRow()"
        class="mx-3"
      />
    </h3>

    <!-- <code>{{ notas }}</code> -->
    <DxDataGrid
      ref="grid"
      id="gridNotas"
      class="dx-card wide-card"
      :data-source="notasAlControladorStore"
      keyExpr="id"
      :hover-state-enabled="true"
      :noDataText="$t('noHayNotas')"
    >
      <DxEditingGrid :allow-updating="true" :allow-adding="false" :allow-deleting="true" mode="row" />

      <DxColumnGrid data-field="nota" :caption="$t('nota')">
        <DxRequiredRule />
      </DxColumnGrid>
      <DxColumnGrid
        data-field="fecha_inicio"
        :caption="$t('fechaInicio')"
        data-type="datetime"
        edit-cell-template="fechaInicioEditor"
      >
        <!-- <DxRangeRule
                :max="fecha_fin"
                :reevaluate="true"
              >
              </DxRangeRule>
            <DxAsyncRule
              :validation-callback="asyncValidation"
              message="Error fecha fin"
            /> -->
      </DxColumnGrid>

      <template #fechaInicioEditor="{ data: cellInfo }">
        <div>
          <DxDateBox
            ref="fechaInicio"
            type="datetime"
            v-model="cellInfo.value"
            :max="cellInfo.data.fecha_fin"
            @valueChanged="
              (e) => {
                $refs.fechaFin.instance.option('min', e.value);
                cellInfo.setValue(e.value);
              }
            "
          />
        </div>
      </template>

      <DxColumnGrid
        data-field="fecha_fin"
        :caption="$t('fechaFin')"
        data-type="datetime"
        edit-cell-template="fechaFinEditor"
      >
        <!-- <DxRangeRule :min="fecha_inicio"
            :reevaluate="true"></DxRangeRule>
            <DxAsyncRule
              :validation-callback="asyncValidation"
              message="Error fecha fin"
            /> -->
      </DxColumnGrid>

      <template #fechaFinEditor="{ data: cellInfo }">
        <div>
          <DxDateBox
            ref="fechaFin"
            type="datetime"
            v-model="cellInfo.value"
            :min="cellInfo.data.fecha_inicio"
            @valueChanged="
              (e) => {
                $refs.fechaInicio.instance.option('max', e.value);
                cellInfo.setValue(e.value);
              }
            "
          />
        </div>
      </template>

      <DxColumnGrid data-field="conclusion" :caption="$t('conclusion')"></DxColumnGrid>

      <DxColumnGrid
        data-field="documentos.length"
        :allow-editing="false"
        width="50"
        cell-template="documentos"
        header-cell-template="documentos-header"
      >
      </DxColumnGrid>
      <template #documentos-header>
        <span class="pr-1">
          <i class="ion ion-md-attach"></i>
        </span>
      </template>
      <template #documentos="{ data }">
        <ButtonAttachedDocuments
          v-if="!data.row.isEditing"
          @click="
            onAttachedClick({
              data: data.data,
              resource: `caracteristicas/${idCaracteristica}/notas/${data.data.id}`,
              type: 'DOC',
            })
          "
          :number="data?.data?.documentos?.length"
        />
      </template>

      <DxColumnGrid
        :allow-editing="false"
        data-field="fotos.length"
        cell-template="fotos"
        width="50"
        header-cell-template="fotos-header"
      >
      </DxColumnGrid>
      <template #fotos-header>
        <span class="pr-1">
          <i class="ion ion-md-camera"></i>
        </span>
      </template>
      <template #fotos="{ data }">
        <ButtonAttachedImages
          v-if="!data.row.isEditing"
          @click="
            onAttachedClick({
              data: data.data,
              resource: `caracteristicas/${idCaracteristica}/notas/${data.data.id}`,
              type: 'IMG',
            })
          "
          :number="data?.data?.fotos?.length"
        />
      </template>
    </DxDataGrid>

    <DxPopup
      v-if="attachedID"
      :title="$t('ficherosAdjuntos')"
      :visible.sync="isPopupAttachedVisible"
      :fullScreen="false"
      :animation="{
        show: {
          type: 'pop',
        },
        hide: {
          type: 'pop',
          from: { scale: 1, opacity: 1 },
          to: { scale: 0, opacidy: 0 },
        },
      }"
      @hidden="refreshDataGrid"
    >
      <template>
        <div>
          <AttachedViewer
            ref="attachedViewerGeneral"
            v-if="attachedID && attachedResourceType === 'IMG'"
            :key="attachedID"
            :resourceURI="attachedResourceURI"
            :resourceType="attachedResourceType"
          ></AttachedViewer>

          <AttachedDocumentViewer
            ref="attachedDocumentViewerGeneral"
            v-if="attachedID && attachedResourceType === 'DOC'"
            :key="attachedID"
            :resourceURI="attachedResourceURI"
            :resourceType="attachedResourceType"
          ></AttachedDocumentViewer>
        </div>
      </template>
    </DxPopup>
  </div>
</template>

<script>
import { DxButton } from "devextreme-vue/button";
import { DxDataGrid } from "devextreme-vue/data-grid";
import { DxColumn as DxColumnGrid } from "devextreme-vue/data-grid";
import { DxEditing as DxEditingGrid } from "devextreme-vue/data-grid";
import { DxRequiredRule } from "devextreme-vue/validator";
import DxDateBox from "devextreme-vue/date-box";
import { DxPopup } from "devextreme-vue/popup";

import { NotasAlControladorDataSource } from "@/data/caracteristicas/NotasAlControladorDataSource.js";

import ButtonAttachedImages from "@/components/button-attached-images.vue";
import ButtonAttachedDocuments from "@/components/button-attached-documents.vue";
import AttachedViewer from "@/components/attached-viewer.vue";
import AttachedDocumentViewer from "@/components/attached-document-viewer.vue";

export default {
  components: {
    DxButton,
    DxDataGrid,
    DxColumnGrid,
    DxEditingGrid,
    DxDateBox,
    DxPopup,
    DxRequiredRule,
    ButtonAttachedDocuments,
    ButtonAttachedImages,
    AttachedViewer,
    AttachedDocumentViewer,
  },
  props: {
    idCaracteristica: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      isPopupAttachedVisible: false,
      attachedID: null,
      attachedResourceURI: null,
      attachedResourceType: null,

      notasAlControladorStore: new NotasAlControladorDataSource(this.idCaracteristica),
    };
  },

  methods: {
    updated() {
      this.$emit("updated");
    },

    onDataErrorOcurred(e) {
      e.error.message = this.$t(e.error.message);
    },

    onAttachedClick({ data, resource, type }) {
      this.isPopupAttachedVisible = true;
      this.attachedID = data.id;
      this.attachedResourceURI = resource;
      this.attachedResourceType = type;
    },

    refreshDataGrid() {
      console.log("refreshDataGrid");
      this.$refs.grid.instance.refresh();
    },
  },
};
</script>
