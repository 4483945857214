<template>
  <div>
    <DxButton
      v-if="false"
      icon="add"
      type="default"
      styling-mode="contained"
      :hint="$t('nueva_calibracion')"
      :text="$t('nueva_calibracion')"
      @click="onClick"
    />

    <DxDropDownButton
      icon="add"
      type="default"
      styling-mode="contained"
      :hint="$t('nueva_calibracion')"
      :text="$t('nueva_calibracion')"
      @buttonClick="onClick"
      :items="actions"
      :splitButton="true"
      :dropDownOptions="{ width: 300 }"
      displayExpr="text"
    >
    </DxDropDownButton>

    <DxPopup
      :title="$t('seleccionarInstrumento')"
      :visible.sync="isPopupInstrumentosVisible"
      width="800px"
      height="auto"
      @hidden="
        () => {
          $refs.gridInstrumentos.instance.clearSelection();
          $refs.gridInstrumentos.instance.clearFilter('search');
        }
      "
    >
      <!-- <p>Selecciona un instrumento de la siguiente lista:</p> -->

      <DxDataGrid
        ref="gridInstrumentos"
        height="500"
        :selection="{ mode: 'single' }"
        :dataSource="dataSourceInstrumentos"
        @selectionChanged="onSelectionChangedInstrumentos"
        @rowDblClick="onRowDblClickInstrumentos"
      >
        <!-- <DxFilterRow :visible="true"></DxFilterRow> -->
        <!-- <DxFilterPanel :visible="true"></DxFilterPanel> -->
        <!-- <DxHeaderFilter :visible="true" /> -->
        <DxSearchPanel :visible="true" :width="240" />
        <DxColumn data-field="id" :caption="$t('numero')" width="80" sort-order="desc" :allowEditing="false" />
        <DxColumn data-field="codigo" :caption="$t('codigo')"></DxColumn>
        <DxColumn data-field="nombre" :caption="$t('nombre')"></DxColumn>
      </DxDataGrid>

      <p class="mt-4 fw-lighter fst-italic fs-small">
        {{ $t("mensaje_instrumento_hay_calibracion") }}
      </p>

      <DxToolbarItem :options="toolbarItemCancelarOptions" widget="dxButton" location="after" toolbar="bottom" />
      <DxToolbarItem
        :options="toolbarItemSeleccionarOptions"
        widget="dxButton"
        location="after"
        toolbar="bottom"
        :disable="!selectedInstrumento"
      />
    </DxPopup>
  </div>
</template>

<script>
import { DxDropDownButton } from "devextreme-vue";
import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";

import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  // DxFilterPanel,
  // DxFilterRow,
  // DxHeaderFilter,
} from "devextreme-vue/data-grid";

import { DxButton } from "devextreme-vue/button";
import { confirm } from "devextreme/ui/dialog";
import { createCalibracion, copyCalibracion } from "../../../api/calibraciones";
import { DataSourceInstrumentos } from "../../../data/instrumentos/InstrumentoDataSource";
// import { DataSourceCalibracionesInstrumento } from "../../../data/instrumentos/CalibracionesInstrumentoDataSource";

// const COPIAR_CALIBRACION_ACTUAL = 1;
// const COPIAR_CALIBRACION_ACTUAL_DE_OTRO_INSTRUMENTO = 2;

export default {
  props: ["instrumento"],
  components: {
    DxButton,
    DxDropDownButton,
    DxPopup,
    DxToolbarItem,
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    // DxFilterPanel,
    // DxFilterRow,
    // DxHeaderFilter,
  },
  data() {
    return {
      actions: [
        {
          text: this.$t("Copiando la actual"),
          onClick: () => {
            this.copyActualCalibration();
          },
        },
        {
          text: this.$t("Copiando la actual de otro instrumento..."),
          onClick: () => {
            this.copyActualFromOtherCalibration();
          },
        },
      ],

      isPopupInstrumentosVisible: false,
      dataSourceInstrumentos: new DataSourceInstrumentos({
        filterQuery: "?hasCalibracion=true",
      }),
      // dataSourceCalibraciones: new DataSourceCalibracionesInstrumento(id_instrumento),

      selectedInstrumento: null,

      toolbarItemCancelarOptions: {
        text: this.$t("cancelar"),
        onClick: () => {
          this.isPopupInstrumentosVisible = false;
        },
      },
    };
  },
  computed: {
    // dataSourceCalibraciones: function () {
    //   if (!this.selectedInstrumento) return null;
    //   return new DataSourceCalibracionesInstrumento(this.selectedInstrumento);
    // },

    toolbarItemSeleccionarOptions: function () {
      return {
        text: this.$t("seleccionar"),
        type: "default",
        stylingMode: "contained",
        disabled: !this.selectedInstrumento,
        onClick: () => {
          this.createNewCalibration(this.selectedInstrumento);
          this.isPopupInstrumentosVisible = false;
        },
      };
    },
  },
  methods: {
    async onClick() {
      if (!(await confirm(this.$t("confirmarNuevaCalibracionVacia"), this.$t("nueva_calibracion")))) return;

      this.createNewCalibration();
    },

    async copyActualCalibration() {
      if (!(await confirm(this.$t("confirmarNuevaCalibracionCopiaActual"), this.$t("nueva_calibracion_import_actual"))))
        return;

      this.createNewCalibration(this.instrumento.id);
    },

    async copyActualFromOtherCalibration() {
      if (!(await confirm(this.$t("confirmarNuevaCalibracionCopiaActual"), this.$t("nueva_calibracion_import_actual"))))
        return;

      this.goToInstrumentSelection();
    },

    // async onItemClick(e) {
    //   if (e.itemData.id === COPIAR_CALIBRACION_ACTUAL) {
    //     // copiar la calibración más actual
    //     if (
    //       !(await confirm(this.$t("confirmarNuevaCalibracionCopiaActual"), this.$t("nueva_calibracion_import_actual")))
    //     )
    //       return;

    //     this.createNewCalibration(this.instrumento.id);
    //   } else if (e.itemData.id === COPIAR_CALIBRACION_ACTUAL_DE_OTRO_INSTRUMENTO) {
    //     if (
    //       !(await confirm(this.$t("confirmarNuevaCalibracionCopiaActual"), this.$t("nueva_calibracion_import_actual")))
    //     )
    //       return;

    //     this.goToInstrumentSelection();
    //   }
    // },

    goToInstrumentSelection() {
      this.isPopupInstrumentosVisible = true;
    },

    onSelectionChangedInstrumentos(e) {
      this.selectedInstrumento = e.selectedRowsData[0]?.id || null;
    },

    /**
     * Se crea una calibración nueva a partir de las características a calibrar activas. Si se proporciona un id en fromInstrumentoID, se importan los datos de la última calibración para dichas características.
     * @param {*} fromInstrumentoID parametro opcional, si se pasa un id de instrumento, se copiará la calibración de ese instrumento
     */
    async createNewCalibration(fromInstrumentoID = null) {
      const initValues = {
        id_instrumentos: this.instrumento.id,
        resultado: null,
        fecha: Date.now(),
        verificado_por: this.$store.state.user.nombre,
        certificado_por: this.$store.state.user.nombre,
        notas: null,
      };

      try {
        let response;

        if (fromInstrumentoID === null) {
          response = await createCalibracion(initValues);
        } else {
          response = await copyCalibracion(initValues, fromInstrumentoID, this.instrumento.id);
        }
        // selected instrumento from

        // go to edit calibración
        this.$router.push({
          name: "calibracion",
          params: { id_instrumento: this.instrumento.id, id: response.id },
        });
      } catch (ex) {
        // console.error(ex);
        this.$notify(`Error al crear calibración: ${ex.message}`, this.$t("error"), 5000);
      }
    },

    async onRowDblClickInstrumentos() {
      await this.createNewCalibration(this.selectedInstrumento);
      this.isPopupInstrumentosVisible = false;
    },
  },
};
</script>
