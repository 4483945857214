import config from "devextreme/core/config";
import "devextreme/dist/css/dx.common.css";
import "./themes/generated/theme.base.css";
import "./themes/generated/theme.additional.css";
// import 'devextreme/dist/css/dx.material.lime.dark.compact.css';

// Custom icons by Ionicons
// import "ionicons/dist/css/ionicons.min.css";

// In the last module update this import stopped working.
// The workaround has been putting the css on index.html (see full commit).
// @TODO: Study which is the best solution to this.
// @TODO: Find a working solution to vue 3.

// Icons by Font-awesome (4.7.0), las versiones superiores no proporcionan webfonts (que es lo que necesita devextreme)
import "font-awesome/css/font-awesome.min.css";

// Icons by Bootstrap
import "bootstrap-icons/font/bootstrap-icons.css";

// Icons by ionicons (4.5.10-0), las versiones superiores no proporcionan webfonts (que es lo que necesita devextreme)
import "ionicons/dist/css/ionicons.min.css";

// Bootstrap
import "bootstrap/dist/css/bootstrap.css";

import Vue from "vue";
import store from "./store";
import AsyncComputed from "vue-async-computed";
import VueCookies from "vue-cookies";
import VueI18n from "vue-i18n";
import constantsPlugin from "vue-options-api-constants-plugin";

import moment from "./plugins/moment";
import localization from "./plugins/localization";
import notify from "./plugins/notify";
import pluginFetch from "./plugins/fetch";
import App from "./App";
import router from "./router";
import appInfo from "./app-info";
// import { Main } from "electron";
// import { locale } from "core-js";
import p from "@/plugins/prompt";
import authImage from "./plugins/authImage";

import Debug from "./components/core/Debug";

// Devexpress 22.1.4
/**
 * Detected breaking change: https://supportcenter.devexpress.com/versionhistory
 * showCloseButton default false: https://supportcenter.devexpress.com/ticket/details/T1025008/popup-the-default-value-of-the-showclosebutton-option-is-changed-to-false-for-material
 * To revert that we can use defaultOptions(rule): https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxPopup/Methods/#defaultOptionsrule
 */

import Popup from "devextreme/ui/popup";
import TextBox from "devextreme/ui/text_box";
import SelectBox from "devextreme/ui/select_box";
import DateBox from "devextreme/ui/date_box";
import TextArea from "devextreme/ui/text_area";
import DataGrid from "devextreme/ui/data_grid";

(async function () {
  // Component Initialization:

  Popup.defaultOptions({
    options: {
      // Here go the Popup properties
      showCloseButton: true,
    },
  });

  TextBox.defaultOptions({
    options: {
      // Here go the TextBox properties
      labelMode: "floating",
      // label: "Lorem Ipsum Dolor Sit Amet",
    },
  });

  SelectBox.defaultOptions({
    options: {
      // Here go the TextBox properties
      labelMode: "floating",
      // label: "Lorem Ipsum Dolor Sit Amet",
    },
  });

  DateBox.defaultOptions({
    options: {
      // Here go the TextBox properties
      labelMode: "floating",
      // label: "Lorem Ipsum Dolor Sit Amet",
    },
  });

  TextArea.defaultOptions({
    options: {
      // Here go the TextBox properties
      labelMode: "floating",
      // label: "Lorem Ipsum Dolor Sit Amet",
    },
  });

  // Plugins

  // async computed properties
  Vue.use(AsyncComputed);
  // cookie handler
  Vue.use(VueCookies);
  // internationalization
  Vue.use(VueI18n);
  Vue.use(moment);
  Vue.use(localization);
  Vue.use(notify);
  Vue.use(pluginFetch);
  Vue.use(authImage);
  Vue.use(constantsPlugin);

  // Global Components
  Vue.component("Debug", Debug);

  //this.$moment.locale("es")
  //moment.locale("es")

  Vue.config.productionTip = false;
  Vue.prototype.$appInfo = appInfo;

  const getRuntimeConfig = async () => {
    const runtimeConfig =
      process.env.NODE_ENV === "development" ? await fetch("config.dev.json") : await fetch("config.json");

    const json = await runtimeConfig.json();

    global.API_URL = json.API_URL;
    global.IMG_URL = json.IMG_URL;
    global.DATA_URL = json.DATA_URL;
  };

  await getRuntimeConfig();

  config({
    editorStylingMode: "outlined", // or 'outlined' | 'underlined'
    defaultCurrency: "EUR",
  });

  function loadLocaleMessages() {
    const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};
    locales.keys().forEach((key) => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i);
      if (matched && matched.length > 1) {
        const locale = matched[1];
        messages[locale] = locales(key);
      }
    });
    return messages;
  }

  // export default new VueI18n({
  //   locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  //   fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  //   messages: loadLocaleMessages()
  // })

  const i18n = new VueI18n({
    // locale: 'fr',
    // locale: process.env.VUE_APP_I18N_LOCALE || 'es' ,
    locale: "es",
    messages: loadLocaleMessages(),
  });

  Vue.use(p, {
    i18n,
  });

  const vm = new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
  });

  // Component Initialization with vm required (plugins, etc)

  DataGrid.defaultOptions({
    options: {
      onDataErrorOccurred(e) {
        // evento de la datagrid que se dispara cuando hay un error
        // se puede alterar el mensaje de error (e.error.message)
        e.error.message = vm.$t(e.error.message);
        vm.$notify(e.error.message, "error", 3000);
      },
    },
  });

  vm.$mount("#app");
})();
