<template>
  <div>
    <DxPopup
      class="popupScrollableContentWithoutPadding"
      :visible.sync="isPopupVisible"
      :fullScreen="false"
      :title="titulo"
      @hidden="onHidden"
      @hiding="onHiding"
    >
      <DxToolbarItem :options="toolbarItemCancelarOptions" widget="dxButton" location="after" toolbar="bottom" />
      <DxToolbarItem :options="toolbarItemAceptarOptions" widget="dxButton" location="after" toolbar="bottom" />

      <DxScrollView>
        <div>
          <div v-if="$store.state.debug">
            <code>
              <pre>{{ instrumento }}</pre>
            </code>
            <br />
            <code>
              <pre>{{ calibracion }}</pre>
            </code>
            <br />
            <code>
              <pre>{{ calibracionLinea }}</pre>
            </code>
            <br />
          </div>

          <DxSelectBox
            :value.sync="patron"
            :dataSource="patrones"
            displayExpr="nombre"
            valueExpr="id"
            :label="$t('patron')"
            class="mt-4"
          />

          <div class="mt-2">
            <DxDataGrid
              class="dx-card wide-card"
              :dataSource="incertidumbresStore"
              keyExpr="id"
              width="100%"
              ref="grid"
              @initNewRow="onInitNewRow"
            >
              <DxColumn dataField="id" :caption="$t('id')" :width="100" :visible="false" />
              <DxColumn dataField="patron" :caption="$t('patron')" />
              <DxColumn dataField="nombre_escala" :caption="$t('escala')" width="200" />
              <DxColumn
                :show-buttons="true"
                dataField="nominal_patron"
                :caption="$t('Vn')"
                :width="75"
                data-type="number"
              />
              <DxColumn
                dataField="k_patron"
                :caption="$t('kp')"
                :width="75"
                data-type="number"
                :setCellValue="setKPatron"
              />
              <DxColumn
                dataField="incertidumbre_patron"
                :caption="$t('Up')"
                :width="75"
                data-type="number"
                :setCellValue="setIncertidumbrePatron"
              />
              <DxColumn
                dataField="valor_patron"
                :caption="$t('Vreal')"
                :width="75"
                data-type="number"
                :setCellValue="setValorPatron"
              />
              <DxColumn
                dataField="media"
                :caption="$t('x̅')"
                :width="75"
                :allowEditing="true"
                data-type="number"
                cell-template="mediaDataTemplate"
                edit-cell-template="mediaEditTemplate"
              >
                <!-- <DxFormat type="fixedPoint" :precision="2" /> -->
              </DxColumn>

              <template #mediaDataTemplate="{ data }">
                <div>
                  <span>{{ data.value }}</span>
                  <div v-if="data.data.lecturas">
                    <!-- eslint-disable-next-line vue/require-v-for-key -->
                    <!-- <div v-for="lectura in data.data?.lecturas?.split('|')">{{ lectura }}</div> -->
                  </div>
                </div>
              </template>

              <template #mediaEditTemplate="{ data }">
                <DxButton width="100%" @click="onClickMedia(data)">{{ data.value }}</DxButton>
              </template>
              <DxColumn
                dataField="lecturas"
                :caption="$t('lecturas')"
                :width="74"
                :allowEditing="false"
                :visible="false"
                :setCellValue="setLecturas"
              />
              <DxColumn
                dataField="correccion"
                :caption="$t('Δx̅c')"
                :width="74"
                :allowEditing="false"
                data-type="number"
              />
              <DxColumn
                dataField="desviacion"
                :caption="$t('σ')"
                :width="75"
                :allowEditing="false"
                data-type="number"
              />
              <DxColumn
                dataField="incertidumbre"
                :caption="$t('U')"
                :width="75"
                :allowEditing="false"
                data-type="number"
              />

              <DxEditing :mode="'row'" :allowUpdating="true" :allowDeleting="true" :allowAdding="true" />
            </DxDataGrid>
          </div>

          <div class="mt-5">
            <div class="d-flex gap-3 justify-content-end align-items-center">
              <DxNumberBox
                ref="numberBoxK"
                width="100%"
                :value.sync="k"
                type="number"
                :label="$t('factor_de_cobertura_(k)')"
                @value-changed="onKChanged"
                :allow-null="true"
              >
              </DxNumberBox>

              <Display :label="$t('incertidumbre_expandida_(U)')" :full="true">
                <span>{{ u }}</span>
              </Display>
            </div>
            <Display :label="$t('formula')" class="mt-3">
              <img :src="require('@/assets/instrumentos/calibraciones/formula2.png')" alt="formula" class="formula" />
            </Display>
          </div>
        </div>

        <Lecturas v-if="lecturas !== null" :lecturas.sync="lecturas" @closed="onClosedIncertidumbre"></Lecturas>
      </DxScrollView>
    </DxPopup>
  </div>
</template>

<script>
const DEFAULT_FORMULA = 2;
import { average, stddev } from "@/core/calculo.js";

import DxButton from "devextreme-vue/button";
import DxSelectBox from "devextreme-vue/select-box";
import DxNumberBox from "devextreme-vue/number-box";
// import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";
import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";
import { DxScrollView } from "devextreme-vue/scroll-view";
import { DataSourceIncertidumbres } from "@/data/instrumentos/calibraciones/IncertidumbresDataSource";
import DxDataGrid, { DxColumn, DxEditing } from "devextreme-vue/data-grid";

import Display from "../../core/Display.vue";
import Lecturas from "./Lecturas.vue";

export default {
  props: ["instrumento", "calibracion", "calibracionLinea"],
  data() {
    return {
      isPopupVisible: true,
      componentKey: 0,
      titulo: `Mesurando: ${this.$props.instrumento.codigo} - ${this.$props.instrumento.nombre} / ${this.$props.calibracionLinea.caracteristica}`,
      patrones: [],
      patron: this.$props.calibracionLinea.id_patron_usado,
      incertidumbresStore: new DataSourceIncertidumbres(
        this.$props.instrumento.id,
        this.$props.calibracion.id,
        this.$props.calibracionLinea.id
      ),

      k: Number(this.$props.calibracionLinea.k_usada ?? this.$props.calibracionLinea.k),
      formula: this.$props.calibracionLinea.formula ?? DEFAULT_FORMULA,

      lecturas: null,
      rowIndex: 0,
    };
  },

  components: {
    DxButton,
    DxSelectBox,
    DxNumberBox,
    DxPopup,
    DxToolbarItem,
    DxScrollView,
    DxDataGrid,
    DxColumn,
    DxEditing,
    Display,
    Lecturas,
  },

  computed: {
    toolbarItemCancelarOptions() {
      return {
        text: this.$t("cancelar"),
        type: "normal",
        onClick: this.onClickButtonCancelar,
      };
    },
    toolbarItemAceptarOptions() {
      return {
        text: this.$t("aceptar"),
        stylingMode: "contained",
        type: "default",
        onClick: this.onClickButtonAceptar,
      };
    },

    //incertidumbre expandida: U
    u() {
      if (this.k === null) return null;
      const incertidumbres = this.incertidumbresStore.items();
      incertidumbres.map((i) => i.incertidumbre ?? 0);
      if (incertidumbres.length === 0) return null;
      return Math.max(...incertidumbres.map((i) => i.incertidumbre ?? 0));
    },
  },

  methods: {
    async load() {
      const urls = [
        `${global.API_URL}/instrumentos/patrones`,
        // `${global.API_URL}/instrumentos/${this.$props.instrumento.id}/calibraciones/${this.$props.calibracion.id}/lineas/${this.$props.calibracionLinea.id}/incertidumbres`,
      ];
      //obtengo todos los fetch en paralelo
      const responses = await Promise.all(urls.map((u) => this.$fetch.get(u)));
      const data = responses; // await Promise.all(responses.map((r) => r.json()));

      this.patrones = data[0];

      if (this.k) {
        // cargo toda la información para poder calcular las incertidumbres:
        const store = this.$refs.grid.instance.getDataSource().store();
        await store.load();
        // const items = this.$refs.grid.instance.getDataSource().items();

        this.updateAllIncertidumbres();
        this.calculateIncertidumbreExpandida();
      }
    },

    onClickButtonCancelar() {
      this.isPopupVisible = false;
    },

    onClickButtonAceptar() {
      // preparar el resultado
      const result = {
        patron: this.patron,
        k: this.k,
        u: this.k ? this.u : null,
        formula: this.formula,
      };

      // @TODO cancelar cuando haya un error
      // e.cancel = false;
      // console.log("guardar!!", result);

      this.$emit("save", result);

      // cerrar el popup!
      this.isPopupVisible = false;
    },

    onHiding(e) {
      this.$emit("hiding", e);
    },

    onHidden() {
      this.$emit("hidden");
    },

    onInitNewRow(e) {
      e.data.k_patron = 3;
    },

    onClickMedia(data) {
      this.lecturas = data.data.lecturas?.split("|").map((v) => Number(v)) ?? [];
      this.rowIndex = data.rowIndex;
    },

    onClosedIncertidumbre() {
      this.$refs.grid.instance.cellValue(this.rowIndex, "lecturas", this.lecturas.join("|"));
      this.lecturas = null;
    },

    setKPatron(newData, value, currentRowData) {
      newData.k_patron = value;

      newData.incertidumbre = this.calculateIncertidumbre(
        this.k,
        currentRowData.lecturas?.split("|").length,
        currentRowData.incertidumbre_patron,
        newData.k_patron,
        currentRowData.correccion,
        currentRowData.desviacion
      );
    },

    setIncertidumbrePatron(newData, value, currentRowData) {
      newData.incertidumbre_patron = value;

      newData.incertidumbre = this.calculateIncertidumbre(
        this.k,
        currentRowData.lecturas?.split("|").length,
        newData.incertidumbre_patron,
        currentRowData.k_patron,
        currentRowData.correccion,
        currentRowData.desviacion
      );
    },

    setValorPatron(newData, value, currentRowData) {
      newData.valor_patron = value;

      if (currentRowData.media) newData.correccion = value - currentRowData.media;

      newData.incertidumbre = this.calculateIncertidumbre(
        this.k,
        currentRowData.lecturas?.split("|").length,
        currentRowData.incertidumbre_patron,
        currentRowData.k_patron,
        newData.correccion,
        currentRowData.desviacion
      );
    },

    setLecturas(newData, value, currentRowData) {
      newData.lecturas = value;

      newData.desviacion = stddev(this.lecturas);
      newData.media = average(this.lecturas);

      if (currentRowData.valor_patron) newData.correccion = currentRowData.valor_patron - newData.media;

      newData.incertidumbre = this.calculateIncertidumbre(
        this.k,
        newData.lecturas.split("|").length,
        currentRowData.incertidumbre_patron,
        currentRowData.k_patron,
        newData.correccion,
        newData.desviacion
      );
    },

    onKChanged(e) {
      this.k = e.value;

      this.updateAllIncertidumbres();
      this.calculateIncertidumbreExpandida();
    },

    calculateIncertidumbre(k, n, Up, kp, correccion, desviacion) {
      if (k === null || n === null || Up === null || kp === null || correccion === null || desviacion === null)
        return null;
      if (isNaN(k) || isNaN(n) || isNaN(Up) || isNaN(kp) || isNaN(correccion) || isNaN(desviacion)) return null;
      const u = k * Math.sqrt(Math.pow(Up / kp, 2) + (1 + 1 / n) * Math.pow(desviacion, 2) + Math.pow(correccion, 2));
      return Number(u.toPrecision(2));
    },

    updateAllIncertidumbres() {
      // const store = this.$refs.grid.instance.getDataSource().store();
      const items = this.$refs.grid.instance.getDataSource().items();

      items.forEach((item) => {
        item.incertidumbre = this.calculateIncertidumbre(
          this.k,
          item.lecturas.split("|").length,
          item.incertidumbre_patron,
          item.k_patron,
          item.correccion,
          item.desviacion
        );

        this.$refs.grid.instance.getDataSource().store().update(item.id, item);
      });
    },

    calculateIncertidumbreExpandida() {
      // const store = this.$refs.grid.instance.getDataSource().store();
      const items = this.$refs.grid.instance.getDataSource().items();

      this.incertidumbre = Math.max(...items.map((e) => e.incertidumbre));
    },
  },

  async mounted() {},

  async created() {
    // console.log("created!!");
    // necesito llamar al load porque estoy llamando al endpoint de instrumentos/id a mano..
    // @TODO mirar de hacer un datasource o algo mejor...
    await this.load();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.formula {
  mix-blend-mode: multiply;
}

.dx-swatch-additional .formula {
  filter: invert(100%);
  mix-blend-mode: exclusion;
}

.fixed {
  position: fixed;
  bottom: 0;
  left: 0;
}
</style>
