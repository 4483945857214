<template>
  <div>
    <Prompt ref="prompt"></Prompt>

    <DxDropDownButton
      icon="add"
      type="default"
      styling-mode="contained"
      :hint="$t('nueva_caracteristica')"
      :text="$t('nueva_caracteristica')"
      @buttonClick="onClick"
      :items="actions"
      @itemClick="onItemClick"
      :splitButton="true"
      :dropDownOptions="{ width: 300 }"
    >
    </DxDropDownButton>

    <DxPopup
      :title="$t('seleccionarCaracteristicas')"
      :visible.sync="isPopupSeleccionarCaracteristicasVisible"
      width="90%"
      height="90%"
      :resizeEnabled="true"
      @hidden="
        () => {
          this.selectedPlanID = null;
          this.selectedEstructuraID = null;
          $refs.gridCaracteristicas.instance.clearSelection();
          $refs.gridCaracteristicas.instance.clearFilter('search');
        }
      "
    >
      <DxSelectBox
        :label="$t('plan')"
        v-model="selectedPlanID"
        :dataSource="planesControlDataSource"
        displayExpr="referencia"
        :searchEnabled="true"
        :searchExpr="['referencia', 'nombre']"
        searchMode="contains"
        valueExpr="id"
        @valueChanged="
          () => {
            this.selectedEstructuraID = null;
            this.selectedRowKeys = [];
          }
        "
      >
        <template #item="{ data }">
          <div>
            {{ data.referencia }}<br />
            <small class="text-small">{{ data.nombre }}</small>
          </div>
        </template>
      </DxSelectBox>

      <DxDataGrid
        class="mt-4"
        ref="gridCaracteristicas"
        height="500"
        :allow-column-resizing="true"
        :selection="{ mode: 'multiple' }"
        :dataSource="caracteristicasDataSource"
        @selectionChanged="onSelectionChangedCaracteristicas"
      >
        <DxSearchPanel :visible="true" :width="240" />

        <!-- fase id_pcestructura -->

        <DxColumn data-field="id_pcestructura" :caption="$t('fase')" sort-order="asc">
          <DxLookup display-expr="descripcion" value-expr="id" :data-source="estructuraCustomStore"> </DxLookup>
        </DxColumn>

        <DxColumn data-field="linea" :caption="$t('linea')" :width="60" :allowFiltering="false"> </DxColumn>

        <DxColumn data-field="id" :caption="$t('numero')" :width="60" :allowFiltering="false" :visible="false" />

        <DxColumn
          data-field="nombre"
          :caption="$t('caracteristica')"
          :allowFiltering="false"
          edit-cell-template="caracteristicaEditor"
        >
        </DxColumn>

        <DxColumn data-field="id_caracteristicastipo" :caption="$t('tipo')">
          <DxLookup display-expr="codigo" value-expr="id" :data-source="caracteristicasTipoCustomStore"> </DxLookup>
        </DxColumn>

        <DxColumn :encodeHtml="false" data-field="especificacion" :caption="$t('especificacionProducto')" />

        <DxColumn data-field="id_unidades" :caption="$t('unidad')" :visible="false">
          <DxLookup display-expr="codigo" value-expr="id" :data-source="unidadesCustomStore"> </DxLookup>
        </DxColumn>

        <DxColumn data-field="id_metodoevaluacion" :caption="$t('metodoEvaluacion')">
          <DxLookup display-expr="descripcion" value-expr="id" :data-source="metodoEvaluacionCustomStore"> </DxLookup>
        </DxColumn>

        <DxColumn :width="70" data-field="tamano_muestra" :caption="$t('tamano') + '/' + $t('recorridoMovil')" />

        <DxColumn data-field="id_frecuencias" :caption="$t('frecuencia')">
          <DxLookup display-expr="descripcion" value-expr="id" :data-source="frecuenciaCustomStore"> </DxLookup>
        </DxColumn>

        <DxColumn data-field="id_instrumentos" :caption="$t('calibre')" :visible="false">
          <DxLookup display-expr="nombre" value-expr="id" :data-source="instrumentosCustomStore"> </DxLookup>
        </DxColumn>

        <DxColumn data-field="token_usuariosrol" :caption="$t('realizadoPor')" cell-template="rolTemplate">
          <DxLookup :dataSource="rolesCustomStore" :display-expr="(a) => [a.codigo, a.descripcion]" value-expr="id" />
        </DxColumn>

        <template #rolTemplate="{ data: templateOptions }">
          <div class="tag-container text-wrap gap-1 d-flex flex-wrap">
            <div
              v-for="role in templateOptions.value"
              :key="role.id"
              class="badge text-bg-secondary"
              :title="templateOptions.column.lookup.calculateCellValue(role, true)[1]"
            >
              {{ templateOptions.column.lookup.calculateCellValue(role, true)[0] }}
            </div>
          </div>
        </template>

        <DxColumn data-field="reaccion" :caption="$t('reaccion')" :visible="false"></DxColumn>

        <DxColumn data-field="observaciones" :caption="$t('observaciones')" :visible="false"></DxColumn>

        <DxColumn data-field="analisis" :caption="$t('analisis')" data-type="boolean"> </DxColumn>
      </DxDataGrid>

      <DxToolbarItem :options="toolbarItemCancelarOptions" widget="dxButton" location="after" toolbar="bottom" />
      <DxToolbarItem :options="toolbarItemSeleccionarOptions" widget="dxButton" location="after" toolbar="bottom" />
    </DxPopup>
  </div>
</template>

<script>
import { DxDropDownButton, DxSelectBox } from "devextreme-vue";
import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";

import {
  DxDataGrid,
  DxColumn,
  DxLookup,
  DxSearchPanel,
  // DxFilterPanel,
  // DxFilterRow,
  // DxHeaderFilter,
} from "devextreme-vue/data-grid";

import Prompt from "@/components/prompt";

import { PlanesControlDataSource } from "@/data/plancontrol/PlanesControlDataSource";
// import { EstructuraDataSource } from "@/data/estructuras/EstructuraDataSource";
import { DataSourceCaracteristicas } from "@/data/caracteristicas/CaracteristicasDataSource";
import { CustomStoreCaracteristicas } from "../../../data/caracteristicas/CaracteristicasDataSource";

import { EstructuraCustomStore } from "../../../data/plancontrol/EstructuraDataSource";
import { CustomStoreCaracteristicasTipo } from "../../../data/caracteristicas/CaracteristicasTipoDataSource";
import { CustomStoreInstrumentos } from "../../../data/instrumentos/InstrumentoDataSource";
import { MetodosEvaluacionCustomStore } from "../../../data/caracteristicas/MetodosEvaluacionDataSource";
import { FrecuenciasCustomStore } from "../../../data/caracteristicas/FrecuenciasDataSource";
import { UnidadesCustomStore } from "../../../data/plancontrol/UnidadesDataSource";
import { RolesCustomStore } from "../../../data/usuarios/RolesDataSource";

const COPIAR_CARACTERISTICA_DE_OTRO_PLAN = 1;

export default {
  props: ["estructuraID", "planID"],
  components: {
    DxDropDownButton,
    DxPopup,
    DxLookup,
    DxToolbarItem,
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    Prompt,
    // DxFilterPanel,
    // DxFilterRow,
    // DxHeaderFilter,
    DxSelectBox,
  },
  data() {
    return {
      actions: [
        {
          id: 1,
          text: this.$t("importaDeOtroPlanDeControl"),
          hint: this.$t("importaDeOtroPlanDeControl"),
        },
      ],

      isPopupSeleccionarCaracteristicasVisible: false,
      // dataSourceInstrumentos: new DataSourceInstrumentos({
      //   filterQuery: "?hasCalibracion=true",
      // }),
      // dataSourceCalibraciones: new DataSourceCalibracionesInstrumento(id_instrumento),

      selectedInstrumento: null,

      toolbarItemCancelarOptions: {
        text: this.$t("cancelar"),
        onClick: () => {
          this.isPopupSeleccionarCaracteristicasVisible = false;
        },
      },

      caracteristicasCustomStore: new CustomStoreCaracteristicas(),
      planesControlDataSource: new PlanesControlDataSource(),

      //estructuraCustomStore: new EstructuraCustomStore(>>necesto el plan<<),  // ver computed
      caracteristicasTipoCustomStore: new CustomStoreCaracteristicasTipo(),
      instrumentosCustomStore: new CustomStoreInstrumentos(),
      metodoEvaluacionCustomStore: new MetodosEvaluacionCustomStore(),
      frecuenciaCustomStore: new FrecuenciasCustomStore(),
      unidadesCustomStore: new UnidadesCustomStore(),
      rolesCustomStore: new RolesCustomStore(),

      selectedPlanID: null, //Number(this.planID),
      selectedEstructuraID: null, // Number(this.estructuraID),

      selectedRowKeys: this.$refs.gridCaracteristicas?.instance.getSelectedRowKeys() ?? [],
    };
  },
  computed: {
    // dataSourceCalibraciones: function () {
    //   if (!this.selectedInstrumento) return null;
    //   return new DataSourceCalibracionesInstrumento(this.selectedInstrumento);
    // },

    estructuraCustomStore: function () {
      if (!this.selectedPlanID) return null;
      return new EstructuraCustomStore(this.selectedPlanID);
    },

    caracteristicasDataSource: function () {
      if (!this.selectedPlanID) return null;

      return new DataSourceCaracteristicas({
        idEstructura: this.selectedEstructuraID,
        idPlan: this.selectedPlanID,
      });
    },

    toolbarItemSeleccionarOptions: function () {
      return {
        text: this.$t("seleccionar"),
        type: "default",
        stylingMode: "contained",
        disabled: this.selectedRowKeys.length === 0,
        onClick: () => {
          this.copyCaracteristicas(this.selectedRowKeys, this.estructuraID);
          this.isPopupSeleccionarCaracteristicasVisible = false;
        },
      };
    },
  },
  methods: {
    async onClick() {
      let nombre;

      try {
        nombre = await this.$refs.prompt.prompt(
          this.$t("nueva_caracteristica"),
          this.$t("nombre_de_la_caracteristica"),
          nombre
        );
      } catch (ex) {
        console.log("se ha cancelado la nueva caracteristica", ex);
        return;
      }

      const inserted = await this.caracteristicasCustomStore.insert({
        id_pcestructura: this.estructuraID,
        nombre: nombre,
        analisis: true,
        // id_metodoanalisis: null,
        // id_condiciones: null,
        // id_caracteristicastipo: null,
        // id_metodoevaluacion: null,
        // id_frecuencias: null,
        // id_unidades: null,
        // id_instrumentos: null,
        // calibres: null,
        // analisis: false,
        // reaccion: null,
        // especificacion: null,
        // tolerancia1: null,
        // tolerancia2: null,
        // valor_pedido: null,
        // tamano_muestra: null,
      });

      this.$emit("new", inserted);
    },

    async onItemClick(e) {
      console.log("onItemClick", e);
      if (e.itemData.id === COPIAR_CARACTERISTICA_DE_OTRO_PLAN) {
        this.goToCaracteristicaSelection();
      }
    },

    onSelectionChangedCaracteristicas(e) {
      this.selectedRowKeys = e.selectedRowKeys;
    },

    goToCaracteristicaSelection() {
      this.isPopupSeleccionarCaracteristicasVisible = true;
    },

    async copyCaracteristicas(selectedRowKeys, to) {
      console.log("copyCaracteristicas", selectedRowKeys, to);

      const response = await this.caracteristicasCustomStore.copy(selectedRowKeys, to);
      console.log(response);

      this.$emit("copied", response);
    },
  },
};
</script>
