<template>
  <div class="d-flex flex-column gap-3">
    <!--@TODO convertir en <table> ??? -->
    <div class="defectos">
      <div class="d-flex flex-row gap-3">
        <div class="form-label text-right flex-fill">{{ $t("defecto") }}</div>
        <div class="form-label">{{ $t("cantidad") }}/{{ $t("tamanoMuestra") }}</div>
      </div>

      <div v-for="def in mdef" class="d-flex flex-row pt-1 align-items-start" :key="def.id" style="gap: 20px">
        <div class="flex-fill text-right w-100" v-bind:title="def.defecto">
          {{ def.defecto }}
        </div>
        <div class="d-flex justify-content-center align-items-center">
          <DxNumberBox
            :id="def.id"
            :value="
              analisis._valores.find((e) => e.id_caracteristicamdef === def.id)
                ? analisis._valores.find((e) => e.id_caracteristicamdef === def.id).cantidad
                : null
            "
            :elementAttr="{ 'data-defecto': def.id }"
            :showSpinButtons="true"
            :step="1"
            :min="0"
            :max="analisis.tamano_muestra"
            @valueChanged="onValueChangedDefecto"
            width="50%"
          >
            <DxValidator>
              <DxRangeRule :max="analisis.tamano_muestra"> </DxRangeRule>
            </DxValidator>
          </DxNumberBox>
          <span class="p-2 w-auto"> / {{ analisis.tamano_muestra }} </span>
        </div>
      </div>

      <div class="d-flex flex-row pt-3" style="gap: 10px">
        <div class="form-label text-right flex-fill"></div>
        <Display :value="analisis.total_defectuosas" :label="$t('totalDefectos')" />
      </div>
    </div>

    <div class="mt-4">
      <div class="d-flex justify-content-center align-items-start gap-3">
        <DxNumberBox
          :label="$t('tamanoMuestra')"
          v-model="analisis.tamano_muestra"
          :min="0"
          :showSpinButtons="true"
          :step="1"
          :width="150"
          :format="tamanoMuestraFormat"
        >
          <DxValidator>
            <DxRequiredRule :message="$t('errorValorRequerido')" />
          </DxValidator>
        </DxNumberBox>

        <Display :value="caracteristica?.tolerancia1 ?? 0" :label="$t('tolerancia')" />

        <!-- <div class="d-flex flex-column analisis tolerancia readonly">
          <label class="form-label">{{ $t("tolerancia") }}</label>
          <div class="form-data">{{ caracteristica.tolerancia1 ? caracteristica.tolerancia1 : 0 }}%</div>
        </div> -->

        <Display :value="max.toFixed(4)" label="% max" v-if="caracteristica.id_condiciones === 9" />

        <!-- <div class="d-flex flex-column analisis max readonly" v-if="caracteristica.id_condiciones === 9">
          <label class="form-label">% max</label>
          <div class="form-data">{{ max.toFixed(4) }}%</div>
        </div> -->

        <Display :value="sum.toFixed(4)" label="% sum" v-if="caracteristica.id_condiciones === 10" />

        <!-- <div class="d-flex flex-column analisis sum readonly" v-if="caracteristica.id_condiciones === 10">
          <label class="form-label">% sum</label>
          <div class="form-data">{{ sum.toFixed(4) }}%</div>
        </div> -->
      </div>

      <div class="text-center mt-3" style="height: 50px">
        <span v-if="caracteristica.id_condiciones === 9 && rechazoMax" class="fs-6 badge text-bg-danger">
          {{ $t("rechazado") }}
        </span>
        <span v-if="caracteristica.id_condiciones === 10 && rechazoSum" class="fs-6 badge text-bg-danger">
          {{ $t("rechazado") }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import DxNumberBox from "devextreme-vue/number-box";
import { DxValidator, DxRequiredRule, DxRangeRule } from "devextreme-vue/validator";

import Display from "../../core/Display.vue";

import auth from "@/auth";

function handleErrors(response) {
  return response;
}

export default {
  props: ["value", "caracteristica"],
  data() {
    return {
      mdef: [],
    };
  },

  created() {
    // console.log("MDef.vue created");
    this.$set(this.analisis, "_valores", this.analisis._valores || []);
  },

  watch: {
    valores: {
      immediate: true,
      handler: async function (val) {
        const aux = val.map((e) => e.cantidad);
        this.analisis.total_defectuosas = aux.reduce((acc, v) => acc + v, 0);
      },
    },
  },

  computed: {
    analisis: function () {
      return this.value;
    },

    valores: function () {
      return this.analisis._valores || [];
    },

    max() {
      if (this.valores.length === 0) return 0;
      const max = Math.max(...this.valores.map((e) => e.cantidad));
      return (max * 100) / this.analisis.tamano_muestra;
    },

    sum() {
      const sum = this.analisis.total_defectuosas;
      return (sum * 100) / this.analisis.tamano_muestra;
    },

    rechazoMax: function () {
      return this.hayRechazoMax();
    },

    rechazoSum: function () {
      return this.hayRechazoSum();
    },

    tamanoMuestraFormat: function () {
      return `#0 (${this.caracteristica.tamano_muestra})`;
    },
  },

  mounted() {
    // console.log("MDef.vue mounted");

    this.analisis.tamano_muestra = this.caracteristica.tamano_muestra;

    fetch(`${global.API_URL}/caracteristicas/${this.caracteristica.id}/mdef`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .then((data) => {
        this.mdef = data
          // .filter((x) => x.id_caracteristica === this.caracteristica.id)
          .sort((a, b) => a.linea - b.linea);
      })
      .catch(() => {
        throw "Network error";
      });
  },

  components: {
    DxNumberBox,
    DxValidator,
    DxRequiredRule,
    DxRangeRule,
    Display,
  },
  methods: {
    onValueChangedDefecto(e) {
      const data = {
        id_caracteristicamdef: parseInt(e.element.dataset.defecto),
        cantidad: e.value,
      };

      const index = this.valores.findIndex((x) => x.id_caracteristicamdef == e.element.dataset.defecto);

      if (index >= 0) {
        // Se ha encontrado la cantidad de defectos en el array!
        // Elimino el valor si la cantidad es 0 o null
        if (!data.cantidad) return this.valores.splice(index, 1);
        // Modifico el valor del array con $set para que sea reactivo.
        this.$set(this.valores, index, data);
      } else {
        // Añado el elemento al array si la cantidad es diferente de 0.
        if (data.cantidad) this.valores.push(data);
      }
    },

    hayRechazoMax() {
      let tolerancia = this.caracteristica.tolerancia1 ? this.caracteristica.tolerancia1 : 0;

      return this.max > tolerancia;
    },

    hayRechazoSum() {
      let tolerancia = this.caracteristica.tolerancia1 ? this.caracteristica.tolerancia1 : 0;

      return this.sum > tolerancia;
    },
  },
};
</script>
