<template>
  <div>
    <div v-if="$store.state.debug">
      <code>
        <pre>{{ JSON.stringify(dataSourceEstadoInstrumento, null, 2) }}</pre>
      </code>
      <br />
      <code>
        <pre>{{ JSON.stringify(dataSourceTipoInstrumento, null, 2) }}</pre>
      </code>
      <br />
      <code>
        <pre>{{ JSON.stringify(instrumento, null, 2) }}</pre>
      </code>
    </div>
    <div class="item">
      <div class="d-flex flex-wrap mt-4 gap-3">
        <Display :label="$t('codigo')" :required="true" :value="instrumento.codigo" />
        <Display :label="$t('nombre')" :required="true" :value="instrumento.nombre" />
        <Display :label="$t('tipo')">
          <ValueWithImage :value="$t(dataSourceTipoInstrumento.find((x) => x.id === instrumento.tipo))" />
        </Display>
        <Display :label="$t('estado')">
          <ValueWithImage :value="$t(dataSourceEstadoInstrumento.find((x) => x.id === instrumento.estado))" />
        </Display>

        <Display
          :label="$t('fecha_alta')"
          :value="instrumento?.fecha_alta && $moment(instrumento?.fecha_alta).format('L')"
        />
        <Display v-if="instrumento?.id_instrumentos" :label="$t('sustituye_a')" :value="displaySustituye?.nombre" />
        <Display
          :label="$t('fecha_baja')"
          :value="instrumento?.fecha_baja && $moment(instrumento?.fecha_baja).format('L')"
        />
      </div>

      <div class="d-flex flex-wrap mt-4 gap-3">
        <Display :label="$t('serie')" :value="instrumento?.serie" />
        <Display :label="$t('marca')" :value="instrumento?.marca" />
        <Display :label="$t('modelo')" :value="instrumento?.modelo" />
        <Display :label="$t('norma')" :value="instrumento?.norma" />
      </div>

      <div class="d-flex flex-wrap mt-4 gap-3">
        <Display
          :label="$t('clasificacion')"
          :value="displayClasificacion?.nombre"
          :subValue="displayClasificacion?.codigo"
        />
        <Display :label="$t('categoria')" :value="displayCategoria?.categoria" :subValue="displayCategoria?.codigo" />
        <Display
          :label="$t('departamento')"
          :value="displayDepartamento?.descripcion"
          :subValue="displayDepartamento?.codigo"
        />
        <Display :label="$t('cliente')" :value="displayCliente?.nombre" :subValue="displayCliente?.codigo" />
      </div>

      <div class="d-flex flex-wrap mt-4 gap-3">
        <DisplayProveedor :value="displayProveedor" />
        <Display
          :label="$t('fecha_compra')"
          :value="instrumento?.fecha_compra && $moment(instrumento?.fecha_compra).format('L')"
        />
        <Display :label="$t('precio')" :value="displayPrecio" />
      </div>

      <div class="d-flex flex-wrap mt-4 gap-3">
        <Display :label="$t('cota_minima')" :value="instrumento?.cota_minima" />
        <Display :label="$t('cota_maxima')" :value="instrumento?.cota_maxima" />
        <Display :label="$t('precision_elemento')" :value="instrumento?.precision_elemento" />
        <Display :label="$t('precision_proceso')" :value="instrumento?.precision_proceso" />
      </div>

      <!-- <div class="d-flex align-items-center mt-4 gap-1">
        <div class="text-center" style="writing-mode: vertical-lr; text-orientation: mixed; transform: scale(-1, -1)">
          {{ $t("calibracion") }}
        </div>
        <div class="border border-primary border-5 border-top-0 border-end-0 border-bottom-0 ps-3">
          <DisplayCalibracion :instrumento="instrumento"></DisplayCalibracion>
        </div>
      </div> -->

      <DisplayCalibracion :instrumento="instrumento"></DisplayCalibracion>

      <!-- <div class="d-flex align-items-center mt-4 gap-1">
        <div class="text-center" style="writing-mode: vertical-lr; text-orientation: mixed; transform: scale(-1, -1)">
          {{ $t("verificacion") }}
        </div>
        <div class="border border-primary border-5 border-top-0 border-end-0 border-bottom-0 ps-3">
          <DisplayVerificacion :instrumento="instrumento"></DisplayVerificacion>
        </div>
      </div> -->

      <DisplayVerificacion class="mt-4" :instrumento="instrumento"></DisplayVerificacion>

      <div class="d-flex flex-wrap mt-4 gap-3">
        <Display :label="$t('notas')" :value="instrumento?.notas" :full="true" />
      </div>
    </div>
  </div>
</template>
<script>
import tipos from "@/data/instrumentos/Tipos";
import estados from "@/data/instrumentos/Estados";

import CustomStore from "devextreme/data/custom_store";

import Display from "../core/Display.vue";
import DisplayProveedor from "../core/DisplayProveedor.vue";

import ValueWithImage from "../core/ValueWithImage.vue";
import DisplayCalibracion from "./DisplayCalibracion.vue";
import DisplayVerificacion from "./DisplayVerificacion.vue";

import { DataSourceProveedores } from "@/data/ProveedorDataSource";

export default {
  props: [
    "instrumento", //Será reactivo cuando haga el update. se sincronizará con un $emit
  ],
  data() {
    return {
      componentKey: 0,
      proveedorStore: new DataSourceProveedores(),
      agrupaciones: new CustomStore({
        key: "id",
        byKey: (key) => this.$fetch.get(`${global.API_URL}/agrupaciones/${key}`),
      }),
    };
  },

  asyncComputed: {
    displayCategoria: {
      async get() {
        if (!this.instrumento.id_categorias) return "";
        return await this.$fetch.get(`${global.API_URL}/instrumentos/categorias/${this.instrumento.id_categorias}`);
      },
      default() {
        return "Loading...";
      },
    },
    displayProveedor: {
      async get() {
        if (!this.instrumento.id_proveedores) return "";
        return await this.$fetch.get(`${global.API_URL}/proveedores/${this.instrumento.id_proveedores}`);
      },
      default() {
        return "Loading...";
      },
    },
    displayDepartamento: {
      async get() {
        if (!this.instrumento.id_departamentos) return "";
        return await this.$fetch.get(`${global.API_URL}/departamentos/${this.instrumento.id_departamentos}`);
      },
      default() {
        return "Loading...";
      },
    },
    displayCliente: {
      async get() {
        if (!this.instrumento.id_clientes) return "";
        return await this.$fetch.get(`${global.API_URL}/clientes/${this.instrumento.id_clientes}`);
      },
      default() {
        return "Loading...";
      },
    },
    displayClasificacion: {
      async get() {
        if (!this.instrumento.id_clasificacion) return "";
        return await this.$fetch.get(
          `${global.API_URL}/instrumentos/clasificacion/${this.instrumento.id_clasificacion}`
        );
      },
      default() {
        return "Loading...";
      },
    },
    displaySustituye: {
      async get() {
        if (!this.instrumento.id_instrumentos) return "";
        return await this.$fetch.get(`${global.API_URL}/instrumentos/${this.instrumento.id_instrumentos}`);
      },
      default() {
        return "Loading...";
      },
    },
  },
  computed: {
    displayPrecio: function () {
      return this.instrumento?.precio?.toLocaleString(undefined, { style: "currency", currency: "EUR" });
    },
    dataSourceTipoInstrumento() {
      return tipos.map((e) => ({
        ...e,
        value: this.$t(e.value),
      }));
    },
    dataSourceEstadoInstrumento() {
      return estados.map((e) => ({
        ...e,
        value: this.$t(e.value),
      }));
    },

    idioma: function () {
      return this.$i18n.locale;
    },
  },
  watch: {
    idioma() {
      this.componentKey++;
    },
  },

  components: { Display, ValueWithImage, DisplayCalibracion, DisplayVerificacion, DisplayProveedor },
};
</script>
