export default [
    {
        nivel: 1,
        id: 11,
        value: "tipoOperacion",
        pantalla: "Pantalla1"
    },
    {
        nivel: 1,
        id: 12,
        value: "tipoAlmacenamiento",
        pantalla: "Pantalla1"
    },
    {
        nivel: 1,
        id: 13,
        value: "tipoEntradaBienes",
        pantalla: "Pantalla1"
    },
    {
        nivel: 1,
        id: 14,
        value: "tipoTransportar",
        pantalla: "Pantalla1"
    },
    {
        nivel: 1,
        id: 15,
        value: "tipoEsperar",
        pantalla: "Pantalla1"
    },
    {
        nivel: 1,
        id: 16,
        value: "tipoRecepcionMateriales",
        pantalla: "Pantalla1"
    },
    {
        nivel: 2,
        id: 21,
        value: "tipoInspeccionProceso",
        pantalla: "Pantalla2"
    },
    {
        nivel: 2,
        id: 22,
        value: "tipoInspeccionProducto",
        pantalla: "Pantalla3"
    },
    {
        nivel: 3,
        id: 31,
        value: "tipoAnalisisAspecto",
        pantalla: "Caracteristicas"
    },
    {
        nivel: 3,
        id: 32,
        value: "tipoAnalisisAtributos",
        pantalla: "Caracteristicas"
    },
    {
        nivel: 3,
        id: 33,
        value: "tipoContrasteNorma",
        pantalla: "Caracteristicas"
    },
    {
        nivel: 3,
        id: 34,
        value: "tipoAnalisisDimensional",
        pantalla: "Caracteristicas"
    },
    {
        nivel: 3,
        id: 35,
        value: "tipoAnalisisElectrico",
        pantalla: "Caracteristicas"
    },
    {
        nivel: 3,
        id: 36,
        value: "tipoAnalisisEmbalaje",
        pantalla: "Caracteristicas"
    },
    {
        nivel: 3,
        id: 37,
        value: "tipoAnalisisGeneral",
        pantalla: "Caracteristicas"
    },
    {
        nivel: 3,
        id: 38,
        value: "tipoAnalisisMecanico",
        pantalla: "Caracteristicas"
    },
    {
        nivel: 3,
        id: 39,
        value: "tipoAnalisisQuimico",
        pantalla: "Caracteristicas"
    },
    {
        nivel: 3,
        id: 40,
        value: "tipoAnalisisVariables",
        pantalla: "Caracteristicas"
    }
]
