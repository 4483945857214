<template>
  <div class="w-100 d-flex flex-column gap-4 p-3 border rounded">
    <h3>MaxMin</h3>
    <div class="d-flex flex-row w-100 gap-3">
      <div class="d-flex flex-column w-25">
        <DxNumberBox
          :label="$t('valorPedido')"
          :value.sync="caracteristicaComputed.valor_pedido"
          :showSpinButtons="true"
        >
          <DxValidator>
            <DxRequiredRule :message="$t('errorValorPedidoRequerido')" />
          </DxValidator>
        </DxNumberBox>
      </div>

      <div class="d-flex flex-column w-25">
        <DxSelectBox
          :label="$t('condicion')"
          :value.sync="caracteristicaComputed.id_condiciones"
          :search-enabled="true"
          search-mode="contains"
          :search-expr="['codigo']"
          display-expr="codigo"
          value-expr="id"
          :data-source="condicionesDataSource"
          :show-clear-button="true"
          @valueChanged="onValueChangedCondiciones"
        >
          <DxValidator>
            <DxRequiredRule :message="$t('errorMetodoAnalisisRequerido')" />
          </DxValidator>
        </DxSelectBox>
      </div>

      <!-- <div class="d-flex flex-column w-100">
            <label class="form-label">{{ $t("tolerancia") }}</label>
            <div class="form-data">
              <DxTextBox :value.sync="caracteristicaComputed.tolerancia"></DxTextBox>
            </div>
          </div> -->
      <div class="d-flex flex-column w-25 gap-3">
        <div class="d-flex flex-column w-100">
          <DxNumberBox
            :label="$t('tolerancia1')"
            :value.sync="caracteristicaComputed.tolerancia1"
            :showSpinButtons="true"
          >
            <DxValidator>
              <DxRequiredRule :message="$t('errorToleranciaRequerido')" />

              <DxCompareRule
                v-if="caracteristicaComputed.id_condiciones === ENTRE"
                :comparisonTarget="() => caracteristicaComputed.valor_pedido"
                :message="$t('errorTolerancia1EsMenorQueValorPedido')"
                comparisonType=">="
              />

              <DxCompareRule
                v-if="caracteristicaComputed.id_condiciones === MIN"
                :comparisonTarget="() => caracteristicaComputed.valor_pedido"
                :message="$t('errorTolerancia1EsMayorQueValorPedido')"
                comparisonType="<="
              />

              <DxCompareRule
                v-if="caracteristicaComputed.id_condiciones === MAX"
                :comparisonTarget="() => caracteristicaComputed.valor_pedido"
                :message="$t('errorTolerancia1EsMenorQueValorPedido')"
                comparisonType=">="
              />
            </DxValidator>
          </DxNumberBox>
        </div>
        <div class="d-flex flex-column w-100" v-if="[3, 4, 5, 6].includes(caracteristicaComputed.id_condiciones)">
          <DxNumberBox
            :label="$t('tolerancia2')"
            :value.sync="caracteristicaComputed.tolerancia2"
            :showSpinButtons="true"
          >
            <DxValidator>
              <DxRequiredRule :message="$t('errorToleranciaRequerido')" />
              <DxCompareRule
                v-if="false"
                :comparisonTarget="() => caracteristicaComputed.tolerancia1"
                :message="$t('errorTolerancia2MayorTolerancia2')"
                comparisonType=">="
              />
            </DxValidator>
          </DxNumberBox>
        </div>
      </div>
      <div class="d-flex flex-column w-25">
        <DxSelectBox
          :label="$t('unidades')"
          :value.sync="caracteristicaComputed.id_unidades"
          :search-enabled="true"
          search-mode="contains"
          :search-expr="['codigo']"
          display-expr="codigo"
          value-expr="id"
          :data-source="unidadesDataSource"
          :show-clear-button="true"
        >
        </DxSelectBox>
      </div>

      <div class="d-flex flex-column w-25">
        <DxNumberBox
          :label="$t('tamanoMuestra')"
          :value.sync="caracteristicaComputed.tamano_muestra"
          :showSpinButtons="true"
          :min="0"
          format="#0"
        >
          <DxValidator>
            <DxRequiredRule :message="$t('errorTamanoMuestraRequerido')" />
          </DxValidator>
        </DxNumberBox>
      </div>
    </div>
  </div>
</template>

<script>
import { DxNumberBox } from "devextreme-vue/ui/number-box";
import { DxSelectBox } from "devextreme-vue/ui/select-box";
import { DxValidator, DxRequiredRule, DxCompareRule } from "devextreme-vue/validator";

import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";

import { ENTRE, MAX, MIN } from "../../../../core/metodoAnalisis/constantes";

export default {
  components: {
    DxNumberBox,
    DxSelectBox,
    DxValidator,
    DxCompareRule,
    DxRequiredRule,
  },
  props: ["caracteristica"],
  constants: {
    ENTRE,
    MIN,
    MAX,
  },
  data() {
    return {
      condicionesDataSource: new DataSource({
        paginate: true,
        store: new CustomStore({
          key: "id",
          loadMode: "raw",
          load: () => this.$fetch.get(`${global.API_URL}/analisis/condiciones/xr`),
        }),
      }),

      unidadesDataSource: new DataSource({
        paginate: true,
        store: new CustomStore({
          key: "id",
          loadMode: "raw",
          load: () => this.$fetch.get(`${global.API_URL}/analisis/unidades`),
          // byKey: (key) => this.$fetch.get(`${global.API_URL}/analisis/unidades/${key}`),
          // insert: (values) => this.$fetch.post(`${global.API_URL}/analisis/unidades`, values),
          // update: (key, values) => this.$fetch.put(`${global.API_URL}/analisis/unidades/${key}`, values),
          // remove: (key) => this.$fetch.delete(`${global.API_URL}/analisis/unidades/${key}`),
        }),
      }),
    };
  },
  computed: {
    caracteristicaComputed: {
      get() {
        return this.caracteristica;
      },
      set(value) {
        console.log("caracteristicaComputed:set happened");
        this.$emit("update:caracteristica", value);
      },
    },
    // ENTRE() {
    //   return ENTRE;
    // },
    // MIN() {
    //   return MIN;
    // },
    // MAX() {
    //   return MAX;
    // },
  },
  methods: {
    onValueChangedCondiciones(e) {
      console.log("onValueChangedCondiciones!!", e.value);
      this.resetValues(e.value);
    },

    resetValues() {
      this.caracteristicaComputed.tolerancia2 = null;
    },
  },
};
</script>
