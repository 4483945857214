<template>
  <div class="w-100 d-flex flex-column gap-4 p-3 border rounded">
    <h3>Defectos</h3>
    <div class="d-flex flex-row w-100 gap-3">
      <div class="d-flex flex-column w-25">
        <DictionaryEditor
          :label="$t('valorPedido')"
          :autorefresh="false"
          :title="$t('diccValores')"
          v-model="caracteristicaComputed.valor_pedido"
          value-expr="texto"
          recurso="caracteristicas/valores/diccionario"
        >
          <DxValidator>
            <DxRequiredRule :message="$t('errorValorPedidoRequerido')" />
          </DxValidator>
        </DictionaryEditor>
      </div>

      <div class="d-flex flex-column w-25">
        <DxSelectBox
          :label="$t('condicion')"
          :value.sync="caracteristicaComputed.id_condiciones"
          :search-enabled="true"
          search-mode="contains"
          :search-expr="['codigo']"
          display-expr="codigo"
          value-expr="id"
          :data-source="condicionesDatasource"
          :show-clear-button="true"
          @valueChanged="onValueChangedCondiciones"
        >
          <DxValidator>
            <DxRequiredRule :message="$t('errorMetodoAnalisisRequerido')" />
          </DxValidator>
        </DxSelectBox>
      </div>

      <!-- SOLO SI 100%-->
      <div v-if="caracteristicaComputed.id_condiciones === CIENPORCIEN" class="d-flex flex-column w-25">
        <DxNumberBox
          :label="$t('tolerancia1')"
          :value.sync="caracteristicaComputed.tolerancia1"
          :showSpinButtons="true"
          :min="0"
          :max="100"
        >
          <DxValidator>
            <DxRequiredRule :message="$t('errorToleranciaRequerido')" />

            <DxRangeRule
              :message="$t('errorTolerancia1FueraDeRango')"
              :min="0"
              :max="100"
              v-if="caracteristicaComputed.id_condiciones === CIENPORCIEN"
            />
          </DxValidator>
        </DxNumberBox>
      </div>

      <div v-if="caracteristicaComputed.id_condiciones === CIENPORCIEN" class="d-flex flex-column w-25">
        <DxNumberBox
          :label="$t('tamanoMuestra')"
          :allowNull="true"
          labelMode="static"
          placeholder="TODAS"
          :showClearButton="true"
          :value.sync="caracteristicaComputed.tamano_muestra"
          :showSpinButtons="true"
          :min="0"
        >
          <!-- <DxValidator>
              <DxRequiredRule :message="$t('errorTamanoMuestraRequerido')" />
            </DxValidator> -->
        </DxNumberBox>
      </div>

      <!-- SOLO SI NCA-->
      <div v-if="caracteristicaComputed.id_condiciones === NCA" class="d-flex flex-column w-25">
        <DxSelectBox
          :label="$t('nca')"
          :value.sync="caracteristicaComputed.id_nca"
          :search-enabled="true"
          search-mode="contains"
          :search-expr="['tabla', 'descripcion', 'aql']"
          :display-expr="(item) => (item ? `${item.tabla} - ${item.descripcion} - ${item.aql}` : '')"
          value-expr="id"
          :data-source="ncaDataSource"
          :show-clear-button="true"
        >
          <template #item="{ data }">
            <div class="clientes-item">
              <div v-if="data.tabla">
                <b>{{ $t("tabla") }}</b> {{ data.tabla }}
              </div>
              <div v-if="data.descripcion">
                <b>{{ $t("descripcion") }}</b> {{ data.descripcion }}
              </div>
              <div>
                <b>{{ $t("aql") }}</b> {{ data.aql }}
              </div>
            </div>
          </template>
          <DxValidator>
            <DxRequiredRule :message="$t('errorMetodoAnalisisRequerido')" />
          </DxValidator>
        </DxSelectBox>
      </div>
    </div>
  </div>
</template>

<script>
import DictionaryEditor from "@/components/core/DictionaryEditor.vue";
import { DxSelectBox } from "devextreme-vue/ui/select-box";
import { DxNumberBox } from "devextreme-vue/ui/number-box";
import { DxValidator, DxRequiredRule, DxRangeRule } from "devextreme-vue/validator";

import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";

import { DataSourceNCA } from "@/data/caracteristicas/NCADataSource.js";
import { CIENPORCIEN, NCA } from "../../../../core/metodoAnalisis/constantes";

export default {
  components: {
    DictionaryEditor,
    DxSelectBox,
    DxNumberBox,
    DxValidator,
    DxRequiredRule,
    DxRangeRule,
  },
  props: ["caracteristica"],
  constants: {
    CIENPORCIEN,
    NCA,
  },
  data() {
    return {
      condicionesDatasource: new DataSource({
        paginate: true,
        store: new CustomStore({
          key: "id",
          loadMode: "raw",
          load: () => this.$fetch.get(`${global.API_URL}/analisis/condiciones/def`),
        }),
      }),
      ncaDataSource: new DataSourceNCA(),
    };
  },
  computed: {
    caracteristicaComputed: {
      get() {
        return this.caracteristica;
      },
      set(value) {
        console.log("caracteristicaComputed:set happened");
        this.$emit("update:caracteristica", value);
      },
    },
  },
  methods: {
    onValueChangedCondiciones(e) {
      console.log("onValueChangedCondiciones", e.value);
      this.resetValues(e.value);
    },

    setMinTolerancia1(id_condiciones) {
      return id_condiciones === CIENPORCIEN ? 0 : null;
    },

    setMaxTolerancia1(id_condiciones) {
      return id_condiciones === CIENPORCIEN ? 100 : null;
    },

    resetValues() {
      this.caracteristicaComputed.tolerancia1 = null;
      this.caracteristicaComputed.tamano_muestra = null;
      this.caracteristicaComputed.id_nca = null;
    },
  },
};
</script>
