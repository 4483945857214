<template>
  <div>
    {{ idInstrumento }}
    <RPTInstrumento :idInstrumento="idInstrumento"> </RPTInstrumento>
  </div>
</template>

<script>
import RPTInstrumento from "../../../components/instrumentos/reports/RPTInstrumento.vue";

export default {
  data() {
    return {
      idInstrumento: this.$route.params.id,
    };
  },
  components: {
    RPTInstrumento,
  },
};
</script>
