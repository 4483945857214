<template>
  <div>
    <div v-if="value.caracteristica.length === 0 && value.plan.length === 0" class="text-center">
      {{ $t("noHayNotas") }}
    </div>
    <ul v-else class="notas">
      <li v-for="nota in value.caracteristica" :key="nota.id" class="caracteristica">
        <div>
          {{ nota.nota }}
          <span v-if="nota.fecha_inicio">
            {{ $moment(nota.fecha_inicio).fromNow() }}
            ( {{ $moment(nota.fecha_inicio).format("LLL") }} )
          </span>
        </div>
      </li>

      <li v-for="nota in value.plan" :key="nota.id" class="plan">
        <div>
          {{ nota.nota }}
          <span v-if="nota.fecha_inicio">
            {{ $moment(nota.fecha_inicio).fromNow() }}
            ( {{ $moment(nota.fecha_inicio).format("LLL") }} )
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["value"],
};
</script>

<style scoped>
ul {
  padding-left: 20px;
}

span {
  font-style: italic;
  color: #999;
}

.notas li.caracteristica::marker {
  color: blue;
}

.notas li.plan::marker {
  color: green;
}
</style>
