<template>
  <div>
    <div class="w-50">
      <h3 class="d-flex justify-content-between">
        {{ $t("conjuntosImplicados") }}
        <DxButton
          icon="add"
          type="default"
          stylingMode="contained"
          :hint="$t('agregar_conjunto_implicado')"
          @click="() => this.$refs.gridConjuntosImplicados.instance.addRow()"
          class="mx-3"
        />
      </h3>
    </div>
    <div class="d-flex gap-2">
      <DxDataGrid
        ref="gridConjuntosImplicados"
        id="gridConjuntosImplicados"
        class="dx-card wide-card w-50"
        :data-source="conjuntosImplicadosDataSource"
        keyExpr="id"
        :hover-state-enabled="true"
        :noDataText="$t('noHayConjuntosImplicados')"
        @rowUpdated="refreshDiagram"
        @rowRemoved="refreshDiagram"
        @rowInserted="refreshDiagram"
      >
        <DxEditingGrid :allow-updating="true" :allow-adding="false" :allow-deleting="true" mode="row" />

        <DxColumnGrid data-field="parent_plancontrol" :caption="$t('planDeControlPadre')">
          <DxRequiredRule />

          <DxLookup display-expr="nombre" value-expr="id" :data-source="dataSourceConfigurationPlanesControl">
          </DxLookup>
        </DxColumnGrid>
        <DxColumnGrid data-field="notas" :caption="$t('nota')"></DxColumnGrid>
      </DxDataGrid>

      <div class="dx-card responsive-paddings position-relative w-50 mt-6">
        <!-- <code>{{ conjuntosImplicados }}</code> -->
        <DxDiagram
          id="diagramaConjuntosImplicados"
          ref="diagramaConjuntosImplicados"
          :read-only="false"
          :simpleView="true"
          autoZoomMode="fitWidth"
          width="100%"
        >
          <DxNodes
            :data-source="conjuntosImplicadosTreeDataSource"
            :key-expr="'id'"
            :text-expr="'nombre'"
            :items-expr="'parents'"
          >
            <DxAutoLayout :type="'tree'" orientation="horizontal" />
          </DxNodes>

          <!-- No será visible si el diagrama está en readOnly -->
          <DxPropertiesPanel :visibility="'collapsed'"> </DxPropertiesPanel>

          <DxViewToolbar> </DxViewToolbar>

          <DxToolbox :visibility="'disabled'">
            <!-- <DxGroup :category="'general'" :title="'General'" /> -->
          </DxToolbox>

          <DxHistoryToolbar :visible="false"></DxHistoryToolbar>
        </DxDiagram>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxDiagram,
  DxNodes,
  DxAutoLayout,
  DxPropertiesPanel,
  DxViewToolbar,
  DxToolbox,
  DxHistoryToolbar,
} from "devextreme-vue/diagram";

import {
  DxDataGrid,
  DxColumn as DxColumnGrid,
  DxEditing as DxEditingGrid,
  DxLookup,
  // DxButton as DxButtonGrid,
} from "devextreme-vue/data-grid";

import { DxRequiredRule } from "devextreme-vue/validator";

import { DxButton } from "devextreme-vue/button";

import { PlanesControlCustomStore } from "@/data/plancontrol/PlanesControlDataSource.js";
import { ConjuntosImplicadosDataSource } from "@/data/plancontrol/ConjuntosImplicadosDataSource.js";
import { ConjuntosImplicadosTreeDataSource } from "@/data/plancontrol/ConjuntosImplicadosTreeDataSource.js";

export default {
  props: {
    idPlan: {
      // type: Number,
      required: true,
    },
  },
  components: {
    DxButton,
    DxDiagram,
    DxNodes,
    DxAutoLayout,
    DxPropertiesPanel,
    // DxTab,
    // DxGroup,
    DxViewToolbar,
    DxToolbox,
    DxHistoryToolbar,
    DxDataGrid,
    DxColumnGrid,
    DxEditingGrid,
    // DxButtonGrid,
    DxLookup,
    DxRequiredRule,
  },
  data() {
    return {
      // conjuntosImplicados: new CustomStore({
      //   key: "id",
      //   load: () => this.$fetch.get(`${global.API_URL}/planes/${this.idPlan}/conjuntos`),
      //   byKey: (key) => this.$fetch.get(`${global.API_URL}/planes/${this.idPlan}/conjuntos/${key}`),
      //   insert: (values) => this.$fetch.post(`${global.API_URL}/planes/${this.idPlan}/conjuntos`, values),
      //   update: (key, values) => this.$fetch.put(`${global.API_URL}/planes/${this.idPlan}/conjuntos/${key}`, values),
      //   remove: (key) => this.$fetch.delete(`${global.API_URL}/planes/${this.idPlan}/conjuntos/${key}`),
      // }),

      conjuntosImplicadosDataSource: new ConjuntosImplicadosDataSource(this.idPlan),

      conjuntosImplicadosTreeDataSource: new ConjuntosImplicadosTreeDataSource(this.idPlan),

      dataSourceConfigurationPlanesControl: {
        paginate: true,
        store: new PlanesControlCustomStore(),
      },
    };
  },
  methods: {
    refreshDiagram() {
      this.conjuntosImplicadosTreeDataSource.reload();
      this.$refs.diagramaConjuntosImplicados.instance.repaint();
    },
  },
};
</script>
