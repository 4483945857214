<template>
  <div>
    <PopupEditor
      v-if="showEditComputed"
      :showEdit.sync="showEditComputed"
      :title="popupTitleComputed"
      :update="updateCaracteristica"
      :insert="insertCaracteristica"
      @shown="handleShown"
    >
      <div v-if="$store.state.debug">
        <code> id: {{ id }} <br /> </code>
        <code>
          <pre>{{ JSON.stringify(caracteristica, null, 2) }}</pre>
        </code>
      </div>

      <!-- <button @click="() => (this.loaded = true)">Cargar</button> -->
      <!-- <button @click="() => (this.loaded = true)">Cargar</button> -->
      <template #loader>
        <Loading v-if="!loaded"></Loading>
      </template>

      <transition name="slide-fade" mode="out-in">
        <div v-if="loaded && caracteristica">
          <div class="d-flex flex-wrap w-100 gap-4 p-3">
            <div class="d-flex flex-column w-100">
              <!-- <label class="form-label">{{ $t("hacerAnalisisDeEstaCaracteristica") }}</label> -->
              <div class="form-data">
                <DxCheckBox
                  :value.sync="caracteristica.analisis"
                  :text="$t('hacerAnalisisDeEstaCaracteristica')"
                ></DxCheckBox>
              </div>
            </div>
            <div class="d-flex flex-column w-10">
              <DxNumberBox :label="$t('linea')" :value.sync="caracteristica.linea" :showSpinButtons="true">
              </DxNumberBox>
            </div>

            <div class="d-flex flex-column flex-grow-1">
              <DxTextBox :label="$t('nombre')" :value.sync="caracteristica.nombre">
                <DxValidator>
                  <DxRequiredRule :message="$t('errorNombreRequerido')" />
                </DxValidator>
              </DxTextBox>
            </div>

            <div class="d-flex flex-column w-30">
              <SelectBoxCatalogEditor
                :label="$t('tipoCaracteristica')"
                :title="$t('catalogoCaracteristicasTipo')"
                autorefresh
                v-model="caracteristica.id_caracteristicastipo"
                :columnas="{
                  codigo: {
                    width: 70,
                    caption: $t('codigo'),
                  },
                  descripcion: {
                    width: '100%',
                    caption: $t('descripcion'),
                  },
                  // imagen: {
                  //   width: '100%',
                  //   caption: $t('imagen'),
                  // },
                }"
                :search-expr="['codigo', 'descripcion']"
                :display-expr="(item) => (item ? `${item.codigo} - ${item.descripcion}` : '')"
                value-expr="id"
                recurso="caracteristicas/tipos"
                @dataSourceUpdated="updatedDataSource"
              >
                <template #item="{ data }">
                  <div>
                    <small>{{ data.codigo }}</small>
                    <span v-if="data.descripcion"> --- {{ data.descripcion }}</span>
                  </div>
                </template>
              </SelectBoxCatalogEditor>
            </div>

            <!-- 
            metodoAnalisis 
            1: ATR Def
            2: ATR MDef
            3: VAR X/R
            4: VAR X̅/R
            5: VAR MaxMin
            6+ USR
          -->

            <div class="d-flex flex-column w-100">
              <DxSelectBox
                :label="$t('metodoAnalisis')"
                @valueChanged="onValueChanged"
                :value.sync="caracteristica.id_metodoanalisis"
                :search-enabled="true"
                search-mode="contains"
                :search-expr="['codigo', 'descripcion']"
                :display-expr="(item) => (item ? `${item.codigo} - ${item.descripcion}` : '')"
                value-expr="id"
                :data-source="metodoAnalisisDataSource"
                :show-clear-button="true"
              >
                <template #item="{ data }">
                  <div class="clientes-item">
                    <span v-if="data.descripcion">{{ data.descripcion }}</span>
                    <i v-else>{{ $t("sinNombre") }}</i>
                    <br />
                    <small>{{ data.codigo }}</small>
                  </div>
                </template>
                <DxValidator>
                  <DxRequiredRule :message="$t('errorMetodoAnalisisRequerido')" />
                </DxValidator>
              </DxSelectBox>
            </div>

            <MetodoAnalisisDefectos v-if="caracteristica.id_metodoanalisis === DEF" :caracteristica="caracteristica" />
            <MetodoAnalisisMultiplesDefectos
              v-if="caracteristica.id_metodoanalisis === MDEF"
              :caracteristica="caracteristica"
            />

            <MetodoAnalisisXR v-if="caracteristica.id_metodoanalisis === XR" :caracteristica="caracteristica" />
            <MetodoAnalisisXBR v-if="caracteristica.id_metodoanalisis === XBR" :caracteristica="caracteristica" />
            <MetodoAnalisisMaxMin v-if="caracteristica.id_metodoanalisis === MAXMIN" :caracteristica="caracteristica" />

            <MetodoAnalisisOtro v-if="caracteristica.id_metodoanalisis >= OTHER" :caracteristica="caracteristica" />

            <div class="d-flex flex-column w-100">
              <SelectBoxCatalogEditor
                :label="$t('metodoEvaluacion')"
                :title="$t('catalogoMetodosEvaluacion')"
                autorefresh
                v-model="caracteristica.id_metodoevaluacion"
                :columnas="{
                  codigo: {
                    width: 70,
                    caption: $t('codigo'),
                  },
                  descripcion: {
                    width: '100%',
                    caption: $t('descripcion'),
                  },
                  // imagen: {
                  //   width: '100%',
                  //   caption: $t('imagen'),
                  // },
                }"
                :search-expr="['codigo', 'descripcion']"
                :display-expr="(item) => (item ? `${item.codigo} - ${item.descripcion}` : '')"
                value-expr="id"
                recurso="caracteristicas/metodoevaluacion"
                @dataSourceUpdated="updatedDataSource"
              >
                <template #item="{ data }">
                  <div>
                    <small>{{ data.codigo }}</small>
                    <span v-if="data.descripcion"> --- {{ data.descripcion }}</span>
                  </div>
                </template>
              </SelectBoxCatalogEditor>
            </div>

            <div class="d-flex flex-column w-100">
              <SelectBoxCatalogEditor
                :label="$t('frecuencia')"
                :title="$t('catalogoFrecuencias')"
                autorefresh
                v-model="caracteristica.id_frecuencias"
                :columnas="{
                  codigo: {
                    width: 70,
                    caption: $t('codigo'),
                  },
                  descripcion: {
                    width: '100%',
                    caption: $t('descripcion'),
                  },
                  // imagen: {
                  //   width: '100%',
                  //   caption: $t('imagen'),
                  // },
                }"
                :search-expr="['codigo', 'descripcion']"
                :display-expr="(item) => (item ? `${item.codigo} - ${item.descripcion}` : '')"
                value-expr="id"
                recurso="caracteristicas/frecuencias"
                @dataSourceUpdated="updatedDataSource"
              >
                <template #item="{ data }">
                  <div>
                    <small>{{ data.codigo }}</small>
                    <span v-if="data.descripcion"> --- {{ data.descripcion }}</span>
                  </div>
                </template>
              </SelectBoxCatalogEditor>
            </div>

            <div class="d-flex flex-column w-100">
              <DxTagBox
                :label="$t('roles')"
                :value.sync="caracteristica.token_usuariosrol"
                :data-source="rolesDataSource"
                :search-enabled="true"
                search-mode="contains"
                :search-expr="['codigo', 'descripcion']"
                :display-expr="(item) => (item ? `${item.codigo} - ${item.descripcion}` : '')"
                value-expr="id"
                :show-clear-button="true"
              >
              </DxTagBox>
            </div>

            <div class="d-flex flex-column w-100">
              <DictionaryEditor
                :label="$t('reaccion')"
                :title="$t('diccReacciones')"
                v-model="caracteristica.reaccion"
                value-expr="texto"
                recurso="caracteristicas/reacciones/diccionario"
              >
                <!-- <DxValidator>
                    <DxRequiredRule :message="$t('errorValorPedidoRequerido')" />
                  </DxValidator> -->
              </DictionaryEditor>
            </div>

            <LimitesControl v-if="isVar" :caracteristica="caracteristica"></LimitesControl>

            <div class="d-flex flex-column w-100">
              <DictionaryEditor
                :label="$t('protocolo')"
                :title="$t('diccProtocolos')"
                v-model="caracteristica.protocolo"
                value-expr="texto"
                recurso="caracteristicas/protocolos/diccionario"
              >
                <!-- <DxValidator>
                    <DxRequiredRule :message="$t('errorValorPedidoRequerido')" />
                  </DxValidator> -->
              </DictionaryEditor>
            </div>

            <div class="d-flex flex-row w-100 gap-5">
              <div class="d-flex flex-column w-25">
                <DxSelectBox
                  :label="$t('calibre')"
                  :value.sync="caracteristica.id_instrumentos"
                  :search-enabled="true"
                  search-mode="contains"
                  :search-expr="['codigo', 'descripcion']"
                  :display-expr="(item) => (item ? `${item.codigo} - ${item.descripcion}` : '')"
                  value-expr="id"
                  :data-source="instrumentosDataSource"
                  :show-clear-button="true"
                  @selectionChanged="onInstrumentoSelectionChanged"
                >
                  <template #item="{ data }">
                    <div class="clientes-item">
                      <span v-if="data.descripcion">{{ data.descripcion }}</span>
                      <i v-else>{{ $t("sinNombre") }}</i>
                      <br />
                      <small>{{ data.codigo }}</small>
                    </div>
                  </template>
                </DxSelectBox>
              </div>

              <div class="d-flex flex-column w-75">
                <DxTextBox :label="$t('calibre')" :value.sync="caracteristica.calibre"> </DxTextBox>
              </div>
            </div>

            <div class="d-flex flex-row w-100 gap-5">
              <div class="d-flex flex-column w-25">
                <DxSelectBox
                  :label="$t('amfe')"
                  :disabled="true"
                  :value.sync="caracteristica.id_amfelin"
                  :search-enabled="true"
                  search-mode="contains"
                  :search-expr="['operacion', 'nombreAmfe']"
                  :display-expr="(item) => (item ? `${item.operacion} - ${item.nombreAmfe}` : '')"
                  value-expr="id"
                  :data-source="amfelinDataSource"
                  :show-clear-button="true"
                  @selectionChanged="onAmfeLineaSelectionChanged"
                >
                  <template #item="{ data }">
                    <div class="clientes-item">
                      <div v-if="data.operacion">{{ data.operacion }}</div>
                      <div v-if="data.nombreAmfe">{{ data.nombreAmfe }}</div>
                    </div>
                  </template>
                </DxSelectBox>
              </div>

              <div class="d-flex flex-column w-75">
                <DxTextBox :label="$t('operacion')" :value.sync="caracteristica.operacion"> </DxTextBox>
              </div>
            </div>
            <div class="d-flex flex-column w-100">
              <DxTextArea
                :label="$t('observaciones')"
                v-model="caracteristica.observaciones"
                :height="60"
                width="100%"
              />
            </div>

            <NotasAlControlador class="mt-5" :idCaracteristica="id"></NotasAlControlador>

            <AttachedImageViewerBlock
              class="w-100 mt-5"
              :title="$t('fotos')"
              :resourceURI="`caracteristicas/${this.id}`"
            ></AttachedImageViewerBlock>

            <AttachedDocumentViewerBlock
              class="w-100 mt-5"
              :title="$t('documentos')"
              :resourceURI="`caracteristicas/${this.id}`"
            />

            <div class="w-100 mt-5 mb-5 danger-zone">
              <h3 class="text-danger"><i class="dx-icon dx-icon-warning"></i> {{ $t("zonaDePeligro") }}</h3>
              <div class="dx-card responsive-paddings border rounded alert alert-danger">
                <ConfirmDeleteButton
                  :text="$t('eliminarCaracteristica')"
                  :text-to-confirm="caracteristica.nombre.replaceAll('\n', '').replaceAll('\r', '')"
                  data-type="la caracteristica"
                  @confirmed="deleteCaracteristica"
                >
                </ConfirmDeleteButton>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </PopupEditor>
  </div>
</template>

<script>
import { DEF, MDEF, XR, XBR, MAXMIN, OTHER } from "@/core/metodoAnalisis/constantes.js";
import { isVar } from "@/core/metodoAnalisis/constantes.js";

// import updateEstructura from "./updateEstructura"
import { DxTextBox } from "devextreme-vue/ui/text-box";
import { DxTextArea } from "devextreme-vue/ui/text-area";
import { DxNumberBox } from "devextreme-vue/ui/number-box";
import { DxSelectBox } from "devextreme-vue/ui/select-box";
import { DxTagBox } from "devextreme-vue/ui/tag-box";
// import { DxDateBox } from "devextreme-vue/ui/date-box";
import { DxCheckBox } from "devextreme-vue/ui/check-box";

import {
  DxValidator,
  DxRequiredRule,
  // DxCompareRule,
  // DxEmailRule,
  // DxPatternRule,
  // DxStringLengthRule,
  // DxRangeRule,
  // DxAsyncRule
} from "devextreme-vue/validator";

import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

import PopupEditor from "@/components/core/PopupEditor.vue";

import LimitesControl from "./LimitesControl.vue";
import DictionaryEditor from "@/components/core/DictionaryEditor";

// import AttachedViewer from "@/components/attached-viewer.vue";
// import AttachedDocumentViewer from "@/components/attached-document-viewer.vue";
import NotasAlControlador from "@/components/plancontrol/caracteristica/notas-al-controlador.vue";

import Loading from "@/components/core/Loading.vue";

import ConfirmDeleteButton from "@/components/core/ConfirmDeleteButton.vue";

import MetodoAnalisisDefectos from "./metodoAnalisis/defectos.vue";
import MetodoAnalisisMultiplesDefectos from "./metodoAnalisis/multiplesDefectos.vue";
import MetodoAnalisisXR from "./metodoAnalisis/xr.vue";
import MetodoAnalisisXBR from "./metodoAnalisis/xbr.vue";
import MetodoAnalisisMaxMin from "./metodoAnalisis/maxMin.vue";
import MetodoAnalisisOtro from "./metodoAnalisis/otro.vue";
import AttachedImageViewerBlock from "../../attached-image-viewer-block.vue";
import AttachedDocumentViewerBlock from "../../attached-document-viewer-block.vue";

import SelectBoxCatalogEditor from "@/components/core/SelectBoxCatalogEditor.vue";

export default {
  components: {
    DxTextBox,
    DxNumberBox,
    DxSelectBox,
    DxTextArea,
    DxTagBox,
    DxCheckBox,
    PopupEditor,
    DxValidator,
    DxRequiredRule,
    LimitesControl,
    DictionaryEditor,
    // AttachedViewer,
    // AttachedDocumentViewer,
    NotasAlControlador,
    Loading,
    ConfirmDeleteButton,
    MetodoAnalisisDefectos,
    MetodoAnalisisMultiplesDefectos,
    MetodoAnalisisXR,
    MetodoAnalisisXBR,
    MetodoAnalisisMaxMin,
    MetodoAnalisisOtro,
    AttachedImageViewerBlock,
    AttachedDocumentViewerBlock,
    //
    SelectBoxCatalogEditor,
  },
  props: ["id", "showEdit", "idEstructura", "popupTitle"],
  created() {
    this.DEF = DEF;
    this.MDEF = MDEF;
    this.XR = XR;
    this.XBR = XBR;
    this.MAXMIN = MAXMIN;
    this.OTHER = OTHER;
  },
  data() {
    console.log(DEF, MDEF, XR, XBR, MAXMIN, OTHER);
    return {
      loaded: false,

      caracteristica: {
        id: undefined,
        id_pcestructura: this.idEstructura,
        linea: undefined,
        operacion: undefined,
        nombre: undefined,
        observaciones: undefined,
        id_caracteristicastipo: undefined,
        id_metodoanalisis: undefined,
        id_condiciones: undefined,
        valor_pedido: undefined,
        tolerancia: undefined,
        id_unidades: undefined,
        id_metodoevaluacion: undefined,
        tamano_muestra: undefined,
        id_frecuencias: undefined,
        token_usuariosrol: undefined,
        reaccion: undefined,
        lcsx: undefined,
        lcix: undefined,
        lcx: undefined,
        lcr: undefined,
        lcsr: undefined,
        lcir: undefined,
        cp: undefined,
        cpk: undefined,
        fecha_inicio: undefined,
        fecha_fin: undefined,
        pp: undefined,
        ppk: undefined,
        protocolo: undefined,
        id_calibres: undefined,
        id_amfelin: undefined,
        analisis: true,
        id_nca: undefined,
        dateCreated: undefined,
        dateModified: undefined,
        idModifier: undefined,
        nameModifier: undefined,
        tolerancia1: undefined,
        tolerancia2: undefined,
        // UI/UX
        defectos: [],
      },

      // caracteristicasTipoDataSource: new DataSource({
      //   paginate: true,
      //   store: new CustomStore({
      //     key: "id",
      //     loadMode: "raw",
      //     load: () => this.$fetch.get(`${global.API_URL}/caracteristicas/tipos`),
      //   }),
      // }),

      metodoAnalisisDataSource: new DataSource({
        paginate: true,
        store: new CustomStore({
          key: "id",
          loadMode: "raw",
          load: () => this.$fetch.get(`${global.API_URL}/caracteristicas/metodoanalisis`),
          // byKey: (key) => this.$fetch.get(`${global.API_URL}/caracteristicas/metodoanalisis/${key}`),
          // insert: (values) => this.$fetch.post(`${global.API_URL}/caracteristicas/metodoanalisis`, values),
          // update: (key, values) => this.$fetch.put(`${global.API_URL}/caracteristicas/metodoanalisis/${key}`, values),
          // remove: (key) => this.$fetch.delete(`${global.API_URL}/caracteristicas/metodoanalisis/${key}`),
        }),
      }),

      // condicionesDataSource: new DataSource({
      //   paginate: true,
      //   store: new CustomStore({
      //     key: "id",
      //     loadMode: "raw",
      //     load: () => this.$fetch.get(`${global.API_URL}/analisis/condiciones`),
      //   }),
      // }),

      // metodoEvaluacionDataSource: new DataSource({
      //   paginate: true,
      //   store: new CustomStore({
      //     key: "id",
      //     loadMode: "raw",
      //     load: () => this.$fetch.get(`${global.API_URL}/caracteristicas/metodoevaluacion`),
      //     // byKey: (key) => this.$fetch.get(`${global.API_URL}/analisis/unidades/${key}`),
      //     // insert: (values) => this.$fetch.post(`${global.API_URL}/analisis/unidades`, values),
      //     // update: (key, values) => this.$fetch.put(`${global.API_URL}/analisis/unidades/${key}`, values),
      //     // remove: (key) => this.$fetch.delete(`${global.API_URL}/analisis/unidades/${key}`),
      //   }),
      // }),

      // frecuenciasDataSource: new DataSource({
      //   paginate: true,
      //   store: new CustomStore({
      //     key: "id",
      //     loadMode: "raw",
      //     load: () => this.$fetch.get(`${global.API_URL}/caracteristicas/frecuencias`),
      //   }),
      // }),

      rolesDataSource: new DataSource({
        paginate: true,
        store: new CustomStore({
          key: "id",
          loadMode: "raw",
          load: () => this.$fetch.get(`${global.API_URL}/roles`),
          // byKey: (key) => this.$fetch.get(`${global.API_URL}/analisis/unidades/${key}`),
          // insert: (values) => this.$fetch.post(`${global.API_URL}/analisis/unidades`, values),
          // update: (key, values) => this.$fetch.put(`${global.API_URL}/analisis/unidades/${key}`, values),
          // remove: (key) => this.$fetch.delete(`${global.API_URL}/analisis/unidades/${key}`),
        }),
      }),

      instrumentosDataSource: new DataSource({
        paginate: true,
        store: new CustomStore({
          key: "id",
          loadMode: "raw",
          load: () => this.$fetch.get(`${global.API_URL}/instrumentos`),
          // byKey: (key) => this.$fetch.get(`${global.API_URL}/analisis/unidades/${key}`),
          // insert: (values) => this.$fetch.post(`${global.API_URL}/analisis/unidades`, values),
          // update: (key, values) => this.$fetch.put(`${global.API_URL}/analisis/unidades/${key}`, values),
          // remove: (key) => this.$fetch.delete(`${global.API_URL}/analisis/unidades/${key}`),
        }),
      }),

      amfelinDataSource: new DataSource({
        paginate: true,
        store: new CustomStore({
          key: "id",
          loadMode: "raw",
          load: () => this.$fetch.get(`${global.API_URL}/amfe/lineas`),
          // byKey: (key) => this.$fetch.get(`${global.API_URL}/analisis/unidades/${key}`),
          // insert: (values) => this.$fetch.post(`${global.API_URL}/analisis/unidades`, values),
          // update: (key, values) => this.$fetch.put(`${global.API_URL}/analisis/unidades/${key}`, values),
          // remove: (key) => this.$fetch.delete(`${global.API_URL}/analisis/unidades/${key}`),
        }),
      }),

      caracteristicasDataSource: new DataSource({
        paginate: true,
        store: new CustomStore({
          key: "id",
          loadMode: "raw",
          // load: () => {
          //   console.log(`${global.API_URL}/caracteristicas`);
          //   return this.$fetch.get(`${global.API_URL}/caracteristicas`);
          // },
          byKey: (key) => this.$fetch.get(`${global.API_URL}/caracteristicas/${key}`),
          insert: (values) => this.$fetch.post(`${global.API_URL}/caracteristicas`, values),
          update: (key, values) => this.$fetch.put(`${global.API_URL}/caracteristicas/${key}`, values),
          remove: (key) => this.$fetch.delete(`${global.API_URL}/caracteristicas/${key}`),
        }),
      }),
    };
  },

  // async created() {
  //   console.log("created!!!!!");
  //   if (this.id) {
  //     const result = await this.caracteristicasDataSource.store().byKey(this.id);
  //     // console.log("result -> ", result);
  //     // console.log("caracteristica -> ", this.caracteristica);
  //     this.caracteristica = result;
  //   }
  // },

  mounted() {
    console.log("mounted!!!!!");
  },

  watch: {
    id: {
      handler: async function (value) {
        if (value) {
          const result = await this.caracteristicasDataSource.store().byKey(value);
          console.log(result);
          this.caracteristica = result;
          // this.loaded = true;
        }
      },
      immediate: true,
    },
  },

  computed: {
    isVar() {
      return isVar(this.caracteristica.id_metodoanalisis);
    },
    showEditComputed: {
      get() {
        return this.showEdit;
      },
      set(value) {
        this.$emit("update:showEdit", value);
      },
    },
    nombreMetodoAnalisis: {
      get() {
        const nombre = this.metodoAnalisisDataSource
          .items()
          .find((item) => item.id === this.caracteristica.id_metodoanalisis)?.codigo;
        return nombre;
      },
    },
    popupTitleComputed: {
      get() {
        return this.caracteristica.nombre || this.popupTitle;
      },
    },
  },

  methods: {
    onValueChanged(e) {
      if (e.previousValue === undefined) return;
      this.resetValuesCaracteristica(e.value);
    },

    resetValuesCaracteristica() {
      this.caracteristica.valor_pedido = null;
      this.caracteristica.id_condiciones = null;
      this.caracteristica.tolerancia1 = null;
      this.caracteristica.tolerancia2 = null;
      this.caracteristica.id_unidades = null;
      this.caracteristica.tamano_muestra = null;
      this.caracteristica.id_nca = null;
      // @TODO borrar ListaDefectos en caso de MDEF? considerar imagenes y documentos :|
    },

    updatedDataSource: function () {
      console.log(" caracteristica.edit.vue ::: updatedDataSource");
      this.$emit("updatedDataSource");
    },
    updateCaracteristica: async function () {
      // this.update(this.estructuraEditable)
      const result = await this.caracteristicasDataSource.store().update(this.id, this.caracteristica);
      this.$emit("updated", result);
    },

    insertCaracteristica: async function () {
      // this.update(this.estructuraEditable)
      const result = await this.caracteristicasDataSource.store().insert(this.caracteristica);
      this.$emit("inserted", result);
    },

    deleteCaracteristica: async function () {
      try {
        const result = await this.caracteristicasDataSource.store().remove(this.id);
        this.$emit("deleted", result);
        this.$notify(this.$t("borrado_correctamente"), "success", 5000);
        this.showEditComputed = false;
      } catch (error) {
        this.$notify("Error al eliminar la característica", "error", 5000);
        console.error(error);
      }
    },

    handleShown: function () {
      console.log("handleShown");
      this.loaded = true;
    },

    onAmfeLineaSelectionChanged(e) {
      console.log("onAmfeLineaSelectionChanged", e);
      // const result = await this.$fetch.get(`${global.API_URL}/amfe/lineas/${e.value}`);
      // console.log("result", result);
      this.caracteristica.operacion = e?.selectedItem?.operacion;
    },

    onInstrumentoSelectionChanged(e) {
      console.log("onInstrumentoSelectionChanged", e);
      // const result = await this.$fetch.get(`${global.API_URL}/amfe/lineas/${e.value}`);
      // console.log("result", result);
      this.caracteristica.calibre = e?.selectedItem?.codigo;
    },
  },
};
</script>

<style scoped></style>
