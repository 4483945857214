<template>
  <PopupEditor :showEdit.sync="showEditComputed" :title="estructuraEditable.descripcion" :update="updateEstructura">
    <div class="p-3">
      <div class="d-flex flex-wrap w-100 gap-4">
        <div class="d-flex flex-column w-100">
          <label class="form-label">{{ $t("descripcion") }}*</label>
          <div class="form-data">
            <DxTextBox :value.sync="estructuraEditable.descripcion">
              <DxValidator>
                <DxRequiredRule :message="$t('errorDescripcionRequerida')" />
              </DxValidator>
            </DxTextBox>
          </div>
        </div>

        <div class="d-flex flex-column w-100">
          <label class="form-label">{{ $t("proceso") }}</label>
          <div class="form-data">
            <DxTextBox :value.sync="estructuraEditable.proceso"> </DxTextBox>
          </div>
        </div>

        <div class="d-flex flex-column w-100">
          <label class="form-label">{{ $t("dispositivo") }}</label>
          <div class="form-data">
            <DxTextBox :value.sync="estructuraEditable.dispositivo"> </DxTextBox>
          </div>
        </div>

        <div class="d-flex flex-column w-100">
          <label class="form-label">{{ $t("notas") }}</label>
          <div class="form-data">
            <HtmlEditor :value.sync="estructuraEditable.notas"> </HtmlEditor>
          </div>
        </div>
      </div>
    </div>
  </PopupEditor>
</template>

<script>
import PopupEditor from "@/components/core/PopupEditor.vue";
import DxTextBox from "devextreme-vue/text-box";
import HtmlEditor from "@/components/core/HtmlEditor.vue";

import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";

export default {
  props: ["showEdit", "estructura", "update"],
  components: {
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    HtmlEditor,
    PopupEditor,
  },
  data() {
    return {
      estructuraEditable: { ...this.estructura },
    };
  },
  computed: {
    showEditComputed: {
      get() {
        return this.showEdit;
      },
      set(value) {
        this.$emit("update:showEdit", value);
      },
    },
  },

  methods: {
    async updateEstructura() {
      this.update(this.estructuraEditable);
    },
  },
};
</script>
