<template>
  <div>
    <div class="row">
      <div class="col-xl-6">
        <DxChart
          v-if="true"
          id="graficoPareto"
          ref="graficoPareto"
          :data-source="dataSourcePareto"
          @point-click="onClickDefecto"
        >
          <DxTitle
            :text="$t('pareto')"
            :subtitle="{
              text: titulo,
              font: {
                size: '2em',
              },
            }"
          >
          </DxTitle>

          <DxCommonSeriesSettings argument-field="defecto" />
          <DxCommonAxisSettings>
            <DxGrid :visible="false" />
          </DxCommonAxisSettings>

          <DxSeries :name="$t('numeroDefectos')" value-field="cantidad" axis="frequency" type="bar" color="#fac29a" />
          <DxSeries :name="$t('acumulado')" value-field="acumulado" axis="acumulado" type="spline" color="#6b71c3" />

          <DxArgumentAxis>
            <DxLabel overlapping-behavior="stagger" />
          </DxArgumentAxis>

          <DxValueAxis name="frequency" position="left" />
          <DxValueAxis :show-zero="true" :value-margins-enabled="false" name="acumulado" position="right" />

          <DxTooltip :enabled="true" :shared="true" :customize-tooltip="customizeTooltip" />

          <DxLegend :visible="false" />
        </DxChart>
      </div>

      <div class="col-xl-6">
        <DxPieChart
          v-if="true"
          id="graficoPie"
          ref="graficoPie"
          :data-source="dataSourcePareto"
          @point-click="onClickDefecto"
        >
          <!-- <DxTitle :text="$t('defectos%')" :subtitle="titulo"> </DxTitle> -->

          <DxTitle
            :text="$t('defectos%')"
            :subtitle="{
              text: titulo,
              font: {
                size: '2em',
              },
            }"
          >
          </DxTitle>

          <DxSeries argument-field="defecto" value-field="cantidad">
            <DxLabel :visible="true" :customize-text="formatLabel" position="columns">
              <DxConnector :visible="true" :width="0.5" />
              <DxFont :size="16" />
            </DxLabel>
          </DxSeries>
          <DxLegend
            :column-count="4"
            orientation="horizontal"
            item-text-position="right"
            horizontal-alignment="center"
            vertical-alignment="bottom"
          />
        </DxPieChart>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxChart,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxLabel,
  DxLegend,
  DxSeries,
  DxTooltip,
  DxValueAxis,
  DxCommonAxisSettings,
  DxGrid,
  DxTitle,
} from "devextreme-vue/chart";

import { DxPieChart, DxConnector, DxFont } from "devextreme-vue/pie-chart";

export default {
  components: {
    DxChart,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxLabel,
    DxLegend,
    DxSeries,
    DxTooltip,
    DxValueAxis,
    DxCommonAxisSettings,
    DxGrid,
    DxPieChart,
    DxConnector,
    DxFont,
    DxTitle,
  },

  props: ["defectos", "titulo"],

  computed: {
    dataSourcePareto: function () {
      if (this.defectos === undefined) return [];

      let acumulado = 0;
      const x = [...this.defectos];

      return x
        .sort(function (a, b) {
          return b.cantidad - a.cantidad;
        })
        .map(function (item) {
          acumulado += item.cantidad;
          return {
            id_caracteristicamdef: item.id_caracteristicamdef,
            defecto: item.defecto,
            cantidad: item.cantidad,
            acumulado: acumulado,
          };
        });
    },
  },

  methods: {
    customizeTooltip(pointInfo) {
      return {
        html: `<div><div class='tooltip-header'>${pointInfo.argumentText}</div><div class='tooltip-body'><div class='series-name'><span class='top-series-name'>${pointInfo.points[0].seriesName}</span>: </div><div class='value-text'><span class='top-series-value'>${pointInfo.points[0].valueText}</span></div><div class='series-name'><span class='bottom-series-name'>${pointInfo.points[1].seriesName}</span>: </div><div class='value-text'><span class='bottom-series-value'>${pointInfo.points[1].valueText}</span> </div></div></div>`,
      };
    },

    onClickDefecto({ target: point }) {
      this.$emit("defectoSelected", point.data.id_caracteristicamdef);
    },

    formatLabel(pointInfo) {
      return `${pointInfo.valueText} (${pointInfo.percentText})`;
    },
  },
};
</script>

<style scoped>
.botones {
  position: fixed;
  z-index: 9000;
  bottom: 50px;
  right: 50px;
}

.padding {
  height: 100px;
}

.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  margin-top: 20px;
}

.option {
  margin-top: 10px;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option > span {
  margin-right: 14px;
}

.option > .dx-widget {
  display: inline-block;
  vertical-align: middle;
}

#graficoX,
#graficoR {
  height: 500px;
  width: 100%;
}

.dxc-tooltip {
  z-index: 2000;
}

.row.plan-control {
  margin: 10px;
}

.row.caracteristica {
  margin: 10px;
}

/* .tabla-limites {
  position: absolute;
  top: 10px;
  right: 0;
} */

.total {
  background-color: #ccc;
}
</style>
