<template>
  <div class="w-100 d-flex flex-column gap-1 p-3 border rounded">
    <h3>Múltiples Defectos</h3>
    <div class="d-flex flex-row w-100 gap-3">
      <div class="d-flex flex-column w-25">
        <DictionaryEditor
          :label="$t('valorPedido')"
          :title="$t('diccValores')"
          v-model="caracteristicaComputed.valor_pedido"
          value-expr="texto"
          recurso="caracteristicas/valores/diccionario"
        >
          <DxValidator>
            <DxRequiredRule :message="$t('errorValorPedidoRequerido')" />
          </DxValidator>
        </DictionaryEditor>
      </div>

      <div class="d-flex flex-column w-25">
        <!-- <small>Solo %max(9) o %s(10)</small> -->
        <DxSelectBox
          :label="$t('condicion')"
          :value.sync="caracteristicaComputed.id_condiciones"
          :search-enabled="true"
          search-mode="contains"
          :search-expr="['codigo']"
          display-expr="codigo"
          value-expr="id"
          :data-source="condicionesDatasource"
          :show-clear-button="true"
        >
          <DxValidator>
            <DxRequiredRule :message="$t('errorMetodoAnalisisRequerido')" />
          </DxValidator>
        </DxSelectBox>
      </div>

      <div class="d-flex flex-column w-25">
        <DxNumberBox
          :label="$t('tolerancia1')"
          :value.sync="caracteristicaComputed.tolerancia1"
          :showSpinButtons="true"
          min="0"
          max="100"
        >
          <DxValidator>
            <DxRequiredRule :message="$t('errorToleranciaRequerido')" />

            <DxRangeRule :message="$t('errorTolerancia1FueraDeRango')" :min="0" :max="100" v-if="true" />
          </DxValidator>
        </DxNumberBox>
      </div>
      <div class="d-flex flex-column w-25">
        <DxNumberBox
          :label="$t('tamanoMuestra')"
          :value.sync="caracteristicaComputed.tamano_muestra"
          :showSpinButtons="true"
        >
          <DxValidator>
            <DxRequiredRule :message="$t('errorTamanoMuestraRequerido')" />
          </DxValidator>
        </DxNumberBox>
      </div>
    </div>

    <div class="d-flex flex-column w-100">
      <ListaDefectos class="mt-3" :idCaracteristica="caracteristicaComputed.id"></ListaDefectos>
    </div>
  </div>
</template>

<script>
import DictionaryEditor from "@/components/core/DictionaryEditor.vue";
import { DxSelectBox } from "devextreme-vue/ui/select-box";
import { DxNumberBox } from "devextreme-vue/ui/number-box";
import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";

import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";

import ListaDefectos from "../MDef/ListaDefectos.vue";

export default {
  components: {
    DictionaryEditor,
    DxSelectBox,
    DxNumberBox,
    DxValidator,
    DxRequiredRule,
    ListaDefectos,
  },
  props: ["caracteristica"],
  data() {
    return {
      condicionesDatasource: new DataSource({
        paginate: true,
        store: new CustomStore({
          key: "id",
          loadMode: "raw",
          load: () => this.$fetch.get(`${global.API_URL}/analisis/condiciones/mdef`),
        }),
      }),
    };
  },
  computed: {
    caracteristicaComputed: {
      get() {
        return this.caracteristica;
      },
      set(value) {
        console.log("caracteristicaComputed:set happened");
        this.$emit("update:caracteristica", value);
      },
    },
  },
};
</script>
