<template>
  <div>
    <h3 class="d-flex justify-content-between">
      {{ $t("plantasConsumidoras") }}
      <DxButton
        icon="add"
        type="default"
        stylingMode="contained"
        :hint="$t('agregar_planta_consumidora')"
        @click="() => this.$refs.gridPlantas.instance.addRow()"
        class="mx-3"
      />
    </h3>

    <DxDataGrid
      ref="gridPlantas"
      id="gridPlantas"
      class="dx-card wide-card"
      :data-source="plantasConsumidorasDataSource"
      keyExpr="id"
      :hover-state-enabled="true"
      :noDataText="$t('noHayPlantasConsumidoras')"
    >
      <DxEditing :allow-updating="true" :allow-adding="false" :allow-deleting="true" mode="row" />

      <DxColumn data-field="id_plantas" :caption="$t('planta')" edit-cell-template="plantaEditor">
        <DxRequiredRule />
        <DxLookup ref="plantasLookup" display-expr="descripcion" value-expr="id" :data-source="plantasDataSource">
        </DxLookup>
      </DxColumn>

      <template #plantaEditor="{ data: cellInfo }">
        <div>
          <!-- :data-source="plantas" -->
          <SelectBoxCatalogEditor
            :title="$t('catalogoPlantas')"
            v-model="cellInfo.value"
            :search-expr="['codigo', 'descripcion']"
            :display-expr="(item) => (item ? `${item.codigo} - ${item.descripcion}` : '')"
            :columnas="{
              codigo: { caption: $t('codigo'), width: 70 },
              descripcion: { caption: $t('descripcion'), width: '100%' },
            }"
            value-expr="id"
            recurso="plantas"
            @input="(e) => cellInfo.setValue(e)"
            @dataSourceUpdated="(e) => $refs.gridPlantas.instance.refresh()"
          >
            <template #item="{ data }">
              <div class="plantas-item">
                <span v-if="data.descripcion">{{ data.descripcion }}</span>
                <i v-else>{{ $t("sinDescripcion") }}</i>
                <br />
                <small>{{ data.codigo }}</small>
              </div>
            </template>
          </SelectBoxCatalogEditor>
        </div>
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
import { DxButton } from "devextreme-vue/button";
import { DxDataGrid, DxColumn, DxEditing, DxLookup } from "devextreme-vue/data-grid";
import { DxRequiredRule } from "devextreme-vue/validator";
import SelectBoxCatalogEditor from "@/components/core/SelectBoxCatalogEditor.vue";

import { PlantasConsumidorasDataSource } from "@/data/plancontrol/PlantasConsumidorasDataSource.js";
import { PlantasCustomStore } from "@/data/plancontrol/PlantasDataSource.js";

export default {
  components: {
    DxButton,
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxLookup,
    SelectBoxCatalogEditor,
    DxRequiredRule,
  },
  props: {
    idPlan: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      plantasConsumidorasDataSource: new PlantasConsumidorasDataSource(this.idPlan),
      plantasDataSource: {
        store: new PlantasCustomStore(),
      },
    };
  },

  methods: {},
};
</script>
