<!-- eslint-disabled -->
<template>
  <div :key="componentKey">
    <DxPopup :title="$t('Cuidado!')" :visible.sync="isPopupDeleteVisible" width="300px" height="auto">
      <DxScrollView height="300">
        ¿Estás seguro que quieres borrar los siguientes elementos?

        <ul class="list-to-delete">
          <li v-for="key in this.selectedLineasCalibracion" :key="key">{{ key }}</li>
        </ul>
      </DxScrollView>

      <DxToolbarItem :options="toolbarItemCancelarOptions" widget="dxButton" location="after" toolbar="bottom" />
      <DxToolbarItem :options="toolbarItemConfirmarOptions" widget="dxButton" location="after" toolbar="bottom" />
    </DxPopup>

    <TitleInstrumentoWithBackButton :instrumento="instrumento"></TitleInstrumentoWithBackButton>
    <!-- {{ $t("calibracion") }} {{ id_calibracion }} -->

    <h3 class="content-block">{{ $t("calibracion") }}: {{ id_calibracion }}</h3>

    <div class="content-block">
      <div class="dx-card">
        <!-- <h1>Añadir toolbar: - imprimir plan (exportar grid) - duplicar plan</h1> -->
        <!-- <code class="d-block m-0 p-3" v-if="$store.state.debug">
          calibraciones seleccionadas: {{ selectedCalibraciones }}
        </code> -->

        <DxDataGrid
          :ref="gridRefName"
          :id="gridRefName"
          class="dx-card wide-card"
          :data-source="customStoreLineasCalibracion"
          :hover-state-enabled="true"
          :height="gridHeight"
          width="100%"
          :paging="{
            enabled: true,
          }"
          :allow-column-reordering="true"
          @selection-changed="onSelectionChanged"
          @rowDblClick="onRowDblClick"
          @exporting="onExporting"
          :filter-value="filterValue"
        >
          <DxEditingGrid :allow-deleting="$store.state.debug" />
          <DxSearchPanel :visible="true" :width="240" />
          <DxFilterRow :visible="true"></DxFilterRow>
          <DxFilterPanel :visible="showFilterPanel"></DxFilterPanel>
          <DxGroupPanel :visible="true" />
          <DxGrouping :auto-expand-all="true" />
          <DxLoadPanel :enabled="true" />
          <DxScrolling mode="standard" />

          <DxExport :enabled="true" :formats="['pdf']" />

          <DxToolbar>
            <DxItem location="before" name="groupPanel"></DxItem>
            <DxItem location="after" name="exportButton"></DxItem>
            <!-- <DxItem
              location="after" name="delete" template="deleteSelectionTemplate">
            </DxItem> -->
            <DxItem location="after" name="refresh" template="refreshTemplate"> </DxItem>
            <DxItem location="after" name="showFilterPanel" template="showFilterPanelTemplate"></DxItem>
            <DxItem location="after" name="searchPanel"></DxItem>
          </DxToolbar>

          <DxColumnGrid data-field="secuencia" :caption="$t('secuencia')"> </DxColumnGrid>
          <DxColumnGrid data-field="caracteristica" :caption="$t('caracteristica')"> </DxColumnGrid>
          <DxColumnGrid data-field="metodo" :caption="$t('metodo')"> </DxColumnGrid>
          <DxColumnGrid data-field="patron" :caption="$t('patron')"> </DxColumnGrid>
          <DxColumnGrid data-field="procecimiento" :caption="$t('procecimiento')"> </DxColumnGrid>
          <DxColumnGrid data-field="valor_pedido" :caption="$t('valor_pedido')"> </DxColumnGrid>
          <DxColumnGrid data-field="valor_teorico" :caption="$t('valor_teorico')"> </DxColumnGrid>
          <DxColumnGrid data-field="valor_obtenido" :caption="$t('valor_obtenido')"> </DxColumnGrid>
          <DxColumnGrid data-field="resultado" :caption="$t('resultado')"> </DxColumnGrid>
          <DxColumnGrid data-field="vp" :caption="$t('vp')"> </DxColumnGrid>
          <DxColumnGrid data-field="condicion" :caption="$t('condicion')"> </DxColumnGrid>
          <DxColumnGrid data-field="tolerancia" :caption="$t('tolerancia')"> </DxColumnGrid>
          <DxColumnGrid data-field="fecha" :caption="$t('fecha')"> </DxColumnGrid>
          <DxColumnGrid data-field="notas" :caption="$t('notas')"> </DxColumnGrid>
          <template #refreshTemplate>
            <DxButton icon="refresh" @click="refreshDataGrid"></DxButton>
          </template>

          <template #showFilterPanelTemplate>
            <!-- using type to show a kind of checked button... -->
            <DxButton
              icon="filter"
              :type="showFilterPanel ? 'success' : 'normal'"
              @click="showFilterPanel = !showFilterPanel"
            >
            </DxButton>
          </template>
        </DxDataGrid>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn as DxColumnGrid,
  DxScrolling,
  DxLoadPanel,
  DxFilterRow,
  DxSearchPanel,
  DxEditing as DxEditingGrid,
  DxFilterPanel,
  DxGroupPanel,
  DxGrouping,
  DxExport,
  DxToolbar,
  DxItem,
} from "devextreme-vue/data-grid";

import { DxButton } from "devextreme-vue/button";

import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";

import { DxScrollView } from "devextreme-vue/scroll-view";

import { jsPDF } from "jspdf";
import { exportDataGrid } from "devextreme/pdf_exporter";

// import { store as customStoreInstrumentos } from "@/data/instrumentos/InstrumentoDataSource";
import { CustomStoreInstrumentos } from "@/data/instrumentos/InstrumentoDataSource";
import { CustomStoreLineasCalibracion } from "@/data/instrumentos/LineasCalibracionDataSource";
import TitleInstrumentoWithBackButton from "../../components/core/TitleInstrumentoWithBackButton.vue";

export default {
  components: {
    DxDataGrid,
    DxColumnGrid,
    DxScrolling,
    DxLoadPanel,
    DxFilterRow,
    DxSearchPanel,
    DxEditingGrid,
    DxFilterPanel,
    DxGroupPanel,
    DxGrouping,
    // DxSwitch,
    DxExport,
    DxToolbar,
    DxItem,
    DxButton,
    //
    DxPopup,
    DxToolbarItem,
    DxScrollView,
    TitleInstrumentoWithBackButton,
  },

  data() {
    const { id_instrumento, id: id_calibracion } = this.$route.params;

    return {
      componentKey: 0,
      gridRefName: "gridLineasCalibracion",

      id_instrumento,
      instrumento: {},
      id_calibracion,
      calibracion: {},

      window: {
        width: 0,
        height: 0,
      },

      customStoreInstrumentos: new CustomStoreInstrumentos(),
      customStoreLineasCalibracion: new CustomStoreLineasCalibracion(id_instrumento, id_calibracion),

      selectedLineasCalibracion: [],

      showFilterPanel: false,
      filterValue: undefined,

      isPopupDeleteVisible: false,
    };
  },

  computed: {
    gridHeight() {
      //return window.innerHeight - 300;
      return this.window.height - 300;
    },
    idioma() {
      return this.$i18n.locale;
    },

    //Necesito que esté en computed para que el idioma lo pueda modificar
    toolbarItemCancelarOptions: function () {
      return {
        text: this.$t("cancelar"),
        type: "normal",
        onClick: () => (this.isPopupDeleteVisible = false),
      };
    },

    //Necesito que esté en computed para que el idioma lo pueda modificar
    toolbarItemConfirmarOptions: function () {
      return {
        text: this.$t("eliminar"),
        stylingMode: "contained",
        type: "danger",
        // no puedo usar useSubmitBehavior porque estoy fuera del form...
        // useSubmitBehavior: true,
        onClick: this.confirmDeleteSelectedCalibracionesClick,
        validationGroup: this.resourceURI,
      };
    },
  },

  methods: {
    itemTypeExpr() {
      // console.log(obj, this.dataSourceDiagram);
      // if (this.dataSourceDiagram.length === 0) return "rectangle";
      // return "shape" + obj.id;
      return "customShape";
    },
    onSelectionChanged(e) {
      console.log("-------- selection changed!", e.selectedRowKeys);
      //this.$emit("update:selectedRow", e.selectedRowsData[0]);
      // this.$router.push({
      //   name: "plan",
      //   params: { id: e.selectedRowsData[0].id },
      // });

      this.selectedCalibraciones = e.selectedRowKeys;
    },

    onRowDblClick(e) {
      console.log("-------- double click", e);

      if (e.isNewRow) return;
      if (e.isEditing) return;

      //this.$emit("update:selectedRow", e.selectedRowsData[0]);
      // this.$router.push({
      //   name: "plan",
      //   params: { id: e.key },
      // });

      // named route with params to let the router build the url
      // this.$router.push({ name: 'calibraciones_instrumento', params: { id: e.data.id_instrumentos } })
    },

    async load() {
      // console.log("")
      // const urls = [
      //   //
      // ];
      // //obtengo todos los fetch en paralelo
      // const responses = await Promise.all(
      //   urls.map((u) =>
      //     fetch(u, {
      //       headers: {
      //         "Content-Type": "application/json",
      //         "Authorization": "Bearer " + auth.getToken(),
      //       },
      //     })
      //   )
      // );
      // const data = await Promise.all(responses.map((r) => r.json()));
      // this.dataSourceMetodoCalibraciones = data[0]

      this.instrumento = await this.customStoreInstrumentos.byKey(this.id_instrumento);
    },

    getTime(date) {
      return this.$moment(date).fromNow();
    },

    getFecha(date) {
      return this.$moment(date).format("L");
    },

    getHora(date) {
      return this.$moment(date).format("LTS");
    },

    handleResize() {
      // console.log(window.innerWidth, window.innerWidth);
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },

    displayExprCliente(item) {
      if (item.nombre && item.codigo) return `${item.codigo} - ${item.nombre}`;
      if (item.nombre) return item.nombre;
      if (item.codigo) return item.codigo;
    },
    displayExprAgrupacion(item) {
      if (item.nombre && item.codigo) return `${item.codigo} - ${item.nombre}`;
      if (item.nombre) return item.nombre;
      if (item.codigo) return item.codigo;
    },
    async onExporting(e) {
      const doc = new jsPDF();
      await exportDataGrid({
        jsPDFDocument: doc,
        component: e.component,
        indent: 5,
      });
      doc.save("Líneas de calibración.pdf");
    },

    duplicateControlPlan() {
      this.isPopupDuplicateVisible = true;
    },

    refreshDataGrid() {
      this.$refs[this.gridRefName].instance.refresh();
    },

    deleteSelectedLineasCalibracionClick() {
      if (this.selectedLineasCalibracion === undefined)
        return console.log("Error, no hay lineascalibracion seleccionado");
      // preguntar si quiere borrar!
      this.isPopupDeleteVisible = true;
    },

    async confirmDeleteSelectedLineasCalibracionClick() {
      console.log("A borrar!");
      const values = this.selectedLineasCalibracion; // this.selectedCalibraciones.map(e => e.id);
      try {
        await this.$fetch.post(`${global.API_URL}/calibraciones/${this.id}/remove`, { ids: values });

        this.isPopupDeleteVisible = false;
        this.$refs[this.gridRefName].instance.refresh();
      } catch (ex) {
        console.log("ha ocurrido algo inesperado.", ex);
      }
    },
  },

  created() {
    console.log("created");
    this.load();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  beforeCreate() {},

  mounted() {},

  watch: {
    idioma() {
      // this.componentKey++;
    },
  },
};
</script>

<style lang="scss">
.list-to-delete {
  padding-top: 3em;
  padding-bottom: 3em;
  // max-height: 300px;
  // overflow-x: auto;
}
</style>
