import { get, post, put, remove } from "@/core/fetch";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

export class CustomStoreProveedores extends CustomStore {
  constructor() {
    const baseUrl = `${global.API_URL}/proveedores`;

    super({
      key: "id",
      loadMode: "raw",
      // cacheRawData: false,
      load: () => get(`${baseUrl}`),
      // byKey: (key) => get(`${baseUrl}/${key}`),
      insert: (values) => post(`${baseUrl}`, values),
      update: (key, values) => put(`${baseUrl}/${key}`, values),
      remove: (key) => remove(`${baseUrl}/${key}`),
    });
  }
}

export class DataSourceProveedores extends DataSource {
  constructor() {
    const store = new CustomStoreProveedores();
    super({
      store,
    });
  }
}

// export const store = new CustomStoreInstrumentos();

// export const datasource = new DataSourceInstrumentos();

// ---------------------------------------------

// export const store = new CustomStore({
//   key: "id",
//   loadMode: "raw",
//   cacheRawData: false,
//   load: () => get(`${global.API_URL}/proveedores`),
//   // byKey: (key) => get(`${global.API_URL}/proveedores/${key}`),
//   insert: (values) => post(`${global.API_URL}/proveedores`, values),
//   update: (key, values) => put(`${global.API_URL}/proveedores/${key}`, values),
//   remove: (key) => remove(`${global.API_URL}/proveedores/${key}`),
// });

// export default new DataSource({
//   store: store,
// });
