<template>
  <div>
    <div class="d-flex align-items-center mt-4 gap-1">
      <div class="text-center" style="writing-mode: vertical-lr; text-orientation: mixed; transform: scale(-1, -1)">
        {{ $t("calibracion") }}
      </div>
      <div
        class="border border-3 border-top-0 border-end-0 border-bottom-0 ps-3"
        style="border-color: var(--accent) !important"
      >
        <div class="d-flex flex-wrap gap-3">
          <Display
            :label="$t('cadencia_calibracion')"
            :value="dataSourceCadenciasInstrumento.find((x) => x.id === instrumento?.cadencia_calibracion)?.value"
          />

          <Display v-if="!isOtros" :label="$t('frecuencia_calibracion')" :value="instrumento?.frecuencia_calibracion" />

          <Display
            :label="$t('fecha_ultima_calibracion')"
            :value="instrumento?.fecha_ultima_calibracion && $moment(instrumento?.fecha_ultima_calibracion).format('L')"
          />

          <Display
            v-if="!isNumeroDeUsos"
            :label="$t('fecha_proxima_calibracion')"
            :value="
              instrumento?.fecha_proxima_calibracion && $moment(instrumento?.fecha_proxima_calibracion).format('L')
            "
          />
          <Display
            v-if="isNumeroDeUsos"
            :label="$t('numero_usos_calibracion')"
            :value="instrumento?.numero_usos_calibracion"
          />
        </div>
        <Display
          class="mt-2"
          :label="$t('texto_otros_calibracion')"
          :value="instrumento?.texto_otros_calibracion"
          :full="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { OTROS, NUMERO_DE_USOS } from "@/core/cadencias/constantes";
import Display from "../core/Display.vue";
import cadencias from "@/data/instrumentos/Cadencias";

export default {
  props: ["instrumento"],

  computed: {
    isOtros() {
      return this.instrumento?.cadencia_calibracion === OTROS;
    },
    isNumeroDeUsos() {
      return this.instrumento?.cadencia_calibracion === NUMERO_DE_USOS;
    },
    dataSourceCadenciasInstrumento() {
      return cadencias.map((e) => ({
        ...e,
        value: this.$t(e.value),
      }));
    },
  },

  components: { Display },
};
</script>
