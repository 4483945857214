import { get, post, put, remove } from "@/core/fetch";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

export class CustomStoreVerificacionesInstrumento extends CustomStore {
  constructor(id_instrumento) {
    const baseUrl = `${global.API_URL}/instrumentos/${id_instrumento}/verificaciones`;

    super({
      key: "id",
      loadMode: "raw",
      // cacheRawData: false,
      load: () => get(`${baseUrl}`),
      // byKey: (key) => get(`${baseUrl}/${key}`),
      insert: (values) => post(`${baseUrl}`, values),
      update: (key, values) => put(`${baseUrl}/${key}`, values),
      remove: (key) => remove(`${baseUrl}/${key}`),
    });
  }
}

/*
Hay un problema con el lookup...
Cuando es nulo el resultado no puedo filtrar por este,
ya que considera como filtro borrado y muestra todos los resultados.
Hacemos un postProceso de los datos y reemplazamos los nulos por -1000
así podemos pedir filtrar por un -1000 y mostrar los registros
con resultado "null"
*/
const desnullify = (data) => {
  console.log("postProcess", data);

  return data.map((item) => ({
    ...item,
    resultado: item.resultado ?? -1000,
  }));
};

export class DataSourceVerificacionesInstrumento extends DataSource {
  constructor(id_instrumento) {
    const store = new CustomStoreVerificacionesInstrumento(id_instrumento);
    super({
      store,
      postProcess: desnullify,
    });
  }
}
