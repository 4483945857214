<!-- eslint-disabled -->
<template>
  <div>
    <div class="item">
      <h3>
        <img style="height: 24px" :src="require('@/assets/tipos/' + estructura.tipo + '.png')" />
        <sub>
          <sub>{{ $t(displayTipo.value) }}</sub>
        </sub>
        <span class="px-2">{{ estructura.descripcion }}</span>
      </h3>

      <div class="d-flex flex-wrap mt-4" style="gap: 10px">
        <!--
          Pantalla 1:
            (nivel 1 del árbol)
            * Tipo
            * descripcion
            * proceso
            - producto
            - departamento
            - proveedores
            * notas
            + instrucciones
            *+ fotos
            *+ documentos
          Pantalla 2:
            (nivel 2 del árbol / inspeccion del proceso)
            * tipo === inspeccion del proceso <-- no editable?
            * descripcion
            * proceso
            - maquina
            * notas
            *+ fotos
            *+ documentos
          Pantalla 3:
            (nivel 2 del árbol / inspección del producto)
            * tipo === inspección del producto
            * descripcion
            * proceso
            - dispositivo
            * notas
            *+ fotos
            *+ documentos
          Caracteristica:
            (nivel 3 del árbol)
            + caracteristicas
          -->

        <div v-if="!showCaracteristicas" class="d-flex flex-column w-100">
          <Pantalla1
            v-if="showPantalla1"
            :estructura.sync="estructuraComputed"
            :showEdit.sync="showEditPantalla1"
            :update="update"
          ></Pantalla1>
          <Pantalla2
            v-if="showPantalla2"
            :estructura.sync="estructuraComputed"
            :showEdit.sync="showEditPantalla2"
            :update="update"
          ></Pantalla2>
          <Pantalla3
            v-if="showPantalla3"
            :estructura.sync="estructuraComputed"
            :showEdit.sync="showEditPantalla3"
            :update="update"
          ></Pantalla3>

          <div class="d-flex flex-column plan notas readonly w-100">
            <label class="form-label">{{ $t("notas") }}</label>
            <div class="form-data text-justify" v-html="displayNotas"></div>
          </div>

          <!-- botones -->
          <div class="botones">
            <DxButton icon="edit" type="default" stylingMode="text" @click="onClickButtonEdit"></DxButton>
          </div>
        </div>

        <div v-else>
          <Caracteristicas :planID="this.idPlan" :estructuraID="estructuraComputed.id" :group="group">
          </Caracteristicas>
        </div>

        <!-- <div class="w-100">
          <h3>{{ $t("fotos") }}</h3>
          <AttachedViewer
            ref="attachedImage"
            type="IMG"
            @loaded="handleImageLoaded"
            :resourceURI="`planes/${this.idPlan}/estructura/${this.estructura.id}`"
          >
          </AttachedViewer>
        </div> -->
        <AttachedImageViewerBlock
          ref="attachedImageViewerBlock"
          class="w-100"
          :title="$t('fotos')"
          :resourceURI="`planes/${this.idPlan}/estructura/${this.estructura.id}`"
          @changed="onChangedImages"
        />

        <!-- <div class="w-100">
          <h3>{{ $t("documentos") }}</h3>

          <AttachedDocumentViewer
            ref="attachedDocument"
            @loaded="handleDocumentLoaded"
            :resourceURI="`planes/${this.idPlan}/estructura/${this.estructura.id}`"
          >
          </AttachedDocumentViewer>
        </div> -->

        <AttachedDocumentViewerBlock
          class="w-100"
          :title="$t('documentos')"
          :resourceURI="`planes/${this.idPlan}/estructura/${this.estructura.id}`"
          @changed="onChangedDocuments"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DxButton from "devextreme-vue/button";

// import AttachedViewer from "@/components/attached-viewer.vue";
// import AttachedDocumentViewer from "@/components/attached-document-viewer.vue";

import tipos from "@/data/TipoEstructura";
import Pantalla1 from "@/components/plancontrol/estructura/Pantalla1.vue";
import Pantalla2 from "@/components/plancontrol/estructura/Pantalla2.vue";
import Pantalla3 from "@/components/plancontrol/estructura/Pantalla3.vue";

import Caracteristicas from "@/components/plancontrol/Caracteristicas.vue";
import AttachedImageViewerBlock from "../attached-image-viewer-block.vue";
import AttachedDocumentViewerBlock from "../attached-document-viewer-block.vue";

export default {
  props: ["id", "estructura", "idPlan", "group"],
  data() {
    return {
      showEditPantalla1: false,
      showEditPantalla2: false,
      showEditPantalla3: false,

      componentKey: 0,
      isPopupVisible: false,

      // Convertimos el array a diccionario para optimizar el acceso.
      tipos: tipos.reduce((a, x) => ({ ...a, [x.id]: x }), {}),
    };
  },

  components: {
    DxButton,
    // AttachedViewer,
    // AttachedDocumentViewer,
    Caracteristicas,
    Pantalla1,
    Pantalla2,
    Pantalla3,
    AttachedImageViewerBlock,
    AttachedDocumentViewerBlock,
  },
  asyncComputed: {
    displayNotas: async function () {
      if (!this.estructura.notas) return "";
      return this.estructura.notas.replace(/\n/g, "<br />");
    },
  },
  computed: {
    estructuraComputed: {
      get() {
        return this.estructura;
      },
      set(value) {
        this.$emit("update:estructura", value);
      },
    },
    showPantalla1: function () {
      return this.tipos[this.estructura.tipo].pantalla === "Pantalla1";
    },
    showPantalla2: function () {
      return this.tipos[this.estructura.tipo].pantalla === "Pantalla2";
    },
    showPantalla3: function () {
      return this.tipos[this.estructura.tipo].pantalla === "Pantalla3";
    },
    showCaracteristicas: function () {
      return this.tipos[this.estructura.tipo].pantalla === "Caracteristicas";
    },

    idioma: function () {
      return this.$i18n.locale;
    },

    displayTipo: function () {
      // return this.tipos.find((e) => this.estructura.tipo === e.id);
      return this.tipos[this.estructura.tipo];
    },

    fotos: {
      set(value) {
        this.estructuraComputed.fotos = value;
        // al modificar las fotos tengo que emitir que ha cambiado estructura
        // para que el árbol actualiza el número de fotos que tiene el nodo.
        this.$emit("update:estructura", this.estructuraComputed);
      },
      get() {
        return this.estructura.fotos;
      },
    },

    documentos: function () {
      return this.estructuraComputed.documentos;
    },
  },

  watch: {
    idioma() {
      this.componentKey++;
    },
  },

  async mounted() {},

  beforeDestroy() {
    // document.removeEventListener("keydown", this.doSave);
  },

  methods: {
    log(...args) {
      console.log(...args);
    },

    onChangedImages(e) {
      // this.$emit("inserted");
      console.log("onChangedImages", e);
      this.estructuraComputed.fotos = e;
      this.$emit("update:estructura", this.estructuraComputed);
    },

    onChangedDocuments(e) {
      console.log("onChangedDocuments", e);
      this.estructuraComputed.documentos = e;
      this.$emit("update:estructura", this.estructuraComputed);
    },

    // handleImageLoaded(e) {
    //   console.log("handleImageLoaded", e, this.estructuraComputed);
    //   this.estructuraComputed.fotos = e;
    //   this.$emit("update:estructura", this.estructuraComputed);
    // },

    // handleDocumentLoaded(e) {
    //   console.log("handleDocumentLoaded", e, this.estructuraComputed);
    //   this.estructuraComputed.documentos = e;
    //   this.$emit("update:estructura", this.estructuraComputed);
    // },

    repaint() {
      this.$refs.attachedImageViewerBlock.repaint();
      // this.$refs.attachedDocument.repaint();
    },

    onClickButtonEdit() {
      this.showPantalla1 && (this.showEditPantalla1 = this.showPantalla1);
      this.showPantalla2 && (this.showEditPantalla2 = this.showPantalla2);
      this.showPantalla3 && (this.showEditPantalla3 = this.showPantalla3);
    },

    async update(estructura) {
      // estructura editable es e.itemData del nodo del árbol.
      // éste tiene unos campos que no existen en la base de datos y por ello
      // tengo que hacerme una cópia de éste y eliminarle propiedades!
      let send = { ...estructura };

      const fotos = estructura.fotos;
      const documentos = estructura.documentos;
      // const selected = estructura.selected;
      // const expanded = estructura.expanded;

      delete send.fotos;
      delete send.documentos;
      delete send.selected;
      delete send.expanded;

      try {
        const response = await this.$fetch.put(
          `${global.API_URL}/planes/${estructura.id_plancontrol}/estructura/${estructura.id}`,
          send
        );

        // asigno el retorno del put que tendrá el objeto actualizado (y trazabilidades nuevas...)
        // Le vuevo a poner las propiedades que he quitado antes...
        send = response; // await response.json();
        send.fotos = fotos;
        send.documentos = documentos;
        send.expanded = true;
        send.selected = true;

        this.$emit("update:estructura", send);
      } catch (ex) {
        this.$emit("error");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.situaciones-list {
  padding-left: 15px;

  li::marker {
    color: #ccc;
  }

  li.selected::marker {
    color: var(--accent);
  }

  li {
    img {
      opacity: 0.2;
    }

    color: #ccc;
  }

  .selected {
    img {
      opacity: 1;
    }

    color: black;
  }
}

.readonly {
  i {
    color: gray;
  }
}

.item {
  position: relative;

  .botones {
    text-align: right;
    padding: 10px 0;
    height: 48px;
  }
}

.readonly .form-data {
  text-align: left;
}

tr.active {
  background-color: #eee;
}

td.active {
  background-color: #3a3;
}
</style>
