<template>
  <div>
    <h3 class="d-flex justify-content-between">
      {{ $t("planos") }}
      <DxButton
        icon="add"
        type="default"
        stylingMode="contained"
        :hint="$t('agregar_plano')"
        @click="() => this.$refs.gridPlanos.instance.addRow()"
        class="mx-3"
      />
    </h3>

    <DxDataGrid
      ref="gridPlanos"
      id="gridPlanos"
      class="dx-card wide-card"
      :data-source="planosDataSource"
      keyExpr="id"
      :hover-state-enabled="true"
      :noDataText="$t('noHayPlanos')"
    >
      <DxEditingGrid :allow-updating="true" :allow-adding="false" :allow-deleting="true" mode="row" />

      <DxColumnGrid data-field="codigo" :caption="$t('codigo')">
        <DxRequiredRule />
      </DxColumnGrid>
      <DxColumnGrid data-field="descripcion" :caption="$t('descripcion')"></DxColumnGrid>
      <DxColumnGrid data-field="nivel" :caption="$t('nivel')"></DxColumnGrid>
      <DxColumnGrid data-field="fecha" :caption="$t('fecha')" data-type="datetime"></DxColumnGrid>
      <DxColumnGrid data-field="subplano" :caption="$t('subplano')"></DxColumnGrid>
      <DxColumnGrid data-field="nivel_subplano" :caption="$t('nivelSubplano')"></DxColumnGrid>
      <DxColumnGrid data-field="fecha_subplano" :caption="$t('fechaSubplano')" data-type="datetime"></DxColumnGrid>
      <DxColumnGrid data-field="fecha_inicio" :caption="$t('fechaInicio')" data-type="datetime"></DxColumnGrid>
      <DxColumnGrid data-field="vigente" :caption="$t('vigente')"></DxColumnGrid>
      <DxColumnGrid data-field="fecha_fin" :caption="$t('fechaFin')" data-type="datetime"></DxColumnGrid>
      <DxColumnGrid data-field="aqp" :caption="$t('aqp')"></DxColumnGrid>
      <DxColumnGrid data-field="certificado" :caption="$t('certificado')"></DxColumnGrid>
      <DxColumnGrid data-field="notas" :caption="$t('nota')"></DxColumnGrid>
    </DxDataGrid>
  </div>
</template>

<script>
import { DxButton } from "devextreme-vue/button";
import { DxDataGrid } from "devextreme-vue/data-grid";
import { DxColumn as DxColumnGrid } from "devextreme-vue/data-grid";
import { DxEditing as DxEditingGrid } from "devextreme-vue/data-grid";
import { DxRequiredRule } from "devextreme-vue/validator";

import { PlanosDataSource } from "@/data/plancontrol/PlanosDataSource.js";

export default {
  components: {
    DxButton,
    DxDataGrid,
    DxColumnGrid,
    DxEditingGrid,
    DxRequiredRule,
  },
  props: {
    idPlan: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      planosDataSource: new PlanosDataSource(this.idPlan),
    };
  },

  methods: {},
};
</script>
