<template>
  <div id="reportVAR">
    <div class="reportContent">
      <h2>{{ $t("graficoPorAtributos") }}: {{ $t("multiplesDefectos") }}</h2>

      <div class="d-flex flex-row pt-2 pb-4" style="gap: 50px">
        <div class="d-flex flex-column plan-control">
          <label class="form-label fw-bold">{{ $t("planControl") }}</label>

          <label class="form-label"
            >{{ $t("referencia") }} / {{ $t("pieza") }} /
            {{ $t("fase") }}</label
          >
          <label>
            {{ info.plan.referencia }} / {{ info.plan.nombre }} /
            {{ info.estructura.descripcion }}
          </label>
        </div>

        <div class="d-flex flex-column caracteristica">
          <label class="form-label fw-bold">{{ $t("caracteristica") }}</label>

          <label class="form-label"
            >{{ $t("numero") }} / {{ $t("nombre") }} /
            {{ $t("especificacion") }}
          </label>

          <div class="form-data">
            {{ info.caracteristica.id }} / {{ info.caracteristica.nombre }} /
            <div
              style="display: inline-block"
              v-html="info.caracteristica.especificacion"
            ></div>
          </div>
        </div>
      </div>
      Desde: {{ $moment(info.analisis[0].fecha).format("L") }} Hasta:
      {{ $moment(info.analisis[info.analisis.length - 1].fecha).format("L") }}
      <br />
      <br />
      <img :src="dataImageX" width="100%" @load="ImageXLoad" />
      <br />
      <br />
      <table width="100%" border="1px solid black">
        <thead>
          <tr>
            <th>{{ $t("defecto") }}</th>
            <th>{{ $t("piezasDefectuosas") }}</th>
            <th>{{ $t("frecuenciaRelativa") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(defecto, i) in defectos" :key="i">
            <td>{{ defecto.defecto }}</td>
            <td>{{ defecto.cantidad }}</td>
            <td>{{ defecto.frecuenciaRelativa.toFixed(2) }}%</td>
          </tr>
        </tbody>
      </table>

      Total defectuosas: {{ info.totales.sumTD }}
      <br />
      Tamaño muestra considerada: {{ info.totales.sumTM }}
    </div>
  </div>
</template>

<script>
import jspdf from "jspdf";
import html2canvas from "html2canvas";

export default {
  props: ["info", "metodoAnalisis"],

  computed: {
    //  totalDefectuosas: function(){
    //      return this.info.puntos.defectos.reduce((acc,current)=> acc+current.cantidad, 0 );
    //   },
    //   tamanoMuestra : function(){
    //      return this.info.analisis.reduce((acc,current)=> acc+current.tamano_muestra, 0 );
    //   },
    defectos: function () {
      const aux = [...this.info.puntos.defectos];
      return aux
        .sort(function (a, b) {
          return b.cantidad - a.cantidad;
        })
        .map((x) => ({
          ...x,
          frecuenciaRelativa: (x.cantidad * 100) / this.info.totales.sumTD,
        }));
    },
  },

  data() {
    return {
      loaded: false,
      dataImageX: null,
    };
  },

  methods: {
    print: async function (el) {
      // if(this.loaded) return this.ImageXLoad();

      const canvas = await html2canvas(el);
      this.dataImageX = canvas.toDataURL();
    },

    ImageXLoad: function () {
      const doc = new jspdf({ unit: "px", hotfixes: ["px_scaling"] });
      doc.html(this.$el.innerHTML, {
        callback: function (doc) {
          //doc.save();
          window.open(doc.output("bloburl"), "_blank");
        },
      });
      // this.$emit("loaded");
      this.loaded = true;
    },
  },
};
</script>


<style>
.reportContent {
  width: 19cm;
  padding: 0.5cm;
  margin: 1cm;
  box-sizing: border-box;
}
</style>