<!-- eslint-disabled -->
<template>
  <div :key="componentKey">
    <h2 class="content-block">{{ $t("instrumentos") }}</h2>
    <div class="content-block">
      <div class="dx-card">
        <code class="p-3 d-block" v-if="$store.state.debug">
          {{ dataSourceTipoInstrumento }} <br /><br />
          {{ dataSourceEstadoInstrumento }}
        </code>

        <!-- <h1>Añadir toolbar: - imprimir plan (exportar grid) - duplicar plan</h1> -->
        <!-- <GridLayout :gridRef="this.$refs[gridRefName]" :gridName="gridRefName" /> -->
        <!-- <ControlPlanDuplicate :controlPlan="selectedPlan"></ControlPlanDuplicate> -->

        <DxPopup :visible.sync="isPopupDuplicateVisible">
          <code v-if="$store.state.debug"> </code>

          <div>
            <h5>Selecciona que quieres duplicar</h5>

            <!-- <DxCheckBox :value.sync="relatedTables" name="duplicateConjuntos" text="Conjuntos Implicados"></DxCheckBox>
            <br />
            <DxCheckBox :value.sync="relatedTables" name="duplicateEquipos" text="Equipos"></DxCheckBox>
            <br />
            <DxCheckBox :value.sync="relatedTables" name="duplicateEstructura" text="Estructura"></DxCheckBox>
            <br />
            <DxCheckBox :value.sync="relatedTables" name="duplicateNotas" text="Notas"></DxCheckBox>
            <br />
            <DxCheckBox :value.sync="relatedTables" name="duplicatePlanos" text="Planos"></DxCheckBox>
            <br />
            <DxCheckBox :value.sync="relatedTables" name="duplicatePlantas" text="Plantas"></DxCheckBox>
            <br />
            <DxCheckBox :value.sync="relatedTables" name="duplicateRevisiones" text="Revisiones"></DxCheckBox>
            <br />
            <DxCheckBox :value.sync="relatedTables" name="duplicateFotos" text="Fotos"></DxCheckBox>
            <br />
            <DxCheckBox :value.sync="relatedTables" name="duplicateDocumentos" text="Documentos"></DxCheckBox>
            <br />

            <input type="checkbox" id="pcconjuntosimplicados" value="pcconjuntosimplicados" v-model="relatedTables">
            <label for="pcconjuntosimplicados">Conjuntos Implicados</label>

            <input type="checkbox" id="pcequipo" value="pcequipo" v-model="relatedTables">
            <label for="pcequipo">Equipos</label>

            <input type="checkbox" id="pcestructura" value="pcestructura" v-model="relatedTables">
            <label for="pcestructura">Estructura</label>

            <input type="checkbox" id="pcnotas" value="pcnotas" v-model="relatedTables">
            <label for="pcnotas">Notas</label>

            <input type="checkbox" id="pcplanos" value="pcplanos" v-model="relatedTables">
            <label for="pcplanos">Planos</label>

            <input type="checkbox" id="pcplantasconsumidoras" value="pcplantasconsumidoras" v-model="relatedTables">
            <label for="pcplantasconsumidoras">Plantas consumidoras</label>

            <input type="checkbox" id="pcrevisiones" value="pcrevisiones" v-model="relatedTables">
            <label for="pcrevisiones">Revisiones</label>

            <input type="checkbox" id="fotos" value="fotos" v-model="relatedTables">
            <label for="fotos">Fotos</label>

            <input type="checkbox" id="documentos" value="documentos" v-model="relatedTables">
            <label for="documentos">Documentos</label> -->
          </div>

          <DxButton @click="onClickDuplicate"></DxButton>
        </DxPopup>

        <DxDataGrid
          :ref="gridRefName"
          :id="gridRefName"
          class="dx-card wide-card"
          :data-source="customStoreInstrumentos"
          keyExpr="id"
          :hover-state-enabled="true"
          :allow-column-resizing="true"
          :allow-column-reordering="true"
          :column-auto-width="false"
          :selection="{ mode: 'single' }"
          :noDataText="$t('noHayInstrumentos')"
          :height="gridHeight"
          :paging="{
            enabled: true,
          }"
          @selection-changed="onSelectionChanged"
          @rowDblClick="onRowDblClick"
          @initNewRow="onInitNewRow"
          @exporting="onExporting"
        >
          <DxStateStoring :enabled="true" type="localStorage" storage-key="grid-instrumentos" :savingTimeout="0" />

          <!-- <dx-filter-row :visible="false" />-->
          <!-- <dx-header-filter :visible="false" /> -->

          <DxColumnChooser :enabled="true" :height="500" mode="select" :allowSearch="true" />
          <DxColumnFixing :enabled="false" />

          <DxEditingGrid :allow-updating="false" :allow-adding="true" :allow-deleting="false" mode="popup">
            <DxPopupGrid :title="$t('instrumentos')" :show-title="true" :width="700" :height="400">
              <!-- <DxToolbarGrid>
                <DxItemGrid location="before" widget="dxButton" :options="{ icon: 'duplicate', text: $t('duplicar'), onClick: duplicateClick }" />
              </DxToolbarGrid> -->
            </DxPopupGrid>
            <DxForm colCount="1" labelMode="floating" title="Añade un instrumento">
              <DxFormItem dataField="codigo" :editorOptions="{ required: true }" />
              <DxFormItem dataField="nombre" />
              <DxFormItem dataField="tipo" />
              <DxFormItem dataField="estado" />
            </DxForm>
          </DxEditingGrid>

          <DxSearchPanel :visible="true" :width="240" />
          <DxFilterRow :visible="true"></DxFilterRow>
          <DxFilterPanel :visible="true"></DxFilterPanel>
          <DxHeaderFilter :visible="true" />
          <DxGroupPanel :visible="true" />
          <DxGrouping :auto-expand-all="true" />

          <DxSummary>
            <DxTotalItem column="nombre" summary-type="count" :display-format="$t('total') + ': {0}'" />
          </DxSummary>

          <DxLoadPanel :enabled="true" />

          <!-- Para mostrar todos lo elementos: -->
          <DxScrolling mode="standard" />
          <!-- <DxScrolling mode="virtual" /> -->
          <!-- <DxScrolling mode="infinite" /> -->

          <DxExport :enabled="true" :formats="['xlsx']" />

          <DxToolbar>
            <DxItem location="before" name="groupPanel"></DxItem>
            <DxItem location="after" name="layoutButton" template="layoutButtonTemplate"> </DxItem>
            <DxItem location="after" name="duplicate" template="duplicateTemplate"> </DxItem>
            <DxItem location="after" name="exportButton"></DxItem>
            <DxItem location="after" name="addRowButton"></DxItem>
            <DxItem location="after" name="columnChooserButton"></DxItem>
            <!-- <DxItem location="after" name="addInstrumento" template="addInstrumentoTemplate"> </DxItem> -->
            <DxItem location="after" name="refresh" template="refreshTemplate"></DxItem>
            <DxItem location="after" name="searchPanel"></DxItem>
          </DxToolbar>

          <template #layoutButtonTemplate>
            <GridLayout :gridRef="$refs[gridRefName]" :gridName="gridRefName" />
          </template>

          <template #duplicateTemplate>
            <DxButton
              icon="copy"
              stylingMode="text"
              :disabled="selectedInstrumento === undefined"
              @click="onClickDuplicate"
            ></DxButton>
          </template>

          <template #refreshTemplate>
            <DxButton icon="refresh" @click="$refs[gridRefName].instance.refresh()"></DxButton>
          </template>

          <template #addInstrumentoTemplate>
            <DxButton icon="add" @click="onClickAddNewInstrumento"></DxButton>
          </template>

          <DxColumn data-field="id" :caption="$t('numero')" width="80" sort-order="desc" :allowEditing="false" />

          <DxColumn data-field="codigo" :caption="$t('codigo')">
            <DxRequiredRule />
          </DxColumn>

          <DxColumn data-field="nombre" :caption="$t('nombre')">
            <DxRequiredRule />
          </DxColumn>

          <DxColumn data-field="id_clasificacion" :caption="$t('clasificacion')" :visible="true">
            <DxLookup display-expr="nombre" value-expr="id" :data-source="dataSourceClasificacionInstrumento">
            </DxLookup>
          </DxColumn>

          <DxColumn
            data-field="path_corto"
            :caption="$t('pathCorto')"
            :visible="false"
            :allowEditing="false"
            :showInColumnChooser="false"
          >
          </DxColumn>

          <DxColumn
            data-field="fecha_alta"
            data-type="date"
            :caption="$t('fecha_alta')"
            :visible="false"
            :allowEditing="false"
          >
          </DxColumn>

          <DxColumn
            data-field="fecha_baja"
            data-type="date"
            :caption="$t('fecha_baja')"
            :visible="false"
            :allowEditing="false"
          >
          </DxColumn>

          <DxColumn data-field="id_categorias" :caption="$t('categoria')" :visible="true">
            <DxLookup display-expr="categoria" value-expr="id" :data-source="dataSourceCategoriasInstrumento">
            </DxLookup>
          </DxColumn>

          <DxColumn data-field="tipo" :caption="$t('tipo')" cell-template="tipo" :visible="true">
            <DxLookup display-expr="value" value-expr="id" :data-source="dataSourceTipoInstrumento"> </DxLookup>
          </DxColumn>

          <template #tipo="{ data }">
            <div>
              <img
                :src="require(`@/assets/${dataSourceTipoInstrumento.find((x) => x.id === data.value).img}`)"
                :title="dataSourceTipoInstrumento.find((x) => x.id === data.value).value"
                height="24"
              />
            </div>
          </template>

          <DxColumn data-field="estado" cell-template="estado" :caption="$t('estado')">
            <DxLookup display-expr="value" value-expr="id" :data-source="dataSourceEstadoInstrumento"> </DxLookup>
          </DxColumn>

          <template #estado="{ data }">
            <div>
              <img
                :src="require(`@/assets/${dataSourceEstadoInstrumento.find((x) => x.id === data.value).img}`)"
                :title="dataSourceEstadoInstrumento.find((x) => x.id === data.value).value"
                height="24"
              />
            </div>
          </template>

          <DxColumn
            name="ubicaciones"
            data-field="UNBOUND_ubicaciones"
            calculate-display-value="UNBOUND_displayUbicaciones"
            :calculate-filter-expression="calculateFilterExpressionUbicaciones"
            :caption="$t('ubicaciones')"
            :visible="true"
            :allow-search="true"
            :allow-filtering="true"
            data-type="string"
            cell-template="ubicacionesTemplate"
          >
            <DxHeaderFilter :visible="false" :allowSearch="true" :data-source="headerFilterData" />
          </DxColumn>

          <template #ubicacionesTemplate="{ data }">
            <ul class="ubicaciones_list" v-if="data.value">
              <li v-for="ubicacion in data.value" :key="ubicacion?.id">
                {{ ubicacion?.nombre }}
              </li>
            </ul>
          </template>

          <DxColumn data-field="marca" :caption="$t('marca')" :visible="false" :allowEditing="false"> </DxColumn>

          <DxColumn data-field="modelo" :caption="$t('modelo')" :visible="false" :allowEditing="false"> </DxColumn>

          <DxColumn data-field="serie" :caption="$t('serie')" :visible="false" :allowEditing="false"> </DxColumn>

          <DxColumn data-field="norma" :caption="$t('norma')" :visible="false" :allowEditing="false"> </DxColumn>

          <DxColumn data-field="cota_maxima" :caption="$t('cota_maxima')" :visible="false" :allowEditing="false">
          </DxColumn>

          <DxColumn data-field="cota_minima" :caption="$t('cota_minima')" :visible="false" :allowEditing="false">
          </DxColumn>

          <DxColumn
            data-field="precision_elemento"
            :caption="$t('precision_elemento')"
            :visible="false"
            :allowEditing="false"
          >
          </DxColumn>

          <DxColumn
            data-field="precicion_proceso"
            :caption="$t('precision_proceso')"
            :visible="false"
            :allowEditing="false"
          >
          </DxColumn>

          <DxColumn data-field="id_proveedores" :caption="$t('proveedor')" :visible="false">
            <DxLookup display-expr="codigo" value-expr="id" :data-source="dataSourceProveedores"> </DxLookup>
          </DxColumn>

          <DxColumn
            data-field="fecha_compra"
            data-type="date"
            :caption="$t('fecha_compra')"
            :visible="false"
            :allowEditing="false"
          >
          </DxColumn>

          <DxColumn data-field="precio" :caption="$t('precio')" :visible="false" :allowEditing="false"> </DxColumn>

          <DxColumn data-field="id_departamentos" :caption="$t('departamento')" :visible="false">
            <DxLookup display-expr="codigo" value-expr="id" :data-source="dataSourceDepartamentos"> </DxLookup>
          </DxColumn>

          <DxColumn data-field="id_clientes" :caption="$t('cliente')" :visible="false">
            <DxLookup display-expr="codigo" value-expr="id" :data-source="dataSourceClientes"> </DxLookup>
          </DxColumn>

          <DxColumn data-field="id_instrumentos" :caption="$t('sustituye_a')" :visible="false">
            <DxLookup display-expr="codigo" value-expr="id" :data-source="instrumentosCached"> </DxLookup>
          </DxColumn>

          <DxColumn :caption="$t('calibracion')" :allowEditing="false">
            <DxColumn data-field="frecuencia_calibracion" :caption="$t('frecuencia_calibracion')" :visible="false">
            </DxColumn>
            <DxColumn data-field="texto_otros_calibracion" :caption="$t('texto_otros_calibracion')" :visible="false">
            </DxColumn>

            <DxColumn data-field="cadencia_calibracion" :caption="$t('cadencia_calibracion')" :visible="false">
              <DxLookup display-expr="value" value-expr="id" :data-source="dataSourceCadenciasInstrumento"> </DxLookup>
            </DxColumn>

            <DxColumn
              name="frecuencia_calibracion_readable"
              :caption="$t('frecuencia')"
              :visible="true"
              cell-template="frecuencia_calibracion_readable"
              data-type="text"
            >
            </DxColumn>

            <!-- <DxColumn
              data-field="frecuencia_calibracion"
              name="frecuencia_calibracion_readable_v2"
              :caption="$t('frecuencia')"
              :visible="true"
              data-type="number"
              :calculateDisplayValue="
                (rowData) => {
                  return 'a' + rowData.frecuencia_calibracion + 'b';
                }
              "
            >
            </DxColumn> -->

            <!-- <DxColumn data-field="numero_usos_calibracion" :caption="$t('numero_usos_calibracion')" :visible="false">
            </DxColumn> -->

            <DxColumn
              data-field="fecha_ultima_calibracion"
              data-type="date"
              :caption="$t('fecha_ultima_calibracion')"
              :visible="true"
            >
            </DxColumn>
            <DxColumn
              data-type="date"
              data-field="fecha_proxima_calibracion"
              cell-template="fechaProximaTemplate"
              :caption="$t('fecha_proxima_calibracion')"
              :visible="true"
            >
            </DxColumn>

            <DxColumn :width="70" cell-template="customButtonsCalibracion" :showInColumnChooser="false"></DxColumn>
          </DxColumn>

          <!-- <DxColumn type="buttons">
            <DxGridButton
              :text="$t('ir_nueva_calibracion')"
              icon="plus"
              :hint="$t('ir_nueva_calibracion')"
              :onClick="(e) => onClickNewCalibracion(e.row.key)"
            />
            <DxGridButton
              :text="$t('calibraciones')"
              icon="contentlayout"
              :hint="$t('calibraciones')"
              :onClick="(e) => onClickCalibraciones(e.row.key)"
            />
          </DxColumn> -->

          <DxColumn :caption="$t('verificacion')">
            <DxColumn data-field="frecuencia_verificacion" :caption="$t('frecuencia_verificacion')" :visible="false">
            </DxColumn>
            <!-- <DxColumn data-field="cadencia_verificacion" :caption="$t('cadencia_verificacion')" :visible="false">
            </DxColumn> -->

            <DxColumn data-field="cadencia_verificacion" :caption="$t('cadencia_verificacion')" :visible="false">
              <DxLookup display-expr="value" value-expr="id" :data-source="dataSourceCadenciasInstrumento"> </DxLookup>
            </DxColumn>

            <DxColumn data-field="texto_otros_verificacion" :caption="$t('texto_otros_verificacion')" :visible="false">
            </DxColumn>

            <DxColumn
              name="frecuencia_verificacion_readable"
              :caption="$t('frecuencia')"
              :visible="true"
              cell-template="frecuencia_verificacion_readable"
            >
            </DxColumn>

            <!-- <DxColumn data-field="numero_usos_verificacion" :caption="$t('numero_usos_verificacion')" :visible="false">
            </DxColumn> -->

            <DxColumn
              data-type="date"
              data-field="fecha_ultima_verificacion"
              :caption="$t('fecha_ultima_verificacion')"
              :visible="true"
            >
            </DxColumn>
            <DxColumn
              data-type="date"
              data-field="fecha_proxima_verificacion"
              cell-template="fechaProximaTemplate"
              :caption="$t('fecha_proxima_verificacion')"
              :visible="true"
            >
            </DxColumn>

            <DxColumn :width="70" cell-template="customButtonsVerificacion" :showInColumnChooser="false"></DxColumn>
          </DxColumn>

          <template #frecuencia_calibracion_readable="{ data }">
            <div v-if="data">
              <Frecuencia
                :cadencia="data.data.cadencia_calibracion"
                :frecuencia="data.data.frecuencia_calibracion"
                :otros="data.data.texto_otros_calibracion"
              />
            </div>
          </template>

          <template #frecuencia_verificacion_readable="{ data }">
            <div v-if="data">
              <Frecuencia
                :cadencia="data.data.cadencia_verificacion"
                :frecuencia="data.data.frecuencia_verificacion"
                :otros="data.data.texto_otros_verificacion"
              />
            </div>
          </template>

          <template #fechaProximaTemplate="{ data }">
            <div
              v-if="data.value"
              :class="{
                expired: isExpired(data.value),
                'close-to-expired': isCloseToExpired(data.value),
              }"
            >
              {{ $moment(data.value).format("L") }}
            </div>
          </template>

          <template #customButtonsCalibracion="{ data }">
            <div class="smaller">
              <DxButton
                :hint="$t('ver_todas_las_calibraciones_realizadas_al_instrumento')"
                icon="contentlayout"
                styling-mode="text"
                type="default"
                @click="
                  $router.push({
                    name: 'calibraciones_instrumento',
                    params: { id: data.key },
                  })
                "
              />

              <DxButton
                :hint="$t('nueva_calibracion')"
                icon="plus"
                styling-mode="text"
                type="default"
                @click="onClickNewCalibracion(data.key)"
              />
            </div>
          </template>

          <template #customButtonsVerificacion="{ data }">
            <div>
              <!-- <DxButton
                :hint="$t('ir_verificaciones')"
                icon="plus"
                styling-mode="text"
                @click="
                  $router.push({
                    name: 'verificaciones_instrumento',
                    params: { id: data.key },
                  })
                "
              /> -->

              <DxButton
                :hint="$t('ver_todas_las_verificaciones_realizadas_al_instrumento')"
                icon="contentlayout"
                styling-mode="text"
                type="default"
                @click="
                  $router.push({
                    name: 'verificaciones_instrumento',
                    params: { id: data.key },
                  })
                "
              />

              <DxButton
                :hint="$t('nueva_verificacion')"
                icon="plus"
                styling-mode="text"
                type="default"
                @click="onClickNewVerificacion(data.key)"
              />
            </div>
          </template>
        </DxDataGrid>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxLookup,
  DxScrolling,
  DxLoadPanel,
  DxFilterRow,
  DxSearchPanel,
  DxEditing as DxEditingGrid,
  DxFilterPanel,
  //DxRequiredRule,
  DxGroupPanel,
  DxGrouping,
  DxHeaderFilter,
  DxSummary,
  DxTotalItem,
  DxStateStoring,
  DxExport,
  DxToolbar,
  DxItem,
  DxColumnChooser,
  DxColumnFixing,

  // DxButton as DxGridButton,
  DxForm,
  DxPopup as DxPopupGrid,
} from "devextreme-vue/data-grid";
import { DxRequiredRule } from "devextreme-vue/validator";
import { DxItem as DxFormItem } from "devextreme-vue/form";

import { DxButton } from "devextreme-vue/button";

import { DxPopup } from "devextreme-vue/popup";

// import { DxCheckBox } from "devextreme-vue/check-box"

//import { jsPDF } from "jspdf";
//import { exportDataGrid } from "devextreme/pdf_exporter";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

// import { DxSwitch } from "devextreme-vue/switch";

// import CustomStore from "devextreme/data/custom_store";
// import DataSource from "devextreme/data/data_source";
import { DataSourceInstrumentos, CustomStoreInstrumentos } from "../../data/instrumentos/InstrumentoDataSource";
// import { DataSourceUbicaciones } from "../../data/instrumentos/UbicacionesDataSource";
// import { DataSourceUbicacionesInstrumento } from "../../data/instrumentos/UbicacionesInstrumentoDataSource";

//import ArrayStore from 'devextreme/data/array_store';
//import service from './data.js';
import auth from "../../auth";

import { duplicateInstrumento } from "../../api/instrumentos";
import { createCalibracion } from "../../api/calibraciones";

import tipos from "@/data/instrumentos/Tipos";
import estados from "@/data/instrumentos/Estados";

import GridLayout from "../../components/grid-layout.vue";
import { confirm } from "devextreme/ui/dialog";

import cadencias from "@/data/instrumentos/Cadencias";
import Frecuencia from "../../components/instrumentos/Frecuencia.vue";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxLookup,
    DxScrolling,
    DxLoadPanel,
    DxFilterRow,
    DxSearchPanel,
    DxEditingGrid,
    DxFilterPanel,
    DxRequiredRule,
    DxGroupPanel,
    DxGrouping,
    DxHeaderFilter,
    DxSummary,
    DxTotalItem,
    DxColumnChooser,
    DxColumnFixing,
    DxStateStoring,
    // DxGridButton,
    // DxStateStoring
    // DxSwitch,
    DxPopupGrid,
    DxForm,
    DxFormItem,

    Frecuencia,

    GridLayout,

    // DxSwitch,
    DxExport,
    DxToolbar,
    DxItem,
    DxButton,
    //
    DxPopup,
    //
    // DxCheckBox,
  },

  data() {
    const dataSourceInstrumentos = new DataSourceInstrumentos();
    const customStoreInstrumentos = new CustomStoreInstrumentos();

    const calculateFilterExpressionUbicaciones = function (value, selectedFilterOperations, target) {
      const column = this;
      if (selectedFilterOperations === "=") {
        return ["UNBOUND_displayUbicaciones", "contains", value];
      }
      if (selectedFilterOperations === "contains") {
        return ["UNBOUND_displayUbicaciones", "contains", value];
      }

      return column.defaultCalculateFilterExpression(value, selectedFilterOperations, target);
    };

    return {
      window: {
        width: 0,
        height: 0,
      },

      calculateFilterExpressionUbicaciones,

      headerFilterData: [],

      dataSourceInstrumentos,
      customStoreInstrumentos,

      // dataSourceUbicacionesInstrumento: {},
      // dataSourceUbicaciones: [],

      instrumentosCached: false,

      gridRefName: "gridInstrumentos",
      selectedInstrumento: undefined,
      relatedTables: [
        "ubicaciones",
        "caracteristicasACalibrar",
        "caracteristicasAVerificar",
        "patrones",
        "procedimientos",
        // "reuniones"
      ],

      dataSourceClasificacionInstrumento: null,
      dataSourceCategoriasInstrumento: null,
      dataSourceProveedores: null,
      dataSourceDepartamentos: null,
      dataSourceClientes: null,
      dataSourceSustituyeA: null,

      componentKey: 0,

      isPopupDuplicateVisible: false,
    };
  },

  computed: {
    gridHeight() {
      //return window.innerHeight - 300;
      return this.window.height - 220;
    },
    idioma() {
      return this.$i18n.locale;
    },

    dataSourceTipoInstrumento() {
      return tipos.map((e) => ({
        ...e,
        value: this.$t(e.value),
      }));
    },

    dataSourceEstadoInstrumento() {
      return estados.map((e) => ({
        ...e,
        value: this.$t(e.value),
      }));
    },

    dataSourceCadenciasInstrumento() {
      return cadencias.map((e) => ({
        ...e,
        value: this.$t(e.value),
      }));
    },
  },

  methods: {
    log(e) {
      console.log(e);
    },

    isExpired(date) {
      return date < Date.now();
    },
    isCloseToExpired(date) {
      const todayPlus30Days = new Date();
      todayPlus30Days.setDate(todayPlus30Days.getDate() + 30);

      return date > Date.now() && date < todayPlus30Days;
    },
    onSelectionChanged(e) {
      // console.log("-------- selection changed!", e.selectedRowsData[0]);
      this.selectedInstrumento = e.selectedRowsData[0];
    },

    onRowDblClick(e) {
      if (e.isNewRow) return;
      if (e.isEditing) return;

      this.$router.push({
        name: "instrumento",
        params: { id: e.key },
      });
    },

    onInitNewRow(e) {
      e.data.tipo = "I";
      e.data.estado = "U";
    },

    async load() {
      // console.log("load");
      const urls = [
        `${global.API_URL}/instrumentos/clasificacion`,
        `${global.API_URL}/instrumentos/categorias`,
        `${global.API_URL}/proveedores`,
        `${global.API_URL}/departamentos`,
        `${global.API_URL}/clientes`,
        // `${global.API_URL}/instrumentos?cols=id,nombre`,
      ];

      try {
        //obtengo todos los fetch en paralelo
        const responses = await Promise.all(
          urls.map((u) =>
            fetch(u, {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + auth.getToken(),
              },
            })
          )
        );

        const data = await Promise.all(responses.map((r) => r.json()));

        this.dataSourceClasificacionInstrumento = data[0];
        this.dataSourceCategoriasInstrumento = data[1];
        this.dataSourceProveedores = data[2];
        this.dataSourceDepartamentos = data[3];
        this.dataSourceClientes = data[4];
        // this.dataSourceSustituyeA = data[5];
      } catch (ex) {
        console.error(ex);
      }

      this.customStoreInstrumentos.on("loaded", async (data) => {
        console.log("loaded", data);
        this.instrumentosCached = data;
      });
    },

    getTime(date) {
      return this.$moment(date).fromNow();
    },

    getFecha(date) {
      return this.$moment(date).format("L");
    },

    getHora(date) {
      return this.$moment(date).format("LTS");
    },

    handleResize() {
      // console.log(window.innerWidth, window.innerWidth);
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },

    displayExprCliente(item) {
      if (item.nombre && item.codigo) return `${item.codigo} - ${item.nombre}`;
      if (item.nombre) return item.nombre;
      if (item.codigo) return item.codigo;
    },
    displayExprAgrupacion(item) {
      if (item.nombre && item.codigo) return `${item.codigo} - ${item.nombre}`;
      if (item.nombre) return item.nombre;
      if (item.codigo) return item.codigo;
    },
    async onExporting(e) {
      // const doc = new jsPDF();
      // await exportDataGrid({
      //   jsPDFDocument: doc,
      //   component: e.component,
      //   indent: 5,
      // });
      // doc.save("Instrumentos.pdf");
      //Solo exportamos a excel
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Instrumentos");
      exportDataGridToExcel({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true,
        topLeftCell: { row: 2, column: 2 },
        customizeCell: (options) => {
          const { gridCell, excelCell } = options;

          // console.log(gridCell);

          if (gridCell.rowType === "data") {
            excelCell.alignment = {};
            excelCell.alignment.vertical = "top";
            // console.log(gridCell);

            // excelCell.font = { name: "Arial", size: 12 };
            // excelCell.alignment = { horizontal: "left" };
            if (gridCell.column.name === "frecuencia_calibracion_readable") {
              excelCell.alignment.wrapText = true;

              excelCell.value = this.transformFrecuencia(
                gridCell.data.frecuencia_calibracion,
                gridCell.data.cadencia_calibracion,
                gridCell.data.texto_otros_calibracion
              );
            }
            if (gridCell.column.name === "frecuencia_verificacion_readable") {
              excelCell.alignment.wrapText = true;
              excelCell.value = this.transformFrecuencia(
                gridCell.data.frecuencia_verificacion,
                gridCell.data.cadencia_verificacion,
                gridCell.data.texto_otros_verificacion
              );
            }
            if (gridCell.column.name === "ubicaciones") {
              excelCell.alignment.wrapText = true;
              //const ubicaciones = this.calculateCellValueUbicaciones(gridCell.data);
              //excelCell.value = ubicaciones.map((x) => x?.nombre).join("\r\n");
              excelCell.value = gridCell.data.UNBOUND_displayUbicaciones;
            }
          }
        },
      }).then(() => {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(new Blob([buffer], { type: "application/octet-stream" }), "Instrumentos.xlsx");
        });
      });
      e.cancel = true;
    },

    transformFrecuencia(frecuencia, cadencia, otros) {
      if (frecuencia === null) return "";
      if (cadencia === null) return "";
      return cadencia == 6
        ? otros
        : `Cada ${frecuencia} ${this.$t(this.dataSourceCadenciasInstrumento.find((x) => x.id == cadencia)?.value)}`;
    },

    duplicateControlPlan() {
      this.isPopupDuplicateVisible = true;
    },

    refreshDataGrid() {
      this.$refs[this.gridRefName].instance.refresh();
    },

    async onClickDuplicate() {
      if (this.selectedInstrumento === undefined) return console.log("Error, no hay instrumento seleccionado");

      const confirmAnswer = await confirm(
        this.$t("estaAccionDuplicaraElInstrumentoSeleccionadoDeseaContinuar"),
        this.$t("duplicarInstrumento")
      );
      if (!confirmAnswer) return;

      try {
        const { id } = await duplicateInstrumento(this.selectedInstrumento, this.relatedTables);
        this.$notify(this.$t("instrumentoDuplicadoCorrectamente"), "success", 5000);
        this.refreshDataGrid();
        this.$refs[this.gridRefName].instance.navigateToRow(id);
        this.$refs[this.gridRefName].instance.option("selectedRowKeys", [id]);
      } catch (ex) {
        // console.log(ex);
        this.$notify(`${this.$t("errorAlDuplicarInstrumento")}: ${ex}`, "error", 5000);
      }
    },

    async onClickNewVerificacion(idInstrumento) {
      console.log(idInstrumento);

      if (
        !(await confirm(
          "Esta acción creará una nueva verificación, ¿Desea crear una nueva verificación?",
          "Nueva verificación"
        ))
      )
        return;

      console.error("Not implemented yet!");
    },

    // @TODO cambiar add de la grid por un custom add com mejor UI/UX
    async onClickAddNewInstrumento() {
      // this.$router.push({ name: "instrumento", params: { id: 0 } });
    },

    async onClickNewCalibracion(idInstrumento) {
      // console.log(idInstrumento);

      if (
        !(await confirm(
          "Esta acción creará una nueva calibración, ¿Desea crear una nueva calibración?",
          "Nueva calibración"
        ))
      )
        return;

      const initValues = {
        id_instrumentos: idInstrumento,
        resultado: null,
        fecha: Date.now(),
        verificado_por: this.$store.state.user.nombre,
        certificado_por: this.$store.state.user.nombre,
        notas: null,
      };

      try {
        const response = await createCalibracion(initValues);

        // go to edit calibración
        this.$router.push({
          name: "calibracion",
          params: { id_instrumento: idInstrumento, id: response.id },
        });
      } catch (ex) {
        console.error(ex);
        this.$notify("Error al crear calibración", this.$t("error"), 5000);
      }
    },
  },

  created() {
    // console.log("created");
    this.load();

    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  beforeCreate() {},

  async mounted() {
    // console.log("mounted");
  },

  watch: {
    idioma() {
      this.componentKey++;
    },

    async instrumentosCached(newValue) {
      console.log("watching instrumentos", newValue);

      const dataSourceInstrumentosUbicaciones = await this.$fetch.get(`${global.API_URL}/instrumentos/ubicaciones`);
      const dataSourceUbicacionesInstrumento = await this.$fetch.get(`${global.API_URL}/instrumentos/@all/ubicaciones`);

      this.headerFilterData = dataSourceInstrumentosUbicaciones.map((item) => ({
        text: item.nombre,
        value: item.nombre,
        id: item.id,
        codigo: item.codigo,
        nombre: item.nombre,
      }));

      // tranform array this.datasourceubicaciones to object
      this.dataSourceUbicaciones = dataSourceInstrumentosUbicaciones.reduce((obj, item) => {
        obj[item.id] = item;
        return obj;
      }, {});

      for (const item of newValue) {
        // console.log(item);
        // console.log(this.dataSourceUbicaciones.store().byKey(76));
        // console.log(this.dataSourceUbicacionesInstrumento);
        item.UNBOUND_ubicaciones = dataSourceUbicacionesInstrumento[item.id];
        item.UNBOUND_ubicaciones = item.UNBOUND_ubicaciones?.map((x) => {
          return this.dataSourceUbicaciones[x];
        });
        // item.UNBOUND_displayUbicaciones = item.UNBOUND_ubicaciones?.map((x) => x).join("\r\n");
        // item.UNBOUND_displayUbicaciones = item.UNBOUND_ubicaciones.join("\r\n");
        item.UNBOUND_displayUbicaciones = item.UNBOUND_ubicaciones?.map((x) => {
          return x?.nombre;
        }).join("\r\n");
      }
    },
  },
};
</script>

<style scoped lang="scss">
// .fechaProxima:has(.expired) {
//   background-color: var(--bs-danger);
// }

.expired {
  background-color: var(--bs-danger);
  padding: 3px;
  border-radius: 4px;
  display: inline-block;
  color: white;
}

.close-to-expired {
  background-color: var(--bs-warning);
  padding: 3px;
  border-radius: 4px;
  display: inline-block;

  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

// .dx-swatch-additional{
//   color: white;
// }

img {
  filter: drop-shadow(1px 1px rgba(0, 0, 0, 0.5));
}

.ubicaciones_list {
  list-style: none;
  padding: 0;
  margin: 0;
}
</style>

<style>
.dx-datagrid-filter-panel {
  padding: 10px 11px !important;
}

.dx-pager {
  padding: 10px 11px !important;
}
</style>
