<!-- eslint-disabled -->
<template>
  <div :key="componentKey">
    <Debug>
      {{ nuevaUbicacion }}
    </Debug>
    <!-- <h2 class="content-block">
      <DxButton icon="arrowleft" type="back"
        styling-mode="text"
        @click="$router.go(-1)" />
      {{ $t("ubicaciones") }}
    </h2> -->
    <div class="d-flex align-items-center">
      <TitleWithBackButton :text="$t('ubicaciones')"></TitleWithBackButton>
      <DxButton
        icon="add"
        :text="$t('agregar')"
        styling-mode="back"
        @click="onAgregarUbicacionClick"
        :type="nuevaUbicacion.id_parent === null ? 'default' : 'success'"
      />
      <DxButton icon="refresh" :text="$t('refrescar')" styling-mode="back" type="default" @click="onRefreshUbicacionesClick" />
    </div>

    <div class="content-block">
      <div class="dx-card">
        <DxSortable
          filter=".dx-treeview-item"
          :data="dataSourceUbicaciones"
          :allow-drop-inside-item="true"
          :allow-reordering="false"
          @drag-change="onSortableDragChange"
          @drag-end="onSortableDragEnd"
          @drag-start="onSortableDragStart"
        >
          <!-- height="80vh" -->
          <DxTreeView
            :expandAllEnabled="true"
            id="treeView"
            ref="treeView"
            data-structure="plain"
            :expand-nodes-recursive="true"
            :dataSource="dataSourceUbicaciones"
            key-expr="id"
            parent-id-expr="id_parent"
            @itemContextMenu="onTreeViewItemContextMenu"
            @itemHold="onTreeViewItemContextMenu"
            @itemClick="onTreeViewItemClick"
            @itemSelectionChanged="onTreeViewItemSelectionChanged"
            @optionChanged="onTreeViewOptionChanged"
            selectionMode="single"
            :selectByClick="true"
            showCheckBoxesMode="none"
            height="80vh"
          >
            <template #item="{ data }">
              <div>
                <span v-if="data.selected" class="d-inline-block bullet text-bg-danger"></span>
                {{ data.nombre }}
                <small class="smaller">{{ data.codigo }}</small>
                <span v-if="data.count_instrumentos" class="ms-2 badge rounded-pill text-bg-primary">{{
                  data.count_instrumentos
                }}</span>

                <!-- <code v-if="true" class="px-3">{{ data.selected }}</code> -->
                <!-- <span v-if="data.id_parent" class="ms-2 badge rounded-pill text-bg-danger">{{ data.id_parent }}</span> -->
              </div>
            </template>
          </DxTreeView>
        </DxSortable>

        <DxContextMenu
          ref="treeViewContextMenu"
          :data-source.sync="menuItems"
          target="#treeViewPlanContainer .dx-treeview-item"
          @item-click="contextMenuItemClick"
        />
      </div>
    </div>

    <DxPopup
      ref="popupEdit"
      :visible.sync="isPopupVisible"
      :fullScreen="false"
      @hiding="handleHiding"
      @hidden="handleHidden"
      :title="$t('nueva_ubicacion')"
      width="400"
    >
      <DxToolbarItem :options="toolbarItemCancelarOptions" widget="dxButton" location="after" toolbar="bottom" />
      <DxToolbarItem
        ref="toolbarItemGuardarRef"
        :options="toolbarItemGuardarOptions"
        widget="dxButton"
        location="after"
        toolbar="bottom"
      />

      <div @keydown.83.ctrl.prevent.stop="ctrlSave">
        <!-- <DxValidationGroup ref="validationGroup">
            <DxValidationSummary id="summary" />
          </DxValidationGroup> -->

        <div class="content-block d-flex flex-wrap justify-content-between mt-1">
          <div class="d-flex flex-column flex-wrap gap-3 column-gap-5">
            <DxTextBox :value.sync="nuevaUbicacion.codigo" :label="$t('codigo')"></DxTextBox>
            <DxTextBox :value.sync="nuevaUbicacion.nombre" :label="$t('nombre')"> </DxTextBox>
          </div>
        </div>
      </div>
    </DxPopup>
  </div>
</template>

<script>
import TitleWithBackButton from "@/components/core/TitleWithBackButton.vue";

import { DataSourceUbicaciones } from "../../data/instrumentos/UbicacionesDataSource";

import { jsPDF } from "jspdf";
import { exportDataGrid } from "devextreme/pdf_exporter";

import DxTreeView from "devextreme-vue/tree-view";
import DxSortable from "devextreme-vue/sortable";
import { DxContextMenu } from "devextreme-vue/context-menu";
import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";
import DxTextBox from "devextreme-vue/text-box";
import DxButton from "devextreme-vue/button";

// import situaciones from "@/data/Situaciones";
// console.log(situaciones);

export default {
  components: {
    TitleWithBackButton,
    DxTreeView,
    DxSortable,
    DxContextMenu,
    DxPopup,
    DxToolbarItem,
    DxButton,
    DxTextBox,
  },

  data() {
    return {
      componentKey: 0,
      window: {
        width: 0,
        height: 0,
      },

      isPopupVisible: false,

      dataSourceUbicaciones: new DataSourceUbicaciones(),

      nuevaUbicacion: {
        codigo: "",
        nombre: "",
        id_parent: null,
        path_corto: "/",
        path_largo: "/",
      },

      // customStoreUbicaciones: new CustomStore({
      //   key: "id",
      //   load: () => this.$fetch.get(`${global.API_URL}/instrumentos/ubicaciones`),
      //   byKey: (key) => this.$fetch.get(`${global.API_URL}/instrumentos/ubicaciones/${key}`),
      //   insert: (values) =>
      //     this.$fetch.post(`${global.API_URL}/instrumentos/ubicaciones/`, values),
      //   update: (key, values) =>
      //     this.$fetch.put(`${global.API_URL}/instrumentos/ubicaciones/${key}`, values),
      //   remove: (key) => this.$fetch.delete(`${global.API_URL}/instrumentos/ubicaciones/${key}`),
      // }),

      menuItems: [
        {
          id: "rename",
          icon: "rename",
          text: this.$t("renombrar"),
          // beginGroup: true,
        },

        {
          id: "add",
          icon: "plus",
          text: this.$t("añadirUbicacion"),
        },

        {
          id: "del",
          icon: "trash",
          text: this.$t("eliminar"),
          beginGroup: true,
        },
      ],
    };
  },

  computed: {
    gridHeight() {
      //return window.innerHeight - 300;
      return this.window.height - 300;
    },
    idioma() {
      return this.$i18n.locale;
    },
    toolbarItemCancelarOptions: function () {
      return {
        text: this.$t("cancelar"),
        type: "normal",
        onClick: () => {
          this.isPopupVisible = false;
        },
      };
    },

    toolbarItemGuardarOptions: function () {
      return {
        elementAttr: { class: "buttonSave" },
        text: this.$t("guardar"),
        stylingMode: "contained",
        type: "default",
        onClick: () => {
          // this.innerUpdate();
          this.onClickGuardar();
        },
      };
    },
  },

  methods: {
    // async load() {
    //   const urls = [
    //     // `${global.API_URL}/planes`,
    //     `${global.API_URL}/clientes`,
    //     `${global.API_URL}/agrupaciones`,
    //   ];

    //   //obtengo todos los fetch en paralelo
    //   const responses = await Promise.all(
    //     urls.map((u) =>
    //       fetch(u, {
    //         headers: {
    //           "Content-Type": "application/json",
    //           "Authorization": "Bearer " + auth.getToken(),
    //         },
    //       })
    //     )
    //   );

    //   const data = await Promise.all(responses.map((r) => r.json()));

    //   // this.dataSourcePlanes = data[0];
    //   this.dataSourceClientes = data[0];
    //   this.dataSourceAgrupaciones = data[1];
    // },

    onTreeViewItemContextMenu(e) {
      console.log("onTreeViewItemContextMenu", e);

      // this.selectedTreeViewPlan = e;
      // this.selectedTreeViewPlanData = e.itemData;
      // this.selectedTreeViewPlanNode = e.node;

      // const contextMenu = this.$refs.treeViewPlanContextMenu.instance;
      // const nivel = tipos.find((f) => f.id === e.itemData.tipo).nivel;

      // //Convertimos el array de menuItems en un objeto para optimizar el acceso.
      // const menuItemsObject = this.menuItems.reduce(
      //   (a, x) => ({ ...a, [x.id]: x }),
      //   {}
      // );

      // menuItemsObject.add1.visible = false;
      // menuItemsObject.add2.visible = nivel === 1;
      // menuItemsObject.add3.visible = nivel === 2;
      // menuItemsObject.rename.visible = true;

      // menuItemsObject.del.visible = true;

      // contextMenu.option("dataSource", Object.values(menuItemsObject));
    },

    onSortableDragChange(e) {
      console.log("onSortableDragChange", e);
    },
    onSortableDragEnd(e) {
      console.log("onSortableDragEnd", e);
    },

    onSortableDragStart(e) {
      console.log("onSortableDragStart", e);
    },

    onTreeViewItemClick(e) {
      console.log("onTreeViewItemClick", e.itemData.id);
    },

    onTreeViewItemSelectionChanged(e) {
      console.log("onTreeViewItemSelectionChanged", e);
      // this.nuevaUbicacion.id_parent = e.node.selected ? e.node.key : null;
      this.nuevaUbicacion.id_parent = e.itemData.selected ? e.itemData.id : null;
    },

    onTreeViewOptionChanged() {
      // console.log("onTreeViewOptionChanged", e);
    },

    async contextMenuItemClick(e) {
      console.log("contextMenuItemClick", e);
    },

    async onAgregarUbicacionClick() {
      console.log("onAgregarUbicacionClick");
      this.isPopupVisible = true;
    },

    onRefreshUbicacionesClick() {
      console.log("onRefreshUbicacionesClick");
      this.$refs.treeView.instance.getDataSource().reload();
      this.$refs.treeView.instance.repaint();
    },

    handleHiding() {
      // this.isPopupVisible = false;
    },

    handleHidden() {
      // reset form data
      // this.nuevaUbicacion = {
      //   codigo: "",
      //   nombre: "",
      //   id_parent: null,
      //   path_corto: "/",
      //   path_largo: "/",
      // };

      this.nuevaUbicacion.codigo = "";
      this.nuevaUbicacion.nombre = "";

      // this.$refs.treeView.instance.refresh();
      // this.dataSourceUbicaciones.reload();
    },

    async onClickGuardar() {
      // this.customStoreUbicaciones.insert(this.nuevaUbicacion);

      // console.log("this.$refs.treeView.instance", this.$refs.treeView.instance);
      // console.log("this.$refs.treeView.instance", this.$refs.treeView.instance.getDataSource().store());
      // console.log("this.$refs.treeView.instance", this.$refs.treeView.instance.getDataSource().items());

      try {
        console.log(this.$refs.treeView.instance);
        // console.log("items before:", this.$refs.treeView.instance.option("items"));
        const inserted = await this.$refs.treeView.instance.getDataSource().store().insert(this.nuevaUbicacion);
        // this.$refs.treeView.instance.selectItem(inserted.id);
        // await this.$refs.treeView.instance.expandItem(inserted.id_parent);
        this.$refs.treeView.instance.selectItem(inserted.id);
        await this.$refs.treeView.instance.expandItem(inserted.id_parent);
        this.$refs.treeView.instance.repaint();
        await this.$refs.treeView.instance.scrollToItem(inserted.id_parent);
        // await this.$refs.treeView.instance.scrollToItem(inserted.id);
        // console.log("this.$refs.treeView.instance", this.$refs.treeView.instance.getDataSource().items());
      } catch (ex) {
        console.error(ex);
      }

      this.isPopupVisible = false;
    },

    // onContentReady() {
    //   console.log("onContentReady")
    //   this.$refs['treeView'].instance.expandAll();
    // },

    async onExporting(e) {
      const doc = new jsPDF();
      await exportDataGrid({
        jsPDFDocument: doc,
        component: e.component,
        indent: 5,
      });
      doc.save("Ubicaciones.pdf");
    },
  },

  created() {
    // this.load();
    // window.addEventListener("resize", this.handleResize);
    // this.handleResize();
  },

  destroyed() {
    // window.removeEventListener("resize", this.handleResize);
  },

  beforeCreate() {},

  mounted() {
    console.log("🚀 ~ file: ubicaciones.vue:257 ~ mounted ~ mounted");

    const ds = this.$refs["treeView"].instance.getDataSource();
    ds.sort("nombre");
    ds.load().done(function (result) {
      console.log(result);
    });
    this.$refs["treeView"].instance.expandAll();
  },

  watch: {
    idioma() {
      this.componentKey++;
    },
  },
};
</script>

<style lang="scss" scoped>
.smaller {
  font-size: 0.8em !important;
  font-weight: 400;
  font-style: italic;
}

.dx-state-selected > .dx-item {
  background-color: #e6f7ff !important;
}

.bullet {
  height: 6px;
  width: 6px;
  border-radius: 50%;
}
</style>
