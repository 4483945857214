<template>
  <div>
    <code v-if="$store.state.debug">isValid: {{ isValid }}</code>

    <div class="d-flex flex-column align-items-center pb-4" style="gap: 10px">
      <div class="d-flex" style="gap: 10px">
        <div class="d-flex flex-column analisis readonly limite-tolerancia-inferior">
          <label class="form-label" :title="$t('limiteToleranciaInferior')">{{ $t("lti") }}</label>
          <div class="form-data">
            {{ caracteristica.LTI }}
          </div>
        </div>

        <div class="d-flex flex-column analisis readonly valor">
          <label class="form-label">{{ $t("valor") }}</label>
          <div class="form-data" v-bind:class="{ 'text-danger': fueraDeLimites(this.analisis.valor) }">
            {{ analisis.valor }}
          </div>
        </div>

        <div class="d-flex flex-column analisis readonly limite-tolerancia-superior">
          <label class="form-label" :title="$t('limiteToleranciaSuperior')">{{ $t("lts") }}</label>
          <div class="form-data">
            {{ caracteristica.LTS }}
          </div>
        </div>
      </div>

      <DxValidationGroup name="grupo" ref="grupo"> </DxValidationGroup>

      <div class="d-flex flex-column analisis">
        <label class="form-label">{{ $t("minimo") }}</label>
        <div class="form-data">
          <DxNumberBox
            ref="minimo"
            :caption="$t('minimo')"
            v-model="analisis.minimo"
            :elementAttr="fueraDeLimitesAttr(analisis.minimo)"
            :min="0"
            :showSpinButtons="true"
            :step="0.1"
          >
            <DxValidator>
              <DxRangeRule :max="analisis.maximo !== null ? analisis.maximo : +Infinity" :reevaluate="true">
              </DxRangeRule>
            </DxValidator>
          </DxNumberBox>
        </div>
      </div>

      <div class="d-flex flex-column analisis">
        <label class="form-label">{{ $t("maximo") }}</label>
        <div class="form-data">
          <DxNumberBox
            ref="maximo"
            :caption="$t('maximo')"
            v-model="analisis.maximo"
            :elementAttr="fueraDeLimitesAttr(analisis.maximo)"
            :min="0"
            :showSpinButtons="true"
            :step="0.1"
          >
            <DxValidator>
              <DxRangeRule :min="analisis.minimo !== null ? analisis.minimo : -Infinity" :reevaluate="true">
              </DxRangeRule>
            </DxValidator>
          </DxNumberBox>
        </div>
      </div>

      <div class="d-flex flex-column analisis">
        <label class="form-label">{{ $t("promedio") }}</label>
        <div class="form-data">
          <DxNumberBox
            v-model="analisis.promedio"
            :elementAttr="fueraDeLimitesAttr(analisis.promedio)"
            :min="0"
            :showSpinButtons="true"
            :step="0.1"
          >
            <DxValidator>
              <DxRangeRule
                :min="analisis.minimo !== null ? analisis.minimo : -Infinity"
                :max="analisis.maximo !== null ? analisis.maximo : +Infinity"
                :reevaluate="true"
              >
              </DxRangeRule>
              <DxRequiredRule :message="$t('errorPromedioRequerido')" />
            </DxValidator>
          </DxNumberBox>
        </div>
      </div>

      <div class="d-flex flex-column analisis">
        <label class="form-label">{{ $t("tamanoMuestra") }}</label>
        <div class="form-data">
          <DxNumberBox
            v-model="analisis.tamano_muestra"
            :min="0"
            :showSpinButtons="true"
            :step="1"
            :format="tamanoMuestraFormat"
          >
            <DxValidator>
              <DxRequiredRule :message="$t('errorTamonoMuestraRequerido')" />
            </DxValidator>
          </DxNumberBox>
        </div>
      </div>
      <div class="d-flex flex-column analisis readonly recorrido-movil">
        <label class="form-label">{{ $t("recorridoMovil") }}</label>
        <div class="form-data">
          {{ analisis.recorrido_movil }}
        </div>
      </div>

      <span class="h5 d-flex flex-column analisis fuera-de-limites">
        <div v-if="fueraDeLimites(this.analisis.valor)" class="badge badge-danger">
          {{ $t("fueraLimitesTolerancia") }}
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import DxNumberBox from "devextreme-vue/number-box";

import { DxValidator, DxRequiredRule, DxRangeRule } from "devextreme-vue/validator";

import DxValidationGroup from "devextreme-vue/validation-group";

export default {
  props: ["value", "caracteristica"],
  data() {
    return {
      isValid: false,
    };
  },
  computed: {
    analisis: function () {
      return this.value;
    },
    tamanoMuestraFormat: function () {
      return `#0 (${this.caracteristica.tamano_muestra})`;
    },
  },
  components: {
    DxNumberBox,
    DxRequiredRule,
    DxValidator,
    DxRangeRule,
    DxValidationGroup,
  },
  mounted() {
    // console.log("%cMinMax mounted!!!!!!!!", "background-color: lime; color:black");

    this.analisis.tamano_muestra = this.caracteristica.tamano_muestra;
  },

  watch: {
    analisis: {
      immediate: false,
      deep: true,
      handler: function (val) {
        if (this.validacionMinMax()) {
          val.recorrido_movil = parseFloat((val.maximo - val.minimo).toFixed(4));
        }

        val.valor = val.promedio;
        val.rechazo = this.fueraDeLimites(val.valor);

        this.isValid = this.validate();
      },
    },
  },

  methods: {
    validate() {
      const valid = true;

      // valido los rangos de todo el grupo
      // const valid = this.$refs.grupo.instance.validate().isValid;

      /**
       *
       * validate().isValid valida todos los controles y muestra la alerta...
       *   1. Eliminamos el grupo
       *   2. (bypass) Devolvemos siempre true
       *
       *  */

      this.$emit("valid", valid);
      return valid;
    },

    fueraDeLimites: function (valor) {
      if (typeof valor !== "number") return false;
      return valor < this.caracteristica.LTI || valor > this.caracteristica.LTS;
    },

    fueraDeLimitesAttr: function (valor) {
      if (typeof valor !== "number") return { fueraDeLimite: false };

      if (valor < this.caracteristica.LTI || valor > this.caracteristica.LTS) {
        return { fueraDeLimite: true };
      } else {
        return { fueraDeLimite: false };
      }
    },

    areNumbersMinMax() {
      return typeof this.analisis.minimo === "number" && typeof this.analisis.maximo === "number";
    },

    validacionMinMax() {
      if (!this.areNumbersMinMax()) return true;
      return this.analisis.maximo >= this.analisis.minimo;
    },

    // validacionPromedio() {
    //   if (!this.areNumbersMinMax()) return true;

    //   return this.analisis.promedio <= this.analisis.maximo && this.analisis.promedio >= this.analisis.minimo;
    // },
  },
};
</script>

<style scoped>
.analisis {
  width: 150px;
}

.readonly label {
  white-space: nowrap;
}

.text-danger input.dx-texteditor-input {
  color: #dc3545 !important;
}

.text-danger input.dx-texteditor-input {
  color: #dc3545 !important;
}

.dx-numberbox[fueraDeLimite="true"] {
  box-shadow: inset 0 0 0 2px #dc3545 !important;
  background: rgb(255, 242, 242);
}

.fuera-de-limites {
  padding-top: 25px;
  width: auto;
}
</style>
