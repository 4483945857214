<template>
  <div>
    <div class="d-flex flex-column flex-wrap align-items-center" style="gap: 10px">
      <div class="d-flex flex-row align-items-start">
        <Display
          :label="$t('lti')"
          :title="$t('limiteToleranciaInferior')"
          :value="caracteristica.LTI"
          :valueStyle="{
            'bg-danger-subtle border border-2 border-danger fw-bold text-danger-emphasis': fueraDeLimiteInferior,
          }"
        />

        <span class="le" :class="{ 'fw-bold text-danger': fueraDeLimiteInferior }">&le;</span>

        <DxNumberBox
          :label="$t('valor')"
          caption="Valor"
          v-model="analisis.valor"
          :showSpinButtons="true"
          :step="0.01"
          :min="0"
          :width="150"
        >
          <DxValidator>
            <DxRequiredRule :message="$t('errorValorRequerido')" />
          </DxValidator>
        </DxNumberBox>
        <span class="le" :class="{ 'fw-bold text-danger': fueraDeLimiteSuperior }">&le;</span>

        <Display
          :label="$t('lts')"
          :title="$t('limiteToleranciaSuperior')"
          :value="caracteristica.LTS"
          :valueStyle="{
            'bg-danger-subtle border border-2 border-danger fw-bold text-danger-emphasis': fueraDeLimiteSuperior,
          }"
        />
      </div>

      <div class="h5 d-flex flex-column" style="height: 50px">
        <span v-show="fueraDeLimites" class="badge text-bg-danger">
          {{ $t("fueraLimitesTolerancia") }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import DxNumberBox from "devextreme-vue/number-box";
import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";
import Display from "../../core/Display.vue";

export default {
  props: ["value", "caracteristica"],

  mounted() {
    this.analisis.tamano_muestra = 1;
    this.analisis.recorrido_movil = this.caracteristica.tamano_muestra;
  },

  updated() {
    this.analisis.promedio = this.analisis.valor;
    this.analisis.rechazo = this.fueraDeLimites;
  },

  methods: {},

  computed: {
    analisis: function () {
      return this.value;
    },

    fueraDeLimiteSuperior: function () {
      if (typeof this.analisis.valor !== "number") return false;
      return this.analisis.valor > this.caracteristica.LTS;
    },

    fueraDeLimiteInferior: function () {
      if (typeof this.analisis.valor !== "number") return false;
      return this.analisis.valor < this.caracteristica.LTI;
    },
    fueraDeLimites: function () {
      // if (typeof this.analisis.valor !== "number") return false;
      return this.fueraDeLimiteInferior || this.fueraDeLimiteSuperior;
    },
  },

  components: {
    DxNumberBox,
    DxValidator,
    DxRequiredRule,
    Display,
  },
};
</script>

<style>
.le {
  padding: 7px 4px;
  font-size: 2em;
}
</style>
