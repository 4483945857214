import { get, put, remove } from "@/core/fetch";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

export class CustomStoreCalibraciones extends CustomStore {
  constructor() {
    const baseUrl = `${global.API_URL}/instrumentos/calibraciones`;

    super({
      key: "id",
      loadMode: "raw",
      // cacheRawData: false,
      load: async () => {
        const result = await get(`${baseUrl}`);
        return desnullify(result);
      },

      // byKey: (key) => get(`${baseUrl}/${key}`),
      // insert: (values) => post(`${baseUrl}`, values),
      update: (key, values) => put(`${baseUrl}/${key}`, values),
      remove: (key) => remove(`${baseUrl}/${key}`),
    });
  }
}

/*
Hay un problema con el lookup...
Cuando es nulo el resultado no puedo filtrar por este,
ya que considera como filtro borrado y muestra todos los resultados.
Hacemos un postProceso de los datos y reemplazamos los nulos por -1000
así podemos pedir filtrar por un -1000 y mostrar los registros
con resultado "null"
*/
const desnullify = (data) => {
  return data.map((item) => ({
    ...item,
    resultado: item.resultado ?? -1000,
  }));
};

export class DataSourceCalibraciones extends DataSource {
  constructor() {
    const store = new CustomStoreCalibraciones();
    super({
      store,
      // postProcess: desnullify,
    });
  }
}

// export const store = new CustomStore({
//   key: "id",
//   loadMode: "raw",
//   load: () => get(`${global.API_URL}/instrumentos/calibraciones`),
//   byKey: (key) => get(`${global.API_URL}/instrumentos/calibraciones/${key}`),
//   // para insertar necesito acceder al enpoint instrumentos/:ID/calibraciones para insertar sobre el instrumento!
//   // insert: (values) =>
//   //   post(`${global.API_URL}/instrumentos/calibraciones`, values),
//   update: (key, values) =>
//     put(`${global.API_URL}/instrumentos/calibraciones/${key}`, values),
//   remove: (key) =>
//     remove(`${global.API_URL}/instrumentos/calibraciones/${key}`),
// });

// export const datasource = new DataSource({
//   store: store,
// });

// export default datasource;
