<template>
  <div>
    <CaracteristicaViewer :plan="plan" :estructura="estructura" :caracteristica="caracteristica"></CaracteristicaViewer>

    <div class="d-flex flex-row gap-3">
      <Display v-if="isDef100" value="TODAS" :label="$t('tamanoMuestra')" />
      <Display v-else-if="isXR" value="1" :label="$t('tamanoMuestra')" />
      <Display v-else :value="caracteristica.tamano_muestra" :label="$t('tamanoMuestra')" />
      <Display :value="frecuencia.descripcion" :label="$t('frecuencia')" />
      <Display :value="metodoevaluacion.descripcion" :label="$t('metodoEvaluacion')" />
      <Display :value="calibre?.descripcion || caracteristica.calibre" :label="$t('calibre')" />
    </div>
    <Display class="pt-3" :value="caracteristica.reaccion" :label="$t('reaccion')" />

    <div class="d-flex flex-wrap gap-3 mt-5">
      <DxDateBox :label="$t('fechaAnalisis')" v-model="analisis.fecha" ref="fecha" type="datetime" :width="200">
        <DxValidator ref="vFecha" @validated="onValidated">
          <DxRequiredRule :message="$t('errorFechaAnalisisRequerida')" />
        </DxValidator>
      </DxDateBox>

      <DxTextBox class="flex-grow-1" :label="$t('operario')" ref="operario" v-model="analisis.operario">
        <DxValidator ref="vOperario" @validated="onValidated">
          <DxRequiredRule :message="$t('errorOperarioRequerido')" />
        </DxValidator>

        <DxButton :options="resetButton" name="reset" location="after" />
      </DxTextBox>

      <DxTextBox :label="$t('turno')" v-model="analisis.turno" :width="100" />

      <DxTextBox :label="$t('lote')" :caption="$t('lote')" v-model="analisis.lote" :width="100" />

      <DxTextBox :label="$t('linea')" v-model="analisis.secuencia" :width="100" />

      <DxDateBox
        :label="$t('fechaMuestra')"
        ref="fecha_muestra"
        v-model="analisis.fecha_muestra"
        :width="200"
        type="datetime"
      >
        <DxValidator ref="vFechaMuestra" @validated="onValidated">
          <DxRequiredRule :message="$t('errorFechaMuestraRequerida')" />
        </DxValidator>
      </DxDateBox>

      <DxTextArea :label="$t('observaciones')" v-model="analisis.notas" :height="100" width="100%" />
    </div>
  </div>
</template>

<script>
import { DEF, CIENPORCIEN, XR } from "../../../core/metodoAnalisis/constantes.js";

import Display from "../../core/Display.vue";
import CaracteristicaViewer from "../../caracteristica-viewer";

import { DxTextBox, DxButton } from "devextreme-vue/text-box";
import DxDateBox from "devextreme-vue/date-box";
import DxTextArea from "devextreme-vue/text-area";
import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";

import auth from "@/auth";

function handleErrors(response) {
  return response;
}

export default {
  props: ["planID", "caracteristica", "value", "lote", "secuencia", "turno"],
  components: {
    CaracteristicaViewer,
    DxTextBox,
    DxButton,
    DxDateBox,
    DxTextArea,
    DxValidator,
    DxRequiredRule,
    Display,
  },
  data() {
    return {
      isValid: true,
      plan: {},
      estructura: {},
      frecuencia: {},
      metodoevaluacion: {},
      calibre: {},
    };
  },
  mounted: function () {
    // console.log("%cGeneral.vue mounted!!!!!!!!!!!", "color: #222; background-color: orange");

    fetch(`${global.API_URL}/planes/${this.planID}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .then((data) => (this.plan = data))
      .catch(() => {
        throw "Network error";
      });

    fetch(`${global.API_URL}/planes/${this.plan.id}/estructura/${this.caracteristica.id_pcestructura}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .then((data) => (this.estructura = data))
      .catch(() => {
        throw "Network error";
      });

    fetch(`${global.API_URL}/caracteristicas/frecuencias/${this.caracteristica.id_frecuencias}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .then((data) => (this.frecuencia = data))
      .catch(() => {
        throw "Network error";
      });

    fetch(`${global.API_URL}/caracteristicas/metodoevaluacion/${this.caracteristica.id_metodoevaluacion}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .then((data) => (this.metodoevaluacion = data))
      .catch(() => {
        throw "Network error";
      });

    if (this.caracteristica.id_instrumentos) {
      fetch(`${global.API_URL}/instrumentos/${this.caracteristica.id_instrumentos}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.getToken(),
        },
      })
        .then(handleErrors)
        .then((response) => response.json())
        .then((data) => (this.calibre = data))
        .catch(() => {
          throw "Network error";
        });
    }

    //@TODO Promise.all()!!
    // @TODO REFACTOR PLEASE!
  },
  computed: {
    resetButton: function () {
      return {
        visible: this.analisis.operario !== auth.user.nombre,
        icon: "undo",
        onClick: () => {
          this.analisis.operario = auth.user.nombre;
        },
      };
    },

    analisis: function () {
      return this.value;
    },

    isDef100: function () {
      return (
        this.caracteristica.id_metodoanalisis === DEF &&
        this.caracteristica.id_condiciones === CIENPORCIEN &&
        this.caracteristica.tamano_muestra === null
      );
    },
    isXR: function () {
      return this.caracteristica.id_metodoanalisis === XR;
    },
  },

  methods: {
    onValidated() {},
    validate: function () {
      let valid = true;

      valid &&= this.$refs.vFecha.instance.validate().isValid;
      valid &&= this.$refs.vOperario.instance.validate().isValid;
      valid &&= this.$refs.vFechaMuestra.instance.validate().isValid;

      this.$emit("valid", valid);
      return valid;
    },
  },

  updated() {
    this.isValid = this.validate();
  },
};
</script>
