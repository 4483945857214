<template>
  <div class="w-100 d-flex flex-column p-3 border rounded">
    <h3>{{ $t("limitesControl") }}</h3>

    <div class="d-flex flex-row gap-5 p-3">
      <div v-if="false" class="d-flex flex-column gap-4">
        <div class="d-flex flex-column w-100">
          <DxDateBox :label="$t('fechaInicio')" v-model="caracteristicaLocal.fecha_inicio" ref="fecha" type="datetime">
          </DxDateBox>
        </div>
        <div class="d-flex flex-column w-100">
          <DxDateBox :label="$t('fechaFin')" v-model="caracteristicaLocal.fecha_fin" ref="fecha" type="datetime">
          </DxDateBox>
        </div>
      </div>

      <div class="d-flex flex-column gap-4">
        <div class="d-flex flex-column w-100">
          <DxNumberBox
            :label="$t('lcsx')"
            width="125"
            :value.sync="caracteristicaLocal.lcsx"
            :showSpinButtons="true"
          ></DxNumberBox>
        </div>
        <div class="d-flex flex-column w-100">
          <DxNumberBox
            :label="$t('lcx')"
            width="125"
            :value.sync="caracteristicaLocal.lcx"
            :showSpinButtons="true"
          ></DxNumberBox>
        </div>
        <div class="d-flex flex-column w-100">
          <DxNumberBox
            :label="$t('lcix')"
            width="125"
            :value.sync="caracteristicaLocal.lcix"
            :showSpinButtons="true"
          ></DxNumberBox>
        </div>
      </div>

      <div class="d-flex flex-column gap-4">
        <div class="d-flex flex-column w-100">
          <DxNumberBox
            :label="$t('lcr')"
            width="125"
            :value.sync="caracteristicaLocal.lcr"
            :showSpinButtons="true"
          ></DxNumberBox>
        </div>
        <div class="d-flex flex-column w-100">
          <DxNumberBox
            :label="$t('lcsr')"
            width="125"
            :value.sync="caracteristicaLocal.lcsr"
            :showSpinButtons="true"
          ></DxNumberBox>
        </div>
        <div class="d-flex flex-column w-100">
          <DxNumberBox
            width="125"
            :label="$t('lcir')"
            :value.sync="caracteristicaLocal.lcir"
            :showSpinButtons="true"
          ></DxNumberBox>
        </div>
      </div>

      <div class="d-flex flex-column gap-4">
        <div class="d-flex flex-column w-100">
          <DxNumberBox
            :label="$t('cp')"
            width="125"
            :value.sync="caracteristicaLocal.cp"
            :showSpinButtons="true"
          ></DxNumberBox>
        </div>
        <div class="d-flex flex-column w-100">
          <DxNumberBox
            :label="$t('cpl')"
            width="125"
            :value.sync="caracteristicaLocal.cpk"
            :showSpinButtons="true"
          ></DxNumberBox>
        </div>
      </div>
    </div>

    <div v-if="false" class="d-flex justify-content-center align-items-end gap-5 mt-5">
      <div class="d-flex flex-column">
        <DxNumberBox
          :label="$t('numeroMuestras')"
          width="175"
          :value="numeroMuestras"
          :showSpinButtons="true"
        ></DxNumberBox>
      </div>
      <DxButton text="$t('calcular')" @click="calcularLimitesControl"></DxButton>
    </div>
  </div>
</template>

<script>
import { DxNumberBox } from "devextreme-vue/ui/number-box";
import { DxDateBox } from "devextreme-vue/ui/date-box";
import { DxButton } from "devextreme-vue/ui/button";

export default {
  components: {
    DxNumberBox,
    DxDateBox,
    DxButton,
  },
  props: ["caracteristica"],
  data() {
    return {
      numeroMuestras: 0,
    };
  },
  computed: {
    caracteristicaLocal: {
      get() {
        return this.caracteristica;
      },
      set(value, oldValue) {
        console.log("Updating caracteristica:", value, oldValue);
        this.$emit("update:caracteristica", value);
      },
    },
  },
  methods: {
    calcularLimitesControl() {
      return true;
    },
  },
};
</script>
