<template>
  <div :key="componentKey">
    <h2 class="content-block">
      <DxButton icon="arrowleft" type="back" styling-mode="text" @click="$router.push('/planes')" />
      {{ id }}: {{ plan.referencia }}/{{ plan.nombre }}
      <DxButton icon="textdocument" type="back" styling-mode="text" @click="onClickReport" />
    </h2>

    <!-- :plancontrol.sync="plan" -->
    <PlanControlEditor
      :key="plan.id"
      :id="plan.id"
      :plancontrol.sync="plan"
      class="content-block"
      ref="plancontrol"
    ></PlanControlEditor>

    <div class="content-block drag-boundary-caracteristica">
      <h3 ref="tituloDiagrama">{{ $t("diagramaDelProceso") }}</h3>
      <div class="d-flex gap-2">
        <DxResizable
          handles="right"
          :element-attr="{
            class: 'dx-card responsive-paddings resizable-tree',
          }"
          @resizeEnd="onResizableResizeEnd"
        >
          <template>
            <div id="treeViewPlanContainer">
              <div @contextmenu.prevent="onRootContextMenu" class="treeRoot dx-treeview-item">
                {{ plan.nombre }}
              </div>
              <DxSortable
                group="estructura"
                filter=".dx-treeview-item"
                :data="estructuraRaw"
                :allow-drop-inside-item="true"
                :allow-reordering="false"
                @drag-change="onSortableDragChange"
                @drag-end="onSortableDragEnd"
                @drag-start="onSortableDragStart"
                @add="onSortableAdd"
              >
                <DxTreeView
                  :expandAllEnabled="true"
                  id="treeViewPlan"
                  ref="treeViewPlan"
                  data-structure="plain"
                  :expand-nodes-recursive="true"
                  :dataSource="estructuraRaw"
                  display-expr="descripcion"
                  parent-id-expr="parent_pcestructura"
                  expanded-expr="expanded"
                  @contentReady="onTreeViewPlanContentReady"
                  @itemContextMenu="onTreeViewPlanItemContextMenu"
                  @itemHold="onTreeViewPlanItemContextMenu"
                  @itemClick="onTreeViewItemClick"
                  @itemSelectionChanged="onTreeViewItemSelectionChanged"
                  selectionMode="single"
                  :selectByClick="true"
                  showCheckBoxesMode="none"
                  noDataText=""
                >
                  <template #item="{ data, index }">
                    <div class="align-middle">
                      <img class="px-2" style="height: 16px" :src="require('@/assets/tipos/' + data.tipo + '.png')" />
                      <span class="align-middle">{{ data.descripcion }}</span>

                      <span>
                        <span>
                          <sub
                            ><sub>
                              <b>{{ data.secuencia }}</b>
                            </sub>
                          </sub>

                          <span class="text-secondary text-opacity-25 px-1">
                            <span :class="{ 'text-danger': data.fotos.length }">
                              <i class="ion ion-md-camera"></i>
                              <sub>
                                <sub>
                                  {{ data.fotos.length }}
                                </sub>
                              </sub>
                            </span>
                            <span :class="{ 'text-danger': data.documentos.length }">
                              <i class="ion ion-md-attach"></i>
                              <sub>
                                <sub>
                                  {{ data.documentos.length }}
                                </sub>
                              </sub>
                            </span>
                          </span>
                        </span>

                        <span class="d-inline-flex flex-row text-secondary px-3">
                          <i
                            class="ion ion-md-arrow-dropup px-1"
                            style=""
                            @click="onClickSecuenciaUp($event, data, index)"
                          ></i>

                          <i class="ion ion-md-arrow-dropdown" @click="onClickSecuenciaDown($event, data, index)"></i>
                        </span>
                      </span>
                    </div>
                  </template>
                </DxTreeView>
              </DxSortable>

              <DxContextMenu
                ref="treeViewPlanContextMenu"
                :items="menuItems"
                target="#treeViewPlanContainer .dx-treeview-item"
                @item-click="contextMenuItemClick"
              />
            </div>
          </template>
        </DxResizable>

        <ConfirmDeleteButton
          ref="confirmDeleteButtonFase"
          :text="$t('eliminarFase')"
          :text-to-confirm="selectedTreeViewPlan?.itemData?.descripcion"
          @confirmed="deleteNodoEstructura(selectedTreeViewPlan?.itemData?.id)"
          :is-button-visible="false"
        >
          <p>{{ $t("Al borrar una fase se borrarán todas las características asociadas") }}</p>
        </ConfirmDeleteButton>

        <div class="dx-card responsive-paddings w-100">
          <transition name="slide-fade" mode="out-in">
            <Estructura
              group="estructura"
              v-if="selectedEstructura"
              ref="estructura"
              :key="selectedEstructura.id"
              :idPlan="id"
              :estructura.sync="selectedEstructura"
              @update:estructura="onUpdateEstructura"
            >
            </Estructura>

            <div v-else class="d-flex justify-content-center align-items-center h-100 w-100 text-muted text-small">
              {{ $t("noHayElementoSeleccionado") }}
            </div>

            <!-- 
            Se ha modificado Estructura para que tenga heights predefinidas para el boton de edit 
            y la galería de fotos.  De este modo la transición de un componente a otro no es tan brusca.
            Si se quisieran ver las fotos durante la transición (y ver como desaparecen) se necesita 
            utilizar el built-in component keep-alive que mantiene el componente en cache (y no lo destruye)
            pudiendo mantener los componentes "vivos" durante la transición.
          -->

            <!-- <keep-alive :max="2" key="item" v-if="selectedEstructura">
            <Estructura
              v-if="selectedEstructura"
              ref="estructura"
              :key="selectedEstructura.id"
              :idPlan="id"
              :estructura.sync="selectedEstructura"
              @update:estructura="onUpdateEstructura"
            ></Estructura>
          </keep-alive>
          <div v-else class="py-5">No hay ningun elemento seleccionado</div> -->
          </transition>
        </div>
      </div>

      <div class="dx-card responsive-paddings position-relative mt-2">
        <DxDiagram
          id="diagram"
          :read-only="false"
          :simpleView="true"
          custom-shape-template="CustomShapeTemplate"
          autoZoomMode="fitWidth"
          @itemDblClick="onItemDblClick"
          width="100%"
        >
          <DxCustomShape
            type="customShape"
            :base-type="'rectangle'"
            :default-width="1.5"
            :default-height="1"
            :allow-edit-text="false"
            :allow-resize="false"
          />

          <DxEditing
            :allow-add-shape="true"
            :allow-change-connection="true"
            :allow-change-connector-points="true"
            :allow-change-connector-text="true"
            :allow-change-shape-text="true"
            :allow-delete-connector="true"
            :allow-delete-shape="true"
            :allow-move-shape="true"
            :allow-resize-shape="true"
          >
          </DxEditing>

          <template #CustomShapeTemplate="{ data }">
            <svg class="template">
              <image
                width="24"
                height="24"
                x="10px"
                y="10px"
                :xlink:href="require('@/assets/tipos/' + data.dataItem.itemData.tipo + '.png')"
              />
            </svg>
          </template>

          <DxNodes
            :data-source="estructuraTree"
            :key-expr="'key'"
            :text-expr="'text'"
            :typeExpr="itemTypeExpr"
            :items-expr="'children'"
            :container-children-expr="'none'"
          >
            <DxAutoLayout :type="'tree'" />
          </DxNodes>

          <!-- No será visible si el diagrama está en readOnly -->
          <DxPropertiesPanel :visibility="'collapsed'"> </DxPropertiesPanel>

          <DxViewToolbar> </DxViewToolbar>

          <DxToolbox :visibility="'disabled'">
            <!-- <DxGroup :category="'general'" :title="'General'" /> -->
          </DxToolbox>

          <DxHistoryToolbar :visible="false"></DxHistoryToolbar>
        </DxDiagram>
      </div>
    </div>

    <Revisiones class="content-block" :idPlan="id" @updated="onRevisionesUpdated"></Revisiones>

    <Equipos class="content-block" :idPlan="id"></Equipos>

    <NotasAlControlador class="content-block" :idPlan="id"></NotasAlControlador>

    <ConjuntosImplicadosBlock class="content-block" :idPlan="id" />

    <Planos class="content-block" :idPlan="id"></Planos>

    <PlantasConsumidoras class="content-block" :idPlan="id" />

    <ProveedoresAfectadosBlock class="content-block" :idPlan="id" />

    <AttachedImageViewerBlock class="content-block" :title="$t('fotos')" :resourceURI="`planes/${id}`" />

    <AttachedDocumentViewerBlock class="content-block" :title="$t('documentos')" :resourceURI="`planes/${id}`" />

    <div class="content-block danger-zone">
      <h3 class="text-danger"><i class="dx-icon dx-icon-warning"></i> {{ $t("zonaDePeligro") }}</h3>
      <div class="dx-card responsive-paddings alert alert-danger rounded">
        <ConfirmDeleteButton :text="$t('eliminarPlan')" :text-to-confirm="plan.nombre" @confirmed="deletePlan">
          <p>
            {{ $t("al borrar el plan de control se eliminarán toda la información relacionada") }}
          </p>
          <ul>
            <li>{{ $t("estructura") }}</li>
            <li>{{ $t("características") }}</li>
            <li>{{ $t("revisiones") }}</li>
            <li>{{ $t("equipos") }}</li>
            <li>{{ $t("notasAlControlador") }}</li>
            <li>{{ $t("conjuntosImplicados") }}</li>
            <li>{{ $t("proveedoresAfectados") }}</li>
            <li>{{ $t("planos") }}</li>
            <li>{{ $t("plantasConsumidoras") }}</li>
            <li>{{ $t("fotos") }}</li>
            <li>{{ $t("documentos") }}</li>
          </ul>
        </ConfirmDeleteButton>
      </div>
    </div>

    <DxPopup
      v-if="attachedID"
      :title="$t('ficherosAdjuntos')"
      :visible.sync="isPopupAttachedVisible"
      :fullScreen="false"
      @shown="onShownAttachedPopup"
      :animation="{
        show: {
          type: 'pop',
        },
        hide: {
          type: 'pop',
          from: { scale: 1, opacity: 1 },
          to: { scale: 0, opacidy: 0 },
        },
      }"
    >
      <template>
        <div>
          <AttachedViewer
            ref="attachedViewerGeneral"
            v-if="attachedID"
            :key="attachedID"
            :resourceURI="attachedResourceURI"
            :resources.sync="attachedResource.fotos"
          ></AttachedViewer>
        </div>
      </template>
    </DxPopup>

    <Prompt ref="prompt"></Prompt>
  </div>
</template>

<script>
import "devexpress-diagram/dist/dx-diagram.min.css";

import {
  DxDiagram,
  DxNodes,
  DxAutoLayout,
  DxCustomShape,
  DxPropertiesPanel,
  DxEditing,
  DxViewToolbar,
  DxToolbox,
  DxHistoryToolbar,
} from "devextreme-vue/diagram";

import DxTreeView from "devextreme-vue/tree-view";
import DxSortable from "devextreme-vue/sortable";

import { DxContextMenu } from "devextreme-vue/context-menu";

import { DxButton } from "devextreme-vue/button";

import DxPopup from "devextreme-vue/popup";
import DxResizable from "devextreme-vue/resizable";

import Prompt from "@/components/prompt";
import PlanControlEditor from "@/components/plancontrol/plan-control-editor.vue";
import Estructura from "@/components/plancontrol/estructura.vue";
import Equipos from "@/components/plancontrol/equipos.vue";
import Revisiones from "@/components/plancontrol/revisiones.vue";
import Planos from "@/components/plancontrol/planos.vue";
import PlantasConsumidoras from "@/components/plancontrol/plantas-consumidoras.vue";
import NotasAlControlador from "@/components/plancontrol/notas-al-controlador.vue";

import ConjuntosImplicadosBlock from "@/components/plancontrol/conjuntos-implicados-block.vue";
import ProveedoresAfectadosBlock from "@/components/plancontrol/proveedores-afectados-block.vue";

import AttachedImageViewerBlock from "@/components/attached-image-viewer-block.vue";

import AttachedDocumentViewerBlock from "@/components/attached-document-viewer-block.vue";
import ConfirmDeleteButton from "@/components/core/ConfirmDeleteButton.vue";

import { alert, confirm } from "devextreme/ui/dialog";

import CustomStore from "devextreme/data/custom_store";

import tipos from "@/data/TipoEstructura";

import { PlanesControlDataSource } from "../data/plancontrol/PlanesControlDataSource.js";
import { CustomStoreCaracteristicas } from "../data/caracteristicas/CaracteristicasDataSource.js";

export default {
  components: {
    DxDiagram,
    DxNodes,
    DxAutoLayout,
    DxCustomShape,
    DxPropertiesPanel,
    DxEditing,
    DxViewToolbar,
    DxToolbox,
    DxHistoryToolbar,
    DxButton,
    DxSortable,
    DxTreeView,
    DxContextMenu,
    AttachedImageViewerBlock,
    AttachedDocumentViewerBlock,
    DxPopup,
    DxResizable,
    PlanControlEditor,
    Estructura,
    Prompt,
    Equipos,
    Revisiones,
    Planos,
    PlantasConsumidoras,
    NotasAlControlador,
    ConfirmDeleteButton,
    ConjuntosImplicadosBlock,
    ProveedoresAfectadosBlock,
  },

  data() {
    return {
      componentKey: 0,
      id: new Number(this.$route.params.id),
      plan: {},
      planesControlDataSource: new PlanesControlDataSource(),
      customStoreCaracteristicas: new CustomStoreCaracteristicas({ idPlan: this.id }),
      estructuraRaw: {},
      estructuraTree: {},
      estructura: new CustomStore({
        key: "id",
        load: () => this.$fetch.get(`${global.API_URL}/planes/${this.id}/estructura`),

        onLoaded: (result) => {
          this.estructuraRaw = result;
          this.estructuraRaw = this.estructuraRaw.sort((a, b) => a.secuencia - b.secuencia);

          this.$nextTick(() => {
            this.$refs.treeViewPlan.instance.expandAll();
          });

          return this.estructuraRaw;
        },

        byKey: (key) => this.$fetch.get(`${global.API_URL}/planes/${this.id}/estructura/${key}`),
        insert: (values) => this.$fetch.post(`${global.API_URL}/planes/${this.id}/estructura`, values),
        update: (key, values) => this.$fetch.put(`${global.API_URL}/planes/${this.id}/estructura/${key}`, values),
        remove: (key) => this.$fetch.delete(`${global.API_URL}/planes/${this.id}/estructura/${key}`),
      }),

      selectedEstructura: undefined,
      selectedTreeViewPlan: null,

      isPopupAttachedVisible: false,
      attachedID: undefined,
      attachedResourceURI: undefined,
      attachedResource: undefined,
    };
  },

  methods: {
    async onClickReport() {
      // this.$fetch.get(`${global.API_URL}/planes/${this.id}/report`).then((response) => {
      //   console.log(response);
      // });

      // location.href = `${global.API_URL}/planes/${this.id}/report`;

      // window.open(`${global.API_URL}/planes/${this.id}/report`, "_blank");
      // ^--no puedo utilizarlo ya que quiero que la peticion get sea con el token de autenticacion

      const { originalName, blob } = await this.$fetch.download(
        `${global.API_URL}/planes/${this.id}/report?culture=${this.$i18n.locale}`
      );
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = originalName ?? "download";
      link.click();
    },

    onRevisionesUpdated() {
      this.$refs.plancontrol.refresh();
    },

    onShownAttachedPopup() {
      this.$refs.attachedViewerGeneral.repaint();
    },

    log(...e) {
      console.log(e);
    },

    itemTypeExpr() {
      return "customShape";
    },

    onItemDblClick(e) {
      this.$refs.tituloDiagrama.scrollIntoView({ behavior: "smooth" });
      this.$refs.treeViewPlan.instance.expandItem(e.item.dataItem.key);
      this.$refs.treeViewPlan.instance.selectItem(e.item.dataItem.key);
    },

    onDragChange(e) {
      console.log("onDragChange", e);

      let visibleRows = e.component.getVisibleRows(),
        sourceNode = e.component.getNodeByKey(e.itemData.id),
        targetNode = visibleRows[e.toIndex].node;

      console.log(sourceNode.data.tipo, "to", targetNode.data.tipo);

      if (e.dropInsideItem === true && targetNode.data.tipo >= 30) return (e.cancel = true);
    },

    onReorder(e) {
      console.log("onReorder", e);
    },

    findNodeById(nodes, id) {
      for (var i = 0; i < nodes.length; i++) {
        if (nodes[i].itemData.id == id) {
          return nodes[i];
        }
        if (nodes[i].children) {
          const node = this.findNodeById(nodes[i].children, id);
          if (node != null) {
            return node;
          }
        }
      }
      return null;
    },

    findNode(treeView, index) {
      const nodeElement = treeView.element().querySelectorAll(".dx-treeview-node")[index];
      if (nodeElement) {
        return this.findNodeById(treeView.getNodes(), nodeElement.getAttribute("data-item-id"));
      }
      return null;
    },

    isNivel1(node) {
      return node.itemData.tipo.toString()[0] == 1;
    },
    isNivel2(node) {
      return node.itemData.tipo.toString()[0] == 2;
    },
    isNivel3(node) {
      return node.itemData.tipo.toString()[0] == 3;
    },

    calculateToIndex(e) {
      return e.toIndex;
    },

    movingCaracteristica(e) {
      // console.log("movingCaracteristica", e);

      const treeView = this.$refs["treeViewPlan"].instance;
      // const fromNode = this.findNode(treeView, e.fromIndex);
      const toNode = this.findNode(treeView, this.calculateToIndex(e));

      if (e.dropInsideItem === false) {
        return (e.cancel = true);
      }

      if (this.isNivel1(toNode) || this.isNivel2(toNode)) {
        return (e.cancel = true);
      }
    },

    movingEstructura(e) {
      const treeView = this.$refs["treeViewPlan"].instance;
      const fromNode = this.findNode(treeView, e.fromIndex);
      //const toNode = this.findNode(treeView, e.toIndex);
      const toNode = this.findNode(treeView, this.calculateToIndex(e));

      if (this.isNivel1(fromNode) && this.isNivel2(toNode)) {
        return (e.cancel = true);
      }
      if (this.isNivel1(fromNode) && this.isNivel3(toNode)) {
        return (e.cancel = true);
      }

      if (this.isNivel2(fromNode) && this.isNivel3(toNode)) {
        return (e.cancel = true);
      }

      if (this.isNivel3(fromNode) && this.isNivel1(toNode)) {
        return (e.cancel = true);
      }
    },

    onSortableAdd(e) {
      const treeView = this.$refs["treeViewPlan"].instance;
      const toNode = this.findNode(treeView, this.calculateToIndex(e));

      console.log("añadiendo", e.itemData.id, "a", toNode);

      this.customStoreCaracteristicas.update(e.itemData.id, {
        id_pcestructura: toNode.key,
      });
    },

    onSortableDragChange(e) {
      // console.log("onSortableDragChange", e);

      // habilita el drop dentro del nodo de un dxDataGrid de caracteristicas (acoplado por ahora pero no sé cómo hacerlo mejor)
      if (e.fromComponent.NAME === "dxDataGrid") {
        return this.movingCaracteristica(e);
      }

      // habilita el drop dentro del nodo de un dxTreeView de si mismo
      if (e.fromComponent.NAME === "dxSortable") {
        return this.movingEstructura(e);
      }
    },

    onSortableDragEnd(e) {
      console.log("onSortableDragEnd", e);

      const treeView = this.$refs["treeViewPlan"].instance;
      const fromNode = this.findNode(treeView, e.fromIndex);
      //const toNode = this.findNode(treeView, e.toIndex);
      const toNode = this.findNode(treeView, this.calculateToIndex(e));

      if (this.isNivel1(fromNode) && this.isNivel1(toNode)) {
        // ordenar
        console.log(`el nodo ${toNode.text} va a ser el HERMANO del nodo ${fromNode.text}`);
        // estructuraRaw.find(e => e.id === toNode.key)
      }
      if (this.isNivel1(fromNode) && this.isNivel2(toNode)) {
        // movimiento invalido
        console.log("movimiento invalido", "nivel1 a nivel 2 error");
      }
      if (this.isNivel1(fromNode) && this.isNivel3(toNode)) {
        // movimiento invalido
        console.log("movimiento invalido", "nivel1 a nivel 3 error");
      }

      if (this.isNivel2(fromNode) && this.isNivel1(toNode)) {
        // el nodo destino va a ser el padre del nodo origen
        console.log(`el nodo ${toNode.text} va a ser el PADRE del nodo ${fromNode.text}`);
        const toRow = this.estructuraRaw.find((e) => e.id === toNode.key);
        const fromRow = this.estructuraRaw.find((e) => e.id === fromNode.key);

        const lastChild = toNode.children[toNode.children.length - 1];
        fromRow.secuencia = lastChild ? lastChild.itemData.secuencia + 1 : 1;

        fromRow.parent_pcestructura = toRow.id;

        //reasigna estructuraRaW con un sort ordeno el resultado y lo hago reactivo.
        this.estructuraRaw = this.estructuraRaw.sort((a, b) => a.secuencia - b.secuencia);

        //Se hace una actualizacion optimista (considero que en el servidor todo ha ido correcto.)

        this.estructura.update(fromRow.id, {
          secuencia: fromRow.secuencia,
          parent_pcestructura: fromRow.parent_pcestructura,
        });
      }
      if (this.isNivel2(fromNode) && this.isNivel2(toNode)) {
        // el nodo destino va a ser hermano del nodo origen:
        // el padre del nodo destino va a ser el padre del nodo origen y la secuencia va a ser el del nodo destino +1.
        console.log(`el nodo ${toNode.text} va a ser el HERMANO del nodo ${fromNode.text}`);

        // el resto?
      }
      if (this.isNivel2(fromNode) && this.isNivel3(toNode)) {
        // movimiento invalido
        console.log("movimiento invalido", "nivel2 a nivel 3 error");
      }

      if (this.isNivel3(fromNode) && this.isNivel1(toNode)) {
        // movimiento invalido:
        console.log("movimiento invalido", "nivel1 a nivel 3 error");
      }
      if (this.isNivel3(fromNode) && this.isNivel2(toNode)) {
        // el nodo destino va a ser el padre del nodo origen
        console.log(`el nodo ${toNode.text} va a ser el PADRE del nodo ${fromNode.text}`);

        const toRow = this.estructuraRaw.find((e) => e.id === toNode.key);
        const fromRow = this.estructuraRaw.find((e) => e.id === fromNode.key);

        const lastChild = toNode.children[toNode.children.length - 1];
        fromRow.secuencia = lastChild ? lastChild.itemData.secuencia + 1 : 1;

        fromRow.parent_pcestructura = toRow.id;

        //this.estructuraRaw = [].concat(this.estructuraRaw); //reasigna estructuraRaW para generar reactividad?
        // this.estructuraRaw.sort();

        this.estructuraRaw = this.estructuraRaw.sort((a, b) => a.secuencia - b.secuencia);

        this.estructura.update(fromRow.id, {
          secuencia: fromRow.secuencia,
          parent_pcestructura: fromRow.parent_pcestructura,
        });
      }
      if (this.isNivel3(fromNode) && this.isNivel3(toNode)) {
        // ordenar
        // el nodo destino va a ser el hermano del nodo origen
        console.log(`el nodo ${toNode.text} va a ser el HERMANO del nodo ${fromNode.text}`);
      }

      // treeView.repaint();
    },

    // onSortableReorder(e) {
    //   console.log("onSortableReorder", e);
    // },

    onSortableDragStart(e) {
      console.log("onSortableDragStart", e);
    },

    async load() {
      try {
        this.plan = await this.planesControlDataSource.store().byKey(this.id);
      } catch (error) {
        console.log(error);
        this.$notify("Error al cargar el plan de control", "error", 5000);
        this.$router.push({ name: "planes" });
      }
    },

    onContentReady(e) {
      console.log("oncontentReady", e);
    },

    onClickSecuenciaUp(e, data) {
      e.cancelBubble = true;

      const tree = this.$refs["treeViewPlan"].instance;
      const node = this.findNodeById(tree.getNodes(), data.id);

      let previousSibling;
      if (node.parent) {
        const i = node.parent.children.findIndex((e) => node.key === e.key);
        previousSibling = node.parent.children[i - 1];
      } else {
        const i = tree.getNodes().findIndex((e) => node.key === e.key);
        previousSibling = tree.getNodes()[i - 1];
      }

      if (!previousSibling) return;

      //swap secuencias:
      //console.log(node, previousSibling);
      const aux = node.itemData.secuencia;
      node.itemData.secuencia = previousSibling.itemData.secuencia;
      previousSibling.itemData.secuencia = aux;

      //data.secuencia++;
      this.estructuraRaw.sort((a, b) => a.secuencia - b.secuencia);

      this.estructura.update(node.key, { secuencia: node.itemData.secuencia });
      this.estructura.update(previousSibling.key, {
        secuencia: previousSibling.itemData.secuencia,
      });

      //repintar el diagrama:
      // this.$refs.diagram.instance.repaint();
    },

    onClickSecuenciaDown(e, data) {
      e.cancelBubble = true;
      //console.log(e, data);

      const tree = this.$refs["treeViewPlan"].instance;
      const node = this.findNodeById(tree.getNodes(), data.id);

      let nextSibling;
      if (node.parent) {
        const i = node.parent.children.findIndex((e) => node.key === e.key);
        nextSibling = node.parent.children[i + 1];
      } else {
        const i = tree.getNodes().findIndex((e) => node.key === e.key);
        nextSibling = tree.getNodes()[i + 1];
      }

      // swap secuencias:
      // console.log(node, nextSibling);

      if (!nextSibling) return;

      const aux = node.itemData.secuencia;
      node.itemData.secuencia = nextSibling.itemData.secuencia;
      nextSibling.itemData.secuencia = aux;

      this.estructura.update(node.key, { secuencia: node.itemData.secuencia });
      this.estructura.update(nextSibling.key, {
        secuencia: nextSibling.itemData.secuencia,
      });

      //data.secuencia--;
      this.estructuraRaw.sort((a, b) => a.secuencia - b.secuencia);

      //repintar el diagrama:
      // this.$refs.diagram.instance.repaint();
    },

    onTreeViewPlanContentReady(e) {
      this.estructuraTree = [...e.component.getNodes()]; //asigno por deep copy para no alterar la estructura del arbol...
      this.removeparents(this.estructuraTree);
    },

    // utility para borrar los parents en la estructura arborescente del treeview para evitar ciclos json.
    removeparents(arr) {
      arr.forEach((e) => {
        delete e.parent;
        this.removeparents(e.children);
      });
    },

    onRootContextMenu() {
      this.selectedTreeViewPlan = null;
      const contextMenu = this.$refs.treeViewPlanContextMenu.instance;

      const menuItemsObject = this.menuItems.reduce((a, x) => ({ ...a, [x.id]: x }), {});

      menuItemsObject.add1.visible = true;
      menuItemsObject.add2.visible = false;
      menuItemsObject.add3.visible = false;
      menuItemsObject.rename.visible = false;
      menuItemsObject.del.visible = false;

      contextMenu.option("items", Object.values(menuItemsObject));
      contextMenu.show();
    },

    onTreeViewPlanItemContextMenu(e) {
      this.selectedTreeViewPlan = e;

      const contextMenu = this.$refs.treeViewPlanContextMenu.instance;
      const nivel = tipos.find((f) => f.id === e.itemData.tipo).nivel;

      //Convertimos el array de menuItems en un objeto para optimizar el acceso.
      const menuItemsObject = this.menuItems.reduce((a, x) => ({ ...a, [x.id]: x }), {});

      menuItemsObject.add1.visible = false;
      menuItemsObject.add2.visible = nivel === 1;
      menuItemsObject.add3.visible = nivel === 2;
      menuItemsObject.rename.visible = true;

      menuItemsObject.del.visible = true;

      contextMenu.option("items", Object.values(menuItemsObject));
    },

    onTreeViewItemClick(e) {
      console.log("onTreeViewItemClick", e.itemData.id);
    },

    onTreeViewItemSelectionChanged(e) {
      this.selectedEstructura = e.node.selected ? e.node.itemData : undefined;
    },

    async contextMenuItemClick(e) {
      if (e.itemData?.items) return e.cancel;

      let children = [];
      let parent_pcestructura = null;
      let descripcion = "";
      let lastChild;
      let secuencia;

      let inserted;

      switch (e.itemData.id) {
        case "rename":
          try {
            //descripcion = Prompt.prompt("Test", "Descripción:" , function(){
            //contenido del callback
            //});
            descripcion = await this.$refs.prompt.prompt(
              this.$t("cambiarLaDescripcion"),
              this.$t("renombrarLaDescripcion"),
              this.selectedTreeViewPlan.itemData.descripcion
            );
          } catch (ex) {
            //se ha cancelado la descripción!
            console.log("se ha cancelado la descripción", ex);
            return;
          }

          await this.estructura.update(this.selectedTreeViewPlan.itemData.id, {
            descripcion: descripcion,
          });

          //await this.estructura.load();
          // Evitamos hacer el load porque el arbol se repinta.
          // y los nodos expandidos se contraen.
          // podemos trabajar con el array de estructuraRaw
          // y evitar el load del arbol.
          this.estructuraRaw.find((x) => x.id === this.selectedTreeViewPlan.itemData.id).descripcion = descripcion;

          this.estructuraRaw = [...this.estructuraRaw];

          break;
        case "add1":
        case "add2":
        case "add3":
          if (this.selectedTreeViewPlan) {
            children = this.selectedTreeViewPlan.node.children;
            parent_pcestructura = this.selectedTreeViewPlan.itemData.id;
          } else {
            children = this.$refs.treeViewPlan.instance.getNodes();
          }

          lastChild = children[children.length - 1];
          secuencia = lastChild ? lastChild.itemData.secuencia + 1 : 1;

          try {
            //descripcion = await Prompt.prompt("Test", "Descripción:");
            descripcion = await this.$refs.prompt.prompt("Titulo", this.$t("nuevoNodo"));
          } catch (ex) {
            //se ha cancelado la descripción!
            console.log("se ha cancelado la descripción", ex);
            return;
          }

          // alert("añadir nivel 1");
          inserted = await this.estructura.insert({
            parent_pcestructura: parent_pcestructura,
            tipo: e.itemData.tipo,
            secuencia: secuencia,
            descripcion: descripcion,
          });

          // Evitamos hacer el load porque el arbol se repinta.
          // y los nodos expandidos se contraen.
          // podemos trabajar con el array de estructuraRaw
          // y evitar el load del arbol.

          inserted.fotos = [];
          inserted.documentos = [];
          inserted.selected = true;
          inserted.expanded = true;

          // await this.estructura.load();
          this.estructuraRaw.push(inserted);

          this.selectedEstructura = inserted;

          break;

        case "del":
          // alert("borrar nodo");
          if (this.selectedTreeViewPlan.node.children.length > 0)
            return alert("El elemento que se intenta borrar tiene hijos, no se puede borrar.", "Imposible borrar");

          if (
            !(await confirm(
              `${this.$t("estasSeguroQueQuieresBorrar")} <b>${this.selectedTreeViewPlan.itemData.descripcion}</b>?`,
              this.$t("borrarElemento")
            ))
          )
            return;

          if (this.selectedTreeViewPlan.itemData.tipo < 30)
            return this.deleteNodoEstructura(this.selectedTreeViewPlan.itemData.id);

          // si es fase (tipo > 30) entonces pedimos super-confirmación
          await this.$refs.confirmDeleteButtonFase.onClickDangerButton();

          break;
      }
    },

    onUpdateEstructura(e) {
      // this.log("plan.vue::onUpdateEstructura!");
      /**
       * COMMENT_ID: adsDFvflHkjhNfS408O9uDaUYcOIvfsd
       *
       * Parece ser que tengo que modificar el estructuraRaw porque el árbol no es capaz
       * de actualizarse con el sync del estructura seleccionado.
       * Esto se debe a que estructura mantiene una copia de la estructura (estructura y estructuraEditable).
       * para habilitar el cancelar.
       * al emitir el cambio de la propiedad estructura (this.$emit("update:estructura" , estructuraEditable))
       * el árbol no quiere detectar el cambio y no actualiza el nodo ( pero en el modelo el selectedEstructura
       * si que se ha modificado ¯\_(ツ)_/¯ ).
       */

      // this.log(this.estructuraRaw);
      let x = this.estructuraRaw.findIndex((el) => el.id === this.selectedEstructura.id);

      this.estructuraRaw[x] = e;
      this.estructuraRaw = [].concat(this.estructuraRaw);
    },

    onResizableResizeEnd() {
      // console.log("onResizableEnd");
      this.$refs.estructura?.repaint();
    },

    onEditorPreparing(e) {
      console.log("onEditorPreparing", e);
    },

    onInitNewRow(e) {
      console.log("onInitNewRow", e);

      // e.data.familia = false;
      // e.data.situacion = 0;
      // e.data.fecha_emision = new Date();
    },
    onEditingStart(e) {
      console.log("onEditingStart", e);

      // e.data.familia = false;
      // e.data.situacion = 0;
      // e.data.fecha_emision = new Date();
    },
    async deletePlan() {
      try {
        const result = await this.planesControlDataSource.store().remove(this.id);
        console.log(result);
        this.$router.push({ name: "planes" });
        this.$notify(this.$t("borrado_correctamente"), "success", 5000);
      } catch (e) {
        console.error(e.message);
        this.$notify(`${this.$t("No se ha podido eliminar")}: ${this.$t(e.message)}`, "error", 5000);
      }
    },

    async deleteNodoEstructura(id) {
      try {
        await this.estructura.remove(id);

        // await this.estructura.load();
        // Evitamos hacer el load porque el arbol se repinta.
        // y los nodos expandidos se contraen.
        // podemos trabajar con el array de estructuraRaw
        // y evitar el load del arbol.

        const index = this.estructuraRaw.findIndex((x) => x.id === id);
        this.estructuraRaw.splice(index, 1);
        this.selectedTreeViewPlan = undefined;
        this.selectedEstructura = undefined;

        this.$notify(this.$t("borrado_correctamente"), "success", 5000);
      } catch (e) {
        console.error(e.message);
        this.$notify(`${this.$t("No se ha podido eliminar")}: ${this.$t(e.message)}`, "error", 5000);
      }
    },
  },

  async mounted() {
    // await this.responsablesDataSource.load();
    // await this.load();
    // console.log(this.responsablesDataSource.items());
  },

  async created() {
    // cambiado del mouted al created...
    // para tenerlo mucho antes... :|
    await this.load(); // VERIFICAR SI ESTO ES CORRECTO...
    this.estructura.load(); //necesito llamar al load de la estructura para cargar los datos.
  },

  computed: {
    idioma() {
      return this.$i18n.locale;
    },

    menuItems() {
      return [
        {
          id: "rename",
          icon: "rename",
          text: this.$t("renombrar"),
          // beginGroup: true,
        },

        {
          id: "add1",
          icon: "plus",
          text: this.$t("añadirNivel1"),
          items: tipos
            .filter((f) => f.id < 20)
            .map((e) => ({
              //id: "add1_" + e.id,
              id: "add1",
              icon: require("@/assets/tipos/" + e.id + ".png"),
              text: this.$t(e.value),
              tipo: e.id,
            })),
        },
        {
          id: "add2",
          icon: "plus",
          text: this.$t("añadirNivel2"),
          items: tipos
            .filter((f) => f.id > 20 && f.id < 30)
            .map((e) => ({
              //id: "add2_" + e.id,
              id: "add2",
              icon: require("@/assets/tipos/" + e.id + ".png"),
              text: this.$t(e.value),
              tipo: e.id,
            })),
        },
        {
          id: "add3",
          icon: "plus",
          text: this.$t("añadirNivel3"),
          items: tipos
            .filter((f) => f.id > 30)
            .map((e) => ({
              // id: "add3_" + e.id,
              id: "add3",
              icon: require("@/assets/tipos/" + e.id + ".png"),
              text: this.$t(e.value),
              tipo: e.id,
            })),
        },

        {
          id: "del",
          icon: "trash",
          text: this.$t("eliminar"),
          beginGroup: true,
        },
      ];
    },
  },
  watch: {
    idioma() {
      this.componentKey++;
    },
  },
};
</script>

<style lang="scss">
.counter {
  position: absolute;
  bottom: -5px;
  font-size: 0.8em;
  font-weight: bold;
  color: inherit;
  border-radius: 3px;
  padding: 1px 3px;
  line-height: 10px;
}

.dx-swatch-additional {
  .treeRoot {
    padding: 10px 5em 10px 10px;
  }
}

.treeRoot {
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  &::before {
    // content: url("/img/plancontrol.png");
    background-image: url("@/assets/plancontrol.png");
    background-size: 16px 16px;
    width: 16px;
    height: 16px;
    display: inline-block;
    content: "";
    vertical-align: bottom;
    margin-left: 3px;
    margin-right: 3px;
  }

  padding: 10px 5em 10px 10px;
  cursor: pointer;
  overflow: hidden;
}

.resizable-tree {
  flex: 0 0 auto;
  white-space: nowrap;
  padding-right: 2px !important;
}

//@TODO
// .dx-treeview-item-without-checkbox.dx-state-selected > .dx-treeview-item {
//   background-color: rgba(255, 87, 34, 0.12);
// }

// .dx-treeview-item-without-checkbox.dx-state-focused > .dx-treeview-item {
//   background-color: rgba(255, 87, 34, 0.04);
// }

// .dx-treeview-item.dx-state-hover {
//   background-color: rgba(255, 87, 34, 0.04);
// }

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.8s ease;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
  transition: all 0;
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
