<template>
  <div>
    <h2 class="content-block">{{ $t("perfil") }}</h2>

    <div class="content-block dx-card responsive-paddings">
      <Display :label="$t('nombre')">
        {{ profile.nombre }}
      </Display>
    </div>

    <div class="content-block dx-card responsive-paddings">
      <!--
        <DxTextBox v-model="formData.oldPassword" :label="$t('oldPassword')" />
      <DxTextBox v-model="formData.newPassword" :label="$t('newPassword')" />
      <DxTextBox v-model="formData.confirmPassword" :label="$t('confirmPassword')" />
      -->
      <form action="your-action" @submit="handleSubmit">
        <DxForm ref="form" id="form" labelMode="floating" :form-data="formData">
          <DxSimpleItem data-field="oldPassword" :label="$t('oldPassword')" :editorOptions="oldPasswordOptions">
            <DxRequiredRule :message="$t('campo_requerido')" />
          </DxSimpleItem>
          <DxSimpleItem data-field="newPassword" :label="$t('newPassword')" :editorOptions="newPasswordOptions">
            <DxRequiredRule :message="$t('campo_requerido')" />
          </DxSimpleItem>
          <DxSimpleItem
            data-field="confirmPassword"
            :label="$t('confirmPassword')"
            :editorOptions="confirmPasswordOptions"
          >
            <DxRequiredRule :message="$t('campo_requerido')" />
            <DxCompareRule
              :comparison-target="() => this.formData.newPassword"
              :message="$t('new_password_and_confirm_password_do_not_match')"
            />
          </DxSimpleItem>

          <DxButtonItem :text="$t('guardarCambios')" :button-options="buttonOptions" horizontal-alignment="right" />
        </DxForm>
      </form>
    </div>
  </div>
</template>

<script>
import { ProfileCustomStore, ProfileApi } from "../data/usuarios/ProfileDataSource";
import Display from "../components/core/Display.vue";
// import DxTextBox from "devextreme-vue/text-box";
import { DxForm, DxSimpleItem, DxButtonItem } from "devextreme-vue/form";

import { DxRequiredRule, DxCompareRule } from "devextreme-vue/validator";

import Validator from "devextreme/ui/validator";

export default {
  data() {
    return {
      profile: {
        name: "Juan",
      },
      profileCustomStore: new ProfileCustomStore(),
      profileApi: new ProfileApi(),

      formData: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },

      oldPasswordOptions: {
        mode: "password",
        validationError: "aaaaaaaaaaaaaaaaaaaaaaaaaaa",
        buttons: [
          {
            name: "password",
            location: "after",
            options: {
              icon: "fa fa-eye",
              type: "back",
              onClick: () => this.changePasswordMode("oldPassword"),
            },
          },
        ],
      },
      newPasswordOptions: {
        mode: "password",

        onValueChanged: () => {
          const editor = this.formInstance.getEditor("confirmPassword");
          if (editor.option("value")) {
            const instance = Validator.getInstance(editor.element());
            instance.validate();
          }
        },

        buttons: [
          {
            name: "password",
            location: "after",
            options: {
              icon: "fa fa-eye",
              type: "back",
              onClick: () => this.changePasswordMode("newPassword"),
            },
          },
        ],
      },
      confirmPasswordOptions: {
        mode: "password",

        buttons: [
          {
            name: "password",
            location: "after",
            options: {
              icon: "fa fa-eye",
              type: "back",
              onClick: () => this.changePasswordMode("confirmPassword"),
            },
          },
        ],
      },
    };
  },
  components: {
    Display,
    // DxTextBox,
    DxRequiredRule,
    DxCompareRule,
    DxForm,
    DxSimpleItem,
    DxButtonItem,
  },

  async mounted() {
    // console.log("mounted");
    // const profileCustomStore = new ProfileCustomStore();
    const result = await this.profileCustomStore.load();
    this.profile = result;
  },

  methods: {
    changePasswordMode(name) {
      const editor = this.formInstance.getEditor(name);
      editor.option("mode", editor.option("mode") === "text" ? "password" : "text");
    },

    async handleSubmit(e) {
      e.preventDefault();

      console.log("handleSubmit");

      try {
        // const result = await this.profileCustomStore.insert(this.formData);
        const result = await this.profileApi.post(this.formData);
        console.log(result);
      } catch (ex) {
        // console.log(ex); shows [object Object]
        // how to get the error message?
        // this.$notify(ex, "error", 3000);
        console.log("error en profile.vue!", ex);
        this.$notify(this.$t(ex.error), "error", 5000);

        if (ex.error === "incorrect_password") {
          const editor = this.formInstance.getEditor("oldPassword");
          // editor not valid AND message:
          editor.option("isValid", false);
          // editor.option("validationError", this.$t("incorrect_password"));
          editor.focus();
        }

        return;
      }

      this.formInstance.resetValues();
      this.$notify(this.$t("el password se ha actualizado correctamente"), "success", 3000);
    },
  },

  computed: {
    formInstance() {
      return this.$refs.form.instance;
    },

    buttonOptions() {
      return {
        text: this.$t("guardar"),
        type: "default",
        icon: "save",
        useSubmitBehavior: true,
      };
    },
  },
};
</script>

<style lang="scss">
.form-avatar {
  float: left;
  height: 120px;
  width: 120px;
  margin-right: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  overflow: hidden;

  img {
    height: 120px;
    display: block;
    margin: 0 auto;
  }
}
</style>
