import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { jwtDecode } from "jwt-decode";

import { CustomStoreModulos } from "@/data/modulosDataSource.js";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    debug: false,
    token: null,
    user: null,
    modulos: [],
  },
  mutations: {
    setDebug(state, value) {
      state.debug = value;
    },
    setToken(state, value) {
      state.token = value;
      state.user = value ? jwtDecode(value) : value;
    },
    setUser(state, value) {
      state.user = value;
    },
    setModulos(state, value) {
      state.modulos = value;
    },
  },
  getters: {
    user: (state) => state.user,
    token: (state) => state.token,
    isLoggedIn: (state) => !!state.token,
    modulos: (state) => state.modulos,
    hasModulo: (state) => (modulo) => state.modulos.includes(modulo),
  },
  actions: {
    async loadModulos({ commit }) {
      try {
        const modulos = await new CustomStoreModulos().load();
        commit("setModulos", modulos);
      } catch (ex) {
        console.error("loadModulos", ex);
      }
    },
  },

  plugins: [createPersistedState()],
});

export default store;
