<template>
  <div class="d-flex flex-column">
    <!-- <label class="form-label">{{ $t("proveedor") }}</label>
        <div class="form-data">
            {{ value.descripcion }}
        </div> -->
    <Display :label="$t('proveedor')" :required="false" :value="value.descripcion" />

    <span class="extra fst-italic ps-2 pt-1 text-secondary">{{ value.codigo }}</span>
    <span class="extra fst-italic ps-2 pt-1 text-secondary">{{ value.contacto }}</span>
    <span class="extra fst-italic ps-2 pt-1 text-secondary">{{ value.telefono }}</span>
    <span class="extra fst-italic ps-2 pt-1 text-secondary">{{ value.email }}</span>
  </div>
</template>

<script>
import Display from "./Display.vue";

export default {
  props: ["value"],
  components: { Display },
};
</script>

<style scoped>
.extra {
  font-size: 0.7rem;
}
</style>
