<template>
  <div class="content-block">
    <h3 class="d-flex justify-content-between">
      {{ $t("rr") }}
      <div>
        <DxButton
          :disabled="!selectedRR"
          type="normal"
          stylingMode="text"
          icon="copy"
          :hint="$t('duplicar_rr')"
          @click="duplicar"
          class="me-3 rounded-1"
        />
        <DxButton
          icon="add"
          type="default"
          stylingMode="contained"
          :hint="$t('agregar_rr')"
          @click="() => this.$refs.gridRR.instance.addRow()"
          class="me-3"
        />
      </div>
    </h3>
    <div>
      <DxDataGrid
        @dataErrorOccurred="onDataErrorOccurred"
        @initNewRow="onInitNewRowGrid"
        @rowPrepared="onRowPrepared"
        @rowDblClick="onRowDblClick"
        @selectionChanged="onSelectionChanged"
        ref="gridRR"
        id="gridRR"
        class="dx-card wide-card"
        :data-source="dataSourceRR"
        :hover-state-enabled="true"
        :noDataText="$t('noTieneNingunRR')"
        :selection="{ mode: 'single' }"
      >
        <DxEditingGrid :allow-updating="true" :allow-adding="false" :allow-deleting="true" mode="row" />

        <DxColumnGrid data-field="fecha" :caption="$t('fecha')" data-type="date" sort-order="desc">
          <DxRequiredRule />
        </DxColumnGrid>
        <DxColumnGrid data-field="id_instcaracteristicascalib" :caption="$t('caracteristicas_del_calibre')">
          <DxLookup
            display-expr="caracteristica"
            value-expr="id"
            :data-source="customStoreCaracteristicasCalibracionInstrumento"
          >
          </DxLookup>
        </DxColumnGrid>

        <DxColumnGrid data-field="id_responsables" :caption="$t('realizadoPor')">
          <DxLookup display-expr="nombre" value-expr="id" :data-source="responsablesStore"> </DxLookup>
        </DxColumnGrid>

        <DxColumnGrid data-field="rr100" :caption="$t('R&R')" data-type="Number" :allowEditing="false"></DxColumnGrid>
        <DxColumnGrid
          :tooltip="$t('grafico')"
          width="50"
          cell-template="graficos"
          header-cell-template="grafico-header"
        ></DxColumnGrid>

        <template #grafico-header>
          <span class="pr-1">
            <i class="fa fa-line-chart"></i>
          </span>
        </template>
        <template #graficos="{ data }">
          <a
            href="#"
            class="dx-link position-relative px-1 d-block"
            @click="
              onGraphClick({
                data: data.data,
                row: data.row,
              })
            "
          >
            <i class="fa fa-line-chart"></i>
          </a>
        </template>
      </DxDataGrid>

      <DxPopup :title="popupTitle" :visible.sync="isPopupVisible">
        <DxScrollView>
          <GraficoRR :operarios="appraisers" :lcsx="rr.lcsx" :lcix="rr.lcix" :lcsr="rr.lcsr" :lcir="rr.lcir" />
        </DxScrollView>
      </DxPopup>
    </div>
  </div>
</template>

<script>
import {
  DxDataGrid,
  //  DxButton as DxButtonGrid,
  DxColumn as DxColumnGrid,
  DxEditing as DxEditingGrid,
  DxLookup,
  // DxPaging,
  // DxRangeRule,
} from "devextreme-vue/data-grid";
import { DxRequiredRule } from "devextreme-vue/validator";

import { DxPopup } from "devextreme-vue/popup";
import { DxScrollView } from "devextreme-vue/scroll-view";

import { DxButton } from "devextreme-vue/button";

import { DataSourceRR } from "../../../data/instrumentos/RRDataSource";
import { ResponsablesCustomStore } from "../../../data/ResponsablesDataSource";
import { CustomStoreCaracteristicasCalibracionInstrumento } from "../../../data/instrumentos/CaracteristicasCalibracionInstrumentoDataSource";

import GraficoRR from "@/components/graficos/GraficoRR.vue";

export default {
  components: {
    DxDataGrid,
    DxColumnGrid,
    DxEditingGrid,
    DxLookup,
    // DxPaging,
    DxButton,
    DxPopup,
    DxScrollView,
    DxRequiredRule,
    GraficoRR,
  },

  props: ["idInstrumento"],

  data() {
    return {
      dataSourceRR: new DataSourceRR(this.idInstrumento),
      selectedRR: null,
      responsablesStore: new ResponsablesCustomStore(),
      customStoreCaracteristicasCalibracionInstrumento: new CustomStoreCaracteristicasCalibracionInstrumento(
        this.idInstrumento
      ),
      isPopupVisible: false,
      popupTitle: "",
      rr: {
        lcsx: 0,
        lcix: 0,
        lcsr: 0,
        lcir: 0,
      },
      appraisers: [],
    };
  },
  methods: {
    onDataErrorOccurred(e) {
      // evento de la datagrid que se dispara cuando hay un error
      // se puede alterar el mensaje de error (e.error.message)
      e.error.message = this.$t(e.error.message);
      this.$notify(e.error.message, "error", 3000);
    },

    onInitNewRowGrid(e) {
      e.data.fecha = Date.now();
      e.data.numAppraisers = 2;
      e.data.numTrials = 2;
      e.data.numParts = 10;
    },

    onRowPrepared(e) {
      // console.log("onRowPreparedRR", e);
      if (e.rowType !== "data") return;
      if (e.data.rr100 === null) return;
      e.rowElement.classList.add("bg-opacity-25");

      if (e.data.rr100 >= 30) {
        e.rowElement.classList.add("bg-danger");
      }
      if (e.data.rr100 >= 10) {
        e.rowElement.classList.add("bg-warning");
      }
      if (e.data.rr100 < 10) {
        e.rowElement.classList.add("bg-success");
      }

      // e.rowElement.classList.add("bg-accent");
    },

    onSelectionChanged(e) {
      console.log("onSelectionChangedRR", e);
      this.selectedRR = e.selectedRowsData[0];
    },

    onRowDblClick(e) {
      if (e.isEditing) return;

      // eslint-disable-next-line no-unreachable
      this.$router.push({
        name: "rr_instrumento",
        params: {
          id_instrumento: e.data.id_instrumentos,
          id: e.key,
        },
      });
    },

    onGraphClick({ data, row }) {
      // this.$emit("graphRRClick", data);
      // console.log("onGraphClick", data, row);

      // const caracteristicaCalibre = this.customStoreCaracteristicasCalibracionInstrumento.byKey(
      //   data.id_instcaracteristicascalib
      // );

      // console.log("caracteristicaCalibre", caracteristicaCalibre);

      this.popupTitle = `${this.$t("grafico")} | ${row.cells[0].text} | ${row.cells[1].text}`;

      this.appraisers = data.appraisers;
      this.rr = {
        lcsx: data.lcsx,
        lcix: data.lcix,
        lcsr: data.lcsr,
        lcir: data.lcir,
      };

      // console.log("this.rr", this.rr);

      this.isPopupVisible = true;
    },

    async duplicar() {
      console.log("duplicarRR", this.selectedRR);

      const newRR = {
        ...this.selectedRR,
        id: undefined,
        appraisers: JSON.stringify(this.selectedRR.appraisers),
        averagePart: JSON.stringify(this.selectedRR.averagePart),
        fecha: new Date(),
      };

      await this.dataSourceRR.store().insert(newRR);
      // await this.dataSourceRR.load();
      this.$refs.gridRR.instance.refresh();
    },
  },
};
</script>
