import { get, post, put, remove } from "@/core/fetch";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

export class CustomStoreInstrumentos extends CustomStore {
  constructor(filterQuery = "") {
    const baseUrl = `${global.API_URL}/instrumentos`;

    super({
      key: "id",
      loadMode: "raw",
      // cacheRawData: false,
      load: (e) => {
        console.log("CustomStoreInstrumentos.load", e);
        return get(`${baseUrl}/${filterQuery}`);
      },
      byKey: (key) => get(`${baseUrl}/${key}`),
      insert: (values) => post(`${baseUrl}`, values),
      update: (key, values) => put(`${baseUrl}/${key}`, values),
      remove: (key) => remove(`${baseUrl}/${key}`),
    });
  }
}

export class DataSourceInstrumentos extends DataSource {
  constructor(options = {}) {
    const store = new CustomStoreInstrumentos(options?.filterQuery);
    // @TODO: abrir la puerta a posibles configuraciones del datasource.
    options.store = store;

    console.log("options", options);

    super(options);
  }
}
