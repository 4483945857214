<template>
  <div v-if="resourceURI">
    <Debug>{{ resourceURI }}</Debug>

    <div class="d-flex flex-horizontal justify-content-between">
      <div v-if="!showGallery" class="p-3 w-100 bg-gray align-self-center">
        {{ $t("no_documentos_disponibles") }}
      </div>
      <div></div>
      <div class="p-3" v-if="isAddButtonVisible">
        <DxButton icon="add" @click="onClickAdd"></DxButton>
      </div>
    </div>

    <DxPopup :title="$t('subirDocumento')" :visible.sync="isPopupVisible" width="300px" height="auto">
      <form id="uploadForm" ref="uploadForm" name="uploadForm" @submit.prevent="onSubmitUploadForm">
        <input type="hidden" name="tipo" value="DOC" />

        <div class="d-flex flex-column" style="gap: 10px">
          <!-- <div class="d-flex flex-column plan nombre">
            <label class="form-label">{{ $t("nombre") }}*</label>
            <div class="form-data">
              <DxTextBox width="100%" name="nombre">
                <DxValidator ref="vNombre" :validationGroup="this.resourceURI + 'DOC'">
                  <DxRequiredRule :message="$t('errorNombreRequerido')" />
                </DxValidator>
              </DxTextBox>
            </div>
            <div class="form-text text-muted">{{ $t("nombreFichero") }}</div>
          </div> -->

          <div class="d-flex flex-column plan descripcion">
            <label class="form-label">{{ $t("descripcion") }}*</label>
            <div class="form-data">
              <DxTextBox width="100%" name="descripcion">
                <DxValidator ref="vDescripcion" :validationGroup="this.resourceURI + 'DOC'">
                  <DxRequiredRule :message="$t('errorDescripcionRequerida')" />
                </DxValidator>
              </DxTextBox>
            </div>
            <div class="form-text text-muted">{{ $t("descripcionFichero") }}</div>
          </div>
        </div>

        <div class="fileuploader-container text-center">
          <DxFileUploader
            name="fichero"
            ref="fichero"
            :select-button-text="$t('seleccionarArchivo')"
            label-text=""
            accept=".doc,.pdf,.docx,.xls,.xlsx,.txt,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.zip, .rar"
            :max-file-size="100000000"
            upload-mode="useForm"
          />
        </div>

        <!-- <input type="file" name="fichero" /> -->

        <!-- <DxButton
          icon="add"
          type="default"
          :useSubmitBehavior="true"
          :validationGroup="this.resourceURI"
        ></DxButton> -->
      </form>

      <DxToolbarItem :options="toolbarItemCancelarOptions" widget="dxButton" location="after" toolbar="bottom" />

      <DxToolbarItem :options="toolbarItemGuardarOptions" widget="dxButton" location="after" toolbar="bottom" />
    </DxPopup>

    <div v-if="showGallery" class="remove-responsive-paddings"></div>

    <div class="gallery" v-show="showGallery">
      <DxDataGrid
        ref="dataGrid"
        :data-source="attachedSource"
        :hover-state-enabled="true"
        height="100%"
        width="100%"
        @row-dbl-click="onRowDblClick"
        @row-removed="() => this.$emit('changed', attachedSource.items())"
        @row-updated="() => this.$emit('changed', attachedSource.items())"
      >
        <DxEditing :allow-updating="true" :allow-adding="false" :allow-deleting="true" mode="row"> </DxEditing>
        <DxFilterRow :visible="true" />

        <DxColumn data-field="nombre" :caption="$t('nombre')" width="200" :allowEditing="true" />
        <DxColumn data-field="descripcion" :caption="$t('descripcion')" width="100%" :allowEditing="true" />
        <!-- <DxColumn data-field="fichero" :caption="$t('fichero')" width="100" :allowEditing="true" /> -->
        <!-- <DxColumn data-field="tipo" :caption="$t('tipo')" width="100" :allowEditing="true" /> -->
        <!-- <DxColumn data-field="enlace" :caption="$t('enlace')" width="100" :allowEditing="true" /> -->
        <!-- <DxColumn data-field="tabla" :caption="$t('tabla')" width="100" :allowEditing="true" /> -->
        <!-- <DxColumn data-field="size" width="100"> </DxColumn> -->
        <DxColumn data-field="originalName" :allowEditing="false" :caption="$t('nombreOriginal')" width="150">
        </DxColumn>
        <DxColumn width="50" cell-template="download-template"> </DxColumn>

        <!-- <DxColumn data-field="mimeType"> </DxColumn> -->

        <template #download-template="{ data }">
          <div>
            <a
              :href="`${DATA_URL}/${data.data.fichero}`"
              @click.prevent="downloadFile(data.data.fichero)"
              class="dx-link position-relative px-1"
            >
              <i class="ion ion-md-cloud-download"></i>
            </a>
          </div>
        </template>
      </DxDataGrid>
      <!-- <code>
      <pre>{{ JSON.stringify(attachedSource.items(), null, 2) }}</pre>
    </code> -->
    </div>
  </div>
</template>

<script>
import {
  // DxSelection,
  DxFilterRow,
  DxDataGrid,
  DxEditing,
  DxColumn,
} from "devextreme-vue/data-grid";
import { DxButton } from "devextreme-vue/button";

import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";

import { DxFileUploader } from "devextreme-vue/file-uploader";
import { DxTextBox } from "devextreme-vue/text-box";

import {
  DxValidator,
  DxRequiredRule,
  // DxCompareRule,
  // DxEmailRule,
  // DxPatternRule,
  // DxStringLengthRule,
  // DxRangeRule,
  // DxAsyncRule
} from "devextreme-vue/validator";

// import { DxContextMenu } from "devextreme-vue/context-menu";

// import auth from "../auth";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

// import { confirm } from "devextreme/ui/dialog";
// import ImageWithAuthToken from "./core/ImageWithAuthToken.vue";

// import Prompt from "@/components/prompt";

import { download } from "@/core/fetch";

export default {
  props: ["resourceURI", "showAddButton"],
  data() {
    const directory = this.$store.state.user.database.substring(2);
    const attachedStore = new CustomStore({
      key: "id",
      // load: async () => {
      //   const result = await this.$fetch.get(`${global.API_URL}/${this.resourceURI}/attached?type=DOC`);
      //   const data = result.filter((item) => item.tipo === "DOC");

      //   // envio el evento loaded al padre para que sepa que ya se han cargado los datos.
      //   this.$emit("loaded", data);
      //   return data;
      // },
      load: () => this.$fetch.get(`${global.API_URL}/${this.resourceURI}/attached?type=DOC`),
      byKey: (key) => this.$fetch.get(`${global.API_URL}/${this.resourceURI}/attached/${key}`),
      insert: (values) => this.$fetch.postMultipart(`${global.API_URL}/${this.resourceURI}/attached`, values),
      update: (key, values) => this.$fetch.put(`${global.API_URL}/${this.resourceURI}/attached/${key}`, values),
      remove: (key) => this.$fetch.delete(`${global.API_URL}/${this.resourceURI}/attached/${key}`),
    });

    const attachedSource = new DataSource({
      store: attachedStore,
      filter: ["tipo", "=", "DOC"],
    });

    return {
      isPopupVisible: false,
      selectedImage: undefined,
      isAddButtonVisible: this.$props.showAddButton ?? true,
      DATA_URL: `${global.DATA_URL}/${directory}`,
      attachedSource: attachedSource,
    };
  },
  components: {
    DxButton,
    //
    DxPopup,
    DxToolbarItem,
    DxTextBox,
    DxFileUploader,
    DxValidator,
    DxRequiredRule,
    DxDataGrid,
    DxEditing,
    DxFilterRow,
    DxColumn,
  },

  methods: {
    async onRowDblClick() {
      console.log("row double click");
    },

    async downloadFile(filename) {
      const directory = this.$store.state.user.database.substring(2);
      const url = `${global.DATA_URL}/${directory}/${filename}`;
      const { originalName, blob } = await download(url);

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = originalName ?? "download";
      link.click();
    },

    onClickAdd() {
      this.$refs.vDescripcion.instance.reset();
      this.$refs.fichero.instance.reset();

      this.isPopupVisible = true;
    },

    add() {
      this.onClickAdd();
    },

    onClickAceptar(e) {
      if (e.validationGroup.validate().isValid) {
        //this.$refs.uploadForm.submit();
        var event = new Event("submit", {
          cancelable: true,
          defaultPrevented: true,
        });
        this.$refs.uploadForm.dispatchEvent(event);
      }
    },

    onClickCancelar() {
      //reset de los controles.
      this.isPopupVisible = false;
    },

    async onSubmitUploadForm(e) {
      e.preventDefault();

      let data = new FormData(e.target);

      data.append("nombre", data.get("fichero").name);

      try {
        // await this.attachedStore.insert(data);
        await this.attachedSource.store().insert(data);
        await this.attachedSource.reload();
        this.$emit("changed", this.attachedSource.items());

        //await this.$refs.dataGrid.instance.getDataSource().reload();
        this.$refs.dataGrid.instance.refresh();
        this.isPopupVisible = false;
      } catch (ex) {
        console.error(ex);
      }
    },
  },

  computed: {
    toolbarItemCancelarOptions: function () {
      return {
        text: this.$t("cancelar"),
        type: "normal",
        onClick: this.onClickCancelar,
      };
    },

    toolbarItemGuardarOptions: function () {
      return {
        text: this.$t("guardar"),
        stylingMode: "contained",
        type: "default",
        onClick: this.onClickAceptar,
        validationGroup: this.resourceURI + "DOC",
      };
    },
    showGallery() {
      return this.attachedSource.items().length > 0;
    },
  },
};
</script>

<style lang="scss">
// @TODO: revisar responsive-padding y mejorar componentes para no tener que estar
// añadiendo clases para quitar padding de .responsive-paddings
.responsive-paddings:has(> div > .remove-responsive-paddings) {
  padding: 0;
}
</style>
