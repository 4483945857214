<template>
  <div>
    <div v-if="showEditComputed">
      <div v-if="false">
        showEdit: {{ showEdit }}<br />
        isPopupVisible: {{ isPopupVisible }}<br />
        showEditComputed: {{ showEditComputed }}<br />
      </div>

      <!--
        :animation="{
                hide: {
                  type: 'fadeOut',
                  duration: 400,
                },
                show: {
                  type: 'fadeIn',
                  duration: 400,
                },
              }"
      -->

      <DxPopup
        ref="popupEdit"
        :visible.sync="isPopupVisible"
        :fullScreen="false"
        @hiding="handleHiding"
        @hidden="handleHidden"
        @shown="handleShown"
        @showing="handleShowing"
        :title="title"
      >
        <DxToolbarItem :options="toolbarItemCancelarOptions" widget="dxButton" location="after" toolbar="bottom" />
        <DxToolbarItem
          ref="toolbarItemGuardarRef"
          :options="toolbarItemGuardarOptions"
          widget="dxButton"
          location="after"
          toolbar="bottom"
        />
        <slot name="loader"></slot>

        <DxScrollView>
          <div @keydown.83.ctrl.prevent.stop="ctrlSave">
            <DxValidationGroup ref="validationGroup">
              <slot></slot>
              <DxValidationSummary id="summary" />
            </DxValidationGroup>
          </div>
        </DxScrollView>
      </DxPopup>
    </div>
  </div>
</template>

<script>
import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";
import { DxScrollView } from "devextreme-vue/scroll-view";
import DxValidationGroup from "devextreme-vue/validation-group";
import DxValidationSummary from "devextreme-vue/validation-summary";

export default {
  props: ["title", "showEdit", "update"],

  components: {
    DxPopup,
    DxToolbarItem,
    DxScrollView,
    DxValidationGroup,
    DxValidationSummary,
  },

  data() {
    return {
      isPopupVisible: this.showEdit,
      localTitle: "...",
    };
  },

  watch: {
    showEditComputed(newValue) {
      this.isPopupVisible = newValue;
    },

    // title(newValue) {
    //   console.log("title", newValue);
    //   console.log("title", 1000);
    //   setTimeout(() => {
    //     this.localTitle = newValue;
    //   }, 1000);
    // },
  },

  computed: {
    showEditComputed: {
      get() {
        return this.showEdit;
      },
      set(value) {
        this.isPopupVisible = value;
        this.$emit("update:showEdit", value);
      },
    },

    toolbarItemCancelarOptions: function () {
      return {
        text: this.$t("cancelar"),
        type: "normal",
        onClick: () => {
          this.isPopupVisible = false;
        },
      };
    },

    toolbarItemGuardarOptions: function () {
      return {
        elementAttr: { class: "buttonSave" },
        text: this.$t("guardar"),
        stylingMode: "contained",
        type: "default",
        onClick: () => {
          this.innerUpdate();
        },
      };
    },
  },

  methods: {
    handleSubmit() {},
    async innerUpdate() {
      // hago focus en el popup focus para que el textbox o lo que esté seleccionado pierda el focus y se valide.
      this.$refs.popupEdit.instance.focus();

      // this.$refs.validationGroup.instance.validate()

      try {
        if (!this.$refs.validationGroup.instance.validate().isValid) throw "campos nos validos!";
        await this.update();
        this.isPopupVisible = false;
      } catch (ex) {
        console.error("error", ex);
        this.$emit("error", ex);
        this.$notify(ex, this.$t("error"), 5000);
      }
    },
    handleHiding(e) {
      this.$emit("hiding", e);
    },
    handleHidden(e) {
      // when popup is not visible we can dismount this component:
      this.showEditComputed = false;
      // this.showEditComputed = false
      this.$emit("hidden", e);
    },
    handleShown(e) {
      this.$emit("shown", e);
    },
    handleShowing(e) {
      this.$emit("showing", e);
    },

    async ctrlSave() {
      // console.log("CTRL + S");
      this.innerUpdate();
    },
  },
};
</script>
