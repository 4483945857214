<template>
  <div class="mb-3">
    <code v-if="$store.state.debug">
      plan: {{ planID }} <br />
      estructura: {{ estructuraID }}<br />
      selectedMetodoAnalisis: {{ selectedMetodoAnalisis }}<br />
      selectedCondicion: {{ selectedCondicion }}<br />
      selectedCaracteristicaID: {{ selectedCaracteristicaID }}
    </code>

    <CaracteristicaEdit
      v-if="showEditCaracteristica"
      :showEdit.sync="showEditCaracteristica"
      :id="selectedCaracteristicaID"
      :idEstructura="estructuraID"
      :popupTitle="selectedCaracteristicaName"
      @inserted="handleInserted"
      @updated="handleUpdated"
      @deleted="handleDeleted"
      @updatedDataSource="handleUpdatedDataSource"
    >
    </CaracteristicaEdit>

    <div class="position-absolute top-0 mt-4 end-0">
      <ButtonNewCaracteristica
        :estructuraID="estructuraID"
        :planID="planID"
        @new="handleNewCaracteristica"
        @copied="handleCopiedCaracteristica"
      >
      </ButtonNewCaracteristica>
    </div>

    <dx-data-grid
      ref="grid"
      id="gridCaracteristicas"
      class="dx-card wide-card"
      :data-source="caracteristicasDataSource"
      keyExpr="id"
      :hover-state-enabled="true"
      :paging="{
        enabled: false,
      }"
      :show-borders="false"
      :selection="{ mode: 'single' }"
      :noDataText="$t('noHayPautaControl')"
      @row-dbl-click="handleRowDblClick"
      :allow-column-resizing="true"
      column-resizing-mode="nextColumn"
    >
      <DxStateStoring :enabled="true" storage-key="grid-plan-caracteristicas"> </DxStateStoring>

      <DxEditingGrid :allow-updating="false" :allow-adding="false" :allow-deleting="false" mode="popup">
      </DxEditingGrid>

      <DxRowDragging
        :group="group"
        :showDragIcons="true"
        :on-remove="onRemove"
        boundary=".drag-boundary-caracteristica"
      />

      <dx-column data-field="linea" :caption="$t('linea')" :width="60" :allowFiltering="false" sort-order="asc">
      </dx-column>

      <dx-column data-field="id" :caption="$t('numero')" :width="60" :allowFiltering="false" :visible="false" />

      <dx-column
        data-field="nombre"
        :caption="$t('caracteristica')"
        :allowFiltering="false"
        edit-cell-template="caracteristicaEditor"
      >
        <DxRequiredRule></DxRequiredRule>
      </dx-column>

      <template #caracteristicaEditor="{ data: cellInfo }">
        <DictionaryEditor
          :title="$t('catalogoCaracteristicas')"
          v-model="cellInfo.value"
          value-expr="texto"
          recurso="caracteristicas/diccionario"
          @input="
            (e) => {
              cellInfo.setValue(e);
            }
          "
          @dataSourceUpdated="
            () => {
              $refs.grid.instance.refresh();
            }
          "
        >
        </DictionaryEditor>
      </template>

      <dx-column
        data-field="id_caracteristicastipo"
        :caption="$t('tipo')"
        :setCellValue="handleSetCellValueCaracteristicasTipo"
      >
        <dx-lookup display-expr="codigo" value-expr="id" :data-source="caracteristicasTipoDataSource"> </dx-lookup>
      </dx-column>

      <dx-column
        data-field="id_metodoanalisis"
        :caption="$t('metodoAnalisis')"
        :setCellValue="handleSetCellValueMetodoAnalisis"
      >
        <dx-lookup display-expr="codigo" value-expr="id" :data-source="metodoAnalisisDataSource"> </dx-lookup>
      </dx-column>

      <dx-column data-field="valor_pedido" :caption="$t('valorPedido')" :visible="false"> </dx-column>
      <dx-column data-field="tolerancia1" :caption="$t('tolerancia1')" :visible="false"> </dx-column>
      <dx-column data-field="tolerancia2" :caption="$t('tolerancia2')" :visible="false"> </dx-column>

      <dx-column data-field="id_condiciones" :caption="$t('condicion')" :visible="false">
        <dx-lookup display-expr="codigo" value-expr="id" :data-source="condicionesDataSource"> </dx-lookup>
      </dx-column>

      <dx-column :encodeHtml="false" data-field="especificacion" :caption="$t('especificacionProducto')" />

      <dx-column data-field="id_unidades" :caption="$t('unidad')" :visible="false">
        <dx-lookup display-expr="codigo" value-expr="id" :data-source="unidadesDataSource"> </dx-lookup>
      </dx-column>

      <dx-column data-field="id_metodoevaluacion" :caption="$t('metodoEvaluacion')">
        <dx-lookup display-expr="descripcion" value-expr="id" :data-source="metodoEvaluacionDataSource"> </dx-lookup>
      </dx-column>

      <dx-column :width="70" data-field="tamano_muestra" :caption="$t('tamano') + '/' + $t('recorridoMovil')" />

      <dx-column data-field="id_frecuencias" :caption="$t('frecuencia')">
        <dx-lookup display-expr="descripcion" value-expr="id" :data-source="frecuenciaDataSource"> </dx-lookup>
      </dx-column>

      <dx-column data-field="id_instrumentos" :caption="$t('calibre')" :visible="false">
        <dx-lookup display-expr="nombre" value-expr="id" :data-source="instrumentosDataSource"> </dx-lookup>
      </dx-column>

      <dx-column data-field="token_usuariosrol" :caption="$t('realizadoPor')" cell-template="rolTemplate">
        <DxLookup :dataSource="customStoreRoles" :display-expr="(a) => [a.codigo, a.descripcion]" value-expr="id" />
      </dx-column>

      <template #rolTemplate="{ data: templateOptions }">
        <div class="tag-container text-wrap gap-1 d-flex flex-wrap">
          <div
            v-for="role in templateOptions.value"
            :key="role.id"
            class="badge text-bg-secondary"
            :title="templateOptions.column.lookup.calculateCellValue(role, true)[1]"
          >
            {{ templateOptions.column.lookup.calculateCellValue(role, true)[0] }}
          </div>
        </div>
      </template>

      <dx-column data-field="reaccion" :caption="$t('reaccion')" :visible="true"></dx-column>

      <dx-column data-field="observaciones" :caption="$t('observaciones')" :visible="true"></dx-column>

      <dx-column data-field="analisis" :caption="$t('analisis')" data-type="boolean"> </dx-column>

      <!-- <dx-column type="buttons" width="40">
        <dxButtonGrid
          hint="Menu"
          icon="overflow"
          :onClick="handleGridMenuButtonClick"
          :visible="true"
          :disabled="false"
        />
      </dx-column> -->
    </dx-data-grid>
  </div>
</template>

<script>
import { CustomStoreCaracteristicasTipo } from "@/data/caracteristicas/CaracteristicasTipoDataSource";
import { CustomStoreInstrumentos } from "@/data/instrumentos/InstrumentoDataSource";
import { RolesCustomStore } from "@/data/usuarios/RolesDataSource";
import { DataSourceCaracteristicas } from "@/data/caracteristicas/CaracteristicasDataSource";

import CaracteristicaEdit from "./caracteristica/Caracteristica.edit.vue";

import {
  DxDataGrid,
  DxColumn,
  DxLookup,
  DxEditing as DxEditingGrid,
  DxRowDragging,
  DxStateStoring,
} from "devextreme-vue/data-grid";
import DictionaryEditor from "@/components/core/DictionaryEditor";
import CustomStore from "devextreme/data/custom_store";
import auth from "@/auth";
import { DxRequiredRule } from "devextreme-vue/validator";

import ButtonNewCaracteristica from "./caracteristica/ButtonNewCaracteristica.vue";

// @TODO REFACTOR PLEASE!

const tipoCaracteristicasDataSource = new CustomStore({
  key: "id",
  loadMode: "raw", // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
  cacheRawData: true,
  load: () => {
    return fetch(`${global.API_URL}/caracteristicas/tipos`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .catch(() => {
        throw "Network error";
      });
  },
  byKey: (key) => {
    return fetch(`${global.API_URL}/caracteristicas/tipos/${key}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .catch(() => {
        throw "Network error";
      });
  },
});

const metodoAnalisisDataSource = new CustomStore({
  key: "id",
  loadMode: "raw", // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
  cacheRawData: true,
  load: () => {
    return fetch(`${global.API_URL}/caracteristicas/metodoanalisis`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .catch(() => {
        throw "Network error";
      });
  },
  byKey: (key) => {
    return fetch(`${global.API_URL}/caracteristicas/metodoanalisis/${key}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .catch(() => {
        throw "Network error";
      });
  },
});

// const caracteristicasTipoDataSource = new CustomStoreCaracteristicasTipo()
// const instrumentosDataSource = new CustomStoreInstrumentos()

const metodoEvaluacionDataSource = new CustomStore({
  key: "id",
  loadMode: "raw", // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
  cacheRawData: true,
  load: () => {
    return fetch(`${global.API_URL}/caracteristicas/metodoevaluacion`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .catch(() => {
        throw "Network error";
      });
  },
  byKey: (key) => {
    return fetch(`${global.API_URL}/caracteristicas/metodoevaluacion/${key}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .catch(() => {
        throw "Network error";
      });
  },
});

const calibresDataSource = new CustomStore({
  key: "id",
  // loadMode: "raw", // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
  // cacheRawData: true,
  load: () => {
    return fetch(`${global.API_URL}/instrumentos`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .catch(() => {
        throw "Network error";
      });
  },
  byKey: (key) => {
    return fetch(`${global.API_URL}/instrumentos/${key}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .catch(() => {
        throw "Network error";
      });
  },
});

const frecuenciaDataSource = new CustomStore({
  key: "id",
  loadMode: "raw", // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
  cacheRawData: true,
  load: () => {
    return fetch(`${global.API_URL}/caracteristicas/frecuencias`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .catch(() => {
        throw "Network error";
      });
  },
  byKey: (key) => {
    return fetch(`${global.API_URL}/caracteristicas/frecuencias/${key}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .catch(() => {
        throw "Network error";
      });
  },
});

const reaccionesDataSource = new CustomStore({
  key: "id",
  loadMode: "raw", // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
  cacheRawData: true,
  load: () => {
    return fetch(`${global.API_URL}/reacciones`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .catch(() => {
        throw "Network error";
      });
  },
  byKey: (key) => {
    return fetch(`${global.API_URL}/reacciones/${key}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .catch(() => {
        throw "Network error";
      });
  },
});

const condicionesCustomStore = new CustomStore({
  key: "id",
  // loadMode: "raw", // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
  // cacheRawData: true,
  load: (optionsLoad) => {
    let url = `${global.API_URL}/analisis/condiciones`;

    if (optionsLoad.filter) {
      const value = optionsLoad.filter[2];
      url = `${global.API_URL}/analisis/${value}/condiciones`;
    }

    return fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .catch(() => {
        throw "Network error";
      });
  },
  byKey: (key) => {
    return fetch(`${global.API_URL}/analisis/condiciones/${key}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .catch(() => {
        throw "Network error";
      });
  },
});

const condicionesDataSource = (options) => {
  return {
    store: condicionesCustomStore,
    filter: options.data
      ? ["id_metodoanalisis", "=", options.data.id_metodoanalisis ? options.data.id_metodoanalisis : null]
      : null,
  };
};

const unidadesDataSource = new CustomStore({
  key: "id",
  loadMode: "raw", // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
  cacheRawData: true,
  load: () => {
    return fetch(`${global.API_URL}/analisis/unidades`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .catch(() => {
        throw "Network error";
      });
  },
  byKey: (key) => {
    return fetch(`${global.API_URL}/analisis/unidades/${key}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .catch(() => {
        throw "Network error";
      });
  },
});

function handleErrors(response) {
  return response;
}

const tipo = {};

export default {
  props: ["estructuraID", "planID", "group"],
  data() {
    const contextMenuDataSource = [{ text: "Nuevo" }, { text: "Editar" }, { text: "Eliminar" }];

    return {
      isCopy: false,
      tipo,
      contextMenuDataSource,
      tipoCaracteristicasDataSource,
      metodoAnalisisDataSource,
      metodoEvaluacionDataSource,
      frecuenciaDataSource,
      reaccionesDataSource,
      unidadesDataSource,
      calibresDataSource,
      popupShow: false,
      popupTarget: "",
      popupContent: "()",

      selectedMetodoAnalisis: undefined,
      selectedCondicion: undefined,
      showTolerancia2: false,

      // Edit caracteristica:
      selectedCaracteristicaID: null,
      selectedCaracteristicaName: null,
      showEditCaracteristica: false,

      // caracteristicasDataSource: new CustomStore({
      //   key: "id",
      //   /**
      //    * La api REST no expone /api/estructuras. (¿Es correcto?)
      //    * Para poder acceder a una característica tenemos que pasar por todo el camino:
      //    *    planes/:idPlan/estructura/:idEstructura/caracteristicas/:idCaracteristicas.
      //    *
      //    * Para hacer la select de las características de una estructura no se necesita tener el ID del plan
      //    * de control por lo que las llamadas se pueden hacer de la siguiente manera:
      //    */

      //   load: () => {
      //     return this.$fetch.get(
      //       `${global.API_URL}/planes/${this.planID}/estructura/${this.estructuraID}/caracteristicas`
      //     );
      //   },
      //   byKey: (key) => this.$fetch.get(`${global.API_URL}/caracteristicas/${key}`),

      //   /**
      //    * Visto esto, la api debería exponer /api/estructura/:id/caracteristica directamente? ¿Qué cambios suponen?
      //    */

      //   remove: (key) => {
      //     return this.$fetch.delete(`${global.API_URL}/caracteristicas/${key}`);
      //   },

      //   insert: (values) => {
      //     return this.$fetch.post(`${global.API_URL}/caracteristicas`, values);
      //   },
      // }),

      caracteristicasDataSource: new DataSourceCaracteristicas({
        idEstructura: this.estructuraID,
        idPlan: this.planID,
      }),

      caracteristicasTipoDataSource: new CustomStoreCaracteristicasTipo(),
      instrumentosDataSource: new CustomStoreInstrumentos(),

      condicionesDataSource,
      customStoreRoles: new RolesCustomStore(),
    };
  },

  computed: {
    toolbarItemCancelarOptions: function () {
      return {
        text: this.$t("cancelar"),
        type: "normal",
        onClick: () => {
          this.$refs.grid.instance.cancelEditData();
        },
      };
    },

    toolbarItemGuardarOptions: function () {
      return {
        elementAttr: { class: "buttonSave" },
        text: this.$t("guardar"),
        stylingMode: "contained",
        type: "default",
        onClick: () => {
          this.$refs.grid.instance.saveEditData();
        },
      };
    },
  },

  components: {
    DxDataGrid,
    DxColumn,
    DxLookup,
    DxEditingGrid,
    DxStateStoring,
    DxRowDragging,
    DxRequiredRule,
    DictionaryEditor,
    CaracteristicaEdit,
    ButtonNewCaracteristica,
  },

  created() {
    this.keyDownHandler = (e) => {
      console.log("keydown", e.key, e.ctrlKey, e.metaKey);
      if (e.key == "Control") {
        this.isCopy = true;
      }
    };

    this.keyUpHandler = (e) => {
      console.log("keyup", e.key, e.ctrlKey, e.metaKey);
      if (e.key == "Control") {
        this.isCopy = false;
      }
    };
    window.addEventListener("keydown", this.keyDownHandler);
    window.addEventListener("keyup", this.keyUpHandler);
  },

  beforeDestroy() {
    window.removeEventListener("keydown", this.keyDownHandler);
    window.removeEventListener("keyup", this.keyUpHandler);
  },

  methods: {
    // onDragStart(e) {
    //   console.log("onDragStart", e);
    // },

    // onDragMove(e) {
    //   console.log("onDragMove", e);
    // },

    // onDragEnd(e) {
    //   console.log("onDragEnd:e", e);
    //   console.log("onDragEnd:fromData,toData", e.fromData, e.toData);
    //   console.log("onDragEnd:fromCompoent,toComponent", e.fromComponent, e.toComponent);
    //   console.log("onDragEnd:fromIndex", e.fromIndex, e.toIndex);
    //   console.log("onDragEnd:dataindex", e.toData[e.toIndex]);
    // },

    onRemove() {
      // console.log("onRemove", e);
      this.$refs.grid.instance.refresh();
    },

    editRow(rowKey, name) {
      this.selectedCaracteristicaID = rowKey;
      this.selectedCaracteristicaName = name;
      this.showEditCaracteristica = true;
    },
    handleRowDblClick(e) {
      // console.log("handleRowDblClick", e);
      this.editRow(e.key, e.data.nombre);
    },
    // handleContextMenuPreparing(e) {
    //   console.log("handleContextMenuPreparing", e);
    //   if (e.row.rowType === "data") {
    //     e.items = [
    //       {
    //         text: this.$t("editar"),
    //         onItemClick: () => {
    //           this.editRow(e.row.key, e.row.data.nombre);
    //         },
    //       },
    //       {
    //         text: this.$t("eliminar"),
    //         onItemClick: () => {
    //           this.$refs.grid.instance.deleteRow(e.row.rowIndex);
    //         },
    //       },
    //     ];
    //   }
    // },

    // handleGridMenuButtonClick(e) {
    //   // console.log(e);
    //   // console.log(this.$refs.grid.instance);
    //   window.grid = this.$refs.grid;
    //   window.instance = this.$refs.grid.instance;

    //   e.event.type = "dxcontextmenu";

    //   this.$refs.grid.$emit("context-menu-preparing", e);
    //   // this.$refs.grid.instance.showContextMenu(e.event.target);
    //   // console.log("handleGridMenuButtonClick", e);
    // },

    // handleItemClick(e) {
    //   console.log("handleItemClick", e);
    // },

    async refresh() {
      this.load();
      //al hacer load se pierde el focus!
      //poner el focus?
    },

    async load() {},

    async reload() {
      this.metodoEvaluacionDataSource.clearRawDataCache();
      this.metodoEvaluacionDataSource.load();

      this.frecuenciaDataSource.clearRawDataCache();
      this.frecuenciaDataSource.load();
    },

    getTime(date) {
      return this.$moment(date).fromNow();
    },

    getFecha(date) {
      return this.$moment(date).format("L");
    },

    getHora(date) {
      return this.$moment(date).format("LTS");
    },

    handleSetCellValueCaracteristicasTipo(newData, value, currentRowData) {
      /* @TODO */ console.log({ newData, value, currentRowData });
    },

    handleSetCellValueMetodoAnalisis(newData, value, currentRowData) {
      /* @TODO */ console.log({ newData, value, currentRowData });

      newData.id_metodoanalisis = value;
      newData.id_condiciones = null;
    },

    handleNewCaracteristica(inserted) {
      this.$refs.grid.instance.refresh();
      this.editRow(inserted.id, inserted.nombre);
    },

    handleCopiedCaracteristica(copied) {
      console.log("handleCopiedCaracteristica", copied);
      this.$refs.grid.instance.refresh();
    },

    handleInserted() {
      console.log("handleInserted");
      this.$refs.grid.instance.refresh();
    },

    handleUpdated() {
      console.log("handleUpdated");
      this.$refs.grid.instance.refresh();
    },

    handleDeleted() {
      console.log("handleDeleted");
      this.$refs.grid.instance.refresh();
    },

    handleClosed() {
      this.showEditCaracteristica = false;
    },

    handleUpdatedDataSource() {
      console.log("handleUpdatedDataSource");
      this.reload();
    },

    handleSelectionChanged() {},
  },
};
</script>

<style>
/* @TODO: find another place to put this css */
.dx-field-item-label {
  padding-left: 0;
  color: #aaa;
  margin-bottom: 3px;
  font-size: 0.7rem;
}

.tolerancia {
  text-align: end;
  display: inline-block;
  vertical-align: top;
}

.dx-sortable-clone {
  box-shadow: 10px 10px 0 0 lime;
  position: relative;
  overflow: visible;
}

.dx-sortable-clone:before {
  content: "➕";
  font-size: 0.7em;
  position: absolute;
  bottom: -5px;
  left: -5px;
  z-index: 1;
  border-radius: 50%;
  padding: 7px;
  background-color: greenyellow;
  content: v-bind(isCopy);
}
</style>
