<template>
  <div class="d-flex flex-wrap justify-content-between">
    <div v-if="showGraph" class="d-flex flex-column flex-lg-row w-100">
      <div v-for="operario in operarios" :key="operario.id" class="w-100">
        <GraficoXBarraR :operario="operario" :lcsx="lcsx" :lcix="lcix" :lcsr="lcsr" :lcir="lcir"></GraficoXBarraR>
      </div>
    </div>

    <div v-if="showGraph" class="w-100">
      <GraficoXBarraR :operario="operarioConcat" :lcsx="lcsx" :lcix="lcix" :lcsr="lcsr" :lcir="lcir"></GraficoXBarraR>
    </div>
  </div>
  <!-- <GraficoXBarraR ref="GraficoOperario" :operario="operario"> </GraficoXBarraR> -->
</template>

<script>
import GraficoXBarraR from "@/components/graficos/GraficoXBarraR.vue";
import { useDebounceFn } from "@vueuse/core";

export default {
  props: ["operarios", "lcsx", "lcix", "lcsr", "lcir"],
  components: {
    GraficoXBarraR,
  },

  data() {
    return {
      operario: {},
      showGraph: true,
      operarioConcat: { average: [], range: [] },
    };
  },

  mounted() {
    // window.addEventListener("resize", this.resize);
    const debouceResize = useDebounceFn(this.resize, 250);
    window.addEventListener("resize", debouceResize);
  },

  methods: {
    async load() {},
    resize() {
      this.showGraph = false;
      this.$nextTick(() => {
        this.showGraph = true;
      });
    },
  },

  // computed: {
  //   operariosComputed() {
  //     this.resize();
  //     return this.operarios;
  //   },
  //   operarioConcat() {
  //     console.log(this.operarios);
  //     const operarios = this.operarios.reduce(
  //       (acc, operario) => {
  //         acc.average = acc.average.concat(operario.average);
  //         acc.range = acc.range.concat(operario.range);
  //         return acc;
  //       },
  //       { average: [], range: [] }
  //     );
  //     console.log(operarios);
  //     return operarios;
  //   },
  // },

  watch: {
    operarios: {
      handler() {
        useDebounceFn(this.resize, 1000)();

        this.operarioConcat = this.operarios.reduce(
          (acc, operario) => {
            acc.average = acc.average.concat(operario.average);
            acc.range = acc.range.concat(operario.range);
            return acc;
          },
          { average: [], range: [] }
        );
        // this.resize();
      },
      deep: true, //<-- esto es peligroso...
      immediate: true, // <-- en el mounted ejecute el handler
    },
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
};
</script>

<style></style>
