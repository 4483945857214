<template>
  <div></div>
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch("loadModulos");
  },
};
</script>

<style></style>
