<template>
  <div>
    <!-- {{ analisis }} -->

    <!-- caso nca -->

    <div v-if="caracteristica.id_nca">
      <div class="d-flex align-items-start justify-content-center gap-5">
        <div>
          <h3 class="mb-3">{{ $t("TablaNCA") }}: {{ tabla }} ({{ $t("aql") }}:{{ aql }})</h3>
          <DxNumberBox
            :label="$t('tamanoMuestra')"
            :readOnly="true"
            labelMode="static"
            :placeholder="!ncanivelSeleccionado ? $t('haz click para seleccionar') : ''"
            v-model="analisis.tamano_muestra"
            :onFocusIn="ncaFocusIn"
            class="mb-1"
          >
            <DxValidator>
              <DxRequiredRule v-if="!analisisNoRequerido" :message="$t('errorValorRequerido')" />
            </DxValidator>
          </DxNumberBox>

          <Display :value="ncanivelSeleccionado?.aceptacion" :label="$t('aceptacion')" />
          <Display :value="ncanivelSeleccionado?.rechazo" :label="$t('rechazo')" />
          <Display :value="ncanivelSeleccionado?.minlote" :label="$t('minlote')" />
          <Display :value="ncanivelSeleccionado?.maxlote" :label="$t('maxlote')" />
        </div>

        <div>
          <h3 class="mb-3">&nbsp;</h3>
          <DxNumberBox
            :label="$t('totalDefectos')"
            v-model="analisis.total_defectuosas"
            :min="0"
            :showSpinButtons="true"
            :step="1"
          >
            <DxValidator>
              <DxRangeRule :max="analisis.tamano_muestra ? analisis.tamano_muestra : +Infinity"> </DxRangeRule>
              <DxRequiredRule :message="$t('errorValorRequerido')" />
            </DxValidator>
          </DxNumberBox>
        </div>

        <!-- <Display :value="tolerancia" :label="$t('aceptacion')" /> -->
      </div>
      <div class="text-center mt-3" height="50px">
        <div v-if="analisisNoRequerido" class="mb-1">
          <span class="fs-6 badge text-bg-success">
            {{ $t("analisisNoRequerido") }}
          </span>
          <p class="text-start p-3"></p>
          {{ $t("explicacionAnalisisNoRequerido") }}
        </div>
        <div v-else-if="rechazoNCA" class="mb-1">
          <span class="fs-6 badge text-bg-danger">
            {{ $t("rechazado") }}
          </span>
        </div>
      </div>
    </div>

    <!-- caso 100% -->

    <div v-else>
      <div class="d-flex align-items-start justify-content-center gap-3">
        <div>
          <DxNumberBox
            :label="$t('tamanoMuestra')"
            v-model="analisis.tamano_muestra"
            :min="1"
            :showSpinButtons="true"
            :step="1"
            :width="150"
          >
            <DxValidator>
              <!-- <DxRangeRule :min="analisis.total_defectuosas"> </DxRangeRule> -->
              <DxRequiredRule :message="$t('errorValorRequerido')" />
            </DxValidator>
          </DxNumberBox>
          <div class="small text-secondary mt-1">
            {{
              caracteristica.tamano_muestra === null
                ? $t("TODAS")
                : `${$t("tamanoMuestraOriginal")}: ${caracteristica.tamano_muestra}`
            }}
          </div>
        </div>

        <DxNumberBox
          :label="$t('totalDefectos')"
          v-model="analisis.total_defectuosas"
          :min="0"
          :showSpinButtons="true"
          :step="1"
          :width="150"
        >
          <DxValidator>
            <DxRangeRule :max="typeof analisis.tamano_muestra === 'number' ? analisis.tamano_muestra : +Infinity">
            </DxRangeRule>
            <DxRequiredRule :message="$t('errorValorRequerido')" />
          </DxValidator>
        </DxNumberBox>

        <Display :value="`${caracteristica?.tolerancia1 ?? ''}%`" :label="$t('tolerancia')" valueStyle="text-end" />
      </div>

      <div class="text-center mt-3" style="height: 50px">
        <span v-if="rechazo100" class="fs-6 badge text-bg-danger">
          {{ $t("rechazado") }}
        </span>
      </div>
    </div>

    <DxPopup :title="$t('nivelesNCA')" :visible.sync="isPopupVisible" :fullScreen="false">
      <template>
        <h3>{{ $t("tabla") }}: {{ tabla }}</h3>
        <h4>{{ $t("descripcion") }}: {{ descripcion }}</h4>
        <h4>{{ $t("aql") }}: {{ aql }}</h4>

        <p class="mt-3">Seleccione haciendo click</p>

        <DxDataGrid
          :data-source="ncaniveles"
          :selection="{ mode: 'single' }"
          :show-borders="true"
          :hover-state-enabled="true"
          key-expr="id"
          @selection-changed="onSelectionChanged"
        >
          <!-- <DxColumn :width="70" data-field="id" caption="ID" /> -->
          <!-- <DxColumn data-field="id_nca"  :caption="$t('tablaNCA')"/> -->
          <DxColumn data-field="minlote" :caption="$t('tamanoLoteMin')" />
          <DxColumn data-field="maxlote" :caption="$t('tamanoLoteMax')" />
          <DxColumn data-field="muestra" :caption="$t('tamanoMuestra')" />
          <DxColumn data-field="aceptacion" :caption="$t('aceptacion')" />
          <DxColumn data-field="rechazo" :caption="$t('rechazo')" />
        </DxDataGrid>
      </template>
    </DxPopup>

    <code v-if="$store.state.debug"> isValid: {{ isValid }} </code>
  </div>
</template>

<script>
import DxNumberBox from "devextreme-vue/number-box";
// import DxRadioGroup from "devextreme-vue/radio-group";
import { DxDataGrid, DxColumn } from "devextreme-vue/data-grid";

import DxPopup from "devextreme-vue/popup";

import { DxValidator, DxRequiredRule, DxRangeRule } from "devextreme-vue/validator";

import auth from "@/auth";
import Display from "../../core/Display.vue";

function handleErrors(response) {
  return response;
}

let ncaniveles = [];

export default {
  props: ["value", "caracteristica"],
  data() {
    return {
      tabla: "",
      descripcion: "",
      aql: "",
      isPopupVisible: false,

      ncaniveles,
      ncanivelSeleccionado: null,
    };
  },

  computed: {
    analisis: function () {
      return this.value;
    },
    rechazo100: function () {
      return this.hayRechazo100();
    },
    rechazoNCA: function () {
      return this.hayRechazoNCA();
    },
    analisisNoRequerido: function () {
      return this.ncanivelSeleccionado !== null && this.analisis.tamano_muestra === null;
    },
  },

  created() {
    // ("%cDef.vue created!!!!!!!!!!!", "background-color: lime");
  },

  mounted() {
    // console.log("%cdef.vue mounted!!!!", "background-color:green");

    const id_nca = this.caracteristica.id_nca;

    //inicializo valores:
    if (this.analisis.total_defectuosas === null) this.analisis.total_defectuosas = 0;

    if (!id_nca) {
      if (this.analisis.tamano_muestra === null) this.analisis.tamano_muestra = this.caracteristica.tamano_muestra;
    } else {
      fetch(`${global.API_URL}/caracteristicas/nca/${id_nca}/niveles`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.getToken(),
        },
      })
        .then(handleErrors)
        .then((response) => response.json())
        .then((data) => (this.ncaniveles = data.sort((a, b) => a.minlote - b.minlote)))
        .catch(() => {
          throw "Network error";
        });

      fetch(`${global.API_URL}/caracteristicas/nca/${id_nca}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.getToken(),
        },
      })
        .then(handleErrors)
        .then((response) => response.json())
        .then((data) => {
          this.descripcion = data.descripcion;
          this.tabla = data.tabla;
          this.aql = data.aql;
        })
        .catch(() => {
          throw "Network error";
        });
    }
  },

  updated() {
    if (!this.caracteristica.id_nca) return this.$set(this.analisis, "rechazo", this.hayRechazo100());
    return this.$set(this.analisis, "rechazo", this.hayRechazoNCA());
  },

  components: {
    DxNumberBox,
    DxValidator,
    DxRequiredRule,
    DxDataGrid,
    DxColumn,
    DxRangeRule,
    DxPopup,
    Display,
  },

  watch: {
    analisisNoRequerido: {
      handler: function () {
        this.$emit("analisisRequerido", !this.analisisNoRequerido);
      },
    },
  },

  methods: {
    validate: function () {
      let valid = true;

      // No es valido si el total_defectuosas no es un número
      if (typeof this.analisis.total_defectuosas !== "number") valid = false;

      // no es valido si sucede lo siguiente:
      valid &&= this.analisis.tamano_muestra > 0;
      valid &&= this.analisis.total_defectuosas >= 0;

      // emitimos validación al padre.
      this.$emit("valid", valid);

      return valid;
    },

    hayRechazo100() {
      let tolerancia1 = this?.caracteristica?.tolerancia1 ?? 0; // this.caracteristica.tolerancia1 : 0;
      return (this.analisis.total_defectuosas * 100) / this.analisis.tamano_muestra > tolerancia1;
    },

    hayRechazoNCA() {
      if (!this.ncanivelSeleccionado) return false;

      if (this.analisis.total_defectuosas <= this.ncanivelSeleccionado.aceptacion) {
        return false;
      }

      if (this.analisis.total_defectuosas >= this.ncanivelSeleccionado.rechazo) {
        return true;
      }

      return undefined;
    },

    onSelectionChanged(e) {
      this.ncanivelSeleccionado = e.selectedRowsData[0];
      this.$set(this.analisis, "tamano_muestra", this.ncanivelSeleccionado ? this.ncanivelSeleccionado.muestra : null);
      this.isPopupVisible = false;
    },
    ncaFocusIn() {
      this.isPopupVisible = true;
    },
  },
};
</script>

<style scoped>
.rechazo {
  min-width: 80px;
  padding-top: 25px;
}
</style>
