<template>
  <div>
    <h2 class="content-block">Dataraw</h2>
    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <ul>
          <li v-for="(plan, index) in test" :key="plan.id">
            {{ index }} - {{ plan.nombre }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

// import Devexpress from "devextreme";

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

/**
 * Returns the object found by ID
 * @memberof Array
 * @param {int} id
 */
Array.prototype.get = function (id) {
  return this.find((e) => e.id === id);
};

const planesCustomStore = new CustomStore({
  key: "id",
  load: () => {
    return fetch(`${global.API_URL}/planes`)
      .then(handleErrors)
      .then((response) => response.json())
      .catch(() => {
        throw "Network error";
      });
  },
  byKey: (key) => {
    return fetch(`${global.API_URL}/planes/${key}`)
      .then(handleErrors)
      .then((response) => response.json())
      .catch(() => {
        throw "Network error";
      });
  },
});

const planesDataSource = new DataSource({
  store: planesCustomStore,
});

export default {
  data() {
    return {
      planesDataSource,
      selectedRowKeys: [],
      selectedPlan: null,
      caracteristica: null,
    };
  },

  mounted() {
    // console.log("mounted autocontrol!", planesDataSource);
    planesDataSource.load();
  },
  computed: {
    test: function () {
      return planesDataSource.items();
    },
    selectedPlanObject: function () {
      // return planesDataSource.items().find((x) => x.id === this.selectedPlan);
      return planesDataSource.items().get(this.selectedPlan);
    },
    caracteristicas: function () {
      if (!this.selectedPlanObject) return [];

      // Version sin id
      const c = this.selectedPlanObject.estructuras
        .filter((e) => this.selectedRowKeys.includes(e.id))
        .map((e) => e.caracteristicas);
      const flatten = c.flat(1);

      // Version con ids
      // const c = Object.values(this.selectedPlanObject.estructuras)
      //   .filter((e) => this.selectedRowKeys.includes(e.id))
      //   .map((e) => Object.values(e.caracteristicas));
      // const flatten = c.flat(1);

      return flatten.length == 0 ? [] : flatten;
    },
  },
};
</script>
<style lang="scss">
</style>