// import * as crud from "../core/fetch";

export const createVerificacion = async (initValues) => {
  console.log(initValues);
  // const url = `${global.API_URL}/instrumentos/${initValues.id_instrumentos}/verificaciones`;
  // const response = await crud.post(url, initValues);
  // return response;

  throw "Not implemented yet";
};
