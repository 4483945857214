// import auth from '@/auth';

// const crud = {
//     async get(url) {
//         try {
//             const response = await fetch(url, {
//                 headers: {
//                     "Content-Type": "application/json",
//                     "Authorization": "Bearer " + auth.getToken(),
//                 },
//             });
//             if (!response.ok) throw (await response.json()).message;
//             const data = await response.json();
//             console.log(data)
//             return data
//         }
//         catch (ex) {
//             console.log("error", ex);
//             throw ex;
//         }
//     },
//     async post(url, values) {
//         try {
//             const response = await fetch(url, {
//                 method: "POST",
//                 headers: {
//                     "Content-Type": "application/json",
//                     "Authorization": "Bearer " + auth.getToken(),
//                 },
//                 body: JSON.stringify(values),
//             });
//             if (!response.ok) {
//                 throw (await response.json()).message;
//             }
//             if (response.status === 204) return;
//             return await response.json()
//         }
//         catch (ex) {
//             console.log("error", ex);
//             throw ex;
//         }
//     },

//     async postMultipart(url, data) {
//         try {
//             const response = await fetch(url, {
//                 method: "POST",
//                 headers: {
//                     // NO PONER!!!!!!
//                     // "Content-Type": "multipart/form-data",
//                     "Authorization": "Bearer " + auth.getToken(),
//                 },
//                 body: data,
//             });
//             if (!response.ok) throw (await response.json()).message;

//             return await response.json()

//         }
//         catch (ex) {
//             console.log("error", ex);
//             throw ex;
//         }
//     },

//     async put(url, values) {
//         try {
//             const response = await fetch(url, {
//                 method: "PUT",
//                 headers: {
//                     "Content-Type": "application/json",
//                     "Authorization": "Bearer " + auth.getToken(),
//                 },
//                 body: JSON.stringify(values),
//             });
//             if (!response.ok) throw (await response.json()).message;

//             return await response.json()
//         }
//         catch (ex) {
//             console.log("error", ex);
//             throw ex;
//         }
//     },

//     async delete(url) {
//         try {

//             console.log(url);
//             const response = await fetch(url, {
//                 method: "DELETE",
//                 headers: {
//                     "Content-Type": "application/json",
//                     "Authorization": "Bearer " + auth.getToken(),
//                 },
//             });
//             if (!response.ok) throw (await response.json()).message;

//             if (response.status === 204) return;
//             return await response.json()
//         }
//         catch (ex) {
//             console.log("error", ex);
//             throw ex;
//         }
//     },
// }

import * as crud from "../core/fetch";

const plugin = crud;
plugin.delete = crud.remove;

export default function install(Vue) {
  Object.defineProperties(Vue.prototype, {
    $fetch: {
      get() {
        return plugin;
      },
    },
  });
}
