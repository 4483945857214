<template>
  <div>
    <!-- v-model="selectedValue"
    ref="selectBox"
    :width="width"
    display-expr="texto"
    value-expr="texto"
    :data-source="dictionaryDataSource"
    :show-clear-button="true"
    :accept-custom-value="true"
    @customItemCreating="customItemCreatingHandler"
    :search-enabled="true"
    search-mode="contains"
    :search-expr="['texto']" -->
    <DxSelectBox
      :accept-custom-value="true"
      @customItemCreating="customItemCreatingHandler"
      v-model="selectedValue"
      :label="label"
      ref="selectBox"
      :width="width"
      :search-enabled="true"
      search-mode="contains"
      :search-expr="searchExpr"
      display-expr="texto"
      value-expr="texto"
      :data-source="dictionaryDataSource"
      :show-clear-button="true"
    >
      <DxSelectBoxButton name="clear"></DxSelectBoxButton>
      <DxSelectBoxButton :options="catalogButtonOptions" name="catalogButton" id="catalogButton" location="after" />
      <DxSelectBoxButton name="dropDown"></DxSelectBoxButton>

      <slot></slot>
    </DxSelectBox>

    <!--
  modificar el selectbox al hacer hidden (onHidden)
  ó
  modificar el selectbox al hacer updated o inserted de la grid?
-->
    <DxPopup
      ref="popup"
      :title="title"
      :wrapper-attr="{ class: 'popupScrollableContentWithoutPadding' }"
      :visible.sync="isPopupVisible"
      :fullScreen="false"
      @hidden="onHidden"
      :width="popupWidth ?? `600px`"
      height="auto"
    >
      <!-- <DxToolbarItem location="before" widget="dxButton" :options="addButtonOptions"> </DxToolbarItem> -->

      <DxToolbarItem location="before" template="addButtonTemplate"> </DxToolbarItem>

      <template #addButtonTemplate>
        <DxButton
          icon="add"
          type="default"
          stylingMode="contained"
          :hint="$t('agregar')"
          @click="() => $refs.dataGrid.instance.addRow()"
        >
        </DxButton>
      </template>

      <template>
        <!-- <div v-for="(columna, key) in columnas" :key="key">
          {{ key }}
          {{ columna["caption"] }}
          {{ columna["width"] }}
        </div> -->

        <div class="h-100 position-relative">
          <!-- :selectedRowKeys="selectedRowKeys" -->
          <DxDataGrid
            ref="dataGrid"
            :data-source="dictionaryDataSource"
            :columns="searchExpr"
            :hover-state-enabled="true"
            height="100%"
            width="100%"
            @selection-changed="onGridSelectionChanged"
            @row-dbl-click="onGridRowDblClick"
          >
            <DxColumn :visible="true" :allow-editing="false" width="17" />

            <DxColumn
              :visible="columnas !== undefined"
              v-for="(columna, key) in columnas"
              :key="key"
              :data-field="key"
              :width="columna['width']"
              :caption="columna['caption']"
            />
            <!-- <DxColumn data-field="codigo" width="70" /> -->
            <!-- <DxColumn data-field="descripcion" width="100%" /> -->

            <DxEditing :allow-updating="true" :allow-adding="false" :allow-deleting="true" mode="row"> </DxEditing>
            <DxSelection mode="single" />
            <!-- <DxPaging :enabled="true" :page-size="10" /> -->
            <DxFilterRow :visible="true" />
            <!-- <DxScrolling mode="virtual" /> -->
          </DxDataGrid>
        </div>
      </template>
    </DxPopup>
  </div>
</template>

<script>
import { DxButton } from "devextreme-vue/button";
import { DxSelectBox, DxButton as DxSelectBoxButton } from "devextreme-vue/select-box";
import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";

import {
  DxSelection,
  // DxPaging,
  // DxScrolling,
  DxFilterRow,
  DxDataGrid,
  DxEditing,
  DxColumn,
} from "devextreme-vue/data-grid";

import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

export default {
  props: {
    title: String,
    label: String,
    value: String,
    width: Number,
    height: Number,
    valueExpr: String,
    /**
     * Con este flag mejoramos el rendimiento.
     * Si autorefresh está en true el control refrescará datasource
     * y repintará el select box.
     * Si el autorefresh está en false (por defecto) el selectbox
     * no recargará ni repintará el datasource y se deja en manos del
     * componente padre hacer el repintado via el evento: dataSourceUpdated.
     * (evitando llamadas innecesarias a la api).
     * */
    autorefresh: Boolean,
    recurso: String, // nombre del recurso para hacer CRUD en la API.
  },

  data() {
    const addButtonOptions = {
      icon: "add",
      onClick: () => {
        this.$refs.dataGrid.instance.addRow();
      },
    };

    return {
      columnas: {
        texto: { caption: this.$t('texto'), width: "100%" },
      },
      popupWidth: 600,
      searchExpr: ["texto"],

      addButtonOptions,
      isPopupVisible: false,
      catalogButtonOptions: {
        elementAttr: {
          class: "catalogButton",
        },
        icon: "comment",
        onClick: () => {
          // this.$refs.selectBox.instance.open();
          // console.log("you have been clicked the button");
          this.isPopupVisible = true;
          this.$refs.selectBox.instance.close();
        },
      },

      dictionaryDataSource: new DataSource({
        store: new CustomStore({
          loadMode: "raw",
          key: "id",
          load: () => this.$fetch.get(`${global.API_URL}/${this.recurso}`),
          byKey: (key) => {
            if (!key) return Promise.resolve({});
            // this.log("byKey: ", key, "recurso: ", this.recurso, "url: ", `${global.API_URL}/${this.recurso}/${key}`);
            return this.$fetch.get(`${global.API_URL}/${this.recurso}/${key}`);
          },
          insert: (values) => {
            // this.somethingHasBeenUpdated = true;
            return this.$fetch.post(`${global.API_URL}/${this.recurso}`, values);
          },
          update: (key, values) => {
            // this.somethingHasBeenUpdated = true;
            return this.$fetch.put(`${global.API_URL}/${this.recurso}/${key}`, values);
          },
          remove: (key) => {
            // this.somethingHasBeenUpdated = true;
            return this.$fetch.delete(`${global.API_URL}/${this.recurso}/${key}`);
          },
          onModified: (e) => {
            // this.log("modified", e);
            this.$emit("dataSourceUpdated", e);

            // const aux = this.selectedValue;
            // this.selectedValue = null;
            // this.selectedValue = aux;
          },
        }),
      }),

      // flag para actualizar v0.1
      /**
       * No quiero estar emitiendo cambios en el datasource
       * si no ha habido ninguno.
       * @TODO: Mejorar flag para que se actualize correctamente
       * Actualmente, se pone a true cuando algo ha sido actualizado (ver customStore)
       * y se vuelve a poner en false cuando se ha emitido el evento.
       * Al ponerlo en true no se está verificando si el fetch ha ido correctamente y
       * podría darse el caso que no se necesitara emitir el evento dataSource:updated.
       *
       * */
      // somethingHasBeenUpdated: false,
      //
      // selectedRowKeys: [],
    };
  },

  components: {
    DxButton,
    DxSelectBox,
    DxSelectBoxButton,
    //
    DxPopup,
    DxToolbarItem,
    //
    DxSelection,
    // DxPaging,
    // DxScrolling,
    DxFilterRow,
    DxDataGrid,
    DxEditing,
    DxColumn,
  },

  methods: {
    log(...args) {
      console.log(...args);
    },
    async onHidden() {
      this.$refs.dataGrid.instance.selectRows([], false);

      if (this.autorefresh) {
        //dataSource != DataSource -> CustomStore.
        //this.dataSouce.reload() -> no existe
        // PERO
        //al asignar el CustomStore al SelectBox
        //si yo accedo al Datasource del SelectBox -> Datasource

        await this.$refs.selectBox.instance.getDataSource().reload();
        this.$refs.selectBox.instance.repaint();
      }

      // console.log("onHidden", this.somethingHasBeenUpdated);

      // if (this.somethingHasBeenUpdated) {
      //   this.$emit("dataSourceUpdated", this.$refs.selectBox.instance);
      //   this.somethingHasBeenUpdated = false;
      // }
    },

    onGridSelectionChanged(e) {
      if (e.selectedRowsData.length === 0) return;
      this.$refs.selectBox.instance.option("value", e.selectedRowsData[0].texto);

      setTimeout(() => {
        this.$refs.dataGrid.instance.selectRows([], false);
      }, 500);
    },

    onGridRowDblClick() {
      // this.$refs.selectBox.instance.option("value", e.data.id);
      //this.isPopupVisible = false;
      this.$refs.popup.instance.hide();
    },

    customItemCreatingHandler(e) {
      console.log("customItemCreatingHandler");
      e.customItem = { texto: e.text };
      this.selectedValue = e.text;
    },
  },

  computed: {
    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        console.log("set", value);
        // this.selectedRowKeys = value && [value];
        // this.$refs.dataGrid?.instance?.navigateToRow(value);

        this.$emit("input", value);
      },
    },
  },

  async mounted() {
    // this.selectedRowKeys = this.value && [this.value];
    // this.$refs.dataGrid?.instance?.navigateToRow(this.value);
  },
};
</script>

<style scoped>
/* .catalogButton .dx-icon {
  color: #aaa;
} */
</style>
