<template>
  <div>
    <div class="row">
      <div class="col-xl-6">
        <DxChart
          id="graficoDistribucion"
          ref="graficoDistribucion"
          v-if="true"
          :data-source="dataSourceDistribucion"
          @point-click="onClickDefecto"
          :rotated="true"
        >
          <!-- <DxTitle :text="$t('distribucion')" :subtitle="titulo"> </DxTitle> -->

          <DxTitle
            :text="$t('distribucion')"
            :subtitle="{
              text: titulo,
              font: {
                size: '2em',
              },
            }"
          >
          </DxTitle>

          <DxCommonSeriesSettings argument-field="defecto" />
          <DxCommonAxisSettings>
            <DxGrid :visible="false" />
          </DxCommonAxisSettings>

          <DxSeries value-field="cantidad" name="cantidad" type="bar" color="#fac29a">
            <DxLabel :visible="true" />
          </DxSeries>

          <DxTooltip :enabled="true" :shared="true" :customize-tooltip="customizeTooltip" />

          <DxLegend :visible="false" />
        </DxChart>
      </div>

      <div class="col-xl-6">
        <DxChart id="graficoFrecuencias" ref="graficoFrecuencias" v-if="true" :data-source="frecuencias">
          <DxArgumentAxis type="discrete"></DxArgumentAxis>

          <!-- <DxTitle :text="$t('frecuencias')" :subtitle="titulo"> </DxTitle> -->

          <DxTitle
            :text="$t('frecuencias')"
            :subtitle="{
              text: titulo,
              font: {
                size: '2em',
              },
            }"
          >
          </DxTitle>

          <DxCommonSeriesSettings argument-field="x" type="stackedbar" />
          <DxCommonAxisSettings>
            <DxGrid :visible="false" />
          </DxCommonAxisSettings>

          <DxSeries v-for="defecto in serieFrecuencias" :value-field="defecto" :name="defecto" :key="defecto" />

          <DxLegend vertical-alignment="bottom" horizontal-alignment="center" item-text-position="top" />
          <DxTooltip :enabled="true" :customize-tooltip="customizeTooltipFrecuencias" location="edge" />
        </DxChart>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxChart,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxLabel,
  DxLegend,
  DxSeries,
  DxTooltip,
  DxCommonAxisSettings,
  DxGrid,
  DxTitle,
} from "devextreme-vue/chart";

export default {
  components: {
    DxChart,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxLabel,
    DxLegend,
    DxSeries,
    DxTooltip,
    DxCommonAxisSettings,
    DxGrid,
    DxTitle,
  },

  props: ["defectos", "frecuencias", "serieFrecuencias", "titulo"],

  computed: {
    dataSourceDistribucion: function () {
      if (this.defectos === undefined) return [];

      const x = [...this.defectos];
      return x.sort(function (a, b) {
        return a.cantidad - b.cantidad;
      });
    },
  },

  methods: {
    customizeTooltip(pointInfo) {
      return {
        html: `<div><div class='tooltip-header'>${pointInfo.argumentText}</div><div class='value-text'><span class='top-series-value'>${pointInfo.points[0].valueText}</span></div`,
      };
    },

    customizeTooltipFrecuencias(pointInfo) {
      return {
        text: `${pointInfo.seriesName}  ${pointInfo.valueText}`,
      };
    },

    onClickDefecto({ target: point }) {
      this.$emit("defectoSelected", point.data.id_caracteristicamdef);
    },

    formatLabel(pointInfo) {
      return `${pointInfo.valueText} (${pointInfo.percentText})`;
    },
  },
};
</script>

<style scoped>
.botones {
  position: fixed;
  z-index: 9000;
  bottom: 50px;
  right: 50px;
}

.padding {
  height: 100px;
}

.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  margin-top: 20px;
}

.option {
  margin-top: 10px;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option > span {
  margin-right: 14px;
}

.option > .dx-widget {
  display: inline-block;
  vertical-align: middle;
}
</style>

<style>
#graficoX,
#graficoR {
  height: 500px;
  width: 100%;
}

.dxc-tooltip {
  z-index: 2000;
}

.row.plan-control {
  margin: 10px;
}

.row.caracteristica {
  margin: 10px;
}

/* .tabla-limites {
  position: absolute;
  top: 10px;
  right: 0;
} */

.total {
  background-color: #ccc;
}
</style>
