import * as localization from 'devextreme/localization';

export default function install(Vue) {
    Object.defineProperties(Vue.prototype, {
        $localization: {
            get() {
                return localization;
            }
        }
    })
}