<template>
  <div>
    <Loading v-if="loading"></Loading>
    <img ref="image" v-show="!loading" />
  </div>
</template>

<script>
import Loading from "./Loading.vue";
import { getArrayBuffer } from "@/core/fetch";

export default {
  data() {
    return {
      loading: true,
    };
  },
  props: ["url", "filename"],
  components: {
    Loading,
  },
  mounted() {
    this.setImgSrc(this.filename);
  },
  methods: {
    getDirectory() {
      //eliminar de user.database los dos primeros caracteres
      const directory = this.$store.state.user.database.substring(2);
      return directory;
    },

    getImageUrl(image) {
      const directory = this.getDirectory();
      return `${global.IMG_URL}/${directory}/${image}`;
    },

    setImgSrc(filename) {
      const url = this.getImageUrl(filename);

      getArrayBuffer(url)
        .then((data) => {
          if (this.$refs.image) this.$refs.image.src = URL.createObjectURL(data);
          this.loading = false;
        })
        .catch(() => {
          // console.log("----------------------------------------------------");
          if (this.$refs.image) this.$refs.image.src = require("@/assets/404.png");
        });
    },
  },
};
</script>

<style scoped>
div {
  height: 100%;
}

img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
</style>
