import notify from "devextreme/ui/notify";

export default {
  install(Vue) {
    /**
     *
     * @param {*} message mensaje a mostrar
     * @param {*} type (opcional) tipo de notificación ("info", "warning", "error" or "success").
     * @param {*} time (opcional) tiempo de duración de la notificación (por defecto 2000ms)
     */
    Vue.prototype.$notify = function (message, type = "success", time = 2000) {
      //   notify(
      //     {
      //       message: message,
      //       position: {
      //         my: "left bottom",
      //         at: "left bottom",
      //         of: "#app-container",
      //         offset: "16 -16",
      //       },
      //       minWidth: null,
      //       width: "auto",
      //     },
      //     type,
      //     time
      //   );
      notify(message, type, time);
    };
  },
};
