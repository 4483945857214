/* eslint-disable no-unused-vars */
import Prompt from "@/components/prompt.vue"

export default {
    install(Vue, options) {
        console.log("installing", options)


        var containerWrapper = document.createElement('div');
        containerWrapper.id = "prompt";
        document.body.appendChild(containerWrapper);

        // const myPrompt = new (Vue.extend(Prompt , options))
        const myPromptClass = Vue.extend(Prompt)
        const myPrompt = new myPromptClass(options)
        myPrompt.$mount("#prompt")


        Vue.prototype.$prompt = myPrompt.prompt

        return myPrompt;
    }
}
