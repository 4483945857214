<template>
  <div id="grafico">
    <h3>
      {{ operario?.nombre || "&nbsp;" }}
    </h3>

    <DxChart id="graficoX" ref="graficoX" :data-source="average">
      <!-- <DxTitle :text="'\u{0305}' + 'X'"> -->
      <DxTitle text="X̅">
        <!-- <DxFont family="monospace" size="2rem" /> -->
      </DxTitle>

      <DxCommonSeriesSettings type="line" argument-field="x" />
      <DxCommonAxisSettings>
        <DxGrid :visible="false" />
      </DxCommonAxisSettings>

      <DxSeries
        v-for="s in limites"
        :key="s.value + 'x'"
        :value-field="s.value + 'x'"
        :name="s.name"
        :color="s.color"
        :width="s.width"
        :dash-style="s.dashStyle"
      >
        <DxPoint :visible="false" />
      </DxSeries>

      <DxSeries key="value" value-field="value" name="X" color="black">
        <DxPoint symbol="square" />
      </DxSeries>

      <DxMargin :bottom="20" />
      <DxArgumentAxis :allow-decimals="false" :axis-division-factor="60">
        <DxLabel>
          <DxFormat type="decimal" />
        </DxLabel>
      </DxArgumentAxis>
      <DxLegend vertical-alignment="bottom" horizontal-alignment="center" />
      <DxExport :enabled="false" />
      <DxTooltip :enabled="true" />
    </DxChart>

    <DxChart id="graficoR" ref="graficoR" :data-source="range">
      <DxTitle text="R">
        <!-- <DxFont family="monospace" size="2rem" /> -->
      </DxTitle>

      <DxCommonSeriesSettings type="line" argument-field="x" />
      <DxCommonAxisSettings>
        <DxGrid :visible="false" />
      </DxCommonAxisSettings>

      <DxSeries
        v-for="s in limites"
        :key="s.value + 'r'"
        :value-field="s.value + 'r'"
        :name="s.name"
        color="blue"
        :width="s.width"
        :dash-style="s.dashStyle"
      >
        <DxPoint :visible="false" />
      </DxSeries>

      <DxSeries key="value" value-field="value" name="R" color="black">
        <DxPoint symbol="triangleDown" />
      </DxSeries>

      <DxMargin :bottom="20" />
      <DxArgumentAxis :allow-decimals="false" :axis-division-factor="60">
        <DxLabel>
          <DxFormat type="decimal" />
        </DxLabel>
      </DxArgumentAxis>
      <DxLegend vertical-alignment="bottom" horizontal-alignment="center" />
      <DxExport :enabled="false" />
      <DxTooltip :enabled="true" />
    </DxChart>
  </div>
</template>

<script>
import {
  DxChart,
  // DxFont,
  DxSeries,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxCommonAxisSettings,
  DxGrid,
  DxExport,
  DxLegend,
  DxMargin,
  DxTooltip,
  DxLabel,
  DxFormat,
  DxPoint,
  DxTitle,
} from "devextreme-vue/chart";

export default {
  props: ["operario", "lcsx", "lcix", "lcsr", "lcir"],

  components: {
    DxChart,
    // DxFont,
    DxSeries,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxCommonAxisSettings,
    DxGrid,
    DxExport,
    DxLegend,
    DxMargin,
    DxTooltip,
    DxLabel,
    DxFormat,
    DxPoint,
    DxTitle,
  },

  computed: {
    average: function () {
      return this.operario?.average?.map((o, i) => {
        return {
          x: i + 1,
          value: o,
          lcsx: this.lcsx,
          lcix: this.lcix,
        };
      });
    },

    range: function () {
      return this.operario?.range?.map((o, i) => {
        return {
          x: i + 1,
          value: o,
          lcsr: this.lcsr,
          lcir: this.lcir,
        };
      });
    },

    // datasourceX: function () {
    //   console.log(this.operario);
    //     return this.operario[0].average;
    //   },
    //   datasourceR: function () {
    //     return this.operario[0].range;
    //   },
    limites: function () {
      return [
        {
          value: "lcs",
          name: this.$t("lc+"),
          color: "green",
          dashStyle: "line",
          width: 1,
        },

        {
          value: "lci",
          name: this.$t("lc-"),
          color: "green",
          dashStyle: "line",
          width: 1,
        },
      ];
    },
  },
};
</script>

<style></style>
