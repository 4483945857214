import { get } from "@/core/fetch";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

export class CustomStoreModulos extends CustomStore {
  constructor() {
    const baseUrl = `${global.API_URL}/modulos`;
    super({
      key: "id",
      loadMode: "raw",
      // cacheRawData: false,

      //load: () => get(`${baseUrl}`),
      /*
        when using the following previous line: load: () => get(`${baseUrl}`),
        the custom_store throws: Uncaught (in promise) TypeError: rawData is null
        there is an answes in the devextreme forum that suggests to respond always with an array:
        https://supportcenter.devexpress.com/ticket/details/t814107/using-customstore-as-datagrid-lookup-column-with-loadmode-raw-causes-error
       */

      // this is copylot showing his developer abilities:
      // load: () => get(`${baseUrl}`).then((data) => (Array.isArray(data) ? data : [])),

      // this is the same as the previous one, but using async/await
      load: async () => (await get(`${baseUrl}`)) ?? [],

      //byKey: (key) => get(`${baseUrl}/${key}`),
      //insert: (values) => post(`${baseUrl}`, values),
      //update: (key, values) => put(`${baseUrl}/${key}`, values),
      //remove: (key) => remove(`${baseUrl}/${key}`),
    });
  }
}

export class DataSourceModulos extends DataSource {
  constructor() {
    const store = new CustomStoreModulos();
    super({
      store,
    });
  }
}
