<template>
  <div>
    <div class="row">
      <div class="col-xl-6">
        <DxChart
          id="graficoLeft"
          ref="graficoLeft"
          v-if="true"
          :data-source="datasources.left"
          @legend-click="onLegendClick"
          @point-click="onPointClick"
          @initialized="onInitialized"
          @done="onDone"
        >
          <DxTitle :text="titulos.left" :subtitle="subtitulos.left"> </DxTitle>

          <DxCommonSeriesSettings argument-field="x" />
          <DxCommonAxisSettings>
            <DxGrid :visible="false" />
          </DxCommonAxisSettings>

          <DxSeries
            v-for="s in series"
            :key="s.value"
            :value-field="s.value"
            :name="s.name"
            :color="s.color"
            :width="s.width"
            :dash-style="s.dashStyle"
            :type="s.type"
          >
            <DxPoint :visible="false" />
          </DxSeries>

          <DxSeries key="value" value-field="value" :name="titulos.left" color="black" type="line">
            <DxPoint symbol="square" />
          </DxSeries>

          <DxMargin :bottom="20" />

          <DxArgumentAxis :allow-decimals="false" :axis-division-factor="60">
            <DxLabel>
              <DxFormat type="decimal" />
            </DxLabel>
          </DxArgumentAxis>
          <DxLegend vertical-alignment="bottom" horizontal-alignment="center" />
          <DxExport :enabled="false" />
          <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip" :z-index="2003" />
        </DxChart>

        <DxPopup
          class="popupScrollableContentWithoutPadding"
          :visible.sync="popupVisible"
          :drag-enabled="false"
          :close-on-outside-click="true"
          :show-title="true"
          :width="350"
          :height="500"
          :shading="true"
          :title="valor ? valor.toFixed(4) : ''"
        >
          <DxPosition my="center" at="left top" of="window" collision="fit fit" boundaryOffset="0 0">
            <DxOffset :x="popupOffsetX" :y="popupOffsetY"></DxOffset>
          </DxPosition>

          <DxScrollView>
            <div class="d-flex flex-column caracteristica px-2 py-4">
              <div>
                <label class="form-label">{{ $t("fechaAnalisis") }}</label>
                <div class="form-data">
                  {{ fecha ? this.$moment(fecha).format("L") : "" }}
                  a las
                  {{ fecha ? this.$moment(fecha).format("LTS") : "" }}
                </div>
              </div>

              <div>
                <label class="form-label">{{ $t("defectos") }} ({{ $t("def") }})</label>
                <div class="form-data">{{ defectos }}</div>
              </div>

              <div>
                <label class="form-label">{{ $t("tamanoMuestra") }} ({{ $t("tm") }})</label>
                <div class="form-data">{{ tamanoMuestra }}</div>
              </div>

              <div>
                <label class="form-label">{{ $t("def") }}/{{ $t("tm") }}</label>
                <div class="form-data">
                  {{ tamanoMuestra && (defectos / tamanoMuestra).toFixed(4) }}
                </div>
              </div>
            </div>
          </DxScrollView>

          <DxToolbarItem
            :options="{
              text: $t('editar'),
              type: 'normal',
              onClick: onClickEdit,
            }"
            widget="dxButton"
            location="before"
            toolbar="bottom"
          />

          <DxToolbarItem
            :options="{
              text: $t('ocultar'),
              type: 'normal',
              onClick: onClickHide,
            }"
            widget="dxButton"
            location="after"
            toolbar="bottom"
          />

          <DxToolbarItem
            :options="{
              text: $t('eliminar'),
              type: 'danger',
              stylingMode: 'contained',
              onClick: onClickDelete,
            }"
            widget="dxButton"
            location="after"
            toolbar="bottom"
          />
        </DxPopup>

        <DxPopup
          class="popupAnalisis popupScrollableContentWithoutPadding"
          title="Edición del punto"
          :visible.sync="popupEditVisible"
          :fullScreen="false"
        >
          <template>
            <DxScrollView width="100%" height="100%" :useNative="false">
              <div>
                <Analisis
                  ref="analisis"
                  v-if="popupEditVisible"
                  :analisis="analisisSeleccionado"
                  :caracteristica="info.caracteristica"
                  :planID="info.plan.id"
                  @updated="analisisUpdated()"
                  @error="analisisError()"
                  @cancelled="analisisCancelled()"
                ></Analisis>
              </div>
            </DxScrollView>
          </template>

          <DxToolbarItem
            :options="{
              text: 'Cancelar',
              type: 'normal',
              onClick: () => {
                this.$refs.analisis.cancel();
              },
            }"
            widget="dxButton"
            location="after"
            toolbar="bottom"
          />

          <DxToolbarItem
            :options="{
              text: 'Actualizar',
              stylingMode: 'contained',
              icon: 'save',
              type: 'default',
              onClick: (e) => {
                e.validationGroup.validate().isValid && this.$refs.analisis.update();
              },
            }"
            widget="dxButton"
            location="after"
            toolbar="bottom"
          />
        </DxPopup>

        <div class="d-flex flex-row justify-content-end caracteristica">
          <table class="tabla-resumen table table-bordered">
            <tbody>
              <tr>
                <!-- <th scope="col">Σdef : Σtm</th> -->
                <th scope="col">{{ $t("piezasDefectuosas") }}:{{ $t("tamanoMuestra") }}</th>
                <td scope="col" class="values">
                  {{ ΣdefcolonΣtm }}
                </td>
              </tr>
              <tr>
                <th scope="row">{{ $t("porcentaje") }}</th>
                <td>{{ percentTotal }}%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="col-xl-6">
        <DxChart
          id="graficoRight"
          ref="graficoRight"
          v-if="true"
          :data-source="datasources.right"
          @legend-click="onLegendClick"
          @point-click="onPointClick"
        >
          <DxTitle :text="titulos.right" :subtitle="subtitulos.right"> </DxTitle>

          <DxCommonSeriesSettings argument-field="x" />
          <DxCommonAxisSettings>
            <DxGrid :visible="false" />
          </DxCommonAxisSettings>

          <DxSeries
            v-for="s in series"
            :key="s.value"
            :value-field="s.value"
            :name="s.name"
            :color="s.color"
            :width="s.width"
            :type="s.type"
            :dash-style="s.dashStyle"
          >
            <DxPoint :visible="false" />
          </DxSeries>

          <DxSeries key="value" value-field="value" type="line" :name="titulos.right" color="black">
            <DxPoint symbol="triangleDown" />
          </DxSeries>

          <DxMargin :bottom="20" />
          <DxArgumentAxis :allow-decimals="false" :axis-division-factor="60">
            <DxLabel>
              <DxFormat type="decimal" />
            </DxLabel>
          </DxArgumentAxis>
          <DxLegend vertical-alignment="bottom" horizontal-alignment="center" />
          <DxExport :enabled="false" />
          <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip" :z-index="2003" />
        </DxChart>
      </div>
    </div>
  </div>
</template>

<script>
import auth from "@/auth";
import { confirm } from "devextreme/ui/dialog";

import Analisis from "../../autocontrol/Analisis.vue";

import { DxPopup, DxPosition, DxOffset, DxToolbarItem } from "devextreme-vue/popup";
import { DxScrollView } from "devextreme-vue/scroll-view";

import {
  DxChart,
  DxSeries,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxCommonAxisSettings,
  DxGrid,
  DxExport,
  DxLegend,
  DxMargin,
  DxTooltip,
  DxLabel,
  DxFormat,
  DxPoint,
  DxTitle,
} from "devextreme-vue/chart";

export default {
  props: [
    "info",
    "caracteristica",
    "planID",
    "titulos",
    "subtitulos",
    "datasources",
    "ocultos",
    "totales",
    "caracteristicaId",
    "analisis",
  ],

  data() {
    return {
      series: [
        {
          value: "lcs",
          name: this.$t("lc+"),
          color: "green",
          dashStyle: "solid",
          width: 1,
          type: "stepline",
        },
        {
          value: "lc",
          name: this.$t("lc"),
          color: "green",
          dashStyle: "dash",
          width: 1,
          type: "line",
        },
        {
          value: "lci",
          name: this.$t("lc-"),
          color: "green",
          dashStyle: "solid",
          width: 1,
          type: "stepline",
        },
      ],

      // Valores del punto seleccionado (crear un objeto?)o
      fecha: null,
      valor: null,
      defectos: null,
      tamanoMuestra: null,
      puntoSeleccionado: null,
      analisisSeleccionado: null,

      popupVisible: false,
      popupOffsetX: 0,
      popupOffsetY: 0,

      popupEditVisible: false,
    };
  },

  async mounted() {
    // console.log("%cGrafico Limites mounted!!!", "background-color:green");
  },

  computed: {
    sumTD: function () {
      return this.totales.sumTD;
    },
    sumTM: function () {
      return this.totales.sumTM;
    },

    ΣdefcolonΣtm: function () {
      if (!(this.sumTD && this.sumTM)) return;
      return `${this.sumTD} : ${this.sumTM}`;
    },
    percentTotal: function () {
      if (!(this.sumTD && this.sumTM)) return;
      return ((100 * this.sumTD) / this.sumTM).toFixed(4);
    },
  },
  components: {
    // DxSelectBox,
    DxChart,
    DxSeries,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxCommonAxisSettings,
    DxGrid,
    DxExport,
    DxLegend,
    DxMargin,
    DxTooltip,
    DxLabel,
    DxFormat,
    DxPoint,
    DxTitle,
    DxPopup,
    DxPosition,
    DxOffset,
    DxToolbarItem,
    DxScrollView,
    Analisis,

    //TheFooter,
  },
  methods: {
    analisisCancelled() {
      this.closePopup();
    },

    closePopup() {
      this.popupEditVisible = false;
      // this.popupVisible = false;
    },

    closeAll() {
      this.popupVisible = false;
      this.popupEditVisible = false;
    },
    analisisUpdated() {
      this.$emit("updated");

      this.closePopup();
    },

    analisisError() {
      this.$notify(this.$t("error_al_guardar_analisis"), "error", 2000);
    },

    customizeTooltip(pointInfo) {
      return { text: pointInfo.value.toFixed(4) };
    },

    onInitialized() {},

    onDone() {},

    async onPointClick(e) {
      const point = e.target;

      const graficoLeftInstance = this.$refs.graficoLeft.instance;
      const graficoRightInstance = this.$refs.graficoRight.instance;

      graficoRightInstance.getSeriesByName(this.titulos.right).getPointsByArg(point.data.x)[0].select();
      graficoLeftInstance.getSeriesByName(this.titulos.left).getPointsByArg(point.data.x)[0].select();

      this.popupVisible = true;

      this.puntoSeleccionado = point; //.data.analisis;
      this.analisisSeleccionado = this.analisis.get(point.data.id_analisis);

      const graficoRect = e.element.getBoundingClientRect();
      this.popupOffsetX = point.x + graficoRect.x;
      this.popupOffsetY = point.y + graficoRect.y;

      this.fecha = this.analisis.get(point.data.id_analisis).fecha;

      this.valor = this.puntoSeleccionado.value;
      this.defectos = this.analisisSeleccionado.total_defectuosas;
      this.tamanoMuestra = this.analisisSeleccionado.tamano_muestra;

      //buscar los valores valoresMDef
      if (this.info.caracteristica.id_metodoanalisis === 2) {
        const response = await fetch(
          `${global.API_URL}/caracteristicas/${this.caracteristicaId}/analisis/${point.data.id_analisis}/valores/MDef`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + auth.getToken(),
            },
          }
        );
        const data = await response.json();

        // coloco los valores y el metodo analisis en el analisis seleccionado
        // this.analisisSeleccionado._valores = data.map((e) => ({
        //   id_caracteristicamdef: e.id_caracteristicamdef,
        //   cantidad: e.cantidad,
        // }));

        this.$set(
          this.analisisSeleccionado,
          "_valores",
          data.map((e) => ({
            id_caracteristicamdef: e.id_caracteristicamdef,
            cantidad: e.cantidad,
          }))
        );

        this.analisisSeleccionado._metodoanalisis = 2;
      }
    },

    onLegendClick({ target: series }) {
      if (series.isVisible()) {
        series.hide();
      } else {
        series.show();
      }
    },

    async onClickEdit() {
      if (!this.puntoSeleccionado) return;

      this.popupVisible = false;
      this.popupEditVisible = true;
    },

    async onClickHide() {
      if (!this.puntoSeleccionado) return;

      const update = {
        ocultar: true,
      };

      const url = `${global.API_URL}/caracteristicas/${this.caracteristicaId}/analisis/${this.puntoSeleccionado.data.id_analisis}`;

      try {
        const response = await fetch(url, {
          method: "PATCH",
          mode: "cors",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.getToken(),
          },
          body: JSON.stringify(update),
        });
        if (response.ok) {
          this.$emit("updated", {
            analisisId: this.puntoSeleccionado.data.id_analisis,
          });
          this.$notify(this.$t("analisis_ocultado_correctamente"));
        } else {
          this.$notify(this.$t("error_al_ocultar"), "error");
        }
      } catch (ex) {
        this.$notify(this.$t("error_al_ocultar"), "error");
      }

      // this.$emit("updated", {
      //   analisisId: this.puntoSeleccionado.data.id_analisis,
      // });

      //this.clearSelection(this.puntoSeleccionado);
      this.popupVisible = false;
      // this.clear();
    },

    async onClickDelete() {
      if (!this.puntoSeleccionado) return;

      let result = await confirm("Estas seguro que quieres borrar el analisis seleccionado", "Borrar analisis");

      if (result) {
        try {
          const response = await fetch(
            `${global.API_URL}/caracteristicas/${this.caracteristicaId}/analisis/${this.puntoSeleccionado.data.id_analisis}`,
            {
              method: "delete",
              mode: "cors",
              cache: "no-cache",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + auth.getToken(),
              },
            }
          );
          if (response.ok) {
            this.$emit("deleted", {
              analisisId: this.puntoSeleccionado.data.id_analisis,
            });
            this.$notify(this.$t("analisis_borrado_correctamente"));
          } else {
            console.error("GraficoLimites::onClickDelete");
            this.$notify(this.$t("error_al_borrar_analisis"));
          }
        } catch (ex) {
          console.error("GraficoLimites::onClickDelete", ex);
          this.$notify(this.$t("error_al_borrar_analisis"));
        }

        this.popupVisible = false;
      }
    },
  },
};
</script>

<style scoped>
.botones {
  position: fixed;
  z-index: 9000;
  bottom: 50px;
  right: 50px;
}

.padding {
  height: 100px;
}

.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  margin-top: 20px;
}

.option {
  margin-top: 10px;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option > span {
  margin-right: 14px;
}

.option > .dx-widget {
  display: inline-block;
  vertical-align: middle;
}

#graficoX,
#graficoR {
  height: 500px;
  width: 100%;
}

.dxc-tooltip {
  z-index: 400;
}

.row.plan-control {
  margin: 10px;
}

.row.caracteristica {
  margin: 10px;
}

.tabla-resumen {
  width: auto;
  text-align: right;
  margin-top: 27px;
  right: 0;
}

.tabla-resumen .values {
  width: 170px;
}

.total {
  background-color: #ccc;
}

.dx-swatch-additional .dxc-title text {
  fill: white !important;
}

@media print {
  .dxc-title text {
    fill: black;
  }
}
</style>
