<template>
  <div id="reportVAR">
    <div class="reportContent">
      <h2>{{ $t("graficoPorAtributos") }}: {{ $t("defecto") }}</h2>

      <div class="d-flex flex-row pt-2 pb-4" style="gap: 50px">
        <div class="d-flex flex-column plan-control">
          <label class="form-label fw-bold">{{ $t("planControl") }}</label>

          <label class="form-label">{{ $t("referencia") }} / {{ $t("pieza") }} /
            {{ $t("fase") }}</label>
          <label>
            {{ info.plan.referencia }} / {{ info.plan.nombre }} /
            {{ info.estructura.descripcion }}
          </label>
        </div>

        <div class="d-flex flex-column caracteristica">
          <label class="form-label fw-bold">{{ $t("caracteristica") }}</label>

          <label class="form-label">{{ $t("numero") }} / {{ $t("nombre") }} /
            {{ $t("especificacion") }}
          </label>

          <div class="form-data">
            {{ info.caracteristica.id }} / {{ info.caracteristica.nombre }} /
            <div
              style="display: inline-block"
              v-html="info.caracteristica.especificacion"></div>
          </div>
        </div>
      </div>

      <img :src="dataImageX" width="100%" @load="ImageXLoad" />

      <table width="100%" border="1px solid black">
        <thead>
          <tr>
            <th>{{ $t("lote") }}</th>
            <th>{{ $t("fechaHora") }}</th>
            <th>{{ $t("operario") }}</th>
            <th>{{ $t("piezasDefectuosas") }}</th>
            <th>{{ $t("tamanoMuestra") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(analisis, i) in info.analisis" :key="i">
            <td>{{ analisis.lote }}</td>
            <td>
              {{ $moment(analisis.fecha).format("L") }}
              {{ $moment(analisis.fecha).format("LTS") }}
            </td>
            <td>{{ analisis.operario }}</td>
            <td>{{ analisis.total_defectuosas }}</td>
            <td>{{ analisis.tamano_muestra }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import jspdf from "jspdf";
import html2canvas from "html2canvas";

export default {
  props: ["info", "metodoAnalisis"],

  data() {
    return {
      loaded: false,
      dataImageX: null,
    };
  },

  methods: {
    print: async function (el) {

      const fillColor = el.querySelector(".dxc-title text").style.fill

      // cambiar color para imprimir
      el.querySelectorAll(".dxc-title text").forEach(x => {
        x.style.setProperty('fill', "rgb(0, 0, 0)", "important")
      })

      const canvas = await html2canvas(el);
      this.dataImageX = canvas.toDataURL();

      // volver al color anterior
      el.querySelectorAll(".dxc-title text").forEach(x => {
        x.style.setProperty('fill', fillColor)
      })

    },

    ImageXLoad: function () {
      const doc = new jspdf({ unit: "px", hotfixes: ["px_scaling"] });
      doc.html(this.$el.innerHTML, {
        callback: function (doc) {
          //doc.save();
          window.open(doc.output("bloburl"), "_blank");
        },
      });
    },
  },
};
</script>


<style>
.reportContent {
  width: 19cm;
  padding: 0.5cm;
  margin: 1cm;
  box-sizing: border-box;
}
</style>