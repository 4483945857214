<template>
  <div v-if="cadencia && frecuencia">
    <span v-if="isOtros">{{ otros }}</span>
    <span v-if="isNumeroDeUsos">Cada {{ frecuencia }} usos</span>
    <span v-if="!isNumeroDeUsos && !isOtros"
      >Cada {{ frecuencia }} {{ dataSourceCadenciasInstrumento.find((x) => x.id == cadencia)?.value }}</span
    >
  </div>
</template>

<script>
import { OTROS, NUMERO_DE_USOS } from "@/core/cadencias/constantes";
import cadencias from "@/data/instrumentos/Cadencias";

export default {
  props: ["cadencia", "frecuencia", "otros"],

  computed: {
    isOtros() {
      return this.cadencia === OTROS;
    },
    isNumeroDeUsos() {
      return this.cadencia === NUMERO_DE_USOS;
    },
    dataSourceCadenciasInstrumento() {
      return cadencias.map((e) => ({
        ...e,
        value: this.$t(e.value),
      }));
    },
  },
};
</script>
