import auth from "@/auth";

const handleError = async (response) => {
  try {
    const errorInfo = await response.json();
    throw errorInfo.message;
  } catch (ex) {
    throw new Error(ex) ?? "error, la respuesta no ha sido ok (y no hay informacón en el body)";
  }
};

export const get = async (url) => {
  try {
    // console.log("get" , url);
    const response = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
    });
    // if (!response.ok) throw (await response.json()).message;
    if (!response.ok) return handleError(response);

    const data = await response.json();
    return data;
  } catch (ex) {
    console.error("error", ex);
    throw ex;
  }
};

export const getArrayBuffer = async (url) => {
  try {
    // console.log("getArrayBuffer" , url);
    const response = await fetch(url, {
      headers: {
        Authorization: "Bearer " + auth.getToken(),
      },
    });
    if (!response.ok) return handleError(response);
    return await response.blob();
  } catch (ex) {
    console.error("error", ex);
    throw ex;
  }
};

export const download = async (url) => {
  try {
    // console.log("getArrayBuffer" , url);
    const response = await fetch(url, {
      headers: {
        Authorization: "Bearer " + auth.getToken(),
      },
    });
    if (!response.ok) return handleError(response);

    const originalName = response.headers.has("x-filename") ? response.headers.get("x-filename") : "download";
    const blob = await response.blob();

    return { originalName, blob };
  } catch (ex) {
    console.error("error", ex);
    throw ex;
  }
};

export const post = async (url, values) => {
  try {
    // console.log("post" , url , data);

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
      body: JSON.stringify(values),
    });
    if (!response.ok) return handleError(response);

    if (response.status === 204) return;
    return await response.json();
  } catch (ex) {
    console.error(ex);
    throw ex;
  }
};

export const postMultipart = async (url, data) => {
  try {
    // console.log("postMultipart" , url , data);

    const response = await fetch(url, {
      method: "POST",
      headers: {
        // NO PONER!!!!!!
        // "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + auth.getToken(),
      },
      body: data,
    });
    // if (!response.ok) throw (await response.json()).message;
    if (!response.ok) return handleError(response);

    return await response.json();
  } catch (ex) {
    console.error("error", ex);
    throw ex;
  }
};

export const put = async (url, values) => {
  try {
    // console.log("put" , url , values);
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
      body: JSON.stringify(values),
    });
    // if (!response.ok) throw (await response.json()).message;
    if (!response.ok) return handleError(response);

    return await response.json();
  } catch (ex) {
    console.error("error", ex);
    throw ex;
  }
};

export const remove = async (url) => {
  try {
    // console.log("remove" , url);
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
    });
    // if (!response.ok) throw (await response.json()).message;
    if (!response.ok) return handleError(response);

    if (response.status === 204) return;
    return await response.json();
  } catch (ex) {
    console.error("error", ex);
    throw ex;
  }
};
