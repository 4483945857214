<template>
  <div>
    <div class="d-flex align-items-center mt-4 gap-1">
      <div class="text-center" style="writing-mode: vertical-lr; text-orientation: mixed; transform: scale(-1, -1)">
        {{ $t("verificacion") }}
      </div>
      <div
        class="border border-3 border-top-0 border-end-0 border-bottom-0 ps-3"
        style="border-color: var(--accent) !important"
      >
        <div class="d-flex flex-wrap gap-3">
          <Display
            :label="$t('cadencia_verificacion')"
            :value="dataSourceCadenciasInstrumento.find((x) => x.id === instrumento?.cadencia_verificacion)?.value"
          />

          <Display
            v-if="!isOtros"
            :label="$t('frecuencia_verificacion')"
            :value="instrumento?.frecuencia_verificacion"
          />

          <Display
            :label="$t('fecha_ultima_verificacion')"
            :value="
              instrumento?.fecha_ultima_verificacion && $moment(instrumento?.fecha_ultima_verificacion).format('L')
            "
          />

          <Display
            v-if="!isNumeroDeUsos"
            :label="$t('fecha_proxima_verificacion')"
            :value="
              instrumento?.fecha_proxima_verificacion && $moment(instrumento?.fecha_proxima_verificacion).format('L')
            "
          />

          <Display
            v-if="isNumeroDeUsos"
            :label="$t('numero_usos_verificacion')"
            :value="instrumento?.numero_usos_verificacion"
          />
        </div>

        <Display
          class="mt-2"
          :label="$t('texto_otros_verificacion')"
          :value="instrumento?.texto_otros_verificacion"
          :full="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { OTROS, NUMERO_DE_USOS } from "@/core/cadencias/constantes";
import Display from "../core/Display.vue";
import cadencias from "@/data/instrumentos/Cadencias";

export default {
  props: ["instrumento"],

  computed: {
    isOtros() {
      return this.instrumento?.cadencia_verificacion === OTROS;
    },
    isNumeroDeUsos() {
      return this.instrumento?.cadencia_verificacion === NUMERO_DE_USOS;
    },
    dataSourceCadenciasInstrumento() {
      return cadencias.map((e) => ({
        ...e,
        value: this.$t(e.value),
      }));
    },
  },
  components: { Display },
};
</script>
