<template>
  <div>
    <DxTreeList
      id="estructura"
      ref="estructura"
      :root-value="null"
      :data-source="datasource"
      :show-row-lines="false"
      :show-borders="true"
      :auto-expand-all="true"
      :expanded-row-keys="expandedRowKeys"
      :columnAutoWidth="false"
      height="auto"
      key-expr="id"
      data-structure="plain"
      parent-id-expr="parent_pcestructura"
      :selectedRowKeys="selectedRowKeys"
      @selectionChanged="
        (event) => {
          this.$emit('update:selectedRowKeys', event.component.getSelectedRowKeys('leavesOnly'));
        }
      "
    >
      <DxSelection :recursive="true" mode="multiple" />
      <!-- <DxColumn data-field="tipo" caption="Tipo" width="70" /> -->
      <DxColumn data-field="descripcion" :caption="$t('descripcion')" cellTemplate="itemTemplate" />

      <DxScrolling mode="standard" />

      <template #itemTemplate="{ data: options }">
        <div class="align-middle">
          <img class="px-2" style="height: 16px" :src="require('@/assets/tipos/' + options.data.tipo + '.png')" />
          <span class="align-middle">{{ options.data.descripcion }}</span>
        </div>
      </template>
    </DxTreeList>
  </div>
</template>

<script>
import {
  DxTreeList,
  DxSelection,
  DxColumn,
  DxScrolling,
  // DxPaging,
} from "devextreme-vue/tree-list";
import auth from "../../auth";

const tipo = {};

export default {
  props: ["selectedPlanID", "selectedRowKeys"],
  data() {
    return {
      tipo,
      datasource: {},
      expandedRowKeys: [],
    };
  },

  async mounted() {
    const ds = this.$refs.estructura.instance.getDataSource();
    ds.paginate(false);
    ds.load();
  },

  watch: {
    selectedPlanID: {
      immediate: true,
      handler: async function (val) {
        try {
          //al montar la página se carga el combo con los planes!
          const response = await fetch(`${global.API_URL}/planes/${val}/estructura`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + auth.getToken(),
            },
          });
          const data = await response.json();
          if (response.ok) {
            // this.datasource = {
            //   paginate: false,
            //   store: data,
            // };
            this.datasource = data;

            this.expandedRowKeys = data.map((item) => item.id);
          } else {
            const mensaje = data.error;
            this.$notify(mensaje, "error", 5000);

            this.datasource = {};
          }
        } catch (ex) {
          const mensaje = `Ha habido un error al intentar obtener las estructuras de los planes`;
          this.$notify(mensaje, "error", 5000);

          this.datasource = {};
        }
      },
    },
  },

  components: {
    DxColumn,
    DxTreeList,
    DxSelection,
    DxScrolling,
  },
};
</script>

<style>
.dx-treelist .dx-row > td {
  padding: 8px;
}
</style>
