<template>
  <div id="reportVAR">
    <div class="reportContent">
      <h2>{{ $t("graficoVariables") }}: {{ metodoAnalisis.codigo }}</h2>

      <div class="d-flex flex-row pt-2 pb-4" style="gap: 50px">
        <div class="d-flex flex-column plan-control">
          <label class="form-label fw-bold">{{ $t("planControl") }}</label>

          <label class="form-label">{{ $t("referencia") }} / {{ $t("pieza") }} / {{ $t("fase") }}</label>
          <label>
            {{ info.plan.referencia }} / {{ info.plan.nombre }} /
            {{ info.estructura.descripcion }}
          </label>
        </div>

        <div class="d-flex flex-column caracteristica">
          <label class="form-label fw-bold">{{ $t("caracteristica") }}</label>

          <label class="form-label"
            >{{ $t("numero") }} / {{ $t("nombre") }} /
            {{ $t("especificacion") }}
          </label>

          <div class="form-data">
            {{ info.caracteristica.id }} / {{ info.caracteristica.nombre }} /
            <div style="display: inline-block" v-html="info.caracteristica.especificacion"></div>
          </div>
        </div>
      </div>

      <img :src="dataImageX" width="100%" @load="ImageXLoad" />

      <div class="row">
        <div class="col">
          <table width="50%" class="tabla-limites table table-jspdf-fix table-bordered table-sm">
            <thead>
              <th>{{ $t("limitesControlX") }}</th>
              <th>{{ $t("superior") }}</th>
              <th>{{ $t("central") }}</th>
              <th>{{ $t("inferior") }}</th>
            </thead>
            <tbody>
              <tr
                v-bind:class="{
                  'table-success': info.caracteristica.lcsx !== null,
                }"
              >
                <th>{{ $t("caracteristica") }}</th>
                <td>
                  {{ info.caracteristica.lcsx != undefined ? info.caracteristica.lcsx.toFixed(4) : "" }}
                </td>
                <td>
                  {{ info.caracteristica.lcx != undefined ? info.caracteristica.lcx.toFixed(4) : "" }}
                </td>
                <td>
                  {{ info.caracteristica.lcix != undefined ? info.caracteristica.lcix.toFixed(4) : "" }}
                </td>
              </tr>
              <tr
                v-if="info.limites_calculados"
                v-bind:class="{
                  'table-success': info.caracteristica.lcsx === null,
                }"
              >
                <th>{{ $t("calculados") }}</th>
                <td>
                  {{ info.limites_calculados.lcsx ? info.limites_calculados.lcsx.toFixed(4) : "" }}
                </td>
                <td>
                  {{ info.limites_calculados.lcx ? info.limites_calculados.lcx.toFixed(4) : "" }}
                </td>
                <td>
                  {{ info.limites_calculados.lcix ? info.limites_calculados.lcix.toFixed(4) : "" }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="col">
          <table width="50%" class="tabla-limites table table-jspdf-fix table-bordered table-sm">
            <thead>
              <th>{{ $t("limitesControlR") }}</th>
              <th>{{ $t("superior") }}</th>
              <th>{{ $t("central") }}</th>
              <th>{{ $t("inferior") }}</th>
            </thead>
            <tbody>
              <tr
                v-bind:class="{
                  'table-success': info.caracteristica.lcsr !== null,
                }"
              >
                <th>{{ $t("caracteristica") }}</th>
                <td>
                  {{ info.caracteristica.lcsr != undefined ? info.caracteristica.lcsr.toFixed(4) : "" }}
                </td>
                <td>
                  {{ info.caracteristica.lcr != undefined ? info.caracteristica.lcr.toFixed(4) : "" }}
                </td>
                <td>
                  {{ info.caracteristica.lcir != undefined ? info.caracteristica.lcir.toFixed(4) : "" }}
                </td>
              </tr>
              <tr
                v-if="info.limites_calculados"
                v-bind:class="{
                  'table-success': info.caracteristica.lcsr === null,
                }"
              >
                <th>{{ $t("calculados") }}</th>
                <td>
                  {{ info.limites_calculados.lcsr ? info.limites_calculados.lcsr.toFixed(4) : "" }}
                </td>
                <td>
                  {{ info.limites_calculados.lcr ? info.limites_calculados.lcr.toFixed(4) : "" }}
                </td>
                <td>
                  {{ info.limites_calculados.lcir ? info.limites_calculados.lcir.toFixed(4) : "" }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <table width="100%" border="1px solid black">
        <thead>
          <tr>
            <th>{{ $t("lote") }}</th>
            <th>{{ $t("fechaHora") }}</th>
            <th>{{ $t("operario") }}</th>
            <th>{{ $t("tamanoMuestra") }}</th>
            <th>{{ metodoAnalisis.codigo }}</th>
            <th>R</th>
            <th>{{ $t("maximo") }}</th>
            <th>{{ $t("minimo") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(punto, i) in info.puntos.x" :key="i">
            <td>{{ punto.analisis.lote }}</td>
            <td>
              {{ $moment(punto.analisis.fecha).format("L") }}
              {{ $moment(punto.analisis.fecha).format("LTS") }}
            </td>
            <td>{{ punto.analisis.operario }}</td>
            <td>{{ punto.analisis.tamano_muestra }}</td>
            <td>{{ punto.value && punto.value.toFixed(2) }}</td>
            <!-- <td>{{ info.puntos.r[i].value.toFixed(2)}}</td> -->
            <td>
              {{ info.puntos.r[i].value && info.puntos.r[i].value.toFixed(2) }}
            </td>
            <td>{{ punto.analisis.maximo }}</td>
            <td>{{ punto.analisis.minimo }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import jspdf from "jspdf";
import html2canvas from "html2canvas";

export default {
  props: ["info", "metodoAnalisis"],

  data() {
    return {
      dataImageX: null,
    };
  },

  methods: {
    print: async function (el) {
      const canvas = await html2canvas(el);
      this.dataImageX = canvas.toDataURL();
    },

    ImageXLoad: function () {
      const doc = new jspdf({ unit: "px", hotfixes: ["px_scaling"] });
      doc.html(this.$el.innerHTML, {
        callback: function (doc) {
          //doc.save();
          window.open(doc.output("bloburl"), "_blank");
        },
      });
      // this.$emit("loaded");
      this.loaded = true;
    },
  },
};
</script>

<style>
.reportContent {
  width: 19cm;
  padding: 0.5cm;
  /* background-color: green; */
  margin: 1cm;
  box-sizing: border-box;
}
</style>
