import { render, staticRenderFns } from "./RPTInstrumento.vue?vue&type=template&id=745a8abc"
import script from "./RPTInstrumento.vue?vue&type=script&lang=js"
export * from "./RPTInstrumento.vue?vue&type=script&lang=js"
import style0 from "./RPTInstrumento.vue?vue&type=style&index=0&id=745a8abc&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports