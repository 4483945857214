import { get, post, put, remove } from "@/core/fetch";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

export class RolesCustomStore extends CustomStore {
  constructor() {
    const baseUrl = `${global.API_URL}/roles`;
    super({
      key: "id",
      loadMode: "raw", //<- si pongo "raw" devuelve un error al hacer dataRaw.slice
      load: async () => await get(`${baseUrl}`),
      byKey: (key) => get(`${baseUrl}/${key}`),
      insert: (values) => post(`${baseUrl}`, values),
      update: (key, values) => put(`${baseUrl}/${key}`, values),
      remove: (key) => remove(`${baseUrl}/${key}`),
    });
  }
}

export class RolesDataSource extends DataSource {
  constructor(id) {
    const store = new RolesCustomStore(id);
    super({
      store,
    });
  }
}
