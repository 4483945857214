<template>
  <div>
    <DxHtmlEditor :value.sync="valueComputed" height="400px">
      <DxMediaResizing :enabled="true" />
      <DxToolbar :multiline="false">
        <DxItem name="undo" />
        <DxItem name="redo" />
        <DxItem name="separator" />
        <DxItem :accepted-values="sizeValues" name="size" />
        <DxItem :accepted-values="fontValues" name="font" />
        <DxItem name="separator" />
        <DxItem name="bold" />
        <DxItem name="italic" />
        <DxItem name="strike" />
        <DxItem name="underline" />
        <DxItem name="subscript" />
        <DxItem name="superscript" />
        <DxItem name="separator" />
        <DxItem name="alignLeft" />
        <DxItem name="alignCenter" />
        <DxItem name="alignRight" />
        <DxItem name="alignJustify" />
        <DxItem name="separator" />
        <DxItem name="orderedList" />
        <DxItem name="bulletList" />
        <DxItem name="separator" />
        <DxItem :accepted-values="headerValues" name="header" />
        <DxItem name="separator" />
        <DxItem name="color" />
        <DxItem name="background" />
        <DxItem name="separator" />
        <DxItem name="link" />
        <DxItem name="image" />
        <DxItem name="separator" />
        <DxItem name="insertTable" />
        <DxItem name="deleteTable" />
        <DxItem name="insertRowAbove" />
        <DxItem name="insertRowBelow" />
        <DxItem name="deleteRow" />
        <DxItem name="insertColumnLeft" />
        <DxItem name="insertColumnRight" />
        <DxItem name="deleteColumn" />
      </DxToolbar>
    </DxHtmlEditor>
  </div>
</template>

<script>
import { DxHtmlEditor, DxToolbar, DxMediaResizing, DxItem } from "devextreme-vue/ui/html-editor";

export default {
  components: {
    DxHtmlEditor,
    DxMediaResizing,
    DxToolbar,
    DxItem,
  },
  props: ["value"],
  data() {
    return {
      // html editor:
      sizeValues: ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"],
      fontValues: [
        "Arial",
        "Courier New",
        "Georgia",
        "Impact",
        "Lucida Console",
        "Tahoma",
        "Times New Roman",
        "Verdana",
      ],
      headerValues: [false, 1, 2, 3, 4, 5],
    };
  },

  computed: {
    valueComputed: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("update:value", v);
      },
    },
  },
};
</script>

<style scoped></style>
