export default [
  {
    id: 0,
    value: "horas",
  },
  {
    id: 1,
    value: "dias",
  },
  {
    id: 2,
    value: "semanas",
  },
  {
    id: 3,
    value: "meses",
  },
  {
    id: 4,
    value: "años",
  },
  // {
  //   id: 5,
  //   value: "numero_de_usos",
  // },
  {
    id: 6,
    value: "otros",
  },
];
