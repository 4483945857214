<template>
    <div>
        <Comun :estructura="estructuraComputed">
            <template #firstRow>
                <div class="d-flex flex-column readonly w-50">
                    <label class="form-label">{{ $t("maquina") }}</label>
                    <div v-if="maquinas" class="form-data">
                        {{ displayMaquina }}
                    </div>
                </div>
            </template>
        </Comun>

        <div class="d-flex flex-row w-100 pb-3">
        </div>

        <Pantalla2Edit
            :showEdit.sync="showEditComputed"
            :estructura.sync="estructuraComputed"
            :update="update">
        </Pantalla2Edit>
    </div>
</template>

<script>
import Comun from "./Comun.vue";
import CustomStore from "devextreme/data/custom_store";
import Pantalla2Edit from './Pantalla2.edit.vue'

export default {
    components: {
        Pantalla2Edit,
        Comun,
    },
    props: ["estructura", "showEdit", "update"],
    data() {
        return {
            maquinas: new CustomStore({
                key: "id",
                // load: () => this.$fetch.get(`${global.API_URL}/maquinas`),
                byKey: (key) => this.$fetch.get(`${global.API_URL}/maquinas/${key}`),
                // insert: (values) => this.$fetch.post(`${global.API_URL}/maquinas`, values),
                // update: (key, values) => this.$fetch.put(`${global.API_URL}/maquinas/${key}`, values),
                // remove: (key) => this.$fetch.delete(`${global.API_URL}/maquinas/${key}`),
            }),
        }
    },
    computed: {
        showEditComputed: {
            get() {
                return this.showEdit
            },
            set(value) {
                this.$emit("update:showEdit", value)
            }
        },
        estructuraComputed: {
            get() {
                return this.estructura
            },
            set(value) {
                this.$emit("update:estructura", value)
            }
        },
    },
    asyncComputed: {
        displayMaquina: async function () {
            if (!this.estructura.id_maquinas) return "";
            return (await this.maquinas.byKey(this.estructura.id_maquinas))?.codigo;
        },
    }
}
</script>