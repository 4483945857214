<template>
  <div>
    <DxButton
      :disabled="true"
      icon="add"
      type="default"
      styling-mode="contained"
      :hint="$t('nueva_verificacion')"
      :text="$t('nueva_verificacion')"
      @click="onClick"
    />
  </div>
</template>

<script>
import { DxButton } from "devextreme-vue/button";
import { confirm } from "devextreme/ui/dialog";
import { createVerificacion } from "../../../api/verificaciones";

export default {
  props: ["instrumento"],
  components: {
    DxButton,
  },
  methods: {
    async onClick() {
      if (!(await confirm("Esta acción creará una nueva verificación, ¿Desea crear una nueva verificacion?"))) return;

      const initValues = {
        id_instrumentos: this.instrumento.id,
        resultado: null,
        fecha: Date.now(),
        verificado_por: this.$store.state.user.nombre,
        certificado_por: this.$store.state.user.nombre,
        notas: null,
      };

      try {
        const response = await createVerificacion(initValues);

        // go to edit calibración
        this.$router.push({
          name: "verificacion",
          params: { id_instrumento: this.instrumento.id, id: response.id },
        });
      } catch (ex) {
        console.error(ex);
        this.$notify("Error al crear verificación", this.$t("error"), 5000);
      }
    },
  },
};
</script>
