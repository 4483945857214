<template>
    <div class="d-flex flex-row w-100 pb-3" style="gap: 1em">
        <div class="d-flex flex-column readonly w-100">
            <label class="form-label">{{ $t("proceso") }}</label>
            <div class="form-data">
                {{ estructura.proceso }}
            </div>
        </div>
        <slot name="firstRow"></slot>
    </div>
</template>
<script>
export default {
    props: ["estructura"],
}
</script>