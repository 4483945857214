<template>
  <div class="d-flex flex-row pt-2 pb-4" style="gap: 50px">
    <div class="d-flex flex-column plan-control">
      <label class="form-label fw-bold">{{ $t("planControl") }}</label>

      <label class="form-label"
        >{{ $t("referencia") }} / {{ $t("pieza") }} / {{ $t("fase") }}</label
      >
      <div class="form-data">
        {{ plan.referencia }} / {{ plan.nombre }} /
        {{ estructura.descripcion }}
      </div>
    </div>

    <div class="d-flex flex-column caracteristica">
      <label class="form-label fw-bold">{{ $t("caracteristica") }}</label>

      <label class="form-label"
        >{{ $t("numero") }} / {{ $t("nombre") }} / {{ $t("especificacion") }}
        <!-- / {{ $t("calibre") }} -->
      </label>

      <div class="form-data">
        {{ caracteristica.id }} / {{ caracteristica.nombre }} /
        <div
          style="display: inline-block"
          v-html="caracteristica.especificacion"
        ></div>
        <!-- / {{ calibre ? calibre.descripcion : "" }} -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["plan", "estructura", "caracteristica"],
};
</script>

<style scoped>
.form-label {
  color: #aaa;
  margin-bottom: 1px;
  font-size: 0.7rem;
}

.form-data {
  margin-top: 1px;
}
</style>