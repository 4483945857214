import store from "./store";

export default {
  get token() {
    // console.log("auth.token(get)", store.getters.token)
    return store.getters.token;
  },

  set token(val) {
    // console.log("auth.token(set)", val)
    store.commit("setToken", val);
  },

  get user() {
    // console.log("auth.user(get)", store.getters.user)
    return store.getters.user;
  },

  loggedIn() {
    // console.log("loggedIn?", store.getters.isLoggedIn)
    return store.getters.isLoggedIn;
  },

  async hasModulo(modulo) {
    // console.log("hasModulo::modulos", modulo);
    try {
      store.dispatch("loadModulos");
      return store.getters.hasModulo(modulo);
    } catch (ex) {
      console.error("auth::hasModulo", ex);
    }
  },

  getToken() {
    // console.log("getToken?", this.token)
    return this.token;
  },

  async logIn(email, password) {
    try {
      return fetch(`${global.API_URL}/login`, {
        method: "POST",
        body: JSON.stringify({
          usuario: email,
          password: password,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (!res.ok) throw "El usuario no existe";
          // if (res.status === 404) throw "El usuario no existe";
          return res.json();
        })
        .then((res) => {
          this.token = res.token;

          return {
            isOk: true,
            data: res.token,
          };
        })
        .catch((err) => {
          console.error("Authentication failed", err);
          return {
            isOk: false,
            message: "Authentication failed",
          };
        });
    } catch (err) {
      console.error("Authentication failed", err);
      return {
        isOk: false,
        message: "Authentication failed",
      };
    }
  },

  async logOut() {
    // store.commit("setToken", null);
    this.token = null;
  },

  async resetPassword(email) {
    try {
      // Send request
      console.error("resetPassword is not implemented", email);

      return {
        isOk: true,
      };
    } catch {
      return {
        isOk: false,
        message: "Failed to reset password",
      };
    }
  },

  async changePassword(email, recoveryCode) {
    try {
      // Send request
      console.error("changePassword is not implemented", email, recoveryCode);

      return {
        isOk: true,
      };
    } catch {
      return {
        isOk: false,
        message: "Failed to change password",
      };
    }
  },

  async createAccount(email, password) {
    try {
      // Send request
      console.error("createAccount is not implemented", email, password);

      return {
        isOk: true,
      };
    } catch {
      return {
        isOk: false,
        message: "Failed to create account",
      };
    }
  },
};
