import { get, post, put, remove } from "@/core/fetch";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

export class UnidadesCustomStore extends CustomStore {
  constructor() {
    const baseUrl = `${global.API_URL}/analisis/unidades`;

    super({
      key: "id",
      loadMode: "raw",
      // cacheRawData: false,
      load: () => get(`${baseUrl}`),
      // byKey: (key) => get(`${baseUrl}/${key}`),
      insert: (values) => post(`${baseUrl}`, values),
      update: (key, values) => put(`${baseUrl}/${key}`, values),
      remove: (key) => remove(`${baseUrl}/${key}`),
    });
  }
}

export class UnidadesDataSource extends DataSource {
  constructor() {
    const store = new UnidadesCustomStore();
    super({
      store,
    });
  }
}
