<template>
  <div>
    <h3>{{ $t("identificacion") }}</h3>
    <div class="dx-card responsive-paddings">
      <div class="item">
        <div class="d-flex flex-wrap gap-3">
          <Display :label="$t('referencia')" :required="true" :value="plancontrol?.referencia" />
          <Display :label="$t('referenciaCliente')" :value="plancontrol?.referencia_cliente" />
          <Display :label="$t('nombre')" :required="true" :value="plancontrol?.nombre" />
          <Display :label="$t('productoFinal')" :value="plancontrol?.producto_final" />
          <Display :label="$t('cliente')" :value="displayCliente" />
          <Display :label="$t('agrupacion')" :value="displayAgrupacion" />
        </div>

        <div class="d-flex flex-wrap mt-3 gap-3">
          <div class="d-flex flex-column plan situacion">
            <label class="form-label">{{ $t("situacion") }}*</label>
            <div class="form-data">
              <ul class="situaciones-list">
                <li
                  v-for="situacion in situaciones"
                  :key="situacion.value"
                  :class="{ selected: situacion.id === plancontrol?.situacion }"
                >
                  <img :src="require(`@/assets/situaciones/${situacion.img}`)" height="24" class="pr-2" />
                  <span>{{ situacion.value }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <h4 class="mt-4">{{ $t("capacidades") }}</h4>
        <div class="d-flex flex-wrap gap-3">
          <Display :label="$t('cp')">
            <span v-if="plancontrol?.capacidad_cp !== null">{{
              $localization.formatNumber(plancontrol?.capacidad_cp)
            }}</span>
            <span v-else
              ><i>{{ $localization.formatNumber(1.33) }}</i></span
            >
          </Display>

          <Display :label="$t('cpk')">
            <span v-if="plancontrol?.capacidad_cpk !== null">{{
              $localization.formatNumber(plancontrol?.capacidad_cpk)
            }}</span>
            <span v-else
              ><i>{{ $localization.formatNumber(1.33) }}</i></span
            >
          </Display>

          <Display :label="$t('pp')">
            <span v-if="plancontrol?.capacidad_pp !== null">{{
              $localization.formatNumber(plancontrol?.capacidad_pp)
            }}</span>
            <span v-else
              ><i>{{ $localization.formatNumber(1.67) }}</i></span
            >
          </Display>

          <Display :label="$t('ppk')">
            <span v-if="plancontrol?.capacidad_ppk !== null">{{
              $localization.formatNumber(plancontrol?.capacidad_ppk)
            }}</span>
            <span v-else
              ><i>{{ $localization.formatNumber(1.67) }}</i></span
            >
          </Display>
        </div>

        <h4 class="mt-4">{{ $t("revisiones") }}</h4>
        <div class="d-flex flex-wrap gap-3">
          <Display v-if="true" :label="$t('fechaEmision')">
            {{ plancontrol?.fecha_emision && $moment(plancontrol?.fecha_emision).format("L") }}
            {{ plancontrol?.fecha_emision && $moment(plancontrol?.fecha_emision).format("LTS") }}
            <template #extra>
              <div class="form-text text-muted fst-italic text-end">
                {{ plancontrol?.fecha_emision && $moment(plancontrol?.fecha_emision).fromNow() }}
              </div>
            </template>
          </Display>

          <Display :label="$t('fechaUltimaRevision')">
            {{ fechaUltimaRevision && $moment(fechaUltimaRevision).format("L") }}
            {{ fechaUltimaRevision && $moment(fechaUltimaRevision).format("LTS") }}
            <template #extra>
              <div class="form-text text-muted fst-italic text-end">
                {{ fechaUltimaRevision && $moment(fechaUltimaRevision).fromNow() }}
              </div>
            </template>
          </Display>

          <Display :label="$t('fechaUltimaRevisionNivel')">
            {{ fechaUltimaRevisionNivel && $moment(fechaUltimaRevisionNivel).format("L") }}
            {{ fechaUltimaRevisionNivel && $moment(fechaUltimaRevisionNivel).format("LTS") }}
            <template #extra>
              <div class="form-text text-muted fst-italic text-end">
                {{ fechaUltimaRevisionNivel && $moment(fechaUltimaRevisionNivel).fromNow() }}
              </div>
            </template>
          </Display>

          <Display :label="$t('nivelUltimaRevisionNivel')">
            {{ nivelUltimaRevisionNivel }}
          </Display>
        </div>
      </div>

      <div class="botones">
        <DxButton icon="edit" type="default" stylingMode="text" @click="onClickButtonEdit"></DxButton>
      </div>
    </div>

    <DxPopup :visible.sync="isPopupVisible" :fullScreen="false" :title="planEditable.nombre">
      <DxToolbarItem :options="toolbarItemCancelarOptions" widget="dxButton" location="after" toolbar="bottom" />

      <DxToolbarItem :options="toolbarItemGuardarOptions" widget="dxButton" location="after" toolbar="bottom" />

      <DxScrollView>
        <div class="edit">
          <!-- <code>{{ planEditable }}</code> -->
          <div v-if="false" class="d-flex flex-wrap gap-3">
            <div class="d-flex flex-row">
              <DxSwitch v-model="planEditable.familia"> </DxSwitch>
              <div class="form-label mt-1 ms-3">Es una familia de planes de control</div>
            </div>
          </div>
          <div class="d-flex flex-wrap gap-3">
            <div>
              <DxTextBox :label="`${$t('referencia')}*`" :value.sync="planEditable.referencia">
                <DxValidator ref="vReferencia">
                  <DxRequiredRule :message="$t('errorReferenciaRequerida')" s />
                </DxValidator>
              </DxTextBox>
            </div>
            <div>
              <DxTextBox :label="$t('referenciaCliente')" v-model="planEditable.referencia_cliente" width="100%">
              </DxTextBox>
            </div>
            <div>
              <DxTextBox :label="$t('nombre')" v-model="planEditable.nombre" width="100%" valueChangeEvent="input">
                <DxValidator ref="vNombre">
                  <DxRequiredRule :message="$t('errorNombreRequerido')" />
                </DxValidator>
              </DxTextBox>
            </div>
            <div>
              <DxTextBox :label="$t('productoFinal')" v-model="planEditable.producto_final" width="300"> </DxTextBox>
            </div>
            <div>
              <SelectBoxCatalogEditor
                :title="$t('catalogoClientes')"
                :label="$t('cliente')"
                autorefresh
                v-model="planEditable.id_cliente"
                :width="400"
                :search-expr="['codigo', 'nombre']"
                :columnas="{
                  codigo: {
                    width: 70,
                    caption: $t('codigo'),
                  },
                  nombre: {
                    width: '100%',
                    caption: $t('nombre'),
                  },
                }"
                :display-expr="(item) => (item ? `${item.codigo} - ${item.nombre}` : '')"
                value-expr="id"
                recurso="clientes"
                @dataSourceUpdated="onClientesDataSourceUpdated"
              >
                <template #item="{ data }">
                  <div class="clientes-item">
                    <span v-if="data.nombre">{{ data.nombre }}</span>
                    <i v-else>{{ $t("sinNombre") }}</i>
                    <br />
                    <small>{{ data.codigo }}</small>
                  </div>
                </template>
              </SelectBoxCatalogEditor>
            </div>
            <div>
              <SelectBoxCatalogEditor
                :title="$t('catalogoAgrupaciones')"
                :label="$t('agrupacion')"
                autorefresh
                v-model="planEditable.id_agrupaciones"
                :width="400"
                :search-expr="['codigo', 'descripcion']"
                :columnas="{
                  codigo: {
                    width: 70,
                    caption: $t('codigo'),
                  },
                  descripcion: {
                    width: '100%',
                    caption: $t('descripcion'),
                  },
                }"
                :display-expr="(item) => (item ? `${item.codigo} - ${item.descripcion}` : '')"
                value-expr="id"
                recurso="agrupaciones"
                @dataSourceUpdated="onDataSourceAgrupacionesUpdated"
              >
                <template #item="{ data }">
                  <div class="agrupaciones-item">
                    <span v-if="data.descripcion">{{ data.descripcion }}</span>
                    <i v-else>{{ $t("sinNombre") }}</i>
                    <br />
                    <small>{{ data.codigo }}</small>
                  </div>
                </template>
              </SelectBoxCatalogEditor>
            </div>
          </div>
          <div class="d-flex flex-wrap mt-4 gap-3">
            <div class="d-flex flex-column">
              <label class="form-label">{{ $t("situacion") }}*</label>
              <div class="form-data">
                <DxRadioGroup
                  :key="componentKey"
                  :items="situaciones"
                  display-expr="value"
                  value-expr="id"
                  v-model="planEditable.situacion"
                >
                  <template #item="{ data }">
                    <div>
                      <img :src="require(`@/assets/situaciones/${data.img}`)" height="24" class="pr-2" />
                      <span>{{ data.value }}</span>
                    </div>
                  </template>
                </DxRadioGroup>
              </div>
            </div>
          </div>
          <h4 class="mt-4">{{ $t("capacidades") }}</h4>
          <div class="d-flex flex-wrap gap-3">
            <div class="d-flex flex-column">
              <DxNumberBox
                :label="$t('cp')"
                width="125"
                v-model="planEditable.capacidad_cp"
                :show-clear-button="true"
                :min="0"
                :showSpinButtons="true"
                :step="0.01"
                :placeholder="$t('valorPorDefecto1.33')"
              >
              </DxNumberBox>
            </div>
            <div class="d-flex flex-column">
              <DxNumberBox
                :label="$t('cpk')"
                width="125"
                v-model="planEditable.capacidad_cpk"
                :show-clear-button="true"
                :min="0"
                :showSpinButtons="true"
                :step="0.01"
                :placeholder="$t('valorPorDefecto1.33')"
              >
              </DxNumberBox>
            </div>
            <div class="d-flex flex-column">
              <DxNumberBox
                labelMode="static"
                :label="$t('pp')"
                width="125"
                v-model="planEditable.capacidad_pp"
                :show-clear-button="true"
                :min="0"
                :showSpinButtons="true"
                :step="0.01"
                :placeholder="$t('valorPorDefecto1.67')"
              >
              </DxNumberBox>
            </div>
            <div class="d-flex flex-column plan ppk">
              <DxNumberBox
                :label="$t('ppk')"
                width="125"
                v-model="planEditable.capacidad_ppk"
                :show-clear-button="true"
                :min="0"
                :showSpinButtons="true"
                :step="0.01"
                :placeholder="$t('valorPorDefecto1.67')"
              >
              </DxNumberBox>
            </div>
          </div>
          <h4 class="mt-5">{{ $t("revisiones") }}</h4>
          <div class="d-flex flex-wrap gap-3">
            <div class="d-flex flex-column">
              <!-- <label class="form-label">{{ $t("fechaEmision") }}*</label> -->
              <div class="form-data">
                <DxDateBox
                  :label="`${$t('fechaEmision')}*`"
                  :key="componentKey"
                  v-model="planEditable.fecha_emision"
                  ref="fecha_emision"
                  type="datetime"
                  :width="200"
                >
                  <DxValidator ref="vFechaEmision">
                    <DxRequiredRule :message="$t('errorFechaEmisionRequerida')" />
                  </DxValidator>
                </DxDateBox>
              </div>
              <div class="form-text text-muted fst-italic text-end">
                {{ plancontrol?.fecha_emision && $moment(plancontrol?.fecha_emision).fromNow() }}
              </div>
            </div>

            <Display :label="$t('fechaUltimaRevision')" :full="false">
              {{ fechaUltimaRevision && $moment(fechaUltimaRevision).format("L") }}
              {{ fechaUltimaRevision && $moment(fechaUltimaRevision).format("LTS") }}
              <template #extra>
                <div class="form-text text-muted fst-italic text-end">
                  {{ fechaUltimaRevision && $moment(fechaUltimaRevision).fromNow() }}
                </div>
              </template>
            </Display>

            <Display :label="$t('fechaUltimaRevisionNivel')">
              {{ fechaUltimaRevisionNivel && $moment(fechaUltimaRevisionNivel).format("L") }}
              {{ fechaUltimaRevisionNivel && $moment(fechaUltimaRevisionNivel).format("LTS") }}
              <template #extra>
                <div class="form-text text-muted fst-italic text-end">
                  {{ fechaUltimaRevisionNivel && $moment(fechaUltimaRevisionNivel).fromNow() }}
                </div>
              </template>
            </Display>

            <Display :label="$t('nivelUltimaRevisionNivel')">
              {{ nivelUltimaRevisionNivel }}
            </Display>
          </div>
        </div>
      </DxScrollView>
    </DxPopup>
  </div>
</template>

<script>
// @TODO:
// plan de control debería hacer el get aquí y no en el padre
// el crud de plan control debería estar aqui.  Ya tenemos put...
// el get lo hace el padre pero debería hacerlo este componente

// import { formatNumber } from "devextreme/localization";
// import auth from "@/auth";

import Display from "@/components/core/Display";

import DxTextBox from "devextreme-vue/text-box";
import DxDateBox from "devextreme-vue/date-box";
import SelectBoxCatalogEditor from "@/components/core/SelectBoxCatalogEditor";
import DxNumberBox from "devextreme-vue/number-box";
import DxRadioGroup from "devextreme-vue/radio-group";
import DxSwitch from "devextreme-vue/switch";
import DxButton from "devextreme-vue/button";

import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";

import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";
import { DxScrollView } from "devextreme-vue/scroll-view";

import situaciones from "@/data/Situaciones";

import { PlanesControlCustomStore } from "../../data/plancontrol/PlanesControlDataSource";
import { RevisionesCustomStore } from "../../data/plancontrol/RevisionesDataSource";
import { ClientesCustomStore } from "@/data/ClienteDataSource";
import { AgrupacionesCustomStore } from "@/data/AgrupacionesDataSource";

export default {
  props: ["plancontrol", "id"],
  data() {
    return {
      // plancontrol: {},
      componentKey: 0,
      isPopupVisible: false,
      planEditable: {}, //Mantendré los datos del plan en esta variable para poder cancelar

      situaciones: situaciones.map((e) => ({
        ...e,
        value: this.$t(e.value),
      })),

      planesControlCustomStore: new PlanesControlCustomStore(),
      clientesCustomStore: new ClientesCustomStore(),
      agrupacionesCustomStore: new AgrupacionesCustomStore(),
      revisionesStore: new RevisionesCustomStore(this.id),
      revisiones: [],
    };
  },

  components: {
    Display,
    DxButton,
    DxTextBox,
    DxDateBox,
    SelectBoxCatalogEditor,
    DxRadioGroup,
    DxSwitch,
    DxNumberBox,
    DxValidator,
    DxRequiredRule,
    DxPopup,
    DxToolbarItem,
    DxScrollView,
  },

  asyncComputed: {
    displayCliente: {
      async get() {
        if (!this.plancontrol?.id_cliente) return "";
        return (await this.clientesCustomStore.byKey(this.plancontrol?.id_cliente))?.codigo;
      },
      default() {
        return "Loading...";
      },
    },
    displayAgrupacion: {
      async get() {
        if (!this.plancontrol?.id_agrupaciones) return "";
        return (await this.agrupacionesCustomStore.byKey(this.plancontrol?.id_agrupaciones))?.codigo;
      },
      default() {
        return "Loading...";
      },
    },
  },

  computed: {
    toolbarItemCancelarOptions: function () {
      return {
        text: this.$t("cancelar"),
        type: "normal",
        onClick: () => {
          this.isPopupVisible = false;
        },
      };
    },

    toolbarItemGuardarOptions: function () {
      return {
        text: this.$t("guardar"),
        stylingMode: "contained",
        // useSubmitBehavior: true,
        type: "default",
        onClick: (e) => {
          // this.$emit("update:plancontrol", this.planEditable);

          /**
           * @TODO la validación necesita un grupo porque el botón no pertenece al grupo e intenta
           * validar TODA la página.  Cuando estaba sólo este formulario esto era válido.
           * Al haber incluido otros componentes con formularios e.validationGroup.validate()
           * intenta validar esos otros formularios y la validación no es correcta.
           * */

          e.validationGroup.validate().isValid && this.update();
        },
      };
    },

    fechaUltimaRevision: function () {
      if (this.revisiones.length === 0) return null;
      return this.revisiones[0]?.fecha;
    },
    fechaUltimaRevisionNivel: function () {
      if (this.revisiones.length === 0) return null;
      return this.revisiones.filter((e) => e.nivel !== null)[0]?.fecha;
    },
    nivelUltimaRevisionNivel: function () {
      if (this.revisiones.length === 0) return null;
      return this.revisiones.filter((e) => e.nivel !== null)[0]?.nivel;
    },
    idioma: function () {
      return this.$i18n.locale;
    },
  },

  watch: {
    idioma() {
      this.componentKey++;
    },
  },

  mounted: function () {
    this.load();
  },

  methods: {
    async refresh() {
      console.log("refresh");
      await this.load();
    },

    async load() {
      const result = await this.revisionesStore.load();
      this.revisiones = result.sort((a, b) => new Date(b.fecha) - new Date(a.fecha));
    },

    async update() {
      // try {
      //   //envio la petición al servidor para que actualice el plan en la base de datos.
      //   const response = await fetch(`${global.API_URL}/planes/${this.plancontrol.id}`, {
      //     method: "PUT",
      //     mode: "cors",
      //     cache: "no-cache",
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: "Bearer " + auth.getToken(),
      //     },
      //     body: JSON.stringify(this.planEditable),
      //   });

      //   if (response.ok) {
      //     // como todo ha ido correctamente this.planEditable mantiene los valores que acabamos de insertar
      //     // para evitar ir al servidor para actualizar el valor del plan nos limitamos a actualizar los datos
      //     // del plan de control con this.planEditable.

      //     // this.$emit("update:plancontrol", this.planEditable);
      //     //CUIDADO: en el objeto planEditable no se han modificado las trazabilidades.
      //     //         Necesitamos obtener el objeto modificado (y put lo devuelve).
      //     this.planEditable = await response.json();
      //     this.$emit("update:plancontrol", this.planEditable);

      //     this.isPopupVisible = false;
      //   } else {
      //     this.$emit("error", { error: "response_not_ok" });
      //   }
      // } catch (ex) {
      //   this.$emit("error", { error: ex });
      // }

      try {
        this.planEditable = await this.planesControlCustomStore.update(this.planEditable.id, this.planEditable);
        this.isPopupVisible = false;
        this.$emit("update:plancontrol", this.planEditable);
      } catch (ex) {
        this.$emit("error", { error: ex });
      }
    },
    async onClickButtonEdit() {
      this.isPopupVisible = true;
      // copio el plan de control a planEditable para poder cancelar

      this.planEditable = { ...this.plancontrol };
    },

    async onClientesDataSourceUpdated() {
      //recargar el displayCliente por si hubiera un cambio?
      this.$asyncComputed.displayCliente.update();
    },

    async onDataSourceAgrupacionesUpdated() {
      //recargar el displayAgrupacion por si hubiera un cambio
      this.$asyncComputed.displayAgrupacion.update();
    },
  },
};
</script>

<style scoped lang="scss">
.situaciones-list {
  padding-left: 15px;

  li::marker {
    color: #ccc;
  }

  li.selected::marker {
    color: var(--accent);
  }

  li {
    img {
      opacity: 0.2;
    }

    color: #ccc;
  }

  .selected {
    img {
      opacity: 1;
    }

    color: var(--accent);
  }
}

i {
  color: gray;
}

.botones {
  text-align: right;
}
</style>
