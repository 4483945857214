<template>
    <div>
        <Comun :estructura="estructuraComputed">
            <template #firstRow>
                <div class="d-flex flex-column readonly w-50">
                    <label class="form-label">{{ $t("dispositivo") }}</label>
                    <div class="form-data">
                        {{ estructuraComputed.dispositivo }}
                    </div>
                </div>
            </template>
        </Comun>

        <div class="d-flex flex-row w-100 pb-3">
        </div>

        <Pantalla3Edit
            :showEdit.sync="showEditComputed"
            :estructura.sync="estructuraComputed"
            :update="update">
        </Pantalla3Edit>

    </div>
</template>

<script>
import Comun from "./Comun.vue";
import Pantalla3Edit from './Pantalla3.edit.vue'

export default {
    components: { Pantalla3Edit, Comun },
    props: ["estructura", "showEdit", "update"],
    data() {
        return {

        }
    },
    computed: {
        showEditComputed: {
            get() {
                return this.showEdit
            },
            set(value) {
                this.$emit("update:showEdit", value)
            }
        },
        estructuraComputed: {
            get() {
                return this.estructura
            },
            set(value) {
                this.$emit("update:estructura", value)
            }
        },
    },
}
</script>