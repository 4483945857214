var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$store.state.debug)?_c('code',[_vm._v(" isValid: "+_vm._s(_vm.isValid)+" ")]):_vm._e(),_c('div',{staticClass:"d-flex flex-column align-items-center pb-4",staticStyle:{"gap":"10px"}},[_c('div',{staticClass:"d-flex flex-column pb-2 w-100 justify-content-center align-items-center"},[_c('DxValidationGroup',{ref:"addValorValidationGroup"},[_c('DxNumberBox',{ref:"nuevoValor",attrs:{"label":_vm.$t('valores'),"mode":"text","showSpinButtons":true,"step":0.01,"min":0,"width":"250"},on:{"enter-key":_vm.nuevoValorOnEnterKey,"focus-in":_vm.nuevoValorOnFocusIn},model:{value:(_vm.nuevoValor),callback:function ($$v) {_vm.nuevoValor=$$v},expression:"nuevoValor"}},[_c('DxNumberBoxButton',{attrs:{"options":_vm.addButton,"name":"add","location":"after"}}),_c('DxNumberBoxButton',{attrs:{"name":"spins"}}),_c('DxValidator',[_c('DxRequiredRule',{attrs:{"message":_vm.$t('errorValorRequerido')}})],1)],1)],1),(_vm.valores.length > 0)?_c('div',{staticClass:"valores d-flex flex-row py-2 mt-2 gap-1 flex-wrap"},_vm._l((_vm.valores),function(v,index){return _c('span',{key:index,staticClass:"badge fs-6",class:{
            'text-bg-success': !_vm.fueraDeLimites(v),
            'text-bg-danger': _vm.fueraDeLimites(v),
          },on:{"click":function($event){return _vm.deleteUnValorOfValores(index)}}},[_vm._v(" "+_vm._s(v)+" ")])}),0):_vm._e(),_c('div',{staticClass:"p-3",class:{},style:({ border: _vm.borderStyle })},[_c('DxValidator',{ref:"valueListValidator",attrs:{"adapter":_vm.adapterConfig}},[_c('DxRequiredRule',{attrs:{"reevaluate":true,"message":_vm.$t('errorNoHayElementosEnLaLista')}})],1),_c('DxValidationSummary',{scopedSlots:_vm._u([{key:"item",fn:function({ data }){return [_c('span',{staticClass:"badge text-bg-danger fs-6 border-0"},[_vm._v(" "+_vm._s(data.text)+" ")])]}}])})],1)],1),_c('div',{staticClass:"d-flex flex-row align-items-end"},[_c('Display',{attrs:{"value":_vm.caracteristica.LTI?.toFixed(3),"label":_vm.$t('lti')}}),_c('span',{staticClass:"le"},[_vm._v("≤")]),_c('Display',{staticStyle:{"width":"90px"},attrs:{"value":_vm.analisis.minimo?.toFixed(3),"label":_vm.$t('minimo'),"valueStyle":{
          'bg-danger-subtle border border-2 border-danger fw-bold text-danger-emphasis': _vm.fueraDeLimites(
            _vm.analisis.minimo
          ),
        }}}),_c('span',{staticClass:"le"},[_vm._v("≤")]),_c('Display',{staticStyle:{"width":"90px"},attrs:{"value":_vm.analisis.promedio?.toFixed(3),"label":_vm.$t('promedio'),"valueStyle":{
          'bg-danger-subtle border border-2 border-danger fw-bold text-danger-emphasis': _vm.fueraDeLimites(
            _vm.analisis.valor
          ),
        }}}),_c('span',{staticClass:"le"},[_vm._v("≤")]),_c('Display',{staticStyle:{"width":"90px"},attrs:{"value":_vm.analisis.maximo?.toFixed(3),"label":_vm.$t('maximo'),"valueStyle":{
          'bg-danger-subtle border border-2 border-danger fw-bold text-danger-emphasis': _vm.fueraDeLimites(
            _vm.analisis.maximo
          ),
        }}}),_c('span',{staticClass:"le"},[_vm._v("≤")]),_c('Display',{attrs:{"value":_vm.caracteristica.LTS?.toFixed(3),"label":_vm.$t('lts')}})],1),_c('Display',{attrs:{"value":_vm.analisis.tamano_muestra,"label":_vm.$t('tamanoMuestra')}}),_c('Display',{attrs:{"value":_vm.analisis.recorrido_movil?.toFixed(3),"label":_vm.$t('recorridoMovil')}}),_c('span',{staticClass:"h5 d-flex flex-column analisis fuera-de-limites"},[(_vm.fueraDeLimites(this.analisis.valor))?_c('div',{staticClass:"badge badge-danger"},[_vm._v(" "+_vm._s(_vm.$t("fueraLimitesTolerancia"))+" ")]):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }