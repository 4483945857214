<template>
  <div :key="componentKey">
    <div class="d-flex flex-column py-2 px-4">
      <code v-if="$store.state.debug">
        {{ $t("resultadoAnalisis") }}:
        <pre>{{ JSON.stringify(analisis, null, 2) }}</pre>
      </code>

      <form action="" @submit="onFormSubmit($event)">
        <General
          ref="general"
          :planID="planID"
          :caracteristica="caracteristica"
          :value.sync="ianalisis"
          @valid="onGeneralValid"
        ></General>

        <div class="d-flex flex-row flex-wrap flex-xl-nowrap justify-content-between my-4 gap-5 align-items-stretch">
          <div class="w-100 d-flex flex-column">
            <h3>{{ $t("fotos") }}</h3>
            <div class="border d-flex justify-content-center align-items-center">
              <DxGallery
                id="gallery"
                :data-source="images"
                :loop="true"
                :slideshow-delay="0"
                :show-nav-buttons="true"
                :show-indicator="true"
                :stretch-images="false"
                :height="300"
              >
                <template #item="{ data }">
                  <ImageWithAuthToken :filename="data.fichero" />
                </template>
              </DxGallery>
            </div>
          </div>

          <div class="w-100 d-flex flex-column">
            <h3>{{ $t("analisis") }}</h3>
            <div class="w-100 h-100 p-3 border d-flex justify-content-center align-items-center">
              <Def
                ref="def"
                v-if="caracteristica.id_metodoanalisis === 1"
                :caracteristica="caracteristica"
                :value.sync="ianalisis"
                @valid="onEspecificValid"
                @analisisRequerido="onAnalisisRequerido"
              ></Def>
              <MDef
                ref="mdef"
                v-else-if="caracteristica.id_metodoanalisis === 2"
                :caracteristica="caracteristica"
                :value.sync="ianalisis"
                @valid="onEspecificValid"
              ></MDef>
              <XR
                ref="xr"
                v-else-if="caracteristica.id_metodoanalisis === 3"
                :caracteristica="caracteristica"
                :value.sync="ianalisis"
                @valid="onEspecificValid"
              ></XR>
              <XBR
                ref="xbr"
                v-else-if="caracteristica.id_metodoanalisis === 4"
                :caracteristica="caracteristica"
                :value.sync="ianalisis"
                @valid="onEspecificValid"
              ></XBR>
              <MinMax
                ref="minmax"
                v-else-if="caracteristica.id_metodoanalisis === 5"
                :caracteristica="caracteristica"
                :value="ianalisis"
                @valid="onEspecificValid"
              ></MinMax>
              <div v-else>{{ $t("warningMetodoAnalisis") }} (id:{{ caracteristica.id_metodoanalisis }})</div>
            </div>
          </div>
        </div>
        <div class="pt-3 mt-3">
          <h3>{{ $t("notasControlador") }}</h3>
          <Notas :value="notas"></Notas>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import DxGallery from "devextreme-vue/gallery";

import Notas from "./analisis/Notas";

import Def from "./analisis/Def";
import MDef from "./analisis/MDef";
import XR from "./analisis/XR";
import XBR from "./analisis/XBR";
import MinMax from "./analisis/MinMax";
import General from "./analisis/General";
import ImageWithAuthToken from "../core/ImageWithAuthToken";

// import DxValidationSummary from "devextreme-vue/validation-summary";

import auth from "@/auth";

export default {
  props: ["analisis", "caracteristica", "planID", "tipo", "lote", "secuencia", "turno", "operario"],
  data() {
    return {
      componentKey: 0,
      isGeneralValid: true,
      isEspecificValid: true,

      ianalisis: {},
    };
  },

  created() {
    // console.log("%cAnalisis.vue created!!!!!!!!!!!", "background-color: blue");
    if (this.analisis === undefined) {
      // INSERT/POST
      // console.log(" --- INSERT --- ");

      // init values!
      this.ianalisis = {
        id_caracteristicas: this.caracteristica.id,
        fecha: new Date(),

        lote: this.lote,
        secuencia: this.secuencia,
        turno: this.turno,
        operario: this.operario,

        notas: null,
        valor: null,
        minimo: null,
        maximo: null,
        promedio: null,
        tamano_muestra: null, // this.caracteristica.tamano_muestra,
        recorrido_movil: null,
        tipo: this.tipo,
        total_defectuosas: null,
        rechazo: null,
        fecha_muestra: new Date(),
        max: null,
        sum: null,
        //propiedades que no pertenecen al objeto de la base de datos
        //los añado al json pero les pongo un _:
        // _valores: [],
        _metodoanalisis: this.caracteristica.id_metodoanalisis,
        // ^^^^^^
        // Los he comentado porque sólo los quiero añadir en MDef y XBR.
      };
    } else {
      // UPDATE/PUT
      // console.log(" --- UPDATE --- ");
      this.ianalisis = { ...this.analisis }; // copia del objeto! (si cancelo no quiero que quede modificado!)
    }
  },

  mounted() {
    // console.log("%cAnalisis.vue mounted!!!!!!!!!!!", "background-color: green");
  },

  watch: {
    idioma() {
      this.componentKey++;
    },
  },
  computed: {
    idioma() {
      // return this.$store.state.idioma;
      return this.$i18n.locale;
    },
    notas: function () {
      return this.caracteristica ? this.caracteristica.notas : { plan: [], caracteristica: [] };
    },
    images: function () {
      return this.caracteristica ? this.caracteristica.images : [];
    },
    isValid: function () {
      return this.isGeneralValid && this.isEspecificValid;
    },
  },
  components: {
    DxGallery,
    Notas,
    Def,
    MDef,
    XR,
    XBR,
    MinMax,
    General,
    ImageWithAuthToken,
  },
  methods: {
    onAnalisisRequerido(analisisRequerido) {
      this.$emit("analisisRequerido", analisisRequerido);
    },
    onGeneralValid(e) {
      this.isGeneralValid = e;
    },

    onEspecificValid(e) {
      this.isEspecificValid = e;
    },

    cancel() {
      //limpiar formulario?
      this.$emit("cancelled");
    },

    async insert() {
      try {
        const response = await fetch(`${global.API_URL}/caracteristicas/${this.caracteristica.id}/analisis`, {
          method: "post",
          mode: "cors",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json",
            // "Authorization": "Bearer " + auth.getToken(),
            Authorization: "Bearer " + auth.getToken(),
          },
          body: JSON.stringify(this.ianalisis),
        });

        if (response.ok) {
          this.$emit("update:lote", this.ianalisis.lote);
          this.$emit("update:secuencia", this.ianalisis.secuencia);
          this.$emit("update:turno", this.ianalisis.turno);
          this.$emit("update:operario", this.ianalisis.operario);

          this.$emit("inserted");
        } else {
          this.$emit("error");
        }
      } catch (ex) {
        console.error("error al insertar", ex);
        this.$emit("error");
      }
    },

    async update() {
      try {
        const response = await fetch(
          `${global.API_URL}/caracteristicas/${this.caracteristica.id}/analisis/${this.analisis.id}`,
          {
            method: "PUT",
            mode: "cors",
            cache: "no-cache",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + auth.getToken(),
            },
            body: JSON.stringify(this.ianalisis),
          }
        );

        if (response.ok) {
          this.$emit("updated");
        } else {
          this.$emit("error");
        }
      } catch (ex) {
        console.error("error al actualizar", ex);
        this.$emit("error");
      }
    },
  },
};
</script>
