<template>
  <div :key="componentKey">
    <RPTInst
      v-if="isReportVisible"
      v-show="false"
      :idInstrumento="id"
      ref="rptInstrumento"
      @printed="onPrinted"
      @error="onPrintedError"
    >
    </RPTInst>
    <LBLInst
      v-if="isLabelVisible"
      v-show="false"
      :idInstrumento="id"
      ref="lblInstrumento"
      @printed="onPrinted"
      @error="onPrintedError"
    >
    </LBLInst>

    <TitleInstrumentoWithBackButton :instrumento="instrumento" :backTo="{ name: 'instrumentos' }">
      <span>
        <DxButton
          id="report"
          icon="fa fa-file-text-o"
          type="back"
          :hint="$t('report_instrumento')"
          @click="onClickReportInstrumento()"
        />
        <DxButton
          id="label"
          :icon="`fa fa-tag ${labelClass}`"
          :disabled="labelClass === ''"
          type="back"
          :hint="$t('label_instrumento')"
          @click="onClickLabelInstrumento()"
        />
      </span>
    </TitleInstrumentoWithBackButton>

    <DxPopup
      v-if="attachedID"
      :title="$t('ficherosAdjuntos')"
      :visible.sync="isPopupAttachedVisible"
      :fullScreen="false"
      :animation="{
        show: {
          type: 'pop',
        },
        hide: {
          type: 'pop',
          from: { scale: 1, opacity: 1 },
          to: { scale: 0, opacidy: 0 },
        },
      }"
      @hidden="refreshDataGrid"
    >
      <template>
        <div>
          <!-- <code>
            {{ attachedID }}
            <br />
            <br />
            {{ attachedResourceURI }}
            <br />
            <br />
            {{ attachedResource }}
          </code> -->
          <AttachedViewer
            ref="attachedViewerGeneral"
            v-if="attachedID && attachedResourceType === 'IMG'"
            :key="attachedID"
            :resourceURI="attachedResourceURI"
            :resourceType="attachedResourceType"
          ></AttachedViewer>

          <AttachedDocumentViewer
            ref="attachedDocumentViewerGeneral"
            v-if="attachedID && attachedResourceType === 'DOC'"
            :key="attachedID"
            :resourceURI="attachedResourceURI"
            :resourceType="attachedResourceType"
          ></AttachedDocumentViewer>
        </div>
      </template>
    </DxPopup>

    <div class="content-block">
      <h3>{{ $t("identificacion") }}</h3>
      <div>
        <div class="dx-card responsive-paddings">
          <InstrumentoItem ref="instrumento" :instrumento.sync="instrumento"></InstrumentoItem>

          <InstrumentoEdit v-if="true" ref="instrumento" :instrumento.sync="instrumento"></InstrumentoEdit>
        </div>
      </div>
    </div>

    <div class="content-block">
      <h3 class="d-flex justify-content-between">
        {{ $t("ubicaciones") }}
        <DxButton
          icon="add"
          type="default"
          stylingMode="contained"
          :hint="$t('agregar_ubicacion')"
          @click="onClickAgregarUbicacion"
          class="mx-3"
        />
      </h3>
      <div>
        <DxDataGrid
          @dataErrorOccurred="onDataErrorOccurred"
          ref="gridUbicaciones"
          id="gridUbicaciones"
          class="dx-card wide-card"
          :data-source="dataSourceUbicacionesInstrumento"
          :hover-state-enabled="true"
          :noDataText="$t('noSeEncuentraEnNingunaUbicacion')"
        >
          <DxEditingGrid :allow-updating="true" :allow-adding="false" :allow-deleting="true" mode="row" />
          <!-- <DxColumnGrid data-field="codigo" :caption="$t('codigo')"></DxColumnGrid>
            <DxColumnGrid data-field="nombre" :caption="$t('nombre')"></DxColumnGrid>
            <DxColumnGrid data-field="path_corto" :caption="$t('path_corto')"></DxColumnGrid>
            <DxColumnGrid data-field="path_largo" :caption="$t('path_largo')" data-type="datetime"></DxColumnGrid> -->
          <DxColumnGrid data-field="id_ubicaciones" :caption="$t('ubicacion')">
            <DxRequiredRule />
            <DxLookup display-expr="codigo" value-expr="id" :data-source="dataSourceConfigurationUbicaciones">
            </DxLookup>
          </DxColumnGrid>
        </DxDataGrid>
      </div>
    </div>

    <div class="content-block">
      <h3 class="d-flex justify-content-between">
        {{ $t("caracteristicas_a_calibrar") }}
        <DxButton
          icon="add"
          type="default"
          stylingMode="contained"
          :hint="$t('agregar_caracteristica')"
          @click="() => this.$refs.gridCaracteristicasCalibracion.instance.addRow()"
          class="mx-3"
        />
      </h3>
      <div>
        <DxDataGrid
          @dataErrorOccurred="onDataErrorOccurred"
          ref="gridCaracteristicasCalibracion"
          id="gridCaracteristicasCalibracion"
          class="dx-card wide-card"
          :data-source="dataSourceCaracteristicasCalibracionInstrumento"
          :hover-state-enabled="true"
          :noDataText="$t('noTieneNingunaCaracteristicaACalibrar')"
          @initNewRow="onInitNewRowGridCaracteristicasCalibracion"
        >
          <DxEditingGrid :allow-updating="true" :allow-adding="false" :allow-deleting="true" mode="row" />

          <DxColumnGrid data-field="secuencia" :caption="$t('secuencia')"> </DxColumnGrid>
          <DxColumnGrid data-field="caracteristica" :caption="$t('caracteristica')">
            <DxRequiredRule />
          </DxColumnGrid>
          <DxColumnGrid data-field="metodo" :caption="$t('metodo')"> </DxColumnGrid>
          <DxColumnGrid data-field="patron" :caption="$t('patron')"> </DxColumnGrid>
          <DxColumnGrid data-field="procedimiento" :caption="$t('procedimiento')"> </DxColumnGrid>
          <DxColumnGrid data-field="valor_pedido" :caption="$t('valor_pedido')"> </DxColumnGrid>
          <DxColumnGrid data-field="valor_teorico" :caption="$t('valor_teorico')"> </DxColumnGrid>
          <DxColumnGrid data-field="k" :caption="$t('k')" data-type="number"> </DxColumnGrid>
          <DxColumnGrid data-field="fecha" :caption="$t('fecha')" data-type="date" :visible="false"> </DxColumnGrid>
          <DxColumnGrid data-field="activa" :caption="$t('activa')" data-type="boolean"> </DxColumnGrid>

          <DxColumnGrid
            :allow-editing="false"
            data-field="documentos.length"
            width="50"
            cell-template="documentos"
            header-cell-template="documentos-header"
          >
          </DxColumnGrid>
          <template #documentos-header>
            <span class="pr-1">
              <i class="ion ion-md-attach"></i>
            </span>
          </template>
          <template #documentos="{ data }">
            <ButtonAttachedDocuments
              v-if="!data.row.isEditing"
              @click="
                onAttachedClick({
                  data: data.data,
                  resource: `instrumentos/${data.data.id_instrumentos}/caracteristicas/calibracion/${data.data.id}`,
                  type: 'DOC',
                })
              "
              :number="data?.data?.documentos?.length"
            />
          </template>

          <DxColumnGrid
            :allow-editing="false"
            data-field="fotos.length"
            cell-template="fotos"
            width="50"
            header-cell-template="fotos-header"
          >
          </DxColumnGrid>
          <template #fotos-header>
            <span class="pr-1">
              <i class="ion ion-md-camera"></i>
            </span>
          </template>
          <template #fotos="{ data }">
            <ButtonAttachedImages
              v-if="!data.row.isEditing"
              @click="
                onAttachedClick({
                  data: data.data,
                  resource: `instrumentos/${data.data.id_instrumentos}/caracteristicas/calibracion/${data.data.id}`,
                  type: 'IMG',
                })
              "
              :number="data?.data?.fotos?.length"
            />
          </template>
        </DxDataGrid>
      </div>
    </div>

    <div class="content-block">
      <h3 class="d-flex justify-content-between">
        {{ $t("caracteristicas_a_verificar") }}
        <DxButton
          icon="add"
          type="default"
          stylingMode="contained"
          :hint="$t('caracteristica')"
          @click="() => this.$refs.gridCaracteristicasVerificacion.instance.addRow()"
          class="mx-3"
        />
      </h3>

      <div>
        <DxDataGrid
          @dataErrorOccurred="onDataErrorOccurred"
          ref="gridCaracteristicasVerificacion"
          id="gridCaracteristicasVerificacion"
          class="dx-card wide-card"
          :data-source="dataSourceCaracteristicasVerificacionInstrumento"
          :hover-state-enabled="true"
          :noDataText="$t('noTieneNingunaCaracteristicaAVerificar')"
          @initNewRow="onInitNewRowGridCaracteristicasVerificacion"
        >
          >

          <DxEditingGrid :allow-updating="true" :allow-adding="false" :allow-deleting="true" mode="row" />

          <DxColumnGrid data-field="secuencia" :caption="$t('secuencia')"> </DxColumnGrid>
          <DxColumnGrid data-field="caracteristica" :caption="$t('caracteristica')">
            <DxRequiredRule />
          </DxColumnGrid>
          <DxColumnGrid data-field="metodo" :caption="$t('metodo')"> </DxColumnGrid>
          <DxColumnGrid data-field="patron" :caption="$t('patron')"> </DxColumnGrid>
          <DxColumnGrid data-field="procedimiento" :caption="$t('procedimiento')"> </DxColumnGrid>
          <DxColumnGrid data-field="valor_pedido" :caption="$t('valor_pedido')"> </DxColumnGrid>
          <DxColumnGrid data-field="valor_teorico" :caption="$t('valor_teorico')"> </DxColumnGrid>
          <DxColumnGrid data-field="fecha" :caption="$t('fecha')" data-type="date" :visible="false"> </DxColumnGrid>
          <DxColumnGrid data-field="activa" :caption="$t('activa')" data-type="boolean"> </DxColumnGrid>

          <DxColumnGrid
            data-field="documentos.length"
            :allow-editing="false"
            width="50"
            cell-template="documentos"
            header-cell-template="documentos-header"
          >
          </DxColumnGrid>
          <template #documentos-header>
            <span class="pr-1">
              <i class="ion ion-md-attach"></i>
            </span>
          </template>
          <template #documentos="{ data }">
            <ButtonAttachedDocuments
              v-if="!data.row.isEditing"
              @click="
                onAttachedClick({
                  data: data.data,
                  resource: `instrumentos/${data.data.id_instrumentos}/caracteristicas/verificacion/${data.data.id}`,
                  type: 'DOC',
                })
              "
              :number="data?.data?.documentos?.length"
            />
          </template>

          <DxColumnGrid
            :allow-editing="false"
            data-field="fotos.length"
            cell-template="fotos"
            width="50"
            header-cell-template="fotos-header"
          >
          </DxColumnGrid>
          <template #fotos-header>
            <span class="pr-1">
              <i class="ion ion-md-camera"></i>
            </span>
          </template>
          <template #fotos="{ data }">
            <ButtonAttachedImages
              v-if="!data.row.isEditing"
              @click="
                onAttachedClick({
                  data: data.data,
                  resource: `instrumentos/${data.data.id_instrumentos}/caracteristicas/verificacion/${data.data.id}`,
                  type: 'IMG',
                })
              "
              :number="data?.data?.fotos?.length"
            />
          </template>
        </DxDataGrid>
      </div>
    </div>

    <div class="content-block d-flex gap-5 mt-5">
      <div>
        <div class="d-flex justify-content-between">
          <h3 class="mb-1 pt-0">
            <router-link
              :to="{ name: 'calibraciones_instrumento' }"
              class="text-reset"
              :title="$t('ver_todas_las_calibraciones_realizadas_al_instrumento')"
            >
              {{ $t("calibraciones") }}
            </router-link>
            <DxButton
              icon="contentlayout"
              styling-mode="text"
              type="default"
              :hint="$t('ver_todas_las_calibraciones_realizadas_al_instrumento')"
              @click="$router.push({ name: 'calibraciones_instrumento' })"
            />
          </h3>
          <ButtonNewCalibracion :instrumento="instrumento" />
        </div>
        <DxDataGrid
          @dataErrorOccurred="onDataErrorOccurred"
          ref="gridCalibraciones"
          id="gridCalibraciones"
          class="dx-card wide-card"
          :data-source="dataSourceCalibracionesInstrumento"
          :hover-state-enabled="true"
          :noDataText="$t('noTieneNingunaCalibracion')"
          @rowDblClick="onRowDblClickCalibraciones"
          @rowRemoved="onRowRemovedCalibraciones"
        >
          <DxPaging :page-size="7" />
          <DxEditingGrid :allow-updating="false" :allow-adding="false" :allow-deleting="true" />
          <DxColumnGrid data-field="fecha" :caption="$t('fecha')" sort-order="desc" data-type="date"> </DxColumnGrid>
          <DxColumnGrid
            data-field="resultado"
            :caption="$t('resultado')"
            cell-template="itemTemplateResultado"
            alignment="center"
          >
            <DxLookup :data-source="dataSourceResultados" display-expr="text" value-expr="id"> </DxLookup>
          </DxColumnGrid>

          <template #itemTemplateResultado="{ data }">
            <div class="text-center">
              <img
                :src="`${dataSourceResultados?.find((x) => x.id === data.value)?.img}`"
                :title="dataSourceResultados?.find((x) => x.id === data.value)?.value"
                height="24"
              />
            </div>
          </template>

          <DxColumnGrid data-field="verificado_por" :caption="$t('verificado_por')"> </DxColumnGrid>
          <DxColumnGrid data-field="certificado_por" :caption="$t('certificado_por')"> </DxColumnGrid>
          <DxColumnGrid data-field="notas" :caption="$t('notas')"> </DxColumnGrid>
        </DxDataGrid>
      </div>
      <div>
        <div class="d-flex justify-content-between">
          <h3 class="mb-1 pt-0">
            <router-link
              :to="{ name: 'verificaciones_instrumento' }"
              class="text-reset"
              :title="$t('ver_todas_las_verificaciones_realizadas_al_instrumento')"
            >
              {{ $t("verificaciones") }}
            </router-link>
            <DxButton
              icon="contentlayout"
              type="default"
              styling-mode="text"
              :hint="$t('ver_todas_las_verificaciones_realizadas_al_instrumento')"
              @click="$router.push({ name: 'verificaciones_instrumento' })"
            />
          </h3>
          <!-- <DxButton
            icon="add"
            type="default"
            styling-mode="contained"
            :hint="$t('nueva_verificación')"
            :text="$t('nueva_verificación')"
            @click="onClickNewVerificacion"
          /> -->
          <ButtonNewVerificacion :instrumento="instrumento" />
        </div>

        <DxDataGrid
          @dataErrorOccurred="onDataErrorOccurred"
          ref="gridVerificaciones"
          id="gridVerificaciones"
          class="dx-card wide-card"
          :data-source="dataSourceVerificacionesInstrumento"
          :hover-state-enabled="true"
          :noDataText="$t('noTieneNingunaVerificacion')"
          @rowDblClick="onRowDblClickVerificaciones"
        >
          <DxPaging :page-size="7" />
          <DxEditingGrid :allow-updating="false" :allow-adding="false" :allow-deleting="true" />

          <DxColumnGrid data-field="fecha" :caption="$t('fecha')" sort-order="desc" data-type="date"> </DxColumnGrid>

          <DxColumnGrid
            data-field="resultado"
            :caption="$t('resultado')"
            cell-template="itemTemplateResultado"
            alignment="center"
          >
            <DxLookup :data-source="dataSourceResultados" display-expr="text" value-expr="id"> </DxLookup>
          </DxColumnGrid>

          <template #itemTemplateResultado="{ data }">
            <div class="text-center">
              <img
                :src="`${dataSourceResultados?.find((x) => x.id === data.value)?.img}`"
                :title="dataSourceResultados?.find((x) => x.id === data.value)?.value"
                height="24"
              />
            </div>
          </template>

          <DxColumnGrid data-field="verificado_por" :caption="$t('verificado_por')"> </DxColumnGrid>
          <DxColumnGrid data-field="certificado_por" :caption="$t('certificado_por')"> </DxColumnGrid>
          <DxColumnGrid data-field="notas" :caption="$t('notas')"> </DxColumnGrid>
        </DxDataGrid>
      </div>
    </div>

    <BlockRR :idInstrumento="id" />

    <div class="content-block">
      <h3 class="d-flex justify-content-between">
        {{ $t("patrones_a_utilizar") }}
        <DxButton
          icon="add"
          type="default"
          stylingMode="contained"
          :hint="$t('agregar_patron')"
          @click="() => this.$refs.gridPatrones.instance.addRow()"
          class="mx-3"
        />
      </h3>
      <div>
        <DxDataGrid
          @dataErrorOccurred="onDataErrorOccurred"
          ref="gridPatrones"
          id="gridPatrones"
          class="dx-card wide-card"
          :data-source="dataSourcePatronesInstrumento"
          :hover-state-enabled="true"
          :noDataText="$t('noTieneNingunPatron')"
        >
          <DxEditingGrid :allow-updating="true" :allow-adding="false" :allow-deleting="true" mode="row" />

          <!-- <DxColumnGrid data-field="codigo" :caption="$t('codigo')"></DxColumnGrid>
                  <DxColumnGrid data-field="nombre" :caption="$t('nombre')"></DxColumnGrid>
                  <DxColumnGrid data-field="path_corto" :caption="$t('path_corto')"></DxColumnGrid>
                  <DxColumnGrid data-field="path_largo" :caption="$t('path_largo')" data-type="datetime"></DxColumnGrid> -->

          <DxColumnGrid data-field="id_patron" :caption="$t('patron')">
            <DxLookup display-expr="codigo" value-expr="id" :data-source="dataSourceConfigurationPatrones"> </DxLookup>
          </DxColumnGrid>
          <DxColumnGrid data-field="nombre" :caption="$t('nombre')"> </DxColumnGrid>
          <DxColumnGrid data-field="fecha" :caption="$t('fecha')" data-type="date" :visible="false"> </DxColumnGrid>
          <DxColumnGrid data-field="notas" :caption="$t('notas')"> </DxColumnGrid>

          <DxColumnGrid
            :allow-editing="false"
            data-field="documentos.length"
            width="50"
            cell-template="documentos"
            header-cell-template="documentos-header"
          >
          </DxColumnGrid>
          <template #documentos-header>
            <span class="pr-1">
              <i class="ion ion-md-attach"></i>
            </span>
          </template>
          <template #documentos="{ data }">
            <ButtonAttachedDocuments
              v-if="!data.row.isEditing"
              @click="
                onAttachedClick({
                  data: data.data,
                  resource: `instrumentos/${data.data.id_instrumentos}/patrones/${data.data.id}`,
                  type: 'DOC',
                })
              "
              :number="data?.data?.documentos?.length"
            />
          </template>

          <DxColumnGrid
            :allow-editing="false"
            data-field="fotos.length"
            cell-template="fotos"
            width="50"
            header-cell-template="fotos-header"
          >
          </DxColumnGrid>
          <template #fotos-header>
            <span class="pr-1">
              <i class="ion ion-md-camera"></i>
            </span>
          </template>
          <template #fotos="{ data }">
            <ButtonAttachedImages
              v-if="!data.row.isEditing"
              @click="
                onAttachedClick({
                  data: data.data,
                  resource: `instrumentos/${data.data.id_instrumentos}/patrones/${data.data.id}`,
                  type: 'IMG',
                })
              "
              :number="data?.data?.fotos.length"
            />
          </template>
        </DxDataGrid>
      </div>
    </div>

    <div class="content-block">
      <h3 class="d-flex justify-content-between">
        {{ $t("procedimientos") }}
        <DxButton
          icon="add"
          type="default"
          stylingMode="contained"
          :hint="$t('agregar_procedimiento')"
          @click="() => this.$refs.gridProcedimientos.instance.addRow()"
          class="mx-3"
        />
      </h3>
      <div>
        <DxDataGrid
          @dataErrorOccurred="onDataErrorOccurred"
          ref="gridProcedimientos"
          id="gridProcedimientos"
          class="dx-card wide-card"
          :data-source="dataSourceProcedimientosInstrumento"
          :hover-state-enabled="true"
          :noDataText="$t('noTieneNingunProcedimiento')"
        >
          <DxEditingGrid :allow-updating="true" :allow-adding="false" :allow-deleting="true" mode="row" />

          <DxColumnGrid data-field="nombre" :caption="$t('nombre')">
            <DxRequiredRule />
          </DxColumnGrid>
          <DxColumnGrid data-field="fecha" :caption="$t('fecha')" data-type="date"> </DxColumnGrid>
          <DxColumnGrid data-field="notas" :caption="$t('notas')"> </DxColumnGrid>

          <DxColumnGrid
            :allow-editing="false"
            data-field="documentos.length"
            width="50"
            cell-template="documentos"
            header-cell-template="documentos-header"
          >
          </DxColumnGrid>
          <template #documentos-header>
            <span class="pr-1">
              <i class="ion ion-md-attach"></i>
            </span>
          </template>
          <template #documentos="{ data }">
            <ButtonAttachedDocuments
              v-if="!data.row.isEditing"
              @click="
                onAttachedClick({
                  data: data.data,
                  resource: `instrumentos/${data.data.id_instrumentos}/procedimientos/${data.data.id}`,
                  type: 'DOC',
                })
              "
              :number="data?.data?.documentos?.length"
            />
          </template>

          <DxColumnGrid
            :allow-editing="false"
            data-field="fotos.length"
            cell-template="fotos"
            width="50"
            header-cell-template="fotos-header"
          >
          </DxColumnGrid>
          <template #fotos-header>
            <span class="pr-1">
              <i class="ion ion-md-camera"></i>
            </span>
          </template>
          <template #fotos="{ data }">
            <ButtonAttachedImages
              v-if="!data.row.isEditing"
              @click="
                onAttachedClick({
                  data: data.data,
                  resource: `instrumentos/${data.data.id_instrumentos}/procedimientos/${data.data.id}`,
                  type: 'IMG',
                })
              "
              :number="data?.data?.fotos?.length"
            />
          </template>
        </DxDataGrid>
      </div>
    </div>

    <div class="content-block">
      <h3 class="d-flex justify-content-between">
        {{ $t("reuniones") }}
        <DxButton
          icon="add"
          type="default"
          stylingMode="contained"
          :hint="$t('agregar_ubicacion')"
          @click="() => this.$refs.gridReuniones.instance.addRow()"
          class="mx-3"
        />
      </h3>
      <div>
        <DxDataGrid
          @dataErrorOccurred="onDataErrorOccurred"
          ref="gridReuniones"
          id="gridReuniones"
          class="dx-card wide-card"
          :data-source="dataSourceReunionesInstrumento"
          :hover-state-enabled="true"
          :noDataText="$t('noTieneNingunaReunion')"
        >
          <DxEditingGrid :allow-updating="true" :allow-adding="false" :allow-deleting="true" mode="row" />

          <DxColumnGrid data-field="tema" :caption="$t('tema')">
            <DxRequiredRule />
          </DxColumnGrid>
          <DxColumnGrid data-field="fecha" :caption="$t('fecha')" data-type="date"> </DxColumnGrid>

          <DxColumnGrid
            :allow-editing="false"
            data-field="documentos.length"
            width="50"
            cell-template="documentos"
            header-cell-template="documentos-header"
          >
          </DxColumnGrid>
          <template #documentos-header>
            <span class="pr-1">
              <i class="ion ion-md-attach"></i>
            </span>
          </template>
          <template #documentos="{ data }">
            <ButtonAttachedDocuments
              v-if="!data.row.isEditing"
              @click="
                onAttachedClick({
                  data: data.data,
                  resource: `instrumentos/${data.data.id_instrumentos}/reuniones/${data.data.id}`,
                  type: 'DOC',
                })
              "
              :number="data?.data?.documentos?.length"
            />
          </template>

          <DxColumnGrid
            :allow-editing="false"
            data-field="fotos.length"
            cell-template="fotos"
            width="50"
            header-cell-template="fotos-header"
          >
          </DxColumnGrid>
          <template #fotos-header>
            <span class="pr-1">
              <i class="ion ion-md-camera"></i>
            </span>
          </template>
          <template #fotos="{ data }">
            <ButtonAttachedImages
              v-if="!data.row.isEditing"
              @click="
                onAttachedClick({
                  data: data.data,
                  resource: `instrumentos/${data.data.id_instrumentos}/reuniones/${data.data.id}`,
                  type: 'IMG',
                })
              "
              :number="data?.data?.fotos?.length"
            />
          </template>
        </DxDataGrid>
      </div>
    </div>

    <div class="content-block">
      <h3 class="d-flex justify-content-between">
        {{ $t("fotos") }}
        <DxButton
          icon="add"
          type="default"
          stylingMode="contained"
          :hint="$t('agregar_imagen')"
          @click="() => this.$refs.attachedViewer.add()"
          class="mx-3"
        />
      </h3>

      <div>
        <div class="dx-card responsive-paddings">
          <AttachedViewer
            ref="attachedViewer"
            :resourceURI="`instrumentos/${this.id}`"
            :showAddButton="false"
          ></AttachedViewer>
        </div>
      </div>
    </div>

    <div class="content-block">
      <h3 class="d-flex justify-content-between">
        {{ $t("documentos") }}
        <DxButton
          icon="add"
          type="default"
          stylingMode="contained"
          :hint="$t('agregar_documento')"
          @click="() => this.$refs.attachedDocumentViewer.add()"
          class="mx-3"
        />
      </h3>

      <div>
        <div class="dx-card responsive-paddings">
          <AttachedDocumentViewer
            ref="attachedDocumentViewer"
            :resourceURI="`instrumentos/${this.id}`"
            :showAddButton="false"
          ></AttachedDocumentViewer>
        </div>
      </div>
    </div>

    <div class="content-block danger-zone">
      <h3 class="text-danger"><i class="dx-icon dx-icon-warning"></i> {{ $t("zonaDePeligro") }}</h3>
      <div class="dx-card responsive-paddings alert alert-danger rounded">
        <ConfirmDeleteButton
          :text="$t('eliminarInstrumentos')"
          :text-to-confirm="instrumento.nombre"
          @confirmed="deleteInstrumento"
        >
        </ConfirmDeleteButton>
      </div>
    </div>

    <Prompt ref="prompt"></Prompt>
  </div>
</template>

<script>
// import DxNumberBox from "devextreme-vue/number-box";
//REPORTS
import RPTInst from "../../components/instrumentos/reports/RPTInstrumento.vue";
import LBLInst from "../../components/instrumentos/reports/etiqueta.vue";

import TitleInstrumentoWithBackButton from "@/components/core/TitleInstrumentoWithBackButton.vue";

import { DxButton } from "devextreme-vue/button";
import DxPopup from "devextreme-vue/popup";
import Prompt from "@/components/prompt";
import InstrumentoItem from "../../components/instrumentos/item.vue";
import InstrumentoEdit from "../../components/instrumentos/edit.vue";

import AttachedViewer from "@/components/attached-viewer.vue";
import AttachedDocumentViewer from "@/components/attached-document-viewer.vue";

import { CustomStoreInstrumentos } from "../../data/instrumentos/InstrumentoDataSource";

import { DataSourceCalibracionesInstrumento } from "../../data/instrumentos/CalibracionesInstrumentoDataSource";
import { DataSourceVerificacionesInstrumento } from "../../data/instrumentos/VerificacionesInstrumentoDataSource";
import { DataSourceUbicacionesInstrumento } from "../../data/instrumentos/UbicacionesInstrumentoDataSource";
import { CustomStoreUbicaciones } from "../../data/instrumentos/UbicacionesDataSource";
import { DataSourcePatronesInstrumento } from "../../data/instrumentos/PatronesInstrumentoDataSource";
import { CustomStorePatrones } from "../../data/instrumentos/PatronesDataSource";
import { DataSourceProcedimientosInstrumento } from "../../data/instrumentos/ProcedimientosInstrumentoDataSource";
import { DataSourceReunionesInstrumento } from "../../data/instrumentos/ReunionesInstrumentoDataSource";
import { DataSourceCaracteristicasCalibracionInstrumento } from "../../data/instrumentos/CaracteristicasCalibracionInstrumentoDataSource";
import { DataSourceCaracteristicasVerificacionInstrumento } from "../../data/instrumentos/CaracteristicasVerificacionInstrumentoDataSource";

import {
  DxDataGrid,
  //  DxButton as DxButtonGrid,
  DxColumn as DxColumnGrid,
  DxEditing as DxEditingGrid,
  DxLookup,
  DxPaging,
  // DxRangeRule,
} from "devextreme-vue/data-grid";

// import auth from "../../auth";
// import { get } from "@/core/fetch.js";
import { DxRequiredRule } from "devextreme-vue/validator";
import ButtonNewCalibracion from "../../components/instrumentos/calibraciones/ButtonNewCalibracion.vue";
import ButtonNewVerificacion from "../../components/instrumentos/calibraciones/ButtonNewVerificacion.vue";

import resultados from "@/data/instrumentos/Resultados.js";

import ButtonAttachedImages from "../../components/button-attached-images.vue";
import ButtonAttachedDocuments from "../../components/button-attached-documents.vue";
import ConfirmDeleteButton from "../../components/core/ConfirmDeleteButton.vue";

import BlockRR from "../../components/instrumentos/blocks/rr.vue";

export default {
  components: {
    RPTInst,
    LBLInst,
    TitleInstrumentoWithBackButton,
    DxButton,
    // DxNumberBox,
    AttachedViewer,
    AttachedDocumentViewer,
    DxPopup,
    InstrumentoItem,
    InstrumentoEdit,
    Prompt,
    //
    DxDataGrid,
    DxColumnGrid,
    DxLookup,
    DxEditingGrid,
    DxPaging,
    DxRequiredRule,
    ButtonNewCalibracion,
    ButtonNewVerificacion,
    ButtonAttachedImages,
    ButtonAttachedDocuments,
    ConfirmDeleteButton,
    //blocks
    BlockRR,
  },

  data() {
    const id = this.$route.params.id;
    return {
      componentKey: 0,
      id,
      instrumento: {},
      ultimaCalibracion: {},

      isReportVisible: false,
      isLabelVisible: false,

      // grid lookups no admiten DataSources...
      // pero sí un dataSourceConfiguration object...

      dataSourceConfigurationUbicaciones: {
        paginate: true,
        store: new CustomStoreUbicaciones(),
      },
      dataSourceConfigurationPatrones: {
        paginate: true,
        store: new CustomStorePatrones(),
      },

      customStoreInstrumentos: new CustomStoreInstrumentos(),

      dataSourceUbicacionesInstrumento: new DataSourceUbicacionesInstrumento(id),
      dataSourcePatronesInstrumento: new DataSourcePatronesInstrumento(id),
      dataSourceProcedimientosInstrumento: new DataSourceProcedimientosInstrumento(id),
      dataSourceReunionesInstrumento: new DataSourceReunionesInstrumento(id),
      dataSourceCalibracionesInstrumento: new DataSourceCalibracionesInstrumento(id),
      dataSourceVerificacionesInstrumento: new DataSourceVerificacionesInstrumento(id),
      dataSourceCaracteristicasCalibracionInstrumento: new DataSourceCaracteristicasCalibracionInstrumento(id),
      dataSourceCaracteristicasVerificacionInstrumento: new DataSourceCaracteristicasVerificacionInstrumento(id),

      isPopupAttachedVisible: false,
      attachedID: undefined,
      attachedResourceURI: undefined,
    };
  },

  methods: {
    onDataErrorOccurred(e) {
      // evento de la datagrid que se dispara cuando hay un error
      // se puede alterar el mensaje de error (e.error.message)
      e.error.message = this.$t(e.error.message);
      this.$notify(e.error.message, "error", 3000);
    },

    onAttachedClick({ data, resource, type }) {
      console.log("🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥🔥");
      console.log("onAttachedClick", data, resource, type);
      this.isPopupAttachedVisible = true;
      this.attachedID = data.id;
      this.attachedResourceURI = resource;
      this.attachedResourceType = type;
    },

    refreshDataGrid() {
      console.log("refreshDataGrid");

      this.$refs.gridReuniones.instance.refresh();
      this.$refs.gridPatrones.instance.refresh();
      this.$refs.gridProcedimientos.instance.refresh();
      // this.$refs.gridVerificaciones.instance.refresh();
      // this.$refs.gridCalibraciones.instance.refresh();
      // this.$refs.gridUbicaciones.instance.refresh();
      this.$refs.gridCaracteristicasCalibracion.instance.refresh();
      this.$refs.gridCaracteristicasVerificacion.instance.refresh();
    },

    onPrinted() {
      console.log("onPrinted");
      this.$notify("Documento generado!", "success", 3000);
      this.isReportVisible = false;
      this.isLabelVisible = false;
    },

    onPrintedError() {
      console.log("onPrintedError");
      this.$notify("Error al intentar generar el documento", "error", 3000);
      this.isReportVisible = false;
      this.isLabelVisible = false;
    },

    async onClickReportInstrumento() {
      this.isReportVisible = true;

      this.$nextTick(() => {
        this.$refs.rptInstrumento.print();
      });
    },
    async onClickLabelInstrumento() {
      console.log("onClickLabelInstrumento");
      // this.$refs.lblInstrumento.print();
      this.isLabelVisible = true;

      this.$nextTick(() => {
        this.$refs.lblInstrumento.print();
      });
    },
    async load() {
      // const urls = [
      //   `${global.API_URL}/instrumentos/${this.id}`,
      //   `${global.API_URL}/instrumentos/${this.id}/calibraciones/ultima`,
      // ];

      // const data = await Promise.allSettled(urls.map((u) => this.$fetch.get(u)));

      // this.instrumento = data[0].status === "fulfilled" ? data[0].value : {};
      // this.ultimaCalibracion = data[1].status === "fulfilled" ? data[1].value : {};

      this.instrumento = await this.customStoreInstrumentos.byKey(this.id);
      this.ultimaCalibracion = await this.getUltimaCalibracion(this.id);
    },

    async getUltimaCalibracion(id) {
      const url = `${global.API_URL}/instrumentos/${id}/calibraciones/ultima`;
      const data = await this.$fetch.get(url);
      return data;
    },

    onEditorPreparing(e) {
      console.log("onEditorPreparing", e);
    },

    onInitNewRow(e) {
      console.log("onInitNewRow", e);
      // e.data.familia = false;
      // e.data.situacion = 0;
      // e.data.fecha_emision = new Date();
    },
    onEditingStart(e) {
      console.log("onEditingStart", e);
      // e.data.familia = false;
      // e.data.situacion = 0;
      // e.data.fecha_emision = new Date();
    },

    onInitNewRowGridCaracteristicasCalibracion(e) {
      e.data.fecha = Date.now();
      e.data.activa = true;
      e.data.k = 2;
    },

    onInitNewRowGridCaracteristicasVerificacion(e) {
      e.data.fecha = Date.now();
      e.data.activa = true;
    },

    onRowDblClickCalibraciones(e) {
      // this.$router.push({
      //   name: "lineas_calibracion",
      //   params: {
      //     id_instrumento: e.data.id_instrumentos,
      //     id: e.key,
      //   },
      // });
      this.$router.push({
        name: "calibracion",
        params: {
          id_instrumento: e.data.id_instrumentos,
          id: e.key,
        },
      });
    },

    async onRowRemovedCalibraciones(e) {
      console.log("onRowRemovedCalibraciones", e);
      // Se ha borrado una calibracion (y sus lineas) y el instrumento puede haber cambiado de estado
      // por lo que hay que recargar el instrumento
      await this.load();
    },

    onRowDblClickVerificaciones(e) {
      this.$router.push({
        name: "verificacion",
        params: {
          id_instrumento: e.data.id_instrumentos,
          id: e.key,
        },
      });
    },

    onClickAgregarUbicacion() {
      this.$refs.gridUbicaciones.instance.addRow();
    },

    async deleteInstrumento() {
      try {
        // const result = await this.dataSourceInstrumento.store().remove(this.id);
        const result = await this.customStoreInstrumentos.remove(this.id);
        console.log(result);
        this.$router.push({ name: "instrumentos" });
        this.$notify(this.$t("borrado_correctamente"), "success", 5000);
      } catch (e) {
        console.error(e);
        this.$notify(`${this.$t("No se ha podido eliminar")}: ${this.$t(e.message)}`, "error", 5000);
      }
    },
  },

  async mounted() {
    // await this.responsablesDataSource.load();
    // await this.load();
    // console.log(this.responsablesDataSource.items());
  },

  async created() {
    await this.load(); // VERIFICAR SI ESTO ES CORRECTO...
  },

  computed: {
    idioma() {
      return this.$i18n.locale;
    },

    dataSourceResultados() {
      return resultados.map((e) => ({
        ...e,
        value: this.$t(e.text),
      }));
    },

    labelClass() {
      if (this.instrumento.estado === "U" && this.ultimaCalibracion?.resultado === 1) return "text-success";
      if (this.instrumento.estado === "U" && this.ultimaCalibracion?.resultado === 3) return "text-warning";
      if (this.instrumento.estado === "O") return "text-danger";

      return "";
    },
  },
  watch: {
    idioma() {
      this.componentKey++;
    },
  },
};
</script>

<style lang="scss">
a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  text-decoration-color: var(--accent);
  text-decoration-thickness: 3px;
}

.dx-selection {
  --bs-bg-opacity: 0.5 !important;
}

.dx-selection:not(.bg-success, .bg-warning, .bg-danger) {
  background-color: rgba(var(--accent-rgb), var(--bs-bg-opacity)) !important;
}
</style>

<style scoped>
/* .content-block > h3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-block > div {
  margin-top: 0;
} */
</style>
