<template>

  <dx-scroll-view height="100%" width="100%" class="with-footer single-card">
    <div class="dx-card content">
      <div class="header">
        <div class="title">{{ title }}</div>
        <div class="description">{{ description }}</div>
      </div>
      <slot />
    </div>
    <div class="text-end p-2">
      <dx-button
        type="back"
        stylingMode="text"
        class="moon-button"
        styling-mode="text" @click="handleDarkMode"
        :icon="isDarkMode ? 'fa fa-sun-o' : 'fa fa-moon-o'">
      </dx-button>
    </div>
  </dx-scroll-view>


</template>

<script>
import DxScrollView from "devextreme-vue/scroll-view";
import DxButton from "devextreme-vue/button";

export default {
  components: {
    DxScrollView,
    DxButton,
  },
  data() {
    return {
      isDarkMode: document.querySelector("html").classList.contains("dx-swatch-additional"),

    }
  },
  props: {
    title: String,
    description: String
  },
  methods: {
    handleDarkMode() {
      this.isDarkMode = !this.isDarkMode
      document.querySelector("html").classList.toggle("dx-swatch-additional")
    }
  }
};
</script>

<style lang="scss">
// @import "../themes/generated/variables.base.scss";
// @import "../themes/generated/variables.additional.scss";
@import "../themes/generated/variables.base.scss";

.single-card {
  width: 100%;
  height: 100%;

  .dx-card {
    width: 330px;
    margin: auto auto;
    padding: 40px;
    flex-grow: 0;

    .screen-x-small & {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      border: 0;
      flex-grow: 1;
    }

    .header {
      margin-bottom: 30px;

      .title {
        color: $base-text-color;
        line-height: 28px;
        font-weight: 500;
        font-size: 24px;
      }

      .description {
        color: rgba($base-text-color, alpha($base-text-color) * 0.7);
        line-height: 18px;
      }
    }
  }
}

.dx-swatch-additional {
  @import "../themes/generated/variables.additional.scss";

  .single-card {
    .dx-card {
      .header {
        .title {
          color: $base-text-color;
        }

        .description {
          color: rgba($base-text-color, alpha($base-text-color) * 0.7);
        }
      }
    }
  }
}
</style>
