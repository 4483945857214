// exports.situaciones = [
//     {
//         id: 0,
//         value: this.$t("prototipo"),
//         img: "@/assets/situaciones/prototype.png",
//     },
//     {
//         id: 1,
//         value: this.$t("prelanzamiento"),
//         img: "@/assets/situaciones/prelaunch.png",
//     },
//     {
//         id: 2,
//         value: this.$t("produccion"),
//         img: "@/assets/situaciones/launch.png",
//     },
//     {
//         id: 3,
//         value: this.$t("descontinuado"),
//         img: "@/assets/situaciones/disconnected.png",
//     },
// ]



export default [
    {
        id: 0,
        value: "prototipo",
        img: "prototype.png",
    },
    {
        id: 1,
        value: "prelanzamiento",
        img: "prelaunch.png",
    },
    {
        id: 2,
        value: "produccion",
        img: "launch.png",
    },
    {
        id: 3,
        value: "descontinuado",
        img: "disconnected.png",
    },
]


