export default [
    {
        id: "U",
        // value: this.$t("enUso"),
        value: "enUso",
        img: "instrumentos/estados/u.png",
    },
    {
        id: "O",
        // value: this.$t("obsoleto"),
        value: "obsoleto",
        img: "instrumentos/estados/o.png",
    },
    {
        id: "R",
        // value: this.$t("reserva"),
        value: "reserva",
        img: "instrumentos/estados/r.png",
    },
    {
        id: "P",
        // value: this.$t("enReparacion"),
        value: "enReparacion",
        img: "instrumentos/estados/p.png",
    },
];
