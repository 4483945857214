<template>
  <h2 class="content-block">
    <DxButton icon="arrowleft" type="back" styling-mode="text" @click="goBack" />
    {{ text }}
    <slot> </slot>
  </h2>
</template>

<script>
import { DxButton } from "devextreme-vue/button";

export default {
  props: ["text", "backTo"],
  components: {
    DxButton,
  },
  methods: {
    goBack() {
      console.log("backTo", this.backTo);
      // this.$router.go(this.backTo ?? -1);

      if (this.backTo === undefined) return this.$router.go(-1);
      this.$router.push(this.backTo);
    },
  },
};
</script>

<style></style>
