<template>
  <div>
    <div
      id="etiqueta"
      class="etiqueta text-start"
      :class="{
        '': instrumento.estado === 'U' && ultimaCalibracion?.resultado === 1,
        'bg-warning': instrumento.estado === 'U' && ultimaCalibracion?.resultado === 3,
        'bg-danger': instrumento.estado === 'O',
      }"
    >
      <div class="grid" v-if="instrumento.estado === 'U' && ultimaCalibracion?.resultado === 1">
        <!-- <div>Instr.</div>
        <div>{{ instrumento.nombre }}</div> -->
        <div>Cod.</div>
        <div>{{ instrumento.codigo }}</div>
        <div>{{ $t("fecha") }}</div>
        <div>
          {{ instrumento?.fecha_ultima_calibracion && $moment(instrumento?.fecha_ultima_calibracion).format("L") }}
        </div>
        <div>Pr.calib</div>
        <div>
          {{ instrumento?.fecha_proxima_calibracion && $moment(instrumento?.fecha_proxima_calibracion).format("L") }}
        </div>
      </div>

      <div class="grid" v-else-if="instrumento.estado === 'U' && ultimaCalibracion?.resultado === 3">
        <!-- <div>Instr.</div>
        <div>{{ instrumento.nombre }}</div> -->
        <div>Cod.</div>
        <div>{{ instrumento.codigo }}</div>
        <div>{{ $t("fecha") }}</div>
        <div>
          {{ instrumento?.fecha_ultima_calibracion && $moment(instrumento?.fecha_ultima_calibracion).format("L") }}
        </div>
        <div>Pr.calib</div>
        <div>
          {{ instrumento?.fecha_proxima_calibracion && $moment(instrumento?.fecha_proxima_calibracion).format("L") }}
        </div>
        <div>{{ $t("Restr.") }}</div>
        <div class=""></div>
      </div>

      <div class="grid" v-else-if="instrumento.estado === 'O'">
        <!-- <div>Instr.</div>
        <div>{{ instrumento.nombre }}</div> -->
        <div>Cod.</div>
        <div>{{ instrumento.codigo }}</div>

        <div class="bigger">{{ $t("fuera de servicio") }}</div>
      </div>

      <div v-else>No hay etiqueta</div>
    </div>
  </div>
</template>

<script>
import jspdf from "jspdf";
import { get } from "@/core/fetch.js";

export default {
  props: ["idInstrumento"],

  data() {
    return {
      instrumento: {},
      ultimaCalibracion: {},
      loaded: false,
    };
  },

  async mounted() {
    console.log("%cmounted", "color: red; font-weight: bold;"); // with colors:
    this.load();
  },

  methods: {
    async load() {
      console.log("load"); // with colors:

      const urls = [
        /*   `${global.API_URL}/instrumentos/${this.idInstrumento}`,
        `${global.API_URL}/instrumentos/${this.idInstrumento}/calibraciones/ultima`, */
        `${global.API_URL}/instrumentos/${this.$props.idInstrumento}`,
        `${global.API_URL}/instrumentos/${this.$props.idInstrumento}/calibraciones/ultima`,
      ];
      //obtengo todos los fetch en paralelo

      const data = await Promise.allSettled(urls.map((u) => get(u)));

      this.instrumento = data[0].status === "fulfilled" ? data[0].value : {};
      this.ultimaCalibracion = data[1].status === "fulfilled" ? data[1].value : {};

      // if (!this.canPrint) return this.$notify("No se puede imprimir la etiqueta", "warning", 3000);
      // this.print();

      this.loaded = true;
    },

    canPrint: function () {
      console.log(this.instrumento.estado);
      return (
        (this.instrumento.estado === "U" && this.ultimaCalibracion?.resultado === 1) ||
        (this.instrumento.estado === "U" && this.ultimaCalibracion?.resultado === 3) ||
        this.instrumento.estado === "O"
      );
    },

    print: async function () {
      this.$notify("Obteniendo datos", "warning");

      // await to this.loaded is true
      await new Promise((resolve) => {
        const interval = setInterval(() => {
          if (this.loaded) {
            clearInterval(interval);
            resolve();
          }
        }, 100);
      });

      this.$notify("Generando etiqueta", "warning");

      if (this.canPrint()) {
        // this.$notify("Imprimiendo etiqueta", "success", 3000);
      } else {
        this.$notify("No se puede imprimir la etiqueta", "error", 3000);
        this.$emit("error");
        return;
      }

      const CM_TO_PIXEL = 37.7952755906;
      //alto x ancho
      //  const doc = new jspdf( ['pt','pt,[87,145]']);
      const doc = new jspdf({
        unit: "px",
        orientation: "landscape",
        hotfixes: ["px_scaling"],
        format: [1.5 * CM_TO_PIXEL, 3 * CM_TO_PIXEL],
        // autoPaging: false,
      });

      doc.html(this.$el.innerHTML, {
        callback: (doc) => {
          doc.setProperties({
            title: `label-${this.instrumento.codigo}`,
          });

          while (doc.internal.getNumberOfPages() > 1) doc.deletePage(2);

          doc.save(`label-${this.instrumento.codigo}.pdf`);

          this.$emit("printed");
        },
      });

      // autotable(doc, {
      //   theme: "grid",
      //   body: [
      //     ["cod.", this.instrumento.codigo],
      //     ["nombre", this.instrumento.nombre],
      //     ["calibrado", this.instrumento.fecha_ultima_calibracion],
      //     ["proxima", this.instrumento.fecha_proxima_calibracion],
      //   ],
      //   styles: {
      //     cellPadding: 1,
      //     fontSize: 8,
      //     overflow: "linebreak",
      //     columnWidth: "wrap",
      //   },
      //   // startY: 0,
      //   startX: 0,
      //   margin: 0,
      //   tableWidth: 1.5 * CM_TO_PIXEL,
      // });

      // doc.save(`etiqueta-${this.instrumento.codigo}.pdf`);

      this.loaded = true;
    },
  },
};
</script>

<style lang="scss">
.etiqueta {
  width: 3cm;
  height: 1.5cm;
  max-height: 1.5cm;
  overflow: hidden;
  margin: 0cm;
  padding: 0cm;
  box-sizing: border-box;
  // background-color: lime;
  border: 1px solid black;
}

.etiqueta > div {
  height: 100%;
}

.etiqueta .grid {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
  align-items: stretch;
  justify-content: middle;
  height: 100%;
}

.etiqueta .grid div {
  border-collapse: collapse;
  padding: 0 5px 0 2px;
  margin: 0;
  border: 1px solid black;
  text-align: start;
  font-size: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: grid;
  align-items: center;

  width: 100%;
}

/* GREEN LABEL */

.etiqueta .grid {
  grid-template-columns: max-content 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}

.etiqueta .grid div:nth-child(1),
.etiqueta .grid div:nth-child(2) {
  height: 100%;
}

.etiqueta .grid div:nth-child(n) {
  border-left: none;
}

.etiqueta .grid div:nth-child(2n) {
  border-right: none;
}

.etiqueta .grid div:nth-child(n) {
  border-top: none;
}

.etiqueta .grid div:nth-child(5),
.etiqueta .grid div:nth-child(6) {
  border-bottom: none;
}

/* YELLOW LABEL */

.etiqueta.bg-warning .grid {
  grid-template-columns: max-content 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
}

.etiqueta .grid div:nth-child(5),
.etiqueta .grid div:nth-child(6) {
  border-bottom: 1px solid black;
}

.etiqueta.bg-warning .grid div:nth-child(7),
.etiqueta.bg-warning .grid div:nth-child(8) {
  border-bottom: none;
}

/* RED LABEL */

.etiqueta .grid {
  grid-template-columns: max-content 1fr;
  // grid-template-rows: 1fr 1fr;
}

.etiqueta .grid div.bigger {
  font-size: 10px;
  font-weight: bold;
  grid-row: span 2 / span 2;
  grid-column: span 2 / span 2;
  border-right: none;
  border-left: none;
  border-bottom: none;
  text-align: center;
  text-transform: uppercase;
}

/* .etiqueta_verde {
  background-color: green;
  width: 5cm;
  margin: 0cm;
  box-sizing: border-box;
}

.etiqueta_amarilla {
  background-color: yellow;
  width: 5cm;
  margin: 0cm;
  box-sizing: border-box;
}

.etiqueta_roja {
  background-color: red;
  width: 5cm;
  margin: 0m;
  box-sizing: border-box;
} */
</style>
