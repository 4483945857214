<template>
  <div :key="componentKey">
    <TitleInstrumentoWithBackButton :instrumento="instrumento" />

    <h2 class="content-block">{{ $t("calibracion") }}</h2>

    <Debug>
      <pre>{{ instrumento }}</pre>
      <pre>{{ calibracion }}</pre>
    </Debug>
    <div class="content-block d-flex flex-wrap justify-content-between mt-4">
      <div class="d-flex flex-wrap gap-3 column-gap-5">
        <DxTextBox :value.sync="calibracion.verificado_por" :label="$t('verificado_por')"></DxTextBox>
        <DxTextBox :value.sync="calibracion.certificado_por" :label="$t('certificado_por')"> </DxTextBox>
        <DxDateBox :value.sync="calibracion.fecha" :label="$t('fecha')">
          <DxValidator ref="fechaValidator">
            <DxRequiredRule :message="$t('error_fecha_required')" />
          </DxValidator>
        </DxDateBox>
      </div>
      <div>
        <DxSelectBox
          :label="$t('resultado')"
          :data-source="dataSourceResultados"
          :value.sync="calibracion.resultado"
          display-expr="value"
          value-expr="id"
          field-template="field"
          item-template="item"
        >
          <template #field="{ data }">
            <!-- <Field :field-data="data"/> -->
            <div class="d-flex">
              <img v-if="data" :src="data.img" class="py-2 ps-3 pe-0" />
              <DxTextBox :value="data?.value" :read-only="true" />
            </div>
          </template>
          <template #item="{ data }">
            <!-- <Item :item-data="data"/> -->
            <div class="d-flex align-items-center">
              <img :src="data.img" />
              <span class="ps-2">{{ data?.value }}</span>
            </div>
          </template>

          <DxValidator ref="resultadoValidador">
            <DxRequiredRule :message="$t('error_resultado_required')" />
          </DxValidator>
        </DxSelectBox>
      </div>
    </div>

    <div class="content-block mt-4">
      <DxTextArea :label="$t('notas')" :value.sync="calibracion.notas"> </DxTextArea>
    </div>

    <div class="content-block">
      <h3>{{ $t("caracteristicas") }}</h3>
      <div
        class="caracteristica"
        v-for="linea in lineas"
        :key="linea.id_instcaracteristicascalib"
        :class="{ success: isValid(linea) }"
      >
        <h3>{{ linea.caracteristica }}</h3>
        <!-- {{ linea.id_instcaracteristicascalib }} -->
        <!-- <div class="d-flex flex-column">
          <Display
            :label="$t('caracteristica')"
            :value="linea.caracteristica"
          />
        </div> -->

        <Debug>
          <pre>{{ linea }}</pre>
        </Debug>

        <div class="d-flex flex-wrap mt-1 gap-3 column-gap-5 align-items-center">
          <Display :label="$t('metodo')" :value="linea.metodo" />
          <Display :label="$t('patron')" :value="linea.patron" />
          <Display :label="$t('procedimiento')" :value="linea.procedimiento" />
          <Display :label="$t('valor_pedido')" :value="linea.valor_pedido" />
          <Display :label="$t('valor_teorico')" :value="linea.valor_teorico" />
          <Display :label="$t('k')" :value="linea.k" />
        </div>

        <div class="d-flex flex-wrap mt-4 gap-3 column-gap-5 align-items-end">
          <DxTextBox
            class="flex-grow-1"
            :label="$t('valor_obtenido')"
            :value.sync="linea.valor_obtenido"
            :showClearButton="true"
            :disabled="linea.k_usada !== null"
            :labelMode="linea.k_usada ? 'static' : 'floating'"
            @valueChanged="
              (e) => {
                // fix para que el valor sea null cuando se borra el contenido del textbox
                if (e.value === '') linea.valor_obtenido = null;
              }
            "
          >
            <!-- <DxValidator ref="valorObtenidoValidator">
                  <DxRequiredRule :message="$t('error_valor_obtenido_required')" />
                </DxValidator> -->
          </DxTextBox>

          <div class="d-flex flex-column align-self-end">
            <DxButton
              icon="bi bi-calculator"
              class="fs-4"
              height="40"
              width="40"
              :hint="$t('calculoIncertidumbre')"
              :type="linea.k_usada !== null ? 'default' : 'normal'"
              stylingMode="outlined"
              @click="onClickCalcularIncertidumbre($event, linea)"
            />
          </div>

          <DxNumberBox
            :value.sync="linea.k_usada"
            width="75"
            :disabled="true"
            :label="$t('k_usada')"
            labelMode="static"
          >
          </DxNumberBox>

          <DxSelectBox
            :label="$t('resultado')"
            width="200"
            :data-source="dataSourceResultadosLineas"
            :value.sync="linea.resultado"
            display-expr="value"
            value-expr="id"
            field-template="field"
            item-template="item"
          >
            <template #field="{ data }">
              <!-- <Field :field-data="data"/> -->
              <div class="d-flex">
                <img v-if="data" :src="data.img" height="30" class="ps-2 py-1 pe-0" />
                <DxTextBox :value="data?.value" :read-only="true" />
              </div>
            </template>
            <template #item="{ data }">
              <!-- <Item :item-data="data"/> -->
              <div class="d-flex align-items-center">
                <img :src="data.img" height="16" />
                <span class="ps-2">{{ data?.value }}</span>
              </div>
            </template>

            <DxValidator ref="resultadoValidador">
              <DxRequiredRule :message="$t('error_resultado_required')" />
            </DxValidator>
          </DxSelectBox>

          <DxTextBox :label="$t('vp')" :value.sync="linea.vp" width="75"> </DxTextBox>

          <DxTextBox :label="$t('condicion')" :value.sync="linea.condicion" width="90"> </DxTextBox>

          <DxTextBox :label="$t('tolerancia')" :value.sync="linea.tolerancia" width="90"> </DxTextBox>

          <DxDateBox :label="$t('fecha')" :value.sync="linea.fecha" width="150"> </DxDateBox>
        </div>
        <div class="mt-4">
          <DxTextArea :label="$t('notas')" :value.sync="linea.notas"> </DxTextArea>
        </div>
      </div>
    </div>

    <div class="content-block">
      <h4>{{ $t("fotos") }}</h4>
      <div class="dx-card responsive-paddings">
        <AttachedViewer :resourceURI="`instrumentos/${this.idInstrumento}/calibraciones/${this.idCalibracion}`">
        </AttachedViewer>
      </div>
    </div>

    <div class="content-block">
      <h4>{{ $t("documentos") }}</h4>
      <div class="dx-card responsive-paddings">
        <AttachedDocumentViewer :resourceURI="`instrumentos/${this.idInstrumento}/calibraciones/${this.idCalibracion}`">
        </AttachedDocumentViewer>
      </div>
    </div>

    <div class="content-block mt-5">
      <DxSelectBox
        :label="$t('resultado')"
        :data-source="dataSourceResultados"
        :value.sync="calibracion.resultado"
        display-expr="value"
        value-expr="id"
        field-template="field"
        item-template="item"
      >
        <template #field="{ data }">
          <!-- <Field :field-data="data"/> -->
          <div class="d-flex">
            <img v-if="data" :src="data.img" class="py-2 ps-3 pe-0" />
            <DxTextBox :value="data?.value" :read-only="true" />
          </div>
        </template>
        <template #item="{ data }">
          <!-- <Item :item-data="data"/> -->
          <div class="d-flex align-items-center">
            <img :src="data.img" />
            <span class="ps-2">{{ data?.value }}</span>
          </div>
        </template>

        <DxValidator ref="resultadoValidador">
          <DxRequiredRule :message="$t('error_resultado_required')" />
        </DxValidator>
      </DxSelectBox>

      <DxTextArea :label="$t('notas')" :value.sync="calibracion.notas" class="mt-4"> </DxTextArea>
    </div>

    <Incertidumbre
      v-if="selectedLineaCalibracion"
      :instrumento="instrumento"
      :calibracion="calibracion"
      :calibracion-linea="selectedLineaCalibracion"
      @save="onSaveIncertidumbre"
      @hidden="onHiddenIncertidumbre"
    >
    </Incertidumbre>

    <!-- <div class="content-block d-flex justify-content-end">
      <DxButton type="default" @click="onClickSaveCalibracion" :text="$t('guardar_cambios')" />
    </div> -->

    <DxSpeedDialAction
      :hint="$t('guardar_cambios')"
      :label="$t('guardar_cambios')"
      icon="save"
      @click="onClickSaveCalibracion"
    />
  </div>
</template>

<script>
import { DxButton } from "devextreme-vue/button";
import { DxTextBox } from "devextreme-vue/text-box";
import DxNumberBox from "devextreme-vue/number-box";
import DxSpeedDialAction from "devextreme-vue/speed-dial-action";

import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";
import DxTextArea from "devextreme-vue/text-area";

import Display from "@/components/core/Display.vue";

import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";

// import {
//   DxDataGrid,
//   DxColumn as DxColumnGrid,
// } from "devextreme-vue/data-grid";

// import auth from "@/auth";
import DxDateBox from "devextreme-vue/date-box";
import DxSelectBox from "devextreme-vue/select-box";

import resultados from "../../../data/instrumentos/Resultados.js";
import resultadosLineas from "../../../data/instrumentos/ResultadosLineas.js";
import Incertidumbre from "../../../components/instrumentos/calibraciones/Incertidumbre.vue";
import TitleInstrumentoWithBackButton from "../../../components/core/TitleInstrumentoWithBackButton.vue";
import AttachedViewer from "../../../components/attached-viewer.vue";
import AttachedDocumentViewer from "../../../components/attached-document-viewer.vue";

export default {
  components: {
    DxButton,
    DxTextBox,
    DxNumberBox,
    DxValidator,
    DxRequiredRule,
    DxTextArea,
    Display,
    DxDateBox,
    DxSelectBox,
    Incertidumbre,
    DxSpeedDialAction,
    TitleInstrumentoWithBackButton,
    AttachedViewer,
    AttachedDocumentViewer,
  },

  data() {
    return {
      componentKey: 0,

      idInstrumento: Number(this.$route.params.id_instrumento),
      idCalibracion: Number(this.$route.params.id),

      instrumento: {},
      calibracion: {},
      lineas: [],

      selectedLineaCalibracion: null,

      // resultados: [
      //   { id: 0, img: "/img/tipos/11.png", text: "nok" },
      //   { id: 1, img: "/img/tipos/12.png", text: "ok" },
      //   { id: 2, img: "/img/tipos/13.png", text: "en verificacion" },
      // ],

      // resultados_linea: [
      //   { id: 0, img: "/img/tipos/11.png", text: "nok" },
      //   { id: 1, img: "/img/tipos/12.png", text: "ok" },
      //   { id: 2, img: "/img/tipos/13.png", text: "excedido por arriba" },
      //   { id: 3, img: "/img/tipos/14.png", text: "excedido por abajo" },
      //   {
      //     id: 4,
      //     img: "/img/tipos/15.png",
      //     text: "excedido por arriba y abajo",
      //   },
      // ],

      instrumentoCalibracionesDataSource: new DataSource({
        store: new CustomStore({
          key: "id",
          // load: () =>
          //   this.$fetch.get(
          //     `${global.API_URL}/instrumentos/${this.idInstrumento}/calibraciones`
          //   ),
          // byKey: (key) =>
          //   this.$fetch.get(
          //     `${global.API_URL}/instrumentos/${this.idInstrumento}/calibraciones/${key}`
          //   ),
          // insert: (values) =>
          //   this.$fetch.post(
          //     `${global.API_URL}/instrumentos/${this.idInstrumento}/calibraciones`,
          //     values
          //   ),
          update: (key, values) => {
            console.log("key", key);
            console.log("values", values);

            return this.$fetch.put(`${global.API_URL}/instrumentos/${this.idInstrumento}/calibraciones/${key}`, values);
          },
          // remove: (key) =>
          //   this.$fetch.delete(
          //     `${global.API_URL}/instrumentos/${this.idInstrumento}/calibraciones/${key}`
          //   ),
        }),
      }),

      instrumentoCalibracionesLineasDataSource: new DataSource({
        store: new CustomStore({
          key: "id",
          load: () =>
            this.$fetch.get(
              `${global.API_URL}/instrumentos/${this.idInstrumento}/calibraciones/${this.idCalibracion}/lineas`
            ),
          byKey: (key) =>
            this.$fetch.get(
              `${global.API_URL}/instrumentos/${this.idInstrumento}/calibraciones/${this.idCalibracion}/lineas/${key}`
            ),
          // insert: (values) =>
          //   this.$fetch.post(
          //     `${global.API_URL}/instrumentos/${this.id}/calibraciones/${this.idCalibracion}/lineas`,
          //     values
          //   ),
          update: (key, values) =>
            this.$fetch.put(
              `${global.API_URL}/instrumentos/${this.idInstrumento}/calibraciones/${this.idCalibracion}/lineas/${key}`,
              values
            ),
          // remove: (key) =>
          //   this.$fetch.delete(
          //     `${global.API_URL}/instrumentos/${this.id}/calibraciones/${this.idCalibracion}/lineas/${key}`
          //   ),
        }),
      }),
    };
  },

  methods: {
    async load() {
      console.log("load!!");
      const urls = [
        `${global.API_URL}/instrumentos/${this.idInstrumento}`,
        `${global.API_URL}/instrumentos/${this.idInstrumento}/calibraciones/${this.idCalibracion}`,
        `${global.API_URL}/instrumentos/${this.idInstrumento}/calibraciones/${this.idCalibracion}/lineas`,
      ];
      //obtengo todos los fetch en paralelo
      const responses = await Promise.all(urls.map((u) => this.$fetch.get(u)));
      const data = responses; // await Promise.all(responses.map((r) => r.json()));

      this.instrumento = data[0];
      this.calibracion = data[1];
      this.lineas = data[2];

      console.log(this.calibracion);

      // const calibracionesLineas =
      //   await this.instrumentoCalibracionesLineasDataSource.store().load();
    },

    isValid(linea) {
      return (
        linea.valor_obtenido !== null &&
        linea.valor_obtenido !== undefined &&
        linea.valor_obtenido !== "" &&
        linea.resultado !== null &&
        linea.resultado !== undefined &&
        linea.resultado !== "" &&
        linea.vp !== null &&
        linea.vp !== undefined &&
        linea.vp !== "" &&
        linea.condicion !== null &&
        linea.condicion !== undefined &&
        linea.condicion !== "" &&
        linea.tolerancia !== null &&
        linea.tolerancia !== undefined &&
        linea.tolerancia !== ""
      );
    },

    async onClickCalcularIncertidumbre(e, linea) {
      this.selectedLineaCalibracion = linea;
    },

    async onSaveIncertidumbre(result) {
      console.log("onSavedIncertidumbre", result);

      const linea = this.selectedLineaCalibracion;
      linea.valor_obtenido = result.u?.toString();
      linea.k_usada = result.k;
      linea.formula = result.formula;
      linea.id_patron_usado = result.patron;

      try {
        const updated = await this.instrumentoCalibracionesLineasDataSource.store().update(linea.id, linea);
        console.log(updated);
        this.$notify(
          `${this.$t("se_ha_actualizado_la_linea")}: ${linea.caracteristica} ${this.$t("correctamente")}`,
          "success",
          5000
        );
      } catch (ex) {
        this.$notify(`${this.$t("se_ha_producido_un_error")}: ${ex.message}`, "error", 5000);
        console.log("e.cancel en onSavedIncertidumbre");
      }
    },

    async onHidingIncertidumbre(e) {
      console.log("onHidingIncertidumbre", e);
    },

    async onHiddenIncertidumbre() {
      console.log("onHiddenIncertidumbre");
      this.selectedLineaCalibracion = null;
    },

    async onClickSaveCalibracion(e) {
      console.log("onClickSaveCalibracion", e);

      // if (!e.validationGroup.validate().isValid) return;
      try {
        this.calibracion.lineas = this.lineas;

        const toUpdate = {
          ...this.calibracion,
          lineas: this.lineas,
        };

        const result = await this.instrumentoCalibracionesDataSource.store().update(this.calibracion.id, toUpdate);

        console.log("result", result);

        this.$notify(this.$t("se_ha_guardado_correctamente"), "success", 5000);
      } catch (ex) {
        console.log("ex", ex);
        this.$notify(this.$t("se_ha_producido_un_error"), "error", 5000);
      }

      // this.$router.go(-1);
    },
  },

  async mounted() {
    // await this.responsablesDataSource.load();
    // await this.load();
    // console.log(this.responsablesDataSource.items());
  },

  async created() {
    // necesito llamar al load porque estoy llamando al endpoint de instrumentos/id a mano..
    // @TODO mirar de hacer un datasource o algo mejor...
    await this.load();
  },

  computed: {
    // resultadoDisabled() {
    //   console.log("resultadoDisabled");
    //   if (this.lineas.length === 0) return true;
    //   this.lineas.forEach((l) => {
    //     console.log(l.resultado);
    //   });
    //   return this.lineas.some((l) => l.resultado === null);
    // },

    idioma() {
      return this.$i18n.locale;
    },

    dataSourceResultados() {
      return resultados
        .filter((e) => e.id !== -1000) // quitamos el -1000 de la lista!
        .map((e) => ({
          ...e,
          value: this.$t(e.text),
        }));
    },

    dataSourceResultadosLineas() {
      return resultadosLineas
        .filter((e) => e.id !== -1000) // quitamos el -1000 de la lista!
        .map((e) => ({
          ...e,
          value: this.$t(e.text),
        }));
    },
  },
  watch: {
    idioma() {
      this.componentKey++;
    },
  },
};
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
}

a:hover {
  // border-bottom: 3px solid red;
  text-decoration: underline;
  text-decoration-color: var(--accent);
  text-decoration-thickness: 3px;
}

.caracteristica {
  margin: 1em 0;
  padding: 1em 1em;
  background-color: var(--form-bg-color);
  border: 1px solid gray;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}

// background-color: rgba(125, 255, 125, 0.05); <--- from this color we calculated:
// --form-bg-color: #ecf3ec; <--- this color for light color
// --form-bg-color: #2e3535; <--- and this color for dark color
//
// --form-bg-color needs to be solid (not transparent) color because is used to hide
// the line from the display component. If it's transparent, the line will be visible

.caracteristica.success {
  --form-bg-color: #ecf3ec;
  position: relative;
  border-color: var(--bs-green);
  border-width: 2px;
}

.dx-swatch-additional .caracteristica.success {
  --form-bg-color: #2e3535;
  border-color: var(--bs-green);
  border-width: 2px;
}

.caracteristica.success::before {
  content: "✔";
  position: absolute;
  top: 0;
  right: 0;

  font-size: 1em;
  padding: 3px;
}

.caracteristica:has(.dx-invalid) {
  --form-bg-color: rgba(255, 125, 125, 0.05);
  // background-color: rgba(255, 125, 125, 0.05);
  border-color: var(--bs-red);
  border-width: 2px;
}
</style>
