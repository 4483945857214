<template>
  <div>
    <h3>{{ $t("proveedoresAfectados") }}</h3>

    <DxDataGrid
      ref="gridProveedoresAfectados"
      id="gridProveedoresAfectados"
      class="dx-card wide-card"
      :data-source="proveedoresAfectadosDataSource"
      keyExpr="id"
      :hover-state-enabled="true"
      :noDataText="$t('noHayProveedoresAfectados')"
    >
      <DxColumn data-field="codigo" :caption="$t('codigo')"> </DxColumn>

      <DxColumn data-field="descripcion" :caption="$t('descripcion')"></DxColumn>

      <DxColumn data-field="contacto" :caption="$t('contacto')"></DxColumn>

      <DxColumn data-field="telefono" :caption="$t('telefono')"></DxColumn>

      <DxColumn data-field="email" :caption="$t('eMail')"></DxColumn>

      <DxColumn data-field="image" :caption="$t('imagen')"></DxColumn>

      <DxColumn data-field="icono" :caption="$t('icono')"></DxColumn>
    </DxDataGrid>
  </div>
</template>

<script>
import { DxDataGrid, DxColumn } from "devextreme-vue/data-grid";

import { ProveedoresAfectadosDataSource } from "@/data/plancontrol/ProveedoresAfectadosDataSource.js";

export default {
  props: {
    idPlan: {
      // type: Number,
      required: true,
    },
  },
  components: {
    DxDataGrid,
    DxColumn,
  },
  data() {
    return {
      proveedoresAfectadosDataSource: new ProveedoresAfectadosDataSource(this.idPlan),
    };
  },
};
</script>
