<template>
  <div :key="componentKey">
    <TitleInstrumentoWithBackButton :instrumento="instrumento" />

    <h3 class="content-block">{{ $t("calibraciones_realizadas") }}</h3>

    <div class="content-block">
      <div class="text-end mb-2">
        <ButtonNewCalibracion :instrumento="instrumento" />
      </div>

      <DxDataGrid
        ref="gridCalibraciones"
        id="gridCalibraciones"
        class="dx-card wide-card"
        :data-source="dataSourceInstrumentoCalibraciones"
        keyExpr="id"
        :hover-state-enabled="true"
        :allow-column-resizing="true"
        :allow-column-reordering="true"
        :noDataText="$t('noTieneNingunaCalibracion')"
        :focused-row-enabled="true"
        @focused-row-changed="onFocusedRowChanged"
        @row-dbl-click="onRowDblClick"
      >
        <DxPaging :page-size="12" />
        <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="grid-calibraciones-instrumento"
          :savingTimeout="0"
        >
        </DxStateStoring>
        <!-- <DxEditingGrid :allow-updating="true" :allow-adding="true" :allow-deleting="true" mode="row" /> -->
        <DxColumnGrid data-field="fecha" :caption="$t('fecha')" sort-order="desc" data-type="date"> </DxColumnGrid>
        <!-- <DxColumnGrid data-field="resultado" :caption="$t('resultado')"> </DxColumnGrid> -->

        <DxColumnGrid
          data-field="resultado"
          :caption="$t('resultado')"
          cell-template="itemTemplateResultado"
          alignment="center"
        >
          <!-- <DxLookup :data-source="dataSourceResultados" display-expr="text" value-expr="id"> </DxLookup> -->
        </DxColumnGrid>

        <template #itemTemplateResultado="{ data }">
          <div class="text-center">
            <img
              :src="`${dataSourceResultados?.find((x) => x.id === data.value)?.img}`"
              :title="dataSourceResultados?.find((x) => x.id === data.value)?.value"
              height="24"
            />
          </div>
        </template>

        <DxColumnGrid data-field="verificado_por" :caption="$t('verificado_por')"> </DxColumnGrid>
        <DxColumnGrid data-field="certificado_por" :caption="$t('certificado_por')"> </DxColumnGrid>
        <DxColumnGrid data-field="notas" :caption="$t('notas')"> </DxColumnGrid>
      </DxDataGrid>
    </div>

    <h3 class="content-block">{{ $t("calibracion_realizada") }}</h3>

    <div class="content-block">
      <DxDataGrid
        ref="gridCalibracionesLineas"
        id="gridCalibracionesLineas"
        class="dx-card wide-card"
        :data-source="dataSourceInstrumentoCalibracionesLineas"
        keyExpr="id"
        :hover-state-enabled="true"
        :noDataText="$t('noTieneNingunaCalibracionLinea')"
      >
        <!-- <DxEditingGrid :allow-updating="true" :allow-adding="true" :allow-deleting="true" mode="row" /> -->

        <DxColumnGrid data-field="secuencia" :caption="$t('secuencia')"> </DxColumnGrid>
        <DxColumnGrid data-field="caracteristica" :caption="$t('caracteristica')"> </DxColumnGrid>

        <DxColumnGrid data-field="metodo" :caption="$t('metodo')"> </DxColumnGrid>

        <DxColumnGrid data-field="patron" :caption="$t('patron')"> </DxColumnGrid>
        <DxColumnGrid data-field="procedimiento" :caption="$t('procedimiento')"> </DxColumnGrid>
        <DxColumnGrid data-field="valor_pedido" :caption="$t('valor_pedido')"> </DxColumnGrid>
        <DxColumnGrid data-field="valor_teorico" :caption="$t('valor_teorico')"> </DxColumnGrid>
        <DxColumnGrid data-field="valor_obtenido" :caption="$t('valor_obtenido')"> </DxColumnGrid>

        <!-- <DxColumnGrid data-field="resultado" :caption="$t('resultado')"> </DxColumnGrid> -->

        <DxColumnGrid
          data-field="resultado"
          :caption="$t('resultado')"
          cell-template="itemTemplateResultado"
          alignment="center"
        >
          <!-- <DxLookup :data-source="dataSourceResultados" display-expr="text" value-expr="id"> </DxLookup> -->
        </DxColumnGrid>

        <template #itemTemplateResultado="{ data }">
          <div>
            <img
              :src="`${dataSourceResultadosLineas?.find((x) => x.id === data.value)?.img}`"
              :title="dataSourceResultadosLineas?.find((x) => x.id === data.value)?.value"
              height="24"
            />
          </div>
        </template>

        <DxColumnGrid data-field="vp" :caption="$t('vp')"> </DxColumnGrid>
        <DxColumnGrid data-field="condicion" :caption="$t('condicion')"> </DxColumnGrid>
        <DxColumnGrid data-field="tolerancia" :caption="$t('tolerancia')"> </DxColumnGrid>
        <DxColumnGrid data-field="fecha" :caption="$t('fecha')"> </DxColumnGrid>
        <DxColumnGrid data-field="notas" :caption="$t('notas')"> </DxColumnGrid>
      </DxDataGrid>
    </div>

    <!-- <h3 class="content-block">{{ $t("fotos") }}</h3>

    <div class="content-block">
      <div class="dx-card responsive-paddings">
        <AttachedViewer :resourceURI="`instrumentos/${this.id}`"></AttachedViewer>
      </div>
    </div> -->

    <!-- <DxPopup v-if="attachedID" :title="$t('ficherosAdjuntos')" :visible.sync="isPopupAttachedVisible"
      :fullScreen="false" @shown="onShownAttachedPopup" :animation="{
        show: {
          type: 'pop',
        },
        hide: {
          type: 'pop',
          from: { scale: 1, opacity: 1 },
          to: { scale: 0, opacidy: 0 },
        },
      }">
      <template>
        <div>
          <AttachedViewer ref="attachedViewerGeneral" v-if="attachedID" :key="attachedID"
            :resourceURI="attachedResourceURI" :resources.sync="attachedResource.fotos"></AttachedViewer>
        </div>
      </template>
    </DxPopup>

    <Prompt ref="prompt"></Prompt> -->
  </div>
</template>

<script>
// import { DxButton } from "devextreme-vue/button";
// import DxPopup from "devextreme-vue/popup";
// import Prompt from "@/components/prompt";

// import AttachedViewer from "@/components/attached-viewer.vue";
// import DataSource from "devextreme/data/data_source";
// import CustomStore from "devextreme/data/custom_store";

import { DxDataGrid, DxColumn as DxColumnGrid, DxPaging, DxStateStoring } from "devextreme-vue/data-grid";

import auth from "../../auth";
import TitleInstrumentoWithBackButton from "../../components/core/TitleInstrumentoWithBackButton.vue";
import ButtonNewCalibracion from "../../components/instrumentos/calibraciones/ButtonNewCalibracion.vue";

import resultados from "@/data/instrumentos/Resultados.js";
import resultadosLineas from "@/data/instrumentos/ResultadosLineas.js";

import { DataSourceCalibracionesInstrumento } from "../../data/instrumentos/CalibracionesInstrumentoDataSource";
import { DataSourceLineasCalibracion } from "../../data/instrumentos/LineasCalibracionDataSource";

// import { confirm } from "devextreme/ui/dialog";

export default {
  components: {
    // DxButton,
    // AttachedViewer,
    // DxPopup,
    // Prompt,
    //
    DxDataGrid,
    DxColumnGrid,
    DxPaging,
    DxStateStoring,
    TitleInstrumentoWithBackButton,
    ButtonNewCalibracion,
  },

  data() {
    const id = this.$route.params.id;
    console.log("data ->", id);

    return {
      componentKey: 0,
      id: id,
      dataSourceInstrumentoCalibraciones: new DataSourceCalibracionesInstrumento(id),
      instrumento: {},
      focusedCalibracion: {},
    };
  },

  methods: {
    async load() {
      const urls = [`${global.API_URL}/instrumentos/${this.id}`];
      //obtengo todos los fetch en paralelo
      const responses = await Promise.all(
        urls.map((u) =>
          fetch(u, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + auth.getToken(),
            },
          })
        )
      );
      const data = await Promise.all(responses.map((r) => r.json()));
      this.instrumento = data[0];
    },

    onEditorPreparing(e) {
      console.log("onEditorPreparing", e);
    },

    onInitNewRow(e) {
      console.log("onInitNewRow", e);
      // e.data.familia = false;
      // e.data.situacion = 0;
      // e.data.fecha_emision = new Date();
    },
    onEditingStart(e) {
      console.log("onEditingStart", e);
      // e.data.familia = false;
      // e.data.situacion = 0;
      // e.data.fecha_emision = new Date();
    },

    onFocusedRowChanged(e) {
      this.focusedCalibracion = e.row.data;
      console.log("fetching this calibracion: ", this.focusedCalibracion.id);
      // this.instrumentoCalibracionesLineasDataSource.clearRawDataCache();
      // this.instrumentoCalibracionesLineasDataSource.reload();
      // this.dataSourceInstrumentoCalibracionesLineas.reload();
    },

    onRowDblClick(e) {
      // e.row.data;
      // console.log("fetching this calibracion: ", this?.focusedCalibracion?.id)
      // this.instrumentoCalibracionesLineasDataSource.clearRawDataCache();
      // this.instrumentoCalibracionesLineasDataSource.reload();

      this.$router.push({
        name: "calibracion",
        params: {
          id_instrumento: this.instrumento.id,
          id: e.key,
        },
      });
    },

    // async onClickNewCalibracion() {
    //   if (!(await confirm("Esta acción creará una nueva calibración, ¿Desea crear una nueva calibración?"))) return;

    //   const initValues = {
    //     id_instrumentos: this.instrumento.id,
    //     resultado: null,
    //     fecha: Date.now(),
    //     verificado_por: this.$store.state.user.nombre,
    //     certificado_por: this.$store.state.user.nombre,
    //     notas: null,
    //   };

    //   try {
    //     const response = await createCalibracion(initValues);

    //     // go to edit calibración
    //     this.$router.push({
    //       name: "calibracion",
    //       params: { id_instrumento: this.instrumento.id, id: response.id },
    //     });
    //   } catch (ex) {
    //     console.error(ex);
    //     this.$notify("Error al crear calibración", this.$t("error"), 5000);
    //   }
    // },
  },

  async mounted() {
    // await this.responsablesDataSource.load();
    // await this.load();
    // console.log(this.responsablesDataSource.items());
  },

  async created() {
    // necesito llamar al load porque estoy llamando al endpoint de instrumentos/id a mano..
    // @TODO mirar de hacer un datasource o algo mejor...
    await this.load();
  },

  computed: {
    idioma() {
      return this.$i18n.locale;
    },

    dataSourceResultados() {
      return resultados.map((e) => ({
        ...e,
        value: this.$t(e.text),
      }));
    },
    dataSourceResultadosLineas() {
      return resultadosLineas.map((e) => ({
        ...e,
        value: this.$t(e.text),
      }));
    },

    // se tiene que renovar cada vez que cambio de calibración.
    dataSourceInstrumentoCalibracionesLineas() {
      // console.log("dataSourceInstrumentoCalibracionesLineas", this.focusedCalibracion?.id);
      return new DataSourceLineasCalibracion(this.id, this.focusedCalibracion?.id);
    },
  },
  watch: {
    idioma() {
      this.componentKey++;
    },
  },
};
</script>

<style scoped lang="scss"></style>
