var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"reportVAR"}},[_c('div',{staticClass:"reportContent"},[_c('h2',[_vm._v(_vm._s(_vm.$t("graficoVariables"))+": "+_vm._s(_vm.metodoAnalisis.codigo))]),_c('div',{staticClass:"d-flex flex-row pt-2 pb-4",staticStyle:{"gap":"50px"}},[_c('div',{staticClass:"d-flex flex-column plan-control"},[_c('label',{staticClass:"form-label fw-bold"},[_vm._v(_vm._s(_vm.$t("planControl")))]),_c('label',{staticClass:"form-label"},[_vm._v(_vm._s(_vm.$t("referencia"))+" / "+_vm._s(_vm.$t("pieza"))+" / "+_vm._s(_vm.$t("fase")))]),_c('label',[_vm._v(" "+_vm._s(_vm.info.plan.referencia)+" / "+_vm._s(_vm.info.plan.nombre)+" / "+_vm._s(_vm.info.estructura.descripcion)+" ")])]),_c('div',{staticClass:"d-flex flex-column caracteristica"},[_c('label',{staticClass:"form-label fw-bold"},[_vm._v(_vm._s(_vm.$t("caracteristica")))]),_c('label',{staticClass:"form-label"},[_vm._v(_vm._s(_vm.$t("numero"))+" / "+_vm._s(_vm.$t("nombre"))+" / "+_vm._s(_vm.$t("especificacion"))+" ")]),_c('div',{staticClass:"form-data"},[_vm._v(" "+_vm._s(_vm.info.caracteristica.id)+" / "+_vm._s(_vm.info.caracteristica.nombre)+" / "),_c('div',{staticStyle:{"display":"inline-block"},domProps:{"innerHTML":_vm._s(_vm.info.caracteristica.especificacion)}})])])]),_c('img',{attrs:{"src":_vm.dataImageX,"width":"100%"},on:{"load":_vm.ImageXLoad}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('table',{staticClass:"tabla-limites table table-jspdf-fix table-bordered table-sm",attrs:{"width":"50%"}},[_c('thead',[_c('th',[_vm._v(_vm._s(_vm.$t("limitesControlX")))]),_c('th',[_vm._v(_vm._s(_vm.$t("superior")))]),_c('th',[_vm._v(_vm._s(_vm.$t("central")))]),_c('th',[_vm._v(_vm._s(_vm.$t("inferior")))])]),_c('tbody',[_c('tr',{class:{
                'table-success': _vm.info.caracteristica.lcsx !== null,
              }},[_c('th',[_vm._v(_vm._s(_vm.$t("caracteristica")))]),_c('td',[_vm._v(" "+_vm._s(_vm.info.caracteristica.lcsx != undefined ? _vm.info.caracteristica.lcsx.toFixed(4) : "")+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.info.caracteristica.lcx != undefined ? _vm.info.caracteristica.lcx.toFixed(4) : "")+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.info.caracteristica.lcix != undefined ? _vm.info.caracteristica.lcix.toFixed(4) : "")+" ")])]),(_vm.info.limites_calculados)?_c('tr',{class:{
                'table-success': _vm.info.caracteristica.lcsx === null,
              }},[_c('th',[_vm._v(_vm._s(_vm.$t("calculados")))]),_c('td',[_vm._v(" "+_vm._s(_vm.info.limites_calculados.lcsx ? _vm.info.limites_calculados.lcsx.toFixed(4) : "")+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.info.limites_calculados.lcx ? _vm.info.limites_calculados.lcx.toFixed(4) : "")+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.info.limites_calculados.lcix ? _vm.info.limites_calculados.lcix.toFixed(4) : "")+" ")])]):_vm._e()])])]),_c('div',{staticClass:"col"},[_c('table',{staticClass:"tabla-limites table table-jspdf-fix table-bordered table-sm",attrs:{"width":"50%"}},[_c('thead',[_c('th',[_vm._v(_vm._s(_vm.$t("limitesControlR")))]),_c('th',[_vm._v(_vm._s(_vm.$t("superior")))]),_c('th',[_vm._v(_vm._s(_vm.$t("central")))]),_c('th',[_vm._v(_vm._s(_vm.$t("inferior")))])]),_c('tbody',[_c('tr',{class:{
                'table-success': _vm.info.caracteristica.lcsr !== null,
              }},[_c('th',[_vm._v(_vm._s(_vm.$t("caracteristica")))]),_c('td',[_vm._v(" "+_vm._s(_vm.info.caracteristica.lcsr != undefined ? _vm.info.caracteristica.lcsr.toFixed(4) : "")+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.info.caracteristica.lcr != undefined ? _vm.info.caracteristica.lcr.toFixed(4) : "")+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.info.caracteristica.lcir != undefined ? _vm.info.caracteristica.lcir.toFixed(4) : "")+" ")])]),(_vm.info.limites_calculados)?_c('tr',{class:{
                'table-success': _vm.info.caracteristica.lcsr === null,
              }},[_c('th',[_vm._v(_vm._s(_vm.$t("calculados")))]),_c('td',[_vm._v(" "+_vm._s(_vm.info.limites_calculados.lcsr ? _vm.info.limites_calculados.lcsr.toFixed(4) : "")+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.info.limites_calculados.lcr ? _vm.info.limites_calculados.lcr.toFixed(4) : "")+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.info.limites_calculados.lcir ? _vm.info.limites_calculados.lcir.toFixed(4) : "")+" ")])]):_vm._e()])])])]),_c('table',{attrs:{"width":"100%","border":"1px solid black"}},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("lote")))]),_c('th',[_vm._v(_vm._s(_vm.$t("fechaHora")))]),_c('th',[_vm._v(_vm._s(_vm.$t("operario")))]),_c('th',[_vm._v(_vm._s(_vm.$t("tamanoMuestra")))]),_c('th',[_vm._v(_vm._s(_vm.metodoAnalisis.codigo))]),_c('th',[_vm._v("R")]),_c('th',[_vm._v(_vm._s(_vm.$t("maximo")))]),_c('th',[_vm._v(_vm._s(_vm.$t("minimo")))])])]),_c('tbody',_vm._l((_vm.info.puntos.x),function(punto,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(punto.analisis.lote))]),_c('td',[_vm._v(" "+_vm._s(_vm.$moment(punto.analisis.fecha).format("L"))+" "+_vm._s(_vm.$moment(punto.analisis.fecha).format("LTS"))+" ")]),_c('td',[_vm._v(_vm._s(punto.analisis.operario))]),_c('td',[_vm._v(_vm._s(punto.analisis.tamano_muestra))]),_c('td',[_vm._v(_vm._s(punto.value && punto.value.toFixed(2)))]),_c('td',[_vm._v(" "+_vm._s(_vm.info.puntos.r[i].value && _vm.info.puntos.r[i].value.toFixed(2))+" ")]),_c('td',[_vm._v(_vm._s(punto.analisis.maximo))]),_c('td',[_vm._v(_vm._s(punto.analisis.minimo))])])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }