<template>
  <TitleWithBackButton :backTo="backTo">
    <router-link
      v-if="instrumento?.id"
      :to="{ name: 'instrumento', params: { id: instrumento?.id } }"
      class="text-reset"
    >
      {{ instrumento?.codigo }}
    </router-link>
    —
    <i>
      {{ instrumento?.nombre }}
    </i>

    {{ $slots.default ? "—" : "" }}

    <slot />

    <small v-if="$store.state.debug" class="p-3"
      ><code>{{ id }}</code></small
    >
  </TitleWithBackButton>
</template>

<script>
import TitleWithBackButton from "./TitleWithBackButton.vue";

export default {
  props: ["instrumento", "backTo"],
  components: {
    TitleWithBackButton,
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  text-decoration-color: var(--accent);
  text-decoration-thickness: 3px;
}
</style>
