<template>
  <div>
    <h3 class="d-flex justify-content-between">
      {{ title }}
      <DxButton
        icon="add"
        type="default"
        stylingMode="contained"
        :hint="$t('agregar_documento')"
        @click="() => this.$refs.attachedDocumentViewer.add()"
        class="mx-3"
      />
    </h3>

    <div>
      <div class="dx-card responsive-paddings">
        <AttachedDocumentViewer
          ref="attachedDocumentViewer"
          :resourceURI="resourceURI"
          :showAddButton="false"
          @changed="onChanged"
        ></AttachedDocumentViewer>
      </div>
    </div>
  </div>
</template>

<script>
import DxButton from "devextreme-vue/button";
import AttachedDocumentViewer from "@/components/attached-document-viewer.vue";

export default {
  props: ["title", "resourceURI"],
  components: {
    DxButton,
    AttachedDocumentViewer,
  },
  methods: {
    onChanged(e) {
      this.$emit("changed", e);
    },
  },
};
</script>
