<template>
  <div>
    <DxPopup
      class="popupScrollableContentWithoutPadding"
      :visible.sync="isPopupVisible"
      :fullScreen="false"
      title="Lecturas"
      width="400"
      height="500"
      @hidden="onHidden"
      @hiding="onHiding"
    >
      <DxToolbarItem :options="toolbarItemAceptarOptions" widget="dxButton" location="after" toolbar="bottom" />
      <DxScrollView>
        <div class="d-flex flex-column justify-content-center gap-5">
          <div class="d-flex flex-column gap-1">
            <div class="mb-5 d-flex justify-content-center gap-3">
              <DxNumberBox ref="numberBoxNuevaLectura" :value.sync="nuevaLectura" :label="$t('nueva_lectura')" />
              <DxButton @click="agregarLectura" hint="Agregar" type="success" icon="plus" />
            </div>

            <div v-for="(lectura, index) in lecturasComputed" :key="index" class="d-flex justify-content-center gap-3">
              <!-- {{ lectura }} -->
              <DxNumberBox
                :ref="`numberbox${index}`"
                :value.sync="lecturasComputed[index]"
                :label="`lectura ${index + 1}`"
              />
              <DxButton @click="eliminarLectura(index)" icon="minus" type="danger" />
            </div>
          </div>
        </div>
      </DxScrollView>
    </DxPopup>
  </div>
</template>

<script>
import DxButton from "devextreme-vue/button";
import DxNumberBox from "devextreme-vue/number-box";

import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";
import { DxScrollView } from "devextreme-vue/scroll-view";

export default {
  props: ["lecturas"],
  data() {
    return {
      isPopupVisible: true,
      nuevaLectura: 0,
    };
  },
  components: {
    DxButton,
    DxPopup,
    DxScrollView,
    DxToolbarItem,
    DxNumberBox,
  },
  computed: {
    lecturasComputed: {
      get() {
        return this.$props.lecturas;
      },

      set(value) {
        this.$emit("update:lecturas", value);
      },
    },

    toolbarItemAceptarOptions() {
      return {
        text: this.$t("aceptar"),
        stylingMode: "contained",
        type: "default",
        onClick: this.onClickButtonAceptar,
      };
    },
  },
  methods: {
    onClickButtonAceptar() {
      this.isPopupVisible = false;
    },

    agregarLectura() {
      this.lecturasComputed.push(this.nuevaLectura);

      this.nuevaLectura = null;
      this.$refs["numberBoxNuevaLectura"].instance.focus();
    },

    eliminarLectura(index) {
      if (this.lecturasComputed.length < 2) return;
      this.lecturasComputed.splice(index, 1);
    },

    onHiding() {
      this.$emit("saved", this.lecturasData);
    },

    onHidden() {
      this.$emit("closed");
    },
  },
};
</script>
