<template>
  <div class="language-panel">
    <!-- <i class="dx-icon ion ion-md-globe"></i> -->
    <i class="dx-icon fa fa-globe"></i>

    <dx-context-menu
      :width="120"
      v-if="menuMode === 'context'"
      target=".lang-button"
      :items="idiomas"
      v-model="$i18n.locale"
      show-event="dxclick"
      css-class="lang-menu"
      :selectByClick="true"
      selectionMode="single"
      :selectedItem="selectedItem"
    >
      <dx-position my="top center" at="bottom center" />
    </dx-context-menu>

    <dx-list
      class="dx-toolbar-menu-action"
      v-if="menuMode === 'list'"
      :items="idiomas"
      v-model="$i18n.locale"
    />
  </div>
</template>

<script>
import DxContextMenu, { DxPosition } from "devextreme-vue/context-menu";
import DxList from "devextreme-vue/list";
import { locale } from "devextreme/localization";

//import { DxEmailRule } from 'devextreme-vue/data-grid';

export default {
  props: {
    menuMode: String,
  },
  computed: {
    idiomas: function () {
      return [
        {
          value: "es",
          text: "Español",
          onClick: () => {
            this.changeLanguage("es");
          },
        },
        {
          value: "en",
          text: "English",
          onClick: () => {
            this.changeLanguage("en");
          },
        },
        {
          value: "fr",
          text: "Français",
          onClick: () => {
            this.changeLanguage("fr");
          },
        },
        {
          value: "ca",
          text: "Català",
          onClick: () => {
            this.changeLanguage("ca");
          },
        },
      ];
    },
    selectedItem: function () {
      return this.idiomas.find((e) => e.value === this.$i18n.locale);
    },
  },

  components: {
    DxContextMenu,
    DxPosition,
    DxList,
  },

  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      locale(lang);
      this.$moment.locale(lang);
      this.$cookies.set("lang", lang, Infinity);
    },
  },
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";

.language-panel {
  .dx-list-item .dx-icon {
    vertical-align: middle;
    color: $base-text-color;
    margin-right: 16px;
  }
  .dx-rtl .dx-list-item .dx-icon {
    margin-right: 0;
    margin-left: 16px;
  }
}
</style>
