import Vue from "vue";
import Router from "vue-router";

import auth from "./auth";

import Home from "./views/home";
import Profile from "./views/profile";
import Tasks from "./views/tasks";

import Caracteristicas from "./views/caracteristicas";
import Autocontrol from "./views/autocontrol";
import AnalisisDataraw from "./views/analisis-dataraw.vue";
import Dataraw from "./views/dataraw";
import Planes from "./views/planes";
import Plan from "./views/plan";

// instrumentos (calibres)
import Instrumentos from "./views/instrumentos/instrumentos";
import Instrumento from "./views/instrumentos/instrumento";
import Ubicaciones from "./views/instrumentos/ubicaciones";
import Clasificacion from "./views/instrumentos/clasificacion";

import Calibraciones from "./views/calibraciones";
import Verificaciones from "./views/verificaciones";

import CalibracionesInstrumento from "./views/instrumentos/calibraciones";
import VerificacionesInstrumento from "./views/instrumentos/verificaciones";

import CalibracionInstrumento from "./views/instrumentos/calibraciones/item";
import VerificacionInstrumento from "./views/instrumentos/verificaciones/item";

import LineasCalibracion from "./views/instrumentos/lineas-calibracion";
import LineasVerificacion from "./views/instrumentos/lineas-verificacion";

import RPTInstrumento from "./views/instrumentos/reports/RPTInstrumento";

//import Icons from "./views/icons";
const Icons = () => import("./views/icons.vue");

import defaultLayout from "./layouts/side-nav-outer-toolbar";
import simpleLayout from "./layouts/single-card";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/home",
      name: "home",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: Home,
      },
    },
    {
      path: "/profile",
      name: "profile",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: Profile,
      },
    },
    {
      path: "/tasks",
      name: "tasks",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: Tasks,
      },
    },
    {
      path: "/caracteristicas",
      name: "caracteristicas",
      meta: { requiresAuth: true, requiresModulo: "PC" },
      components: {
        layout: defaultLayout,
        content: Caracteristicas,
      },
    },
    {
      path: "/autocontrol",
      name: "autocontrol",
      meta: { requiresAuth: true, requiresModulo: "AC" },
      components: {
        layout: defaultLayout,
        content: Autocontrol,
      },
    },
    {
      path: "/analisis-dataraw",
      name: "analisis-dataraw",
      meta: { requiresAuth: true, requiresModulo: "AC" },
      components: {
        layout: defaultLayout,
        content: AnalisisDataraw,
      },
    },

    {
      path: "/login-form",
      name: "login-form",
      meta: { requiresAuth: false },
      components: {
        layout: simpleLayout,
        content: () => import(/* webpackChunkName: "login" */ "./views/login-form"),
      },
      props: {
        layout: {
          title: "Sign In",
        },
      },
    },
    {
      path: "/reset-password",
      name: "reset-password",
      meta: { requiresAuth: false },
      components: {
        layout: simpleLayout,
        content: () => import(/* webpackChunkName: "login" */ "./views/reset-password-form"),
      },
      props: {
        layout: {
          title: "Reset Password",
          description:
            "Please enter the email address that you used to register, and we will send you a link to reset your password via Email.",
        },
      },
    },
    {
      path: "/create-account",
      name: "create-account",
      meta: { requiresAuth: false },
      components: {
        layout: simpleLayout,
        content: () => import(/* webpackChunkName: "login" */ "./views/create-account-form"),
      },
      props: {
        layout: {
          title: "Sign Up",
        },
      },
    },
    {
      path: "/change-password/:recoveryCode",
      name: "change-password",
      meta: { requiresAuth: false },
      components: {
        layout: simpleLayout,
        content: () => import(/* webpackChunkName: "login" */ "./views/change-password-form"),
      },
      props: {
        layout: {
          title: "Change Password",
        },
      },
    },
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "/recovery",
      redirect: "/home",
    },
    {
      path: "*",
      redirect: "/home",
    },
    {
      path: "/planes",
      name: "planes",
      meta: { requiresAuth: true, requiresModulo: "PC" },
      components: {
        layout: defaultLayout,
        content: Planes,
      },
    },

    {
      path: "/planes/plantas",
      name: "catalogo_plantas",
      meta: { requiresAuth: true, requiresModulo: "PC" },
      components: {
        layout: defaultLayout,
        content: () => import(/* webpackChunkName: "catalogos_plantas" */ "./views/planes/catalogos/plantas.vue"),
      },
    },

    {
      path: "/planes/agrupaciones",
      name: "catalogo_agrupaciones",
      meta: { requiresAuth: true, requiresModulo: "PC" },
      components: {
        layout: defaultLayout,
        content: () =>
          import(/* webpackChunkName: "catalogos_agrupaciones" */ "./views/planes/catalogos/agrupaciones.vue"),
      },
    },

    {
      path: "/planes/productos",
      name: "catalogo_productos",
      meta: { requiresAuth: true, requiresModulo: "PC" },
      components: {
        layout: defaultLayout,
        content: () => import(/* webpackChunkName: "catalogos_productos" */ "./views/planes/catalogos/productos.vue"),
      },
    },

    {
      path: "/planes/maquinas",
      name: "catalogo_maquinas",
      meta: { requiresAuth: true, requiresModulo: "PC" },
      components: {
        layout: defaultLayout,
        content: () => import(/* webpackChunkName: "catalogos_maquinas" */ "./views/planes/catalogos/maquinas.vue"),
      },
    },

    {
      path: "/planes/frecuencias",
      name: "catalogo_frecuencias",
      meta: { requiresAuth: true, requiresModulo: "PC" },
      components: {
        layout: defaultLayout,
        content: () =>
          import(/* webpackChunkName: "catalogos_frecuencias" */ "./views/planes/catalogos/frecuencias.vue"),
      },
    },

    {
      path: "/planes/unidades",
      name: "catalogo_unidades",
      meta: { requiresAuth: true, requiresModulo: "PC" },
      components: {
        layout: defaultLayout,
        content: () => import(/* webpackChunkName: "catalogos_unidades" */ "./views/planes/catalogos/unidades.vue"),
      },
    },

    {
      path: "/planes/metodosEval",
      name: "catalogo_metodosEval",
      meta: { requiresAuth: true, requiresModulo: "PC" },
      components: {
        layout: defaultLayout,
        content: () =>
          import(/* webpackChunkName: "catalogos_metodosEval" */ "./views/planes/catalogos/metodos-evaluacion.vue"),
      },
    },

    {
      path: "/caracteristicas/metodoanalisis",
      name: "catalogo_metodosAnal",
      meta: { requiresAuth: true, requiresModulo: "PC" },
      components: {
        layout: defaultLayout,
        content: () =>
          import(/* webpackChunkName: "catalogos_metodosAnal" */ "./views/planes/catalogos/metodos-analisis.vue"),
      },
    },

    {
      path: "/caracteristicas/nca",
      name: "catalogo_nca",
      meta: { requiresAuth: true, requiresModulo: "PC" },
      components: {
        layout: defaultLayout,
        content: () => import(/* webpackChunkName: "catalogos_nca" */ "./views/planes/catalogos/nca.vue"),
      },
    },
    {
      path: "/planes/caracteristicaTipos",
      name: "catalogo_caracteristicaTipos",
      meta: { requiresAuth: true, requiresModulo: "PC" },
      components: {
        layout: defaultLayout,
        content: () =>
          import(
            /* webpackChunkName: "catalogos_caracteristicasTipo" */ "./views/planes/catalogos/caracteristica-tipos.vue"
          ),
      },
    },

    {
      path: "/planes/:id",
      name: "plan",
      meta: { requiresAuth: true, requiresModulo: "PC" },
      components: {
        layout: defaultLayout,
        content: Plan,
      },
    },
    {
      path: "/dataraw",
      name: "dataraw",
      meta: { requiresAuth: true, requiresModulo: "PC" },
      components: {
        layout: defaultLayout,
        content: Dataraw,
      },
    },
    {
      path: "/instrumentos",
      name: "instrumentos",
      meta: { requiresAuth: true, requiresModulo: "CA" },
      components: {
        layout: defaultLayout,
        content: Instrumentos,
      },
    },

    {
      path: "/instrumentos/categorias",
      name: "instrumentos_categorias",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: () =>
          import(/* webpackChunkName: "catalogos_categorias" */ "./views/instrumentos/catalogos/categorias.vue"),
      },
    },

    {
      path: "/instrumentos/ubicaciones",
      name: "instrumentos_ubicaciones",
      meta: { requiresAuth: true, requiresModulo: "CA" },
      components: {
        layout: defaultLayout,
        content: Ubicaciones,
      },
    },
    {
      path: "/instrumentos/clasificacion",
      name: "instrumentos_clasificacion",
      meta: { requiresAuth: true, requiresModulo: "CA" },
      components: {
        layout: defaultLayout,
        content: Clasificacion,
      },
    },
    {
      path: "/instrumentos/calibraciones",
      name: "instrumentos_calibraciones",
      meta: { requiresAuth: true, requiresModulo: "CA" },
      components: {
        layout: defaultLayout,
        content: Calibraciones,
      },
    },
    {
      path: "/instrumentos/verificaciones",
      name: "instrumentos_verificaciones",
      meta: { requiresAuth: true, requiresModulo: "CA" },
      components: {
        layout: defaultLayout,
        content: Verificaciones,
      },
    },
    {
      path: "/instrumentos/:id",
      name: "instrumento",
      meta: { requiresAuth: true, requiresModulo: "CA" },
      components: {
        layout: defaultLayout,
        content: Instrumento,
      },
      // children: [
      //   {
      //     path: "calibraciones",
      //     name: "calibraciones_instrumento",
      //     components: {
      //       layout: defaultLayout,
      //       content: CalibracionesInstrumento,
      //     },
      //   },
      //   {
      //     path: "/instrumentos/:id/verificaciones",
      //     name: "verificaciones_instrumento",
      //     components: {
      //       layout: defaultLayout,
      //       content: VerificacionesInstrumento,
      //     },
      //   },
      // ],
    },
    {
      path: "/instrumentos/:id/calibraciones",
      name: "calibraciones_instrumento",
      meta: { requiresAuth: true, requiresModulo: "CA" },
      components: {
        layout: defaultLayout,
        content: CalibracionesInstrumento,
      },
    },
    {
      path: "/instrumentos/:id/verificaciones",
      name: "verificaciones_instrumento",
      meta: { requiresAuth: true, requiresModulo: "CA" },
      components: {
        layout: defaultLayout,
        content: VerificacionesInstrumento,
      },
    },

    {
      path: "/instrumentos/:id/report",
      name: "report_instrumento",
      meta: { requiresAuth: true, requiresModulo: "CA" },
      components: {
        layout: defaultLayout,
        // content: () =>
        //   import(/* webpackChunkName: "report_instrumento" */ "./views/instrumentos/reports/RPTInstrumento.vue"),
        content: RPTInstrumento,
      },
    },

    {
      path: "/instrumentos/:id_instrumento/calibraciones/:id",
      name: "calibracion",
      meta: { requiresAuth: true, requiresModulo: "CA" },
      components: {
        layout: defaultLayout,
        content: CalibracionInstrumento,
      },
    },

    {
      path: "/instrumentos/:id_instrumento/verificaciones/:id",
      name: "verificacion",
      meta: { requiresAuth: true, requiresModulo: "CA" },
      components: {
        layout: defaultLayout,
        content: VerificacionInstrumento,
      },
    },

    {
      path: "/instrumentos/:id_instrumento/calibraciones/:id/lineas",
      name: "lineas_calibracion",
      meta: { requiresAuth: true, requiresModulo: "CA" },
      components: {
        layout: defaultLayout,
        content: LineasCalibracion,
      },
    },

    {
      path: "/instrumentos/:id_instrumento/verificaciones/:id/lineas",
      name: "lineas_verificacion",
      meta: { requiresAuth: true, requiresModulo: "CA" },
      components: {
        layout: defaultLayout,
        content: LineasVerificacion,
      },
    },

    {
      path: "/instrumentos/:id_instrumento/rr/:id",
      name: "rr_instrumento",
      meta: { requiresAuth: true, requiresModulo: "CA" },
      components: {
        layout: defaultLayout,
        content: () => import(/* webpackChunkName: "catalogos_departamentos" */ "./views/instrumentos/rr/item.vue"),
      },
    },

    {
      path: "/catalogos/departamentos",
      name: "catalogos_departamentos",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: () => import(/* webpackChunkName: "catalogos_departamentos" */ "./views/catalogos/departamentos.vue"),
      },
    },
    {
      path: "/catalogos/clientes",
      name: "catalogos_clientes",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: () => import(/* webpackChunkName: "catalogos_clientes" */ "./views/catalogos/clientes.vue"),
      },
    },
    {
      path: "/catalogos/proveedores",
      name: "catalogos_proveedores",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: () => import(/* webpackChunkName: "catalogos_proveedores" */ "./views/catalogos/proveedores.vue"),
      },
    },

    {
      path: "/catalogos/responsables",
      name: "catalogos_responsables",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: () => import(/* webpackChunkName: "catalogos_responsables" */ "./views/catalogos/responsables.vue"),
      },
    },

    {
      path: "/icons",
      name: "icons",
      meta: { requiresAuth: true },
      components: {
        layout: defaultLayout,
        content: Icons,
      },
    },

    {
      path: "/admin",
      name: "admin",
      meta: { requiresAuth: true, requiresModulo: "AD" },
      components: {
        layout: defaultLayout,
        content: () => import(/* webpackChunkName: "login" */ "./views/admin"),
      },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (to.name === "login-form" && auth.loggedIn()) {
    next({ name: "home" });
  }

  if (to.matched.some((record) => record.meta.requiresModulo)) {
    if (!(await auth.hasModulo(to.meta.requiresModulo))) {
      next({
        name: "home",
        // query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!auth.loggedIn()) {
      next({
        name: "login-form",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
