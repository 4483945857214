export default [
  { id: -1000, img: "/img/resultados/no-result.png", text: "sinResultado", color: "danger" },

  { id: 0, img: "/img/resultados/nok.png", text: "nok", color: "danger" },
  { id: 1, img: "/img/resultados/ok.png", text: "ok", color: "success" },
  { id: 2, img: "/img/resultados/excedido-por-arriba.png", text: "excedidoPorArriba", color: "warning" },
  { id: 3, img: "/img/resultados/excedido-por-abajo.png", text: "excedidoPorAbajo", color: "warning" },
  {
    id: 4,
    img: "/img/resultados/excedido-por-arriba-y-abajo.png",
    text: "excedidoPorArribaYAbajo",
    color: "warning",
  },
];
