<template>
    <div>
        <Comun :estructura="estructuraComputed"></Comun>

        <div class="d-flex flex-row w-100 pb-3" style="gap: 1em">
            <div class="d-flex flex-column readonly w-100">
                <label class="form-label">{{ $t("producto") }}</label>
                <div class="form-data">
                    {{ displayProducto }}
                </div>
            </div>
            <div class="d-flex flex-column readonly w-100">
                <label class="form-label">{{ $t("departamento") }}</label>
                <div class="form-data">
                    {{ displayDepartamento }}
                </div>
            </div>
            <div class="d-flex flex-column readonly w-100">
                <label class="form-label">{{ $t("proveedor") }}</label>
                <div class="form-data">
                    {{ displayProveedor }}
                </div>
            </div>
        </div>

        <Pantalla1Edit
            :showEdit.sync="showEditComputed"
            :estructura.sync="estructuraComputed"
            :update="update">
        </Pantalla1Edit>

    </div>

</template>

<script>
import Comun from "./Comun.vue";
import Pantalla1Edit from './Pantalla1.edit.vue';

// import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";

export default {
    components: { Pantalla1Edit, Comun },
    props: ["estructura", "showEdit", "update"],

    data() {
        return {
            proveedores: new CustomStore({
                key: "id",
                // loadMode: "raw",
                // cacheRawData: true,
                // load: () => this.$fetch.get(`${global.API_URL}/proveedores`),
                byKey: (key) => this.$fetch.get(`${global.API_URL}/proveedores/${key}`),
                // insert: (values) => this.$fetch.post(`${global.API_URL}/proveedores`, values),
                // update: (key, values) => this.$fetch.put(`${global.API_URL}/proveedores/${key}`, values),
                // remove: (key) => this.$fetch.delete(`${global.API_URL}/proveedores/${key}`),
            }),
            departamentos: new CustomStore({
                key: "id",
                // loadMode: "raw",
                // cacheRawData: true,
                // load: () => this.$fetch.get(`${global.API_URL}/departamentos`),
                byKey: (key) => this.$fetch.get(`${global.API_URL}/departamentos/${key}`),
                // insert: (values) => this.$fetch.post(`${global.API_URL}/departamentos`, values),
                // update: (key, values) => this.$fetch.put(`${global.API_URL}/departamentos/${key}`, values),
                // remove: (key) => this.$fetch.delete(`${global.API_URL}/departamentos/${key}`),
            }),

            productos: new CustomStore({
                key: "id",
                // loadMode: "raw",
                // cacheRawData: true,
                // load: () => this.$fetch.get(`${global.API_URL}/productos`),
                byKey: (key) => this.$fetch.get(`${global.API_URL}/productos/${key}`),
                // insert: (values) => this.$fetch.post(`${global.API_URL}/productos`, values),
                // update: (key, values) => this.$fetch.put(`${global.API_URL}/productos/${key}`, values),
                // remove: (key) => this.$fetch.delete(`${global.API_URL}/productos/${key}`),
            }),
        }
    },
    computed: {
        showEditComputed: {
            get() {
                return this.showEdit
            },
            set(value) {
                this.$emit("update:showEdit", value)
            }
        },
        estructuraComputed: {
            get() {
                return this.estructura
            },
            set(value) {
                this.$emit("update:estructura", value)
            }
        },
    },
    asyncComputed: {
        displayProveedor: async function () {
            if (!this.estructuraComputed.id_proveedores) return "";
            return (await this.proveedores.byKey(this.estructuraComputed.id_proveedores))?.codigo;
        },

        displayDepartamento: async function () {
            if (!this.estructuraComputed.id_departamentos) return "";
            return (await this.departamentos.byKey(this.estructuraComputed.id_departamentos))?.codigo;
        },

        displayProducto: async function () {
            if (!this.estructuraComputed.id_productos) return "";
            return (await this.productos.byKey(this.estructuraComputed.id_productos))?.codigo;
        },
    },
}
</script>