<!-- eslint-disabled -->
<template>
  <div :key="componentKey">
    <!-- <h2 class="content-block">
      <DxButton icon="arrowleft" type="back"
        styling-mode="text"
        @click="$router.go(-1)" />
      {{ $t("clasificacion") }}
    </h2> -->
    <TitleWithBackButton :text="$t('clasificacion')"></TitleWithBackButton>

    <!-- <button @click="addNewClasificacion">+</button> -->
    <div class="content-block row gap-2">
      <div id="treeViewContainer" class="col dx-card p-4">
        <DxSortable
          filter=".dx-treeview-item"
          :data="dataSourceClasificacion"
          :allow-drop-inside-item="true"
          :allow-reordering="false"
          @drag-change="onSortableDragChange"
          @drag-end="onSortableDragEnd"
          @drag-start="onSortableDragStart"
        >
          <DxTreeView
            :expandAllEnabled="true"
            id="treeView"
            ref="treeView"
            dataStructure="plain"
            :dataSource="dataSourceClasificacion"
            display-expr="nombre"
            parent-id-expr="id_parent"
            @itemContextMenu="onTreeViewItemContextMenu"
            @itemHold="onTreeViewItemContextMenu"
            @itemClick="onTreeViewItemClick"
            @itemSelectionChanged="onTreeViewItemSelectionChanged"
            selectionMode="single"
            :selectByClick="true"
            showCheckBoxesMode="none"
          >
            <template #item="{ data }">
              <div class="align-middle">
                <span class="align-middle">{{ data.codigo }}</span>
                -
                <span class="align-middle">{{ data.nombre }}</span>
              </div>
            </template>
          </DxTreeView>
        </DxSortable>

        <DxContextMenu
          ref="treeViewContextMenu"
          :data-source.sync="menuItems"
          target="#treeViewContainer .dx-treeview-item"
          @item-click="contextMenuItemClick"
        />
      </div>
      <div v-if="selectedClasificacion" class="dx-card col vh-100 sticky-top">
        <DxDataGrid
          :cacheEnabled="false"
          ref="dataGrid"
          :data-source="dataSourceInstrumentos"
          :hover-state-enabled="true"
          height="100%"
          width="100%"
          @row-click="onRowClick"
          @rowDblClick="onRowDblClick"
        >
          <DxColumn data-field="codigo" :caption="$t('codigo')" width="200" />
          <DxColumn data-field="nombre" :caption="$t('nombre')" width="200" />
        </DxDataGrid>
      </div>
    </div>
  </div>
</template>

<script>
import TitleWithBackButton from "@/components/core/TitleWithBackButton.vue";
//import CustomStore from "devextreme/data/custom_store";
//import DataSource from "devextreme/data/data_source";
// import auth from "../../auth";

import { DataSourceClasificacion } from "@/data/instrumentos/ClasificacionDataSource";
import { DataSourceInstrumentos } from "@/data/instrumentos/InstrumentoDataSource";

import { jsPDF } from "jspdf";
import { exportDataGrid } from "devextreme/pdf_exporter";

import DxTreeView from "devextreme-vue/tree-view";
import DxSortable from "devextreme-vue/sortable";
import { DxContextMenu } from "devextreme-vue/context-menu";

import { DxDataGrid, DxColumn } from "devextreme-vue/data-grid";

// import situaciones from "@/data/Situaciones";
// console.log(situaciones);

export default {
  components: {
    TitleWithBackButton,
    DxTreeView,
    DxSortable,
    DxContextMenu,
    DxDataGrid,
    DxColumn,
  },

  data() {
    return {
      componentKey: 0,
      window: {
        width: 0,
        height: 0,
      },

      // customStoreClasificacion: new CustomStore({
      //   loadMode: "raw",
      //   key: "id",
      //   load: () => this.$fetch.get(`${global.API_URL}/instrumentos/clasificacion`),
      //   byKey: (key) => this.$fetch.get(`${global.API_URL}/instrumentos/clasificacion/${key}`),
      //   insert: (values) =>
      //     this.$fetch.post(`${global.API_URL}/instrumentos/clasificacion/`, values),
      //   update: (key, values) =>
      //     this.$fetch.put(`${global.API_URL}/instrumentos/clasificacion/${key}`, values),
      //   remove: (key) => this.$fetch.delete(`${global.API_URL}/instrumentos/clasificacion/${key}`),
      //   sort: "codigo"
      // }),

      // dataSourceClasificacion: new DataSource({
      //   store: new CustomStore({
      //     key: "id",
      //     loadMode: "raw",
      //     load: () => this.$fetch.get(`${global.API_URL}/instrumentos/clasificacion`),
      //     byKey: (key) => this.$fetch.get(`${global.API_URL}/instrumentos/clasificacion/${key}`),
      //     insert: (values) =>
      //       this.$fetch.post(`${global.API_URL}/instrumentos/clasificacion/`, values),
      //     update: (key, values) =>
      //       this.$fetch.put(`${global.API_URL}/instrumentos/clasificacion/${key}`, values),
      //     remove: (key) => this.$fetch.delete(`${global.API_URL}/instrumentos/clasificacion/${key}`),
      //   })

      //   ,
      //   // sort: [{ field: 'id', desc: false }]
      //   sort: "codigo",
      //   // filter: ["id", ">", "10"]
      //   // postProcess: function (e) {
      //   //   console.log("postProcess", e)
      //   //   return e.reverse();
      //   // }
      // }),

      dataSourceClasificacion: new DataSourceClasificacion(),
      dataSourceInstrumentos: new DataSourceInstrumentos(),

      selectedClasificacion: null,

      menuItems: [
        {
          id: "rename",
          icon: "rename",
          text: this.$t("renombrar"),
          // beginGroup: true,
        },

        {
          id: "add",
          icon: "plus",
          text: this.$t("añadirClasificacion"),
        },

        {
          id: "del",
          icon: "trash",
          text: this.$t("eliminar"),
          beginGroup: true,
        },
      ],
    };
  },

  computed: {
    gridHeight() {
      //return window.innerHeight - 300;
      return this.window.height - 300;
    },
    idioma() {
      return this.$i18n.locale;
    },
    // dataSourceSituaciones() {
    //   return situaciones.map((e) => ({
    //     ...e,
    //     value: this.$t(e.value),
    //   }));
    // },
  },

  methods: {
    onRowClick(e) {
      console.log("onRowClick", e);
    },

    onRowDblClick(e) {
      // console.log("-------- double click", e);
      this.$router.push({ name: "instrumento", params: { id: e.data.id } });
    },

    // async load() {
    //   const urls = [
    //     // `${global.API_URL}/planes`,
    //     `${global.API_URL}/clientes`,
    //     `${global.API_URL}/agrupaciones`,
    //   ];

    //   //obtengo todos los fetch en paralelo
    //   const responses = await Promise.all(
    //     urls.map((u) =>
    //       fetch(u, {
    //         headers: {
    //           "Content-Type": "application/json",
    //           "Authorization": "Bearer " + auth.getToken(),
    //         },
    //       })
    //     )
    //   );

    //   const data = await Promise.all(responses.map((r) => r.json()));

    //   // this.dataSourcePlanes = data[0];
    //   this.dataSourceClientes = data[0];
    //   this.dataSourceAgrupaciones = data[1];
    // },

    onSortableDragChange(e) {
      console.log("onSortableDragChange", e);
    },
    onSortableDragEnd(e) {
      console.log("onSortableDragEnd", e);
    },
    onSortableDragStart(e) {
      console.log("onSortableDragStart", e);
    },

    onTreeViewItemContextMenu(e) {
      console.log("onTreeViewItemContextMenu", e);

      // this.selectedTreeView = e;
      // this.selectedTreeViewData = e.itemData;
      // this.selectedTreeViewNode = e.node;

      // const contextMenu = this.$refs.treeViewContextMenu.instance;
      // const nivel = tipos.find((f) => f.id === e.itemData.tipo).nivel;

      // //Convertimos el array de menuItems en un objeto para optimizar el acceso.
      // const menuItemsObject = this.menuItems.reduce(
      //   (a, x) => ({ ...a, [x.id]: x }),
      //   {}
      // );

      // menuItemsObject.add1.visible = false;
      // menuItemsObject.add2.visible = nivel === 1;
      // menuItemsObject.add3.visible = nivel === 2;
      // menuItemsObject.rename.visible = true;

      // menuItemsObject.del.visible = true;

      // contextMenu.option("dataSource", Object.values(menuItemsObject));
    },

    onTreeViewItemClick(e) {
      console.log("onTreeViewItemClick", e.itemData.id);
      this.selectedClasificacion = e.itemData;
    },

    onTreeViewItemSelectionChanged(e) {
      console.log("onTreeViewItemSelectionChanged", e.itemData.id);
    },

    async contextMenuItemClick(e) {
      console.log("contextMenuItemClick", e);
    },

    async onExporting(e) {
      const doc = new jsPDF();
      await exportDataGrid({
        jsPDFDocument: doc,
        component: e.component,
        indent: 5,
      });
      doc.save("Planes.pdf");
    },

    async addNewClasificacion() {
      console.log("addNewClasificacion");

      // this.$refs.treeViewPlan.instance()

      // const response = await this.customStoreClasificacion.insert({
      //   codigo: "lorem",
      //   nombre: "ipsum",
      //   id_parent: null,
      // });

      // console.log(response)

      console.log(this.$refs.treeView.instance.getDataSource());

      // this.$refs.treeView.instance.getDataSource().reload()
      const treeView = this.$refs.treeView.instance;
      const treeDataSource = this.$refs.treeView.instance.getDataSource();

      console.log(treeView.getSelectedNodeKeys());

      treeDataSource
        .store()
        .insert({
          codigo: "lorem",
          nombre: "ipsum",
          id_parent: treeView.getSelectedNodeKeys()[0],
        })
        .done((values, key) => {
          console.log(values, key);
          treeDataSource.reload();
        })
        .fail((error) => {
          console.log(error);
        });
    },
  },

  created() {
    // this.load();
    // window.addEventListener("resize", this.handleResize);
    // this.handleResize();
  },

  destroyed() {
    // window.removeEventListener("resize", this.handleResize);
  },

  beforeCreate() {},

  mounted() {
    console.log("🚀 ~ file: ubicaciones.vue:257 ~ mounted ~ mounted");
    this.$refs["treeView"].instance.expandAll();
  },

  watch: {
    idioma() {
      this.componentKey++;
    },
    selectedClasificacion: function (newValue) {
      this.dataSourceInstrumentos.filter("id_clasificacion", newValue.id);
      this.dataSourceInstrumentos.reload();
    },
  },
};
</script>

<style lang="scss"></style>
