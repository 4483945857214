<!-- eslint-disabled -->
<template>
  <div :key="componentKey">
    <h2 class="content-block">{{ $t("planes") }}</h2>
    <div class="content-block">
      <div class="dx-card">
        <!-- <h1>Añadir toolbar: - imprimir plan (exportar grid) - duplicar plan</h1> -->

        <!-- <GridLayout :gridRef="this.$refs[gridRefName]" :gridName="gridRefName" /> -->
        <!-- <ControlPlanDuplicate :controlPlan="selectedPlan"></ControlPlanDuplicate> -->

        <DxPopup
          width="475"
          height="auto"
          :title="$t('duplicar')"
          :visible.sync="isPopupDuplicateVisible"
          @showing="onDuplicateShowing"
        >
          <code>
            <!-- {{ selectedPlan }} -->
            <!-- {{ newValues }} -->
            <!-- {{ `${selectedPlan?.referencia} + (copia)` }} -->
            <!-- {{ JSON.stringify(relatedTables, null, 2) }} -->
          </code>

          <div class="d-flex flex-column gap-2 responsive-paddings pt-1">
            <DxTextBox
              class="my-2"
              labelMode="floating"
              :label="$t('referencia')"
              :value.sync="newValues.referencia"
              name="referencia"
              :show-clear-button="true"
            >
              <DxValidator validationGroup="duplicate">
                <DxRequiredRule :message="$t('errorReferenciaRequerida')" />
              </DxValidator>
            </DxTextBox>

            <DxTextBox
              class="my-2"
              labelMode="floating"
              :label="$t('referenciaCliente')"
              :value.sync="newValues.referencia_cliente"
              name="nombre"
              :show-clear-button="true"
            >
              <!-- <DxValidator validationGroup="duplicate">
                <DxRequiredRule :message="$t('errorNombreRequerido')" />
              </DxValidator> -->
            </DxTextBox>

            <DxTextBox
              class="my-2"
              labelMode="floating"
              :label="$t('nombre')"
              :value.sync="newValues.nombre"
              name="nombre"
              :show-clear-button="true"
            >
              <DxValidator validationGroup="duplicate">
                <DxRequiredRule :message="$t('errorNombreRequerido')" />
              </DxValidator>
            </DxTextBox>

            <!-- <DxCheckBox
              :value.sync="relatedTables.conjuntos"
              name="duplicateConjuntos"
              text="Conjuntos Implicados"
            ></DxCheckBox>
            <br />
            <DxCheckBox :value.sync="relatedTables.equipos" name="duplicateEquipos" text="Equipos"></DxCheckBox>
            <br />
            <DxCheckBox
              :value.sync="relatedTables.estructura"
              name="duplicateEstructura"
              text="Estructura"
            ></DxCheckBox>
            <br />
            <DxCheckBox :value.sync="relatedTables.notas" name="duplicateNotas" text="Notas"></DxCheckBox>
            <br />
            <DxCheckBox :value.sync="relatedTables.planos" name="duplicatePlanos" text="Planos"></DxCheckBox>
            <br />
            <DxCheckBox :value.sync="relatedTables.plantas" name="duplicatePlantas" text="Plantas"></DxCheckBox>
            <br />
            <DxCheckBox
              :value.sync="relatedTables.revisiones"
              name="duplicateRevisiones"
              text="Revisiones"
            ></DxCheckBox>
            <br />
            <DxCheckBox
              :value.sync="relatedTables.fotos"
              name="duplicateFotos"
              text="Fotos"
              :disabled="true"
            ></DxCheckBox>
            <br />
            <DxCheckBox
              :value.sync="relatedTables.documentos"
              name="duplicateDocumentos"
              text="Documentos"
              :disabled="true"
            ></DxCheckBox>
            <br /> -->

            <h3>{{ $t("textoDuplicarPC") }}</h3>

            <div class="d-flex flex-column gap-2 px-4 my-2">
              <div>
                <input
                  type="checkbox"
                  id="pcestructura"
                  value="pcestructura"
                  v-model="relatedTables"
                  @change="pcestructuraChanged"
                />
                <label for="pcestructura">{{ $t("estructura") }}</label>
              </div>

              <div class="ms-3">
                <input
                  type="checkbox"
                  id="caracteristicas"
                  value="caracteristicas"
                  v-model="relatedTables"
                  @change="caracteristicasChanged"
                />
                <label for="caracteristicas">{{ $t("caracteristicas") }}</label>
              </div>

              <div>
                <input type="checkbox" id="pcrevisiones" value="pcrevisiones" v-model="relatedTables" />
                <label for="pcrevisiones">{{ $t("revisiones") }}</label>
              </div>

              <div>
                <input type="checkbox" id="pcequipo" value="pcequipo" v-model="relatedTables" />
                <label for="pcequipo">{{ $t("equipos") }}</label>
              </div>

              <div>
                <input type="checkbox" id="pcnotas" value="pcnotas" v-model="relatedTables" />
                <label for="pcnotas">{{ $t("notasAlControlador") }}</label>
              </div>

              <div>
                <input
                  type="checkbox"
                  id="pcconjuntosimplicados"
                  value="pcconjuntosimplicados"
                  v-model="relatedTables"
                />
                <label for="pcconjuntosimplicados">{{ $t("conjuntosImplicados") }}</label>
              </div>

              <div>
                <input type="checkbox" id="pcplanos" value="pcplanos" v-model="relatedTables" />
                <label for="pcplanos">{{ $t("planos") }}</label>
              </div>

              <div>
                <input
                  type="checkbox"
                  id="pcplantasconsumidoras"
                  value="pcplantasconsumidoras"
                  v-model="relatedTables"
                />
                <label for="pcplantasconsumidoras">{{ $t("plantasConsumidoras") }}</label>
              </div>

              <div v-if="false">
                <input type="checkbox" id="fotos" value="fotos" v-model="relatedTables" disabled="true" />
                <label for="fotos">{{ $t("fotos") }}</label>
              </div>

              <div v-if="false">
                <input type="checkbox" id="documentos" value="documentos" v-model="relatedTables" disabled="true" />
                <label for="documentos">{{ $t("documentos") }}</label>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-end pt-4">
            <DxButton
              type="default"
              styling-mode="contained"
              @click="duplicateClick"
              :text="$t('duplicar')"
              validationGroup="duplicate"
            >
            </DxButton>
          </div>
        </DxPopup>

        <DxDataGrid
          :ref="gridRefName"
          id="gridPlanes"
          class="dx-card wide-card"
          :data-source="customStorePlanes"
          keyExpr="id"
          :hover-state-enabled="true"
          :allow-column-resizing="true"
          :allow-column-reordering="true"
          column-resizing-mode="nextColumn"
          :selection="{ mode: 'single' }"
          :noDataText="$t('noHayPlanesDeControl')"
          :height="gridHeight"
          :paging="{
            enabled: false,
          }"
          @selection-changed="onSelectionChanged"
          @rowDblClick="onRowDblClick"
          @initNewRow="onInitNewRow"
          @exporting="onExporting"
        >
          <!-- <dx-filter-row :visible="false" />-->
          <!-- <dx-header-filter :visible="false" /> -->
          <DxStateStoring :enabled="true" storage-key="grid-planes"> </DxStateStoring>

          <DxEditingGrid :allow-updating="false" :allow-adding="true" :allow-deleting="false" mode="popup">
            <DxPopupGrid :title="$t('planControl')" :show-title="true" :width="700" :height="800"> </DxPopupGrid>
            <DxForm colCount="1" labelMode="floating" :title="$t('nuevoPlanControl')">
              <DxFormItem dataField="referencia" />
              <DxFormItem dataField="situacion" />
              <DxFormItem dataField="nombre" />
              <DxFormItem dataField="id_cliente" />
              <DxFormItem dataField="id_agrupaciones" />
            </DxForm>
          </DxEditingGrid>

          <DxSearchPanel :visible="true" :width="240" />
          <DxFilterRow :visible="true"></DxFilterRow>
          <DxFilterPanel :visible="true"></DxFilterPanel>
          <DxHeaderFilter :visible="true" />
          <DxGroupPanel :visible="true" />
          <DxGrouping :auto-expand-all="true" />
          <DxSummary>
            <DxTotalItem column="referencia" summary-type="count" :display-format="$t('total') + ':{0}'" />
          </DxSummary>
          <DxLoadPanel :enabled="true" />

          <!-- Para mostrar todos lo elementos: -->
          <!-- <DxScrolling mode="standard" /> -->
          <!-- <DxScrolling mode="virtual" /> -->
          <DxScrolling mode="infinite" />

          <!-- <DxExport :enabled="true" :formats="['pdf', 'xlsx']" /> -->
          <DxExport :enabled="true" :formats="['xlsx']" />
          <DxToolbar>
            <DxItem location="before" name="groupPanel"></DxItem>
            <DxItem location="after" name="exportButton"></DxItem>
            <DxItem location="after" name="addRowButton"></DxItem>
            <DxItem location="after" name="layoutButton" template="layoutButtonTemplate"> </DxItem>

            <DxItem location="after" name="duplicate" template="duplicateTemplate"> </DxItem>
            <DxItem location="after" name="refresh" template="refreshTemplate"> </DxItem>
            <DxItem location="after" name="searchPanel"></DxItem>
          </DxToolbar>

          <template #layoutButtonTemplate>
            <GridLayout v-if="true" :gridRef="$refs[gridRefName]" :gridName="gridRefName" />
          </template>

          <DxColumn data-field="id" :caption="$t('numero')" width="80" sort-order="desc" :allowEditing="false" />

          <DxColumn data-field="referencia" :caption="$t('referencia')">
            <DxRequiredRule />
          </DxColumn>

          <DxColumn
            :visible="false"
            :allowEditing="false"
            data-type="boolean"
            data-field="familia"
            width="80"
            :caption="$t('familia')"
            cell-template="familia"
          >
          </DxColumn>

          <template #familia="{ data }">
            <div>
              <!-- <img v-if="data.value" src="@/assets/checked-24.png" />
              <img v-else src="@/assets/unchecked-24.png" /> -->
              <i v-if="data.value" class="fa fa-check-square-o fs-6" aria-hidden="true"></i>
              <i v-else class="fa fa-square-o fs-6" aria-hidden="false"></i>
            </div>
          </template>

          <DxColumn data-field="situacion" width="100" :caption="$t('situacion')" cell-template="situacion">
            <DxRequiredRule />
            <DxLookup display-expr="value" value-expr="id" :data-source="dataSourceSituaciones"> </DxLookup>
          </DxColumn>

          <template #situacion="{ data }">
            <div>
              <img
                :src="require(`@/assets/situaciones/${dataSourceSituaciones[data.value].img}`)"
                :title="$t(dataSourceSituaciones[data.value].value)"
                height="24"
              />
            </div>
          </template>

          <DxColumn data-field="referencia_cliente" :caption="$t('referenciaCliente')"> </DxColumn>

          <DxColumn data-field="nombre" :caption="$t('plan')">
            <DxRequiredRule />
          </DxColumn>

          <DxColumn data-field="fecha_emision" :caption="$t('fechaEmision')" width="130" data-type="date"> </DxColumn>

          <DxColumn data-field="id_cliente" :caption="$t('cliente')">
            <DxLookup :display-expr="displayExprCliente" value-expr="id" :data-source="dataSourceClientes"> </DxLookup>
          </DxColumn>

          <DxColumn data-field="id_agrupaciones" :caption="$t('agrupacion')">
            <DxLookup :display-expr="displayExprAgrupacion" value-expr="id" :data-source="dataSourceAgrupaciones">
            </DxLookup>
          </DxColumn>

          <template #fechaTemplate="{ data }">
            <div>
              {{ getFecha(new Date(data.value)) }}
              <!-- <br />
              {{ getHora(new Date(data.value)) }} <br />
              {{ getTime(new Date(data.value)) }} <br /> -->
            </div>
          </template>

          <template #duplicateTemplate>
            <DxButton
              icon="copy"
              stylingMode="text"
              :disabled="selectedPlan === undefined"
              @click="duplicateControlPlan"
            ></DxButton>
          </template>

          <template #refreshTemplate>
            <DxButton icon="refresh" @click="refreshDataGrid"></DxButton>
          </template>
        </DxDataGrid>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxLookup,
  DxScrolling,
  DxLoadPanel,
  DxFilterRow,
  DxSearchPanel,
  DxEditing as DxEditingGrid,
  DxFilterPanel,
  DxGroupPanel,
  DxGrouping,
  DxHeaderFilter,
  DxSummary,
  DxTotalItem,
  DxStateStoring,
  DxExport,
  DxToolbar,
  DxPopup as DxPopupGrid,
  DxItem,
  DxForm,
} from "devextreme-vue/data-grid";

import { DxItem as DxFormItem } from "devextreme-vue/form";

import { DxTextBox } from "devextreme-vue/text-box";

import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";

import { DxButton } from "devextreme-vue/button";

import { DxPopup } from "devextreme-vue/popup";

// import { DxCheckBox } from "devextreme-vue/check-box";

import { jsPDF } from "jspdf";
import { exportDataGrid } from "devextreme/pdf_exporter";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { exportDataGrid as exportDataGridToExcel } from "devextreme/excel_exporter";

import CustomStore from "devextreme/data/custom_store";

import auth from "../auth";

import situaciones from "@/data/Situaciones";
import GridLayout from "../components/grid-layout.vue";

// console.log(situaciones);

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxLookup,
    DxScrolling,
    DxLoadPanel,
    DxFilterRow,
    DxSearchPanel,
    DxEditingGrid,
    DxFilterPanel,
    DxGroupPanel,
    DxGrouping,
    DxHeaderFilter,
    DxSummary,
    DxTotalItem,
    GridLayout,
    DxExport,
    DxToolbar,
    DxItem,
    DxStateStoring,
    //
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    //
    DxButton,
    //
    DxPopup,
    //
    // DxCheckBox,
    DxPopupGrid,
    DxForm,
    DxFormItem,
  },

  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },

      customStorePlanes: new CustomStore({
        key: "id",
        load: () => this.$fetch.get(`${global.API_URL}/planes`),
        byKey: (key) => this.$fetch.get(`${global.API_URL}/planes/${key}`),
        insert: (values) => this.$fetch.post(`${global.API_URL}/planes/`, values),
        update: (key, values) => this.$fetch.put(`${global.API_URL}/planes/${key}`, values),
        remove: (key) => this.$fetch.delete(`${global.API_URL}/planes/${key}`),
      }),

      // dataSourcePlanes: [],
      // this.dataGrid.state(this.estadoGrid)
      gridRefName: "gridPlanes",
      dataSourceClientes: [],
      dataSourceAgrupaciones: [],
      selectedPlan: undefined,
      componentKey: 0,

      dataSourceFamilias: [
        { id: 0, value: this.$t("noEsFamilia") },
        { id: 1, value: this.$t("esFamilia") },
      ],

      //@TODO: REFACTOR DUPLICATE PLANES TO A COMPONENT

      isPopupDuplicateVisible: false,

      // duplicar
      // duplicateConjuntos: false,
      // duplicateEquipos: false,
      // duplicateEstructura: false,
      // duplicateNotas: false,
      // duplicatePlanos: false,
      // duplicatePlantas: false,
      // duplicateRevisiones: false,
      // duplicateFotos: false,
      // duplicateDocumentos: false,

      // relatedTables: {
      //   conjuntos: false,
      //   equipos: false,
      //   estructura: false,
      //   notas: false,
      //   planos: false,
      //   plantas: false,
      //   revisiones: false,
      //   fotos: false,
      //   documentos: false,
      // },

      newValues: {
        referencia: "",
        referencia_cliente: "",
        nombre: "",
      },

      relatedTables: [
        "pcconjuntosimplicados",
        "pcequipo",
        "pcestructura",
        "caracteristicas",
        "pcnotas",
        "pcplanos",
        "pcplantasconsumidoras",
        "pcrevisiones",
        // "fotos",
        // "documentos",
      ],
    };
  },

  computed: {
    gridHeight() {
      //return window.innerHeight - 300;
      return this.window.height - 300;
    },
    idioma() {
      return this.$i18n.locale;
    },
    dataSourceSituaciones() {
      return situaciones.map((e) => ({
        ...e,
        value: this.$t(e.value),
      }));
    },
  },

  methods: {
    itemTypeExpr() {
      // console.log(obj, this.dataSourceDiagram);
      // if (this.dataSourceDiagram.length === 0) return "rectangle";
      // return "shape" + obj.id;
      return "customShape";
    },
    onSelectionChanged(e) {
      console.log("-------- selection changed!", e.selectedRowsData[0]);
      //this.$emit("update:selectedRow", e.selectedRowsData[0]);
      // this.$router.push({
      //   name: "plan",
      //   params: { id: e.selectedRowsData[0].id },
      // });
      this.selectedPlan = e.selectedRowsData[0];
    },

    onRowDblClick(e) {
      console.log("-------- double click", e);

      if (e.isNewRow) return;
      if (e.isEditing) return;

      //this.$emit("update:selectedRow", e.selectedRowsData[0]);
      this.$router.push({
        name: "plan",
        params: { id: e.key },
      });
    },

    onInitNewRow(e) {
      e.data.familia = false;
      e.data.situacion = 0;
      e.data.fecha_emision = new Date();
    },

    async load() {
      const urls = [
        // `${global.API_URL}/planes`,
        `${global.API_URL}/clientes`,
        `${global.API_URL}/agrupaciones`,
      ];

      //obtengo todos los fetch en paralelo
      const responses = await Promise.all(
        urls.map((u) =>
          fetch(u, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + auth.getToken(),
            },
          })
        )
      );

      const data = await Promise.all(responses.map((r) => r.json()));

      // this.dataSourcePlanes = data[0];
      this.dataSourceClientes = data[0];
      this.dataSourceAgrupaciones = data[1];
    },

    getTime(date) {
      return this.$moment(date).fromNow();
    },

    getFecha(date) {
      return this.$moment(date).format("L");
    },

    getHora(date) {
      return this.$moment(date).format("LTS");
    },

    handleResize() {
      // console.log(window.innerWidth, window.innerWidth);
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },

    displayExprCliente(item) {
      if (!item) return;
      if (item.nombre && item.codigo) return `${item.codigo} - ${item.nombre}`;
      if (item.nombre) return item.nombre;
      if (item.codigo) return item.codigo;
    },
    displayExprAgrupacion(item) {
      if (!item) return;
      if (item.nombre && item.codigo) return `${item.codigo} - ${item.nombre}`;
      if (item.nombre) return item.nombre;
      if (item.codigo) return item.codigo;
    },
    async onExporting(e) {
      if (e.format === "xlsx") {
        /* const workbook = new Workbook();
                 const worksheet = workbook.addWorksheet('Planes');
                 exportDataGridToExcel({
                     component: e.component,
                    worksheet,
                   autoFilterEnabled: true,
                 }).then(() => {
                     workbook.xlsx.writeBuffer().then((buffer) => {
                     saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Planes.xlsx');
                    });
                 });
                 e.cancel = true; */

        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Planes");
        exportDataGridToExcel({
          component: e.component,
          worksheet: worksheet,
          autoFilterEnabled: true,
          topLeftCell: { row: 2, column: 2 },
          customizeCell: function (options) {
            const excelCell = options;
            excelCell.font = { name: "Arial", size: 12 };
            excelCell.alignment = { horizontal: "left" };
          },
        }).then(function () {
          workbook.xlsx.writeBuffer().then(function (buffer) {
            saveAs(new Blob([buffer], { type: "application/octet-stream" }), "Planes.xlsx");
          });
        });
        e.cancel = true;
      } else if (e.format === "pdf") {
        const doc = new jsPDF();
        await exportDataGrid({
          jsPDFDocument: doc,
          component: e.component,
          indent: 5,
        });
        doc.text("Planes de control", 1000, 20);
        doc.save("Planes.pdf");
      }
    },

    duplicateControlPlan() {
      this.isPopupDuplicateVisible = true;
    },

    refreshDataGrid() {
      this.$refs[this.gridRefName].instance.refresh();
    },

    onDuplicateShowing() {
      this.newValues.referencia = this.selectedPlan.referencia + `(${this.$t("copia")})`;
      this.newValues.referencia_cliente =
        this.selectedPlan.referencia_cliente && this.selectedPlan.referencia_cliente + `(${this.$t("copia")})`;
      this.newValues.nombre = this.selectedPlan.nombre + `(${this.$t("copia")})`;

      // this.newValues.referencia = "";
      // this.newValues.referencia_cliente = "";
      // this.newValues.nombre = "";
    },

    pcestructuraChanged(e) {
      console.log("pcestructuraChanged", e);
      if (!e.target.checked) {
        this.relatedTables = this.relatedTables.filter((t) => t !== "caracteristicas");
      }
    },

    caracteristicasChanged(e) {
      console.log("caracteristicasChanged", e);
      if (e.target.checked) {
        this.relatedTables.includes("pcestructura") || this.relatedTables.push("pcestructura");
      }
    },

    async duplicateClick(e) {
      // console.log("duplicateClick", e);
      if (!e.validationGroup.validate().isValid) return;

      if (this.selectedPlan === undefined) return console.log("Error, no hay plan seleccionado");

      // const idPlan = this.selectedPlan.id;

      const values = {
        // planID: this.selectedPlan,  <-- no hace falta, ya lo tengo en el endpoint
        newValues: this.newValues,
        relatedTables: this.relatedTables,
        // relatedTables: [
        //   "pcconjuntosimplicados": this.duplicateConjuntos,
        //   "pcequipo": this.duplicateEquipos,
        //   "pcestructura": this.duplicateEstructura,
        //   "pcnotas": this.duplicateNotas,
        //   "pcplanos": this.duplicatePlanos,
        //   "pcplantasconsumidoras": this.duplicatePlantas,
        //   "pcrevisiones": this.duplicateRevisiones,
        //   "fotos": this.duplicateFotos,
        //   "documentos": this.duplicateDocumentos
        // ]
      };

      try {
        const response = await this.$fetch.post(`${global.API_URL}/planes/${this.selectedPlan.id}/duplicate`, values);
        console.log(response);

        this.isPopupDuplicateVisible = false;
        this.refreshDataGrid();
        this.$notify(this.$t("planDuplicadoCorrectamente"));
      } catch (ex) {
        console.log(ex);
        this.$notify(this.$t("errorDuplicarPlan"), "error");
      }
    },
  },

  created() {
    this.load();

    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  beforeCreate() {},

  async mounted() {
    console.log("testtest:", this.$fetch);
  },

  watch: {
    idioma() {
      this.componentKey++;
    },
  },
};
</script>

<style lang="scss">
input[type="checkbox"] {
  margin: 0 1em;
}
input[type="checkbox"]:disabled + label {
  color: #b8b8b8;
  /* display: none; */
  /* visibility: hidden; */
  /* position: absolute; */
  /* left: -9999px; */
  /* top: -9999px; */
  /* opacity: 0; */
  /* z-index: -9999; */
}
</style>
