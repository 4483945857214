export function stddev(a) {
  if (a.length === 0) return 0;
  const n = a.length;
  const avg = average(a);
  return Math.sqrt(a.map((x) => Math.pow(x - avg, 2)).reduce((acc, elem) => acc + elem) / (n - 1)); //verificar n vs n-1 (Bessel's correction)
}

export function average(a) {
  if (a.length === 0) return 0;
  let arr = a.map((e) => (e === null ? 0 : Number(e)));
  return arr.reduce((previous, current) => (current += previous)) / arr.length;
}
