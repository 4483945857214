<template>
  <div class="position-relative">
    <div
      v-if="showError"
      class="grafico-error p-3 rounded position-absolute top-50 start-50 translate-middle text-white"
    >
      {{ errorMessage }}
    </div>

    <div class="d-flex flex-column flex-xl-row justify-content-between">
      <div class="d-flex flex-row align-items-center">
        <div class="title">
          <h2>
            {{ $t("graficoPorAtributos") }}:
            <b>{{ metodoAnalisis.codigo }}</b>
          </h2>
        </div>

        <div class="buttons pl-4">
          <DxButton
            id="notes"
            icon="ion ion-md-book"
            type="back"
            text="Notas"
            :hint="$t('gestionNoConformidades')"
            @click="onClickNotes()"
          />

          <DxButton
            :disabled="info.ocultos === 0"
            id="restore"
            icon="ion ion-md-undo"
            type="back"
            :text="$t('restaurar')"
            :hint="$t('restaurar')"
            @click="onClickRestore()"
          />

          <DxButton id="export" icon="export" type="back" :hint="$t('exportar')" @click="onClickExport()" />

          <DxButton id="report" icon="export" type="back" :hint="$t('report')" @click="onClickReport()" />
        </div>
      </div>

      <div class="pb-4">
        <Seleccion
          :tipo.sync="selectionTypeSelected"
          :cantidad.sync="cantidad"
          :fechaInicio.sync="fechaInicio"
          :fechaFin.sync="fechaFin"
        >
        </Seleccion>
      </div>
    </div>

    <CaracteristicaViewer
      :plan="info.plan"
      :estructura="info.estructura"
      :caracteristica="info.caracteristica"
      :calibre="info.calibre"
    ></CaracteristicaViewer>

    <div v-if="this.info.caracteristica.id_metodoanalisis === 1">
      <GraficoLimites
        ref="graficoPNP"
        :info="info"
        :datasources="{
          left: info.puntos.p,
          right: info.puntos.np,
        }"
        :titulos="{ left: 'p', right: 'np' }"
        :subtitulos="{
          left: info.caracteristica.nombre,
          right: info.caracteristica.nombre,
        }"
        :ocultos="info.ocultos"
        :totales="info.totales"
        :caracteristicaId="caracteristicaId"
        :caracteristica="info.caracteristica"
        :planID="info.plan.id"
        :analisis="info.analisis"
        @updated="onGraficoLimitesUpdated"
        @restored="onGraficoLimitesUpdated"
        @deleted="onGraficoLimitesUpdated"
      >
      </GraficoLimites>
    </div>

    <!-- caso MDef! -->
    <div v-if="this.info.caracteristica.id_metodoanalisis === 2">
      <DxTabs
        :items="tabs"
        :scroll-by-content="true"
        :show-nav-buttons="true"
        :selected-index.sync="selectedTab"
        item-template="item-template"
      >
        <template #item-template="{ data }">
          {{ $t(data.text) }}
        </template>
      </DxTabs>

      <div ref="graficosTodos">
        <GraficoLimites
          v-if="selectedTab === 0 || selectedTab === 3"
          ref="graficoCU"
          :info="info"
          :datasources="{ left: info.puntos.c, right: info.puntos.u }"
          :titulos="{ left: 'c', right: 'u' }"
          :subtitulos="{
            left: info.caracteristica.nombre,
            right: info.caracteristica.nombre,
          }"
          :ocultos="info.ocultos"
          :totales="info.totales"
          :caracteristicaId="caracteristicaId"
          :caracteristica="info.caracteristica"
          :planID="info.plan.id"
          :analisis="info.analisis"
          @updated="onGraficoLimitesUpdated"
          @restored="onGraficoLimitesUpdated"
          @deleted="onGraficoLimitesUpdated"
        >
        </GraficoLimites>

        <GraficoPareto
          v-if="selectedTab === 1 || selectedTab === 3"
          ref="graficoPareto"
          :defectos="info.puntos.defectos"
          :titulo="info.caracteristica.nombre"
          @defectoSelected="onDefectoSelected"
        >
        </GraficoPareto>

        <GraficoDistribucion
          v-if="selectedTab === 2 || selectedTab === 3"
          ref="graficoDistribucion"
          :defectos="info.puntos.defectos"
          :frecuencias="info.puntos.frecuencias"
          :serieFrecuencias="info.series.frecuencias"
          :titulo="info.caracteristica.nombre"
          @defectoSelected="onDefectoSelected"
        >
        </GraficoDistribucion>
      </div>

      <DxPopup
        :title="$t('graficoPNPDefecto') + defecto.defecto"
        :visible.sync="isPopupVisible"
        :fullScreen="false"
        :animation="{
          show: {
            type: 'pop',
          },
          hide: {
            type: 'pop',
            from: { scale: 1, opacity: 1 },
            to: { scale: 0, opacidy: 0 },
          },
        }"
      >
        <template>
          <DxButton id="report" icon="export" type="back" :hint="$t('report')" @click="onClickReportUnDefecto" />
          <GraficoLimites
            ref="graficoPNPDefecto"
            :info="defecto"
            :datasources="{
              left: defecto.puntos.p,
              right: defecto.puntos.np,
            }"
            :titulos="{ left: 'p', right: 'np' }"
            :subtitulos="{
              left: `${defecto.caracteristica.nombre}: ${defecto.defecto}`,
              right: `${defecto.caracteristica.nombre}: ${defecto.defecto}`,
            }"
            :ocultos="defecto.ocultos"
            :totales="defecto.totales"
            :caracteristicaId="caracteristicaId"
            :analisis="defecto.analisis"
            :caracteristica="defecto.caracteristica"
            :planID="defecto.plan.id"
            @updated="onGraficoLimitesDefectoUpdated"
            @deleted="onGraficoLimitesDefectoUpdated"
            @restored="onGraficoLimitesDefectoUpdated"
          >
          </GraficoLimites>
        </template>
      </DxPopup>
    </div>

    <!--Def -->
    <RPTDef
      v-if="info.caracteristica.id_metodoanalisis === 1 || isPopupVisible"
      :key="caracteristicaId"
      v-show="false"
      :info="info"
      :metodoAnalisis="metodoAnalisis"
      ref="rptDef"
    >
    </RPTDef>

    <!--MDef: Pareto y Distribucion -->
    <RPTMdefPareto
      v-if="
        info.caracteristica.id_metodoanalisis === 2 && (selectedTab === 1 || selectedTab === 2 || selectedTab === 3)
      "
      :key="caracteristicaId"
      v-show="false"
      :info="info"
      :metodoAnalisis="metodoAnalisis"
      ref="rptMDefPareto"
    >
    </RPTMdefPareto>

    <!--MDef: CU -->
    <RPTMdefCU
      v-if="info.caracteristica.id_metodoanalisis === 2 && selectedTab === 0"
      :key="caracteristicaId"
      v-show="false"
      :info="info"
      :metodoAnalisis="metodoAnalisis"
      ref="rptMDefCU"
    >
    </RPTMdefCU>
  </div>
</template>

<script>
import auth from "@/auth";
import { alert } from "devextreme/ui/dialog";

//REPORTS
import RPTDef from "@/components/autocontrol/reports/RPTGraficoDef.vue";
import RPTMdefPareto from "@/components/autocontrol/reports/RPTGraficoMdefPareto.vue";
import RPTMdefCU from "@/components/autocontrol/reports/RPTGraficoMDefCU.vue";

import DxTabs from "devextreme-vue/tabs";
import DxButton from "devextreme-vue/button";
import DxPopup from "devextreme-vue/popup";

import CustomStore from "devextreme/data/custom_store";

import GraficoLimites from "./Atributos/GraficoLimites.vue";
import GraficoPareto from "./Atributos/GraficoPareto.vue";
import GraficoDistribucion from "./Atributos/GraficoDistribucion.vue";

import Seleccion from "./Seleccion.vue";
import CaracteristicaViewer from "../caracteristica-viewer.vue";

import { exportWidgets } from "devextreme/viz/export";

function handleErrors(response) {
  return response;
}

const calibresDataSource = new CustomStore({
  key: "id",
  loadMode: "raw", // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
  cacheRawData: true,
  load: () => {
    return fetch(`${global.API_URL}/instrumentos`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .catch(() => {
        throw "Network error";
      });
  },
  byKey: (id) => {
    if (!id) return;

    return fetch(`${global.API_URL}/instrumentos/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .catch(() => {
        throw "Network error";
      });
  },
});

const metodoAnalisisDataSource = new CustomStore({
  key: "id",
  loadMode: "raw", // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
  cacheRawData: true,
  load: () => {
    return fetch(`${global.API_URL}/caracteristicas/metodoanalisis`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .catch(() => {
        throw "Network error";
      });
  },
  byKey: (id) => {
    if (!id) return;
    return fetch(`${global.API_URL}/caracteristicas/metodoanalisis/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .catch(() => {
        throw "Network error";
      });
  },
});

export default {
  props: ["caracteristicaId"],

  watch: {
    caracteristicaId: {
      immediate: true,
      handler: async function () {
        await this.load();
      },
    },
    selectionTypeSelected: async function () {
      await this.load();
    },
    cantidad: async function () {
      await this.load();
    },
    fechaInicio: async function () {
      await this.load();
    },
    fechaFin: async function () {
      await this.load();
    },
  },

  data() {
    return {
      //Genero el objeto info vacío para no tener errores en el mounted!!
      info: {
        plan: {},
        estructura: {},
        caracteristica: {},
        titulo: {},
        series: {
          frecuencias: [],
        },
        puntos: {
          p: [],
          np: [],
          c: [],
          u: [],
          defectos: [],
          frecuencias: [],
        },
        totales: {},
      },

      defecto: {
        plan: {},
        estructura: {},
        caracteristica: {},
        titulo: {},
        puntos: {
          p: [],
          np: [],
        },
        totales: {},
      },

      // @TODO poner en computed y habilitar la traducción?
      tabs: [{ text: "c&u" }, { text: "pareto" }, { text: "distribucion" }, { text: "todos" }],
      selectedTab: 0,

      selectionTypeSelected: 0, //tipo de seleccion === cantidad
      fechaInicio: null,
      fechaFin: new Date(),
      cantidad: 25,

      calibresDataSource,
      calibre: "",
      metodoAnalisisDataSource,
      metodoAnalisis: "",

      isPopupVisible: false,
      defectoSelected: null,
      dataImagePNP: null,
      dataImagePareto: null,

      showError: false,
      errorMessage: "Error!",
    };
  },

  async mounted() {
    // console.log("%cGrafico atributos mounted!!!", "background:green");
  },

  computed: {
    notas: function () {
      return this.info.caracteristica ? this.info.caracteristica.notas : [];
    },
    images: function () {
      return this.info.caracteristica ? this.info.caracteristica.images : [];
    },
  },
  components: {
    RPTDef,
    RPTMdefPareto,
    RPTMdefCU,
    DxTabs,
    DxPopup,
    DxButton,
    GraficoLimites,
    GraficoPareto,
    GraficoDistribucion,
    Seleccion,
    CaracteristicaViewer,
  },
  methods: {
    refresh() {
      this.load();
    },

    async load() {
      try {
        // montar el querystring:
        // let url = `${global.API_URL}/caracteristicas/${this.caracteristicaId}/analisis/grafico/atr?q=${this.cantidad}`,
        let url = "";
        // mirar el radio button:
        switch (this.selectionTypeSelected) {
          case 0:
            // cantidad
            url = `${global.API_URL}/caracteristicas/${this.caracteristicaId}/analisis/grafico/atr?q=${this.cantidad}`;
            break;
          case 1:
            // fechas
            if (!this.fechaInicio || !this.fechaFin) {
              url = `${global.API_URL}/caracteristicas/${this.caracteristicaId}/analisis/grafico/atr?q=0`;
            } else {
              url = `${global.API_URL}/caracteristicas/${
                this.caracteristicaId
              }/analisis/grafico/atr?s=${this.fechaInicio.getTime()}&e=${this.fechaFin.getTime()}`;
            }

            break;
        }

        const response = await fetch(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.getToken(),
          },
        });
        const data = await response.json();

        if (response.ok) {
          this.info = data;
          this.showError = false;
          this.errorMessage = "";

          // Ver si tenemos criterios:
          // p
          if (this.info.criterios.p.length != 0) {
            let mensaje = this.info.criterios.p
              .map((c) => this.$t(`criteriosATR${c}`))
              .reduce((list, c) => (list += `<li>${c}</li>`), "");
            await alert(`<ul>${mensaje}</ul>`, "Criterios p");
          }
          // np
          if (this.info.criterios.np.length != 0) {
            let mensaje = this.info.criterios.np
              .map((c) => this.$t(`criteriosATR${c}`))
              .reduce((list, c) => (list += `<li>${c}</li>`), "");
            await alert(`<ul>${mensaje}</ul>`, "Criterios np");
          }
        } else {
          this.info = data;
          const mensaje = `${data.error.codigo}`;

          this.showError = true;
          this.errorMessage = this.$t(`errorSPC${mensaje}`).interpolate(data.error.param);
        }
      } catch (ex) {
        this.$notify(this.$t("error_al_cargar_los_datos"), "error", 5000);
      }

      this.calibre = await calibresDataSource.byKey(this.info.caracteristica.id_calibres);

      this.metodoAnalisis = await metodoAnalisisDataSource.byKey(this.info.caracteristica.id_metodoanalisis);
    },

    //async onClickNotes(){
    onClickNotes() {
      // @TODO
      console.error("Not implemented yet");
    },

    async onClickRestore() {
      //utilizo el gráfico C para obtener el primer y último analisis.
      let primer_analisis = this.info.puntos.c[0].id_analisis; //he seleccionado por cantidad
      let ultimo_analisis = this.info.puntos.c[this.info.puntos.c.length - 1].id_analisis;

      let restore = {
        id_inicio: primer_analisis,
        id_fin: ultimo_analisis,
        tipo: this.info.analisis.get(primer_analisis).tipo,
      };

      try {
        const response = await fetch(`${global.API_URL}/caracteristicas/${this.caracteristicaId}/analisis/restore`, {
          method: "put",
          mode: "cors",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.getToken(),
          },
          body: JSON.stringify(restore),
        });
        if (response.ok) {
          this.$notify(this.$t("restaurado_correctamente"));
        } else {
          this.$notify(this.$t("error_al_restaurar"), "error", 5000);
        }
      } catch (ex) {
        this.$notify(this.$t("error_al_restaurar"), "error", 5000);
      }

      await this.load();
    },

    async onClickExport() {
      let graficoPInstance, graficoNPInstance;
      let graficoCInstance, graficoUInstance;
      let graficoParetoInstance, graficoPieInstance;
      let graficoDistribucionInstance, graficoFrecuenciasInstance;

      switch (this.info.caracteristica.id_metodoanalisis) {
        case 1:
          // Def
          // graficos P NP
          graficoPInstance = this.$refs.graficoPNP.$refs.graficoLeft.instance;
          graficoNPInstance = this.$refs.graficoPNP.$refs.graficoRight.instance;

          exportWidgets([[graficoPInstance, graficoNPInstance]], {
            fileName: `grafico_${this.info.plan.referencia}_${this.info.caracteristica.nombre}`,
            format: "PNG",
          });
          break;

        case 2:
          // MDef
          // graficos C U Pareto
          graficoCInstance = this.$refs.graficoCU.$refs.graficoLeft.instance;
          graficoUInstance = this.$refs.graficoCU.$refs.graficoRight.instance;
          graficoParetoInstance = this.$refs.graficoPareto.$refs.graficoPareto.instance;
          graficoPieInstance = this.$refs.graficoPareto.$refs.graficoPie.instance;
          graficoDistribucionInstance = this.$refs.graficoDistribucion.$refs.graficoDistribucion.instance;
          graficoFrecuenciasInstance = this.$refs.graficoDistribucion.$refs.graficoFrecuencias.instance;

          exportWidgets(
            [
              [graficoCInstance, graficoUInstance],
              [graficoParetoInstance, graficoPieInstance],
              [graficoDistribucionInstance, graficoFrecuenciasInstance],
            ],
            {
              fileName: `grafico_${this.info.plan.referencia}_${this.info.caracteristica.nombre}`,
              format: "PNG",
            }
          );
          break;

        default:
          break;
      }
    },

    async onClickReportUnDefecto() {
      this.$refs.rptDef.print(this.$refs.graficoPNPDefecto.$el);
    },

    async onClickReport() {
      switch (this.info.caracteristica.id_metodoanalisis) {
        case 1: //Def
          // canvas = await html2canvas(document.querySelector("#canvasPNP"));
          // this.dataImagePNP = canvas.toDataURL();
          this.$refs.rptDef.print(this.$refs.graficoPNP.$el);
          break;

        case 2: //Mdef
          // c/u, Pareto, Distribucion, Todos
          //no se como haríamos en el caso de p/np para 1 defecto en particular
          switch (this.selectedTab) {
            case 0: //c-u
              this.$refs.rptMDefCU.print(this.$refs.graficoCU.$el);
              break;

            case 1: //Pareto
              this.$refs.rptMDefPareto.print(this.$refs.graficoPareto.$el);
              break;

            case 2: //Distribucion
              this.$refs.rptMDefPareto.print(this.$refs.graficoDistribucion.$el);
              break;

            case 3: //Todos
              this.$refs.rptMDefPareto.print(this.$refs.graficosTodos);
              break;
          }
      }
    },

    async loadDefecto(idDefecto = this.defectoSelected) {
      try {
        //montar el querystring:
        // mirar el radio button:
        // -por cantidad
        // -por fechas

        const response = await fetch(
          `${global.API_URL}/caracteristicas/${this.caracteristicaId}/analisis/grafico/atr/${idDefecto}?q=${this.cantidad}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + auth.getToken(),
            },
          }
        );

        const data = await response.json();

        if (response.ok) {
          this.defecto = data;
        } else {
          console.error("GraficoAtributos::loadDefecto", response);
          this.$notify(this.$t("error"), "error", 5000);
          this.defecto = data;
        }
      } catch (ex) {
        this.$notify(this.$t("error"), "error", 5000);
        console.error("GraficoAtributos::loadDefecto", ex);
      }
    },

    async onGraficoLimitesUpdated() {
      //recargar todo!
      this.load();
    },

    async onGraficoLimitesDefectoUpdated() {
      // recargar todo!
      this.load();
      this.loadDefecto();
    },

    async onDefectoSelected(e) {
      this.defectoSelected = e;
      this.isPopupVisible = true;
      this.loadDefecto();
    },
  },
};
</script>

<style scoped>
.botones {
  position: fixed;
  z-index: 9000;
  bottom: 50px;
  right: 50px;
}

.padding {
  height: 100px;
}

.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  margin-top: 20px;
}

.option {
  margin-top: 10px;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option > span {
  margin-right: 14px;
}

.option > .dx-widget {
  display: inline-block;
  vertical-align: middle;
}

#graficoX,
#graficoR {
  height: 500px;
  width: 100%;
}

.dxc-tooltip {
  z-index: 2000;
}

.row.plan-control {
  margin: 10px;
}

.row.caracteristica {
  margin: 10px;
}

.grafico-error {
  --bs-red: #f44336;
  background-color: var(--bs-red);
  z-index: 1;
  box-shadow: 0 0 10px -5px black;
}
</style>
