<template>
  <div :key="componentKey">
    <DxButton icon="like" @click="isPopupVisible = !isPopupVisible"></DxButton>
    <DxPopup
      v-if="isPopupVisible"
      ref="popup"
      title="Layouts"
      class="popupScrollableContentWithoutPadding"
      :visible.sync="isPopupVisible"
      :fullScreen="false"
      width="666"
    >
      <template>
        <div class="h-100 position-relative">
          <p>
            {{ $t("guardar_el_estado_de_la_grid") }}
          </p>
          <p>
            {{ $t("haz_doble_click_para_aplicar") }} o haz click
            <a class="text-decoration-underline" role="button" @click="onClickReset">aquí</a> para para volver a su
            estado original.
          </p>

          <!-- <DxButton @click="onClickReset" type="back" :text="$t('reset')"> </DxButton> -->

          <DxDataGrid
            ref="dataGrid"
            :data-source="userlayouts"
            :hover-state-enabled="true"
            height="100%"
            width="100%"
            @row-click="onRowClick"
            @row-dbl-click="onRowDblClick"
            @selection-changed="onSelectionChanged"
          >
            <DxEditing :allow-updating="true" :allow-adding="true" :allow-deleting="true" mode="row"> </DxEditing>
            <!-- <DxSelection mode="single" /> -->
            <!-- <DxPaging :enabled="true" :page-size="10" /> -->
            <DxFilterRow :visible="true" />
            <!-- <DxScrolling mode="virtual" /> -->

            <DxColumn data-field="nombre" :caption="$t('nombre')" width="200" :allowEditing="true" />
            <DxColumn data-field="descripcion" :caption="$t('descripcion')" width="200" :allowEditing="true" />

            <DxColumn data-field="es_publica" :caption="$t('es_publica')" width="100" :allowEditing="true" />
          </DxDataGrid>
        </div>
      </template>
    </DxPopup>
  </div>
</template>

<script>
import { DxButton } from "devextreme-vue/button";

// import {
//   DxSelectBox,
//   DxButton as DxSelectBoxButton,
// } from "devextreme-vue/select-box";

import { DxPopup } from "devextreme-vue/popup";

import {
  // DxSelection,
  DxFilterRow,
  DxDataGrid,
  DxEditing,
  DxColumn,
} from "devextreme-vue/data-grid";

// import DxList from 'devextreme-vue/list';

import CustomStore from "devextreme/data/custom_store";

export default {
  props: ["gridRef", "gridName"],

  data() {
    return {
      componentKey: 0,

      // isListVisible: false,
      isPopupVisible: false,

      catalogButtonOptions: {
        elementAttr: {
          class: "catalogButton",
        },
        icon: "tableproperties",
        onClick: () => {
          this.isPopupVisible = true;
        },
      },

      // somethingHasBeenUpdated: false,

      // layouts: new CustomStore({
      //   key: "id",
      //   load: () => this.$fetch.get(`${global.API_URL}/layouts/grid/${this.gridName}`),
      // }),

      userlayouts: new CustomStore({
        key: "id",
        load: () => this.$fetch.get(`${global.API_URL}/layouts/usergrid/${this.gridName}`),
        byKey: (key) => this.$fetch.get(`${global.API_URL}/layouts/${key}`),
        insert: (values) => {
          // this.somethingHasBeenUpdated = true;
          values.grid = this.gridName;
          values.tipo = "U";
          //Save state actual de la grid
          values.layout = JSON.stringify(this.gridRef.instance.state());
          return this.$fetch.post(`${global.API_URL}/layouts`, values);
        },
        update: (key, values) => {
          // this.somethingHasBeenUpdated = true;

          values.layout = JSON.stringify(this.gridRef.instance.state());
          return this.$fetch.put(`${global.API_URL}/layouts/${key}`, values);
        },
        remove: (key) => {
          // this.somethingHasBeenUpdated = true;
          return this.$fetch.delete(`${global.API_URL}/layouts/${key}`);
        },
      }),
    };
  },

  components: {
    // DxSelectBox,
    // DxSelectBoxButton,
    DxPopup,
    // DxSelection,
    DxFilterRow,
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxButton,
    // DxList,
  },

  computed: {
    idioma: function () {
      return this.$i18n.locale;
    },
  },

  watch: {
    idioma() {
      this.componentKey++;
    },
  },

  async mounted() {
    this.selectedRowKeys = this.value && [this.value];
    this.$refs.dataGrid?.instance?.navigateToRow(this.value);
  },

  methods: {
    // async onHidden() {
    //   if (this.somethingHasBeenUpdated) {
    //     // this.layouts.load();
    //     this.somethingHasBeenUpdated = false;
    //   }
    // },

    onRowClick() {},

    onRowDblClick(e) {
      // console.log(e);
      // console.log(this.gridRef.option);
      //console.log(this.gridRef.instance.option().stateStoring.storageKey);
      // this.gridRef.instance.state(JSON.parse(e.data.layout));

      localStorage.setItem(this.gridRef.instance.option().stateStoring.storageKey, e.data.layout);
      this.gridRef.instance.state(JSON.parse(e.data.layout));
      this.isPopupVisible = false;
    },

    onSelectionChanged() {},

    onClickReset() {
      this.gridRef.instance.state(null);
      this.isPopupVisible = false;
    },
  },
};
</script>

<style></style>
