<template>
  <div class="d-flex flex-column display" :class="{ 'w-100': full }">
    <div class="form-data" ref="data" :class="valueStyle">
      <div class="form-label">
        <label ref="label"> {{ label }}<span v-if="required">*</span></label>
      </div>
      <div class="form-value">
        <slot>
          <span v-html="value"></span>
        </slot>
      </div>
    </div>

    <slot name="extra">
      <span class="extra fst-italic ps-2 pt-1 text-secondary">
        {{ subValue }}
      </span>
    </slot>
  </div>
</template>

<script>
export default {
  props: ["label", "value", "required", "subValue", "full", "valueStyle"],
};
</script>

<style scoped>
.display {
  --border: 1px solid rgba(0, 0, 0, 0.4);
  /* --color: rgb(255, 255, 255); */
  --color: var(--form-bg-color);
  --bg-color: rgba(0, 0, 0, 0.04);
}
.dx-swatch-additional .display {
  --border: 1px solid rgba(255, 255, 255, 0.4);
  /* --color: #363640; */
  --color: var(--form-bg-color);
  --bg-color: rgba(0, 0, 0, 0.1);
}

.form-data {
  position: relative;
  min-width: 69px;
  background-color: var(--bg-color);
  min-height: 35px;
  margin-top: 5px;
  height: auto;
  border: var(--border);
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  padding: 0px;
}

.form-label {
  border-bottom: none;
  font-size: 0.7rem;
  /* transform: translate(0.7rem, 0.7rem); */
  padding: 0 3em 0 1em;
  /* background-color: rgba(0 0 0 / 0.1); */
  border-radius: 3px;
  white-space: nowrap;
  position: relative;
  top: -7px;
  min-width: 0px;
  display: inline;
  font-weight: normal;
}

label {
  padding: 0 0.5em;

  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 30%,
    var(--color) 31%,
    var(--color) 49%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0) 100%
  );
}

.dx-swatch-additional label {
  color: rgb(170, 170, 170);
}

.form-value {
  padding: 0 10px 6px 10px;
  margin-top: -8px;
}

.extra {
  font-size: 0.7rem;
}
</style>
