<template>
  <div>
    <div class="content-block d-flex gap-3">
      <DxResizable
        v-show="showPlan"
        handles="right"
        :element-attr="{
          class: 'left-tree flex-shrink-0',
        }"
      >
        <template>
          <h2>{{ $t("planControl") }}</h2>
          <DxSelectBox
            id="selectPlanControl"
            :dataSource="planesDataSource"
            :searchEnabled="true"
            searchMode="contains"
            :searchExpr="['referencia', 'nombre']"
            displayExpr="referencia"
            valueExpr="id"
            v-model="selectedPlanID"
          >
            <template #item="{ data }">
              <div>
                {{ data.referencia }}<br />
                <small class="text-small">{{ data.nombre }}</small>
              </div>
            </template>
          </DxSelectBox>

          <h2 class="mt-4">{{ $t("estructura") }}</h2>

          <Estructura
            id="myComponentEstructura"
            :selectedPlanID="selectedPlanID"
            :selectedRowKeys.sync="selectedRowKeys"
          />

          <div class="debug" v-if="$store.state.debug">selectedRowKeys: {{ selectedRowKeys }}</div>
        </template>
      </DxResizable>

      <div class="w-100">
        <h2>
          <DxButton :visible="showPlan" icon="hidepanel" stylingMode="text" @click="() => (showPlan = !showPlan)">
          </DxButton>
          <DxButton :visible="!showPlan" icon="showpanel" stylingMode="text" @click="() => (showPlan = !showPlan)">
          </DxButton>
          {{ $t("pautaControl") }}
        </h2>
        <div>
          <!-- Caracteristicas devuelve una caracteristica en caractersitica sync! -->
          <!-- {{
              caracteristica
                ? caracteristica.id
                : %t('noHayningunaCaracteristicaSeleccionada') 
            }} -->
          <Caracteristicas
            ref="caracteristicas"
            id="myComponentCaracteristica"
            :estructuraIds="selectedRowKeys"
            :selectedRow.sync="caracteristica"
            @rowDblClick="showPopup()"
          ></Caracteristicas>
        </div>

        <div v-if="caracteristica">
          <!-- @TODO v-if por metodoanalisis -->
          <!-- <h2>Grafico por variables!</h2> -->
          <GraficoVariables
            :key="caracteristicaID"
            v-if="showGraficoVariables"
            ref="graficoVariables"
            :caracteristicaId="caracteristicaID"
            @procesoNoCapaz="procesoNoCapazHandler"
            @procesoFueraControl="procesoFueraControlHandler"
          ></GraficoVariables>

          <GraficoAtributos
            :key="caracteristicaID"
            v-if="showGraficoAtributos"
            ref="graficoAtributos"
            :caracteristicaId="caracteristicaID"
          ></GraficoAtributos>
        </div>
      </div>
    </div>
    <!-- <DxButton
      :visible="!(caracteristica === undefined || caracteristica === null)"
      text="lorem"
    /> -->

    <!-- plus -->
    <!-- icon ion-md-create -->
    <!-- icon ion-md-add -->
    <DxSpeedDialAction :visible="!!caracteristica" hint="New" icon="plus" @click="showPopup()" />

    <DxPopup
      class="popupAnalisis popupScrollableContentWithoutPadding"
      :title="tituloPopup"
      :visible.sync="isPopupVisible"
      :fullScreen="false"
    >
      <template>
        <DxScrollView width="100%" height="100%" :useNative="false">
          <div>
            <Analisis
              :lote.sync="lote"
              :secuencia.sync="secuencia"
              :turno.sync="turno"
              :operario.sync="operario"
              :key="caracteristica ? caracteristica.id : null"
              ref="analisis"
              v-if="isPopupVisible"
              tipo="A"
              :caracteristica="caracteristica"
              :planID="selectedPlanID"
              @inserted="analisisInserted()"
              @updated="analisisUpdated()"
              @error="analisisError()"
              @cancelled="analisisCancelled()"
              @analisisRequerido="onAnalisisRequerido"
            ></Analisis>
          </div>
        </DxScrollView>

        <DxToolbarItem
          v-if="showCancelar"
          :options="{
            text: $t('cancelar'),
            type: 'normal',
            onClick: () => {
              this.$refs.analisis.cancel();
            },
          }"
          widget="dxButton"
          location="after"
          toolbar="bottom"
        />

        <DxToolbarItem
          v-if="showGuardar"
          :options="{
            text: $t('guardarAnalisis'),
            stylingMode: 'contained',
            //useSubmitBehavior: true,
            //icon: 'save',
            type: 'default',
            //onClick: () => {
            //  this.$refs.analisis.insert();
            //},
            onClick: (e) => {
              e.validationGroup.validate().isValid && this.$refs.analisis.insert();
            },
          }"
          widget="dxButton"
          location="after"
          toolbar="bottom"
        />

        <DxToolbarItem
          v-if="showSalir"
          :options="{
            text: $t('salir'),
            stylingMode: 'contained',
            //useSubmitBehavior: true,
            //icon: 'save',
            type: 'default',
            //onClick: () => {
            //  this.$refs.analisis.insert();
            //},
            onClick: () => {
              this.$refs.analisis.cancel();
            },
          }"
          widget="dxButton"
          location="after"
          toolbar="bottom"
        />
      </template>
    </DxPopup>

    <!-- <div class="container-fluid mt-5">
      <div class="row">
        <div class="col-1 offset-11">
          <DxCheckBox text="DEBUG" v-model="debug" />
        </div>

        <div class="col-5">
          <DxButton text="Error" @click="onClickTest" :visible="debug" />
          <DxButton
            text="Success"
            @click="$showNotification($t('exitoClicBoton'))"
            :visible="debug" />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
//@TODO: https://js.devexpress.com/Documentation/Guide/Data_Binding/Data_Source_Examples/#Custom_Sources/Connect_to_RESTful_Service
//@TODO: Consultar ¿OData? https://github.com/DevExpress-Examples/XPO_how-to-implement-odata4-service-with-xpo
//import "devextreme/data/odata/store";

import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

import DxResizable from "devextreme-vue/resizable";
import DxSelectBox from "devextreme-vue/select-box";
import DxButton from "devextreme-vue/button";
import DxSpeedDialAction from "devextreme-vue/speed-dial-action";
import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";
import DxScrollView from "devextreme-vue/scroll-view";

import Estructura from "../components/autocontrol/Estructura";
import Caracteristicas from "../components/autocontrol/Caracteristicas";
import Analisis from "../components/autocontrol/Analisis";

import GraficoVariables from "../components/graficos/GraficoVariables";
import GraficoAtributos from "../components/graficos/GraficoAtributos";

import config from "devextreme/core/config";

config({
  floatingActionButtonConfig: {
    position: {
      my: "right bottom",
      at: "right bottom",
      of: "#app-container",
      offset: "-24 -24",
    },
  },
});

import auth from "../auth";

// import Devexpress from "devextreme";
// function handleErrors(response) {
//   if (!response.ok) {
//     throw Error(response.statusText);
//   }
//   return response;
// }

/**
 * Returns the object found by ID
 * @memberof Array
 * @param {int} id
 */
Array.prototype.get = function (id) {
  return this.find((e) => e.id === id);
};

String.prototype.interpolate = function (params) {
  const names = Object.keys(params);
  const vals = Object.values(params);
  return new Function(...names, `return \`${this}\`;`)(...vals);
};

// const planesCustomStore = new CustomStore({
//   key: "id",
//   loadMode: "raw",
//   cacheRawData: true,
//   load: () => {
//     return fetch(`${global.API_URL}/planes/activos`, {
//       headers: {
//         "Content-Type": "application/json",
//         "Authorization": "Bearer " + auth.getToken(),
//       },
//     })
//       .then(handleErrors)
//       .then((response) => {
//         // console.log("loaded!");
//         return response.json();
//       })
//       .catch(() => {
//         throw "Network error";
//       });
//   },
// });

// const planesDataSource = new DataSource({
//   paginate: true,
//   store: planesCustomStore,
//   searchExpr: function (dataItem) {
//     return dataItem.nombre + " " + dataItem.referencia;
//   },
// });

// let planesCustomStore;
// let planesDataSource;

export default {
  data() {
    return {
      // planesDataSource:
      //   new DataSource({
      //     paginate: true,
      //     store: new CustomStore({
      //       key: "id",
      //       loadMode: "raw",
      //       cacheRawData: true,
      //       load: () => this.$fetch.get(`${global.API_URL}//planes/activos`),
      //     }),
      //     searchExpr: function (dataItem) {
      //       console.log(dataItem)
      //       return dataItem.nombre + " " + dataItem.referencia;
      //     },
      //   }),

      planesDataSource: new DataSource({
        paginate: true,
        store: new CustomStore({
          key: "id",
          loadMode: "raw",
          cacheRawData: true,
          load: () => this.$fetch.get(`${global.API_URL}/planes/activos`),
        }),
      }),

      selectedRowKeys: [],
      selectedPlanID: null,
      caracteristica: null,

      titlePopup: "",
      isPopupVisible: false,

      lote: "",
      secuencia: "",
      turno: "",
      operario: auth.user.nombre,

      showPlan: true,

      // popup buttons
      showSalir: false,
      showGuardar: true,
      showCancelar: true,
    };
  },
  components: {
    DxResizable,
    DxSelectBox,
    DxButton,
    DxSpeedDialAction,
    DxPopup,
    DxToolbarItem,
    DxScrollView,
    Estructura,
    Caracteristicas,
    Analisis,
    GraficoVariables,
    GraficoAtributos,
  },
  async mounted() {},

  computed: {
    tituloPopup: function () {
      let metodo = "";
      switch (this.caracteristica?.id_metodoanalisis) {
        case 1:
          metodo = "Def";
          break;
        case 2:
          metodo = "MDef";
          break;
        case 3:
          metodo = "X/R";
          break;
        case 4:
          metodo = "X̅/R";
          break;
        case 5:
          metodo = "MinMax";
          break;
      }

      return `${this.$t("analisis")} ${metodo} ${this.$t("de")}: ${this.caracteristica?.nombre}`;
    },
    showGraficoVariables: function () {
      return [3, 4, 5].includes(this.caracteristica.id_metodoanalisis);
    },
    showGraficoAtributos: function () {
      return [1, 2].includes(this.caracteristica.id_metodoanalisis);
    },
    caracteristicaID: function () {
      return this.caracteristica ? this.caracteristica.id : null;
    },
  },

  methods: {
    onAnalisisRequerido(analisisRequerido) {
      this.showCancelar = analisisRequerido;
      this.showGuardar = analisisRequerido;
      this.showSalir = !analisisRequerido;
    },

    procesoNoCapazHandler(e) {
      console.log("el proceso no es capaz", e);
    },
    procesoFueraControlHandler(e) {
      console.log("el proceso está fuera de control", e);
    },
    showPopup() {
      //console.log("showPopup!!!!", this.$refs["analisis"]);
      this.isPopupVisible = true;
      // this.$refs["analisis"].clear();
    },
    closePopup() {
      this.isPopupVisible = false;
      this.showGuardar = true;
      this.showCancelar = true;
      this.showSalir = false;
    },
    analisisCancelled() {
      this.closePopup();
    },
    analisisInserted() {
      this.closePopup();

      this.$refs["caracteristicas"].refresh();
      this.$refs.graficoVariables && this.$refs["graficoVariables"].refresh();
      this.$refs.graficoAtributos && this.$refs["graficoAtributos"].refresh();

      this.$notify(this.$t("analisis_guardado_correctamente"));
    },

    analisisUpdated() {
      this.closePopup();

      this.$refs["caracteristicas"].refresh();
      this.$refs.graficoVariables && this.$refs["graficoVariables"].refresh();
      this.$refs.graficoAtributos && this.$refs["graficoAtributos"].refresh();

      this.$notify(this.$t("analisis_actualizado_correctamente"));
    },

    analisisError() {
      //this.closePopup();
      this.$notify(this.$t("error_al_guardar_analisis"), "error", 2000);
    },

    onClickTest() {
      this.$notify(this.$t("errorClicBoton"), "error", 1000);
    },
  },

  // created() {
  //   this.operario = auth.user.nombre;
  // },

  beforeCreate() {
    // planesCustomStore = new CustomStore({
    //   key: "id",
    //   loadMode: "raw",
    //   cacheRawData: true,
    //   load: () => {
    //     return fetch(`${global.API_URL}/planes/activos`, {
    //       headers: {
    //         "Content-Type": "application/json",
    //         "Authorization": "Bearer " + auth.getToken(),
    //       },
    //     })
    //       .then(handleErrors)
    //       .then((response) => {
    //         // console.log("loaded!");
    //         return response.json();
    //       })
    //       .catch(() => {
    //         throw "Network error";
    //       });
    //   },
    // });

    // planesDataSource = new DataSource({
    //   paginate: true,
    //   store: planesCustomStore,
    //   searchExpr: function (dataItem) {
    //     console.log(dataItem)
    //     return dataItem.nombre + " " + dataItem.referencia;
    //   },
    // });

    // //ponemos el usuario en el campo operario
    // // auth.getUser().then((e) => {
    // //   this.operario = e.data.nombre;
    // // });

    // console.log(auth);

    this.operario = auth.user.nombre;
  },
};
</script>

<style scoped>
.dx-fa-button-icon {
  text-align: center;
}

p {
  text-align: center;
}

#app-container {
  height: 360px;
  width: 320px;
  border: 1px solid black;
}

.left-tree {
  width: 400px;
}
</style>
