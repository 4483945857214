<template>
  <div id="grafico" class="position-relative">
    <div
      v-if="showError"
      class="grafico-error p-3 rounded position-absolute top-50 start-50 translate-middle text-white"
    >
      {{ errorMessage }}
    </div>

    <div class="d-flex flex-column flex-xl-row justify-content-between">
      <div class="d-flex flex-row align-items-center">
        <div class="title">
          <h2>
            {{ $t("graficoVariables") }}:
            <b>{{ metodoAnalisis.codigo }}</b>
          </h2>
        </div>
        <div class="buttons pl-4">
          <DxButton
            id="notes"
            icon="ion ion-md-book"
            :element-attr="procesoFueraControl ? { class: 'danger' } : {}"
            type="back"
            :hint="$t('gestionNoConformidades')"
            @click="onClickNotes()"
          />

          <DxButton
            :disabled="info.ocultos === 0"
            id="restore"
            icon="ion ion-md-undo"
            type="back"
            :text="$t('restaurar')"
            :hint="$t('restaurar')"
            @click="onClickRestore()"
          />

          <DxButton id="export" icon="export" type="back" :hint="$t('exportar')" @click="onClickExport()" />

          <DxButton id="report" icon="export" type="back" :hint="$t('report')" @click="onClickReport()" />
        </div>
      </div>

      <div class="pb-4">
        <Seleccion
          :tipo.sync="selectionTypeSelected"
          :cantidad.sync="cantidad"
          :fechaInicio.sync="fechaInicio"
          :fechaFin.sync="fechaFin"
        >
        </Seleccion>
      </div>
    </div>
    <div class="danger-zone d-flex" style="gap: 1em">
      <DxButton
        class="mb-3"
        v-if="procesoFueraControl"
        type="danger"
        stylingMode="contained"
        :text="$t('procesoFueraControl')"
        @click="onClickProcesoFueraControl()"
      />

      <DxButton
        class="mb-3"
        v-if="!isConsigna && procesoNoCapaz"
        type="danger"
        stylingMode="contained"
        :text="$t('procesoNoCapaz')"
        @click="onClickProcesoNoCapaz()"
      />
    </div>

    <!-- <div class="row plan-control">
        <div class="col">
          <label class="form-label">Referencia</label>
          <div class="form-data">{{ info.plan.referencia }}</div>
        </div>
        <div class="col">
          <label class="form-label">Pieza</label>
          <div class="form-data">{{ info.plan.nombre }}</div>
        </div>
        <div class="col">
          <label class="form-label">Fase</label>
          <div class="form-data">
            {{ info.estructura.descripcion }}
          </div>
        </div>
      </div> -->

    <CaracteristicaViewer
      :plan="info.plan"
      :estructura="info.estructura"
      :caracteristica="info.caracteristica"
      :calibre="info.calibre"
    ></CaracteristicaViewer>

    <!-- <div class="d-flex flex-row pt-2 pb-4" style="gap: 50px">
      <div class="d-flex flex-column plan-control">
        <label class="form-label"
          >{{ $t("referencia") }} / {{ $t("pieza") }} / {{ $t("fase") }}</label
        >
        <div class="form-data">
          {{ info.plan.referencia }} / {{ info.plan.nombre }} /
          {{ info.estructura.descripcion }}
        </div>
      </div>

      <div class="d-flex flex-column caracteristica">
        <label class="form-label"
          >{{ $t("numero") }} / {{ $t("nombre") }} /
          {{ $t("especificacion") }} / {{ $t("calibre") }}</label
        >

        <div class="form-data">
          {{ info.caracteristica.id }} / {{ info.caracteristica.nombre }} /
          <div
            style="display: inline-block"
            v-html="info.caracteristica.especificacion"
          ></div>
          / {{ calibre ? calibre.descripcion : "" }}
        </div>
      </div>
    </div> -->

    <div ref="graficoXR" class="row">
      <div class="col-xl-6">
        <DxChart
          id="graficoX"
          ref="graficoX"
          :data-source="datasourceX"
          @legend-click="onLegendClick"
          @point-click="onPointClick"
          @initialized="(e) => {}"
          @done="(e) => {}"
        >
          <DxTitle
            :text="info.titulo.x"
            :subtitle="{
              text: info.caracteristica.nombre,
              font: {
                size: '2em',
              },
            }"
          >
            <!-- <DxSubtitle :text="caracteristica.nombre" /> -->
          </DxTitle>

          <DxCommonSeriesSettings type="line" argument-field="x" />
          <DxCommonAxisSettings>
            <DxGrid :visible="false" />
          </DxCommonAxisSettings>

          <DxSeries
            v-for="s in series"
            :key="s.value + 'x'"
            :value-field="s.value + 'x'"
            :name="s.name"
            :color="s.color"
            :width="s.width"
            :dash-style="s.dashStyle"
          >
            <DxPoint :visible="false" />
          </DxSeries>

          <DxSeries
            v-for="s in seriesTolerancia"
            :key="s.value"
            :value-field="s.value"
            :name="s.name"
            :color="s.color"
            :width="s.width"
            :dash-style="s.dashStyle"
          >
            <DxPoint :visible="false" />
          </DxSeries>

          <DxSeries key="value" value-field="value" :name="info.titulo.x" color="black">
            <DxPoint symbol="square" />
          </DxSeries>

          <DxMargin :bottom="20" />
          <DxArgumentAxis :allow-decimals="false" :axis-division-factor="60">
            <DxLabel>
              <DxFormat type="decimal" />
            </DxLabel>
          </DxArgumentAxis>
          <DxLegend vertical-alignment="bottom" horizontal-alignment="center" />
          <DxExport :enabled="false" />
          <DxTooltip :enabled="true" />
        </DxChart>

        <DxPopup
          class="popupScrollableContentWithoutPadding"
          :visible.sync="popupVisible"
          :drag-enabled="false"
          :close-on-outside-click="true"
          :show-title="true"
          :width="350"
          :height="500"
          :shading="true"
          :title="valor ? valor.toString() : ''"
        >
          <DxPosition my="center" at="left top" of="window" collision="fit fit" boundaryOffset="0 0">
            <DxOffset :x="popupOffsetX" :y="popupOffsetY"></DxOffset>
          </DxPosition>

          <DxScrollView>
            <div class="d-flex flex-column caracteristica py-2 px-4">
              <div>
                <label class="form-label">{{ $t("fechaAnalisis") }}</label>
                <div class="form-data">
                  {{ fecha ? this.$moment(fecha).format("L") : "" }}
                  {{ $t("aLas") }}
                  {{ fecha ? this.$moment(fecha).format("LTS") : "" }}
                </div>
              </div>
              <div>
                <label class="form-label">{{ $t("valor") }}</label>
                <div class="form-data">{{ valor }}</div>
              </div>

              <div v-if="info.caracteristica.id_metodoanalisis === 4">
                <label class="form-label">{{ $t("valores") }}</label>
                <div class="form-data">
                  <h5>
                    <span
                      v-for="(valor, index) in analisisSeleccionado ? analisisSeleccionado._valores : []"
                      :key="index"
                      class="badge text-bg-info mx-1"
                    >
                      {{ valor }}
                    </span>
                  </h5>
                </div>
              </div>

              <div v-if="info.caracteristica.id_metodoanalisis === 4 || info.caracteristica.id_metodoanalisis === 5">
                <div>
                  <label class="form-label">{{ $t("minimo") }}</label>
                  <div class="form-data">{{ minimo }}</div>
                </div>

                <div>
                  <label class="form-label">{{ $t("maximo") }}</label>
                  <div class="form-data">
                    {{ maximo }}
                  </div>
                </div>
                <div>
                  <label class="form-label">{{ $t("recorridoMovil") }}</label>
                  <div class="form-data">
                    {{ recorridoMovil }}
                  </div>
                </div>
              </div>
            </div>
          </DxScrollView>

          <!-- <div
            id="popupButtonsContainer"
            class="pt-3 d-flex justify-content-end"
          >
            <DxButton
              class="mr-auto"
              :disabled="puntoSeleccionado === null"
              id="edit"
              icon="edit"
              type="normal"
              stylingMode="text"
              @click="onClickEdit()"
            />

            <DxButton
              :disabled="puntoSeleccionado === null"
              id="hide"
              icon="ion ion-md-eye-off"
              :hint="$t('ocultar')"
              type="normal"
              stylingMode="text"
              @click="onClickHide()"
            />

            <DxButton
              :disabled="puntoSeleccionado === null"
              id="delete"
              icon="ion ion-md-trash"
              :hint="$t('eliminar')"
              type="danger"
              @click="onClickDelete()"
            />
          </div> -->

          <DxToolbarItem
            :options="{
              text: $t('editar'),
              type: 'normal',
              onClick: onClickEdit,
            }"
            widget="dxButton"
            location="before"
            toolbar="bottom"
          />

          <DxToolbarItem
            :options="{
              text: $t('ocultar'),
              type: 'normal',
              onClick: onClickHide,
            }"
            widget="dxButton"
            location="after"
            toolbar="bottom"
          />

          <DxToolbarItem
            :options="{
              text: $t('eliminar'),
              type: 'danger',
              stylingMode: 'contained',
              onClick: onClickDelete,
            }"
            widget="dxButton"
            location="after"
            toolbar="bottom"
          />
        </DxPopup>

        <DxPopup
          class="popupAnalisis popupScrollableContentWithoutPadding"
          title="Edición del punto"
          :visible.sync="popupEditVisible"
          :fullScreen="false"
        >
          <template>
            <DxScrollView width="100%" height="100%" :useNative="false">
              <div>
                <Analisis
                  ref="analisis"
                  v-if="popupEditVisible"
                  :analisis="puntoSeleccionado.data.analisis"
                  :caracteristica="info.caracteristica"
                  :planID="info.plan.id"
                  @updated="analisisUpdated()"
                  @error="analisisError()"
                  @cancelled="analisisCancelled()"
                ></Analisis>
              </div>
            </DxScrollView>

            <DxToolbarItem
              :options="{
                text: $t('cancelar'),
                type: 'normal',
                onClick: () => {
                  this.$refs.analisis.cancel();
                },
              }"
              widget="dxButton"
              location="after"
              toolbar="bottom"
            />

            <DxToolbarItem
              :options="{
                text: $t('actualizarAnalisis'),
                stylingMode: 'contained',
                icon: 'save',
                type: 'default',
                onClick: (e) => {
                  e.validationGroup.validate().isValid && this.$refs.analisis.update();
                },
              }"
              widget="dxButton"
              location="after"
              toolbar="bottom"
            />
          </template>
        </DxPopup>
      </div>

      <div class="col-xl-6">
        <DxChart id="graficoR" ref="graficoR" :data-source="datasourceR" @legend-click="onLegendClick">
          <DxTitle
            :text="info.titulo.r"
            :subtitle="{
              text: info.caracteristica.nombre,
              font: {
                size: '2em',
              },
            }"
          >
            <!-- <DxSubtitle :text="info.caracteristica.nombre"/> -->
          </DxTitle>

          <DxCommonSeriesSettings type="line" argument-field="x" />
          <DxCommonAxisSettings>
            <DxGrid :visible="false" />
          </DxCommonAxisSettings>

          <DxSeries
            v-for="s in series"
            :key="s.value + 'r'"
            :value-field="s.value + 'r'"
            :name="s.name"
            :color="s.color"
            :width="s.width"
            :dash-style="s.dashStyle"
          >
            <DxPoint :visible="false" />
          </DxSeries>

          <DxSeries key="value" value-field="value" name="R" color="black">
            <DxPoint symbol="triangleDown" />
          </DxSeries>

          <DxMargin :bottom="20" />
          <DxArgumentAxis :allow-decimals="false" :axis-division-factor="60">
            <DxLabel>
              <DxFormat type="decimal" />
            </DxLabel>
          </DxArgumentAxis>
          <DxLegend vertical-alignment="bottom" horizontal-alignment="center" />
          <DxExport :enabled="false" />
          <DxTooltip :enabled="true" />
        </DxChart>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <table width="50%" class="tabla-limites table table-bordered table-sm">
          <thead>
            <th>{{ $t("limitesControlX") }}</th>
            <th>{{ $t("superior") }}</th>
            <th>{{ $t("central") }}</th>
            <th>{{ $t("inferior") }}</th>
          </thead>
          <tbody>
            <tr
              v-bind:class="{
                'table-success': info.caracteristica.lcsx !== null,
              }"
            >
              <th>{{ $t("caracteristica") }}</th>
              <td>
                {{ info?.caracteristica?.lcsx?.toFixed(4) }}
              </td>
              <td>
                {{ info?.caracteristica?.lcx?.toFixed(4) }}
              </td>
              <td>
                {{ info?.caracteristica?.lcix?.toFixed(4) }}
              </td>
            </tr>
            <tr
              v-if="info.limites_calculados"
              v-bind:class="{
                'table-success': info.caracteristica.lcsx === null,
              }"
            >
              <th>{{ $t("calculados") }}</th>
              <td>
                {{ info?.limites_calculados?.lcsx?.toFixed(4) }}
              </td>
              <td>
                {{ info?.limites_calculados?.lcx?.toFixed(4) }}
              </td>
              <td>
                {{ info?.limites_calculados?.lcix?.toFixed(4) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col" v-if="!isConsigna">
        <table width="50%" class="tabla-capacidades table table-bordered table-sm">
          <thead>
            <th>{{ $t("capacidades") }}</th>
            <th>&sigma;</th>
            <th class="campo">Cp</th>
            <th class="comparacion"></th>
            <th>Cp</th>
            <th class="campo">Cpk</th>
            <th class="comparacion"></th>
            <th>Cpk</th>
          </thead>
          <tbody>
            <tr>
              <th>{{ $t("estimada") }}</th>
              <td>
                {{ info?.capacidades?.estimada?.sigma?.toFixed(4) }}
              </td>
              <td
                class="campo"
                v-bind:class="{
                  'table-danger': info.capacidades && !info.capacidades.estimada.capazcp,
                }"
              >
                {{ info?.capacidades?.estimada?.cp?.toFixed(4) }}
              </td>

              <td class="comparacion">
                <span class="text-success" v-if="info?.capacidades?.estimada?.capazcp">&gt;=</span>
                <span class="text-danger" v-else>&lt;</span>
              </td>

              <td>
                {{ info?.capacidades?.estimada?.cpusado?.toFixed(4) }}
              </td>
              <td
                class="campo"
                v-bind:class="{
                  'table-danger': info.capacidades && !info.capacidades.estimada.capazcpk,
                }"
              >
                {{ info?.capacidades?.estimada?.cpk?.toFixed(4) }}
              </td>

              <td class="comparacion">
                <span class="text-success" v-if="info.capacidades?.estimada.capazcpk">&gt;=</span>
                <span class="text-danger" v-else>&lt;</span>
              </td>

              <td>
                {{ info?.capacidades?.estimada?.cpkusado.toFixed(4) }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("real") }}</th>
              <td>
                {{ info?.capacidades?.real?.sigma?.toFixed(4) }}
              </td>
              <td
                class="campo"
                v-bind:class="{
                  'table-danger': info.capacidades && !info.capacidades.real.capazcp,
                }"
              >
                {{ info?.capacidades?.real?.cp?.toFixed(4) }}
              </td>

              <td class="comparacion">
                <span class="text-success" v-if="info?.capacidades?.real?.capazcp">&gt;=</span>
                <span class="text-danger" v-else>&lt;</span>
              </td>

              <td>
                {{ info?.capacidades?.real?.cpusado?.toFixed(4) }}
              </td>
              <td
                class="campo"
                v-bind:class="{
                  'table-danger': info.capacidades && !info.capacidades.real.capazcpk,
                }"
              >
                {{ info?.capacidades?.real?.cpk?.toFixed(4) }}
              </td>

              <td class="comparacion">
                <span class="text-success" v-if="info.capacidades?.real.capazcpk">&gt;=</span>
                <span class="text-danger" v-else>&lt;</span>
              </td>

              <td>
                {{ info?.capacidades?.real?.cpkusado?.toFixed(4) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col">
        <table width="50%" class="tabla-limites table table-bordered table-sm">
          <thead>
            <th>{{ $t("limitesControlR") }}</th>
            <th>{{ $t("superior") }}</th>
            <th>{{ $t("central") }}</th>
            <th>{{ $t("inferior") }}</th>
          </thead>
          <tbody>
            <tr
              v-bind:class="{
                'table-success': info.caracteristica.lcsr !== null,
              }"
            >
              <th>{{ $t("caracteristica") }}</th>
              <td>
                {{ info?.caracteristica?.lcsr?.toFixed(4) }}
              </td>
              <td>
                {{ info?.caracteristica?.lcr?.toFixed(4) }}
              </td>
              <td>
                {{ info?.caracteristica?.lcir?.toFixed(4) }}
              </td>
            </tr>
            <tr
              v-if="info.limites_calculados"
              v-bind:class="{
                'table-success': info.caracteristica.lcsr === null,
              }"
            >
              <th>{{ $t("calculados") }}</th>
              <td>
                <!-- {{ info.limites_calculados.lcsr ? info.limites_calculados.lcsr.toFixed(4) : "" }} -->
                {{ info?.limites_calculados?.lcsr?.toFixed(4) }}
              </td>
              <td>
                <!-- {{ info.limites_calculados.lcr ? info.limites_calculados.lcr.toFixed(4) : "" }} -->
                {{ info?.limites_calculados?.lcr?.toFixed(4) }}
              </td>
              <td>
                <!-- {{ info.limites_calculados.lcir ? info.limites_calculados.lcir.toFixed(4) : "" }} -->
                {{ info?.limites_calculados?.lcir?.toFixed(4) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div id="procesoNoCapaz" v-show="false">
      <div class="procesoNoCapaz px-4">
        <h4 v-if="info.capacidades && !info.capacidades.estimada.capaz" class="pt-3">&sigma; Estimada</h4>
        <ul v-if="info.capacidades && !info.capacidades.estimada.capaz">
          <li v-if="info.capacidades && !info.capacidades.estimada.capazcp">
            <label>Cp</label>
            <span
              >{{ info?.capacidades?.estimada?.cp?.toFixed(4) }}
              &lt;
              {{ info?.capacidades?.estimada?.cpusado?.toFixed(4) }}</span
            >
          </li>
          <li v-if="info.capacidades && !info.capacidades.estimada.capazcpk">
            <label>Cpk</label>
            <span
              >{{ info?.capacidades?.estimada?.cpk?.toFixed(4) }}
              &lt;
              {{ info?.capacidades?.estimada?.cpkusado?.toFixed(4) }}</span
            >
          </li>
        </ul>

        <h4 v-if="info.capacidades && !info.capacidades.real.capaz" class="pt-3">&sigma; Real</h4>

        <ul v-if="info.capacidades && !info.capacidades.real.capaz">
          <li v-if="info.capacidades && !info.capacidades.real.capazcp">
            <label>Cp</label>
            <span
              >{{ info?.capacidades?.real?.cp?.toFixed(4) }}
              &lt;
              {{ info?.capacidades?.real?.cpusado?.toFixed(4) }}</span
            >
          </li>
          <li v-if="info.capacidades && !info.capacidades.real.capazcpk">
            <label>Cpk</label>
            <span
              >{{ info?.capacidades?.real?.cpk?.toFixed(4) }}
              &lt;
              {{ info?.capacidades?.real?.cpkusado?.toFixed(4) }}</span
            >
          </li>
        </ul>
      </div>
    </div>

    <RPT v-show="false" :info="info" :metodoAnalisis="metodoAnalisis" ref="rpt1"> </RPT>
  </div>
</template>

<script>
/* eslint-disable */
//import DxButton from "devextreme-vue/button";
//import DxValidationSummary from "devextreme-vue/validation-summary";

import auth from "@/auth";

import Seleccion from "./Seleccion.vue";
import Analisis from "../autocontrol/Analisis.vue";
import CaracteristicaViewer from "../caracteristica-viewer.vue";

import { alert, confirm } from "devextreme/ui/dialog";

//REPORTS
import RPT from "@/components/autocontrol/reports/RPTGraficoVar";

//import DxSelectBox from "devextreme-vue/select-box";
import DxButton from "devextreme-vue/button";
import { DxPopup, DxPosition, DxOffset, DxToolbarItem } from "devextreme-vue/popup";
import { DxScrollView } from "devextreme-vue/scroll-view";

import * as overlay from "devextreme/ui/overlay";
overlay.baseZIndex(2001);

import {
  DxChart,
  DxSeries,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxCommonAxisSettings,
  DxGrid,
  DxExport,
  DxLegend,
  DxMargin,
  DxTooltip,
  DxLabel,
  DxFormat,
  DxPoint,
  DxTitle,
  DxSubtitle,
} from "devextreme-vue/chart";

import { exportWidgets } from "devextreme/viz/export";

import CustomStore from "devextreme/data/custom_store";

function handleErrors(response) {
  return response;
}

const calibresDataSource = new CustomStore({
  key: "id",
  loadMode: "raw", // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
  cacheRawData: true,
  load: () => {
    return fetch(`${global.API_URL}/instrumentos`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .catch(() => {
        throw "Network error";
      });
  },
  byKey: (id) => {
    return fetch(`${global.API_URL}/instrumentos/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .catch(() => {
        throw "Network error";
      });
  },
});

const metodoAnalisisDataSource = new CustomStore({
  key: "id",
  loadMode: "raw", // omit in the DataGrid, TreeList, PivotGrid, and Scheduler
  cacheRawData: true,
  load: () => {
    return fetch(`${global.API_URL}/caracteristicas/metodoanalisis`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .catch(() => {
        throw "Network error";
      });
  },
  byKey: (id) => {
    return fetch(`${global.API_URL}/caracteristicas/metodoanalisis/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.getToken(),
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .catch(() => {
        throw "Network error";
      });
  },
});

export default {
  props: ["caracteristicaId"],

  watch: {
    caracteristicaId: {
      immediate: true,
      handler: async function () {
        await this.load();
      },
    },
    selectionTypeSelected: async function () {
      await this.load();
    },
    cantidad: async function () {
      await this.load();
    },
    fechaInicio: async function () {
      await this.load();
    },
    fechaFin: async function () {
      await this.load();
    },
  },

  data() {
    return {
      //Genero el objeto info vacío para no tener errores en el mounted!!
      info: {
        plan: {},
        estructura: {},
        caracteristica: {},
        titulo: {},
        puntos: {
          x: [],
          r: [],
        },
        limites_calculados: {},
      },

      //series: series,
      //seriesTolerancia: seriesTolerancia,

      selectionTypeSelected: 0,
      fechaInicio: null,
      fechaFin: new Date(),
      cantidad: 25,

      //puntosX,
      //series,
      //caracteristica,
      //limites,
      calibresDataSource,
      calibre: "",
      metodoAnalisisDataSource,
      metodoAnalisis: "",

      // operaciones: ["ocultar", "eliminar", "notas"],
      // operacion: null,

      // Valores del punto seleccionado (crear un objeto?)
      fecha: null,
      minimo: null,
      maximo: null,
      valor: null,
      recorridoMovil: null,
      puntoSeleccionado: null,
      analisisSeleccionado: null,

      popupVisible: false,
      popupOffsetX: 0,
      popupOffsetY: 0,

      popupEditVisible: false,

      rptView: false,

      imageX: null,
      dataImageX: null,

      showError: false,
      errorMessage: "Error!",
    };
  },

  async mounted() {
    // console.log("%cGrafico variables mounted", "background:green");

    this.rptView = false;
  },

  computed: {
    isConsigna() {
      return this.info?.caracteristica?.tipo_calculo_cpk === 1;
    },

    procesoNoCapaz: function () {
      if (!this.info.capacidades) return false;
      return !this.info.capacidades?.estimada?.capaz || !this.info.capacidades?.real?.capaz;
    },
    procesoFueraControl: function () {
      if (!this.info?.criterios) return false;
      return this.info.criterios.x.length != 0 || this.info.criterios.r.length != 0;
    },
    // tieneCriterios: function () {
    //   if (!this.info.criterios) return false;
    //   return (
    //     this.info.criterios.x.length != 0 || this.info.criterios.r.length != 0
    //   );
    // },
    notas: function () {
      return this.info.caracteristica ? this.info.caracteristica.notas : [];
    },
    images: function () {
      return this.info.caracteristica ? this.info.caracteristica.images : [];
    },
    datasourceX: function () {
      return this.info.puntos.x;
    },
    datasourceR: function () {
      return this.info.puntos.r;
    },

    series: function () {
      return [
        {
          value: "lcs",
          name: this.$t("lc+"),
          color: "green",
          dashStyle: "line",
          width: 1,
        },
        {
          value: "l23s",
          name: "L2/3+",
          color: "orange",
          dashStyle: "dot",
          width: 1,
        },
        {
          value: "l13s",
          name: "L1/3+",
          color: "red",
          dashStyle: "dot",
          width: 1,
        },
        {
          value: "lc",
          name: this.$t("lc"),
          color: "black",
          dashStyle: "dash",
          width: 1,
        },
        {
          value: "l13i",
          name: "L1/3-",
          color: "red",
          dashStyle: "dot",
          width: 1,
        },
        {
          value: "l23i",
          name: "L2/3-",
          color: "orange",
          dashStyle: "dot",
          width: 1,
        },
        {
          value: "lci",
          name: this.$t("lc-"),
          color: "green",
          dashStyle: "line",
          width: 1,
        },
      ];
    },

    seriesTolerancia: function () {
      return [
        {
          value: "lts",
          name: this.$t("lt+"),
          color: "blue",
          dashStyle: "line",
          width: 1,
        },
        {
          value: "lti",
          name: this.$t("lt-"),
          color: "blue",
          dashStyle: "line",
          width: 1,
        },
      ];
    },
  },
  components: {
    // DxSelectBox,
    RPT,
    Seleccion,
    CaracteristicaViewer,
    DxChart,
    DxSeries,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxCommonAxisSettings,
    DxGrid,
    DxExport,
    DxLegend,
    DxMargin,
    DxTooltip,
    DxLabel,
    DxFormat,
    DxPoint,
    DxTitle,
    DxSubtitle,
    DxButton,
    DxPopup,
    DxPosition,
    DxOffset,
    DxToolbarItem,
    DxScrollView,
    Analisis,
    //TheFooter,
  },
  methods: {
    // moment(date) {
    //   return this.$moment(date);
    // },

    analisisCancelled() {
      this.closePopup();
    },
    closePopup() {
      this.popupEditVisible = false;
    },
    analisisUpdated() {
      this.$emit("updated");

      this.closePopup();

      // this.$refs["caracteristicas"].refresh();
      // this.$refs.graficoVariables && this.$refs["graficoVariables"].refresh();
      // this.$refs.graficoAtributos && this.$refs["graficoAtributos"].refresh();

      // this.$notify(this.$t("analisisGuardadoSatisfactoriamente"));
      this.load();
    },

    analisisError() {
      //this.closePopup();
      this.$notify(this.$t("error_al_guardar_analisis"), "error", 2000);
    },

    refresh() {
      //this.$forceUpdate();
      this.load();
    },

    onClickExport() {
      const graficoXInstance = this.$refs.graficoX.instance;
      const graficoRInstance = this.$refs.graficoR.instance;

      exportWidgets([[graficoXInstance, graficoRInstance]], {
        fileName: `grafico_${this.info.plan.referencia}_${this.info.caracteristica.nombre}`,
        format: "PNG",
      });
    },

    async onClickReport() {
      this.$refs.rpt1.print(this.$refs.graficoXR);
    },

    async onClickProcesoFueraControl() {
      let listaX, listaR;
      let mensaje = "";
      // x
      if (this.info.criterios.x.length != 0) {
        mensaje += `<h4>${this.info.titulo.x}</h4>`;
        listaX = this.info.criterios.x
          .map((c) => this.$t(`criteriosVAR${c}`))
          .reduce((list, c) => (list += `<li>${c}</li>`), "");
        mensaje += `<ul>${listaX}</ul><br><br>`;
      }

      // r
      if (this.info.criterios.r.length != 0) {
        mensaje += `<h4>${this.info.titulo.r}</h4>`;
        listaR = this.info.criterios.r
          .map((c) => this.$t(`criteriosVAR${c}`))
          .reduce((list, c) => (list += `<li>${c}</li>`), "");
        mensaje += `<ul>${listaR}</ul>`;
      }

      if (mensaje !== "") {
        await alert(`<div class="px-4 pt-4">${mensaje}</div>`, this.$t("procesoFueraControl"));
      }
    },

    async onClickProcesoNoCapaz() {
      let mensaje = "";
      let test = document.getElementById("procesoNoCapaz");
      mensaje = test.innerHTML;

      await alert(mensaje, this.$t("procesoNoCapaz"));
    },

    async load() {
      try {
        // montar el querystring:
        // let url = `${global.API_URL}/caracteristicas/${this.caracteristicaId}/analisis/grafico/atr?q=${this.cantidad}`,
        let url = "";
        // mirar el radio button:
        switch (this.selectionTypeSelected) {
          case 0:
            // cantidad
            url = `${global.API_URL}/caracteristicas/${this.caracteristicaId}/analisis/grafico/var?q=${this.cantidad}`;
            break;
          case 1:
            // fechas
            if (!this.fechaInicio || !this.fechaFin) {
              url = `${global.API_URL}/caracteristicas/${this.caracteristicaId}/analisis/grafico/var?q=0`;
            } else {
              url = `${global.API_URL}/caracteristicas/${
                this.caracteristicaId
              }/analisis/grafico/var?s=${this.fechaInicio.getTime()}&e=${this.fechaFin.getTime()}`;
            }

            break;
        }

        const response = await fetch(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.getToken(),
          },
        });

        const data = await response.json();

        if (response.ok) {
          this.info = data;
          this.showError = false;
          this.errorMessage = "";
          this.procesoFueraControl && this.$emit("procesoFueraControl", this.info.criterios);
          this.procesoNoCapaz && this.$emit("procesoNoCapaz", this.info.capacidades);
        } else {
          //la seleccion ha sido incorrecta
          this.info = data;
          const mensaje = `${data.error.codigo}`;
          this.$notify(mensaje, this.$t("error"), 5000);
          this.showError = true;
          this.errorMessage = this.$t(`errorSPC${mensaje}`).interpolate(data.error.param);
        }
      } catch (ex) {
        this.$notify(mensaje, this.$t("error"), 5000);
      }

      this.calibre = await calibresDataSource.byKey(this.info.caracteristica.id_instrumentos);

      this.metodoAnalisis = await metodoAnalisisDataSource.byKey(this.info.caracteristica.id_metodoanalisis);
    },

    async onPointClick({ target: point }) {
      point.select();

      this.popupVisible = true;

      this.puntoSeleccionado = point; //.data.analisis;
      this.analisisSeleccionado = point.data.analisis;

      this.valor = point.data.value;
      this.minimo = point.data.analisis ? point.data.analisis.minimo : null;
      this.maximo = point.data.analisis ? point.data.analisis.maximo : null;
      this.recorridoMovil = point.data.analisis ? point.data.analisis.recorrido_movil : null;
      this.fecha = point.data.analisis ? point.data.analisis.fecha : null;

      this.puntoSeleccionadoHtml = point;

      const graficoXRect = this.$refs.graficoX.$el.getBoundingClientRect();

      this.popupOffsetX = point.x + graficoXRect.x;
      this.popupOffsetY = point.y + graficoXRect.y;

      //buscar los valores valoresXBR
      if (this.info.caracteristica.id_metodoanalisis === 4) {
        const response = await fetch(
          `${global.API_URL}/caracteristicas/${this.caracteristicaId}/analisis/${point.data.analisis.id}/valores/XBR`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + auth.getToken(),
            },
          }
        );
        const data = await response.json();

        // coloco los valores y el metodo analisis en el analisis seleccionado
        // this.analisisSeleccionado._valores = data.map((e) => e.valor);

        this.$set(
          this.analisisSeleccionado,
          "_valores",
          data.map((e) => e.valor)
        );

        this.analisisSeleccionado._metodoanalisis = 4;
      }
    },

    onLegendClick({ target: series }) {
      if (series.isVisible()) {
        series.hide();
      } else {
        series.show();
      }
    },

    async onClickEdit() {
      if (!this.puntoSeleccionado) return;

      this.popupVisible = false;
      this.popupEditVisible = true;
    },

    async onClickHide() {
      if (!this.puntoSeleccionado) return;

      const update = {
        ocultar: true,
      };

      try {
        const response = await fetch(
          `${global.API_URL}/caracteristicas/${this.caracteristicaId}/analisis/${this.puntoSeleccionado.data.analisis.id}`,
          {
            method: "PATCH",
            mode: "cors",
            cache: "no-cache",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + auth.getToken(),
            },
            body: JSON.stringify(update),
          }
        );
        if (response.ok) {
          this.$notify(this.$t("ocultado_correctamente"), "success", 5000);
        } else {
          this.$notify(this.$t("error_al_ocultar"), "error", 5000);
        }
      } catch (ex) {
        this.$notify(this.$t("error_al_ocultar"), "error", 5000);
      }

      this.puntoSeleccionado.clearSelection();
      this.popupVisible = false;
      await this.load();
    },

    async onClickDelete() {
      if (!this.puntoSeleccionado) return;

      let result = await confirm(this.$t("confirmarBorrarAnalisis"), this.$t("borrarAnalisis"));

      if (result) {
        try {
          const response = await fetch(
            `${global.API_URL}/caracteristicas/${this.caracteristicaId}/analisis/${this.puntoSeleccionado.data.analisis.id}`,
            {
              method: "delete",
              mode: "cors",
              cache: "no-cache",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + auth.getToken(),
              },
            }
          );
          if (response.ok) {
            this.$notify(this.$t("borrado_correctamente"), "success", 5000);
          } else {
            this.$notify(this.$t("error_al_borrar"), "error", 5000);
          }
        } catch (ex) {
          this.$notify(this.$t("error_al_borrar"), "error", 5000);
        }

        this.popupVisible = false;

        await this.load();
      }
    },

    async onClickNotes() {
      // @TODO
      // console.error("not implemented yet");
    },

    async onClickRestore() {
      let primer_analisis = this.info.puntos.x[0].analisis; //he seleccionado por cantidad
      let ultimo_analisis = this.info.puntos.x[this.info.puntos.x.length - 1].analisis;

      let restore = {
        id_inicio: primer_analisis.id,
        id_fin: ultimo_analisis.id,
        tipo: primer_analisis.tipo,
      };

      try {
        const response = await fetch(`${global.API_URL}/caracteristicas/${this.caracteristicaId}/analisis/restore`, {
          method: "put",
          mode: "cors",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.getToken(),
          },
          body: JSON.stringify(restore),
        });
        if (response.ok) {
          this.$notify(this.$t("restaurado_correctamente"), "success", 5000);
        } else {
          this.$notify(this.$t("error_al_restaurar"), "error", 5000);
        }
      } catch (ex) {
        this.$notify(this.$t("error_al_restaurar"), "error", 5000);
      }

      await this.load();
    },
  },
};
</script>

<style scoped>
.botones {
  position: fixed;
  z-index: 9000;
  bottom: 50px;
  right: 50px;
}

.padding {
  height: 100px;
}

.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  margin-top: 20px;
}

.option {
  margin-top: 10px;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option > span {
  margin-right: 14px;
}

.option > .dx-widget {
  display: inline-block;
  vertical-align: middle;
}

#graficoX,
#graficoR {
  height: 500px;
  width: 100%;
}

.dxc-tooltip {
  z-index: 2000;
}

.row.plan-control {
  margin: 10px;
}

.row.caracteristica {
  margin: 10px;
}

#popupButtonsContainer {
  gap: 2em;
}

.dx-button.danger {
  background: var(--danger);
}

.dx-button.danger .dx-icon {
  color: white;
}

.procesoNoCapaz label {
  width: 30px;
  font-weight: 700;
}

th.campo ~ th.comparacion,
th.campo,
td.campo ~ td.comparacion,
td.campo {
  border-right: 1px solid transparent;
}

.comparacion span {
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 16px;
}

.grafico-error {
  --bs-red: #f44336;
  background-color: var(--bs-red);
  z-index: 1;
  box-shadow: 0 0 10px -5px black;
}
</style>
