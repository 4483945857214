var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"d-flex justify-content-between"},[_vm._v(" "+_vm._s(_vm.$t("notasAlControlador"))+" "),_c('DxButton',{staticClass:"mx-3",attrs:{"icon":"add","type":"default","stylingMode":"contained","hint":_vm.$t('agregar_nota_al_controlador')},on:{"click":() => _vm.$refs.grid.instance.addRow()}})],1),_c('DxDataGrid',{ref:"grid",staticClass:"dx-card wide-card",attrs:{"id":"gridNotas","data-source":_vm.notasAlControladorStore,"keyExpr":"id","hover-state-enabled":true,"noDataText":_vm.$t('noHayNotas')},scopedSlots:_vm._u([{key:"fechaInicioEditor",fn:function({ data: cellInfo }){return [_c('div',[_c('DxDateBox',{ref:"fechaInicio",attrs:{"type":"datetime","max":cellInfo.data.fecha_fin},on:{"valueChanged":(e) => {
              _vm.$refs.fechaFin.instance.option('min', e.value);
              cellInfo.setValue(e.value);
            }},model:{value:(cellInfo.value),callback:function ($$v) {_vm.$set(cellInfo, "value", $$v)},expression:"cellInfo.value"}})],1)]}},{key:"fechaFinEditor",fn:function({ data: cellInfo }){return [_c('div',[_c('DxDateBox',{ref:"fechaFin",attrs:{"type":"datetime","min":cellInfo.data.fecha_inicio},on:{"valueChanged":(e) => {
              _vm.$refs.fechaInicio.instance.option('max', e.value);
              cellInfo.setValue(e.value);
            }},model:{value:(cellInfo.value),callback:function ($$v) {_vm.$set(cellInfo, "value", $$v)},expression:"cellInfo.value"}})],1)]}},{key:"documentos-header",fn:function(){return [_c('span',{staticClass:"pr-1"},[_c('i',{staticClass:"ion ion-md-attach"})])]},proxy:true},{key:"documentos",fn:function({ data }){return [(!data.row.isEditing)?_c('ButtonAttachedDocuments',{attrs:{"number":data?.data?.documentos?.length},on:{"click":function($event){return _vm.onAttachedClick({
            data: data.data,
            resource: `caracteristicas/${_vm.idCaracteristica}/notas/${data.data.id}`,
            type: 'DOC',
          })}}}):_vm._e()]}},{key:"fotos-header",fn:function(){return [_c('span',{staticClass:"pr-1"},[_c('i',{staticClass:"ion ion-md-camera"})])]},proxy:true},{key:"fotos",fn:function({ data }){return [(!data.row.isEditing)?_c('ButtonAttachedImages',{attrs:{"number":data?.data?.fotos?.length},on:{"click":function($event){return _vm.onAttachedClick({
            data: data.data,
            resource: `caracteristicas/${_vm.idCaracteristica}/notas/${data.data.id}`,
            type: 'IMG',
          })}}}):_vm._e()]}}])},[_c('DxEditingGrid',{attrs:{"allow-updating":true,"allow-adding":false,"allow-deleting":true,"mode":"row"}}),_c('DxColumnGrid',{attrs:{"data-field":"nota","caption":_vm.$t('nota')}},[_c('DxRequiredRule')],1),_c('DxColumnGrid',{attrs:{"data-field":"fecha_inicio","caption":_vm.$t('fechaInicio'),"data-type":"datetime","edit-cell-template":"fechaInicioEditor"}}),_c('DxColumnGrid',{attrs:{"data-field":"fecha_fin","caption":_vm.$t('fechaFin'),"data-type":"datetime","edit-cell-template":"fechaFinEditor"}}),_c('DxColumnGrid',{attrs:{"data-field":"conclusion","caption":_vm.$t('conclusion')}}),_c('DxColumnGrid',{attrs:{"data-field":"documentos.length","allow-editing":false,"width":"50","cell-template":"documentos","header-cell-template":"documentos-header"}}),_c('DxColumnGrid',{attrs:{"allow-editing":false,"data-field":"fotos.length","cell-template":"fotos","width":"50","header-cell-template":"fotos-header"}})],1),(_vm.attachedID)?_c('DxPopup',{attrs:{"title":_vm.$t('ficherosAdjuntos'),"visible":_vm.isPopupAttachedVisible,"fullScreen":false,"animation":{
      show: {
        type: 'pop',
      },
      hide: {
        type: 'pop',
        from: { scale: 1, opacity: 1 },
        to: { scale: 0, opacidy: 0 },
      },
    }},on:{"update:visible":function($event){_vm.isPopupAttachedVisible=$event},"hidden":_vm.refreshDataGrid}},[[_c('div',[(_vm.attachedID && _vm.attachedResourceType === 'IMG')?_c('AttachedViewer',{key:_vm.attachedID,ref:"attachedViewerGeneral",attrs:{"resourceURI":_vm.attachedResourceURI,"resourceType":_vm.attachedResourceType}}):_vm._e(),(_vm.attachedID && _vm.attachedResourceType === 'DOC')?_c('AttachedDocumentViewer',{key:_vm.attachedID,ref:"attachedDocumentViewerGeneral",attrs:{"resourceURI":_vm.attachedResourceURI,"resourceType":_vm.attachedResourceType}}):_vm._e()],1)]],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }