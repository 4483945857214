<template>
    <div :key="componentKey">
        <div class="d-flex flex-row pt-3">
            <div class="pr-3"> {{$t('seleccione')}}:</div>

            <div class="px-3">
                <DxRadioGroup
                    v-model="ctipo"
                    :items="options"
                    display-expr="text"
                    value-expr="value"
                ></DxRadioGroup>
            </div>
            <div v-if="0 === ctipo" class="pl-3">
                <DxNumberBox
                    :showSpinButtons="true"
                    :step="1"
                    :min="0"
                    v-model="ccantidad"
                    :width="100"
                    :disabled="0 !== ctipo"
                ></DxNumberBox>
            </div>
            <div v-if="1 === ctipo" class="d-flex flex-row pl-3">
                <div>
              {{$t('desde')}}
                    <DxDateBox
                        v-model="cfechaInicio"
                        :width="100"
                        :disabled="1 !== ctipo"
                    ></DxDateBox>
                </div>
                <div class="pl-3">
                  {{$t('hasta')}}
                    <DxDateBox
                        v-model="cfechaFin"
                        :width="100"
                        :disabled="1 !== ctipo"
                    ></DxDateBox>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
// const options = [
//   { value: 0, text: $t('cantidadAnalisis') },
//   { value: 1, text: $t('entreFechas') },
// ];

 
import DxRadioGroup from "devextreme-vue/radio-group";
import DxNumberBox from "devextreme-vue/number-box";
import DxDateBox from "devextreme-vue/date-box";

let timer;

export default {
    components: {
        DxRadioGroup,
        DxNumberBox,
        DxDateBox,
    },

    props: ["tipo", "cantidad", "fechaInicio", "fechaFin"],
    data() {
        return {
            componentKey: 0,
            //options: options,
        };
    },
    computed: {
        ctipo: {
            get: function() {
                return this.tipo;
            },
            set: function(value) {
                this.$emit("update:tipo", value);
            },
        },
        ccantidad: {
            get: function() {
                return this.cantidad;
            },
            set: function(value) {
                clearTimeout(timer);
                timer = setTimeout(
                    () => this.$emit("update:cantidad", value),
                    1000
                );
            },
        },
        cfechaInicio: {
            get: function() {
                return this.fechaInicio;
            },
            set: function(value) {
                this.$emit("update:fechaInicio", value);
            },
        },
        cfechaFin: {
            get: function() {
                return this.fechaFin;
            },
            set: function(value) {
                this.$emit("update:fechaFin", value);
            },
        },
        idioma() {
            // return this.$store.state.idioma;
            return this.$i18n.locale;
        }, 
        
        options: function(){
            return [    
            { value: 0, text: this.$t('cantidadAnalisis') },
            { value: 1, text: this.$t('entreFechas') },
      ];
    }
    },
    // methods: {
    //   changeLanguage(){
    //     this.componentKey++;
    //   }
    // },
    watch: {
        // eslint-disable-next-line no-unused-vars
        idioma(newI, oldI) {
            this.componentKey++;
        },
    },

   
  }
 

  

</script>

<style></style>
