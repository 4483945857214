<template>
  <div>
    <DxPopup
      class="popupScrollableContentWithoutPadding"
      :visible.sync="isPopupVisible"
      v-if="isPopupVisible"
      :fullScreen="false"
      :title="instrumento.nombre"
    >
      <DxToolbarItem :options="toolbarItemCancelarOptions" widget="dxButton" location="after" toolbar="bottom" />

      <DxToolbarItem :options="toolbarItemGuardarOptions" widget="dxButton" location="after" toolbar="bottom" />

      <DxScrollView>
        <div>
          <div v-if="$store.state.debug">
            <code>
              <pre>{{ instrumento }}</pre></code
            ><br />
            <code>
              <pre>{{ instrumentoEditable }}</pre></code
            ><br />
          </div>

          <div class="d-flex flex-wrap mt-4 gap-3 column-gap-3">
            <div class="d-flex flex-column">
              <DxTextBox :label="$t('codigo')" :value.sync="instrumentoEditable.codigo">
                <DxValidator ref="codigoValidator">
                  <DxRequiredRule :message="$t('error_codigo_required')" />
                </DxValidator>
              </DxTextBox>
            </div>

            <div class="d-flex flex-column">
              <DxTextBox :label="$t('nombre')" :value.sync="instrumentoEditable.nombre">
                <DxValidator ref="nombreValidator">
                  <DxRequiredRule :message="$t('error_nombre_required')" />
                </DxValidator>
              </DxTextBox>
            </div>

            <div class="d-flex flex-column">
              <DxSelectBox
                :label="$t('tipo')"
                v-model="instrumentoEditable.tipo"
                ref="tipo"
                :width="200"
                value-expr="id"
                display-expr="value"
                :data-source="dataSourceTipoInstrumento"
                field-template="field"
                item-template="item"
              >
                <template #field="{ data }">
                  <div class="d-flex align-items-center">
                    <img
                      class="ms-2"
                      v-if="data?.img"
                      :src="require(`@/assets/${data?.img}`)"
                      :alt="data?.value"
                      height="24"
                    />
                    <DxTextBox :value="data && data.value" :read-only="true" />
                  </div>
                </template>
                <template #item="{ data }">
                  <div class="d-flex align-items-center">
                    <img
                      class="me-3"
                      v-if="data?.img"
                      :src="require(`@/assets/${data?.img}`)"
                      :alt="data?.value"
                      height="24"
                    />
                    <span>{{ data.value }}</span>
                  </div>
                </template>
              </DxSelectBox>
            </div>
            <div class="d-flex flex-column">
              <DxSelectBox
                :label="$t('estado')"
                v-model="instrumentoEditable.estado"
                :width="200"
                value-expr="id"
                display-expr="value"
                :data-source="dataSourceEstadoInstrumento"
                field-template="field"
                item-template="item"
              >
                <template #field="{ data }">
                  <div class="d-flex align-items-center">
                    <img
                      class="ms-2"
                      v-if="data?.img"
                      :src="require(`@/assets/${data?.img}`)"
                      :alt="data?.value"
                      height="24"
                    />
                    <DxTextBox :value="data && data.value" :read-only="true" />
                  </div>
                </template>
                <template #item="{ data }">
                  <div class="d-flex align-items-center">
                    <img
                      class="me-3"
                      v-if="data?.img"
                      :src="require(`@/assets/${data?.img}`)"
                      :alt="data?.value"
                      height="24"
                    />
                    <span>{{ data.value }}</span>
                  </div>
                </template>
              </DxSelectBox>
            </div>

            <div class="d-flex flex-column">
              <DxSelectBox
                :label="$t('clasificacion')"
                v-model="instrumentoEditable.id_clasificacion"
                :width="200"
                value-expr="id"
                display-expr="nombre"
                :data-source="clasificacionStore"
                :search-enabled="true"
                search-mode="contains"
                :search-expr="['nombre']"
                :show-clear-button="true"
              >
              </DxSelectBox>
            </div>

            <div class="d-flex flex-column">
              <DxSelectBox
                :label="$t('categoria')"
                v-model="instrumentoEditable.id_categorias"
                :width="200"
                value-expr="id"
                :display-expr="(item) => (!item ? '' : `${item?.categoria} (${item?.codigo})`)"
                :data-source="categoriaStore"
                :show-clear-button="true"
              >
                <template #item="{ data }">
                  <div>
                    <div>{{ data?.categoria }}</div>
                    <div class="fst-italic text-secondary">{{ data?.codigo }}</div>
                  </div>
                </template>
              </DxSelectBox>
            </div>

            <div class="d-flex flex-column">
              <DxSelectBox
                :label="$t('departamento')"
                v-model="instrumentoEditable.id_departamentos"
                :width="200"
                value-expr="id"
                :display-expr="(item) => (!item ? '' : `${item?.descripcion} (${item?.codigo})`)"
                :data-source="departamentoStore"
                :show-clear-button="true"
              >
                <template #item="{ data }">
                  <div>
                    <div>{{ data?.descripcion }}</div>
                    <div class="fst-italic text-secondary">{{ data?.codigo }}</div>
                  </div>
                </template>
              </DxSelectBox>
            </div>

            <div class="d-flex flex-column">
              <DxSelectBox
                :label="$t('cliente')"
                v-model="instrumentoEditable.id_clientes"
                :width="200"
                value-expr="id"
                :display-expr="(item) => (!item ? '' : `${item?.nombre} (${item?.codigo})`)"
                :data-source="clienteStore"
                :show-clear-button="true"
              >
                <template #item="{ data }">
                  <div>
                    <div>{{ data?.nombre }}</div>
                    <div class="fst-italic text-secondary">{{ data?.codigo }}</div>
                  </div>
                </template>
              </DxSelectBox>
            </div>
          </div>

          <div class="d-flex flex-wrap mt-4 gap-3 column-gap-3">
            <div class="d-flex flex-column">
              <DxTextBox :label="$t('marca')" :value.sync="instrumentoEditable.marca"> </DxTextBox>
            </div>
            <div class="d-flex flex-column">
              <DxTextBox :label="$t('modelo')" :value.sync="instrumentoEditable.modelo"> </DxTextBox>
            </div>
            <div class="d-flex flex-column">
              <DxTextBox :label="$t('serie')" :value.sync="instrumentoEditable.serie"> </DxTextBox>
            </div>
            <div class="d-flex flex-column">
              <DxTextBox :label="$t('norma')" :value.sync="instrumentoEditable.norma"> </DxTextBox>
            </div>
          </div>

          <div class="d-flex flex-wrap mt-4 gap-3 column-gap-3">
            <div class="d-flex flex-column">
              <DxDateBox :label="$t('fecha_alta')" :value.sync="instrumentoEditable.fecha_alta"> </DxDateBox>
            </div>
            <div class="d-flex flex-column">
              <DxDateBox :label="$t('fecha_baja')" :value.sync="instrumentoEditable.fecha_baja"> </DxDateBox>
            </div>
          </div>

          <div class="d-flex flex-wrap mt-4 gap-3 column-gap-3">
            <DxSelectBox
              :label="$t('proveedor')"
              v-model="instrumentoEditable.id_proveedores"
              :width="200"
              value-expr="id"
              :display-expr="(item) => (!item ? '' : `${item?.descripcion} (${item?.codigo})`)"
              :data-source="proveedorStore"
              :show-clear-button="true"
            >
              <template #item="{ data }">
                <div>
                  <div>{{ data?.descripcion }}</div>
                  <div class="fst-italic text-secondary">{{ data?.codigo }}</div>
                </div>
              </template>
            </DxSelectBox>

            <DxDateBox :label="$t('fecha_compra')" :value.sync="instrumentoEditable.fecha_compra"> </DxDateBox>

            <DxNumberBox
              :format="{ type: 'currency', precision: 2 }"
              :label="$t('precio')"
              v-model="instrumentoEditable.precio"
              :min="0"
              :showSpinButtons="true"
              :step="1"
              :showClearButton="true"
              :width="150"
            >
            </DxNumberBox>
          </div>

          <div class="d-flex flex-wrap mt-4 gap-3 column-gap-3">
            <DxTextBox :label="$t('cota_minima')" :value.sync="instrumentoEditable.cota_minima"> </DxTextBox>
            <DxTextBox :label="$t('cota_maxima')" :value.sync="instrumentoEditable.cota_maxima"> </DxTextBox>
            <DxTextBox :label="$t('precision_elemento')" :value.sync="instrumentoEditable.precision_elemento">
            </DxTextBox>
            <DxTextBox :label="$t('precision_proceso')" :value.sync="instrumentoEditable.precision_proceso">
            </DxTextBox>
          </div>
          <div class="d-flex flex-wrap my-5 gap-3 column-gap-3">
            <DxTextArea :label="$t('notas')" width="100%" :value.sync="instrumentoEditable.notas"> </DxTextArea>
          </div>
          <div class="d-flex flex-wrap mt-4 gap-3 column-gap-3">
            <DxSelectBox
              :label="$t('sustituye_a')"
              v-model="instrumentoEditable.id_instrumentos"
              width="100%"
              value-expr="id"
              :display-expr="(item) => (!item ? '' : `${item.nombre} (${item.codigo})`)"
              :data-source="instrumentosStore"
              :show-clear-button="true"
            >
              <template #item="{ data }">
                <div>
                  <div>{{ data?.nombre }}</div>
                  <div class="fst-italic text-secondary">{{ data?.codigo }}</div>
                </div>
              </template>
            </DxSelectBox>
          </div>

          <div class="mt-5">
            <h3>{{ $t("calibracion") }}</h3>
          </div>

          <div class="d-flex flex-wrap mt-2 gap-3 column-gap-2">
            <DxSelectBox
              :label="$t('cadencia_calibracion')"
              v-model="instrumentoEditable.cadencia_calibracion"
              ref="tipo"
              :width="200"
              value-expr="id"
              display-expr="value"
              :data-source="dataSourceCadenciasInstrumento"
              @valueChanged="onValueChangedCadenciasCalibracion"
              @selectionChanged="onSelectionChangedCadenciasCalibracion"
            >
            </DxSelectBox>
            <DxNumberBox
              v-if="!isOtrosCalibracion"
              :label="$t('frecuencia_calibracion')"
              v-model="instrumentoEditable.frecuencia_calibracion"
              :min="0"
              :showSpinButtons="true"
              :step="1"
            >
            </DxNumberBox>
            <DxNumberBox
              :label="$t('numero_usos_calibracion')"
              v-if="isNumeroDeUsosCalibracion"
              v-model="instrumentoEditable.numero_usos_calibracion"
              :min="0"
              :showSpinButtons="true"
              :step="1"
            >
            </DxNumberBox>
            <DxTextBox
              :label="$t('texto_otros_calibracion')"
              width="100%"
              :value.sync="instrumentoEditable.texto_otros_calibracion"
            >
            </DxTextBox>
          </div>

          <div class="mt-5">
            <h3>{{ $t("verificacion") }}</h3>
          </div>

          <div class="d-flex flex-wrap mt-2 gap-3 column-gap-2">
            <DxSelectBox
              :label="$t('cadencia_verificacion')"
              v-model="instrumentoEditable.cadencia_verificacion"
              ref="tipo"
              :width="200"
              value-expr="id"
              display-expr="value"
              :data-source="dataSourceCadenciasInstrumento"
              @valueChanged="onValueChangedCadenciasVerificacion"
              @selectionChanged="onSelectionChangedCadenciasVerificacion"
            >
            </DxSelectBox>
            <DxNumberBox
              :label="$t('frecuencia_verificacion')"
              v-if="!isOtrosVerificacion"
              v-model="instrumentoEditable.frecuencia_verificacion"
              :min="0"
              :showSpinButtons="true"
              :step="1"
            >
            </DxNumberBox>
            <DxNumberBox
              :label="$t('numero_usos_verificacion')"
              v-if="isNumeroDeUsosVerificacion"
              v-model="instrumentoEditable.numero_usos_verificacion"
              :min="0"
              :showSpinButtons="true"
              :step="1"
            >
            </DxNumberBox>
            <DxTextBox
              :label="$t('texto_otros_verificacion')"
              width="100%"
              :value.sync="instrumentoEditable.texto_otros_verificacion"
            >
            </DxTextBox>
          </div>
        </div>
      </DxScrollView>
    </DxPopup>

    <!-- botones -->
    <div class="botones">
      <DxButton icon="edit" type="default" stylingMode="text" @click="onClickButtonEdit"></DxButton>
    </div>
  </div>
</template>

<script>
import { NUMERO_DE_USOS, OTROS } from "@/core/cadencias/constantes.js";

import auth from "@/auth";

import DxTextBox from "devextreme-vue/text-box";
import DxDateBox from "devextreme-vue/date-box";
import DxSelectBox from "devextreme-vue/select-box";
import DxTextArea from "devextreme-vue/text-area";
import DxNumberBox from "devextreme-vue/number-box";
import DxButton from "devextreme-vue/button";

import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";

import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";
import { DxScrollView } from "devextreme-vue/scroll-view";

import tipos from "@/data/instrumentos/Tipos";
import estados from "@/data/instrumentos/Estados";
import cadencias from "@/data/instrumentos/Cadencias";

import { DataSourceClasificacion } from "@/data/instrumentos/ClasificacionDataSource";
import { DataSourceCategorias } from "../../data/instrumentos/CategoriasDataSource";
import { DataSourceDepartamentos } from "@/data/DepartamentoDataSource";
import { ClientesDataSource } from "@/data/ClienteDataSource";
import { DataSourceProveedores } from "@/data/ProveedorDataSource";
import { DataSourceInstrumentos } from "@/data/instrumentos/InstrumentoDataSource";

export default {
  props: [
    "instrumento", //Será reactivo cuando haga el update. se sincronizará con un $emit
  ],
  data() {
    return {
      isPopupVisible: false,
      componentKey: 0,
      instrumentoEditable: structuredClone(this.instrumento),

      clasificacionStore: new DataSourceClasificacion(),
      categoriaStore: new DataSourceCategorias(),
      departamentoStore: new DataSourceDepartamentos(),
      clienteStore: new ClientesDataSource(),
      proveedorStore: new DataSourceProveedores(),
      instrumentosStore: new DataSourceInstrumentos(),
    };
  },

  components: {
    DxButton,
    DxTextBox,
    DxDateBox,
    DxSelectBox,
    DxTextArea,
    DxNumberBox,
    // SelectBoxCatalog,
    // DxCheckBox,
    // DxRadioGroup,
    // DxSwitch,
    //
    DxValidator,
    DxRequiredRule,
    //
    DxPopup,
    DxToolbarItem,
    DxScrollView,
  },

  computed: {
    dataSourceTipoInstrumento() {
      return tipos.map((e) => ({
        ...e,
        value: this.$t(e.value),
      }));
    },
    dataSourceEstadoInstrumento() {
      return estados.map((e) => ({
        ...e,
        value: this.$t(e.value),
      }));
    },
    dataSourceCadenciasInstrumento() {
      return cadencias.map((e) => ({
        ...e,
        value: this.$t(e.value),
      }));
    },

    isOtrosCalibracion() {
      return this.instrumentoEditable?.cadencia_calibracion === OTROS;
    },
    isNumeroDeUsosCalibracion() {
      return this.instrumentoEditable?.cadencia_calibracion === NUMERO_DE_USOS;
    },
    isOtrosVerificacion() {
      return this.instrumentoEditable?.cadencia_verificacion === OTROS;
    },
    isNumeroDeUsosVerificacion() {
      return this.instrumentoEditable?.cadencia_verificacion === NUMERO_DE_USOS;
    },

    toolbarItemCancelarOptions: function () {
      return {
        text: this.$t("cancelar"),
        type: "normal",
        onClick: () => {
          // this.log("click cancel", e);
          this.isPopupVisible = false;
        },
      };
    },

    toolbarItemGuardarOptions: function () {
      return {
        text: this.$t("guardar"),
        stylingMode: "contained",
        // useSubmitBehavior: true,
        type: "default",
        onClick: (e) => {
          // this.log("click save", e);
          // this.$emit("update:plancontrol", this.planEditable);

          /**
           * @TODO la validación necesita un grupo porque el botón no pertenece al grupo e intenta
           * validar TODA la página.  Cuando estaba sólo este formulario esto era válido.
           * Al haber incluido otros componentes con formularios e.validationGroup.validate()
           * intenta validar esos otros formularios y la validación no es correcta.
           * */

          e.validationGroup.validate().isValid && this.update();
        },
      };
    },
  },

  watch: {
    idioma() {
      this.componentKey++;
    },
  },

  methods: {
    onValueChangedCadenciasCalibracion() {},
    onValueChangedCadenciasVerificacion() {},
    onSelectionChangedCadenciasCalibracion() {},

    onSelectionChangedCadenciasVerificacion() {},

    resetCalibracion() {
      this.instrumentoEditable.frecuencia_calibracion = null;
      this.instrumentoEditable.numero_usos_calibracion = null;
      this.instrumentoEditable.texto_otros_calibracion = null;
    },
    resetVerificacion() {
      this.instrumentoEditable.frecuencia_verificacion = null;
      this.instrumentoEditable.numero_usos_verificacion = null;
      this.instrumentoEditable.texto_otros_verificacion = null;
    },

    async update() {
      try {
        console.log(JSON.stringify(this.instrumentoEditable, null, 2));
        //envio la petición al servidor para que actualice el plan en la base de datos.
        const response = await fetch(`${global.API_URL}/instrumentos/${this.instrumento.id}`, {
          method: "PUT",
          mode: "cors",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.getToken(),
          },
          body: JSON.stringify(this.instrumentoEditable),
        });

        if (response.ok) {
          const data = await response.json();

          this.instrumentoEditable.fecha_ultima_calibracion = data.fecha_ultima_calibracion;
          this.instrumentoEditable.fecha_proxima_calibracion = data.fecha_proxima_calibracion;
          this.instrumentoEditable.fecha_ultima_verificacion = data.fecha_ultima_verificacion;
          this.instrumentoEditable.fecha_proxima_verificacion = data.fecha_proxima_verificacion;
          this.instrumentoEditable.numero_usos_calibracion = data.numero_usos_calibracion;
          this.instrumentoEditable.numero_usos_verificacion = data.numero_usos_verificacion;

          this.$emit("update:instrumento", this.instrumentoEditable);
          this.isPopupVisible = false;
        } else {
          this.$emit("error");
          this.$notify(this.$t("error_al_actualizar"), "error");
        }
      } catch (ex) {
        this.$emit("error");
        this.$notify(this.$t("error_al_actualizar"), "error");
      }
    },
    async onClickButtonEdit() {
      this.isPopupVisible = true;
      // se copia el instrumento en instrumentoEditable para porder cancelar la actualización.
      // lo bonito sería trabajar con el montaje del componente pero el popup funciona con els isPopupVisible
      // y no construye/destruye el componente y hay que buscar otro camino.  (el que ya hicimos con plan...  pero más bonito)
      this.instrumentoEditable = structuredClone(this.instrumento);
    },
  },
  async mounted() {
    console.log("-------------- edit.vue mounted -------- ");
  },
};
</script>

<style lang="scss">
.situaciones-list {
  padding-left: 15px;

  li::marker {
    color: #ccc;
  }

  li.selected::marker {
    color: var(--accent);
  }

  li {
    img {
      opacity: 0.2;
    }

    color: #ccc;
  }

  .selected {
    img {
      opacity: 1;
    }

    color: var(--accent);
  }
}

.readonly {
  i {
    color: gray;
  }
}

.botones {
  text-align: right;
}
</style>
