import { get, post, put, remove } from "@/core/fetch";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

export class CustomStoreIncertidumbres extends CustomStore {
  constructor(id_instrumento, id_calibracion, id_linea_calibracion) {
    const baseUrl = `${global.API_URL}/instrumentos/${id_instrumento}/calibraciones/${id_calibracion}/lineas/${id_linea_calibracion}/incertidumbres`;
    super({
      key: "id",
      loadMode: "raw",
      load: () => get(`${baseUrl}`),
      byKey: (key) => get(`${baseUrl}/${key}`),
      insert: (values) => post(`${baseUrl}`, values),
      update: (key, values) => put(`${baseUrl}/${key}`, values),
      remove: (key) => remove(`${baseUrl}/${key}`),
    });
  }
}

export class DataSourceIncertidumbres extends DataSource {
  constructor(id_instrumento, id_calibracion, id_linea_calibracion) {
    const store = new CustomStoreIncertidumbres(id_instrumento, id_calibracion, id_linea_calibracion);
    super({
      store,
    });
  }
}
