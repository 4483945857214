import { get, post, put, remove } from "@/core/fetch";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

export class CustomStoreClasificacion extends CustomStore {
  constructor() {
    const baseUrl = `${global.API_URL}/instrumentos/clasificacion`;

    super({
      key: "id",
      loadMode: "raw",
      // cacheRawData: false,
      load: () => get(`${baseUrl}`),
      // byKey: (key) => get(`${baseUrl}/${key}`),
      insert: (values) => post(`${baseUrl}`, values),
      update: (key, values) => put(`${baseUrl}/${key}`, values),
      remove: (key) => remove(`${baseUrl}/${key}`),
    });
  }
}
export class DataSourceClasificacion extends DataSource {
  constructor() {
    const store = new CustomStoreClasificacion();
    super({
      store,
    });
  }
}

// export const store = new CustomStore({
//   key: "id",
//   loadMode: "raw",
//   // cacheRawData: true,
//   load: () => get(`${global.API_URL}/instrumentos/clasificacion`),
//   // byKey: (key) => get(`${global.API_URL}/instrumentos/clasificacion/${key}`),
//   insert: (values) => post(`${global.API_URL}/instrumentos/clasificacion`, values),
//   update: (key, values) => put(`${global.API_URL}/instrumentos/clasificacion/${key}`, values),
//   remove: (key) => remove(`${global.API_URL}/instrumentos/clasificacion/${key}`),
// });

// export const datasource = new DataSource({
//   store: store,
//   sort: "codigo",
// });

// export default datasource;
