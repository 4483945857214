var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-6"},[(true)?_c('DxChart',{ref:"graficoDistribucion",attrs:{"id":"graficoDistribucion","data-source":_vm.dataSourceDistribucion,"rotated":true},on:{"point-click":_vm.onClickDefecto}},[_c('DxTitle',{attrs:{"text":_vm.$t('distribucion'),"subtitle":{
            text: _vm.titulo,
            font: {
              size: '2em',
            },
          }}}),_c('DxCommonSeriesSettings',{attrs:{"argument-field":"defecto"}}),_c('DxCommonAxisSettings',[_c('DxGrid',{attrs:{"visible":false}})],1),_c('DxSeries',{attrs:{"value-field":"cantidad","name":"cantidad","type":"bar","color":"#fac29a"}},[_c('DxLabel',{attrs:{"visible":true}})],1),_c('DxTooltip',{attrs:{"enabled":true,"shared":true,"customize-tooltip":_vm.customizeTooltip}}),_c('DxLegend',{attrs:{"visible":false}})],1):_vm._e()],1),_c('div',{staticClass:"col-xl-6"},[(true)?_c('DxChart',{ref:"graficoFrecuencias",attrs:{"id":"graficoFrecuencias","data-source":_vm.frecuencias}},[_c('DxArgumentAxis',{attrs:{"type":"discrete"}}),_c('DxTitle',{attrs:{"text":_vm.$t('frecuencias'),"subtitle":{
            text: _vm.titulo,
            font: {
              size: '2em',
            },
          }}}),_c('DxCommonSeriesSettings',{attrs:{"argument-field":"x","type":"stackedbar"}}),_c('DxCommonAxisSettings',[_c('DxGrid',{attrs:{"visible":false}})],1),_vm._l((_vm.serieFrecuencias),function(defecto){return _c('DxSeries',{key:defecto,attrs:{"value-field":defecto,"name":defecto}})}),_c('DxLegend',{attrs:{"vertical-alignment":"bottom","horizontal-alignment":"center","item-text-position":"top"}}),_c('DxTooltip',{attrs:{"enabled":true,"customize-tooltip":_vm.customizeTooltipFrecuencias,"location":"edge"}})],2):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }