<template>
  <a href="#" class="dx-link position-relative px-1 d-block" @click="clickHandler">
    <i class="ion ion-md-attach"></i>
    <span v-if="number" class="counter">{{ number }}</span>
  </a>
</template>

<script>
export default {
  props: ["number"],
  methods: {
    clickHandler() {
      this.$emit("click");
    },
  },
};
</script>
