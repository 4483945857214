<template>
  <div id="reportINST">
    <div class="reportContent">
      <div class="d-flex justify-content-end">
        <label style="font-size: 24px">{{ empresa?.empresa }} </label>
      </div>

      <h3>{{ $t("instrumento") }}: {{ instrumento.codigo }} {{ instrumento.nombre }}</h3>
      <hr class="report-line-medium" />

      <div class="d-flex flex-row pt-2 pb-4 justify-content-between" style="gap: 50px">
        <div class="d-flex flex-column">
          <label class="form-label fw-bold">{{ $t("tipo") }}</label>
          <label>
            {{ instrumento.tipo }}-{{ dataSourceTipoInstrumento.find((x) => x.id === instrumento?.tipo)?.value }}
          </label>
        </div>
        <div class="d-flex flex-column">
          <label class="form-label fw-bold">{{ $t("estado") }}</label>
          <label>
            {{ instrumento.estado }}-{{ dataSourceEstadoInstrumento.find((x) => x.id === instrumento?.estado)?.value }}
          </label>
        </div>
        <div class="d-flex flex-column">
          <label class="form-label fw-bold">{{ $t("fecha_alta") }}</label>
          <label> {{ instrumento?.fecha_alta && $moment(instrumento?.fecha_alta).format("L") }} </label>
        </div>

        <div class="d-flex flex-column">
          <label class="form-label fw-bold">{{ $t("fecha_baja") }}</label>
          <label> {{ instrumento?.fecha_baja && $moment(instrumento?.fecha_baja).format("L") }} </label>
        </div>
      </div>

      <div class="d-flex flex-row pt-2 pb-4 justify-content-between" style="gap: 50px">
        <div class="d-flex flex-column">
          <label class="form-label fw-bold">{{ $t("serie") }}</label>
          <label> {{ instrumento.serie }} </label>
        </div>

        <div class="d-flex flex-column">
          <label class="form-label fw-bold">{{ $t("marca") }}</label>
          <label> {{ instrumento.marca }} </label>
        </div>
        <div class="d-flex flex-column">
          <label class="form-label fw-bold">{{ $t("modelo") }}</label>
          <label> {{ instrumento.modelo }} </label>
        </div>

        <div class="d-flex flex-column">
          <label class="form-label fw-bold">{{ $t("norma") }}</label>
          <label> {{ instrumento.norma }} </label>
        </div>
      </div>

      <div class="d-flex flex-row pt-2 pb-4 justify-content-between" style="gap: 50px">
        <div class="d-flex flex-column">
          <label class="form-label fw-bold">{{ $t("clasificacion") }}</label>
          <label> {{ displayClasificacion }} </label>
        </div>

        <div class="d-flex flex-column">
          <label class="form-label fw-bold">{{ $t("categoria") }}</label>
          <label> {{ displayCategoria }} </label>
        </div>
        <div class="d-flex flex-column">
          <label class="form-label fw-bold">{{ $t("departamento") }}</label>
          <label> {{ displayDepartamento }} </label>
        </div>
        <div class="d-flex flex-column">
          <label class="form-label fw-bold">{{ $t("cliente") }}</label>
          <label> {{ displayCliente }}</label>
        </div>
      </div>
      <div class="d-flex flex-row pt-2 pb-4" style="gap: 50px">
        <div class="d-flex flex-column">
          <label class="form-label fw-bold">{{ $t("proveedor") }}</label>
          <label> {{ displayProveedor }}</label>
        </div>

        <div class="d-flex flex-column">
          <label class="form-label fw-bold">{{ $t("fecha_compra") }}</label>
          <label> {{ instrumento?.fecha_compra && $moment(instrumento?.fecha_compra).format("L") }} </label>
        </div>
        <div class="d-flex flex-column">
          <label class="form-label fw-bold">{{ $t("precio") }}</label>
          <!-- <label> {{ instrumento.precio }} </label> -->
          <label> {{ displayPrecio }} </label>
        </div>
      </div>

      <div class="d-flex flex-row pt-2 pb-4" style="gap: 50px">
        <!-- <div class="d-flex flex-column">
          <label class="form-label fw-bold">{{ $t("cadencia_calibracion") }}</label>
          <label> {{ instrumento.cadencia_calibracion }} </label>
        </div> -->

        <div class="d-flex flex-column">
          <label class="form-label fw-bold">{{ $t("frecuencia_cadencia_calibracion") }}</label>
          <label>
            {{ instrumento.frecuencia_calibracion }}
            {{ dataSourceCadenciasInstrumento.find((x) => x.id === instrumento?.cadencia_calibracion)?.value }}
          </label>
        </div>
      </div>

      <div class="d-flex flex-row pt-2 pb-4 justify-content-between" style="gap: 50px">
        <div class="d-flex flex-column">
          <label class="form-label fw-bold">{{ $t("cota_minima") }}</label>
          <label> {{ instrumento.cota_minima }} </label>
        </div>

        <div class="d-flex flex-column">
          <label class="form-label fw-bold">{{ $t("cota_maxima") }}</label>
          <label> {{ instrumento.cota_maxima }} </label>
        </div>
        <div class="d-flex flex-column">
          <label class="form-label fw-bold">{{ $t("precision_elemento") }}</label>
          <label> {{ instrumento.precision_elemento }} </label>
        </div>

        <div class="d-flex flex-column">
          <label class="form-label fw-bold">{{ $t("precision_proceso") }}</label>
          <label> {{ instrumento.precision_proceso }} </label>
        </div>
      </div>
      <!-- <th>{{ $t("ubicaciones") }}</th>   -->
      <!-- <label class="form-label fw-bold">{{ $t("ubicaciones") }}</label>
      <br />

      <table>
        <tbody>
          <tr v-for="(ubi, id) in ubicaciones" :key="id">
            <td>{{ ubi.id_ubicaciones }}</td>
          </tr>
        </tbody>
      </table> -->

      <!-- <th>{{ $t("ultima_calibracion") }}</th>{{instrumento.ultima_calibracion}} -->
      <br />
      <!-- Última verificación -->
      <br />
      <div class="d-flex flex-column">
        <label class="form-label fw-bold">{{ $t("notas") }}</label>
        <label> {{ instrumento.notas }} </label>
      </div>
      <br />
      <br />
      <br />

      <h4>{{ $t("caracteristicas_a_calibrar") }}</h4>

      <hr class="report-line-small" />
      <table>
        <thead>
          <tr>
            <th>{{ $t("caracteristica") }}</th>
            <th>{{ $t("metodo") }}</th>
            <th>{{ $t("patron") }}</th>
            <th>{{ $t("procedimiento") }}</th>
            <th>{{ $t("valor_pedido") }}</th>
            <th>{{ $t("valor_teorico") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(carac, id) in caracteristicasCalib" :key="id">
            <!-- <td>{{ carac.activa}}</td> -->
            <td>{{ carac.caracteristica }}</td>
            <td>{{ carac.metodo }}</td>
            <td>{{ carac.patron }}</td>
            <td>{{ carac.procedimiento }}</td>
            <td>{{ carac.valor_pedido }}</td>
            <td>{{ carac.valor_teorico }}</td>
          </tr>
        </tbody>
      </table>
      <!-- <th>{{ $t("caracteristicas_a_verificar") }}</th> -->
      <!-- <hr class="report-line-small" /> -->
    </div>
  </div>
</template>

<script>
// import Debug from '@/components/core/Debug.vue'
import { get } from "@/core/fetch.js";
import jspdf from "jspdf";
//import html2canvas from "html2canvas";

import tipos from "@/data/instrumentos/Tipos";
import estados from "@/data/instrumentos/Estados";
import cadencias from "@/data/instrumentos/Cadencias";

export default {
  props: ["idInstrumento"],

  data() {
    return {
      instrumento: {},
      caracteristicasCalib: [],
      clasificacion: {},
      ubicaciones: [],
      empresa: {},

      displayClasificacion: "",
      displayProveedor: "",
      displayCliente: "",
      displayCategoria: "",
      displayDepartamento: "",

      loaded: false,
    };
  },

  async mounted() {
    this.load();
  },

  computed: {
    displayPrecio: function () {
      return this.instrumento?.precio?.toLocaleString(undefined, { style: "currency", currency: "EUR" });
    },
    dataSourceTipoInstrumento() {
      return tipos.map((e) => ({
        ...e,
        value: this.$t(e.value),
      }));
    },
    dataSourceEstadoInstrumento() {
      return estados.map((e) => ({
        ...e,
        value: this.$t(e.value),
      }));
    },

    dataSourceCadenciasInstrumento() {
      return cadencias.map((e) => ({
        ...e,
        value: this.$t(e.value),
      }));
    },

    idioma: function () {
      return this.$i18n.locale;
    },
  },
  methods: {
    async load() {
      // console.log in red:
      console.log("%c load", "color: red; font-size: 20px");

      const urls = [
        `${global.API_URL}/instrumentos/${this.$props.idInstrumento}`,
        `${global.API_URL}/instrumentos/${this.$props.idInstrumento}/caracteristicas/calibracion`,
        // `${global.API_URL}/instrumentos/${this.$props.idInstrumento}/caracteristicas/verificacion`,
        `${global.API_URL}/instrumentos/${this.$props.idInstrumento}/ubicaciones`,
        `${global.API_URL}/configuracion`,
      ];
      //obtengo todos los fetch en paralelo
      const data = await Promise.all(urls.map((u) => get(u)));

      this.instrumento = data[0];
      this.caracteristicasCalib = data[1];
      this.ubicaciones = data[2];
      this.empresa = data[3][0];

      const urlsDisplay = [
        `${global.API_URL}/instrumentos/clasificacion/${this.instrumento.id_clasificacion}`,
        `${global.API_URL}/clientes/${this.instrumento.id_clientes}`,
        `${global.API_URL}/proveedores/${this.instrumento.id_proveedores}`,
        `${global.API_URL}/departamentos/${this.instrumento.id_departamentos}`,
        `${global.API_URL}/instrumentos/categorias/${this.instrumento.id_categorias}`,
      ];

      // const dataDisplay = await Promise.all(urlsDisplay.map((u) => get(u)));
      const dataDisplay = await Promise.allSettled(urlsDisplay.map((u) => get(u))).catch((error) => {
        console.log(error);
        this.$notify(`Ups: ${error}`, "danger");
      });

      this.displayClasificacion = dataDisplay[0]?.value?.nombre;
      this.displayCliente = dataDisplay[1]?.value?.nombre;
      this.displayProveedor = dataDisplay[2]?.value?.descripcion;
      this.displayDepartamento = dataDisplay[3]?.value?.descripcion;
      this.displayCategoria = dataDisplay[4]?.value?.categoria;

      this.$nextTick(() => {
        // this.print();
      });

      this.loaded = true;
    },

    print: function () {
      this.$notify("Obteniendo datos", "warning", 5000);

      // wait until loaded is true
      if (!this.loaded) {
        setTimeout(() => {
          this.print();
        }, 100);
        return;
      }

      this.$notify("Generando el documento", "warning", 5000);

      const doc = new jspdf({ unit: "px", hotfixes: ["px_scaling"] });

      doc.html(this.$el.innerHTML, {
        callback: (doc) => {
          doc.save("Inst_" + this.instrumento.codigo + ".pdf");
          // window.open(doc.output("bloburl"), "_blank");
          this.$emit("printed");
        },
      });

      // this.loaded = true;
    },
  },
};
</script>

<style>
.report-caption-top {
  color: #aaa;
  margin-bottom: 1px;
  font-size: 10px;
}

.report-caption-bottom {
  color: #aaa;
  margin-top: 1px;
  font-size: 10px;
}

.report-label {
  font-size: 12px;
}

.report-line-small {
  height: 1px;
  border-width: 0;
  background-color: gray;
  margin: 0;
}

.report-line-medium {
  height: 2px;
  border-width: 0;
  background-color: gray;
  margin: 0;
}

.report-line-large {
  height: 3px;
  border-width: 0;
  background-color: gray;
  margin: 0;
}

.reportContent {
  width: 19cm;
  padding: 0.5cm;
  /* background-color: green; */
  margin: 1cm;
  box-sizing: border-box;
}

table tr {
  padding: 0.5em;
}

table tr td,
table tr th {
  padding: 0.5em;
}
</style>
