import { get, post, put, remove } from "@/core/fetch";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

export class ResponsablesCustomStore extends CustomStore {
  #data = {};

  constructor() {
    const baseUrl = `${global.API_URL}/responsables`;
    super({
      key: "id",
      loadMode: "raw",
      load: () => get(`${baseUrl}`),
      byKey: (key) => get(`${baseUrl}/${key}`),
      insert: (values) => post(`${baseUrl}`, values),
      update: (key, values) => put(`${baseUrl}/${key}`, values),
      remove: (key) => remove(`${baseUrl}/${key}`),

      onLoaded: (result) => {
        // Convertimos responsables en un diccionario para que el acceso sea de O(1) en lugar de O(n) (Array.find...)
        this.#data = result.reduce((a, x) => ({ ...a, [x.id]: x }), {});
      },
    });
  }

  getResponsable = (key) => this.#data[key];
}

export class ResponsablesDataSource extends DataSource {
  constructor() {
    const store = new ResponsablesCustomStore();
    super({
      store,
    });
  }
}
