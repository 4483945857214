<template>
    <span>
        <img v-if="value?.img" :src="require(`@/assets/${value?.img}`)" :alt="value?.value" height="24" />
        <span class="mx-3">{{ value?.value }}</span>
    </span>
</template>

<script>
export default {
    props: [
        "value",
    ]
}
</script>

<style scoped>
img {
    margin-top: -8px;
    margin-left: -8px;
    margin-bottom: -4px;
}

span {
    overflow: hidden;
}
</style>