<!-- eslint-disabled -->
<template>
  <div :key="componentKey">
    <DxPopup :title="$t('Cuidado!')" :visible.sync="isPopupDeleteVisible" width="300px" height="auto">
      <DxScrollView height="300">
        ¿Estás seguro que quieres borrar los siguientes elementos?

        <ul class="list-to-delete">
          <li v-for="key in this.selectedAnalisis" :key="key">{{ key }}</li>
        </ul>
      </DxScrollView>

      <DxToolbarItem :options="toolbarItemCancelarOptions" widget="dxButton" location="after" toolbar="bottom" />
      <DxToolbarItem :options="toolbarItemConfirmarOptions" widget="dxButton" location="after" toolbar="bottom" />
    </DxPopup>

    <h2 class="content-block">Analisis</h2>
    <div class="content-block">
      <div class="dx-card">
        <!-- <h1>Añadir toolbar: - imprimir plan (exportar grid) - duplicar plan</h1> -->

        <code class="d-block m-0 p-3" v-if="$store.state.debug"> analisis seleccionadas: {{ selectedAnalisis }} </code>

        <DxDataGrid
          :ref="gridRefName"
          id="gridAnalisis"
          class="dx-card wide-card"
          :data-source="customStoreAnalisis"
          :hover-state-enabled="true"
          :selection="{ mode: 'multiple' }"
          :height="gridHeight"
          width="100%"
          :paging="{
            enabled: true,
          }"
          :allow-column-resizing="true"
          :allow-column-reordering="true"
          @selection-changed="onSelectionChanged"
          @rowDblClick="onRowDblClick"
          @exporting="onExporting"
          :filter-value="filterValue"
        >
          <DxEditingGrid :allow-deleting="true" />
          <DxStateStoring :enabled="true" storage-key="grid-analisis"> </DxStateStoring>
          <DxSearchPanel :visible="true" :width="240" />
          <DxFilterRow :visible="true"></DxFilterRow>
          <DxFilterPanel :visible="true"></DxFilterPanel>
          <DxGroupPanel :visible="true" />
          <DxGrouping :auto-expand-all="true" />

          <DxLoadPanel :enabled="true" />

          <!-- Para mostrar todos lo elementos: -->
          <!-- <DxScrolling mode="standard" /> -->
          <!-- <DxScrolling mode="virtual" /> -->
          <DxScrolling mode="standard" />

          <DxExport :enabled="true" :formats="['pdf']" />

          <DxToolbar>
            <DxItem location="before" name="groupPanel"></DxItem>
            <DxItem location="after" name="exportButton"></DxItem>
            <DxItem location="after" name="addRowButton"></DxItem>
            <DxItem location="after" name="show" template="showSelectionTemplate"> </DxItem>
            <DxItem location="after" name="hide" template="hideSelectionTemplate"> </DxItem>
            <DxItem location="after" name="delete" template="deleteSelectionTemplate"> </DxItem>
            <DxItem location="after" name="refresh" template="refreshTemplate"> </DxItem>
            <DxItem location="after" name="searchPanel"></DxItem>
          </DxToolbar>

          <DxColumn
            data-type="number"
            data-field="id"
            :caption="$t('numero')"
            width="80"
            sort-order="desc"
            :allowEditing="false"
          />

          <DxColumn
            data-field="analisis_fecha"
            :caption="$t('fecha')"
            data-type="datetime"
            cell-template="fechaTemplate"
          ></DxColumn>

          <template #fechaTemplate="{ data }">
            <div>
              {{ getFecha(new Date(data.value)) }}
              <!-- <br />
                                  {{ getHora(new Date(data.value)) }} <br />
                                  {{ getTime(new Date(data.value)) }} <br /> -->
            </div>
          </template>

          <DxColumn data-field="analisis_valor" :caption="$t('valor')" data-type="number"></DxColumn>
          <DxColumn
            data-field="analisis_total_defectuosas"
            :caption="$t('total_defectuosas')"
            data-type="number"
          ></DxColumn>

          <DxColumn :caption="$t('caracteristica')">
            <DxColumn data-field="caracteristicas_nombre" :caption="$t('nombre')" data-type="string"></DxColumn>
            <DxColumn
              name="caracteristicas_id_metodoanalisis_tipo"
              data-field="caracteristicas_id_metodoanalisis"
              data-type="number"
              :caption="$t('tipo')"
            >
              <DxLookup display-expr="tipo" value-expr="id" :data-source="dataSourceMetodoAnalisis"> </DxLookup>
            </DxColumn>

            <DxColumn
              data-type="number"
              name="caracteristicas_id_metodoanalisis_metodo"
              data-field="caracteristicas_id_metodoanalisis"
              :caption="$t('metodoAnalisis')"
            >
              <DxLookup display-expr="codigo" value-expr="id" :data-source="dataSourceMetodoAnalisis"> </DxLookup>
            </DxColumn>
          </DxColumn>

          <DxColumn :caption="$t('estructura')">
            <DxColumn
              data-type="string"
              data-field="pcestructura_descripcion"
              width="100"
              :caption="$t('fase')"
              cell-template="faseTemplate"
            >
            </DxColumn>
          </DxColumn>

          <template #faseTemplate="{ data }">
            <div>
              <!-- <img v-if="data.data.pcestructura_tipo" :src="require(`@/assets/tipos/${data.data.pcestructura_tipo}.png`)" /> -->
              {{ data.value }}
            </div>
          </template>

          <DxColumn :caption="$t('planControl')">
            <DxColumn data-field="plancontrol_referencia" :caption="$t('referencia')"> </DxColumn>

            <DxColumn data-field="plancontrol_nombre" :caption="$t('nombre')"> </DxColumn>

            <DxColumn
              data-field="plancontrol_situacion"
              width="150"
              :caption="$t('situacion')"
              cell-template="situacion"
            >
              <DxRequiredRule />
              <DxLookup width="320" display-expr="value" value-expr="id" :data-source="dataSourceSituaciones">
              </DxLookup>
            </DxColumn>
          </DxColumn>

          <template #situacion="{ data }">
            <div>
              <img
                :src="require(`@/assets/situaciones/${dataSourceSituaciones[data.value].img}`)"
                height="24"
                :title="dataSourceSituaciones[data.value].value"
              />
            </div>
          </template>

          <template #deleteSelectionTemplate>
            <DxButton
              icon="trash"
              stylingMode="text"
              :disabled="selectedAnalisis.length === 0"
              @click="deleteSelectedAnalisisClick"
            ></DxButton>
          </template>

          <template #showSelectionTemplate>
            <DxButton
              icon="fa fa-eye"
              stylingMode="text"
              :disabled="selectedAnalisis.length === 0"
              @click="showSelectedAnalisisClick"
            ></DxButton>
          </template>
          <template #hideSelectionTemplate>
            <DxButton
              icon="fa fa-eye-slash"
              stylingMode="text"
              :disabled="selectedAnalisis.length === 0"
              @click="hideSelectedAnalisisClick"
            ></DxButton>
          </template>

          <template #refreshTemplate>
            <DxButton icon="refresh" @click="refreshDataGrid"></DxButton>
          </template>

          <DxColumn data-field="analisis_ocultar" :caption="$t('ocultar')"> </DxColumn>

          <DxColumn data-field="analisis_rechazo" :caption="$t('rechazo')"> </DxColumn>
        </DxDataGrid>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxLookup,
  DxScrolling,
  DxLoadPanel,
  DxFilterRow,
  DxSearchPanel,
  DxEditing as DxEditingGrid,
  DxFilterPanel,
  DxGroupPanel,
  DxGrouping,
  DxExport,
  DxToolbar,
  DxItem,
  DxStateStoring,
} from "devextreme-vue/data-grid";

import { DxRequiredRule } from "devextreme-vue/validator";
import { DxButton } from "devextreme-vue/button";

import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";

import { DxScrollView } from "devextreme-vue/scroll-view";

import { jsPDF } from "jspdf";
import { exportDataGrid } from "devextreme/pdf_exporter";

import CustomStore from "devextreme/data/custom_store";

import auth from "../auth";

import situaciones from "@/data/Situaciones";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxLookup,
    DxScrolling,
    DxLoadPanel,
    DxFilterRow,
    DxSearchPanel,
    DxEditingGrid,
    DxFilterPanel,
    DxRequiredRule,
    DxGroupPanel,
    DxGrouping,
    // DxSwitch,
    DxExport,
    DxToolbar,
    DxItem,
    DxButton,
    //
    DxPopup,
    DxToolbarItem,
    DxScrollView,
    DxStateStoring,
  },

  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },

      customStoreAnalisis: new CustomStore({
        key: "id",
        load: () => this.$fetch.get(`${global.API_URL}/analisis/raw`),
        byKey: (key) => this.$fetch.get(`${global.API_URL}/analisis/${key}`),
        // insert: (values) =>
        //   this.$fetch.post(`${global.API_URL}/planes/`, values),
        // update: (key, values) =>
        //   this.$fetch.put(`${global.API_URL}/planes/${key}`, values),
        remove: (key) => this.$fetch.delete(`${global.API_URL}/analisis/${key}`),
      }),

      dataSourceMetodoAnalisis: [],
      selectedAnalisis: [],
      componentKey: 0,
      gridRefName: "gridAnalisis",

      filterValue: [],

      isPopupDeleteVisible: false,
    };
  },

  computed: {
    gridHeight() {
      //return window.innerHeight - 300;
      return this.window.height - 300;
    },
    idioma() {
      return this.$i18n.locale;
    },
    dataSourceSituaciones() {
      return situaciones.map((e) => ({
        ...e,
        value: this.$t(e.value),
      }));
    },

    //Necesito que esté en computed para que el idioma lo pueda modificar
    toolbarItemCancelarOptions: function () {
      return {
        text: this.$t("cancelar"),
        type: "normal",
        onClick: () => (this.isPopupDeleteVisible = false),
      };
    },

    //Necesito que esté en computed para que el idioma lo pueda modificar
    toolbarItemConfirmarOptions: function () {
      return {
        text: this.$t("eliminar"),
        stylingMode: "contained",
        type: "danger",
        // no puedo usar useSubmitBehavior porque estoy fuera del form...
        // useSubmitBehavior: true,
        onClick: this.confirmDeleteSelectedAnalisisClick,
        validationGroup: this.resourceURI,
      };
    },
  },

  methods: {
    itemTypeExpr() {
      // console.log(obj, this.dataSourceDiagram);
      // if (this.dataSourceDiagram.length === 0) return "rectangle";
      // return "shape" + obj.id;
      return "customShape";
    },
    onSelectionChanged(e) {
      console.log("-------- selection changed!", e.selectedRowKeys);
      //this.$emit("update:selectedRow", e.selectedRowsData[0]);
      // this.$router.push({
      //   name: "plan",
      //   params: { id: e.selectedRowsData[0].id },
      // });

      this.selectedAnalisis = e.selectedRowKeys;
    },

    onRowDblClick(e) {
      console.log("-------- double click", e);

      if (e.isNewRow) return;
      if (e.isEditing) return;

      //this.$emit("update:selectedRow", e.selectedRowsData[0]);
      // this.$router.push({
      //   name: "plan",
      //   params: { id: e.key },
      // });
    },

    async load() {
      console.log("load!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      const urls = [
        // `${global.API_URL}/planes`,
        `${global.API_URL}/analisis/metodos`,
      ];

      //obtengo todos los fetch en paralelo
      const responses = await Promise.all(
        urls.map((u) =>
          fetch(u, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + auth.getToken(),
            },
          })
        )
      );

      const data = await Promise.all(responses.map((r) => r.json()));
      this.dataSourceMetodoAnalisis = data[0];

      // this.dataSourcePlanes = data[0];
    },

    getTime(date) {
      return this.$moment(date).fromNow();
    },

    getFecha(date) {
      return this.$moment(date).format("L");
    },

    getHora(date) {
      return this.$moment(date).format("LTS");
    },

    handleResize() {
      // console.log(window.innerWidth, window.innerWidth);
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },

    displayExprCliente(item) {
      if (item.nombre && item.codigo) return `${item.codigo} - ${item.nombre}`;
      if (item.nombre) return item.nombre;
      if (item.codigo) return item.codigo;
    },
    displayExprAgrupacion(item) {
      if (item.nombre && item.codigo) return `${item.codigo} - ${item.nombre}`;
      if (item.nombre) return item.nombre;
      if (item.codigo) return item.codigo;
    },
    async onExporting(e) {
      const doc = new jsPDF();
      await exportDataGrid({
        jsPDFDocument: doc,
        component: e.component,
        indent: 5,
      });
      doc.save("Planes.pdf");
    },

    duplicateControlPlan() {
      this.isPopupDuplicateVisible = true;
    },

    refreshDataGrid() {
      this.$refs[this.gridRefName].instance.refresh();
    },

    async hideSelectedAnalisisClick() {
      if (this.selectedAnalisis === undefined) return console.log("Error, no hay analisis seleccionado");

      const values = this.selectedAnalisis; // this.selectedAnalisis.map(e => e.id);
      await this.$fetch.post(`${global.API_URL}/analisis/hide`, { ids: values });
      this.$refs[this.gridRefName].instance.refresh();
    },

    async showSelectedAnalisisClick() {
      if (this.selectedAnalisis === undefined) return console.log("Error, no hay analisis seleccionado");

      const values = this.selectedAnalisis; // this.selectedAnalisis.map(e => e.id);
      await this.$fetch.post(`${global.API_URL}/analisis/show`, { ids: values });
      this.$refs[this.gridRefName].instance.refresh();
    },

    deleteSelectedAnalisisClick() {
      if (this.selectedAnalisis === undefined) return console.log("Error, no hay analisis seleccionado");
      // preguntar si quiere borrar!
      this.isPopupDeleteVisible = true;
    },

    async confirmDeleteSelectedAnalisisClick() {
      console.log("A borrar!");
      const values = this.selectedAnalisis; // this.selectedAnalisis.map(e => e.id);
      try {
        await this.$fetch.post(`${global.API_URL}/analisis/remove`, { ids: values });

        this.isPopupDeleteVisible = false;
        this.$refs[this.gridRefName].instance.refresh();
      } catch (ex) {
        console.log("ha ocurrido algo inesperado.", ex);
      }
    },
  },

  created() {
    console.log("created");
    this.load();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  beforeCreate() {},

  mounted() {
    const now = new Date();
    const dateWithMonthsSubstracted = new Date(now.setMonth(now.getMonth() - 1));
    this.filterValue = [
      ["plancontrol_situacion", "=", 2],
      "and",
      ["analisis_fecha", ">", dateWithMonthsSubstracted],
      "and",
      ["analisis_ocultar", "=", false],
      "and",
      ["analisis_rechazo", "=", true],
    ];
  },

  watch: {
    idioma() {
      // this.componentKey++;
    },
  },
};
</script>

<style lang="scss">
.list-to-delete {
  padding-top: 3em;
  padding-bottom: 3em;
  // max-height: 300px;
  // overflow-x: auto;
}
</style>
