import { get, put, remove } from "@/core/fetch";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

export class CustomStoreLineasVerificacion extends CustomStore {
  constructor(id_instrumento, id_verificacion) {
    const baseUrl = `${global.API_URL}/instrumentos/${id_instrumento}/verificaciones/${id_verificacion}/lineas`;
    super({
      key: "id",
      loadMode: "raw",
      load: () => get(`${baseUrl}`),
      byKey: (key) => get(`${baseUrl}/${key}`),
      update: (key, values) => put(`${baseUrl}/${key}`, values),
      remove: (key) => remove(`${baseUrl}/${key}`),
    });
  }
}

/*
Hay un problema con el lookup...
Cuando es nulo el resultado no puedo filtrar por este,
ya que considera como filtro borrado y muestra todos los resultados.
Hacemos un postProceso de los datos y reemplazamos los nulos por -1000
así podemos pedir filtrar por un -1000 y mostrar los registros
con resultado "null"
*/
const desnullify = (data) => {
  console.log("postProcess", data);

  return data.map((item) => ({
    ...item,
    resultado: item.resultado ?? -1000,
  }));
};

// /**
//  *
//  * @param {*} id_instrumento
//  * @param {*} id_verificacion
//  * @deprecated Use `CustomStoreLineasVerificacion` instead.
//  * @returns
//  */
// export const CreateCustomStoreLineasVerificacion = (id_instrumento, id_verificacion) => {
//   const baseUrl = `${global.API_URL}/instrumentos/${id_instrumento}/verificaciones/${id_verificacion}/lineas`;
//   return new CustomStore({
//     key: "id",
//     loadMode: "raw",
//     load: () => get(`${baseUrl}`),
//     byKey: (key) => get(`${baseUrl}/${key}`),
//     insert: (values) => post(`${baseUrl}`, values),
//     update: (key, values) => put(`${baseUrl}/${key}`, values),
//     remove: (key) => remove(`${baseUrl}/${key}`),
//   });
// };

export class DataSourceLineasVerificacion extends DataSource {
  constructor(id_instrumento, id_verificacion) {
    const store = new CustomStoreLineasVerificacion(id_instrumento, id_verificacion);
    super({
      store,
      postProcess: desnullify,
    });
  }
}

// /**
//  *
//  * @param {*} id_instrumento
//  * @param {*} id_verificacion
//  * @deprecated Use `DataSourceLineasVerificacion` instead.
//  * @returns
//  */
// export const CreateDataSourceLineasVerificacion = (id_instrumento, id_verificacion) => {
//   return new DataSource({
//     source: CreateCustomStoreLineasVerificacion(id_instrumento, id_verificacion),
//   });
// };

// // const baseUrl = `${global.API_URL}/instrumentos/${id_instrumento}}/verificaciones/${id_verificacion}/lineas`;

// // export const store = new CustomStore({
// //   key: "id",
// //   loadMode: "raw",
// //   load: () => get(`${baseUrl}`),
// //   byKey: (key) => get(`${baseUrl}/${key}`),
// //   update: (key, values) => put(`${baseUrl}/${key}`, values),
// //   remove: (key) => remove(`${baseUrl}/${key}`),
// // });

// // export const datasource = new DataSource({
// //   store: store,
// // });

// // export default datasource;
