<template>
  <div :key="componentKey">
    <DxPopup
      :title="titulo"
      :visible.sync="isPopupVisible"
      width="80%"
      height="auto"
      @shown="onShown"
      :showTitle="showTitle"
    >
      <template>
        <!-- <span>{{ pregunta }}</span> -->
        <h6 class="pl-1 pt-3 pb-2">{{ pregunta }}</h6>
        <DxValidationGroup ref="validationGroup" name="vprompt">
          <DxTextBox ref="respuesta" v-model="respuesta" :placeholder="pregunta" @key-up="this.onKeyUp">
            <DxValidator ref="respuestaValidator">
              <DxRequiredRule :message="$t('errorValorRequerido')" />
            </DxValidator>
          </DxTextBox>
        </DxValidationGroup>
      </template>

      <DxToolbarItem :options="toolbarItemCancelarOptions" widget="dxButton" location="after" toolbar="bottom" />

      <DxToolbarItem :options="toolbarItemGuardarOptions" widget="dxButton" location="after" toolbar="bottom" />

      <!-- <DxToolbarItem
        :options="{
          text: this.$t('cancelar'),
          type: 'normal',
          onClick: this.onClickCancelar,
        }"
        widget="dxButton"
        location="after"
        toolbar="bottom"
      /> -->

      <!-- <DxToolbarItem
        :options="{
          text: this.$t('guardar'),
          stylingMode: 'contained',
          // useSubmitBehavior: true,
          type: 'default',
          onClick: this.onClickAceptar,
        }"
        widget="dxButton"
        location="after"
        toolbar="bottom"
      /> -->
    </DxPopup>
  </div>
</template>

<script>
import DxTextBox from "devextreme-vue/text-box";
import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";
import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";
import { DxValidationGroup } from "devextreme-vue/validation-group";
export default {
  props: {
    showTitle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      componentKey: 0,
      isPopupVisible: false,
      titulo: "",
      pregunta: "",
      respuesta: "",
      resolve: null,
      reject: null,
    };
  },
  computed: {
    toolbarItemCancelarOptions: function () {
      return {
        text: this.$t("cancelar"),
        type: "normal",
        onClick: this.onClickCancelar,
      };
    },

    toolbarItemGuardarOptions: function () {
      return {
        text: this.$t("guardar"),
        stylingMode: "contained",
        type: "default",
        onClick: this.onClickAceptar,
      };
    },
    idioma() {
      // return this.$store.state.idioma;
      return this.$i18n.locale;
    },
  },
  components: {
    DxPopup,
    DxToolbarItem,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationGroup,
  },
  watch: {
    idioma() {
      this.componentKey++;
    },
  },
  methods: {
    prompt(titulo, pregunta, respuesta) {
      this.isPopupVisible = true;
      if (respuesta) this.respuesta = respuesta;
      this.titulo = titulo;
      this.pregunta = pregunta;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    onShown() {
      this.$refs.respuesta.$el.querySelector("input").select();
    },

    onKeyUp(e) {
      e.event.keyCode === 13 && this.onClickAceptar(e);
      e.event.keyCode === 27 && this.onClickCancelar(e);
    },

    onClickCancelar() {
      this.reject();
      this.isPopupVisible = false;
      this.$refs.respuestaValidator.instance.reset();
    },

    showInputError() {
      this.$refs.respuesta.instance.isValid = false;
    },

    onClickAceptar() {
      const validationGroup = this.$refs.validationGroup.instance;

      if (validationGroup.validate().isValid) {
        this.resolve(this.respuesta);
        this.isPopupVisible = false;
        this.$refs.respuestaValidator.instance.reset();
      }
    },
  },
};
</script>

<style scoped></style>
