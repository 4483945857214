export default [
    {
        id: "P",
        //value: this.$t("patron"),
        value: "patron",
        img: "instrumentos/tipos/p.png",
    },
    {
        id: "I",
        // value: this.$t("instrumento"),
        value: "instrumento",
        img: "instrumentos/tipos/i.png",
    },
    {
        id: "E",
        // value: this.$t("equipoMedicion"),
        value: "equipoMedicion",
        img: "instrumentos/tipos/e.png",
    },
];
