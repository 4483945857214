import { get, post } from "@/core/fetch";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

/*
 * Esta clase la he creado para no utilizar el CustomStore o el DataSource.
 * Necesitaba obtener un error vía throw y no ha habido manera.
 * Estudiar si se necesita un refactor
 */

export class ProfileApi {
  constructor() {
    this.baseUrl = `${global.API_URL}/profile`;
  }
  async get() {
    return await get(`${this.baseUrl}`);
  }
  async post(values) {
    return await post(`${this.baseUrl}`, values);
  }
}

export class ProfileCustomStore extends CustomStore {
  constructor() {
    const baseUrl = `${global.API_URL}/profile`;
    super({
      key: "id",
      // loadMode: "raw", //<- si pongo "raw" devuelve un error al hacer dataRaw.slice
      load: async () => await get(`${baseUrl}`),
      // byKey: (key) => get(`${baseUrl}/${key}`),
      insert: (values) => post(`${baseUrl}`, values),
      // update: (key, values) => put(`${baseUrl}/${key}`, values),
      // remove: (key) => remove(`${baseUrl}/${key}`),
    });
  }
}

export class ProfileDataSource extends DataSource {
  constructor() {
    const store = new ProfileCustomStore();
    super({
      store,
    });
  }
}
