import { get, post, put, remove } from "@/core/fetch";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

export class MetodosEvaluacionCustomStore extends CustomStore {
  constructor() {
    const baseUrl = `${global.API_URL}/caracteristicas/metodoevaluacion`;

    super({
      key: "id",
      loadMode: "raw",
      // cacheRawData: false,
      load: () => get(`${baseUrl}`),
      byKey: (key) => get(`${baseUrl}/${key}`),
      insert: (values) => post(`${baseUrl}`, values),
      update: (key, values) => put(`${baseUrl}/${key}`, values),
      remove: (key) => remove(`${baseUrl}/${key}`),
    });
  }
}

export class MetodosEvaluacionDataSource extends DataSource {
  constructor(options = {}) {
    const store = new MetodosEvaluacionCustomStore();
    // @TODO: abrir la puerta a posibles configuraciones del datasource.
    options.store = store;
    super(options);
  }
}
