<template>
  <div v-if="resourceURI">
    <Debug>{{ resourceURI }}</Debug>

    <div class="d-flex flex-horizontal justify-content-between">
      <div v-show="!showGallery" class="p-3 w-100 bg-gray align-self-center">
        {{ $t("no_fotos_disponibles") }}
      </div>
      <div>
        <div v-if="selectedImage">
          <h3>{{ selectedImage.nombre }}</h3>
          <h4>{{ selectedImage.descripcion }}</h4>
        </div>
      </div>
      <div class="p-3" v-if="isAddButtonVisible">
        <DxButton icon="add" @click="onClickAdd"></DxButton>
      </div>
    </div>

    <DxPopup :title="$t('subirImagen')" :visible.sync="isPopupVisible" width="300px" height="auto">
      <form id="uploadForm" ref="uploadForm" name="uploadForm" @submit.prevent="onSubmitUploadForm">
        <input type="hidden" name="tipo" value="IMG" />

        <div class="d-flex flex-column" style="gap: 10px">
          <div class="d-flex flex-column plan nombre">
            <label class="form-label">{{ $t("nombre") }}*</label>
            <div class="form-data">
              <DxTextBox width="100%" name="nombre">
                <DxValidator ref="vNombre" :validationGroup="this.resourceURI">
                  <DxRequiredRule :message="$t('errorNombreRequerido')" />
                </DxValidator>
              </DxTextBox>
            </div>
            <div class="form-text text-muted">{{ $t("nombreFichero") }}</div>
          </div>

          <div class="d-flex flex-column plan descripcion">
            <label class="form-label">{{ $t("descripcion") }}*</label>
            <div class="form-data">
              <DxTextBox width="100%" name="descripcion">
                <DxValidator ref="vDescripcion" :validationGroup="this.resourceURI">
                  <DxRequiredRule :message="$t('errorDescripcionRequerida')" />
                </DxValidator>
              </DxTextBox>
            </div>
            <div class="form-text text-muted">{{ $t("descripcionFichero") }}</div>
          </div>
        </div>

        <div class="fileuploader-container text-center">
          <DxFileUploader
            name="fichero"
            ref="fichero"
            :select-button-text="$t('seleccionarFoto')"
            label-text=""
            accept="image/*"
            upload-mode="useForm"
          />
        </div>

        <!-- <input type="file" name="fichero" /> -->

        <!-- <DxButton
          icon="add"
          type="default"
          :useSubmitBehavior="true"
          :validationGroup="this.resourceURI"
        ></DxButton> -->
      </form>

      <DxToolbarItem :options="toolbarItemCancelarOptions" widget="dxButton" location="after" toolbar="bottom" />

      <DxToolbarItem :options="toolbarItemGuardarOptions" widget="dxButton" location="after" toolbar="bottom" />
    </DxPopup>

    <div class="gallery" v-show="showGallery">
      <DxGallery
        ref="gallery"
        :data-source="attachedSource"
        :selectedItem.sync="selectedImage"
        :loop="true"
        :slideshow-delay="0"
        :show-nav-buttons="true"
        :show-indicator="true"
        :stretch-images="false"
        :height="400"
        width="100%"
        @itemContextMenu="onItemContextMenu"
      >
        <template #item="{ data }">
          <!-- <div class="dx-item-content dx-gallery-item-content position-relative"> -->
          <ImageWithAuthToken :filename="data.fichero" />
          <!-- </div> -->
        </template>
      </DxGallery>

      <div id="dummy"></div>

      <DxContextMenu
        ref="galleryContextMenu"
        target="#dummy"
        :data-source.sync="menuItems"
        @itemClick="onContextMenuItemClick"
      />

      <DxTileView
        :dataSource="attachedSource"
        :baseItemHeight="120"
        :baseItemWidth="180"
        :itemMargin="10"
        height="140"
        width="100%"
        direction="horizontal"
        @itemClick="onItemClickTileView"
        @itemContextMenu="onItemContextMenu"
      >
        <template #item="{ data }">
          <div>
            <ImageWithAuthToken :filename="data.fichero" />
          </div>
        </template>
      </DxTileView>
    </div>
  </div>
</template>

<script>
import { DxGallery } from "devextreme-vue/gallery";
import { DxTileView } from "devextreme-vue/tile-view";
import { DxButton } from "devextreme-vue/button";

import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";

import { DxFileUploader } from "devextreme-vue/file-uploader";
import { DxTextBox } from "devextreme-vue/text-box";

import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";

import { DxContextMenu } from "devextreme-vue/context-menu";

import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";

import { confirm } from "devextreme/ui/dialog";
import ImageWithAuthToken from "./core/ImageWithAuthToken.vue";

export default {
  props: ["resourceURI", "showAddButton"],
  data() {
    const attachedStore = new CustomStore({
      key: "id",

      // load: async () => {
      //   const response = await this.$fetch.get(`${global.API_URL}/${this.resourceURI}/attached?type=IMG`);
      //   const data = response.filter((x) => x.tipo === "IMG");

      //   // envio el evento loaded al padre para que sepa que ya se han cargado los datos.
      //   this.$emit("loaded", data);
      //   return data;
      // },
      load: () => this.$fetch.get(`${global.API_URL}/${this.resourceURI}/attached?type=IMG`),
      byKey: (key) => this.$fetch.get(`${global.API_URL}/${this.resourceURI}/attached/${key}`),
      insert: (values) => this.$fetch.postMultipart(`${global.API_URL}/${this.resourceURI}/attached`, values),
      update: (key, values) => this.$fetch.put(`${global.API_URL}/${this.resourceURI}/attached/${key}`, values),
      remove: (key) => this.$fetch.delete(`${global.API_URL}/${this.resourceURI}/attached/${key}`),

      // onInserted: () => {
      //   this.$emit("inserted", 1);
      // },

      // onRemoved: () => {
      //   this.$emit("removed");
      // },
    });

    const attachedSource = new DataSource({
      store: attachedStore,
      filter: ["tipo", "=", "DOC"],
    });

    return {
      isPopupVisible: false,
      selectedImage: undefined,
      isAddButtonVisible: this.$props.showAddButton ?? true,

      attachedSource: attachedSource,

      menuItems: [
        {
          id: "add",
          icon: "add",
          text: this.$t("nuevo"),
          // beginGroup: true,
        },
        {
          id: "rename",
          icon: "rename",
          text: this.$t("renombrar"),
          beginGroup: true,
        },

        {
          id: "redescription",
          icon: "rename",
          text: this.$t("cambiarLaDescripcion"),
          // beginGroup: true,
        },

        {
          id: "del",
          icon: "trash",
          text: this.$t("eliminar"),
          beginGroup: true,
        },
      ],
    };
  },
  components: {
    DxGallery,
    DxTileView,
    DxButton,
    DxPopup,
    DxToolbarItem,
    DxTextBox,
    DxFileUploader,
    DxValidator,
    DxRequiredRule,
    DxContextMenu,
    ImageWithAuthToken,
  },

  methods: {
    repaint() {
      this.$refs.gallery.instance.repaint();
    },

    onItemClickTileView(e) {
      this.selectedImage = e.itemData;
    },

    onClickAdd() {
      //this.$refs.uploadForm.reset();
      this.$refs.vNombre.instance.reset();
      this.$refs.vDescripcion.instance.reset();
      this.$refs.fichero.instance.reset();

      this.isPopupVisible = true;
    },

    add() {
      this.onClickAdd();
    },

    onClickAceptar(e) {
      if (e.validationGroup.validate().isValid) {
        //this.$refs.uploadForm.submit();
        var event = new Event("submit", {
          cancelable: true,
          defaultPrevented: true,
        });
        this.$refs.uploadForm.dispatchEvent(event);
      }
    },

    onClickCancelar() {
      //reset de los controles.
      this.isPopupVisible = false;
    },

    async update() {},

    async onSubmitUploadForm(e) {
      e.preventDefault();

      let data = new FormData(e.target);
      try {
        await this.attachedSource.store().insert(data);
        // const ds = this.$refs.gallery.instance.getDataSource();
        await this.attachedSource.reload();

        this.$emit("changed", this.attachedSource.items());
        this.$refs.gallery.instance.goToItem(this.attachedSource.items().length - 1, true);

        this.isPopupVisible = false;
      } catch (ex) {
        console.error(ex);
      }
    },

    onItemContextMenu(e) {
      e.event.preventDefault();

      this.selectedImage = e.itemData;

      this.$refs.galleryContextMenu.instance.option("target", e.element);
      this.$refs.galleryContextMenu.instance.show();
    },

    async rename(prop, title, text) {
      let aux = {};
      try {
        aux[prop] = await this.$prompt(title, text, this.selectedImage[prop]);
        await this.attachedSource.store().update(this.selectedImage.id, aux);
        this.selectedImage[prop] = aux[prop];

        this.$emit("changed", this.attachedSource.items());
      } catch (ex) {
        // se ha cancelado el prompt
      }
    },

    async onContextMenuItemClick(e) {
      switch (e.itemData.id) {
        case "add":
          this.onClickAdd();
          break;
        case "rename":
          await this.rename("nombre", "Cambiar el nombre", this.$t("cambiarElNombre"));
          break;
        case "redescription":
          await this.rename("descripcion", "Cambiar la descripcion", this.$t("cambiarLaDescripcion"));
          break;
        case "del":
          if (
            !(await confirm(
              `${this.$t("estasSeguroQueQuieresBorrar")} <b>${this.selectedImage.nombre}</b>?`,
              this.$t("borrarElemento")
            ))
          )
            return;
          try {
            await this.attachedSource.store().remove(this.selectedImage.id);
            await this.attachedSource.reload();

            this.$emit("changed", this.attachedSource.items());
          } catch (ex) {
            console.error(ex);
          }
          break;
      }
    },
  },

  computed: {
    showGallery: function () {
      return this.attachedSource.items().length > 0;
    },
    toolbarItemCancelarOptions: function () {
      return {
        text: this.$t("cancelar"),
        type: "normal",
        onClick: this.onClickCancelar,
      };
    },

    toolbarItemGuardarOptions: function () {
      return {
        text: this.$t("guardar"),
        stylingMode: "contained",
        type: "default",
        onClick: this.onClickAceptar,
        validationGroup: this.resourceURI,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
