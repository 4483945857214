<template>
  <div>
    <DxPopup
      v-if="attachedID"
      :title="$t('ficherosAdjuntos')"
      :visible.sync="isPopupAttachedVisible"
      :fullScreen="false"
      :animation="{
        show: {
          type: 'pop',
        },
        hide: {
          type: 'pop',
          from: { scale: 1, opacity: 1 },
          to: { scale: 0, opacidy: 0 },
        },
      }"
      @hidden="refreshDataGrid"
    >
      <template>
        <div>
          <!-- <code>
            {{ attachedID }}
            <br />
            <br />
            {{ attachedResourceURI }}
            <br />
            <br />
            {{ attachedResource }}
          </code> -->
          <AttachedViewer
            ref="attachedViewerGeneral"
            v-if="attachedID && attachedResourceType === 'IMG'"
            :key="attachedID"
            :resourceURI="attachedResourceURI"
            :resourceType="attachedResourceType"
          ></AttachedViewer>

          <AttachedDocumentViewer
            ref="attachedDocumentViewerGeneral"
            v-if="attachedID && attachedResourceType === 'DOC'"
            :key="attachedID"
            :resourceURI="attachedResourceURI"
            :resourceType="attachedResourceType"
          ></AttachedDocumentViewer>
        </div>
      </template>
    </DxPopup>

    <DxDataGrid
      ref="grid"
      id="gridMultiplesDefectos"
      class="dx-card wide-card"
      :dataSource="caracteristicaMultiplesDefectosDataSource"
      keyExpr="id"
    >
      <DxEditing :allow-updating="true" :allow-adding="true" :allow-deleting="true" mode="row"> </DxEditing>

      <DxColumn dataField="linea" :caption="$t('linea')" sortOrder="asc" width="80" data-type="number" />
      <DxColumn dataField="defecto" :caption="$t('defecto')" editCellTemplate="defectoEditor">
        <DxRequiredRule />
      </DxColumn>

      <template #defectoEditor="{ data: cellInfo }">
        <DictionaryEditor
          :title="$t('catalogoCaracteristicas')"
          v-model="cellInfo.value"
          value-expr="texto"
          recurso="caracteristicas/defectos/diccionario"
          @input="
            (e) => {
              cellInfo.setValue(e);
            }
          "
          @dataSourceUpdated="
            () => {
              $refs.grid.instance.refresh();
            }
          "
        >
        </DictionaryEditor>
      </template>

      <DxColumn dataField="notas" :caption="$t('notas')" />
      <DxColumn
        :allow-editing="false"
        data-field="documentos.length"
        width="50"
        cell-template="documentos"
        header-cell-template="documentos-header"
      >
      </DxColumn>
      <template #documentos-header>
        <span class="pr-1">
          <i class="ion ion-md-attach"></i>
        </span>
      </template>
      <template #documentos="{ data }">
        <ButtonAttachedDocuments
          v-if="!data.row.isEditing"
          @click="
            onAttachedClick({
              data: data.data,
              resource: `caracteristicas/${data.data.id_caracteristica}/mdef/${data.data.id}`,
              type: 'DOC',
            })
          "
          :number="data?.data?.documentos?.length"
        />
      </template>

      <DxColumn
        :allow-editing="false"
        data-field="fotos.length"
        cell-template="fotos"
        width="50"
        header-cell-template="fotos-header"
      >
      </DxColumn>
      <template #fotos-header>
        <span class="pr-1">
          <i class="ion ion-md-camera"></i>
        </span>
      </template>
      <template #fotos="{ data }">
        <ButtonAttachedImages
          v-if="!data.row.isEditing"
          @click="
            onAttachedClick({
              data: data.data,
              resource: `caracteristicas/${data.data.id_caracteristica}/mdef/${data.data.id}`,
              type: 'IMG',
            })
          "
          :number="data?.data?.fotos?.length"
        />
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
import AttachedViewer from "@/components/attached-viewer.vue";
import AttachedDocumentViewer from "@/components/attached-document-viewer.vue";
import DictionaryEditor from "@/components/core/DictionaryEditor.vue";

import DxPopup from "devextreme-vue/popup";
import { DxDataGrid, DxColumn, DxEditing } from "devextreme-vue/data-grid";
import { DxRequiredRule } from "devextreme-vue/validator";

import { DataSourceCaracteristicasMultiplesDefectos } from "@/data/caracteristicas/CaracteristicasMultiplesDefectosDataSource.js";

export default {
  props: ["idCaracteristica"],
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPopup,
    DxRequiredRule,
    AttachedViewer,
    AttachedDocumentViewer,
    DictionaryEditor,
    ButtonAttachedDocuments: () => import("@/components/button-attached-documents.vue"),
    ButtonAttachedImages: () => import("@/components/button-attached-images.vue"),
  },
  data() {
    //const { id: idCaracteristica } = this.$props.caracteristica;

    return {
      caracteristicaMultiplesDefectosDataSource: new DataSourceCaracteristicasMultiplesDefectos(this.idCaracteristica),
      isPopupAttachedVisible: false,
      attachedID: undefined,
      attachedResourceURI: undefined,
      attachedResource: undefined,
    };
  },
  methods: {
    addDefecto() {},
    removeDefecto() {},

    onAttachedClick({ data, resource, type }) {
      this.isPopupAttachedVisible = true;
      this.attachedID = data.id;
      this.attachedResourceURI = resource;
      this.attachedResourceType = type;
    },

    refreshDataGrid() {
      this.$refs.grid.instance.refresh();
    },
  },
  computed: {},
};
</script>
